import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  FIND_COMPANY_MEMBER_BY_ID,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import { MemberTypeEnum, ModuleEnum } from '../../../../commons/enums';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const JointShareholderInformation: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isJointOwnerShare, setIsJointOwnerShare] = useState<boolean>(false);
  const [products, setProducts] = useState<any>();
  const [tempProducts, setTempProducts] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Joint Shareholder Information',
        },
      ])
    );
  }, []);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);
  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setProducts(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
          ?.jointShareHolderInfo
      );
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
      {
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.type ===
        MemberTypeEnum.JOINT_OWNER_SHARE
          ? setIsJointOwnerShare(true)
          : setIsJointOwnerShare(false);
      }
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);
  useEffect(() => {
    if (dataDraftUpdateCompanyMember && !errorDraftUpdateCompanyMember) {
      setEditableRow(-1);
    } else if (errorDraftUpdateCompanyMember) {
      dispatch(setToastError(errorDraftUpdateCompanyMember));
    }
  }, [errorDraftUpdateCompanyMember, dataDraftUpdateCompanyMember]);
  useEffect(() => {
    if (dataupdateCompanyMember && !errorupdateCompanyMember) {
      setEditableRow(-1);
    } else if (errorupdateCompanyMember) {
      dispatch(setToastError(errorupdateCompanyMember));
    }
  }, [errorupdateCompanyMember, dataupdateCompanyMember]);
  return (
    <React.Fragment>
      {loadingRelevantProcesses ||
      loadingReliventDocumnets ||
      loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 bg-white">
            <div
              id="mainComponentHeading"
              className="background-primary-medium"
            >
              <span className="ms-auto">
                <div className="d-flex justify-content-end gap-3 end-0 mx-4">
                  <button
                    type="button"
                    className="btn fw-bold dangerButton d-flex align-items-center "
                    disabled={editableRow !== -1}
                    onClick={() => {
                      if (isNewFolio) {
                        navigate('/shares/add');
                      } else if (isNewFolioForTransfer) {
                        navigate('/shares/add/transfer');
                      } else {
                        navigate('/companyMembers');
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn fw-bold primaryDismissButton d-flex align-items-center "
                    disabled={editableRow !== -1}
                    onClick={() => {
                      if (isNewFolio) {
                        navigate(
                          '/companyMembers/membershipInformation?isNewFolio=true'
                        );
                      } else if (isNewFolioForTransfer) {
                        navigate(
                          '/companyMembers/membershipInformation?isNewFolioForTransfer=true'
                        );
                      } else {
                        navigate('/companyMembers/membershipInformation');
                      }
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn fw-bold successButton d-flex align-items-center "
                    disabled={editableRow !== -1}
                    onClick={() => {
                      if (isNewFolio) {
                        navigate(
                          '/companyMembers/nomineeInformation?isNewFolio=true'
                        );
                      } else if (isNewFolioForTransfer) {
                        navigate(
                          '/companyMembers/nomineeInformation?isNewFolioForTransfer=true'
                        );
                      } else {
                        navigate('/companyMembers/nomineeInformation');
                      }
                    }}
                  >
                    Next
                    <i className="bx bx-chevron-right ms-1" />
                  </button>
                </div>
              </span>
            </div>
            <div className="c-nav">
              <span>
                <ul className="nav nav-tabs w-100">
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link cursor-pointer">
                        Company Member Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate(
                            '/companyMembers/primaryShareHolderInformation'
                          )
                        }
                        className="nav-link cursor-pointer"
                      >
                        Company Member Information
                      </a>
                    )}
                  </li>
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link active cursor-pointer">
                        Joint Shareholder Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate(
                            '/companyMembers/jointShareholderInformation'
                          )
                        }
                        className="nav-link active cursor-pointer"
                      >
                        Joint Shareholder Information
                      </a>
                    )}
                  </li>
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link cursor-pointer">
                        Nominee Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate('/companyMembers/nomineeInformation')
                        }
                        className="nav-link cursor-pointer"
                      >
                        Nominee Information
                      </a>
                    )}
                  </li>
                  <li className="nav-item border-end flex-grow-1 text-center">
                    <a
                      onClick={() => navigate('/companyMembers/memberHistory')}
                      className="nav-link cursor-pointer"
                    >
                      Member History
                    </a>
                  </li>
                </ul>
              </span>
            </div>

            <div id="mainComponentNew">
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div className="w-100" style={{ display: 'contents' }}>
                <div className="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Joint Shareholder</th> */}
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Name</th>
                        {!memberView && isJointOwnerShare && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((val: any, index: number) => (
                        <tr key={`${index}`}>
                          {/* <td>
                          {editableRow === index ? (
                            <input
                              value={val.jointShareHolder}
                              onChange={e => {
                                const { value } = e.target;
                                const temp = JSON.parse(
                                  JSON.stringify(products)
                                );
                                temp[index].jointShareHolder = value;
                                setProducts(temp);
                              }}
                            />
                          ) : (
                            val.jointShareHolder
                          )}
                        </td> */}
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.firstName}
                                type="text"
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  temp[index].firstName = value;
                                  setProducts(temp);
                                }}
                              />
                            ) : (
                              val.firstName
                            )}
                          </td>
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.middleName}
                                type="text"
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  temp[index].middleName = value;
                                  setProducts(temp);
                                }}
                              />
                            ) : (
                              val.middleName
                            )}
                          </td>
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.lastName}
                                type="text"
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  temp[index].lastName = value;
                                  setProducts(temp);
                                }}
                              />
                            ) : (
                              val.lastName
                            )}
                          </td>
                          {/* {!companyView && */}
                          {!memberView && isJointOwnerShare && (
                            <td className="editColumn">
                              {editableRow !== index ? (
                                <>
                                  <i
                                    className={`bx bxs-edit ${
                                      editableRow === -1
                                        ? 'text-primary'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        setEditableRow(index);
                                        setTempProducts(products);
                                      }
                                    }}
                                  />
                                  <i
                                    className={`bx bxs-trash-alt ${
                                      editableRow === -1
                                        ? 'text-danger'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            let temp = [...products];
                                            temp.splice(index, 1);
                                            if (isDraft) {
                                              let temp2 = JSON.parse(
                                                JSON.stringify(temp)
                                              );
                                              temp2.forEach((element: any) => {
                                                delete element['__typename'];
                                              });
                                              draftUpdateCompanyMember({
                                                variables: {
                                                  id: selectedMemberId,
                                                  companyId: companyID,
                                                  jointShareHolderInfo: temp2,
                                                },
                                              }).then(res => {
                                                res.data && setProducts(temp);
                                                ConfirmationDialogue.fire(
                                                  'Deleted!',
                                                  '',
                                                  'success'
                                                );
                                              });
                                            } else {
                                              let temp2 = JSON.parse(
                                                JSON.stringify(products)
                                              );
                                              temp2.forEach((element: any) => {
                                                delete element['__typename'];
                                              });
                                              temp2[index].toBeDeleted = true;
                                              updateCompanyMember({
                                                variables: {
                                                  id: selectedMemberId,
                                                  companyId: companyID,
                                                  jointShareHolderInfo: temp2,
                                                },
                                              }).then(res => {
                                                res.data && setProducts(temp);
                                                ConfirmationDialogue.fire(
                                                  'Deleted!',
                                                  '',
                                                  'success'
                                                );
                                              });
                                            }
                                          } else if (
                                            result.dismiss ===
                                            Swal.DismissReason.cancel
                                          ) {
                                            ConfirmationDialogue.fire(
                                              'Cancelled',
                                              '',
                                              'error'
                                            );
                                          }
                                        });
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <i
                                    className="bx bx-check-circle text-primary"
                                    onClick={() => {
                                      if (
                                        products[editableRow].firstName &&
                                        products[editableRow].lastName
                                      ) {
                                        if (isDraft) {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          draftUpdateCompanyMember({
                                            variables: {
                                              id: selectedMemberId,
                                              companyId: companyID,
                                              jointShareHolderInfo: temp,
                                            },
                                          });
                                        } else {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          updateCompanyMember({
                                            variables: {
                                              id: selectedMemberId,
                                              companyId: companyID,
                                              jointShareHolderInfo: temp,
                                            },
                                          });
                                        }
                                      } else {
                                        dispatch(
                                          setToastError(
                                            'Please enter data of all input fields!'
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  <i
                                    className="bx bxs-x-circle text-danger"
                                    onClick={() => {
                                      setEditableRow(-1);
                                      setProducts(tempProducts);
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {(products?.length === 0 || products === undefined) &&
                    memberView &&
                    !isJointOwnerShare && (
                      <div className="addDiv">
                        <h6 className="">No records Found</h6>
                      </div>
                    )}
                  {!memberView && isJointOwnerShare && (
                    <div className="addDiv">
                      <i
                        className="bx bxs-plus-circle font-22 text-success cursor-pointer"
                        onClick={() => {
                          if (editableRow === -1) {
                            if (products) {
                              setTempProducts(products);
                              let newProduct = [...products];
                              newProduct.push({
                                id: uuidv4(),
                                //   jointShareHolder: '',
                                firstName: '',
                                middleName: '',
                                lastName: '',
                              });
                              setProducts(newProduct);
                              setEditableRow(products?.length);
                            } else {
                              let newProduct = [
                                {
                                  id: uuidv4(),
                                  // jointShareHolder: '',
                                  firstName: '',
                                  middleName: '',
                                  lastName: '',
                                },
                              ];
                              setProducts(newProduct);
                              setEditableRow(0);
                            }
                          } else {
                            dispatch(
                              setToastError(
                                'You have some unsaved data. Please save or discard it!'
                              )
                            );
                          }
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </>
      )}
    </React.Fragment>
  );
};
