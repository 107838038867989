import { SubscriptionPlansEnum } from '../../commons/enums';
const GetSubscriptionPlanDuration = (duration: string) => {
  if (duration === SubscriptionPlansEnum.ONE_YEAR_PLAN) {
    return '1 Year';
  } else if (duration === SubscriptionPlansEnum.TWO_YEARS_PLAN) {
    return '2 Years';
  } else if (duration === SubscriptionPlansEnum.THREE_YEARS_PLAN) {
    return '3 Years';
  }
};

export default GetSubscriptionPlanDuration;
