import { gql } from '@apollo/client';
export const GET_ALL_MEETINGS_OF_COMPANY = gql`
  query findAllMeetings(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $meetingType: String
    $meetingSubType: String
    $searchKey: String
    $searchValue: String
  ) {
    findAllMeetings(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: {
        companyId: $companyId
        meetingType: $meetingType
        meetingSubType: $meetingSubType
      }
      searchKey: $searchKey
      searchValue: $searchValue
      orderBy: "createdAt"
    ) {
      edges {
        node {
          meetingId
          meetingType
          meetingSubType
          meetingNumber
          meetingSubject
          startTime
          endTime
          meetingRoom
          otherRoom
          status
          chair
          invitees
          attendees
          absentees
          proxies
          companyId
          url
          meetingActionItems {
            id
            meetingId
            agendaId
            actionItem
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const CREATE_MEETING = gql`
  mutation createMeeting(
    $companyId: String
    $meetingType: String
    $meetingSubType: String
    $committeeId: String
    $meetingSubject: String
    $meetingRoom: String
    $otherRoom: String
    $status: String
    $chair: String
    $minuteTaker: String
    $auditor: String
    $invitees: String
    $address: String
    $city: String
    $province: String
    $country: String
    $isVirtual: Boolean
    $zoomLink: String
    $meetingActionItems: [MeetingActionItemsInput]
  ) {
    createMeeting(
      data: {
        companyId: $companyId
        meetingType: $meetingType
        meetingSubType: $meetingSubType
        committeeId: $committeeId
        meetingSubject: $meetingSubject
        meetingRoom: $meetingRoom
        otherRoom: $otherRoom
        status: $status
        chair: $chair
        minuteTaker: $minuteTaker
        auditor: $auditor
        address: $address
        city: $city
        province: $province
        country: $country
        isVirtual: $isVirtual
        zoomLink: $zoomLink
        invitees: $invitees
        meetingActionItems: $meetingActionItems
      }
    ) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        companyId
        meetingActionItems {
          id
          meetingId
          agendaId
          actionItem
        }
      }
    }
  }
`;
export const FIND_MEETING_BY_ID = gql`
  query findOneMeeting($id: ID!) {
    findOneMeeting(id: $id) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        companyId
        minuteTaker
        auditor
        address
        city
        province
        country
        isVirtual
        zoomLink
        status
        committee {
          committeeId
          committeeName
          committeeNumber
        }
        meetingActionItems {
          id
          meetingActionItemId
          meetingId
          agendaId
          actionItem
          resolution
          mom
          resolutionUrl
          status
          momUrl
        }
      }
    }
  }
`;
export const DELETE_MEETING = gql`
  mutation deleteMeeting($id: String!) {
    deleteMeeting(data: { id: $id }) {
      count
    }
  }
`;
export const UPDATE_MEETING = gql`
  mutation updateMeeting(
    $id: String!
    $meetingType: String
    $meetingSubType: String
    $committeeId: String
    $meetingSubject: String
    $meetingRoom: String
    $otherRoom: String
    $status: String
    $chair: String
    $minuteTaker: String
    $auditor: String
    $invitees: String
    $startTime: String
    $endTime: String
    $address: String
    $city: String
    $province: String
    $country: String
    $isVirtual: Boolean
    $zoomLink: String
    $meetingActionItems: [MeetingActionItemsInput]
  ) {
    updateMeeting(
      data: {
        id: $id
        data: {
          meetingType: $meetingType
          meetingSubType: $meetingSubType
          committeeId: $committeeId
          meetingSubject: $meetingSubject
          meetingRoom: $meetingRoom
          otherRoom: $otherRoom
          status: $status
          chair: $chair
          minuteTaker: $minuteTaker
          auditor: $auditor
          invitees: $invitees
          startTime: $startTime
          endTime: $endTime
          address: $address
          city: $city
          province: $province
          country: $country
          isVirtual: $isVirtual
          zoomLink: $zoomLink
          meetingActionItems: $meetingActionItems
        }
      }
    ) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        companyId
        meetingActionItems {
          id
          meetingId
          agendaId
          actionItem
          resolutionUrl
          momUrl
        }
      }
    }
  }
`;
export const SCHEDULE_MEETING = gql`
  mutation scheduleMeeting(
    $id: String!
    $invitees: String!
    $startTime: String!
    $endTime: String!
    $status: String!
  ) {
    scheduleMeeting(
      data: {
        id: $id
        invitees: $invitees
        startTime: $startTime
        endTime: $endTime
        status: $status
      }
    ) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        companyId
        meetingActionItems {
          id
          meetingId
          agendaId
          actionItem
        }
      }
    }
  }
`;
export const FIND_MEETING_INVITEES_INFO = gql`
  query findMeetingInviteesInfo(
    $meetingType: String
    $meetingSubType: String
    $companyId: String
  ) {
    findMeetingInviteesInfo(
      input: {
        meetingType: $meetingType
        meetingSubType: $meetingSubType
        companyId: $companyId
      }
    ) {
      head
      auditor
      minuteTaker
      invitees
    }
  }
`;
export const CONCLUDE_MEETING = gql`
  mutation concludeMeeting(
    $meetingId: String!
    $attendees: String
    $absentees: String
    $proxies: String
    $mom: [MeetingActionItemsInput]
    $generalMom: [String]
    $sendPdfReport: Boolean
  ) {
    concludeMeeting(
      data: {
        meetingId: $meetingId
        attendees: $attendees
        absentees: $absentees
        proxies: $proxies
        mom: $mom
        generalMom: $generalMom
        sendPdfReport: $sendPdfReport
      }
    ) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        companyId
        meetingActionItems {
          id
          meetingId
          agendaId
          resolution
          actionItem
        }
      }
    }
  }
`;
export const ADJOURN_MEETING = gql`
  mutation adjournMeeting(
    $meetingId: String!
    $attendees: String
    $absentees: String
    $proxies: String
    $mom: [MeetingActionItemsInput]
    $generalMom: [String]
    $sendPdfReport: Boolean
  ) {
    adjournMeeting(
      data: {
        meetingId: $meetingId
        attendees: $attendees
        absentees: $absentees
        proxies: $proxies
        mom: $mom
        generalMom: $generalMom
        sendPdfReport: $sendPdfReport
      }
    ) {
      meeting {
        meetingId
        meetingType
        meetingSubType
        meetingNumber
        meetingSubject
        startTime
        endTime
        meetingRoom
        otherRoom
        chair
        invitees
        attendees
        absentees
        proxies
        meetingActionItems {
          id
          meetingId
          agendaId
          resolution
          actionItem
        }
      }
    }
  }
`;
