import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SAVE_DEVICE_TOKEN } from '~/Store/graphQL/Notification';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setDeviceToken } from '~/Store/reducers/notification';
import { getToken } from '../../firebase';

const Notifications = () => {
  const { deviceToken } = useAppSelector(state => state.notificationReducer);
  const dispatch = useAppDispatch();
  const [isTokenFound, setTokenFound] = useState(false);

  const [
    addDeviceToken,
    {
      loading: loadingAddDeviceToken,
      error: errorAddDeviceToken,
      data: dataAddDeviceToken,
    },
  ] = useMutation<any>(SAVE_DEVICE_TOKEN);

  // To load once
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        if (!deviceToken) {
          dispatch(setDeviceToken(data));
          addDeviceToken({
            variables: {
              token: data,
            },
          });
        } else if (deviceToken && deviceToken !== data) {
          dispatch(setDeviceToken(data));
          addDeviceToken({
            variables: {
              token: data,
            },
          });
        }
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);
  return <></>;
};
Notifications.prototype = {};
export default Notifications;
