import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setSelectedUserGroupId } from '~/Store/reducers/userGroup';

import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import './permissions.scss';

import { FIND_PERMISSIONS, FIND_USER_GROUPS } from '~/Store/graphQL/UserGroup';
import { ACCESS_PRIVILAGES } from '~/commons/enums/AccessPrivileges';
import getModules from '~/helpers/Permissions/getModules';
import { ModuleEnum } from '~/commons/enums';

export const Permissions: FC = () => {
  const [permissions, setPermissions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [usergroupId, setusergroupId] = useState<any>();
  const [moduleName, setmoduleName] = useState<any>();
  const data = ACCESS_PRIVILAGES;
  const [itemsPerPage, setItemsPerPage] = useState<number>(data.length);

  const [userGroups, setuserGroups] = useState<any>([]);
  const navigate = useNavigate();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Access Privileges',
        },
      ])
    );
    findPermissions();
    findUserGroups();
  }, []);

  const [
    findPermissions,
    {
      loading: loadingfindPermissions,
      error: errorfindPermissions,
      data: datafindPermissions,
    },
  ] = useLazyQuery<any>(FIND_PERMISSIONS, {
    variables: {
      companyId: companyID,
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (datafindPermissions) {
      setPermissions(datafindPermissions.findPermissions.edges);
    } else if (errorfindPermissions) {
      console.log('errorfindPermissions', errorfindPermissions);
      dispatch(setToastError(errorfindPermissions));
    }
  }, [datafindPermissions, errorfindPermissions]);

  const [
    findUserGroups,
    {
      loading: loadingFindUserGroup,
      error: errorFindUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUPS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (dataFindUserGroup) {
      setuserGroups(dataFindUserGroup.userGroups.edges);
    } else if (errorFindUserGroup) {
      console.log('errorFindUserGroup', errorFindUserGroup);
      dispatch(setToastError(errorFindUserGroup));
    }
  }, [dataFindUserGroup, errorFindUserGroup]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Access Privileges</b>
              </h5>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 mb-4">
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="userGroup">User Group</label>
                </div>
                <div className="field">
                  <select
                    id="userGroup"
                    name="userGroup"
                    className={`form-control`}
                    value={usergroupId}
                    onChange={e => {
                      setusergroupId(e.target.value);
                    }}
                  >
                    <option>Select</option>
                    {userGroups?.map((val: any, index: number) => (
                      <option key={index} value={val.node.id}>
                        {val?.node?.groupName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="module">Module</label>
                </div>

                <div className="field">
                  <select
                    id="moduleName"
                    name="moduleName"
                    className={`form-control`}
                    value={moduleName}
                    onChange={e => {
                      setmoduleName(e.target.value);
                    }}
                  >
                    <option>Select</option>
                    <option value={ModuleEnum.COMPANY_PROFILE}>
                      Company Profile
                    </option>
                    <option value={ModuleEnum.COMPANY_OFFICERS}>
                      Company Officers
                    </option>
                    <option value={ModuleEnum.COMPANY_MEMBERS}>
                      Company Members
                    </option>
                    <option value={ModuleEnum.REGISTER}>Registers</option>
                    <option value={ModuleEnum.NOTIFICATION}>
                      Notifications
                    </option>
                    <option value={ModuleEnum.MEETING}>
                      Meeting Management
                    </option>
                    <option value={ModuleEnum.COMMITTEE}>
                      Committee Management
                    </option>
                    <option value={ModuleEnum.SHARE_MANAGEMENT}>
                      Company Shares Management
                    </option>
                    <option value={ModuleEnum.LEGAL_DOCUMENTS}>
                      Legal Documents Management
                    </option>
                    <option value={ModuleEnum.BANK_ACCOUNTS}>
                      Bank Account Management
                    </option>
                    <option value={ModuleEnum.FINANCIAL_YEAR}>
                      Financial Year Management
                    </option>
                    <option value={ModuleEnum.SYSTEM_USER}>
                      System Users Management
                    </option>
                    <option value={ModuleEnum.SYSTEM_USER_GROUPS}>
                      User Groups Management
                    </option>
                  </select>
                </div>
              </div>

              <button
                type="button"
                className="btn dangerButton d-flex align-items-center align-self-end mb-2"
                onClick={() => {
                  findPermissions();
                  setmoduleName('');
                  setusergroupId('');
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn primaryDismissButton d-flex align-items-center align-self-end mb-2"
                onClick={() => {
                  findPermissions({
                    variables: {
                      userGroupId: usergroupId,
                      moduleName: moduleName,
                    },
                  });
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              onClick={() => {
                dispatch(setSelectedUserGroupId(null));
                navigate('/permission/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Permissions
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingFindUserGroup ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead className="bg-primary">
                          <tr>
                            <th className="col-1 text-white">Module</th>
                            <th className="col-1 text-white">User Group</th>
                            <th className="col-1 text-white">View</th>
                            <th className="col-1 text-white">Edit</th>
                            <th className="col-1 text-white">Delete</th>
                            <th className="col-1 text-white">Email</th>
                            <th className="col-1 text-white">
                              Generate Documents
                            </th>
                            <th className="col-1 text-white">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {permissions?.map((val: any, index: number) => (
                            <tr key={`${index}`}>
                              <td>{getModules(val.node.moduleName)}</td>

                              <td>{val.node.groupName}</td>

                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'view'}
                                  name={'view'}
                                  checked={val.node.view}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'edit'}
                                  name={'edit'}
                                  checked={val.node.edit}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'delete'}
                                  name={'delete'}
                                  checked={val.node.delete}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'email'}
                                  name={'email'}
                                  checked={val.node.email}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'generateDocument'}
                                  name={'generateDocument'}
                                  checked={val.node.generateDocument}
                                ></input>
                              </td>

                              <td>
                                <div className="editColumn mt-0 gap-1">
                                  <i
                                    className="bx bxs-edit text-primary bx-sm"
                                    onClick={() => {
                                      navigate('/permission/add');
                                      dispatch(
                                        setSelectedUserGroupId(
                                          val.node.userGroupId
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {(datafindPermissions?.findPermissions?.edges?.length ===
                        0 ||
                        datafindPermissions?.findPermissions?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={16}>16</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          datafindPermissions?.findPermissions?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            datafindPermissions?.findPermissions?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            findPermissions({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  datafindPermissions?.findPermissions?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          datafindPermissions?.findPermissions?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            datafindPermissions?.findPermissions?.pageInfo
                              ?.hasNextPage
                          ) {
                            findPermissions({
                              variables: {
                                after:
                                  datafindPermissions?.findPermissions?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
