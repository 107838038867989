import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ModuleEnum } from '~/commons/enums';
import { DefaultEditor } from 'react-simple-wysiwyg';
import {
  CREATE_HELP_OF_MODULE,
  FIND_HELP_BY_ID,
  UPDATE_HELP_OF_MODULE,
} from '~/Store/graphQL/HelpSection';

export const AddAdminHelpSection: FC = () => {
  const { selectedHelpSectionId, helpSectionView } = useAppSelector(
    state => state.helpSectionReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/admin/help',
          title: 'Help Section',
        },
        {
          url: location.pathname,
          title: 'Add Help',
        },
      ])
    );
  }, []);

  const [
    findHelpSectionById,
    {
      loading: loadingFindHelpSectionById,
      error: errorFindHelpSectionById,
      data: dataFindHelpSectionById,
    },
  ] = useLazyQuery<any>(FIND_HELP_BY_ID);

  useEffect(() => {
    if (dataFindHelpSectionById) {
      setSubscriptionData(
        dataFindHelpSectionById?.findOneHelpSection?.helpSection
      );
    } else if (errorFindHelpSectionById) {
      console.log('errorFindHelpSectionById:', errorFindHelpSectionById);
    }
  }, [dataFindHelpSectionById, errorFindHelpSectionById]);

  useEffect(() => {
    if (selectedHelpSectionId) {
      findHelpSectionById({
        variables: {
          id: selectedHelpSectionId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedHelpSectionId]);

  // update help section of a module

  const [
    updateHelpOfModule,
    {
      loading: loadingUpdateHelpOfModule,
      error: errorUpdateHelpOfModule,
      data: dataUpdateHelpOfModule,
    },
  ] = useMutation<any>(UPDATE_HELP_OF_MODULE);

  // create help section of a module

  const [
    createHelpOfModule,
    {
      loading: loadingCreateHelpOfModule,
      error: errorCreateHelpOfModule,
      data: dataCreateHelpOfModule,
    },
  ] = useMutation<any>(CREATE_HELP_OF_MODULE);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      moduleName: subscriptionData ? subscriptionData.moduleName : '',
      heading: subscriptionData ? subscriptionData.heading : '',
      instructions: subscriptionData ? subscriptionData.instructions : '',
    },
    validationSchema: Yup.object({
      moduleName: Yup.string().required('Please select module name').nullable(),
      // heading: Yup.string().required('Please select heading').nullable(),
      instructions: Yup.string()
        .required('Please enter instructions')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (selectedHelpSectionId) {
        updateHelpOfModule({
          variables: {
            id: selectedHelpSectionId,
            moduleName: values.moduleName,
            heading: values.heading,
            instructions: values.instructions,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateHelpSection) {
              dispatch(setToastSuccess('Help updated!'));
              navigate(`/admin/help`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createHelpOfModule({
          variables: {
            moduleName: values.moduleName,
            heading: values.heading,
            instructions: values.instructions,
          },
        })
          .then((res: any) => {
            if (res?.data?.createHelpSection) {
              dispatch(setToastSuccess('Help created!'));
              navigate(`/admin/help`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindHelpSectionById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Help Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="moduleName">Module Name*</label>
                      {validation.touched.moduleName &&
                      validation.errors.moduleName ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.moduleName}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.moduleName &&
                          validation.errors.moduleName &&
                          'is-invalid'
                        }`}
                        id="moduleName"
                        name="moduleName"
                        placeholder="Module Name"
                        value={validation.values.moduleName}
                        disabled={helpSectionView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={ModuleEnum.COMPANY_PROFILE}>
                          Company Profile
                        </option>
                        <option value={ModuleEnum.COMPANY_OFFICERS}>
                          Company Officers
                        </option>
                        <option value={ModuleEnum.COMPANY_MEMBERS}>
                          Company Members
                        </option>
                        <option value={ModuleEnum.REGISTER}>Registers</option>
                        <option value={ModuleEnum.NOTIFICATION}>
                          Notifications
                        </option>
                        <option value={ModuleEnum.MEETING}>
                          Meeting Management
                        </option>
                        <option value={ModuleEnum.COMMITTEE}>
                          Committee Management
                        </option>
                        <option value={ModuleEnum.SHARE_MANAGEMENT}>
                          Company Shares Management
                        </option>
                        <option value={ModuleEnum.LEGAL_DOCUMENTS}>
                          Legal Documents Management
                        </option>
                        <option value={ModuleEnum.BANK_ACCOUNTS}>
                          Bank Account Management
                        </option>
                        <option value={ModuleEnum.FINANCIAL_YEAR}>
                          Financial Year Management
                        </option>
                        <option value={ModuleEnum.SYSTEM_USER}>
                          System Users Management
                        </option>
                        <option value={ModuleEnum.SYSTEM_USER_GROUPS}>
                          User Groups Management
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6">
                  {/* <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="heading">Heading*</label>
                      {validation.touched.heading &&
                      validation.errors.heading ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.heading}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.heading &&
                        validation.errors.heading &&
                        'is-invalid'
                      }`}
                      id="heading"
                      name="heading"
                      placeholder="Heading"
                      value={validation.values.heading}
                      onChange={validation.handleChange}
                    />
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-8 col-xl-12">
                  <div className="form-group">
                    <div className="d-flex w-350 mb-2">
                      <label htmlFor="instructions">Instructions*</label>
                      {validation.touched.instructions &&
                      validation.errors.instructions ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.instructions}
                        </p>
                      ) : null}
                    </div>
                    <DefaultEditor
                      id="instructions"
                      name="instructions"
                      placeholder="Instructions"
                      style={{ minHeight: '20vh' }}
                      value={validation.values.instructions}
                      onChange={e => {
                        e.target.value = e.target.value.replaceAll(
                          '&nbsp;',
                          ' '
                        );
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  disabled={
                    loadingFindHelpSectionById ||
                    loadingUpdateHelpOfModule ||
                    loadingCreateHelpOfModule
                  }
                  onMouseDown={() => {
                    navigate('/admin/help');
                  }}
                >
                  Cancel
                </button>
                {true ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={
                      loadingFindHelpSectionById ||
                      loadingUpdateHelpOfModule ||
                      loadingCreateHelpOfModule
                    }
                    onMouseDown={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {(loadingFindHelpSectionById ||
                      loadingUpdateHelpOfModule ||
                      loadingCreateHelpOfModule) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
