import { BusinessTypeEnum } from '../../commons/enums';
const GetAgendaBusinessType = (businessType: string) => {
  if (businessType === BusinessTypeEnum.ORDINARY_BUSINESS) {
    return 'Ordinary Business';
  } else if (businessType === BusinessTypeEnum.SPECIAL_BUSINESS) {
    return 'Special Business';
  }
};

export default GetAgendaBusinessType;
