import { ShareSubTypeEnum } from '../../commons/enums';
const GetShareSubType = (type: string) => {
  if (type === ShareSubTypeEnum.CLASS_1A_SHARE) {
    return 'Class 1/A Share';
  } else if (type === ShareSubTypeEnum.CLASS_2B_SHARE) {
    return 'Class 2/B Share';
  } else if (type === ShareSubTypeEnum.NON_PARTICIPATING_PREFERENCE) {
    return 'Non Participating Preference';
  } else if (type === ShareSubTypeEnum.PARTICIPATING_ACCUMULATIVE) {
    return 'Participating  Accumulative';
  } else if (type === ShareSubTypeEnum.PARTICIPATING_NON_ACCUMULATIVE) {
    return 'Participating Non Accumulative';
  } else if (type === ShareSubTypeEnum.PARTICIPATING_PREFERENCE) {
    return 'Participating Preference';
  } else if (type === ShareSubTypeEnum.OTHER) {
    return 'Other';
  }
};

export default GetShareSubType;
