import { gql } from '@apollo/client';

export const CREATE_COMPANY_PROFILE_DRAFT = gql`
  mutation draftCreateProfileCompanyDraft(
    $name: String
    $cuinNumber: String
    $kind: String
    $sectorialClassification: String
    $companyObjects: String
    $incorporationDate: String
    $commencementOfBusiness: String
    $holdingCompany: String
    $address: String
    $city: String
    $district: String
    $province: String
    $isActive: Boolean
    $town: String
    $postalCode: String
    $mobile: String
    $phone: String
    $fax: String
    $email: String
    $websiteUrl: String
    $perFaceValue: Int
    $paidUpCapital: Int
    $authorizedCapitalShare: Float
    $authorizedCapitalTotal: Float
    $companySubsidiariesInput: [CompanySubsidiariesInput]
    $companyMortgagesInput: [CompanyMortgagesInput]
    $companyPaidUpCapitalsInput: [CompanyPaidUpCapitalsInput]
    $companyAuthorizedCapitalsInput: [CompanyAuthorizedCapitalsInput]
  ) {
    draftCreateProfileCompanyDraft(
      data: {
        name: $name
        cuinNumber: $cuinNumber
        kind: $kind
        sectorialClassification: $sectorialClassification
        companyObjects: $companyObjects
        incorporationDate: $incorporationDate
        commencementOfBusiness: $commencementOfBusiness
        holdingCompany: $holdingCompany
        address: $address
        city: $city
        district: $district
        province: $province
        town: $town
        postalCode: $postalCode
        mobile: $mobile
        phone: $phone
        fax: $fax
        isActive: $isActive
        email: $email
        websiteUrl: $websiteUrl
        perFaceValue: $perFaceValue
        paidUpCapital: $paidUpCapital
        authorizedCapitalShare: $authorizedCapitalShare
        authorizedCapitalTotal: $authorizedCapitalTotal
        companySubsidiariesInput: $companySubsidiariesInput
        companyMortgagesInput: $companyMortgagesInput
        companyPaidUpCapitalsInput: $companyPaidUpCapitalsInput
        companyAuthorizedCapitalsInput: $companyAuthorizedCapitalsInput
      }
    ) {
      draftCompanyProfile {
        companyId
      }
    }
  }
`;
export const UPDATE_COMPANY_PROFILE_DRAFT = gql`
  mutation draftUpdateProfileCompanyDraft(
    $id: String!
    $name: String
    $cuinNumber: String
    $kind: String
    $sectorialClassification: String
    $companyObjects: String
    $incorporationDate: String
    $commencementOfBusiness: String
    $holdingCompany: String
    $address: String
    $city: String
    $district: String
    $province: String
    $isActive: Boolean
    $town: String
    $postalCode: String
    $mobile: String
    $phone: String
    $fax: String
    $email: String
    $websiteUrl: String
    $perFaceValue: Int
    $paidUpCapital: Int
    $authorizedCapitalShare: Float
    $authorizedCapitalTotal: Float
    $companySubsidiariesInput: [CompanySubsidiariesInput]
    $companyMortgagesInput: [CompanyMortgagesInput]
    $companyPaidUpCapitalsInput: [CompanyPaidUpCapitalsInput]
    $companyAuthorizedCapitalsInput: [CompanyAuthorizedCapitalsInput]
    $logoUrl: String
    $logoFile: Upload
  ) {
    draftUpdateProfileCompanyDraft(
      data: {
        id: $id
        data: {
          name: $name
          cuinNumber: $cuinNumber
          kind: $kind
          sectorialClassification: $sectorialClassification
          companyObjects: $companyObjects
          incorporationDate: $incorporationDate
          commencementOfBusiness: $commencementOfBusiness
          holdingCompany: $holdingCompany
          address: $address
          city: $city
          district: $district
          province: $province
          town: $town
          postalCode: $postalCode
          mobile: $mobile
          phone: $phone
          isActive: $isActive
          fax: $fax
          email: $email
          websiteUrl: $websiteUrl
          perFaceValue: $perFaceValue
          paidUpCapital: $paidUpCapital
          authorizedCapitalShare: $authorizedCapitalShare
          authorizedCapitalTotal: $authorizedCapitalTotal
          companySubsidiariesInput: $companySubsidiariesInput
          companyMortgagesInput: $companyMortgagesInput
          companyPaidUpCapitalsInput: $companyPaidUpCapitalsInput
          companyAuthorizedCapitalsInput: $companyAuthorizedCapitalsInput
          logoFile: $logoFile
          logoUrl: $logoUrl
        }
      }
    ) {
      draftCompanyProfile {
        companyId
      }
    }
  }
`;
export const CREATE_COMPANY_PROFILE_FROM_DRAFT = gql`
  mutation createProfileFromCompanyDraft(
    $name: String
    $cuinNumber: String
    $kind: String
    $sectorialClassification: String
    $companyObjects: String
    $incorporationDate: String
    $commencementOfBusiness: String
    $holdingCompany: String
    $address: String
    $city: String
    $district: String
    $province: String
    $isActive: Boolean
    $town: String
    $postalCode: String
    $mobile: String
    $phone: String
    $fax: String
    $email: String
    $websiteUrl: String
    $perFaceValue: Float
    $paidUpCapital: Float
    $authorizedCapitalShare: Float
    $authorizedCapitalTotal: Float
    $companySubsidiariesInput: [CompanySubsidiariesInput]
    $companyMortgagesInput: [CompanyMortgagesInput]
    $companyPaidUpCapitalsInput: [CompanyPaidUpCapitalsInput]
    $companyAuthorizedCapitalsInput: [CompanyAuthorizedCapitalsInput]
    $logoUrl: String
    $logoFile: Upload
  ) {
    createProfileFromCompanyDraft(
      data: {
        name: $name
        cuinNumber: $cuinNumber
        kind: $kind
        sectorialClassification: $sectorialClassification
        companyObjects: $companyObjects
        incorporationDate: $incorporationDate
        commencementOfBusiness: $commencementOfBusiness
        holdingCompany: $holdingCompany
        address: $address
        city: $city
        district: $district
        province: $province
        town: $town
        postalCode: $postalCode
        mobile: $mobile
        phone: $phone
        fax: $fax
        isActive: $isActive
        email: $email
        websiteUrl: $websiteUrl
        perFaceValue: $perFaceValue
        paidUpCapital: $paidUpCapital
        authorizedCapitalShare: $authorizedCapitalShare
        authorizedCapitalTotal: $authorizedCapitalTotal
        companySubsidiariesInput: $companySubsidiariesInput
        companyMortgagesInput: $companyMortgagesInput
        companyPaidUpCapitalsInput: $companyPaidUpCapitalsInput
        companyAuthorizedCapitalsInput: $companyAuthorizedCapitalsInput
        logoUrl: $logoUrl
        logoFile: $logoFile
      }
    ) {
      companyProfile {
        companyId
        name
        cuinNumber
        kind
        sectorialClassification
        companyObjects
        incorporationDate
        commencementOfBusiness
        holdingCompany
        address
        district
        city
        province
        phone
        mobile
        fax
        email
        websiteUrl
        logoUrl
        companySubsidiaries {
          id
          companyName

          shareHolding
          incorporationNumber
        }
        companyMortgages {
          id
          landing
          borrowing
          mortgage
          charging
          createdBy
        }
        companyPaidUpCapitals {
          id
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
        companyAuthorizedCapitals {
          id
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
      }
      errors {
        message
        field
      }
    }
  }
`;
export const UPDATE_COMPANY_PROFILE = gql`
  mutation updateCompanyProfile(
    $id: String!
    $name: String
    $cuinNumber: String
    $kind: String
    $sectorialClassification: String
    $companyObjects: String
    $incorporationDate: String
    $commencementOfBusiness: String
    $holdingCompany: String
    $address: String
    $city: String
    $district: String
    $province: String
    $isActive: Boolean
    $town: String
    $postalCode: String
    $mobile: String
    $phone: String
    $fax: String
    $email: String
    $websiteUrl: String
    $perFaceValue: Float
    $paidUpCapital: Float
    $authorizedCapitalShare: Float
    $authorizedCapitalTotal: Float
    $companySubsidiariesInput: [CompanySubsidiariesInput]
    $companyMortgagesInput: [CompanyMortgagesInput]
    $companyPaidUpCapitalsInput: [CompanyPaidUpCapitalsInput]
    $companyAuthorizedCapitalsInput: [CompanyAuthorizedCapitalsInput]
    $logoFile: Upload
    $logoUrl: String
  ) {
    updateCompanyProfile(
      data: {
        id: $id
        data: {
          name: $name
          cuinNumber: $cuinNumber
          kind: $kind
          sectorialClassification: $sectorialClassification
          companyObjects: $companyObjects
          incorporationDate: $incorporationDate
          commencementOfBusiness: $commencementOfBusiness
          holdingCompany: $holdingCompany
          address: $address
          city: $city
          district: $district
          province: $province
          town: $town
          postalCode: $postalCode
          mobile: $mobile
          phone: $phone
          isActive: $isActive
          fax: $fax
          email: $email
          websiteUrl: $websiteUrl
          perFaceValue: $perFaceValue
          paidUpCapital: $paidUpCapital
          authorizedCapitalShare: $authorizedCapitalShare
          authorizedCapitalTotal: $authorizedCapitalTotal
          companySubsidiariesInput: $companySubsidiariesInput
          companyMortgagesInput: $companyMortgagesInput
          companyPaidUpCapitalsInput: $companyPaidUpCapitalsInput
          companyAuthorizedCapitalsInput: $companyAuthorizedCapitalsInput
          logoUrl: $logoUrl
          logoFile: $logoFile
        }
      }
    ) {
      companyProfile {
        companyId
        name
        cuinNumber
        kind
        sectorialClassification
        companyObjects
        incorporationDate
        commencementOfBusiness
        holdingCompany
        address
        city
        province
        phone
        mobile
        fax
        email
        websiteUrl
        logoUrl
        companySubsidiaries {
          id
          companyName

          shareHolding
          incorporationNumber
        }
        companyMortgages {
          id
          landing
          borrowing
          mortgage
          charging
          createdBy
        }
        companyPaidUpCapitals {
          id
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
        companyAuthorizedCapitals {
          id
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
      }
      errors {
        message
        field
      }
    }
  }
`;
export const DELETE_COMPANY_PROFILE = gql`
  mutation deleteCompanyProfile($id: String!) {
    deleteCompanyProfile(data: { id: $id }) {
      count
    }
  }
`;
export const FIND_COMPANY_PROFILE_BY_USER_ID = gql`
  query findCompanyProfileByUserId($id: ID!, $isIntermedialUser: Boolean!) {
    findCompanyProfileByUserId(id: $id, isIntermedialUser: $isIntermedialUser) {
      companyProfile {
        isDraft
        userId
        companyId
        name
        cuinNumber
        kind
        sectorialClassification
        companyObjects
        incorporationDate
        commencementOfBusiness
        holdingCompany
        address
        city
        town
        district
        postalCode
        province
        phone
        mobile
        fax

        isActive
        email
        websiteUrl
        perFaceValue
        paidUpCapital
        authorizedCapitalShare
        authorizedCapitalTotal
        logoUrl
        companyHistory {
          name
          cuinNumber
          kind
          sectorialClassification
          companyObjects
          incorporationDate
          commencementOfBusiness
          holdingCompany
          address
          city
          town
          district
          postalCode
          province
          phone
          mobile
          fax
          isActive
          email
          websiteUrl
          updatedAt
        }
        companySubsidiaries {
          id
          companyName

          shareHolding
          incorporationNumber
        }
        companyMortgages {
          id
          landing
          borrowing
          mortgage
          charging
          mortgageChangeType
          createdBy
        }
        companyPaidUpCapitals {
          id
          date

          numOfShares
          totalAmount
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
        companyAuthorizedCapitals {
          id
          date
          numOfShares
          totalAmount
          shareType
          shareSubType
          issuePrice
          secpRefId
        }
        updatedAt
      }
      errors {
        message
        field
      }
    }
  }
`;
export const FIND_COMPANY_PROFILE_BY_ID = gql`
  query findCompanyProfileById($id: ID!) {
    findCompanyProfileById(id: $id) {
      companyProfile {
        companyId
        name
        cuinNumber
        email
        mobile
        kind
        name
        address
        city
        province
        incorporationDate
        perFaceValue
        logoUrl
      }
      errors {
        message
        field
      }
    }
  }
`;
export const GET_ALL_COMPANY_PROFILES = gql`
  query getAllCompanyProfiles($userId: String!) {
    getAllCompanyProfiles(filterBy: { userId: $userId }) {
      draftCompanyProfileConnection {
        edges {
          node {
            companyId
            name
            mobile
            city
            incorporationDate
            cuinNumber
            kind
            userId
            isDraft
            isActive
          }
        }
      }
      companyProfileConnection {
        edges {
          node {
            companyId
            name
            mobile
            city
            incorporationDate
            cuinNumber
            kind
            userId
            isDraft
            dashboardData {
              draftMember
              draftOfficer
              actualOfficer
              actualMember
              pendingMom
              completedMom
              pendingReturn
              completedReturn
              pendingMeeting
              completedMeetings
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_ADMIN_COMPANY_PROFILES = gql`
  query findCompanyProfiles(
    $profile: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    findCompanyProfiles(
      q: $profile
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          companyId
          name
          email
          mobile
          cuinNumber
          kind
          isActive
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ALL_AUTHORIZED_CAPITAL_hISTORY = gql`
  query findHistoryOfAuthorizedCapital(
    $authorizedCapitalId: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    findHistoryOfAuthorizedCapital(
      filterBy: { authorizedCapitalId: $authorizedCapitalId }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          numOfShares
          date
          id
          authorizedCapitalId
          updatedBy
          secpRefId
          shareType
          shareSubType
          numOfShares
          updatedAt
          totalAmount
          issuePrice
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ALL_PAID_UP_CAPITAL_hISTORY = gql`
  query findHistoryOfPaidUpCapital(
    $companyId: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    findHistoryOfPaidUpCapital(
      filterBy: { companyId: $companyId }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          numOfShares
          date
          id
          date
          shareType
          shareSubType
          secpRefId
          issuePrice
          parValue
          totalAmount
          companyId
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      sum
    }
  }
`;
