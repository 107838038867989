import { createSlice } from '@reduxjs/toolkit';
import { HelpSectionType } from './types';

const initialState: HelpSectionType = {
  selectedHelpSectionId: '',
  helpSectionView: false,
};
const helpSectionSlice = createSlice({
  name: 'helpSection',
  initialState,
  reducers: {
    setSelectedHelpSectionId: (state, { payload }) => {
      state.selectedHelpSectionId = payload;
    },
    setHelpSectionViewScreen: (state, { payload }) => {
      state.helpSectionView = payload;
    },
  },
});

export const {
  setSelectedHelpSectionId,
  setHelpSectionViewScreen,
} = helpSectionSlice.actions;

export default helpSectionSlice.reducer;
