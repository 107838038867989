import React, { FC, useEffect, useState } from 'react';
import './companyprofile.scss';
import { company, clocation, profileIcon } from '../../../assets/images';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CREATE_COMPANY_PROFILE_DRAFT,
  DELETE_COMPANY_PROFILE,
  FIND_COMPANY_PROFILE_BY_USER_ID,
} from '~/Store/graphQL/CompanyProfile';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  setCompanyID,
  setCompanyAddScreen,
  setCompanyViewScreen,
} from '~/Store/reducers/companyProfile';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import moment from 'moment';
import GetCompanyKind from '~/helpers/CompanyProfile/getCompanyKind';
import jwt_decode from 'jwt-decode';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { Tooltip } from 'reactstrap';
import GetSectorialClassification from '~/helpers/CompanyProfile/getSectorialClassification';
export const CompanyProfile: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userId, userType, token } = useAppSelector(
    state => state.layoutReducer
  );
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [companyProfileData, setCompanyProfile] = useState<any>();
  //states here
  const { user } = jwt_decode(token) as any;
  const [updateProfileToolTipIsOpen, setUpdateProfileToolTipIsOpen] =
    useState(false);
  useEffect(() => {
    //breadcrumb
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Company Profile',
        },
      ])
    );
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
      });
    }
  }, []);

  //delete company profile
  const [
    deleteCompanyProfile,
    {
      loading: loadingDeleteCompanyProfile,
      error: errorDeleteCompanyProfile,
      data: dataDeleteCompanyProfile,
    },
  ] = useMutation<any>(DELETE_COMPANY_PROFILE);

  //get company profile by user id
  const [
    draftCreateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(CREATE_COMPANY_PROFILE_DRAFT);
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      dispatch(
        setCompanyID(
          dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
            .companyId
        )
      );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );
      setCompanyProfile(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
      );
      dispatch(setCompanyAddScreen(false));
    } else if (errorCompanyProfileByUserId) {
      dispatch(setCompanyAddScreen(true));
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
      dispatch(
        setCompanyID(
          dataCompanyDraft.draftCreateProfileCompanyDraft.draftCompanyProfile
            .companyId
        )
      );
      navigate('/companyProfile/companyInformation');
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);
  return (
    <React.Fragment>
      <div id="" className="w-100  h-100 no-padding-main max-content-height">
        <div className="d-flex  w-100 max-content-height">
          <div className="profileContainer w-100">
            <div className="topCompany C-headhight">
              <div className="d-flex align-self-end justify-content-between w-100">
                <div className="d-flex">
                  <div>
                    <img
                      className="companyDP"
                      src={
                        companyProfileData?.logoUrl
                          ? companyProfileData?.logoUrl
                          : profileIcon
                      }
                    ></img>
                  </div>
                  <div className="mx-2">
                    <div className="row">
                      <div className="col-10">
                        {/* <h5 className="mt-1">Hey {user?.firstName}!</h5> */}
                        {/* <p className="text-light">{user?.email}</p> */}
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
                <div
                  className={`pt-4 ${
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_PROFILE,
                        PermissionCategoryEnum.EDIT
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                      ? 'cursor-not-allowed'
                      : ''
                  }`}
                  onMouseEnter={() => setUpdateProfileToolTipIsOpen(true)}
                  onMouseLeave={() => setUpdateProfileToolTipIsOpen(false)}
                >
                  <button
                    type="button"
                    id="updateProfile"
                    disabled={
                      loadingCompanyDraft ||
                      !(
                        GetPermissionsOfUser(
                          selectedUserPermissions,
                          ModuleEnum.COMPANY_PROFILE,
                          PermissionCategoryEnum.EDIT
                        ) || userType === UserTypeEnum.COMPANY_ADMIN
                      )
                    }
                    className="btn btn-primary updateBt d-flex align-items-end align-self-end me-4 mt-2"
                    onClick={() => {
                      dispatch(setCompanyViewScreen(false));
                      navigate('/companyProfile/companyInformation');
                    }}
                  >
                    {loadingCompanyDraft && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Update Profile
                  </button>
                  {!(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_PROFILE,
                      PermissionCategoryEnum.EDIT
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  ) ? (
                    <Tooltip
                      placement="top"
                      isOpen={updateProfileToolTipIsOpen}
                      target="updateProfile"
                    >
                      You don't have permission for this module
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row mainbox ">
              {/* <div className="Cleft col-6 "></div> */}
              <div className="Cright col-12 ">
                <div className="p-2 ms-4 d-flex gap-3 align-items-center ">
                  <div>
                    <img className="companyIcon me-1 my-3" src={company}></img>
                  </div>
                  <div>
                    <h6 className="greyLabel align-self-center">
                      Company Name
                    </h6>
                    <h4 className="text-primary text-break">
                      {companyProfileData?.name
                        ? companyProfileData?.name
                        : 'N/A'}
                    </h4>
                  </div>
                </div>
                <div className="row ms-0 border-top C-Rowhight background-dim-grey">
                  <div className="col-3   border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Company Kind</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.kind
                        ? GetCompanyKind(companyProfileData?.kind)
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3  border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">CUIN No</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.cuinNumber
                        ? companyProfileData?.cuinNumber
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3  border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Email Address</h6>
                    <p className="sublabel text-break">
                      {' '}
                      {companyProfileData?.email
                        ? companyProfileData?.email
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3   justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Mobile No</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.mobile
                        ? companyProfileData?.mobile
                        : 'N/A'}
                    </p>
                  </div>
                  {/* <div className="col-6 pt-2">
                    <h6 className="">
                      {companyProfileData?.kind
                        ? GetCompanyKind(companyProfileData?.kind)
                        : 'N/A'}
                    </h6>
                  </div> */}
                </div>
                <div className="row ms-0 border-top C-Rowhight  ">
                  <div className="col-3  border-end justify-content-start align-items-center text-start p-3 ">
                    <h6 className="greyLabel bg-succes ">Incorporation Date</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.incorporationDate
                        ? moment(companyProfileData?.incorporationDate).format(
                            'MMM DD, yyyy'
                          )
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3  border-end justify-content-start align-items-center  text-start p-3">
                    <h6 className="greyLabel bg-succes ">Telephone</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.mobile
                        ? companyProfileData?.mobile
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3   border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Website URL</h6>
                    <p className="sublabel text-break">
                      {' '}
                      {companyProfileData?.websiteUrl
                        ? companyProfileData?.websiteUrl
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3   justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Location</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.city && companyProfileData?.province
                        ? companyProfileData?.city +
                          ',' +
                          companyProfileData?.province
                        : 'N/A'}
                    </p>
                  </div>
                </div>
                <div className="row ms-0 border-top C-Rowhight background-dim-grey ">
                  <div className="col-3 border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">
                      Sectorial Classification
                    </h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.sectorialClassification
                        ? GetSectorialClassification(
                            companyProfileData?.sectorialClassification
                          )
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3  border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Company Objects</h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.companyObjects
                        ? companyProfileData?.companyObjects
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3 border-end justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Fax</h6>
                    <p className="sublabel text-break">
                      {' '}
                      {companyProfileData?.fax
                        ? companyProfileData?.fax
                        : 'N/A'}
                    </p>
                  </div>
                  <div className="col-3  justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">
                      Commencement of Business
                    </h6>
                    <p className="sublabel">
                      {' '}
                      {companyProfileData?.commencementOfBusiness
                        ? moment(
                            companyProfileData?.commencementOfBusiness
                          ).format('MMM DD, yyyy')
                        : 'N/A'}
                    </p>
                  </div>
                </div>
                <div className="row ms-0 border-top C-Rowhight ">
                  <div className="col-12 justify-content-start align-items-center text-start p-3">
                    <h6 className="greyLabel bg-succes ">Address</h6>
                    <p className="sublabel">
                      {companyProfileData?.address
                        ? companyProfileData?.address
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
