import { ModuleEnum } from '../../commons/enums';
const GetModules = (module: string) => {
  if (module === ModuleEnum.COMPANY_PROFILE) {
    return 'Company Profile';
  } else if (module === ModuleEnum.COMPANY_OFFICERS) {
    return 'Company Officers';
  } else if (module === ModuleEnum.COMPANY_MEMBERS) {
    return 'Company Members';
  } else if (module === ModuleEnum.SHARE_MANAGEMENT) {
    return 'Share Management';
  } else if (module === ModuleEnum.REGISTER) {
    return 'Registers';
  } else if (module === ModuleEnum.MEETING) {
    return 'Meeting';
  } else if (module === ModuleEnum.NOTIFICATION) {
    return 'Notifications';
  } else if (module === ModuleEnum.COMMITTEE) {
    return 'Committee';
  } else if (module === ModuleEnum.LEGAL_DOCUMENTS) {
    return 'Legal Documents';
  } else if (module === ModuleEnum.FINANCIAL_YEAR) {
    return 'Financial Year';
  } else if (module === ModuleEnum.BANK_ACCOUNTS) {
    return 'Bank Account';
  } else if (module === ModuleEnum.SYSTEM_USER) {
    return 'System User';
  } else if (module === ModuleEnum.SYSTEM_USER_GROUPS) {
    return 'System User Groups';
  }
};

export default GetModules;
