import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { setSelectedUserGroupId } from '~/Store/reducers/userGroup';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './addUserGroup.scss';
import {
  CREATE_USER_GROUP,
  FIND_USER_GROUP,
  UPDATE_USER_GROUP,
} from '~/Store/graphQL/UserGroup';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';

import { ACCESS_PRIVILAGES } from '~/commons/enums/AccessPrivileges';

import {
  ASSIGN_PERMISSION_TO_USERGROUP,
  UPDATE_PERMISSION_OF_USERGROUP,
} from '~/Store/graphQL/UserGroup';
import getModules from '~/helpers/Permissions/getModules';
import { control, people } from '~/assets/images';

export const AddUserGroup: FC = () => {
  const dispatch = useAppDispatch();
  const [userGroupData, setUserGroupData] = useState<any>();

  const navigate = useNavigate();
  const { selectedUserGroupId } = useAppSelector(
    state => state.userGroupReducer
  );

  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [temp, setTemp] = useState<any>([]);
  const data = ACCESS_PRIVILAGES;

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SYSTEM_USER_GROUPS,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SYSTEM_USER_GROUPS,
    },
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });

  // -----------//

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      name: userGroupData?.groupName ? userGroupData?.groupName : '',
      description: userGroupData?.description ? userGroupData?.description : '',
      // --- //
      userGroup: selectedUserGroupId ? selectedUserGroupId : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter name').nullable(),
      description: Yup.string().required('Please enter description').nullable(),
      // --  //
    }),
    onSubmit: (values: any) => {
      if (selectedUserGroupId == null) {
        createUserGroup({
          variables: {
            companyId: companyID,
            name: values.name,
            description: values.description,
            permissionArr: temp,
          },
        })
          .then(res => {
            if (res.data.createUserGroup.userGroup) {
              dispatch(setToastSuccess('User Group Added'));
              navigate('/usergroup');
            }
          })
          .catch(dispatch(setToastError(errorCreateUserGroup)));
      } else {
        updateUserGroup({
          variables: {
            Id: selectedUserGroupId,
            name: values.name,
            description: values.description,
            permissionArr: temp,
          },
        })
          .then(res => {
            if (res.data.updateUserGroup.userGroup) {
              dispatch(setToastSuccess('User Group Updated'));
              navigate('/usergroup');
            }
          })
          .catch(dispatch(setToastError(errorUpdateUserGroup)));
      }
    },
  });

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/usergroup',
          title: 'System User Groups',
        },
        {
          url: location.pathname,
          title: 'Add User Groups',
        },
      ])
    );

    setTemp(data);
  }, []);

  useEffect(() => {
    if (selectedUserGroupId) {
      findUserGroup();
    }
  }, [selectedUserGroupId]);

  const [
    updateUserGroup,
    {
      loading: loadingUpdateUserGroup,
      error: errorUpdateUserGroup,
      data: dataUpdateUserGroup,
    },
  ] = useMutation<any>(UPDATE_USER_GROUP, {});

  const [
    createUserGroup,
    {
      loading: loadingCreateUserGroup,
      error: errorCreateUserGroup,
      data: dataCreateUserGroup,
    },
  ] = useMutation<any>(CREATE_USER_GROUP, {});

  const [
    findUserGroup,
    {
      loading: loadingFindUserGroup,
      error: errorFIndUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUP, {
    variables: {
      id: selectedUserGroupId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataFindUserGroup) {
      setUserGroupData(dataFindUserGroup?.userGroup);
      let tempted =
        dataFindUserGroup?.userGroup?.permissions.length == 0
          ? data
          : [...dataFindUserGroup?.userGroup?.permissions];
      tempted?.forEach((element: any) => {
        delete element['__typename'];
        delete element['groupName'];
      });

      setTemp(tempted);
    }
    if (errorFIndUserGroup) {
      dispatch(setToastError(errorFIndUserGroup));
    }
  }, [dataFindUserGroup, errorFIndUserGroup]);

  return (
    <React.Fragment>
      <div className="w-100 d-flex parent">
        <div className="w-100 background-white">
          <div
            id="mainComponentHeading"
            className="justify-content-end  background-primary-medium"
          >
            <div className="">
              <div className="d-flex gap-3 position-relative end-0 mx-4">
                <button
                  type="button"
                  className="btn primaryButton d-flex align-items-center my-auto fw-bold height-100"
                  onClick={() => {
                    navigate('/usergroup');
                    dispatch(setSelectedUserGroupId(null));
                  }}
                >
                  Cancel
                </button>
                {true ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto fw-bold"
                    disabled={loadingCreateUserGroup}
                    onClick={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {loadingCreateUserGroup && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          <div id="mainComponent" className="m-0 scroll-content">
            {loadingFindUserGroup ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-75">
                <i className="bx bx-loader-circle bx-spin bx-lg"></i>
              </div>
            ) : (
              <>
                <div className="w-100 border ">
                  <div className=" justify-content-between mt-2 ">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center max-content ms-3 mt-4">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={people}
                      ></img>
                      <div className="fs-5 font-weight-600 ">
                        User Group Info
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="d-flex w-50 action-items ps-3 pt-2 ">
                        <label htmlFor="groupName">Title</label>
                        {validation.touched.name && validation.errors.name ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.name}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-50 ms-3 py-3 `}
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        value={validation.values.name}
                        onChange={validation.handleChange}
                      />

                      <div className="w-100 h-100 backgroung-light-grey p-3 mt-4 ">
                        <div className="d-flex w-300 pt-3 action-items ">
                          <label htmlFor="groupDescription">Description</label>
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.description}
                            </p>
                          ) : null}
                        </div>
                        <textarea
                          style={{
                            height: 150,
                            maxHeight: 200,
                          }}
                          className={`form-control w-100 p-3`}
                          id="description"
                          name="description"
                          placeholder="Enter description"
                          value={validation.values.description}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 border p-3 pt-0">
                  <>
                    <>
                      <div className="table-responsive">
                        <div className="d-flex w-300  mb-3">
                          <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center max-content ms-1 mt-4">
                            <img
                              className="height-30 me-2 my-1 bg-transparent align-items-center"
                              src={control}
                            ></img>
                            <div className="fs-5 font-weight-600">
                              Access Privileges
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th className="col-1 text-white">Module</th>

                                <th className="col-1 text-white">View</th>
                                <th className="col-1 text-white">Edit</th>
                                <th className="col-1 text-white">Delete</th>
                                {/* <th className="col-1 text-white">Email</th> */}
                                <th className="col-1 text-white">
                                  Generate Documents
                                </th>
                                <th className="col-1 text-white">Select All</th>
                              </tr>
                            </thead>
                            <tbody>
                              {temp?.map((val: any, index: number) => (
                                <tr key={`${index}`}>
                                  <td>{getModules(val.moduleName)}</td>
                                  <td>
                                    <input
                                      className="me-3 form-check-input c-me"
                                      type="checkbox"
                                      id={'view'}
                                      name={'view'}
                                      checked={val.view}
                                      disabled={
                                        val.edit ||
                                        val.delete ||
                                        val.generateDocument
                                      }
                                      onChange={e => {
                                        const temp2 = JSON.parse(
                                          JSON.stringify(temp)
                                        );
                                        temp2[index].view = e.target.checked;
                                        setTemp(temp2);
                                      }}
                                    ></input>
                                  </td>
                                  <td>
                                    <input
                                      className="me-3 form-check-input c-me"
                                      type="checkbox"
                                      id={'edit'}
                                      name={'edit'}
                                      checked={val.edit}
                                      disabled={
                                        val.moduleName ===
                                          ModuleEnum.COMPANY_PROFILE &&
                                        val.delete
                                      }
                                      onChange={e => {
                                        const temp2 = JSON.parse(
                                          JSON.stringify(temp)
                                        );
                                        if (e.target.checked === true) {
                                          temp2[index].view = e.target.checked;
                                        }
                                        temp2[index].edit = e.target.checked;
                                        setTemp(temp2);
                                      }}
                                    ></input>
                                  </td>
                                  <td>
                                    <input
                                      className="me-3 form-check-input c-me"
                                      type="checkbox"
                                      id={'delete'}
                                      name={'delete'}
                                      checked={val.delete}
                                      onChange={e => {
                                        const temp2 = JSON.parse(
                                          JSON.stringify(temp)
                                        );
                                        if (e.target.checked === true) {
                                          temp2[index].view = e.target.checked;
                                          if (
                                            val.moduleName ===
                                            ModuleEnum.COMPANY_PROFILE
                                          ) {
                                            temp2[index].edit =
                                              e.target.checked;
                                          }
                                        }
                                        temp2[index].delete = e.target.checked;
                                        setTemp(temp2);
                                      }}
                                    ></input>
                                  </td>
                                  {/* <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'email'}
                                  name={'email'}
                                  checked={val.email}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].email = e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td> */}
                                  <td>
                                    <input
                                      className="me-3 form-check-input c-me"
                                      type="checkbox"
                                      id={'generateDocument'}
                                      name={'generateDocument'}
                                      checked={val.generateDocument}
                                      onChange={e => {
                                        const temp2 = JSON.parse(
                                          JSON.stringify(temp)
                                        );
                                        if (e.target.checked === true) {
                                          temp2[index].view = e.target.checked;
                                        }
                                        temp2[index].generateDocument =
                                          e.target.checked;
                                        setTemp(temp2);
                                      }}
                                    ></input>
                                  </td>
                                  <td>
                                    <input
                                      className="me-3 form-check-input c-me"
                                      type="checkbox"
                                      id={'selectAll'}
                                      name={'selectAll'}
                                      checked={
                                        val.view &&
                                        val.edit &&
                                        val.delete &&
                                        val.generateDocument
                                      }
                                      onChange={e => {
                                        const temp2 = JSON.parse(
                                          JSON.stringify(temp)
                                        );
                                        temp2[index].view = e.target.checked;
                                        temp2[index].edit = e.target.checked;
                                        temp2[index].delete = e.target.checked;
                                        temp2[index].generateDocument =
                                          e.target.checked;
                                        setTemp(temp2);
                                      }}
                                    ></input>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  </>
                </div>
              </>
            )}
          </div>
        </div>
        {!loadingReliventDocumnets &&
          !loadingRelevantProcesses &&
          !loadingGetHelp &&
          (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
            <RightSideBar
              documents={
                dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
              }
              processes={
                dataRelevantProcesses?.getRelevantProcessesByEvent
                  ?.eventRelevantProcesses
              }
              help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
            />
          )}
      </div>
    </React.Fragment>
  );
};
