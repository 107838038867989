import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { ModuleEnum, RelationshipEnum } from '../../../../commons/enums';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  CREATE_COMPANY_MEMBER_FROM_DRAFT,
  FIND_COMPANY_MEMBER_BY_ID,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';

import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import 'react-phone-input-2/lib/style.css';

import { v4 as uuidv4 } from 'uuid';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { useSearchParams } from 'react-router-dom';
import {
  AddressIcon,
  AlertIcon,
  calendar,
  ContactInfoIcon,
  idCard,
  idCard2,
  PersonalInfoLogo,
} from '~/assets/images';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { valid } from 'semver';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';

export const NomineeInformation: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [membersData, setMembersData] = useState<any>();
  const [countries, setCountries] = useState<ICountry>();
  const [states, setStates] = useState<IState>();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [cities, setCities] = useState<ICity>();
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [selectedState, setSelectedState] = useState<number>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  let nominee = [
    {
      id: '',
      firstName: '',
      middleName: '',
      lastName: '',
      nominationReceiptDate: '',
      relativeFirstName: '',
      relativeMiddleName: '',
      relativeLastName: '',
      relationship: '',
      nationality: '',
      cnic: '',
      passportNumber: '',
      residentialAddress: '',
      phone: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      trusteeName: '',
      dobMinor: '',
      files: '',
    },
  ];
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Nominee Information',
        },
      ])
    );
  }, []);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedState, selectedCountry]);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);

  const [
    createCompanyMemberfromDraft,
    {
      loading: loadingCreateCompanyMemberfromDraft,
      error: errorCreateCompanyMemberfromDraft,
      data: dataCreateCompanyMemberfromDraft,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_FROM_DRAFT);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);

  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
      if (
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
          ?.companyNomineeInfo.length > 0
      ) {
        let country: ICountry = countries.find(
          (x: ICountry) =>
            x.name ===
            dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
              .companyNomineeInfo[0].country
        );
        if (country) {
          setSelectedCountry(country.isoCode);
        }
      }
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      dateOfReceiptOfNomination:
        membersData &&
        membersData.companyNomineeInfo[0] &&
        membersData.companyNomineeInfo[0].nominationReceiptDate &&
        membersData.companyNomineeInfo.length > 0
          ? new Date(membersData.companyNomineeInfo[0].nominationReceiptDate)
              .toISOString()
              .slice(0, 10)
          : new Date().toISOString().slice(0, 10),
      name: {
        firstName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].firstName
            : '',
        middleName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].middleName
            : '',
        lastName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].lastName
            : '',
      },
      fatherSpouseName: {
        firstName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].relativeFirstName
            : '',
        middleName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].relativeMiddleName
            : '',
        lastName:
          membersData && membersData.companyNomineeInfo.length > 0
            ? membersData.companyNomineeInfo[0].relativeLastName
            : '',
      },
      relationship:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0].relationship
          : '',
      nationality:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0].nationality
          : '',
      cnicOrNICOP:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0].cnic
          : '',
      cnicExpiry: membersData?.cnicExpiry
        ? new Date(membersData.cnicExpiry).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      passportNumber:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0].passportNumber
          : '',

      // ---------

      residentialAddress:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.residentialAddress
          : '',
      city:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.city
          : '',
      province:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.province
          : '',
      country:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.country
          : '',
      postalCode:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.postalCode
          : '',
      mobile:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.phone
          : '',

      willAttachment:
        membersData &&
        membersData.companyNomineeInfo.length > 0 &&
        membersData.companyNomineeInfo[0].url
          ? JSON.parse(membersData.companyNomineeInfo[0].url)
          : '',

      // ----

      guardianName:
        membersData && membersData.companyNomineeInfo.length > 0
          ? membersData.companyNomineeInfo[0]?.trusteeName
          : '',
      dobMinor:
        membersData &&
        membersData.companyNomineeInfo[0] &&
        membersData.companyNomineeInfo[0].dobMinor &&
        membersData.companyNomineeInfo.length > 0
          ? new Date(membersData.companyNomineeInfo[0].dobMinor)
              .toISOString()
              .slice(0, 10)
          : new Date().toISOString().slice(0, 10),
    },
    validationSchema: Yup.object({
      dateOfReceiptOfNomination: Yup.string()
        .required('Please selectdate')
        .nullable(),
      name: Yup.object()
        .shape({
          firstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          lastName: Yup.string().required('Please enter last name').nullable(),
        })
        .nullable(),
      fatherSpouseName: Yup.object()
        .shape({
          firstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          lastName: Yup.string().required('Please enter last name').nullable(),
        })
        .nullable(),

      relationship: Yup.string()
        .required('Please select relationship')
        .nullable(),
      nationality: Yup.string().required('Please enter nationality').nullable(),
      cnicOrNICOP: Yup.string()
        .matches(
          /^\d{5}-\d{7}-\d$/,
          'CNIC must be in the format xxxxx-xxxxxxx-x'
        )
        .required('Please enter CNIC number')
        .nullable(),
      cnicExpiry: Yup.string()
        .required('Please select date of expiry of cnic')
        .nullable(),
      // ------------ //
      residentialAddress: Yup.string()
        .required('Please enter residential address')
        .nullable(),
      city: Yup.string().required('Please enter city').nullable(),
      province: Yup.string().required('Please enter province').nullable(),
      country: Yup.string().required('Please enter country').nullable(),
      postalCode: Yup.string().required('Please enter postal code').nullable(),
      mobile: Yup.string()
        .required('Please enter phone number')
        .min(11, 'Telephone must contain atleast 11 characters')
        .nullable(),
      dobMinor: Yup.string().required('Please enter birth date').nullable(),
    }),
    onSubmit: (values: any) => {
      nominee[0].id =
        membersData &&
        membersData.companyNomineeInfo.length > 0 &&
        membersData.companyNomineeInfo[0]?.id
          ? membersData.companyNomineeInfo[0]?.id
          : uuidv4();
      nominee[0].firstName = values.name.firstName;
      nominee[0].middleName = values.name.middleName;
      nominee[0].lastName = values.name.lastName;
      nominee[0].nominationReceiptDate = values.dateOfReceiptOfNomination;
      nominee[0].relativeFirstName = values.fatherSpouseName.firstName;
      nominee[0].relativeMiddleName = values.fatherSpouseName.middleName;
      nominee[0].relativeLastName = values.fatherSpouseName.lastName;
      nominee[0].relationship = values.relationship;
      nominee[0].nationality = values.nationality;
      nominee[0].cnic = values.cnicOrNICOP;
      nominee[0].passportNumber = values.passportNumber;
      nominee[0].residentialAddress = values.residentialAddress;
      nominee[0].city = values.city;
      nominee[0].province = values.province;
      nominee[0].country = values.country;
      nominee[0].phone = values.mobile.toString();
      nominee[0].postalCode = values.postalCode;
      nominee[0].trusteeName = values.guardianName;
      nominee[0].dobMinor = values.dobMinor;
      nominee[0].files = values.willAttachment;
      if (isDraft) {
        if (selectedMemberId) {
          findCompanyMemberById({
            variables: {
              id: selectedMemberId,
            },
            fetchPolicy: 'no-cache',
          }).then(res => {
            if (res.data.findOneCompanyMember.companyMember) {
              let temp = JSON.parse(
                JSON.stringify(res.data.findOneCompanyMember.companyMember)
              );
              temp.jointShareHolderInfo.forEach((element: any) => {
                delete element['__typename'];
              });
              temp.companyNomineeInfo.forEach((element: any) => {
                delete element['__typename'];
              });
              createCompanyMemberfromDraft({
                variables: {
                  secpRefId: temp.secpRefId,
                  firstName: temp.firstName,
                  middleName: temp.middleName,
                  lastName: temp.lastName,
                  status: temp.status,
                  type: temp.type,
                  shareType: temp.shareType,
                  shareSubType: temp.shareSubType,
                  folioNumber: temp.folioNumber,
                  category: temp.category,
                  relativeFirstName: temp.relativeFirstName,
                  relativeMiddleName: temp.relativeMiddleName,
                  relativeLastName: temp.relativeLastName,
                  relationship: temp.relationship,
                  nationality: temp.nationality,
                  cnic: temp.cnic,
                  passportNumber: temp.passportNumber,
                  ntn: temp.ntn,
                  email: temp.email,
                  occupation: temp.occupation,
                  companyName: temp.companyName,
                  legalPersonName: temp.legalPersonName,
                  companyCuin: temp.companyCuin,
                  numOfShares: temp.numOfShares,
                  holdingCompany: temp.holdingCompany,
                  subsidiaries: temp.subsidiaries,
                  residentialAddress: temp.residentialAddress,
                  city: temp.city,
                  province: temp.province,
                  country: temp.country,
                  phone: temp.phone,
                  postalCode: temp.postalCode,
                  interestHeld: temp.interestHeld,
                  dateOfMembership: temp.dateOfMembership,
                  amountPaid: temp.amountPaid,
                  action: temp.action,
                  ceaseDate: temp.ceaseDate,
                  cessationReason: temp.cessationReason,
                  cdcAccountNumber: temp.cdcAccountNumber,
                  otherInstructions: temp.otherInstructions,
                  instructions: temp.instructions,
                  companyId: companyID,
                  companyNomineeInfo: nominee,
                  jointShareHolderInfo: temp.jointShareHolderInfo,
                },
              })
                .then(res => {
                  if (res.data.create_companyMemberFromDraft.companyMember) {
                    if (isNewFolio) {
                      navigate(
                        '/shares/add?newFolio=' +
                          res.data.create_companyMemberFromDraft.companyMember
                            ?.folioNo
                      );
                    } else if (isNewFolioForTransfer) {
                      navigate(
                        '/shares/add/transfer?newFolio=' +
                          res.data.create_companyMemberFromDraft.companyMember
                            ?.folioNo
                      );
                    } else {
                      navigate('/companyMembers');
                    }
                  }
                })
                .catch(err => {
                  dispatch(setToastError(err));
                });
            }
          });
        } else {
          navigate('/companyMembers');
        }
      } else {
        updateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            companyNomineeInfo: nominee,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyMember?.companyMember) {
              dispatch(setToastSuccess('Company Member updated!'));
              if (isNewFolio) {
                navigate(`/companyMembers/nomineeAddress?isNewFolio=true`);
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/nomineeAddress?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/nomineeAddress?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingRelevantProcesses ||
      loadingReliventDocumnets ||
      loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 bg-white">
            <div
              id="mainComponentHeading"
              className="background-primary-medium"
            >
              <span className="ms-auto">
                <div className="d-flex justify-content-end gap-3  end-0 mx-4">
                  <button
                    type="button"
                    className="btn fw-bold dangerButton d-flex align-items-center "
                    disabled={loadingDraftUpdateCompanyMember}
                    onMouseDown={() => {
                      if (isNewFolio) {
                        navigate('/shares/add');
                      } else if (isNewFolioForTransfer) {
                        navigate('/shares/add/transfer');
                      } else {
                        navigate('/companyMembers');
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn fw-bold primaryDismissButton d-flex align-items-center "
                    disabled={loadingDraftUpdateCompanyMember}
                    onMouseDown={() => {
                      if (isNewFolio) {
                        navigate(
                          '/companyMembers/jointShareholderInformation?isNewFolio=true'
                        );
                      } else if (isNewFolioForTransfer) {
                        navigate(
                          '/companyMembers/jointShareholderInformation?isNewFolioForTransfer=true'
                        );
                      } else {
                        navigate('/companyMembers/jointShareholderInformation');
                      }
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn fw-bold successButton d-flex align-items-center "
                    disabled={loadingDraftUpdateCompanyMember}
                    onMouseDown={() => {
                      if (memberView) {
                        navigate('/companyMembers');
                      } else {
                        if (isDraft) {
                          ConfirmationDialogue.fire({
                            title: 'Are You Sure?',
                            html: `<body><p>You are saving Company Member, you will not be able to change following fields later<p>
                            <div class="row">
                              <div class="col-4"></div>
                              <div class="col-4">
                                <ol>
                                  <li class="text-start">Email</li>
                                </ol>
                              </div>
                              <div class="col-4"></div>
                            </div>
                            <body/>`,
                            imageUrl: AlertIcon,
                            imageWidth: '100px',
                            showCancelButton: true,
                            confirmButtonText: 'Save',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true,
                          }).then(result => {
                            if (result.isConfirmed) {
                              validation.handleSubmit();
                            } else if (
                              /* Read more about handling dismissals below */
                              result.dismiss === Swal.DismissReason.cancel
                            ) {
                              console.log('Cancelled');
                            }
                          });
                        } else {
                          validation.handleSubmit();
                        }
                      }
                    }}
                  >
                    {(loadingDraftUpdateCompanyMember ||
                      loadingupdateCompanyMember) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    {memberView ? 'Close' : 'Save & Finish'}
                    <i className="bx bx-chevron-right ms-1" />
                  </button>
                </div>
              </span>
            </div>
            <div className="c-nav">
              <span>
                <ul className="nav nav-tabs w-100">
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link  cursor-pointer">
                        Company Member Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate(
                            '/companyMembers/primaryShareHolderInformation'
                          )
                        }
                        className="nav-link  cursor-pointer"
                      >
                        Company Member Information
                      </a>
                    )}
                  </li>
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link cursor-pointer">
                        Joint Shareholder Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate(
                            '/companyMembers/jointShareholderInformation'
                          )
                        }
                        className="nav-link cursor-pointer "
                      >
                        Joint Shareholder Information
                      </a>
                    )}
                  </li>
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link active cursor-pointer">
                        Nominee Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate('/companyMembers/nomineeInformation')
                        }
                        className="nav-link active cursor-pointer"
                      >
                        Nominee Information
                      </a>
                    )}
                  </li>
                  {!isDraft && (
                    <li className="nav-item border-end flex-grow-1 text-center">
                      <a
                        onClick={() =>
                          navigate('/companyMembers/memberHistory')
                        }
                        className="nav-link cursor-pointer"
                      >
                        Member History
                      </a>
                    </li>
                  )}
                </ul>
              </span>
            </div>
            <div id="mainComponentNew" className="scroll-content">
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={PersonalInfoLogo}
                      ></img>
                      <div className="font-weight-600">Personal Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex w-400">
                        <label htmlFor="name">Name*</label>
                        {validation.touched.name && validation.errors.name ? (
                          <p className="text-danger font-12 ms-2 mb-0">
                            {validation.errors.name.firstName
                              ? validation.errors.name.firstName
                              : validation.errors.name.lastName}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="name.firstName"
                          name="name.firstName"
                          placeholder="First Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.name?.firstName &&
                            validation.errors.name?.firstName &&
                            'is-invalid'
                          }`}
                          value={validation.values.name.firstName}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            {
                              !validation.errors.name?.firstName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.name.firstName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                        <input
                          type="text"
                          id="name.middleName"
                          name="name.middleName"
                          placeholder="Middle Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.name?.middleName &&
                            validation.errors.name?.middleName &&
                            'is-invalid'
                          }`}
                          value={validation.values.name.middleName}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.name?.middleName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.name?.middleName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                        <input
                          type="text"
                          id="name.lastName"
                          name="name.lastName"
                          placeholder="Last Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.name?.lastName &&
                            validation.errors.name?.lastName &&
                            'is-invalid'
                          }`}
                          value={validation.values.name.lastName}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.name?.lastName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.name?.lastName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex w-400">
                        <label htmlFor="name">Father/Spouse Name*</label>
                        {validation.touched.fatherSpouseName &&
                        validation.errors.fatherSpouseName ? (
                          <p className="text-danger font-12 ms-2 mb-0">
                            {validation.errors.fatherSpouseName.firstName
                              ? validation.errors.fatherSpouseName.firstName
                              : validation.errors.fatherSpouseName.lastName}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="fatherSpouseName.firstName"
                          name="fatherSpouseName.firstName"
                          placeholder="First Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.fatherSpouseName?.firstName &&
                            validation.errors.fatherSpouseName?.firstName &&
                            'is-invalid'
                          }`}
                          value={validation.values.fatherSpouseName.firstName}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.fatherSpouseName?.firstName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.fatherSpouseName?.firstName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                        <input
                          type="text"
                          id="fatherSpouseName.middleName"
                          name="fatherSpouseName.middleName"
                          placeholder="Middle Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.fatherSpouseName?.middleName &&
                            validation.errors.fatherSpouseName?.middleName &&
                            'is-invalid'
                          }`}
                          value={validation.values.fatherSpouseName.middleName}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.fatherSpouseName?.middleName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.fatherSpouseName
                                  ?.middleName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                        <input
                          type="text"
                          id="fatherSpouseName.lastName"
                          name="fatherSpouseName.lastName"
                          placeholder="Last Name"
                          className={`form-control form-sm mb-0 ${
                            validation.touched.fatherSpouseName?.lastName &&
                            validation.errors.fatherSpouseName?.lastName &&
                            'is-invalid'
                          }`}
                          value={validation.values.fatherSpouseName.lastName}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.fatherSpouseName?.lastName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.fatherSpouseName?.lastName &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="relationship">Relationship*</label>
                        {validation.touched.relationship &&
                        validation.errors.relationship ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.relationship}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.relationship &&
                            validation.errors.relationship &&
                            'is-invalid'
                          }`}
                          id="relationship"
                          name="relationship"
                          placeholder="Sectorial Classification"
                          value={validation.values.relationship}
                          disabled={memberView}
                          onBlur={e => {
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            validation.handleBlur(e);
                            {
                              !validation.errors.relationship &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.relationship &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={RelationshipEnum.SON_OF}>
                            Son of
                          </option>
                          <option value={RelationshipEnum.FATHER_OF}>
                            Father of
                          </option>
                          <option value={RelationshipEnum.SPOUSE_OF}>
                            Spouse of
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="">
                        <label htmlFor="dateOfReceiptOfNomination">
                          Date of Receipt of Nomination*
                        </label>
                        {validation.touched.dateOfReceiptOfNomination &&
                        validation.errors.dateOfReceiptOfNomination ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfReceiptOfNomination}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.dateOfReceiptOfNomination &&
                          validation.errors.dateOfReceiptOfNomination &&
                          'is-invalid'
                        }`}
                        id="dateOfReceiptOfNomination"
                        name="dateOfReceiptOfNomination"
                        placeholder="Date"
                        value={validation.values.dateOfReceiptOfNomination}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.dateOfReceiptOfNomination &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.dateOfReceiptOfNomination &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="">
                        <label htmlFor="dobMinor">Date of Birth of Minor</label>
                        {validation.touched.dobMinor &&
                        validation.errors.dobMinor ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dobMinor}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.dobMinor &&
                          validation.errors.dobMinor &&
                          'is-invalid'
                        }`}
                        id="dobMinor"
                        name="dobMinor"
                        max={new Date().toISOString().split('T')[0]}
                        placeholder="Date of Birth"
                        value={validation.values.dobMinor}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.dobMinor &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.dobMinor &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="nationality">Nationality*</label>
                        {validation.touched.nationality &&
                        validation.errors.nationality ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.nationality}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.nationality &&
                          validation.errors.nationality &&
                          'is-invalid'
                        }`}
                        id="nationality"
                        name="nationality"
                        placeholder="Nationality"
                        value={validation.values.nationality}
                        disabled={memberView}
                        onBlur={e => {
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          validation.handleBlur(e);
                          {
                            !validation.errors.nationality &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.nationality &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnicOrNICOP">CNIC No / NICOP*</label>
                        {validation.touched.cnicOrNICOP &&
                        validation.errors.cnicOrNICOP ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.cnicOrNICOP}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.cnicOrNICOP &&
                          validation.errors.cnicOrNICOP &&
                          'is-invalid'
                        }`}
                        id="cnicOrNICOP"
                        name="cnicOrNICOP"
                        placeholder="CNIC"
                        value={validation.values.cnicOrNICOP}
                        disabled={memberView}
                        onBlur={e => {
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          validation.handleBlur(e);
                          {
                            !validation.errors.cnicOrNICOP &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.cnicOrNICOP &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={e => {
                          validation.setFieldValue(
                            'cnicOrNICOP',
                            e.target.value.replace(/[^0-9]/gi, '-')
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnicExpiry">CNIC Expiry Date*</label>
                        {validation.touched.cnicExpiry &&
                        validation.errors.cnicExpiry ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.cnicExpiry}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.cnicExpiry &&
                          validation.errors.cnicExpiry &&
                          'is-invalid'
                        }`}
                        id="cnicExpiry"
                        name="cnicExpiry"
                        value={validation.values.cnicExpiry}
                        disabled={memberView}
                        onBlur={e => {
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          validation.handleBlur(e);
                          {
                            !validation.errors.cnicOrNICOP &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.cnicOrNICOP &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="passportNumber">
                          Passport No. (Foreign National)
                        </label>
                        {validation.touched.passportNumber &&
                        validation.errors.passportNumber ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.passportNumber}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.passportNumber &&
                          validation.errors.passportNumber &&
                          'is-invalid'
                        }`}
                        id="passportNumber"
                        name="passportNumber"
                        placeholder="Passport Number"
                        value={validation.values.passportNumber}
                        disabled={memberView}
                        onBlur={e => {
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          validation.handleBlur(e);
                          {
                            !validation.errors.passportNumber &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.passportNumber &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="guardianName">
                          Guardian / Trustee Name
                        </label>
                        {validation.touched.guardianName &&
                        validation.errors.guardianName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.guardianName}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.guardianName &&
                          validation.errors.guardianName &&
                          'is-invalid'
                        }`}
                        id="guardianName"
                        name="guardianName"
                        placeholder="Name"
                        value={validation.values.guardianName}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.guardianName &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.guardianName &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-1">
                    <div className="font-weight-600">Attachment</div>
                    {/* <div className="font-11">Documents</div> */}
                    <div
                      className={`attachedDocumentDiv cursor-pointer ${
                        memberView && 'icon-disabled'
                      }`}
                    >
                      <label htmlFor={'file-input'} className="cursor-pointer">
                        <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf,.docx"
                        onChangeCapture={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (e?.target?.files) {
                            const selectedFile = e?.target?.files[0];

                            // Perform file type validation
                            if (selectedFile) {
                              const allowedFileTypes = [
                                '.jpg',
                                '.jpeg',
                                '.png',
                                '.pdf',
                                '.docx',
                              ];
                              const fileExtension = selectedFile.name.substring(
                                selectedFile.name.lastIndexOf('.')
                              );
                              if (
                                !allowedFileTypes.includes(
                                  fileExtension.toLowerCase()
                                )
                              ) {
                                // File type not allowed
                                dispatch(
                                  setToastError(
                                    'Only JPG, PNG, PDF and DOCX files are allowed.'
                                  )
                                );
                                e.target.value = ''; // Clear the selected file
                                return;
                              }

                              // Perform further processing with the selected file
                              validation.setFieldValue(
                                'willAttachment',
                                e?.target?.files[0]
                              );
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-start align-items-center">
                    {validation.values.willAttachment ? (
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <ul className="d-flex justify-content-center align-items-center">
                            <li>
                              {validation.values.willAttachment
                                ? validation.values.willAttachment.name
                                : ''}
                            </li>
                            {!memberView ? (
                              <i
                                className="bx bx-x bx-sm mx-3 cursor-pointer"
                                onClick={e => {
                                  validation.setFieldValue(
                                    'willAttachment',
                                    ''
                                  );
                                }}
                              ></i>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-4"></div>
                  <div className="col-3"></div>
                </div>
              </div>
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={AddressIcon}
                      ></img>
                      <div className="font-weight-600">Address</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="country">Country*</label>
                        {validation.touched.country &&
                        validation.errors.country ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.country}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.country &&
                            validation.errors.country &&
                            'is-invalid'
                          }`}
                          id="country"
                          name="country"
                          placeholder="Country"
                          value={validation.values.country}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            {
                              !validation.errors.country &&
                                isDraft &&
                                validation.values.country &&
                                !loadingDraftUpdateCompanyMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            let country: ICountry = countries.find(
                              (x: ICountry) => x.name === e.currentTarget.value
                            );
                            if (country) {
                              setSelectedCountry(country.isoCode);
                            }
                            setSelectedState(undefined);
                            validation.setFieldValue('city', '');
                            validation.setFieldValue('province', '');
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {countries?.map((country: ICountry) => (
                            <option value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="province">Province*</label>
                        {validation.touched.province &&
                        validation.errors.province ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.province}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.province &&
                            validation.errors.province &&
                            'is-invalid'
                          }`}
                          id="province"
                          name="province"
                          placeholder="Province"
                          value={validation.values.province}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            {
                              !validation.errors.province &&
                                isDraft &&
                                validation.values.province &&
                                !loadingDraftUpdateCompanyMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            let state: IState = states.find(
                              (x: IState) => x.name === e.target.value
                            );
                            setSelectedCountry(selectedCountry);
                            if (state) {
                              setSelectedState(state.isoCode);
                            }
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {states?.map((state: IState) => (
                            <option value={state.name}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="city">City*</label>
                        {validation.touched.city && validation.errors.city ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.city}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.city &&
                            validation.errors.city &&
                            'is-invalid'
                          }`}
                          id="city"
                          name="city"
                          placeholder="City"
                          value={validation.values.city}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            nominee[0].id =
                              membersData.companyNomineeInfo[0]?.id;
                            nominee[0].nominationReceiptDate =
                              validation.values.dateOfReceiptOfNomination;
                            nominee[0].firstName =
                              validation.values.name.firstName;
                            nominee[0].middleName =
                              validation.values.name.middleName;
                            nominee[0].lastName =
                              validation.values.name.lastName;
                            nominee[0].relativeFirstName =
                              validation.values.fatherSpouseName.firstName;
                            nominee[0].relativeMiddleName =
                              validation.values.fatherSpouseName.middleName;
                            nominee[0].relativeLastName =
                              validation.values.fatherSpouseName.lastName;
                            nominee[0].relationship =
                              validation.values.relationship;
                            nominee[0].nationality =
                              validation.values.nationality;
                            nominee[0].cnic = validation.values.cnicOrNICOP;
                            nominee[0].passportNumber =
                              validation.values.passportNumber;
                            nominee[0].residentialAddress =
                              validation.values.residentialAddress;
                            nominee[0].city = validation.values.city;
                            nominee[0].province = validation.values.province;
                            nominee[0].country = validation.values.country;
                            nominee[0].phone =
                              validation.values.mobile.toString();
                            nominee[0].postalCode =
                              validation.values.postalCode;
                            nominee[0].trusteeName =
                              validation.values.guardianName;
                            nominee[0].dobMinor = validation.values.dobMinor;
                            nominee[0].files = validation.values.willAttachment;
                            {
                              !validation.errors.city &&
                                isDraft &&
                                validation.values.city &&
                                !loadingDraftUpdateCompanyMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    companyNomineeInfo: nominee,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {cities?.map((city: ICity) => (
                            <option value={city.name}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="postalCode">Postal Code*</label>
                        {validation.touched.postalCode &&
                        validation.errors.postalCode ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.postalCode}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.postalCode &&
                          validation.errors.postalCode &&
                          'is-invalid'
                        }`}
                        id="postalCode"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={validation.values.postalCode}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.postalCode &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.postalCode &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="residentialAddress">
                          Residential Address*
                        </label>
                        {validation.touched.residentialAddress &&
                        validation.errors.residentialAddress ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.residentialAddress}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.residentialAddress &&
                          validation.errors.residentialAddress &&
                          'is-invalid'
                        }`}
                        id="residentialAddress"
                        name="residentialAddress"
                        placeholder="Residential Address"
                        value={validation.values.residentialAddress}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.residentialAddress &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.residentialAddress &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                </div>
              </div>
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={ContactInfoIcon}
                      ></img>
                      <div className="font-weight-600">Contact Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="mobile">Telephone*</label>
                        {validation.touched.mobile &&
                        validation.errors.mobile ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.mobile}
                          </p>
                        ) : null}
                      </div>
                      <PhoneInput
                        // name="mobile"
                        containerStyle={{ padding: 0, width: '100%' }}
                        buttonClass="button-class"
                        containerClass="form-control"
                        inputClass="my-input-class"
                        inputStyle={{
                          border: 0,
                          height: 48,
                          width: '100%',
                        }}
                        buttonStyle={{
                          height: 48,
                        }}
                        countryCodeEditable={false}
                        preferredCountries={['pk']}
                        country={'pk'}
                        value={validation.values.mobile}
                        onChange={e => {
                          validation.setFieldValue('mobile', e);
                        }}
                        disabled={memberView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          nominee[0].id = membersData.companyNomineeInfo[0]?.id;
                          nominee[0].nominationReceiptDate =
                            validation.values.dateOfReceiptOfNomination;
                          nominee[0].firstName =
                            validation.values.name.firstName;
                          nominee[0].middleName =
                            validation.values.name.middleName;
                          nominee[0].lastName = validation.values.name.lastName;
                          nominee[0].relativeFirstName =
                            validation.values.fatherSpouseName.firstName;
                          nominee[0].relativeMiddleName =
                            validation.values.fatherSpouseName.middleName;
                          nominee[0].relativeLastName =
                            validation.values.fatherSpouseName.lastName;
                          nominee[0].relationship =
                            validation.values.relationship;
                          nominee[0].nationality =
                            validation.values.nationality;
                          nominee[0].cnic = validation.values.cnicOrNICOP;
                          nominee[0].passportNumber =
                            validation.values.passportNumber;
                          nominee[0].residentialAddress =
                            validation.values.residentialAddress;
                          nominee[0].city = validation.values.city;
                          nominee[0].province = validation.values.province;
                          nominee[0].country = validation.values.country;
                          nominee[0].phone =
                            validation.values.mobile.toString();
                          nominee[0].postalCode = validation.values.postalCode;
                          nominee[0].trusteeName =
                            validation.values.guardianName;
                          nominee[0].dobMinor = validation.values.dobMinor;
                          nominee[0].files = validation.values.willAttachment;
                          {
                            !validation.errors.mobile &&
                              isDraft &&
                              !loadingDraftUpdateCompanyMember &&
                              validation.values.mobile &&
                              draftUpdateCompanyMember({
                                variables: {
                                  id: selectedMemberId,
                                  companyId: companyID,
                                  companyNomineeInfo: nominee,
                                },
                              });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </>
      )}
    </React.Fragment>
  );
};
