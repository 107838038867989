import { createSlice } from '@reduxjs/toolkit';
import { CompanyOfficerStoreType } from './types';

const initialState: CompanyOfficerStoreType = {
  selectedOfficerId: '',
  officerView: false,
};
const companyOfficerSlice = createSlice({
  name: 'company-officer',
  initialState,
  reducers: {
    setSelectedOfficerId: (state, { payload }) => {
      state.selectedOfficerId = payload;
    },
    setOfficerViewScreen: (state, { payload }) => {
      state.officerView = payload;
    },
  },
});

export const {
  setSelectedOfficerId,
  setOfficerViewScreen,
} = companyOfficerSlice.actions;

export default companyOfficerSlice.reducer;
