import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_OFFICER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  FIND_COMPANY_OFFICER_BY_ID,
  CREATE_COMPANY_OFFICER_FROM_DRAFT,
} from '~/Store/graphQL/CompanyOfficer';
import GetCompanyOfficerStatus from '~/helpers/CompanyOfficer/getCompanyOfficerStatus';
import GetCompanyOfficerDesignation from '~/helpers/CompanyOfficer/getCompanyOfficerDesignation';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import {
  ActionEnum,
  DesignationEnum,
  GenderEnum,
  ModuleEnum,
  NOCEnum,
  OfficerStatusEnum,
  RelationshipEnum,
} from '~/commons/enums';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { ConfirmationDialogue } from '~/components/Toasts';
import { AlertIcon, ContactInfoIcon, PersonalInfoLogo } from '~/assets/images';
import Swal from 'sweetalert2';
import { Container, Modal } from 'reactstrap';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import moment from 'moment';

export const OfficerHistory: FC = () => {
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { selectedOfficerId, officerView } = useAppSelector(
    state => state.companyOfficerReducer
  );
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [officersData, setOfficersData] = useState<any>();

  const [modalVisibility, setModalVisibility] = useState(false);
  const toggleModal = () => {
    setModalVisibility(!modalVisibility);
  };

  const [selectedIndex, setSelectedIndex] = useState<any>();

  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_OFFICER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_OFFICERS,
    },
  });

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyOfficer',
          title: 'Company Officer',
        },
        {
          url: location.pathname,
          title: 'Officer History',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_OFFICERS,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findCompanyOfficerById,
    {
      loading: loadingFindCompanyOfficerById,
      error: errorFindCompanyOfficerById,
      data: dataFindCompanyOfficerById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_OFFICER_BY_ID);
  const [
    createCompanyOfficerfromDraft,
    {
      loading: loadingCreateCompanyOfficerfromDraft,
      error: errorCreateCompanyOfficerfromDraft,
      data: dataCreateCompanyOfficerfromDraft,
    },
  ] = useMutation<any>(CREATE_COMPANY_OFFICER_FROM_DRAFT);
  useEffect(() => {
    if (selectedOfficerId) {
      findCompanyOfficerById({
        variables: {
          id: selectedOfficerId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedOfficerId]);

  useEffect(() => {
    if (dataFindCompanyOfficerById) {
      setIsDraft(
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.isDraft
      );
      setOfficersData(
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
      );
    } else if (errorFindCompanyOfficerById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyOfficerById);
    }
  }, [dataFindCompanyOfficerById, errorFindCompanyOfficerById]);
  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3 end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  // disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyOfficer');
                  }}
                >
                  {officerView ? 'Close' : 'Cancel'}
                </button>
                <button
                  type="button"
                  className="btn fw-bold primaryDismissButton d-flex align-items-center "
                  // disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyOfficer/personalInformation');
                  }}
                >
                  Back
                </button>

                {!officerView && (
                  <button
                    type="button"
                    className="btn btn-primary fw-bold successButton d-flex align-items-center "
                    onClick={() => {
                      if (officerView) {
                        navigate('/companyOfficer');
                      } else {
                        if (isDraft) {
                          ConfirmationDialogue.fire({
                            title: 'Are You Sure?',

                            html: `<body><p>You are saving Company Officer, you will not be able to change following fields later<p>
                            <div class="row">
                              <div class="col-4"></div>
                              <div class="col-4">
                                <ol>
                                  <li class="text-start">Email</li>
                                </ol>
                              </div>
                              <div class="col-4"></div>
                            </div>
                            <body/>`,
                            imageUrl: AlertIcon,
                            imageWidth: '100px',
                            showCancelButton: true,
                            confirmButtonText: 'Save',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true,
                          }).then(result => {
                            if (result.isConfirmed) {
                              if (selectedOfficerId) {
                                findCompanyOfficerById({
                                  variables: {
                                    id: selectedOfficerId,
                                  },
                                  fetchPolicy: 'no-cache',
                                }).then(res => {
                                  if (
                                    res?.data?.findOneCompanyOfficer
                                      ?.companyOfficer
                                  ) {
                                    let temp = JSON.parse(
                                      JSON.stringify(
                                        res?.data?.findOneCompanyOfficer
                                          ?.companyOfficer
                                      )
                                    );

                                    createCompanyOfficerfromDraft({
                                      variables: {
                                        secpRefId: temp?.secpRefId,
                                        firstName: temp?.firstName,
                                        middleName: temp?.middleName,
                                        lastName: temp?.lastName,
                                        designation: temp?.designation,
                                        relativeFirstName:
                                          temp?.relativeFirstName,
                                        relativeMiddleName:
                                          temp?.relativeMiddleName,
                                        relativeLastName:
                                          temp?.relativeLastName,
                                        relationship: temp?.relationship,
                                        nationality: temp?.nationality,
                                        cnic: temp?.cnic,
                                        cnicExpiry: temp?.cnicExpiry,
                                        passportNumber: temp?.passportNumber,
                                        dateOfBirth: temp?.dateOfBirth,
                                        gender: temp?.gender,
                                        residentialAddress:
                                          temp?.residentialAddress,
                                        otherOccupation: temp?.otherOccupation,
                                        noc: temp?.noc,
                                        city: temp?.city,
                                        status: temp?.status,
                                        province: temp?.province,
                                        country: temp?.country,
                                        postalCode: temp?.postalCode,
                                        telephone: temp?.telephone,
                                        email: temp?.email,
                                        ntn: temp?.ntn,
                                        date: temp?.date,
                                        renumeration: temp?.renumeration,
                                        cessationReason: temp?.cessationReason,
                                        companyId: companyID,
                                        files: temp?.url?.split(';'),
                                      },
                                    })
                                      .then(res => {
                                        if (
                                          res?.data
                                            ?.create_companyOfficerFromDraft
                                            ?.companyOfficer
                                        ) {
                                          navigate('/companyOfficer');
                                        }
                                      })
                                      .catch(err => {
                                        dispatch(setToastError(err));
                                      });
                                  }
                                });
                              }
                            } else if (
                              /* Read more about handling dismissals below */
                              result.dismiss === Swal.DismissReason.cancel
                            ) {
                              console.log('Cancelled');
                            }
                          });
                        } else {
                          navigate('/companyOfficer');
                        }
                      }
                    }}
                  >
                    <i className="bx bx-upload me-1" />
                    {'Save & Finish'}
                    {loadingCreateCompanyOfficerfromDraft && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </span>
        </div>

        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs w-100">
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link cursor-pointer">Officer Information</a>
                ) : (
                  <a
                    onClick={() =>
                      navigate('/companyOfficer/personalInformation')
                    }
                    className="nav-link cursor-pointer "
                  >
                    Officer Information
                  </a>
                )}
              </li>
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link active cursor-pointer">
                    Officer History
                  </a>
                ) : (
                  <a
                    onClick={() => navigate('/companyOfficer/officerHistory')}
                    className="nav-link active cursor-pointer"
                  >
                    Officer History
                  </a>
                )}
              </li>
            </ul>
          </span>
        </div>
        <div id="mainComponentNew">
          {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>CNIC</th>
                  <th>Passport No</th>
                  <th>Address</th>
                  <th>SECP Ref.ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {officersData?.companyOfficerHistory?.length === 0 ||
                officersData?.companyOfficerHistory === undefined ||
                officersData?.companyOfficerHistory == null ? (
                  <tr>
                    <td>
                      <div className="badge custom-badge-primary">{1}</div>
                    </td>
                    <td>
                      {officersData?.date ? (
                        <div className="badge custom-badge-success">
                          {new Date(officersData?.date)
                            .toISOString()
                            .slice(0, 10)}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {officersData?.status
                        ? GetCompanyOfficerStatus(officersData?.status)
                        : 'N/A'}
                    </td>
                    <td>
                      {officersData?.middleName
                        ? officersData?.firstName +
                          ' ' +
                          officersData?.middleName +
                          ' ' +
                          officersData?.lastName
                        : officersData?.firstName +
                          ' ' +
                          officersData?.lastName}
                    </td>
                    <td>
                      {officersData?.designation
                        ? GetCompanyOfficerDesignation(
                            officersData?.designation
                          )
                        : 'N/A'}
                    </td>
                    <td>{officersData?.cnic ? officersData?.cnic : 'N/A'}</td>
                    <td>
                      {officersData?.passportNumber ? (
                        <div className="badge custom-badge-primary">
                          {officersData?.passportNumber}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {officersData?.residentialAddress
                        ? officersData?.residentialAddress
                        : 'N/A'}
                    </td>
                    <td>
                      {officersData?.secpRefId ? (
                        <div className="badge custom-badge-primary">
                          {officersData?.secpRefId}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>N/A</td>
                  </tr>
                ) : (
                  officersData?.companyOfficerHistory?.map(
                    (val: any, index: number) => (
                      <tr key={`${index}`}>
                        <td>
                          <div className="badge custom-badge-primary">
                            {index + 1}
                          </div>
                        </td>
                        <td>
                          {val?.date ? (
                            <div className="badge custom-badge-success">
                              {new Date(val?.date).toISOString().slice(0, 10)}
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          {val?.status
                            ? GetCompanyOfficerStatus(val?.status)
                            : 'N/A'}
                        </td>
                        <td>
                          {val?.middleName
                            ? val?.firstName +
                              ' ' +
                              val?.middleName +
                              ' ' +
                              val?.lastName
                            : val?.firstName + ' ' + val?.lastName}
                        </td>
                        <td>
                          {val?.designation
                            ? GetCompanyOfficerDesignation(val?.designation)
                            : 'N/A'}
                        </td>
                        <td>{val?.cnic ? val?.cnic : 'N/A'}</td>
                        <td>
                          {val?.passportNumber ? (
                            <div className="badge custom-badge-primary">
                              {val?.passportNumber}
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          {val?.residentialAddress
                            ? val?.residentialAddress
                            : 'N/A'}
                        </td>
                        <td>
                          {val?.secpRefId ? (
                            <div className="badge custom-badge-primary">
                              {val?.secpRefId}
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          <div className="editColumn mt-0 gap-1">
                            <i
                              className="bx bx-show-alt text-primary"
                              onClick={() => {
                                setSelectedIndex(index);
                                setModalVisibility(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}

      <Modal
        className="modalreact"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={modalVisibility}
        toggle={() => {
          toggleModal();
        }}
      >
        <Container fluids>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              {/* <i className="bx  text-primary bx-sm me-2"></i>s */}
              <i className="bx bx-history text-primary bx-md me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                History
              </h5>
            </div>

            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            {selectedIndex >= 0 ? (
              <div className="mt-2">
                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={PersonalInfoLogo}
                        ></img>
                        <div className="font-weight-600">Personal Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="OfficerName">Officer Name* </label>
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                          <input
                            type="text"
                            id="officerName.officerFirstName"
                            name="officerName.officerFirstName"
                            placeholder="First Name"
                            className={`form-control form-sm w-30 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.firstName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.firstName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.firstName || 'N/A'
                            }
                            disabled={officerView}
                          />
                          <input
                            type="text"
                            id="officerName.officerMiddleName"
                            name="officerName.officerMiddleName"
                            placeholder="Middle Name"
                            className={`form-control form-sm w-30 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.middleName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.middleName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.middleName || 'N/A'
                            }
                            disabled={officerView}
                          />
                          <input
                            type="text"
                            id="officerName.officerLastName"
                            name="officerName.officerLastName"
                            placeholder="Last Name"
                            className={`form-control form-sm w-30  ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.lastName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.lastName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.lastName || 'N/A'
                            }
                            disabled={officerView}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="fatherName">
                            Father/Spouse Name*
                          </label>
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                          <input
                            type="text"
                            id="relativeName.fatherFirstName"
                            name="relativeName.fatherFirstName"
                            placeholder="First Name"
                            className={`form-control form-sm w-30 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeFirstName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.relativeFirstName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeFirstName || 'N/A'
                            }
                            disabled={officerView}
                          />
                          <input
                            type="text"
                            id="relativeName.fatherMiddleName"
                            name="relativeName.fatherMiddleName"
                            placeholder="Middle Name"
                            className={`d-flex form-control form-sm w-30 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeMiddleName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.relativeMiddleName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeMiddleName || 'N/A'
                            }
                            disabled={officerView}
                          />
                          <input
                            type="text"
                            id="relativeName.fatherLastName"
                            name="relativeName.fatherLastName"
                            placeholder="Last Name"
                            className={`d-flex form-control form-sm w-30 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeLastName ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.relativeLastName
                                ? ''
                                : 'updated'
                            }`}
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relativeLastName || 'N/A'
                            }
                            disabled={officerView}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 background-dim-grey mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="officerStatus">Officer Status*</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.status ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.status
                                ? ''
                                : 'updated'
                            }`}
                            id="officerStatus"
                            name="officerStatus"
                            placeholder="Officer Status"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.status
                            }
                            disabled={officerView}
                          >
                            <option value="">Select</option>
                            <option value={OfficerStatusEnum.APPOINTED}>
                              Appointed
                            </option>
                            <option value={OfficerStatusEnum.RESIGNED}>
                              Resigned
                            </option>
                            <option value={OfficerStatusEnum.ELECTED}>
                              Elected
                            </option>
                            <option value={OfficerStatusEnum.REELECTED}>
                              Re-Elected
                            </option>
                            <option value={OfficerStatusEnum.CEASED}>
                              Ceased
                            </option>
                            <option value={OfficerStatusEnum.RETIRED}>
                              Retired
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="date">Date*</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.date ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.date
                              ? ''
                              : 'updated'
                          }`}
                          id="date"
                          name="date"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.date
                              ? moment(
                                  officersData?.companyOfficerHistory[
                                    selectedIndex
                                  ]?.date
                                ).format('YYYY-MM-DD')
                              : 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="designation">Designation*</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.designation ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.designation
                                ? ''
                                : 'updated'
                            }`}
                            id="designation"
                            name="designation"
                            placeholder="Designation"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.designation
                            }
                            disabled={officerView}
                          >
                            <option value="">Select</option>
                            <option value={DesignationEnum.DIRECTOR}>
                              Director
                            </option>
                            <option
                              value={DesignationEnum.DIRECTOR_AND_SUBSCRIBER}
                            >
                              Director And Subscriber
                            </option>
                            <option value={DesignationEnum.DECLARANT}>
                              Declarant
                            </option>
                            <option value={DesignationEnum.ADVISOR_CONSULTANT}>
                              Advisor/Consultant
                            </option>
                            <option value={DesignationEnum.NOMINEE_DIRECTOR}>
                              Nominee Director
                            </option>
                            <option value={DesignationEnum.LEGAL_ADVISOR}>
                              Legal Advisor
                            </option>
                            <option value={DesignationEnum.AUDITOR}>
                              Auditor
                            </option>
                            <option value={DesignationEnum.CHIEF_ACCOUNTANT}>
                              Chairman Accountant/CFO
                            </option>
                            <option value={DesignationEnum.CHAIRMAN}>
                              Chairman
                            </option>
                            <option value={DesignationEnum.COMPANY_SECRETARY}>
                              Company Secretary
                            </option>
                            <option value={DesignationEnum.CHIEF_EXECUTIVE}>
                              Chief Executive
                            </option>
                            <option value={DesignationEnum.MANAGING_AGENT}>
                              Managing Agent
                            </option>
                            <option value={DesignationEnum.SALE_AGENT}>
                              Sale Agent
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="relationship">Relationship*</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relationship ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.relationship
                                ? ''
                                : 'updated'
                            }`}
                            id="relationship"
                            name="relationship"
                            placeholder="Relationship"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.relationship
                            }
                            disabled={officerView}
                          >
                            <option value="">Select</option>
                            <option value={RelationshipEnum.SON_OF}>
                              Son of
                            </option>
                            <option value={RelationshipEnum.FATHER_OF}>
                              Father of
                            </option>
                            <option value={RelationshipEnum.SPOUSE_OF}>
                              Spouse of
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="nationality">Nationality*</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.nationality ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.nationality
                              ? ''
                              : 'updated'
                          }`}
                          id="nationality"
                          name="nationality"
                          placeholder="Nationality"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.nationality || 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cnic">CNIC No* (PK)</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.cnic ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.cnic
                              ? ''
                              : 'updated'
                          }`}
                          id="cnic"
                          name="cnic"
                          placeholder="CNIC"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.cnic || 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cnicExpiry">CNIC Expiry Date*</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.cnicExpiry ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.cnicExpiry
                              ? ''
                              : 'updated'
                          }`}
                          id="cnicExpiry"
                          name="cnicExpiry"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.cnicExpiry
                              ? moment(
                                  officersData?.companyOfficerHistory[
                                    selectedIndex
                                  ]?.cnicExpiry
                                ).format('YYYY-MM-DD')
                              : 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="passportNo">
                            Passport No (Foreign National)
                          </label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100  ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.passportNumber ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.passportNumber
                              ? ''
                              : 'updated'
                          }`}
                          id="passportNo"
                          name="passportNo"
                          placeholder="Passport No"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.passportNumber
                              ? officersData?.companyOfficerHistory[
                                  selectedIndex
                                ]?.passportNumber
                              : 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 background-dim-grey mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="dob">Date of Birth*</label>
                        </div>
                        <input
                          type="date"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.dateOfBirth ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.dateOfBirth
                              ? ''
                              : 'updated'
                          }`}
                          id="dob"
                          name="dob"
                          max={new Date().toISOString().split('T')[0]}
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.dateOfBirth
                              ? moment(
                                  officersData?.companyOfficerHistory[
                                    selectedIndex
                                  ]?.dateOfBirth
                                ).format('YYYY-MM-DD')
                              : 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="gender">Gender*</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.gender ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.gender
                                ? ''
                                : 'updated'
                            }`}
                            id="gender"
                            name="gender"
                            placeholder="Gender"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.gender
                            }
                            disabled={officerView}
                          >
                            <option value="">Select</option>
                            <option value={GenderEnum.MALE}>Male</option>
                            <option value={GenderEnum.FEMALE}>Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="businessOccupation">
                            Business Occupation
                          </label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.otherOccupation ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.otherOccupation
                              ? ''
                              : 'updated'
                          }`}
                          id="businessOccupation"
                          name="businessOccupation"
                          placeholder="Business Occupation"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.otherOccupation
                              ? officersData?.companyOfficerHistory[
                                  selectedIndex
                                ]?.otherOccupation
                              : 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="noc">NOC*</label>
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.noc ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.noc
                                ? ''
                                : 'updated'
                            }`}
                            id="noc"
                            name="noc"
                            placeholder="NOC"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.noc
                            }
                            disabled={officerView}
                          >
                            <option value="">Select</option>
                            <option value={NOCEnum.YES}>Yes</option>
                            <option value={NOCEnum.NO}>No</option>
                            <option value={NOCEnum.NA}>N/A</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    {officersData?.companyOfficerHistory[selectedIndex]
                      ?.officerStatus === ActionEnum.CEASED ? (
                      <div className="col-3">
                        <div className="form-group">
                          <div className="d-flex">
                            <label htmlFor="cessationReason">
                              Cessation Reason*
                            </label>
                          </div>
                          <input
                            type="text"
                            className={`form-control w-100 ${
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.cessationReason ===
                              officersData?.companyOfficerHistory[
                                selectedIndex + 1
                              ]?.cessationReason
                                ? ''
                                : 'updated'
                            }`}
                            id="cessationReason"
                            name="cessationReason"
                            placeholder="Cessation Reason"
                            value={
                              officersData?.companyOfficerHistory[selectedIndex]
                                ?.cessationReason || 'N/A'
                            }
                            disabled={officerView}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="ntn">NTN*</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.ntn ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.ntn
                              ? ''
                              : 'updated'
                          }`}
                          id="ntn"
                          name="ntn"
                          placeholder="NTN"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.ntn || 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="remuneration">Remuneration</label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.renumeration ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.renumeration
                              ? ''
                              : 'updated'
                          }`}
                          id="remuneration"
                          name="remuneration"
                          placeholder="Remuneration"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.renumeration || 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>

                    {/* <div className="col-1">
                    <div className="font-weight-600">Attachment</div>
                    <div className="font-11">Documents</div>
                    <div className="attachedDocumentDiv cursor-pointer">
                      <label htmlFor={'file-input'} className="cursor-pointer">
                        <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*,.pdf"
                        multiple={true}
                        onChangeCapture={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (e?.target?.files) {
                            validation.setFieldValue(
                              'attachment',
                              e?.target?.files
                            );
                          }
                        }}
                      />
                    </div>
                  </div> */}
                    {/* <div className="col-4 d-flex justify-content-start align-items-center">
                    {validation.values.attachment ? (
                      <div className="row">
                        <div className="col-12">
                          <ul className="d-flex flex-column">
                            {[...validation?.values?.attachment]?.map(
                              (attachment: any, index: number) => (
                                <div className="d-flex align-items-center justify-content-between">
                                  <li key={index}>
                                    {attachment ? attachment.name : ''}
                                  </li>
                                  {!officerView ? (
                                    <i
                                      className="bx bx-x bx-sm mx-3 cursor-pointer"
                                      onClick={e => {
                                        let tempAttachmentFiles = [
                                          ...validation.values.attachment,
                                        ];
                                        tempAttachmentFiles.splice(index, 1);
                                        validation.setFieldValue(
                                          'attachment',
                                          tempAttachmentFiles
                                        );
                                      }}
                                    ></i>
                                  ) : null}
                                </div>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div> */}
                  </div>
                </div>

                <div className="inputFields border-div px-3 pt-3 mt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={ContactInfoIcon}
                        ></img>
                        <div className="font-weight-600">Contact Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="email">Email*</label>
                        </div>

                        <input
                          type="text"
                          className={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.email ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.email
                              ? ''
                              : 'updated'
                          }`}
                          id="email"
                          name="email"
                          placeholder="Email"
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.email || 'N/A'
                          }
                          disabled={officerView}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="telephone">Telephone*</label>
                        </div>
                        <PhoneInput
                          containerStyle={{ padding: 0, width: '100%' }}
                          disabled={officerView}
                          buttonClass="button-class"
                          containerClass={`form-control w-100 ${
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.telephone ===
                            officersData?.companyOfficerHistory[
                              selectedIndex + 1
                            ]?.telephone
                              ? ''
                              : 'updated'
                          }`}
                          inputClass="my-input-class"
                          inputStyle={{
                            border: 0,
                            height: 48,
                            width: '100%',
                          }}
                          buttonStyle={{
                            height: 48,
                          }}
                          countryCodeEditable={false}
                          preferredCountries={['pk']}
                          country={'pk'}
                          value={
                            officersData?.companyOfficerHistory[selectedIndex]
                              ?.telephone || 'N/A'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">No data available</div>
            )}
            <br className="my-4"></br>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
