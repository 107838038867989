import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './agenda.scss';
import {
  BusinessTypeEnum,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
} from '../../../commons/enums';
import {
  CREATE_AGENDA,
  FIND_AGENDA_BY_ID,
  UPDATE_AGENDA,
} from '~/Store/graphQL/AgendaManagement';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ActionItemsInputDto } from './types';
import { v4 as uuidv4 } from 'uuid';
import './agenda.scss';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';
import { GET_ALL_BUSINESS_RULES } from '~/Store/graphQL/BusinessRules';

export const AddAdminAgenda: FC = () => {
  const { selectedAgendaId, agendaView } = useAppSelector(
    state => state.agendaReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [agendaData, setAgendaData] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [selectOrdinanceName, setSelectOrdinanceName] = useState<any>();
  const [selectSection, setSelectSection] = useState<any>();
  const [actionItems, setActionItems] = useState<
    ActionItemsInputDto[] | undefined
  >();
  useEffect(() => {
    getAllOrdinance();
    getAllBusinessRules();
    dispatch(
      setBreadCrumb([
        {
          url: '/admin/agenda',
          title: 'Agenda',
        },
        {
          url: location.pathname,
          title: 'Add Agenda',
        },
      ])
    );
  }, []);
  // Get all business rules for dropdown
  const [
    getAllBusinessRules,
    {
      loading: loadingGetAllBusinessRules,
      error: errorGetAllBusinessRules,
      data: dataGetAllBusinessRules,
    },
  ] = useLazyQuery<any>(GET_ALL_BUSINESS_RULES, {
    variables: {
      isActive: true,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting ordinance information

  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    fetchPolicy: 'no-cache',
  });

  const [
    findAgendaById,
    {
      loading: loadingFindAgendaById,
      error: errorFindAgendaById,
      data: dataFindAgendaById,
    },
  ] = useLazyQuery<any>(FIND_AGENDA_BY_ID);
  //   update agenda
  const [
    updateAgenda,
    {
      loading: loadingUpdateAgenda,
      error: errorUpdateAgenda,
      data: dataUpdateAgenda,
    },
  ] = useMutation<any>(UPDATE_AGENDA);
  // create agenda
  const [
    createAgenda,
    {
      loading: loadingCreateAgenda,
      error: errorCreateAgenda,
      data: dataCreateAgenda,
    },
  ] = useMutation<any>(CREATE_AGENDA);

  useEffect(() => {
    if (selectOrdinanceName && dataGetAllOrdinance) {
      const result = dataGetAllOrdinance?.findAllOrdinance?.edges.filter(
        (ordinance: any, ordinanceIndex: number) =>
          ordinance?.node?.ordinanceName === selectOrdinanceName
      );
      setSelectSection(result);
    }
  }, [selectOrdinanceName, dataGetAllOrdinance]);

  useEffect(() => {
    if (selectedAgendaId) {
      findAgendaById({
        variables: {
          id: selectedAgendaId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedAgendaId]);
  useEffect(() => {
    if (errorGetAllOrdinance) {
      console.log('errorGetAllOrdinance', errorGetAllOrdinance);
      dispatch(setToastError(errorGetAllOrdinance));
    }
  }, [errorGetAllOrdinance]);
  useEffect(() => {
    if (dataFindAgendaById) {
      setAgendaData(dataFindAgendaById?.findOneAgenda?.agenda);
      setActionItems(dataFindAgendaById?.findOneAgenda?.agenda?.actionItems);
      setSelectOrdinanceName(
        dataFindAgendaById?.findOneAgenda?.agenda?.ordinanceType
      );
    } else if (errorFindAgendaById) {
      console.log('errorFindAgendaById:', errorFindAgendaById);
    }
  }, [dataFindAgendaById, errorFindAgendaById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      meetingType: agendaData ? agendaData.meetingType : '',
      meetingSubType: agendaData ? agendaData.meetingSubType : '',
      section: agendaData ? agendaData.ordinanceSection : '',
      ordinance: agendaData ? agendaData.ordinanceType : '',
      businessRule: agendaData ? agendaData.businessRuleId : '',
      businessType: agendaData ? agendaData.businessType : '',
      actionItems: agendaData ? agendaData.actionItems[0].actionItem : '',
      attachment:
        agendaData &&
        agendaData.actionItems &&
        agendaData.actionItems[0].attachmentUrl
          ? JSON.parse(agendaData.actionItems[0].attachmentUrl)
          : '',
      resolution: agendaData ? agendaData.actionItems[0].resolution : '',
    },
    validationSchema: Yup.object({
      meetingType: Yup.string()
        .required('Please select meeting type')
        .nullable(),
      meetingSubType: Yup.string()
        .required('Please select meeting sub type')
        .nullable(),
      section: Yup.string().required('Please select section').nullable(),
      ordinance: Yup.string().required('Please select ordinance').nullable(),
      businessType: Yup.string()
        .required('Please enter business type')
        .nullable(),
      actionItems: Yup.string()
        .required('Please enter action item type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      actionItems?.forEach((element: any) => {
        delete element['__typename'];
      });
      if (agendaData && selectedAgendaId && !agendaView) {
        updateAgenda({
          variables: {
            id: selectedAgendaId,
            ordinanceType: values.ordinance,
            ordinanceSection: values.section,
            meetingType: values.meetingType,
            meetingSubType: values.meetingSubType,
            businessType: values.businessType,
            businessRuleId: values.businessRule,
            actionItems: [
              {
                id: uuidv4(),
                actionItem: values.actionItems,
                agendaId: '',
                resolution: values.resolution,
                attachment: values.attachment,
              },
            ],
          },
        })
          .then((res: any) => {
            if (res?.data?.updateAgenda?.agenda) {
              dispatch(setToastSuccess('Agenda updated!'));
              navigate(`/admin/agenda`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createAgenda({
          variables: {
            companyId: null,
            ordinanceType: values.ordinance,
            ordinanceSection: values.section,
            meetingType: values.meetingType,
            meetingSubType: values.meetingSubType,
            businessType: values.businessType,
            businessRuleId: values.businessRule ? values.businessRule : null,
            actionItems: [
              {
                id: uuidv4(),
                actionItem: values.actionItems,
                agendaId: '',
                resolution: values.resolution,
                attachment: values.attachment,
              },
            ],
          },
        })
          .then((res: any) => {
            if (res?.data?.createAgenda?.agenda) {
              dispatch(setToastSuccess('Agenda created!'));
              navigate(`/admin/agenda`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingFindAgendaById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentHeading">Agenda Info</div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Agenda Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="meetingType">Meeting Type*</label>
                      {validation.touched.meetingType &&
                      validation.errors.meetingType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.meetingType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.meetingType &&
                          validation.errors.meetingType &&
                          'is-invalid'
                        }`}
                        id="meetingType"
                        name="meetingType"
                        placeholder="Meeting Type"
                        value={validation.values.meetingType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={MeetingTypeEnum.BOARD_MEETING}>
                          Board Meeting
                        </option>
                        <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                          Committee Meeting
                        </option>
                        <option value={MeetingTypeEnum.GENERAL_MEETING}>
                          General Meeting
                        </option>
                        <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                          Statutory Meeting
                        </option>
                        <option value={MeetingTypeEnum.OTHER}>
                          Other Meeting
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="meetingSubType">Meeting Sub Type*</label>
                      {validation.touched.meetingSubType &&
                      validation.errors.meetingSubType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.meetingSubType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.meetingSubType &&
                          validation.errors.meetingSubType &&
                          'is-invalid'
                        }`}
                        id="meetingSubType"
                        name="meetingSubType"
                        placeholder="Meeting Sub Type"
                        value={validation.values.meetingSubType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        {validation.values.meetingType ===
                        MeetingTypeEnum.BOARD_MEETING ? (
                          <>
                            <option value={MeetingSubTypeEnum.BOARD_MEETING}>
                              Board Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.GENERAL_MEETING ? (
                          <>
                            <option
                              value={MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING}
                            >
                              Annual General Meeting
                            </option>

                            <option
                              value={
                                MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING
                              }
                            >
                              Extraordinary General Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.COMMITTEE_MEETING ? (
                          <>
                            <option
                              value={MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING}
                            >
                              Audit Committee Meeting
                            </option>
                            <option
                              value={MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING}
                            >
                              Other Committee Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.STATUTORY_MEETING ? (
                          <option value={MeetingSubTypeEnum.STATUTORY_MEETING}>
                            Statutory Meeting
                          </option>
                        ) : null}

                        <option value={MeetingSubTypeEnum.OTHER}>
                          Other Meeting
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="businessType">Business Type*</label>
                      {validation.touched.businessType &&
                      validation.errors.businessType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.businessType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.businessType &&
                          validation.errors.businessType &&
                          'is-invalid'
                        }`}
                        id="businessType"
                        name="businessType"
                        placeholder="Business Type"
                        value={validation.values.businessType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={BusinessTypeEnum.ORDINARY_BUSINESS}>
                          Ordinary Business
                        </option>
                        <option value={BusinessTypeEnum.SPECIAL_BUSINESS}>
                          Special Business
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="ordinance">Ordinance*</label>
                      {validation.touched.ordinance &&
                      validation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.ordinance &&
                          validation.errors.ordinance &&
                          'is-invalid'
                        }`}
                        id="ordinance"
                        name="ordinance"
                        placeholder="Ordinance"
                        value={validation.values.ordinance}
                        disabled={agendaView}
                        onChange={e => {
                          validation.setFieldValue('ordinance', e.target.value);
                          setSelectOrdinanceName(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                          (ordinance: any, ordinanceIndex: number) => (
                            <option
                              value={ordinance?.node?.ordinanceName}
                              key={ordinanceIndex}
                            >
                              {ordinance?.node?.ordinanceName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="section">Section*</label>
                      {validation.touched.section &&
                      validation.errors.section ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.section}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.section &&
                          validation.errors.section &&
                          'is-invalid'
                        }`}
                        id="section"
                        name="section"
                        placeholder="Section"
                        value={validation.values.section}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        {selectSection &&
                          selectSection[0]?.node?.section.map(
                            (section: any, sectionIndex: number) => (
                              <>
                                <option value={section?.id} key={sectionIndex}>
                                  {section?.sectionName}
                                </option>
                              </>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="actionItem">Action Items*</label>
                      {validation.touched.actionItems &&
                      validation.errors.actionItems ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.actionItems}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.actionItems &&
                        validation.errors.actionItems &&
                        'is-invalid'
                      }`}
                      id="actionItems"
                      name="actionItems"
                      placeholder="Action Item"
                      value={validation.values.actionItems}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="businessRule">Business Rule</label>
                      {validation.touched.businessRule &&
                      validation.errors.businessRule ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.businessRule}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.businessRule &&
                          validation.errors.businessRule &&
                          'is-invalid'
                        }`}
                        id="businessRule"
                        name="businessRule"
                        placeholder="Business Rule"
                        value={validation.values.businessRule}
                        disabled={agendaView}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllBusinessRules?.findAllBusinessRules?.edges?.map(
                          (businessRule: any, index: number) => (
                            <option value={businessRule?.node?.id} key={index}>
                              {businessRule?.node?.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"></div>
                <div className="col-xxl-4 col-xl-6"></div>
              </div>
              <div className="row">
                <div className="col-1">
                  <div className="font-weight-600">Attachment</div>
                  {/* <div className="font-11">Documents</div> */}
                  <div
                    className={`attachedDocumentDiv cursor-pointer ${
                      agendaView && 'icon-disabled'
                    }`}
                  >
                    <label htmlFor={'file-input'} className="cursor-pointer">
                      <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.docx"
                      onChangeCapture={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (e?.target?.files) {
                          const selectedFile = e?.target?.files[0];

                          // Perform file type validation
                          if (selectedFile) {
                            const allowedFileTypes = [
                              '.jpg',
                              '.jpeg',
                              '.png',
                              '.pdf',
                              '.docx',
                            ];
                            const fileExtension = selectedFile.name.substring(
                              selectedFile.name.lastIndexOf('.')
                            );
                            if (
                              !allowedFileTypes.includes(
                                fileExtension.toLowerCase()
                              )
                            ) {
                              // File type not allowed
                              dispatch(
                                setToastError(
                                  'Only JPG, PNG, PDF and DOCX files are allowed.'
                                )
                              );
                              e.target.value = ''; // Clear the selected file
                              return;
                            }

                            // Perform further processing with the selected file
                            validation.setFieldValue(
                              'attachment',
                              e?.target?.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-start align-items-center">
                  {validation.values.attachment ? (
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <ul className="d-flex justify-content-center align-items-center">
                          <li>
                            {validation.values.attachment?.name
                              ? validation.values.attachment.name
                              : validation.values.attachment?.split('/')[3]}
                          </li>
                          {!agendaView ? (
                            <i
                              className="bx bx-x bx-sm mx-3 cursor-pointer"
                              onClick={e => {
                                validation.setFieldValue('attachment', '');
                              }}
                            ></i>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-4"></div>
                <div className="col-3"></div>
              </div>
              <hr className="my-4"></hr>
              <div>
                <div className="formGroupHeading">Resolution</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="col-12">
                <DefaultEditor
                  style={{ minHeight: '20vh' }}
                  value={validation.values.resolution}
                  disabled={agendaView}
                  onChange={e => {
                    validation.setFieldValue(
                      'resolution',
                      e.target.value.replaceAll('&nbsp;', ' ')
                    );
                  }}
                />
              </div>

              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  disabled={
                    loadingFindAgendaById ||
                    loadingUpdateAgenda ||
                    loadingCreateAgenda
                  }
                  onMouseDown={() => {
                    navigate('/admin/agenda');
                  }}
                >
                  Cancel
                </button>
                {!agendaView ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={
                      loadingFindAgendaById ||
                      loadingUpdateAgenda ||
                      editableRow !== -1
                    }
                    onMouseDown={() => {
                      validation.handleSubmit();

                      // dispatch(
                      //   setToastError('Please add atleast 1 action item!')
                      // );
                    }}
                  >
                    {(loadingFindAgendaById ||
                      loadingUpdateAgenda ||
                      loadingCreateAgenda) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
