import { ShareTypeEnum } from '../../commons/enums';
const GetShareType = (type: string) => {
  if (type === ShareTypeEnum.ORDINARY_SHARE) {
    return 'Ordinary Share';
  } else if (type === ShareTypeEnum.PREFERENCE_SHARE) {
    return 'Preference Share';
  }
};



export default GetShareType;
