export const Country={
    "id": 167,
    "name": "Pakistan",
    "iso3": "PAK",
    "iso2": "PK",
    "numeric_code": "586",
    "phone_code": "92",
    "capital": "Islamabad",
    "currency": "PKR",
    "currency_name": "Pakistani rupee",
    "currency_symbol": "₨",
    "tld": ".pk",
    "native": "Pakistan",
    "region": "Asia",
    "subregion": "Southern Asia",
    "timezones": [
        {
            "zoneName": "Asia\/Karachi",
            "gmtOffset": 18000,
            "gmtOffsetName": "UTC+05:00",
            "abbreviation": "PKT",
            "tzName": "Pakistan Standard Time"
        }
    ],
    "translations": {
        "kr": "파키스탄",
        "br": "Paquistão",
        "pt": "Paquistão",
        "nl": "Pakistan",
        "hr": "Pakistan",
        "fa": "پاکستان",
        "de": "Pakistan",
        "es": "Pakistán",
        "fr": "Pakistan",
        "ja": "パキスタン",
        "it": "Pakistan",
        "cn": "巴基斯坦",
        "tr": "Pakistan"
    },
    "latitude": "30.00000000",
    "longitude": "70.00000000",
    "emoji": "🇵🇰",
    "emojiU": "U+1F1F5 U+1F1F0",
    "states": [
        {
            "id": 3172,
            "name": "Azad Kashmir",
            "state_code": "JK",
            "latitude": "33.92590550",
            "longitude": "73.78103340",
            "type": null,
            "cities": [
                {
                    "id": 85368,
                    "name": "Bhimbar",
                    "latitude": "32.97465000",
                    "longitude": "74.07846000"
                },
                {
                    "id": 85562,
                    "name": "Kotli",
                    "latitude": "33.51836000",
                    "longitude": "73.90220000"
                },
                {
                    "id": 85563,
                    "name": "Kotli",
                    "district": true,
                    "latitude": "33.44559000",
                    "longitude": "73.91557000"
                },
                {
                    "id": 85614,
                    "name": "Mirpur",
                    "district": true,
                    "latitude": "33.21556000",
                    "longitude": "73.75144000"
                },
                {
                    "id": 85628,
                    "name": "Muzaffarābād",
                    "latitude": "34.37002000",
                    "longitude": "73.47082000"
                },
                {
                    "id": 85643,
                    "name": "New Mirpur",
                    "latitude": "33.14782000",
                    "longitude": "73.75187000"
                },
                {
                    "id": 85682,
                    "name": "Rawala Kot",
                    "latitude": "33.85782000",
                    "longitude": "73.76043000"
                }
            ]
        },
        {
            "id": 3174,
            "name": "Balochistan",
            "state_code": "BA",
            "latitude": "28.49073320",
            "longitude": "65.09577920",
            "type": null,
            "cities": [
                {
                    "id": 85334,
                    "name": "Alik Ghund",
                    "latitude": "30.48976000",
                    "longitude": "67.52177000"
                },
                {
                    "id": 85342,
                    "name": "Awārān",
                    "district": true,
                    "latitude": "26.21157000",
                    "longitude": "65.42944000"
                },
                {
                    "id": 85353,
                    "name": "Barkhan",
                    "latitude": "29.89773000",
                    "longitude": "69.52558000"
                },
                {
                    "id": 85375,
                    "name": "Bārkhān",
                    "district": true,
                    "latitude": "29.98482000",
                    "longitude": "69.69944000"
                },
                {
                    "id": 85360,
                    "name": "Bela",
                    "latitude": "26.22718000",
                    "longitude": "66.31178000"
                },
                {
                    "id": 85362,
                    "name": "Bhag",
                    "latitude": "29.04174000",
                    "longitude": "67.82394000"
                },
                {
                    "id": 85401,
                    "name": "Chāgai",
                    "district": true,
                    "latitude": "28.98765000",
                    "longitude": "63.59087000"
                },
                {
                    "id": 85385,
                    "name": "Chaman",
                    "latitude": "30.91769000",
                    "longitude": "66.45259000"
                },
                {
                    "id": 85397,
                    "name": "Chowki Jamali",
                    "latitude": "28.01944000",
                    "longitude": "67.92083000"
                },
                {
                    "id": 85402,
                    "name": "Dadhar",
                    "latitude": "29.47489000",
                    "longitude": "67.65167000"
                },
                {
                    "id": 85406,
                    "name": "Dalbandin",
                    "latitude": "28.88846000",
                    "longitude": "64.40616000"
                },
                {
                    "id": 85416,
                    "name": "Dera Bugti",
                    "latitude": "29.03619000",
                    "longitude": "69.15849000"
                },
                {
                    "id": 85417,
                    "name": "Dera Bugti",
                    "district": true,
                    "latitude": "28.94250000",
                    "longitude": "69.06883000"
                },
                {
                    "id": 85432,
                    "name": "Duki",
                    "latitude": "30.15307000",
                    "longitude": "68.57323000"
                },
                {
                    "id": 85442,
                    "name": "Gadani",
                    "latitude": "25.11879000",
                    "longitude": "66.73219000"
                },
                {
                    "id": 85445,
                    "name": "Garhi Khairo",
                    "latitude": "28.06029000",
                    "longitude": "67.98033000"
                },
                {
                    "id": 85459,
                    "name": "Gwadar",
                    "latitude": "25.12163000",
                    "longitude": "62.32541000"
                },
                {
                    "id": 85465,
                    "name": "Harnai",
                    "latitude": "30.10077000",
                    "longitude": "67.93824000"
                },
                {
                    "id": 85497,
                    "name": "Jāfarābād",
                    "district": true,
                    "latitude": "28.30104000",
                    "longitude": "68.19783000"
                },
                {
                    "id": 85489,
                    "name": "Jhal Magsi",
                    "district": true,
                    "latitude": "28.36881000",
                    "longitude": "67.54300000"
                },
                {
                    "id": 85495,
                    "name": "Jiwani",
                    "latitude": "25.04852000",
                    "longitude": "61.74573000"
                },
                {
                    "id": 85507,
                    "name": "Kalat",
                    "latitude": "29.02663000",
                    "longitude": "66.59361000"
                },
                {
                    "id": 85511,
                    "name": "Kalāt",
                    "district": true,
                    "latitude": "28.88242000",
                    "longitude": "66.53165000"
                },
                {
                    "id": 85530,
                    "name": "Khadan Khak",
                    "latitude": "30.75236000",
                    "longitude": "67.71133000"
                },
                {
                    "id": 85543,
                    "name": "Kharan",
                    "latitude": "28.58459000",
                    "longitude": "65.41501000"
                },
                {
                    "id": 85550,
                    "name": "Khārān",
                    "district": true,
                    "latitude": "27.96308000",
                    "longitude": "64.57113000"
                },
                {
                    "id": 85548,
                    "name": "Khuzdar",
                    "latitude": "27.81193000",
                    "longitude": "66.61096000"
                },
                {
                    "id": 85549,
                    "name": "Khuzdār",
                    "district": true,
                    "latitude": "27.48680000",
                    "longitude": "66.58703000"
                },
                {
                    "id": 85552,
                    "name": "Kohlu",
                    "latitude": "29.89651000",
                    "longitude": "69.25324000"
                },
                {
                    "id": 85556,
                    "name": "Kot Malik Barkhurdar",
                    "latitude": "30.20379000",
                    "longitude": "66.98723000"
                },
                {
                    "id": 85579,
                    "name": "Lasbela",
                    "district": true,
                    "latitude": "25.78634000",
                    "longitude": "66.60330000"
                },
                {
                    "id": 85584,
                    "name": "Loralai",
                    "latitude": "30.37051000",
                    "longitude": "68.59795000"
                },
                {
                    "id": 85585,
                    "name": "Loralai",
                    "district": true,
                    "latitude": "30.30253000",
                    "longitude": "68.84636000"
                },
                {
                    "id": 85586,
                    "name": "Mach",
                    "latitude": "29.86371000",
                    "longitude": "67.33018000"
                },
                {
                    "id": 85599,
                    "name": "Mastung",
                    "latitude": "29.79966000",
                    "longitude": "66.84553000"
                },
                {
                    "id": 85600,
                    "name": "Mastung",
                    "district": true,
                    "latitude": "29.79455000",
                    "longitude": "66.72068000"
                },
                {
                    "id": 85605,
                    "name": "Mehrabpur",
                    "latitude": "28.10773000",
                    "longitude": "68.02554000"
                },
                {
                    "id": 85629,
                    "name": "Mūsa Khel",
                    "district": true,
                    "latitude": "30.84937000",
                    "longitude": "69.90069000"
                },
                {
                    "id": 85635,
                    "name": "Nasīrābād",
                    "district": true,
                    "latitude": "28.62643000",
                    "longitude": "68.12925000"
                },
                {
                    "id": 85648,
                    "name": "Nushki",
                    "latitude": "29.55218000",
                    "longitude": "66.02288000"
                },
                {
                    "id": 85651,
                    "name": "Ormara",
                    "latitude": "25.21018000",
                    "longitude": "64.63626000"
                },
                {
                    "id": 85656,
                    "name": "Panjgūr",
                    "district": true,
                    "latitude": "26.73750000",
                    "longitude": "64.20380000"
                },
                {
                    "id": 85658,
                    "name": "Pasni",
                    "latitude": "25.26302000",
                    "longitude": "63.46921000"
                },
                {
                    "id": 85668,
                    "name": "Pishin",
                    "latitude": "30.58176000",
                    "longitude": "66.99406000"
                },
                {
                    "id": 85671,
                    "name": "Qila Saifullāh",
                    "district": true,
                    "latitude": "30.95392000",
                    "longitude": "68.33996000"
                },
                {
                    "id": 85672,
                    "name": "Quetta",
                    "latitude": "30.18414000",
                    "longitude": "67.00141000"
                },
                {
                    "id": 85673,
                    "name": "Quetta",
                    "district": true,
                    "latitude": "30.17458000",
                    "longitude": "66.76203000"
                },
                {
                    "id": 85721,
                    "name": "Sibi",
                    "latitude": "29.54299000",
                    "longitude": "67.87726000"
                },
                {
                    "id": 85727,
                    "name": "Sohbatpur",
                    "latitude": "28.52038000",
                    "longitude": "68.54298000"
                },
                {
                    "id": 85731,
                    "name": "Surab",
                    "latitude": "28.49276000",
                    "longitude": "66.25999000"
                },
                {
                    "id": 85755,
                    "name": "Turbat",
                    "latitude": "26.00122000",
                    "longitude": "63.04849000"
                },
                {
                    "id": 85760,
                    "name": "Usta Muhammad",
                    "latitude": "28.17723000",
                    "longitude": "68.04367000"
                },
                {
                    "id": 85761,
                    "name": "Uthal",
                    "latitude": "25.80722000",
                    "longitude": "66.62194000"
                },
                {
                    "id": 85771,
                    "name": "Zhob",
                    "latitude": "31.34082000",
                    "longitude": "69.44930000"
                },
                {
                    "id": 85772,
                    "name": "Zhob",
                    "district": true,
                    "latitude": "31.36444000",
                    "longitude": "69.20749000"
                },
                {
                    "id": 85773,
                    "name": "Ziarat",
                    "latitude": "30.38244000",
                    "longitude": "67.72562000"
                },
                {
                    "id": 85774,
                    "name": "Ziārat",
                    "district": true,
                    "latitude": "30.43591000",
                    "longitude": "67.50962000"
                }
            ]
        },
        {
            "id": 3173,
            "name": "Federally Administered Tribal Areas",
            "state_code": "TA",
            "latitude": "32.66747600",
            "longitude": "69.85974060",
            "type": null,
            "cities": [
                {
                    "id": 851136,
                    "name": "Bajaur Agency",
                    "district": true,
                },
                {
                    "id": 852136,
                    "name": "Mohmand Agency",
                    "district": true,
                },
                {
                    "id": 852236,
                    "name": "Mohmand Agency",
                    "district": true,
                },
                {
                    "id": 812236,
                    "name": "Khyber Agency",
                    "district": true,
                },
                {
                    "id": 822236,
                    "name": "Orakzai Agency",
                    "district": true,
                },
                {
                    "id": 822226,
                    "name": "Kurram Agency",
                    "district": true,
                },
                {
                    "id": 85645,
                    "name": "North Wazīristān Agency",
                    "district": true,
                    "latitude": "32.95087000",
                    "longitude": "69.95764000"
                },
                {
                    "id": 85728,
                    "name": "South Wazīristān Agency",
                    "district": true,
                    "latitude": "32.30397000",
                    "longitude": "69.68207000"
                },
                {
                    "id": 85336,
                    "name": "Alizai",
                    "latitude": "33.53613000",
                    "longitude": "70.34607000"
                },
                {
                    "id": 85458,
                    "name": "Gulishah Kach",
                    "latitude": "32.67087000",
                    "longitude": "70.33917000"
                },
                {
                    "id": 85577,
                    "name": "Landi Kotal",
                    "latitude": "34.09880000",
                    "longitude": "71.14108000"
                },
                {
                    "id": 85611,
                    "name": "Miran Shah",
                    "latitude": "33.00059000",
                    "longitude": "70.07117000"
                },
                {
                    "id": 85717,
                    "name": "Shinpokh",
                    "latitude": "34.32959000",
                    "longitude": "71.17852000"
                },
                {
                    "id": 85764,
                    "name": "Wana",
                    "latitude": "32.29889000",
                    "longitude": "69.57250000"
                }
            ]
        },
        {
            "id": 3170,
            "name": "Gilgit-Baltistan",
            "state_code": "GB",
            "latitude": "35.80256670",
            "longitude": "74.98318080",
            "type": null,
            "cities": [
                {
                    "id": 8781232,
                    "name": "Ghanche",
                },
                {
                    "id": 8781220,
                    "name": "Skardu",
                },
                {
                    "id": 8781221,
                    "name": "Shigar",
                },
                {
                    "id": 8781222,
                    "name": "Kharmang",
                },
                {
                    "id": 8781223,
                    "name": "Roundu",
                },
                {
                    "id": 8781224,
                    "name": "Ghizer",
                },
                {
                    "id": 8781225,
                    "name": "Gilgit",
                },
                {
                    "id": 8781226,
                    "name": "Hunza",
                },
                {
                    "id": 8781227,
                    "name": "Nagar",
                },
                {
                    "id": 8781228,
                    "name": "Astore",
                },
                {
                    "id": 8781229,
                    "name": "Diamer",
                },
                {
                    "id": 8781230,
                    "name": "Aliabad",
                },
                {
                    "id": 85352,
                    "name": "Barishal",
                    "latitude": "36.32162000",
                    "longitude": "74.69502000"
                },
                {
                    "id": 85450,
                    "name": "Gilgit",
                    "latitude": "35.91869000",
                    "longitude": "74.31245000",
                    "district": true,
                },
                {
                    "id": 85724,
                    "name": "Skardu",
                    "latitude": "35.29787000",
                    "longitude": "75.63372000",
                    "district": true,
                },
                {
                    "id": 85724,
                    "name": "Diamer",
                    "latitude": "35.29787000",
                    "longitude": "75.63372000",
                    "district": true,
                },
            ]
        },
        {
            "id": 3169,
            "name": "Islamabad Capital Territory",
            "state_code": "IS",
            "latitude": "33.72049970",
            "longitude": "73.04052770",
            "type": null,
            "cities": [
                {
                    "id": 854759,
                    "name": "Islamabad",
                    "district": true,
                    "latitude": "33.72148000",
                    "longitude": "73.04329000"
                },
                {
                    "id": 85475,
                    "name": "Islamabad",
                    "latitude": "33.72148000",
                    "longitude": "73.04329000"
                },
            ]
        },
        {
            "id": 3171,
            "name": "Khyber Pakhtunkhwa",
            "state_code": "KP",
            "latitude": "34.95262050",
            "longitude": "72.33111300",
            "type": null,
            "cities": [
                {
                    "id": 8454321,
                    "name": "Bannu",
                    "district":true,
                },
                {
                    "id": 8454322,
                    "name": "Lakki Marwat",
                    "district":true,
                },
                {
                    "id": 8454323,
                    "name": "Tank",
                    "district":true,
                },
                {
                    "id": 8454324,
                    "name": "Abbottabad",
                    "district":true,
                },
                {
                    "id": 8454325,
                    "name": "Haripur",
                    "district":true,
                },
                {
                    "id": 8454326,
                    "name": "Kohistan",
                    "district":true,
                },
                {
                    "id": 8454327,
                    "name": "Mansehra",
                    "district":true,
                },
                {
                    "id": 8454328,
                    "name": "Karak",
                    "district":true,
                },
                {
                    "id": 8454329,
                    "name": "Kohat",
                    "district":true,
                },
                {
                    "id": 8454330,
                    "name": "Swat",
                    "district":true,
                },
                {
                    "id": 85329,
                    "name": "Abbottabad",
                    "latitude": "34.14630000",
                    "longitude": "73.21168000"
                },
                {
                    "id": 85333,
                    "name": "Akora",
                    "latitude": "34.00337000",
                    "longitude": "72.12561000"
                },
                {
                    "id": 85337,
                    "name": "Aman Garh",
                    "latitude": "34.00584000",
                    "longitude": "71.92971000"
                },
                {
                    "id": 85338,
                    "name": "Amirabad",
                    "latitude": "34.18729000",
                    "longitude": "73.09078000"
                },
                {
                    "id": 85340,
                    "name": "Ashanagro Koto",
                    "latitude": "34.10773000",
                    "longitude": "72.24517000"
                },
                {
                    "id": 85345,
                    "name": "Baffa",
                    "latitude": "34.43770000",
                    "longitude": "73.22368000"
                },
                {
                    "id": 85351,
                    "name": "Bannu",
                    "latitude": "32.98527000",
                    "longitude": "70.60403000"
                },
                {
                    "id": 85356,
                    "name": "Bat Khela",
                    "latitude": "34.61780000",
                    "longitude": "71.97247000"
                },
                {
                    "id": 85357,
                    "name": "Battagram",
                    "latitude": "34.67719000",
                    "longitude": "73.02329000"
                },
                {
                    "id": 85358,
                    "name": "Battagram",
                    "district": true,
                    "latitude": "34.68051000",
                    "longitude": "73.00535000"
                },
                {
                    "id": 85374,
                    "name": "Buner",
                    "district": true,
                    "latitude": "34.44301000",
                    "longitude": "72.49933000"
                },
                {
                    "id": 85387,
                    "name": "Charsadda",
                    "latitude": "34.14822000",
                    "longitude": "71.74060000"
                },
                {
                    "id": 85390,
                    "name": "Cherat Cantonement",
                    "latitude": "33.82342000",
                    "longitude": "71.89292000"
                },
                {
                    "id": 85395,
                    "name": "Chitral",
                    "latitude": "35.85180000",
                    "longitude": "71.78636000"
                },
                {
                    "id": 85419,
                    "name": "Dera Ismail Khan",
                    "latitude": "31.83129000",
                    "longitude": "70.90170000"
                },
                {
                    "id": 85420,
                    "name": "Dera Ismāīl Khān",
                    "district": true,
                    "latitude": "31.85963000",
                    "longitude": "70.64879000"
                },
                {
                    "id": 85430,
                    "name": "Doaba",
                    "latitude": "33.42450000",
                    "longitude": "70.73676000"
                },
                {
                    "id": 85463,
                    "name": "Hangu",
                    "latitude": "33.53198000",
                    "longitude": "71.05950000"
                },
                {
                    "id": 85464,
                    "name": "Haripur",
                    "latitude": "33.99783000",
                    "longitude": "72.93493000"
                },
                {
                    "id": 85470,
                    "name": "Havelian",
                    "latitude": "34.05348000",
                    "longitude": "73.15993000"
                },
                {
                    "id": 85504,
                    "name": "Kakad Wari Dir Upper",
                    "latitude": "34.99798000",
                    "longitude": "72.07295000"
                },
                {
                    "id": 85522,
                    "name": "Karak",
                    "latitude": "33.11633000",
                    "longitude": "71.09354000"
                },
                {
                    "id": 85536,
                    "name": "Khalabat",
                    "latitude": "34.05997000",
                    "longitude": "72.88963000"
                },
                {
                    "id": 85551,
                    "name": "Kohat",
                    "latitude": "33.58196000",
                    "longitude": "71.44929000"
                },
                {
                    "id": 85566,
                    "name": "Kulachi",
                    "latitude": "31.93058000",
                    "longitude": "70.45959000"
                },
                {
                    "id": 85570,
                    "name": "Lachi",
                    "latitude": "33.38291000",
                    "longitude": "71.33733000"
                },
                {
                    "id": 85574,
                    "name": "Lakki",
                    "latitude": "32.60724000",
                    "longitude": "70.91234000"
                },
                {
                    "id": 85597,
                    "name": "Mansehra",
                    "latitude": "34.33023000",
                    "longitude": "73.19679000"
                },
                {
                    "id": 85598,
                    "name": "Mardan",
                    "latitude": "34.19794000",
                    "longitude": "72.04965000"
                },
                {
                    "id": 85610,
                    "name": "Mingora",
                    "latitude": "34.77950000",
                    "longitude": "72.36265000"
                },
                {
                    "id": 85644,
                    "name": "Noorabad",
                    "latitude": "34.25195000",
                    "longitude": "71.96656000"
                },
                {
                    "id": 85646,
                    "name": "Nowshera",
                    "latitude": "34.01583000",
                    "longitude": "71.98123000"
                },
                {
                    "id": 85647,
                    "name": "Nowshera Cantonment",
                    "latitude": "33.99829000",
                    "longitude": "71.99834000"
                },
                {
                    "id": 85652,
                    "name": "Pabbi",
                    "latitude": "34.00968000",
                    "longitude": "71.79445000"
                },
                {
                    "id": 85654,
                    "name": "Paharpur",
                    "latitude": "32.10502000",
                    "longitude": "70.97055000"
                },
                {
                    "id": 85661,
                    "name": "Peshawar",
                    "latitude": "34.00800000",
                    "longitude": "71.57849000"
                },
                {
                    "id": 85686,
                    "name": "Risalpur Cantonment",
                    "latitude": "34.06048000",
                    "longitude": "71.99276000"
                },
                {
                    "id": 85700,
                    "name": "Sarai Naurang",
                    "latitude": "32.82581000",
                    "longitude": "70.78107000"
                },
                {
                    "id": 85705,
                    "name": "Shabqadar",
                    "latitude": "34.21599000",
                    "longitude": "71.55480000"
                },
                {
                    "id": 85716,
                    "name": "Shingli Bala",
                    "latitude": "34.67872000",
                    "longitude": "72.98491000"
                },
                {
                    "id": 85718,
                    "name": "Shorkot",
                    "latitude": "31.91023000",
                    "longitude": "70.87757000"
                },
                {
                    "id": 85733,
                    "name": "Swabi",
                    "latitude": "34.12018000",
                    "longitude": "72.46982000"
                },
                {
                    "id": 85745,
                    "name": "Tangi",
                    "latitude": "34.30090000",
                    "longitude": "71.65238000"
                },
                {
                    "id": 85747,
                    "name": "Tank",
                    "latitude": "32.21707000",
                    "longitude": "70.38315000"
                },
                {
                    "id": 85749,
                    "name": "Thal",
                    "latitude": "35.47836000",
                    "longitude": "72.24383000"
                },
                {
                    "id": 85754,
                    "name": "Topi",
                    "latitude": "34.07034000",
                    "longitude": "72.62147000"
                },
                {
                    "id": 85759,
                    "name": "Upper Dir",
                    "latitude": "35.20740000",
                    "longitude": "71.87680000"
                },
                {
                    "id": 85762,
                    "name": "Utmanzai",
                    "latitude": "34.18775000",
                    "longitude": "71.76274000"
                },
                {
                    "id": 85770,
                    "name": "Zaida",
                    "latitude": "34.05950000",
                    "longitude": "72.46690000"
                }
            ]
        },
        {
            "id": 3176,
            "name": "Punjab",
            "state_code": "PB",
            "latitude": "31.14713050",
            "longitude": "75.34121790",
            "type": null,
            "cities": [
                {
                    "id": 85332,
                    "name": "Ahmadpur Sial",
                    "latitude": "30.67791000",
                    "longitude": "71.74344000"
                },
                {
                    "id": 85331,
                    "name": "Ahmedpur East",
                    "latitude": "29.14269000",
                    "longitude": "71.25771000"
                },
                {
                    "id": 85335,
                    "name": "Alipur Chatha",
                    "latitude": "29.38242000",
                    "longitude": "70.91106000"
                },
                {
                    "id": 85339,
                    "name": "Arifwala",
                    "latitude": "30.29058000",
                    "longitude": "73.06574000"
                },
                {
                    "id": 85341,
                    "name": "Attock Tehsil",
                    "latitude": "33.76671000",
                    "longitude": "72.35977000"
                },
                {
                    "id": 85343,
                    "name": "Baddomalhi",
                    "latitude": "31.99042000",
                    "longitude": "74.66410000"
                },
                {
                    "id": 85347,
                    "name": "Bahawalnagar",
                    "latitude": "29.99835000",
                    "longitude": "73.25272000"
                },
                {
                    "id": 85348,
                    "name": "Bahawalpur",
                    "latitude": "29.39779000",
                    "longitude": "71.67520000"
                },
                {
                    "id": 85349,
                    "name": "Bakhri Ahmad Khan",
                    "latitude": "30.73586000",
                    "longitude": "70.83796000"
                },
                {
                    "id": 85354,
                    "name": "Basirpur",
                    "latitude": "30.57759000",
                    "longitude": "73.83912000"
                },
                {
                    "id": 85355,
                    "name": "Basti Dosa",
                    "latitude": "30.78769000",
                    "longitude": "70.86853000"
                },
                {
                    "id": 85359,
                    "name": "Begowala",
                    "latitude": "32.43816000",
                    "longitude": "74.26794000"
                },
                {
                    "id": 85363,
                    "name": "Bhakkar",
                    "latitude": "31.62685000",
                    "longitude": "71.06471000"
                },
                {
                    "id": 85364,
                    "name": "Bhalwal",
                    "latitude": "32.26576000",
                    "longitude": "72.89809000"
                },
                {
                    "id": 85366,
                    "name": "Bhawana",
                    "latitude": "31.56884000",
                    "longitude": "72.64917000"
                },
                {
                    "id": 85367,
                    "name": "Bhera",
                    "latitude": "32.48206000",
                    "longitude": "72.90865000"
                },
                {
                    "id": 85371,
                    "name": "Bhopalwala",
                    "latitude": "32.42968000",
                    "longitude": "74.36350000"
                },
                {
                    "id": 85376,
                    "name": "Burewala",
                    "latitude": "30.16667000",
                    "longitude": "72.65000000"
                },
                {
                    "id": 85378,
                    "name": "Chak Azam Saffo",
                    "latitude": "30.75202000",
                    "longitude": "73.02834000"
                },
                {
                    "id": 85380,
                    "name": "Chak Jhumra",
                    "latitude": "31.56808000",
                    "longitude": "73.18317000"
                },
                {
                    "id": 85381,
                    "name": "Chak One Hundred Twenty Nine Left",
                    "latitude": "30.42919000",
                    "longitude": "73.04522000"
                },
                {
                    "id": 85382,
                    "name": "Chak Thirty-one -Eleven Left",
                    "latitude": "30.42388000",
                    "longitude": "72.69737000"
                },
                {
                    "id": 85383,
                    "name": "Chak Two Hundred Forty-Nine TDA",
                    "latitude": "31.17772000",
                    "longitude": "71.20480000"
                },
                {
                    "id": 85384,
                    "name": "Chakwal",
                    "latitude": "32.93286000",
                    "longitude": "72.85394000"
                },
                {
                    "id": 85388,
                    "name": "Chawinda",
                    "latitude": "32.34434000",
                    "longitude": "74.70507000"
                },
                {
                    "id": 85392,
                    "name": "Chichawatni",
                    "latitude": "30.53010000",
                    "longitude": "72.69155000"
                },
                {
                    "id": 85393,
                    "name": "Chiniot",
                    "latitude": "31.72091000",
                    "longitude": "72.97836000"
                },
                {
                    "id": 85394,
                    "name": "Chishtian",
                    "latitude": "29.79713000",
                    "longitude": "72.85772000"
                },
                {
                    "id": 85396,
                    "name": "Choa Saidanshah",
                    "latitude": "32.71962000",
                    "longitude": "72.98625000"
                },
                {
                    "id": 143765,
                    "name": "Chuhar Kana",
                    "latitude": "31.75000000",
                    "longitude": "73.80000000"
                },
                {
                    "id": 85400,
                    "name": "Chunian",
                    "latitude": "30.96621000",
                    "longitude": "73.97908000"
                },
                {
                    "id": 85404,
                    "name": "Daira Din Panah",
                    "latitude": "30.57053000",
                    "longitude": "70.93722000"
                },
                {
                    "id": 85405,
                    "name": "Dajal",
                    "latitude": "29.55769000",
                    "longitude": "70.37614000"
                },
                {
                    "id": 85407,
                    "name": "Dandot RS",
                    "latitude": "32.64167000",
                    "longitude": "72.97500000"
                },
                {
                    "id": 85409,
                    "name": "Darya Khan",
                    "latitude": "31.78447000",
                    "longitude": "71.10197000"
                },
                {
                    "id": 85411,
                    "name": "Daska",
                    "latitude": "32.32422000",
                    "longitude": "74.35039000"
                },
                {
                    "id": 143767,
                    "name": "Daud Khel",
                    "latitude": "32.87498000",
                    "longitude": "71.57013000"
                },
                {
                    "id": 85414,
                    "name": "Daultala",
                    "latitude": "33.19282000",
                    "longitude": "73.14099000"
                },
                {
                    "id": 85418,
                    "name": "Dera Ghazi Khan",
                    "latitude": "30.04587000",
                    "longitude": "70.64029000"
                },
                {
                    "id": 85421,
                    "name": "Dhanot",
                    "latitude": "29.57991000",
                    "longitude": "71.75213000"
                },
                {
                    "id": 85422,
                    "name": "Dhaunkal",
                    "latitude": "32.40613000",
                    "longitude": "74.13706000"
                },
                {
                    "id": 143766,
                    "name": "Dhok Awan",
                    "latitude": "32.84452000",
                    "longitude": "72.52357000"
                },
                {
                    "id": 85425,
                    "name": "Dijkot",
                    "latitude": "31.21735000",
                    "longitude": "72.99621000"
                },
                {
                    "id": 85426,
                    "name": "Dinan Bashnoian Wala",
                    "latitude": "29.76584000",
                    "longitude": "73.26557000"
                },
                {
                    "id": 85427,
                    "name": "Dinga",
                    "latitude": "32.64101000",
                    "longitude": "73.72039000"
                },
                {
                    "id": 85428,
                    "name": "Dipalpur",
                    "latitude": "30.66984000",
                    "longitude": "73.65306000"
                },
                {
                    "id": 85433,
                    "name": "Dullewala",
                    "latitude": "31.83439000",
                    "longitude": "71.43639000"
                },
                {
                    "id": 85434,
                    "name": "Dunga Bunga",
                    "latitude": "29.74975000",
                    "longitude": "73.24294000"
                },
                {
                    "id": 85435,
                    "name": "Dunyapur",
                    "latitude": "29.80275000",
                    "longitude": "71.74344000"
                },
                {
                    "id": 85436,
                    "name": "Eminabad",
                    "latitude": "32.04237000",
                    "longitude": "74.25996000"
                },
                {
                    "id": 85437,
                    "name": "Faisalabad",
                    "latitude": "31.41554000",
                    "longitude": "73.08969000"
                },
                {
                    "id": 85438,
                    "name": "Faqirwali",
                    "latitude": "29.46799000",
                    "longitude": "73.03489000"
                },
                {
                    "id": 85439,
                    "name": "Faruka",
                    "latitude": "31.88642000",
                    "longitude": "72.41362000"
                },
                {
                    "id": 85440,
                    "name": "Fazilpur",
                    "latitude": "32.17629000",
                    "longitude": "75.06583000"
                },
                {
                    "id": 143768,
                    "name": "Ferozewala",
                    "latitude": "29.30000000",
                    "longitude": "70.43333333"
                },
                {
                    "id": 85441,
                    "name": "Fort Abbas",
                    "latitude": "29.19344000",
                    "longitude": "72.85525000"
                },
                {
                    "id": 85444,
                    "name": "Garh Maharaja",
                    "latitude": "30.83383000",
                    "longitude": "71.90491000"
                },
                {
                    "id": 85451,
                    "name": "Gojra",
                    "latitude": "31.14926000",
                    "longitude": "72.68323000"
                },
                {
                    "id": 85455,
                    "name": "Gujar Khan",
                    "latitude": "33.25411000",
                    "longitude": "73.30433000"
                },
                {
                    "id": 85456,
                    "name": "Gujranwala",
                    "latitude": "32.15567000",
                    "longitude": "74.18705000"
                },
                {
                    "id": 143769,
                    "name": "Gujranwala Division",
                    "latitude": "32.45000000",
                    "longitude": "74.13333000"
                },
                {
                    "id": 85457,
                    "name": "Gujrat",
                    "latitude": "32.57420000",
                    "longitude": "74.07542000"
                },
                {
                    "id": 85460,
                    "name": "Hadali",
                    "latitude": "32.64043000",
                    "longitude": "74.56898000"
                },
                {
                    "id": 85461,
                    "name": "Hafizabad",
                    "latitude": "32.07095000",
                    "longitude": "73.68802000"
                },
                {
                    "id": 85466,
                    "name": "Harnoli",
                    "latitude": "32.27871000",
                    "longitude": "71.55429000"
                },
                {
                    "id": 85467,
                    "name": "Harunabad",
                    "latitude": "29.61206000",
                    "longitude": "73.13802000"
                },
                {
                    "id": 143770,
                    "name": "Hasan Abdal",
                    "latitude": "33.81948611",
                    "longitude": "72.68902778"
                },
                {
                    "id": 85468,
                    "name": "Hasilpur",
                    "latitude": "29.69221000",
                    "longitude": "72.54566000"
                },
                {
                    "id": 85469,
                    "name": "Haveli Lakha",
                    "latitude": "30.45097000",
                    "longitude": "73.69371000"
                },
                {
                    "id": 85471,
                    "name": "Hazro",
                    "latitude": "33.90990000",
                    "longitude": "72.49179000"
                },
                {
                    "id": 85473,
                    "name": "Hujra Shah Muqeem",
                    "latitude": "30.74168000",
                    "longitude": "73.82327000"
                },
                {
                    "id": 85478,
                    "name": "Jahanian Shah",
                    "latitude": "31.80541000",
                    "longitude": "72.27740000"
                },
                {
                    "id": 85479,
                    "name": "Jalalpur Jattan",
                    "latitude": "32.64118000",
                    "longitude": "74.20561000"
                },
                {
                    "id": 85480,
                    "name": "Jalalpur Pirwala",
                    "latitude": "29.50510000",
                    "longitude": "71.22202000"
                },
                {
                    "id": 85481,
                    "name": "Jampur",
                    "latitude": "29.64235000",
                    "longitude": "70.59518000"
                },
                {
                    "id": 85483,
                    "name": "Jand",
                    "latitude": "33.43304000",
                    "longitude": "72.01877000"
                },
                {
                    "id": 85484,
                    "name": "Jandiala Sher Khan",
                    "latitude": "31.82098000",
                    "longitude": "73.91815000"
                },
                {
                    "id": 85485,
                    "name": "Jaranwala",
                    "latitude": "31.33320000",
                    "longitude": "73.41868000"
                },
                {
                    "id": 85487,
                    "name": "Jatoi Shimali",
                    "latitude": "29.51827000",
                    "longitude": "70.84474000"
                },
                {
                    "id": 85488,
                    "name": "Jauharabad",
                    "latitude": "32.29016000",
                    "longitude": "72.28182000"
                },
                {
                    "id": 85490,
                    "name": "Jhang",
                    "latitude": "31.30568000",
                    "longitude": "72.32594000"
                },
                {
                    "id": 85491,
                    "name": "Jhang Sadar",
                    "latitude": "31.26981000",
                    "longitude": "72.31687000"
                },
                {
                    "id": 85492,
                    "name": "Jhawarian",
                    "latitude": "32.36192000",
                    "longitude": "72.62275000"
                },
                {
                    "id": 85493,
                    "name": "Jhelum",
                    "latitude": "32.93448000",
                    "longitude": "73.73102000"
                },
                {
                    "id": 85499,
                    "name": "Kabirwala",
                    "latitude": "30.40472000",
                    "longitude": "71.86269000"
                },
                {
                    "id": 85501,
                    "name": "Kahna Nau",
                    "latitude": "31.36709000",
                    "longitude": "74.36899000"
                },
                {
                    "id": 85503,
                    "name": "Kahuta",
                    "latitude": "33.59183000",
                    "longitude": "73.38736000"
                },
                {
                    "id": 85505,
                    "name": "Kalabagh",
                    "latitude": "32.96164000",
                    "longitude": "71.54638000"
                },
                {
                    "id": 85506,
                    "name": "Kalaswala",
                    "latitude": "32.20081000",
                    "longitude": "74.64858000"
                },
                {
                    "id": 85508,
                    "name": "Kaleke Mandi",
                    "latitude": "31.97597000",
                    "longitude": "73.59999000"
                },
                {
                    "id": 85509,
                    "name": "Kallar Kahar",
                    "latitude": "32.77998000",
                    "longitude": "72.69793000"
                },
                {
                    "id": 85510,
                    "name": "Kalur Kot",
                    "latitude": "32.15512000",
                    "longitude": "71.26631000"
                },
                {
                    "id": 85512,
                    "name": "Kamalia",
                    "latitude": "30.72708000",
                    "longitude": "72.64607000"
                },
                {
                    "id": 85513,
                    "name": "Kamar Mushani",
                    "latitude": "32.84318000",
                    "longitude": "71.36192000"
                },
                {
                    "id": 85515,
                    "name": "Kamoke",
                    "latitude": "31.97526000",
                    "longitude": "74.22304000"
                },
                {
                    "id": 85516,
                    "name": "Kamra",
                    "latitude": "33.74698000",
                    "longitude": "73.51229000"
                },
                {
                    "id": 85520,
                    "name": "Kanganpur",
                    "latitude": "30.76468000",
                    "longitude": "74.12286000"
                },
                {
                    "id": 85525,
                    "name": "Karor",
                    "latitude": "31.22460000",
                    "longitude": "70.95153000"
                },
                {
                    "id": 85527,
                    "name": "Kasur",
                    "latitude": "31.11866000",
                    "longitude": "74.45025000"
                },
                {
                    "id": 85528,
                    "name": "Keshupur",
                    "latitude": "32.26000000",
                    "longitude": "72.50000000"
                },
                {
                    "id": 85535,
                    "name": "Khairpur Tamiwali",
                    "latitude": "29.58139000",
                    "longitude": "72.23804000"
                },
                {
                    "id": 85537,
                    "name": "Khandowa",
                    "latitude": "32.74255000",
                    "longitude": "72.73478000"
                },
                {
                    "id": 85538,
                    "name": "Khanewal",
                    "latitude": "30.30173000",
                    "longitude": "71.93212000"
                },
                {
                    "id": 85539,
                    "name": "Khanga Dogran",
                    "latitude": "31.83294000",
                    "longitude": "73.62213000"
                },
                {
                    "id": 85540,
                    "name": "Khangarh",
                    "latitude": "29.91446000",
                    "longitude": "71.16067000"
                },
                {
                    "id": 85541,
                    "name": "Khanpur",
                    "latitude": "28.64739000",
                    "longitude": "70.65694000"
                },
                {
                    "id": 85544,
                    "name": "Kharian",
                    "latitude": "32.81612000",
                    "longitude": "73.88697000"
                },
                {
                    "id": 85545,
                    "name": "Khewra",
                    "latitude": "32.64910000",
                    "longitude": "73.01059000"
                },
                {
                    "id": 85546,
                    "name": "Khurrianwala",
                    "latitude": "31.49936000",
                    "longitude": "73.26763000"
                },
                {
                    "id": 85547,
                    "name": "Khushab",
                    "latitude": "32.29667000",
                    "longitude": "72.35250000"
                },
                {
                    "id": 143771,
                    "name": "Kohror Pakka",
                    "latitude": "29.62382000",
                    "longitude": "71.91673000"
                },
                {
                    "id": 85553,
                    "name": "Kot Addu Tehsil",
                    "latitude": "30.46907000",
                    "longitude": "70.96699000"
                },
                {
                    "id": 85555,
                    "name": "Kot Ghulam Muhammad",
                    "latitude": "32.33311000",
                    "longitude": "74.54694000"
                },
                {
                    "id": 85557,
                    "name": "Kot Mumin",
                    "latitude": "32.18843000",
                    "longitude": "73.02987000"
                },
                {
                    "id": 85558,
                    "name": "Kot Radha Kishan",
                    "latitude": "31.17068000",
                    "longitude": "74.10126000"
                },
                {
                    "id": 85559,
                    "name": "Kot Rajkour",
                    "latitude": "32.41208000",
                    "longitude": "74.62855000"
                },
                {
                    "id": 85560,
                    "name": "Kot Samaba",
                    "latitude": "28.55207000",
                    "longitude": "70.46837000"
                },
                {
                    "id": 85561,
                    "name": "Kot Sultan",
                    "latitude": "30.77370000",
                    "longitude": "70.93125000"
                },
                {
                    "id": 85564,
                    "name": "Kotli Loharan",
                    "latitude": "32.58893000",
                    "longitude": "74.49466000"
                },
                {
                    "id": 85567,
                    "name": "Kundian",
                    "latitude": "32.45775000",
                    "longitude": "71.47892000"
                },
                {
                    "id": 85568,
                    "name": "Kunjah",
                    "latitude": "32.52982000",
                    "longitude": "73.97486000"
                },
                {
                    "id": 85571,
                    "name": "Ladhewala Waraich",
                    "latitude": "32.15692000",
                    "longitude": "74.11564000"
                },
                {
                    "id": 85572,
                    "name": "Lahore",
                    "latitude": "31.55800000",
                    "longitude": "74.35071000"
                },
                {
                    "id": 85575,
                    "name": "Lala Musa",
                    "latitude": "32.70138000",
                    "longitude": "73.95746000"
                },
                {
                    "id": 85576,
                    "name": "Lalian",
                    "latitude": "31.82462000",
                    "longitude": "72.80116000"
                },
                {
                    "id": 85580,
                    "name": "Layyah",
                    "latitude": "30.96128000",
                    "longitude": "70.93904000"
                },
                {
                    "id": 85581,
                    "name": "Layyah",
                    "district": true,
                    "latitude": "30.96800000",
                    "longitude": "70.94300000"
                },
                {
                    "id": 85582,
                    "name": "Liliani",
                    "latitude": "32.20393000",
                    "longitude": "72.95120000"
                },
                {
                    "id": 85583,
                    "name": "Lodhran",
                    "latitude": "29.53390000",
                    "longitude": "71.63244000"
                },
                {
                    "id": 85588,
                    "name": "Mailsi",
                    "latitude": "29.80123000",
                    "longitude": "72.17398000"
                },
                {
                    "id": 85589,
                    "name": "Malakwal",
                    "latitude": "32.55449000",
                    "longitude": "73.21274000"
                },
                {
                    "id": 85590,
                    "name": "Malakwal City",
                    "latitude": "32.55492000",
                    "longitude": "73.21220000"
                },
                {
                    "id": 85592,
                    "name": "Mamu Kanjan",
                    "latitude": "30.83044000",
                    "longitude": "72.79943000"
                },
                {
                    "id": 85593,
                    "name": "Mananwala",
                    "latitude": "31.58803000",
                    "longitude": "73.68927000"
                },
                {
                    "id": 85594,
                    "name": "Mandi Bahauddin",
                    "latitude": "32.58704000",
                    "longitude": "73.49123000"
                },
                {
                    "id": 143772,
                    "name": "Mandi Bahauddin",
                    "district": true,
                    "latitude": "32.58333333",
                    "longitude": "73.50000000"
                },
                {
                    "id": 85595,
                    "name": "Mangla",
                    "latitude": "31.89306000",
                    "longitude": "72.38167000"
                },
                {
                    "id": 85596,
                    "name": "Mankera",
                    "latitude": "31.38771000",
                    "longitude": "71.44047000"
                },
                {
                    "id": 85604,
                    "name": "Mehmand Chak",
                    "latitude": "32.78518000",
                    "longitude": "73.82306000"
                },
                {
                    "id": 85606,
                    "name": "Mian Channun",
                    "latitude": "30.44067000",
                    "longitude": "72.35679000"
                },
                {
                    "id": 85607,
                    "name": "Mianke Mor",
                    "latitude": "31.20240000",
                    "longitude": "73.94857000"
                },
                {
                    "id": 85608,
                    "name": "Mianwali",
                    "latitude": "32.57756000",
                    "longitude": "71.52847000"
                },
                {
                    "id": 85609,
                    "name": "Minchinabad",
                    "latitude": "30.16356000",
                    "longitude": "73.56858000"
                },
                {
                    "id": 85619,
                    "name": "Mitha Tiwana",
                    "latitude": "32.24540000",
                    "longitude": "72.10615000"
                },
                {
                    "id": 85622,
                    "name": "Moza Shahwala",
                    "latitude": "30.80563000",
                    "longitude": "70.84911000"
                },
                {
                    "id": 85623,
                    "name": "Multan",
                    "latitude": "30.19679000",
                    "longitude": "71.47824000"
                },
                {
                    "id": 143773,
                    "name": "Multan",
                    "district": true,
                    "latitude": "30.08333333",
                    "longitude": "71.66666667"
                },
                {
                    "id": 85624,
                    "name": "Muridke",
                    "latitude": "31.80258000",
                    "longitude": "74.25772000"
                },
                {
                    "id": 85625,
                    "name": "Murree",
                    "latitude": "33.90836000",
                    "longitude": "73.39030000"
                },
                {
                    "id": 85626,
                    "name": "Mustafabad",
                    "latitude": "30.89222000",
                    "longitude": "73.49889000"
                },
                {
                    "id": 85627,
                    "name": "Muzaffargarh",
                    "latitude": "30.07258000",
                    "longitude": "71.19379000"
                },
                {
                    "id": 85631,
                    "name": "Nankana Sahib",
                    "latitude": "31.45010000",
                    "longitude": "73.70653000"
                },
                {
                    "id": 85632,
                    "name": "Narang Mandi",
                    "latitude": "31.90376000",
                    "longitude": "74.51587000"
                },
                {
                    "id": 85633,
                    "name": "Narowal",
                    "latitude": "32.10197000",
                    "longitude": "74.87303000"
                },
                {
                    "id": 85638,
                    "name": "Naushahra Virkan",
                    "latitude": "31.96258000",
                    "longitude": "73.97117000"
                },
                {
                    "id": 85641,
                    "name": "Nazir Town",
                    "latitude": "33.30614000",
                    "longitude": "73.48330000"
                },
                {
                    "id": 85650,
                    "name": "Okara",
                    "latitude": "30.81029000",
                    "longitude": "73.45155000"
                },
                {
                    "id": 143774,
                    "name": "Pakki Shagwanwali",
                    "latitude": "30.79033000",
                    "longitude": "70.87139000"
                },
                {
                    "id": 85655,
                    "name": "Pakpattan",
                    "latitude": "30.34314000",
                    "longitude": "73.38944000"
                },
                {
                    "id": 85659,
                    "name": "Pasrur",
                    "latitude": "32.26286000",
                    "longitude": "74.66327000"
                },
                {
                    "id": 85660,
                    "name": "Pattoki",
                    "latitude": "31.02021000",
                    "longitude": "73.85333000"
                },
                {
                    "id": 85662,
                    "name": "Phalia",
                    "latitude": "32.43104000",
                    "longitude": "73.57900000"
                },
                {
                    "id": 85663,
                    "name": "Pind Dadan Khan",
                    "latitude": "32.58662000",
                    "longitude": "73.04456000"
                },
                {
                    "id": 85664,
                    "name": "Pindi Bhattian",
                    "latitude": "31.89844000",
                    "longitude": "73.27339000"
                },
                {
                    "id": 85665,
                    "name": "Pindi Gheb",
                    "latitude": "33.24095000",
                    "longitude": "72.26480000"
                },
                {
                    "id": 85667,
                    "name": "Pir Mahal",
                    "latitude": "30.76663000",
                    "longitude": "72.43455000"
                },
                {
                    "id": 85670,
                    "name": "Qadirpur Ran",
                    "latitude": "30.29184000",
                    "longitude": "71.67164000"
                },
                {
                    "id": 143775,
                    "name": "Qila Didar Singh",
                    "latitude": "32.80000000",
                    "longitude": "74.10000000"
                },
                {
                    "id": 85389,
                    "name": "Rabwah",
                    "latitude": "31.75511000",
                    "longitude": "72.91403000"
                },
                {
                    "id": 85674,
                    "name": "Rahim Yar Khan",
                    "latitude": "28.41987000",
                    "longitude": "70.30345000"
                },
                {
                    "id": 143776,
                    "name": "Rahimyar Khan",
                    "district": true,
                    "latitude": "28.46020000",
                    "longitude": "70.52837000"
                },
                {
                    "id": 85675,
                    "name": "Raiwind",
                    "latitude": "31.24895000",
                    "longitude": "74.21534000"
                },
                {
                    "id": 85676,
                    "name": "Raja Jang",
                    "latitude": "31.22078000",
                    "longitude": "74.25483000"
                },
                {
                    "id": 85677,
                    "name": "Rajanpur",
                    "latitude": "29.10408000",
                    "longitude": "70.32969000"
                },
                {
                    "id": 85680,
                    "name": "Rasulnagar",
                    "latitude": "32.32794000",
                    "longitude": "73.78040000"
                },
                {
                    "id": 85683,
                    "name": "Rawalpindi",
                    "latitude": "33.59733000",
                    "longitude": "73.04790000"
                },
                {
                    "id": 85684,
                    "name": "Rawalpindi",
                    "district": true,
                    "latitude": "33.42987000",
                    "longitude": "73.23092000"
                },
                {
                    "id": 85685,
                    "name": "Renala Khurd",
                    "latitude": "30.87878000",
                    "longitude": "73.59857000"
                },
                {
                    "id": 85688,
                    "name": "Rojhan",
                    "latitude": "28.68735000",
                    "longitude": "69.95350000"
                },
                {
                    "id": 85690,
                    "name": "Sadiqabad",
                    "latitude": "28.30910000",
                    "longitude": "70.12652000"
                },
                {
                    "id": 85691,
                    "name": "Sahiwal",
                    "latitude": "30.66595000",
                    "longitude": "73.10186000"
                },
                {
                    "id": 85694,
                    "name": "Sambrial",
                    "latitude": "32.47835000",
                    "longitude": "74.35338000"
                },
                {
                    "id": 85696,
                    "name": "Sangla Hill",
                    "latitude": "31.71667000",
                    "longitude": "73.38333000"
                },
                {
                    "id": 85697,
                    "name": "Sanjwal",
                    "latitude": "33.76105000",
                    "longitude": "72.43315000"
                },
                {
                    "id": 85699,
                    "name": "Sarai Alamgir",
                    "latitude": "32.90495000",
                    "longitude": "73.75518000"
                },
                {
                    "id": 85701,
                    "name": "Sarai Sidhu",
                    "latitude": "30.59476000",
                    "longitude": "71.96990000"
                },
                {
                    "id": 85702,
                    "name": "Sargodha",
                    "latitude": "32.08586000",
                    "longitude": "72.67418000"
                },
                {
                    "id": 143777,
                    "name": "Shahkot Tehsil",
                    "latitude": "31.56166667",
                    "longitude": "73.48750000"
                },
                {
                    "id": 85709,
                    "name": "Shahpur",
                    "latitude": "32.26820000",
                    "longitude": "72.46884000"
                },
                {
                    "id": 85711,
                    "name": "Shahr Sultan",
                    "latitude": "29.57517000",
                    "longitude": "71.02209000"
                },
                {
                    "id": 85712,
                    "name": "Shakargarh",
                    "latitude": "32.26361000",
                    "longitude": "75.16008000"
                },
                {
                    "id": 85713,
                    "name": "Sharqpur",
                    "latitude": "31.46116000",
                    "longitude": "74.10091000"
                },
                {
                    "id": 85714,
                    "name": "Sheikhupura",
                    "latitude": "31.71287000",
                    "longitude": "73.98556000"
                },
                {
                    "id": 85708,
                    "name": "Shorkot",
                    "latitude": "30.50000000",
                    "longitude": "72.40000000"
                },
                {
                    "id": 85719,
                    "name": "Shujaabad",
                    "latitude": "29.88092000",
                    "longitude": "71.29344000"
                },
                {
                    "id": 85720,
                    "name": "Sialkot",
                    "latitude": "32.49268000",
                    "longitude": "74.53134000"
                },
                {
                    "id": 85722,
                    "name": "Sillanwali",
                    "latitude": "31.82539000",
                    "longitude": "72.54064000"
                },
                {
                    "id": 85726,
                    "name": "Sodhra",
                    "latitude": "32.46211000",
                    "longitude": "74.18207000"
                },
                {
                    "id": 85729,
                    "name": "Sukheke Mandi",
                    "latitude": "31.86541000",
                    "longitude": "73.50875000"
                },
                {
                    "id": 85732,
                    "name": "Surkhpur",
                    "latitude": "32.71816000",
                    "longitude": "74.44773000"
                },
                {
                    "id": 85735,
                    "name": "Talagang",
                    "latitude": "32.92766000",
                    "longitude": "72.41594000"
                },
                {
                    "id": 85736,
                    "name": "Talamba",
                    "latitude": "30.52693000",
                    "longitude": "72.24079000"
                },
                {
                    "id": 85738,
                    "name": "Tandlianwala",
                    "latitude": "31.03359000",
                    "longitude": "73.13268000"
                },
                {
                    "id": 85748,
                    "name": "Taunsa",
                    "latitude": "30.70358000",
                    "longitude": "70.65054000"
                },
                {
                    "id": 85753,
                    "name": "Toba Tek Singh",
                    "latitude": "30.97127000",
                    "longitude": "72.48275000"
                },
                {
                    "id": 143778,
                    "name": "Umerkot",
                    "latitude": "25.36138889",
                    "longitude": "69.73611111"
                },
                {
                    "id": 85763,
                    "name": "Vihari",
                    "latitude": "30.04450000",
                    "longitude": "72.35560000"
                },
                {
                    "id": 143779,
                    "name": "Wah",
                    "latitude": "33.81000000",
                    "longitude": "72.70972222"
                },
                {
                    "id": 143780,
                    "name": "Warburton",
                    "latitude": "31.55000000",
                    "longitude": "73.83330000"
                },
                {
                    "id": 85766,
                    "name": "Wazirabad",
                    "latitude": "32.44324000",
                    "longitude": "74.12000000"
                },
                {
                    "id": 143781,
                    "name": "West Punjab",
                    "latitude": "31.00000000",
                    "longitude": "72.00000000"
                },
                {
                    "id": 85767,
                    "name": "Yazman",
                    "latitude": "29.12122000",
                    "longitude": "71.74459000"
                },
                {
                    "id": 85768,
                    "name": "Zafarwal",
                    "latitude": "32.34464000",
                    "longitude": "74.89990000"
                },
                {
                    "id": 85769,
                    "name": "Zahir Pir",
                    "latitude": "28.81284000",
                    "longitude": "70.52341000"
                }
            ]
        },
        {
            "id": 3175,
            "name": "Sindh",
            "state_code": "SD",
            "latitude": "25.89430180",
            "longitude": "68.52471490",
            "type": null,
            "cities": [
                {
                    "id": 85330,
                    "name": "Adilpur",
                    "latitude": "27.93677000",
                    "longitude": "69.31941000"
                },
                {
                    "id": 85344,
                    "name": "Badin",
                    "latitude": "24.65600000",
                    "longitude": "68.83700000"
                },
                {
                    "id": 85346,
                    "name": "Bagarji",
                    "latitude": "27.75431000",
                    "longitude": "68.75866000"
                },
                {
                    "id": 85350,
                    "name": "Bandhi",
                    "latitude": "26.58761000",
                    "longitude": "68.30215000"
                },
                {
                    "id": 85361,
                    "name": "Berani",
                    "latitude": "25.78497000",
                    "longitude": "68.80754000"
                },
                {
                    "id": 85365,
                    "name": "Bhan",
                    "latitude": "26.55831000",
                    "longitude": "67.72139000"
                },
                {
                    "id": 85369,
                    "name": "Bhiria",
                    "latitude": "26.91041000",
                    "longitude": "68.19466000"
                },
                {
                    "id": 85370,
                    "name": "Bhit Shah",
                    "latitude": "25.80565000",
                    "longitude": "68.49143000"
                },
                {
                    "id": 85372,
                    "name": "Bozdar Wada",
                    "latitude": "27.18300000",
                    "longitude": "68.63580000"
                },
                {
                    "id": 85373,
                    "name": "Bulri",
                    "latitude": "24.86667000",
                    "longitude": "68.33333000"
                },
                {
                    "id": 85377,
                    "name": "Chak",
                    "latitude": "27.85838000",
                    "longitude": "68.83378000"
                },
                {
                    "id": 85386,
                    "name": "Chamber",
                    "latitude": "25.29362000",
                    "longitude": "68.81176000"
                },
                {
                    "id": 85391,
                    "name": "Chhor",
                    "latitude": "25.51260000",
                    "longitude": "69.78437000"
                },
                {
                    "id": 85399,
                    "name": "Chuhar Jamali",
                    "latitude": "24.39440000",
                    "longitude": "67.99298000"
                },
                {
                    "id": 85403,
                    "name": "Dadu",
                    "latitude": "26.73033000",
                    "longitude": "67.77690000"
                },
                {
                    "id": 85408,
                    "name": "Daromehar",
                    "latitude": "24.79382000",
                    "longitude": "68.17978000"
                },
                {
                    "id": 85410,
                    "name": "Darya Khan Marri",
                    "latitude": "26.67765000",
                    "longitude": "68.28666000"
                },
                {
                    "id": 85413,
                    "name": "Daulatpur",
                    "latitude": "26.50158000",
                    "longitude": "67.97079000"
                },
                {
                    "id": 85415,
                    "name": "Daur",
                    "latitude": "26.45528000",
                    "longitude": "68.31835000"
                },
                {
                    "id": 85423,
                    "name": "Dhoro Naro",
                    "latitude": "25.50484000",
                    "longitude": "69.57090000"
                },
                {
                    "id": 85424,
                    "name": "Digri",
                    "latitude": "25.15657000",
                    "longitude": "69.11098000"
                },
                {
                    "id": 85429,
                    "name": "Diplo",
                    "latitude": "24.46688000",
                    "longitude": "69.58114000"
                },
                {
                    "id": 85431,
                    "name": "Dokri",
                    "latitude": "27.37421000",
                    "longitude": "68.09715000"
                },
                {
                    "id": 85443,
                    "name": "Gambat",
                    "latitude": "27.35170000",
                    "longitude": "68.52150000"
                },
                {
                    "id": 85446,
                    "name": "Garhiyasin",
                    "latitude": "27.90631000",
                    "longitude": "68.51210000"
                },
                {
                    "id": 85447,
                    "name": "Gharo",
                    "latitude": "24.74182000",
                    "longitude": "67.58534000"
                },
                {
                    "id": 85448,
                    "name": "Ghauspur",
                    "latitude": "28.13882000",
                    "longitude": "69.08245000"
                },
                {
                    "id": 85449,
                    "name": "Ghotki",
                    "latitude": "28.00437000",
                    "longitude": "69.31569000"
                },
                {
                    "id": 85452,
                    "name": "Goth Garelo",
                    "latitude": "27.43521000",
                    "longitude": "68.07572000"
                },
                {
                    "id": 85453,
                    "name": "Goth Phulji",
                    "latitude": "26.88099000",
                    "longitude": "67.68239000"
                },
                {
                    "id": 85454,
                    "name": "Goth Radhan",
                    "latitude": "27.19846000",
                    "longitude": "67.95348000"
                },
                {
                    "id": 85462,
                    "name": "Hala",
                    "latitude": "25.81459000",
                    "longitude": "68.42198000"
                },
                {
                    "id": 85472,
                    "name": "Hingorja",
                    "latitude": "27.21088000",
                    "longitude": "68.41598000"
                },
                {
                    "id": 85474,
                    "name": "Hyderabad",
                    "latitude": "25.39242000",
                    "longitude": "68.37366000"
                },
                {
                    "id": 85476,
                    "name": "Islamkot",
                    "latitude": "24.69904000",
                    "longitude": "70.17982000"
                },
                {
                    "id": 85477,
                    "name": "Jacobabad",
                    "latitude": "28.28187000",
                    "longitude": "68.43761000"
                },
                {
                    "id": 85498,
                    "name": "Jām Sāhib",
                    "latitude": "26.29583000",
                    "longitude": "68.62917000"
                },
                {
                    "id": 85482,
                    "name": "Jamshoro",
                    "latitude": "25.43608000",
                    "longitude": "68.28017000"
                },
                {
                    "id": 85486,
                    "name": "Jati",
                    "latitude": "24.35492000",
                    "longitude": "68.26732000"
                },
                {
                    "id": 85494,
                    "name": "Jhol",
                    "latitude": "25.95533000",
                    "longitude": "68.88871000"
                },
                {
                    "id": 85496,
                    "name": "Johi",
                    "latitude": "26.69225000",
                    "longitude": "67.61431000"
                },
                {
                    "id": 85500,
                    "name": "Kadhan",
                    "latitude": "24.48041000",
                    "longitude": "68.98551000"
                },
                {
                    "id": 85514,
                    "name": "Kambar",
                    "latitude": "27.58753000",
                    "longitude": "68.00066000"
                },
                {
                    "id": 85517,
                    "name": "Kandhkot",
                    "latitude": "28.24574000",
                    "longitude": "69.17974000"
                },
                {
                    "id": 85518,
                    "name": "Kandiari",
                    "latitude": "26.91550000",
                    "longitude": "68.52193000"
                },
                {
                    "id": 85519,
                    "name": "Kandiaro",
                    "latitude": "27.05918000",
                    "longitude": "68.21022000"
                },
                {
                    "id": 85521,
                    "name": "Karachi",
                    "latitude": "24.86080000",
                    "longitude": "67.01040000"
                },
                {
                    "id": 85523,
                    "name": "Karaundi",
                    "latitude": "26.89709000",
                    "longitude": "68.40643000"
                },
                {
                    "id": 85524,
                    "name": "Kario Ghanwar",
                    "latitude": "24.80817000",
                    "longitude": "68.60483000"
                },
                {
                    "id": 85526,
                    "name": "Kashmor",
                    "latitude": "28.43260000",
                    "longitude": "69.58364000"
                },
                {
                    "id": 85529,
                    "name": "Keti Bandar",
                    "latitude": "24.14422000",
                    "longitude": "67.45094000"
                },
                {
                    "id": 85531,
                    "name": "Khadro",
                    "latitude": "26.14713000",
                    "longitude": "68.71777000"
                },
                {
                    "id": 85532,
                    "name": "Khairpur",
                    "latitude": "28.06437000",
                    "longitude": "69.70363000"
                },
                {
                    "id": 85533,
                    "name": "Khairpur Mir’s",
                    "latitude": "27.52948000",
                    "longitude": "68.75915000"
                },
                {
                    "id": 85534,
                    "name": "Khairpur Nathan Shah",
                    "latitude": "27.09064000",
                    "longitude": "67.73489000"
                },
                {
                    "id": 85542,
                    "name": "Khanpur Mahar",
                    "latitude": "27.84088000",
                    "longitude": "69.41302000"
                },
                {
                    "id": 85554,
                    "name": "Kot Diji",
                    "latitude": "27.34156000",
                    "longitude": "68.70821000"
                },
                {
                    "id": 85565,
                    "name": "Kotri",
                    "latitude": "25.36566000",
                    "longitude": "68.30831000"
                },
                {
                    "id": 85569,
                    "name": "Kunri",
                    "latitude": "25.17874000",
                    "longitude": "69.56572000"
                },
                {
                    "id": 85573,
                    "name": "Lakhi",
                    "latitude": "27.84884000",
                    "longitude": "68.69972000"
                },
                {
                    "id": 85578,
                    "name": "Larkana",
                    "latitude": "27.55898000",
                    "longitude": "68.21204000"
                },
                {
                    "id": 85587,
                    "name": "Madeji",
                    "latitude": "27.75314000",
                    "longitude": "68.45166000"
                },
                {
                    "id": 85591,
                    "name": "Malir Cantonment",
                    "latitude": "24.94343000",
                    "longitude": "67.20591000"
                },
                {
                    "id": 85601,
                    "name": "Matiari",
                    "latitude": "25.59709000",
                    "longitude": "68.44670000"
                },
                {
                    "id": 85602,
                    "name": "Matli",
                    "latitude": "25.04290000",
                    "longitude": "68.65591000"
                },
                {
                    "id": 85603,
                    "name": "Mehar",
                    "latitude": "27.18027000",
                    "longitude": "67.82051000"
                },
                {
                    "id": 85612,
                    "name": "Miro Khan",
                    "latitude": "27.75985000",
                    "longitude": "68.09195000"
                },
                {
                    "id": 85613,
                    "name": "Mirpur Bhtoro",
                    "latitude": "24.72852000",
                    "longitude": "68.26010000"
                },
                {
                    "id": 85615,
                    "name": "Mirpur Khas",
                    "latitude": "25.52760000",
                    "longitude": "69.01255000"
                },
                {
                    "id": 85616,
                    "name": "Mirpur Mathelo",
                    "latitude": "28.02136000",
                    "longitude": "69.54914000"
                },
                {
                    "id": 85617,
                    "name": "Mirpur Sakro",
                    "latitude": "24.54692000",
                    "longitude": "67.62797000"
                },
                {
                    "id": 85618,
                    "name": "Mirwah Gorchani",
                    "latitude": "25.30981000",
                    "longitude": "69.05019000"
                },
                {
                    "id": 85620,
                    "name": "Mithi",
                    "latitude": "24.73701000",
                    "longitude": "69.79707000"
                },
                {
                    "id": 85621,
                    "name": "Moro",
                    "latitude": "26.66317000",
                    "longitude": "68.00016000"
                },
                {
                    "id": 85630,
                    "name": "Nabisar",
                    "latitude": "25.06717000",
                    "longitude": "69.64340000"
                },
                {
                    "id": 85634,
                    "name": "Nasirabad",
                    "latitude": "27.38137000",
                    "longitude": "67.91644000"
                },
                {
                    "id": 85636,
                    "name": "Naudero",
                    "latitude": "27.66684000",
                    "longitude": "68.36090000"
                },
                {
                    "id": 85637,
                    "name": "Naukot",
                    "latitude": "24.85822000",
                    "longitude": "69.40153000"
                },
                {
                    "id": 85639,
                    "name": "Naushahro Firoz",
                    "latitude": "26.84010000",
                    "longitude": "68.12265000"
                },
                {
                    "id": 85640,
                    "name": "Nawabshah",
                    "latitude": "26.23939000",
                    "longitude": "68.40369000"
                },
                {
                    "id": 85642,
                    "name": "New Bādāh",
                    "latitude": "27.34167000",
                    "longitude": "68.03194000"
                },
                {
                    "id": 85653,
                    "name": "Pad Idan",
                    "latitude": "26.77455000",
                    "longitude": "68.30094000"
                },
                {
                    "id": 85657,
                    "name": "Pano Aqil",
                    "latitude": "27.85619000",
                    "longitude": "69.11111000"
                },
                {
                    "id": 85666,
                    "name": "Pir Jo Goth",
                    "latitude": "27.59178000",
                    "longitude": "68.61848000"
                },
                {
                    "id": 85669,
                    "name": "Pithoro",
                    "latitude": "25.51122000",
                    "longitude": "69.37803000"
                },
                {
                    "id": 85678,
                    "name": "Rajo Khanani",
                    "latitude": "24.98391000",
                    "longitude": "68.85370000"
                },
                {
                    "id": 85679,
                    "name": "Ranipur",
                    "latitude": "27.28720000",
                    "longitude": "68.50623000"
                },
                {
                    "id": 85681,
                    "name": "Ratodero",
                    "latitude": "27.80227000",
                    "longitude": "68.28902000"
                },
                {
                    "id": 85687,
                    "name": "Rohri",
                    "latitude": "27.69203000",
                    "longitude": "68.89503000"
                },
                {
                    "id": 85689,
                    "name": "Rustam",
                    "latitude": "27.96705000",
                    "longitude": "68.80386000"
                },
                {
                    "id": 85692,
                    "name": "Sakrand",
                    "latitude": "26.13845000",
                    "longitude": "68.27444000"
                },
                {
                    "id": 85693,
                    "name": "Samaro",
                    "latitude": "25.28143000",
                    "longitude": "69.39623000"
                },
                {
                    "id": 85695,
                    "name": "Sanghar",
                    "latitude": "26.04694000",
                    "longitude": "68.94917000"
                },
                {
                    "id": 85698,
                    "name": "Sann",
                    "latitude": "26.04030000",
                    "longitude": "68.13763000"
                },
                {
                    "id": 85703,
                    "name": "Sehwan",
                    "latitude": "26.42495000",
                    "longitude": "67.86126000"
                },
                {
                    "id": 85704,
                    "name": "Setharja Old",
                    "latitude": "27.21270000",
                    "longitude": "68.46883000"
                },
                {
                    "id": 85706,
                    "name": "Shahdad Kot",
                    "latitude": "27.84726000",
                    "longitude": "67.90679000"
                },
                {
                    "id": 85707,
                    "name": "Shahdadpur",
                    "latitude": "25.92539000",
                    "longitude": "68.62280000"
                },
                {
                    "id": 85710,
                    "name": "Shahpur Chakar",
                    "latitude": "26.15411000",
                    "longitude": "68.65013000"
                },
                {
                    "id": 85715,
                    "name": "Shikarpur",
                    "latitude": "27.95558000",
                    "longitude": "68.63823000"
                },
                {
                    "id": 85723,
                    "name": "Sinjhoro",
                    "latitude": "26.03008000",
                    "longitude": "68.80867000"
                },
                {
                    "id": 85734,
                    "name": "Sīta Road",
                    "latitude": "27.03333000",
                    "longitude": "67.85000000"
                },
                {
                    "id": 85725,
                    "name": "Sobhodero",
                    "latitude": "27.30475000",
                    "longitude": "68.39715000"
                },
                {
                    "id": 85730,
                    "name": "Sukkur",
                    "latitude": "27.70323000",
                    "longitude": "68.85889000"
                },
                {
                    "id": 85737,
                    "name": "Talhar",
                    "latitude": "24.88454000",
                    "longitude": "68.81437000"
                },
                {
                    "id": 85739,
                    "name": "Tando Adam",
                    "latitude": "25.76818000",
                    "longitude": "68.66196000"
                },
                {
                    "id": 85740,
                    "name": "Tando Allahyar",
                    "latitude": "25.46050000",
                    "longitude": "68.71745000"
                },
                {
                    "id": 85741,
                    "name": "Tando Bago",
                    "latitude": "24.78914000",
                    "longitude": "68.96535000"
                },
                {
                    "id": 85742,
                    "name": "Tando Jam",
                    "latitude": "25.42813000",
                    "longitude": "68.52923000"
                },
                {
                    "id": 85743,
                    "name": "Tando Mitha Khan",
                    "latitude": "25.99625000",
                    "longitude": "69.20251000"
                },
                {
                    "id": 85744,
                    "name": "Tando Muhammad Khan",
                    "latitude": "25.12384000",
                    "longitude": "68.53677000"
                },
                {
                    "id": 85746,
                    "name": "Tangwani",
                    "latitude": "28.27886000",
                    "longitude": "68.99760000"
                },
                {
                    "id": 85750,
                    "name": "Tharu Shah",
                    "latitude": "26.94230000",
                    "longitude": "68.11759000"
                },
                {
                    "id": 85751,
                    "name": "Thatta",
                    "latitude": "24.74745000",
                    "longitude": "67.92353000"
                },
                {
                    "id": 85752,
                    "name": "Thul",
                    "latitude": "28.24030000",
                    "longitude": "68.77550000"
                },
                {
                    "id": 85756,
                    "name": "Ubauro",
                    "latitude": "28.16429000",
                    "longitude": "69.73114000"
                },
                {
                    "id": 85757,
                    "name": "Umarkot",
                    "latitude": "25.36329000",
                    "longitude": "69.74184000"
                },
                {
                    "id": 85758,
                    "name": "Umerkot",
                    "district": true,
                    "latitude": "25.37000000",
                    "longitude": "69.73000000"
                },
                {
                    "id": 85765,
                    "name": "Warah",
                    "latitude": "27.44805000",
                    "longitude": "67.79654000"
                },
                {
                    "id": 6789123,
                    "name": "Badin",
                    "district": true,
                },
                {
                    "id": 6789122,
                    "name": "Dadu",
                    "district": true,
                },
                {
                    "id": 6789121,
                    "name": "Ghotki",
                    "district": true,
                },
                {
                    "id": 6789120,
                    "name": "Hyderabad",
                    "district": true,
                },
                {
                    "id": 6789124,
                    "name": "Jacobabad",
                    "district": true,
                },
                {
                    "id": 6789125,
                    "name": "Jamshoro",
                    "district": true,
                },
                {
                    "id": 6789126,
                    "name": "Karachi Central",
                    "district": true,
                },
                {
                    "id": 6789127,
                    "name": "Kashmore",
                    "district": true,
                },
                {
                    "id": 6789128,
                    "name": "Khairpur",
                    "district": true,
                },
                {
                    "id": 6789129,
                    "name": "Larkana",
                    "district": true,
                },
                {
                    "id": 6789130,
                    "name": "Matiari",
                    "district": true,
                },
                {
                    "id": 6789131,
                    "name": "Mirpur Khas",
                    "district": true,
                },
                {
                    "id": 6789132,
                    "name": "Naushahro Feroze",
                    "district": true,
                },
                {
                    "id": 6789133,
                    "name": "Shikarpur",
                    "district": true,
                },
                {
                    "id": 6789134,
                    "name": "Tharparkar",
                    "district": true,
                },
                {
                    "id": 6789135,
                    "name": "Karachi",
                    "district": true,
                },
                {
                    "id": 6789136,
                    "name": "Thatta",
                    "district": true,
                },
                {
                    "id": 6789137,
                    "name": "Korangi",
                    "district": true,
                },
                {
                    "id": 6789138,
                    "name": "Malir",
                    "district": true,
                },
            ]
        }
    ]
}
    