import React, { useEffect, useState } from 'react';
import { Modal, Container } from 'reactstrap';
import { useAppDispatch } from '~/Store/hooks';
import { useNavigate } from 'react-router';
import '../meetings.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SCHEDULE_MEETING } from '~/Store/graphQL/MeetingsManagement';
import { useMutation } from '@apollo/client';
import { setToastSuccess } from '~/Store/reducers/layout';
import { MeetingStatus } from '~/commons/enums';
import moment from 'moment';

export const ScheduleMeetingModal: any = ({
  visible,
  modalSelectedMeetingId,
  modalInvitees,
  onHide,
}: {
  visible: boolean;
  modalSelectedMeetingId: string;
  modalInvitees: string[];
  onHide: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [inviteesArray, setInviteesArray] = useState<string[]>([]);
  const [tempInviteesArray, setTempInviteesArray] = useState<string[]>([]);
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [inviteeInput, setInviteeInput] = useState<string>();
  const [inviteeInputError, setInviteeInputError] = useState<string>();
  const [modalError, setModalError] = useState<string>();

  useEffect(() => {
    if (modalInvitees) {
      setInviteesArray(modalInvitees);
    }
  }, [modalInvitees]);
  useEffect(() => {
    setTimeout(() => {
      setModalError('');
    }, 8000);
  }, [modalError]);
  //   schedule meeting
  const [
    scheduleMeeting,
    {
      loading: loadingScheduleMeeting,
      error: errorScheduleMeeting,
      data: dataScheduleMeeting,
    },
  ] = useMutation<any>(SCHEDULE_MEETING);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      startDate: new Date().toISOString().slice(0, 10),
      startTime: moment(new Date())
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
        .slice(11, 16),
      endDate: new Date().toISOString().slice(0, 10),
      endTime: moment(new Date())
        .local()
        .format('YYYY-MM-DD HH:mm:ss')
        .slice(11, 16),
    },
    validationSchema: Yup.object({
      startDate: Yup.string().required('Please select start date').nullable(),
      startTime: Yup.string().required('Please select start time').nullable(),
      endDate: Yup.string().required('Please select end date').nullable(),
      endTime: Yup.string().required('Please select end time').nullable(),
    }),
    onSubmit: (values: any) => {
      scheduleMeeting({
        variables: {
          id: modalSelectedMeetingId,
          invitees: inviteesArray.join(';'),
          startTime: new Date(values.startDate + ' ' + values.startTime),
          endTime: new Date(values.endDate + ' ' + values.endTime),
          status: MeetingStatus.SCHEDULED,
        },
      })
        .then((res: any) => {
          if (res?.data?.scheduleMeeting?.meeting) {
            dispatch(setToastSuccess('Meeting scheduled!'));
            onHide();
            navigate('/meetings');
          }
        })
        .catch(err => {
          setModalError(err);
        });
    },
  });
  const validateEmail = (email: string) => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    }
    return false;
  };
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={visible}
        toggle={() => {
          onHide?.();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-calendar bx-sm me-2 text-primary"></i>
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Schedule
              </h5>
            </div>
            <button
              onClick={() => {
                onHide?.();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {modalError ? (
              <div className="text-danger fw-bold ms-auto text-center mb-3">
                {modalError}
              </div>
            ) : null}
            <div className="row mb-4">
              <div className="col-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="startDate"
                      className="model-input-heading mb-3"
                    >
                      Start Date
                    </label>
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.startDate}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="date"
                    className={`form-control ${
                      validation.touched.startDate &&
                      validation.errors.startDate &&
                      'is-invalid'
                    }`}
                    id="startDate"
                    name="startDate"
                    placeholder="Start Date"
                    min={new Date().toISOString().split('T')[0]}
                    value={validation.values.startDate}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="startTime"
                      className="model-input-heading mb-3"
                    >
                      Start Time
                    </label>
                    {validation.touched.startTime &&
                    validation.errors.startTime ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.startTime}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="time"
                    className={`form-control ${
                      validation.touched.startTime &&
                      validation.errors.startTime &&
                      'is-invalid'
                    }`}
                    id="startTime"
                    name="startTime"
                    placeholder="Start Time"
                    value={validation.values.startTime}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row mb-4">
              <div className="col-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="endDate"
                      className="model-input-heading mb-3"
                    >
                      End Date
                    </label>
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.endDate}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="date"
                    className={`form-control ${
                      validation.touched.endDate &&
                      validation.errors.endDate &&
                      'is-invalid'
                    }`}
                    id="endDate"
                    name="endDate"
                    placeholder="End Date"
                    value={validation.values.endDate}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="endTime"
                      className="model-input-heading mb-3"
                    >
                      End Time
                    </label>
                    {validation.touched.endTime && validation.errors.endTime ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.endTime}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="time"
                    className={`form-control ${
                      validation.touched.endTime &&
                      validation.errors.endTime &&
                      'is-invalid'
                    }`}
                    id="endTime"
                    name="endTime"
                    placeholder="End Time"
                    value={validation.values.endTime}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div> */}
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div>
                <label className="model-input-heading">Invitees</label>
              </div>
              <div className="d-flex justify-content-center align-items-center cursor-pointer">
                <i className="bx bx-plus color-disabled"></i>
                <div
                  className="ms-1 color-disabled font-weight-600"
                  onClick={() => {
                    setInviteeInputError('');
                    if (editableRow === -1) {
                      if (inviteesArray) {
                        setTempInviteesArray(inviteesArray);
                        let newInvitee = [...inviteesArray];
                        newInvitee.push('');
                        setInviteesArray(newInvitee);
                        setEditableRow(inviteesArray.length);
                      } else {
                        let newInvitee = [''];
                        setInviteesArray(newInvitee);
                        setEditableRow(0);
                      }
                    }
                  }}
                >
                  Add More
                </div>
              </div>
            </div>
            {inviteesArray?.map(
              (invitee: any, index: number) => (
                // invitee ? (
                <div key={index}>
                  {editableRow !== index ? (
                    <div className="row backgroung-light-grey">
                      <div className="col-6">
                        <ul className="ps-3 my-2">
                          <li>{invitee}</li>
                        </ul>
                      </div>
                      <div className="col-6 d-flex justify-content-end align-items-center">
                        <div>
                          <i
                            className={
                              'bx bxs-pencil color-sub-primary bx-sm mx-2 cursor-pointer'
                            }
                            onClick={() => {
                              setInviteeInputError('');
                              if (editableRow === -1) {
                                setEditableRow(index);
                                setTempInviteesArray(inviteesArray);
                                setInviteeInput(inviteesArray[index]);
                              }
                            }}
                          />
                          <i
                            className={
                              'bx bxs-trash-alt text-danger bx-sm cursor-pointer'
                            }
                            onClick={() => {
                              let temp = [...inviteesArray];
                              temp.splice(index, 1);
                              // let temp2 = JSON.parse(JSON.stringify(inviteesArray));
                              // temp2.forEach((element: any) => {
                              //   delete element['__typename'];
                              // });
                              // temp2[index].toBeDeleted = true;
                              setInviteesArray(temp);
                            }}
                          />
                        </div>
                      </div>
                      <hr className="m-0"></hr>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-8">
                        <div>
                          {inviteeInputError ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {inviteeInputError}
                            </p>
                          ) : null}
                          <input
                            type="text"
                            id="invitee"
                            name="invitee"
                            placeholder="Invitee"
                            className={`form-control form-medium my-2`}
                            value={inviteeInput ? inviteeInput : invitee}
                            onChange={e => {
                              setInviteeInputError(undefined);
                              const { value } = e.target;
                              setInviteeInput(value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-4 d-flex justify-content-end align-items-center">
                        <i
                          className="bx bx-check-circle bx-md ms-4 me-2 text-success cursor-pointer"
                          onClick={() => {
                            if (inviteeInput) {
                              var checkEmail = inviteesArray.findIndex(
                                email => email === inviteeInput
                              );
                              if (
                                checkEmail === -1 ||
                                checkEmail === editableRow
                              ) {
                                if (validateEmail(inviteeInput)) {
                                  const temp = [...inviteesArray];
                                  temp[index] = inviteeInput;
                                  setInviteesArray(temp);
                                  setInviteeInput(undefined);
                                  setInviteeInputError(undefined);
                                  setEditableRow(-1);
                                } else {
                                  setInviteeInputError(
                                    'Please enter a valid email!'
                                  );
                                }
                              } else {
                                setInviteeInputError('Invitee already added!');
                              }
                            } else {
                              setInviteeInputError(
                                'Please enter invitee email!'
                              );
                            }
                          }}
                        ></i>
                        <i
                          className="bx bx-x-circle bx-md text-danger cursor-pointer"
                          onClick={() => {
                            setInviteeInput(undefined);
                            setEditableRow(-1);
                            setInviteesArray(tempInviteesArray);
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              )
              // ) : null
            )}
            {(inviteesArray?.length === 0 || inviteesArray === undefined) && (
              <div className="addDiv">
                <h6 className="m-3">No records Found</h6>
              </div>
            )}
            <div className="mt-4">
              <div className="d-flex justify-content-end align-items-center">
                {/* <button
                  type="button"
                  className="btn btn-primary primary-btn-new-modal d-flex align-items-center my-auto mx-4"
                  onClick={() => {
                    navigate('/meetings/info');
                  }}
                >
                  <div className="ms-1">View Details</div>
                </button> */}
                <button
                  type="button"
                  className="btn successButtonModal d-flex align-items-center my-auto"
                  disabled={loadingScheduleMeeting}
                  onClick={() => {
                    if (inviteesArray.length === 0) {
                      setModalError('Please add alteast 1 invitee!');
                    } else if (editableRow !== -1) {
                      setModalError(
                        'You have some unsaved changes, please save or discard them to continue!'
                      );
                    } else {
                      validation.handleSubmit();
                    }
                  }}
                >
                  {loadingScheduleMeeting && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}{' '}
                  <div className="ms-1">Schedule</div>
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
