import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import 'react-multi-email/style.css';
import {
  DUPLICATE_SHARE,
  FIND_DUPLICATE_SHARE_BY_ID,
  FIND_SHARE_BY_ID,
  GET_NEXT_SHARE_CERTIFICATE_NO,
  UPDATE_DUPLICATE_SHARE,
} from '~/Store/graphQL/Shares';
import NumberBlockImg from '/images/number-blocks.png';
import CalendarImg from '/images/calendar.png';
import {
  setSelectedShareId,
  setSelectedShareIdForTransaction,
} from '~/Store/reducers/share';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { ModuleEnum } from '~/commons/enums';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
export const DuplicateShare: FC = () => {
  const { selectedShareId, shareView, selectedShareIdForTransaction } =
    useAppSelector(state => state.shareReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [shareData, setShareData] = useState<any>();
  const [duplicateShareData, setDuplicateShareData] = useState<any>();
  const [attachmentFiles, setAttachmentFiles] = useState<any>([]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/shares',
          title: 'Share Management',
        },
        {
          url: location.pathname,
          title: 'Duplicate Share',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting share information
  const [
    findShareById,
    {
      loading: loadingFindShareById,
      error: errorFindShareById,
      data: dataFindShareById,
    },
  ] = useLazyQuery<any>(FIND_SHARE_BY_ID);
  // Getting duplicate share information
  const [
    findDuplicateShareById,
    {
      loading: loadingFindDuplicateShareById,
      error: errorFindDuplicateShareById,
      data: dataFindDuplicateShareById,
    },
  ] = useLazyQuery<any>(FIND_DUPLICATE_SHARE_BY_ID);
  //   update duplicate share
  const [
    updateDuplicateShare,
    {
      loading: loadingUpdateDuplicateShare,
      error: errorUpdateDuplicateShare,
      data: dataUpdateDuplicateShare,
    },
  ] = useMutation<any>(UPDATE_DUPLICATE_SHARE);
  // duplicate share
  const [
    duplicateShare,
    {
      loading: loadingDuplicateShare,
      error: errorDuplicateShare,
      data: dataDuplicateShare,
    },
  ] = useMutation<any>(DUPLICATE_SHARE);
  // Get next share certificate number query
  const [
    getNextShareCertificateNo,
    {
      loading: loadingGetNextShareCertificateNo,
      error: errorGetNextShareCertificateNo,
      data: dataGetNextShareCertificateNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_SHARE_CERTIFICATE_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (selectedShareIdForTransaction) {
      findShareById({
        variables: {
          id: selectedShareIdForTransaction,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareIdForTransaction]);
  useEffect(() => {
    if (dataFindShareById) {
      setShareData(dataFindShareById?.findOneShare?.share);
    } else if (errorFindShareById) {
      console.log('errorFindShareById:', errorFindShareById);
    }
  }, [dataFindShareById, errorFindShareById]);
  useEffect(() => {
    if (selectedShareId) {
      findDuplicateShareById({
        variables: {
          id: selectedShareId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareId]);
  useEffect(() => {
    if (dataFindDuplicateShareById) {
      let tempArray: any = [];
      setDuplicateShareData(dataFindDuplicateShareById?.findOneDuplicateShare);
      dataFindDuplicateShareById?.findOneDuplicateShare?.url.forEach(
        (element: any) => {
          tempArray.push(JSON.parse(element));
        }
      );
      setAttachmentFiles(tempArray);
    } else if (errorFindDuplicateShareById) {
      console.log('errorFindDuplicateShareById:', errorFindDuplicateShareById);
    }
  }, [dataFindDuplicateShareById, errorFindDuplicateShareById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      newCertificateNo: duplicateShareData?.shareCertificateNo
        ? duplicateShareData?.shareCertificateNo
        : '',
      receiptDateOfDuplicateShare: duplicateShareData?.receiptDate
        ? moment(duplicateShareData?.receiptDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      dateOfIssue: duplicateShareData?.issueDate
        ? moment(duplicateShareData?.issueDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      approvalDate: duplicateShareData?.approveDate
        ? moment(duplicateShareData?.approveDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      duplicateReason: duplicateShareData?.duplicateReason
        ? duplicateShareData?.duplicateReason
        : '',
      numOfShares: shareData?.noOfShares ? shareData?.noOfShares : '',

      distinctiveNumFrom: shareData?.DistinctiveNoFrom
        ? shareData?.DistinctiveNoFrom
        : '',
      distinctiveNumTo: shareData?.DistinctiveNoTo
        ? shareData?.DistinctiveNoTo
        : '',
      certificateNo: shareData?.shareCertificateNo
        ? shareData?.shareCertificateNo
        : '',
    },
    validationSchema: Yup.object({
      newCertificateNo: Yup.number()
        .required('Please enter new certificate no')
        .nullable(),
      receiptDateOfDuplicateShare: Yup.string()
        .required('Please select receipt date of duplicate share')
        .nullable(),
      dateOfIssue: Yup.string()
        .required('Please select date of issue')
        .nullable(),
      approvalDate: Yup.string()
        .required('Please select approval date')
        .nullable(),
      duplicateReason: Yup.string()
        .required('Please enter duplicate reason')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (shareData && duplicateShareData && selectedShareId && !shareView) {
        updateDuplicateShare({
          variables: {
            shareId: selectedShareId,
            companyId: companyID,
            duplicateReason: values.duplicateReason,
            issueDate: values.dateOfIssue,
            approveDate: values.approvalDate,
            receiptDate: values.receiptDateOfDuplicateShare,
            shareCertificateNo: duplicateShareData?.shareCertificateNo,
            files: attachmentFiles,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateDuplicateShare?.shareId) {
              dispatch(setToastSuccess('Duplicate Share updated!'));
              dispatch(setSelectedShareId(null));
              dispatch(setSelectedShareIdForTransaction(null));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        duplicateShare({
          variables: {
            shareId: selectedShareIdForTransaction,
            companyId: companyID,
            duplicateReason: values.duplicateReason,
            issueDate: values.dateOfIssue,
            approveDate: values.approvalDate,
            receiptDate: values.receiptDateOfDuplicateShare,
            files: attachmentFiles,
          },
        })
          .then((res: any) => {
            if (res?.data?.duplicateShare?.shareId) {
              dispatch(setToastSuccess('Share Duplicated!'));
              dispatch(setSelectedShareId(null));
              dispatch(setSelectedShareIdForTransaction(null));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindShareById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 background-white">
          <div className="d-flex justify-content-end background-primary-medium">
            <button
              type="button"
              className="btn dangerButton d-flex align-items-center m-3 fw-bold"
              disabled={loadingDuplicateShare || loadingUpdateDuplicateShare}
              onMouseDown={() => {
                navigate('/shares');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-bold primaryDismissButton d-flex align-items-center m-3 mx-0 me-3"
              disabled={loadingDuplicateShare || loadingUpdateDuplicateShare}
              onMouseDown={() => {
                navigate('/shares/transactions');
              }}
            >
              Back
            </button>
            {!shareView && (
              <button
                type="button"
                className="btn successButton d-flex align-items-center m-3 fw-bold ms-0"
                disabled={loadingDuplicateShare || loadingUpdateDuplicateShare}
                onMouseDown={() => {
                  validation.handleSubmit();
                }}
              >
                {(loadingDuplicateShare || loadingUpdateDuplicateShare) && (
                  <i className="bx bx-loader-circle bx-spin me-1" />
                )}
                Save
                <i className="bx bx-upload mx-1" />
              </button>
            )}
          </div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div className="row mt-4">
                <div className="col-4">
                  <div className="d-flex justify-content-start align-items-center primary-medium-border-div">
                    <img className="mx-4" src={NumberBlockImg}></img>
                    <div className="form-group pt-3 web-auto">
                      <div className="d-flex">
                        <label htmlFor="newCertificateNo">
                          New Certificate No*
                        </label>
                        {validation.touched.newCertificateNo &&
                        validation.errors.newCertificateNo ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.newCertificateNo}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          type="text"
                          className={`form-control w-autox ${
                            validation.touched.newCertificateNo &&
                            validation.errors.newCertificateNo &&
                            'is-invalid'
                          }`}
                          id="newCertificateNo"
                          name="newCertificateNo"
                          placeholder="New Certificate No"
                          value={
                            validation.values.newCertificateNo
                              ? validation.values.newCertificateNo
                              : dataGetNextShareCertificateNo
                                  ?.getShareCertificateNumber
                                  ?.shareCertificateNo
                          }
                          disabled={true}
                          onChange={validation.handleChange}
                        />
                        {!shareView ? (
                          <button
                            type="button"
                            className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold oneline"
                            disabled={loadingGetNextShareCertificateNo}
                            onMouseDown={() => {
                              getNextShareCertificateNo()
                                .then((res: any) => {
                                  if (
                                    res?.data?.getShareCertificateNumber
                                      ?.shareCertificateNo
                                  ) {
                                    validation.setFieldValue(
                                      'newCertificateNo',
                                      res?.data?.getShareCertificateNumber
                                        ?.shareCertificateNo
                                    );
                                  }
                                })
                                .catch(err => {
                                  dispatch(setToastError(err));
                                });
                            }}
                          >
                            Next No
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="d-flex justify-content-start align-items-center success-border-div gap-3 px-2">
                    <img
                      className="d-flex justify-content-start ms-4 "
                      src={CalendarImg}
                    ></img>
                    <div className="form-group pt-3 web-auto">
                      <div className="d-flex">
                        <label
                          htmlFor="receiptDateOfDuplicateShare"
                          className="w-autox"
                        >
                          Receipt Date of Duplicate Share*
                        </label>
                        {validation.touched.receiptDateOfDuplicateShare &&
                        validation.errors.receiptDateOfDuplicateShare ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.receiptDateOfDuplicateShare}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control web-auto ${
                          validation.touched.receiptDateOfDuplicateShare &&
                          validation.errors.receiptDateOfDuplicateShare &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="receiptDateOfDuplicateShare"
                        name="receiptDateOfDuplicateShare"
                        placeholder="Receipt Date of Transmission Deed"
                        value={validation.values.receiptDateOfDuplicateShare}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group pt-3 web-auto">
                      <div className="d-flex">
                        <label
                          htmlFor="dateOfIssue"
                          className="model-input-heading"
                        >
                          Date of Issue*
                        </label>
                        {validation.touched.dateOfIssue &&
                        validation.errors.dateOfIssue ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfIssue}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.dateOfIssue &&
                          validation.errors.dateOfIssue &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="dateOfIssue"
                        name="dateOfIssue"
                        placeholder="Date of Issue"
                        value={validation.values.dateOfIssue}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group pt-3 web-auto">
                      <div className="d-flex">
                        <label
                          htmlFor="approvalDate"
                          className="model-input-heading"
                        >
                          Approval Date*
                        </label>
                        {validation.touched.approvalDate &&
                        validation.errors.approvalDate ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.approvalDate}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.approvalDate &&
                          validation.errors.approvalDate &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="approvalDate"
                        name="approvalDate"
                        placeholder="Approval Date"
                        value={validation.values.approvalDate}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 mt-4 pt-4 w-50 ">
                <div className="col-6 d-flex justify-content-start align-items-center">
                  <div className="form-group w-100">
                    <div className="d-flex ">
                      <label
                        htmlFor="duplicateReason"
                        className="model-input-heading"
                      >
                        Duplicate Reason*
                      </label>
                      {validation.touched.duplicateReason &&
                      validation.errors.duplicateReason ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.duplicateReason}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.duplicateReason &&
                        validation.errors.duplicateReason &&
                        'is-invalid'
                      }`}
                      disabled={shareView}
                      id="duplicateReason"
                      name="duplicateReason"
                      placeholder="Duplicate Reason"
                      value={validation.values.duplicateReason}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-xxl-2 col-xl-6 col-lg-6 col-md-6 col-6 d-flex justify-content-start align-items-center">
                  {!shareView ? (
                    <label className="btn primaryDismissButton d-flex align-items-center">
                      <input
                        type="file"
                        multiple
                        accept="image/*,.pdf"
                        onChangeCapture={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                        }}
                      />
                      <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                    </label>
                  ) : null}
                </div>

                <div className="col-xxl-7 col-xl-12 col-lg-12 col-md-12 col-12">
                  {[...attachmentFiles].map((val: any, index: number) =>
                    val ? (
                      <ul className="d-flex align-items-center" key={index}>
                        <li>
                          {val?.name ? val?.name : val?.url.split('/')[3]}
                        </li>
                        {!shareView ? (
                          <i
                            className="bx bx-x bx-sm mx-3 cursor-pointer"
                            onClick={e => {
                              let tempAttachmentFiles = [...attachmentFiles];
                              tempAttachmentFiles.splice(index, 1);
                              setAttachmentFiles(tempAttachmentFiles);
                            }}
                          ></i>
                        ) : null}
                      </ul>
                    ) : null
                  )}
                </div> */}
              </div>
              <div className="row mb-4">
                <div className="col-1">
                  <div className="font-weight-600">Attachment</div>
                  {/* <div className="font-11">Documents</div> */}
                  <div
                    className={`attachedDocumentDiv cursor-pointer ${
                      shareView && 'icon-disabled'
                    }`}
                  >
                    <label htmlFor={'file-input'} className="cursor-pointer">
                      <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.docx"
                      multiple={true}
                      onChangeCapture={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (e?.target?.files) {
                          const selectedFile = e?.target?.files[0];

                          // Perform file type validation
                          if (selectedFile) {
                            const allowedFileTypes = [
                              '.jpg',
                              '.jpeg',
                              '.png',
                              '.pdf',
                              '.docx',
                            ];
                            const fileExtension = selectedFile.name.substring(
                              selectedFile.name.lastIndexOf('.')
                            );
                            if (
                              !allowedFileTypes.includes(
                                fileExtension.toLowerCase()
                              )
                            ) {
                              // File type not allowed
                              dispatch(
                                setToastError(
                                  'Only JPG, PNG, PDF and DOCX files are allowed.'
                                )
                              );
                              e.target.value = ''; // Clear the selected file
                              return;
                            }

                            // Perform further processing with the selected file
                            setAttachmentFiles(e?.target?.files);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-start align-items-center flex-column">
                  {[...attachmentFiles].map((val: any, index: number) =>
                    val ? (
                      <div className="row d-flex justify-content-start align-items-center">
                        <div className="col-12 d-flex justify-content-start align-items-center">
                          <ul className="d-flex justify-content-start align-items-center">
                            <li>
                              {val?.name ? val?.name : val?.url.split('/')[3]}
                            </li>
                            {!shareView ? (
                              <i
                                className="bx bx-x bx-sm mx-3 cursor-pointer"
                                onClick={e => {
                                  let tempAttachmentFiles = [
                                    ...attachmentFiles,
                                  ];
                                  tempAttachmentFiles.splice(index, 1);
                                  setAttachmentFiles(tempAttachmentFiles);
                                }}
                              ></i>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
                {/* <div className="col-4"></div>
                <div className="col-3"></div> */}
              </div>
              <div className="row mx-0 mt-4 pt-4 backgroung-light-grey">
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="certificateNo"
                        className="model-input-heading"
                      >
                        Certificate Number*
                      </label>
                      {validation.touched.certificateNo &&
                      validation.errors.certificateNo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.certificateNo}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control W-100 ${
                          validation.touched.certificateNo &&
                          validation.errors.certificateNo &&
                          'is-invalid'
                        }`}
                        id="certificateNo"
                        name="certificateNo"
                        placeholder="Certificate No"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={validation.values.certificateNo}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="numOfShares">No Of Shares*</label>
                      {validation.touched.numOfShares &&
                      validation.errors.numOfShares ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.numOfShares}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control W-100 ${
                        validation.touched.numOfShares &&
                        validation.errors.numOfShares &&
                        'is-invalid'
                      }`}
                      id="numOfShares"
                      name="numOfShares"
                      placeholder="No Of Shares"
                      value={validation.values.numOfShares}
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      disabled={true}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="distinctiveNumFrom"
                        className="model-input-heading"
                      >
                        Distinctive No: From*
                      </label>
                      {validation.touched.distinctiveNumFrom &&
                      validation.errors.distinctiveNumFrom ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNumFrom}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control w-100 ${
                          validation.touched.distinctiveNumFrom &&
                          validation.errors.distinctiveNumFrom &&
                          'is-invalid'
                        }`}
                        id="distinctiveNumFrom"
                        name="distinctiveNumFrom"
                        placeholder="Distinctive No: From"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={validation.values.distinctiveNumFrom}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="distinctiveNumTo"
                        className="model-input-heading"
                      >
                        Distinctive No: To*
                      </label>
                      {validation.touched.distinctiveNumTo &&
                      validation.errors.distinctiveNumTo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNumTo}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control w-100 ${
                          validation.touched.distinctiveNumTo &&
                          validation.errors.distinctiveNumTo &&
                          'is-invalid'
                        }`}
                        id="distinctiveNumTo"
                        name="distinctiveNumTo"
                        placeholder="Distinctive No: To"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={validation.values.distinctiveNumTo}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
