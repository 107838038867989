import { ModuleEnum } from '..';

export const ACCESS_PRIVILAGES = [
  {
    moduleName: ModuleEnum.COMPANY_PROFILE,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.COMPANY_OFFICERS,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.COMPANY_MEMBERS,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.SHARE_MANAGEMENT,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.REGISTER,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.MEETING,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.NOTIFICATION,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.COMMITTEE,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.LEGAL_DOCUMENTS,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.FINANCIAL_YEAR,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.BANK_ACCOUNTS,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.SYSTEM_USER,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
  {
    moduleName: ModuleEnum.SYSTEM_USER_GROUPS,
    view: false,
    edit: false,
    delete: false,
    email: false,
    generateDocument: false,
  },
];
