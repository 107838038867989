import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import {
  companyProfileReducer,
  layoutReducer,
  notificationReducer,
  companyOfficerReducer,
  companyMemberReducer,
  agendaReducer,
  ordinanceReducer,
  committeeReducer,
  meetingReducer,
  shareReducer,
  userGroupReducer,
  userReducer,
  financialYearReducer,
  bankAccountReducer,
  subscriptionReducer,
  helpSectionReducer,
  businessRuleReducer,
  helpReducer,
} from './reducers';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';
const combinedReducer = combineReducers({
  layoutReducer,
  companyProfileReducer,
  notificationReducer,
  companyOfficerReducer,
  companyMemberReducer,
  agendaReducer,
  ordinanceReducer,
  committeeReducer,
  meetingReducer,
  shareReducer,
  userGroupReducer,
  userReducer,
  financialYearReducer,
  bankAccountReducer,
  subscriptionReducer,
  helpSectionReducer,
  businessRuleReducer,
  helpReducer,
  //^^new reduceers here
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'root/clearStore') {
    state = undefined;
    window.open(`https://compliancedigital.com.pk`, '_self');
  }
  return combinedReducer(state, action);
};
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'layoutReducer',
    'companyProfileReducer',
    'notificationReducer',
    'companyOfficerReducer',
    'companyMemberReducer',
    'agendaReducer',
    'ordinanceReducer',
    'committeeReducer',
    'meetingReducer',
    'shareReducer',
    'userGroupReducer',
    'userReducer',
    'financialYearReducer',
    'bankAccountReducer',
    'subscriptionReducer',
    'helpSectionReducer',
    'businessRuleReducer',
    'helpReducer',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: any = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk]
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
