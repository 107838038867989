import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import moment from 'moment';
import {
  setSelectedShareId,
  setShareViewScreen,
  setSelectedFolioNumber,
} from '~/Store/reducers/share';
import { GET_ALL_COMPANY_MEMBERS_FOR_SHARES } from '~/Store/graphQL/Shares';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { Tooltip } from 'reactstrap';

export const Share: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Shares',
        },
      ])
    );
  }, []);
  // Get all shares query
  const [
    getAllCompanyMembersForShare,
    {
      loading: loadingGetAllCompanyMembersForShare,
      error: errorGetAllCompanyMembersForShare,
      data: dataGetAllCompanyMembersForShare,
    },
  ] = useLazyQuery<any>(GET_ALL_COMPANY_MEMBERS_FOR_SHARES, {
    variables: {
      companyId: companyID,
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (companyID) {
      getAllCompanyMembersForShare();
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllCompanyMembersForShare) {
      dispatch(setToastError(errorGetAllCompanyMembersForShare));
    }
  }, [errorGetAllCompanyMembersForShare]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Shares</b>
              </h5>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.SHARE_MANAGEMENT,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.SHARE_MANAGEMENT,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setShareViewScreen(false));
                dispatch(setSelectedShareId(null));
                dispatch(setSelectedFolioNumber(-1));
                navigate('/shares/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Allotment of share
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.SHARE_MANAGEMENT,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllCompanyMembersForShare ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Folio No</th>
                            <th>Name</th>
                            <th>Total Shares</th>
                            <th>Total Amount</th>
                            <th>Percentage Shares</th>
                            <th>Membership Date</th>
                            <th>Cease Date</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllCompanyMembersForShare?.findAllCompanyMembersInShare?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val?.node?.folioNo
                                      ? String(val?.node?.folioNo).padStart(
                                          3,
                                          '00'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.firstName + ' ' + val.node.lastName}
                                </td>
                                <td>
                                  {val.node.totalShares
                                    ? val.node.totalShares
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.totalAmount
                                    ? val.node.totalAmount
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div>
                                    {val.node.sharePercentage
                                      ? val.node.sharePercentage
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.dateOfMembership
                                    ? moment(val.node.dateOfMembership).format(
                                        'DD-MM-YYYY'
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.ceaseDate
                                    ? moment(val.node.ceaseDate).format(
                                        'DD-MM-YYYY'
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SHARE_MANAGEMENT,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt bx-sm text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedShareId(val.node.shareId)
                                          );
                                          dispatch(setShareViewScreen(false));
                                          dispatch(
                                            setSelectedFolioNumber(
                                              val.node.folioNo
                                            )
                                          );
                                          navigate('/shares/transactions');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SHARE_MANAGEMENT,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllCompanyMembersForShare
                        ?.findAllCompanyMembersInShare?.edges?.length === 0 ||
                        dataGetAllCompanyMembersForShare
                          ?.findAllCompanyMembersInShare?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllCompanyMembersForShare
                            ?.findAllCompanyMembersInShare?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllCompanyMembersForShare
                              ?.findAllCompanyMembersInShare?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllCompanyMembersForShare({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllCompanyMembersForShare
                                    ?.findAllCompanyMembersInShare?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllCompanyMembersForShare
                            ?.findAllCompanyMembersInShare?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllCompanyMembersForShare
                              ?.findAllCompanyMembersInShare?.pageInfo
                              ?.hasNextPage
                          ) {
                            getAllCompanyMembersForShare({
                              variables: {
                                after:
                                  dataGetAllCompanyMembersForShare
                                    ?.findAllCompanyMembersInShare?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
