import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './ordinance.scss';
import { DELETE_ORDINANCE, GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';

import {
  setOrdinanceViewScreen,
  setSelectedOrdinanceId,
} from '~/Store/reducers/ordinance';

export const AdminOrdinance: FC = () => {
  let pageNo = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    ordinance: '',
  });
  useEffect(() => {
    getAllOrdinance();
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Ordinance',
        },
      ])
    );
  }, []);

  const [
    deleteOrdinance,
    {
      loading: loadingDeleteOrdinance,
      error: errorDeleteOrdinance,
      data: dataDeleteOrdinance,
    },
  ] = useMutation<any>(DELETE_ORDINANCE);

  useEffect(() => {
    getAllOrdinance();
  }, [itemsPerPage]);

  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    variables: {
      first: itemsPerPage,
      // ordinance: undefined,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            {/* Second Row */}
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="ordinance">Ordinance</label>
                </div>
                <div className="field">
                  <input
                    id="ordinance"
                    name="ordinance"
                    placeholder="Ordinance"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.ordinance}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.ordinance = value;
                      setFilters(temp);
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.ordinance) {
                      getAllOrdinance({
                        variables: {
                          first: itemsPerPage,
                          ordinance: undefined,
                        },
                      });
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.ordinance = '';
                      setFilters(temp);
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  // disabled={loadingGetAllAgendaForCompany}
                  onClick={() => {
                    getAllOrdinance({
                      variables: {
                        ordinance: filters?.ordinance
                          ? filters?.ordinance
                          : undefined,
                      },
                    });
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setOrdinanceViewScreen(false));
                dispatch(setSelectedOrdinanceId(null));
                navigate('/admin/ordinance/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Ordinance
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllOrdinance ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th className="col-1">S.No</th>
                            <th className="col-1">Ordinance</th>
                            {/* <th className="col-1">Section</th> */}
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                            // const n;
                            (ordinance: any, ordinanceIndex: number) => (
                              // ordinance?.node?.section.map(
                              //   (section: any, sectionIndex: number) => (

                              <tr key={`${ordinanceIndex}`}>
                                <td>
                                  {pageNo.current * itemsPerPage +
                                    (ordinanceIndex + 1)}
                                </td>
                                <td>{ordinance.node.ordinanceName}</td>
                                {/* <td>{section.sectionName}</td> */}

                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className="bx bx-show-alt text-primary"
                                      onClick={() => {
                                        dispatch(
                                          setSelectedOrdinanceId(
                                            ordinance?.node?.ordinanceId
                                          )
                                        );
                                        dispatch(setOrdinanceViewScreen(true));
                                        navigate('/admin/ordinance/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(setOrdinanceViewScreen(false));
                                        dispatch(
                                          setSelectedOrdinanceId(
                                            ordinance?.node?.ordinanceId
                                          )
                                        );
                                        navigate('/admin/ordinance/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(setOrdinanceViewScreen(false));
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteOrdinance({
                                              variables: {
                                                id: ordinance.node.ordinanceId,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data.deleteOrdinance.count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Ordinance Deleted!'
                                                    )
                                                  );
                                                  getAllOrdinance();
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                            //   )
                            // )
                            ///
                          )}
                        </tbody>
                      </table>
                      {/* {(dataGetAllAgendaForCompany?.findAllAgendas?.edges
                        ?.length === 0 ||
                        dataGetAllAgendaForCompany?.findAllAgendas?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )} */}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllOrdinance?.findAllOrdinance?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllOrdinance?.findAllOrdinance?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllOrdinance({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllOrdinance?.findAllOrdinance
                                    ?.pageInfo?.startCursor,
                              },
                            });
                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />

                      <i
                        className={
                          dataGetAllOrdinance?.findAllOrdinance?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllOrdinance?.findAllOrdinance?.pageInfo
                              ?.hasNextPage
                          ) {
                            getAllOrdinance({
                              variables: {
                                after:
                                  dataGetAllOrdinance?.findAllOrdinance
                                    ?.pageInfo?.endCursor,
                              },
                            });
                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
