import { useFormik } from 'formik';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Container, Modal, Tooltip } from 'reactstrap';
import { format } from 'timeago.js';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import {
  ASSIGN_NOTIFICATION_TO_USERS,
  DELETE_TEMP_NOTIFICATION,
  FIND_NOTIFICATIONS,
  UPDATE_NOTIFICATION_STATUS,
  UPDATE_TASK_STATUS,
} from '~/Store/graphQL/Notification';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { setNotificationsInDropdown } from '~/Store/reducers/notification';
import './notification.scss';
import { FIND_ALL_USERS_NOTIFICATIONS } from '~/Store/graphQL/Users';

export const NotificationScreen: FC = () => {
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const [allNotifications, setAllNotifications] = useState<boolean>(true);
  const [expandedRow, setExpandedRow] = useState<number>(-1);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Notifications',
        },
      ])
    );
  }, []);
  useEffect(() => {
    setExpandedRow(-1);
  }, [allNotifications]);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  return (
    <React.Fragment>
      <div className="w-100 d-flex parent">
        <div id="mainComponentNotification">
          <div style={{ width: 'inherit' }}>
            <div className="row d-flex align-items-center mb-5 mx-1">
              <div className="col-1 dot p-0"></div>
              <div className="col-11 d-flex align-items-center">
                <h5 className="m-0">
                  <b>Notifications</b>
                </h5>
              </div>
            </div>
            <div id="mainComponentNotification">
              <div className="w-100">
                <ul className="nav nav-tabs mb-3">
                  <li
                    className={`nav-item ${allNotifications ? 'active' : ''}`}
                    onClick={() => {
                      setAllNotifications(true);
                    }}
                  >
                    <p className="nav-link">Opened</p>
                  </li>
                  <li
                    className={`nav-item ${!allNotifications ? 'active' : ''}`}
                    onClick={() => {
                      setAllNotifications(false);
                    }}
                  >
                    <p className="nav-link">Completed</p>
                  </li>
                </ul>
                {allNotifications ? (
                  <NotificationsList
                    completed={false}
                    expandedRow={expandedRow}
                    setExpandedRow={setExpandedRow}
                  />
                ) : (
                  <NotificationsList
                    completed={true}
                    expandedRow={expandedRow}
                    setExpandedRow={setExpandedRow}
                  />
                )}
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {!loadingReliventDocumnets &&
          !loadingGetHelp &&
          (dataReliventDocumnets || dataGetHelp) && (
            <RightSideBar
              style={{ padding: '20px' }}
              documents={
                dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
              }
              help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
            />
          )}
      </div>
    </React.Fragment>
  );
};

const NotificationsList: FC<{
  completed: boolean;
  expandedRow: number;
  setExpandedRow: any;
}> = memo(({ completed, expandedRow, setExpandedRow }) => {
  const dispatch = useDispatch();
  let pageNo = useRef(0);
  const navigate = useNavigate();

  const { notificationsArray } = useAppSelector(
    state => state.notificationReducer
  );
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [markAsDoneToolTipIsOpen, setMarkAsDoneToolTipIsOpen] = useState(false);
  const [updateNotificationToolTipIsOpen, setUpdateNotificationToolTipIsOpen] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [notifications, setNotifications] = useState<any>();
  const selectedNotificationID = searchParams.get('notificationId');
  const [expandedRow1, setExpandedRow1] = useState<number>(-1);
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const [selectedNotificationObject, setSelectedNotificationObject] =
    useState<any>();
  const [searchName, setSearchName] = useState<any>();

  const [
    getNotifications,
    {
      loading: loadingGetNotifications,
      error: errorGetNotifications,
      data: dataGetNotifications,
    },
  ] = useLazyQuery<any>(FIND_NOTIFICATIONS, {
    variables: {
      companyId: companyID,
      first: itemsPerPage,
      status: completed ? true : false,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteTempNotification,
    {
      loading: loadingDeleteTempNotification,
      error: errorDeleteTempNotification,
      data: dataDeleteTempNotification,
    },
  ] = useMutation<any>(DELETE_TEMP_NOTIFICATION);

  const [
    updateTaskStatus,
    {
      loading: loadingUpdateTaskStatus,
      error: errorUpdateTaskStatus,
      data: dataUpdateTaskStatus,
    },
  ] = useMutation<any>(UPDATE_TASK_STATUS);
  const [
    updateNotificationStatus,
    {
      loading: loadingUpdateNotificationStatus,
      error: errorUpdateNotificationStatus,
      data: dataUpdateNotificationStatus,
    },
  ] = useMutation<any>(UPDATE_NOTIFICATION_STATUS);
  useEffect(() => {
    if (selectedNotificationID) {
      deleteTempNotification({
        variables: {
          id: selectedNotificationID,
        },
      })
        .then((res: any) => {
          if (res.data.deleteTempNotificationById.count) {
            var index = notificationsArray.findIndex(
              (x: any) => x.id === selectedNotificationID
            );
            let temp = [...notificationsArray];
            temp.splice(index, 1);
            dispatch(setNotificationsInDropdown(temp));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [selectedNotificationID]);
  useEffect(() => {
    if (companyID) {
      getNotifications();
    }
  }, [itemsPerPage]);

  useEffect(() => {
    if (dataGetNotifications && !errorGetNotifications) {
      setNotifications(dataGetNotifications?.findNotifications);
    } else if (errorGetNotifications) {
      dispatch(setToastError(errorGetNotifications));
    }
  }, [dataGetNotifications, errorGetNotifications]);

  useEffect(() => {
    if (errorUpdateTaskStatus) {
      dispatch(setToastError(errorUpdateTaskStatus));
    }
  }, [errorUpdateTaskStatus]);

  useEffect(() => {
    if (errorUpdateNotificationStatus) {
      dispatch(setToastError(errorUpdateNotificationStatus));
    }
  }, [errorUpdateNotificationStatus]);
  const [
    getAllUsers,
    {
      loading: loadingGetAllUsers,
      error: errorGetAllGetAllUsers,
      data: dataGetAllGetAllUsers,
    },
  ] = useLazyQuery<any>(FIND_ALL_USERS_NOTIFICATIONS, {
    variables: {
      userType: [
        UserTypeEnum.INTERMEDIAL_USER.toString(),
        // UserTypeEnum.SUPER_ADMIN.toString(),
        // UserTypeEnum.COMPANY_ADMIN.toString(),
        UserTypeEnum.COMPANY_OFFICER.toString(),
        UserTypeEnum.COMPANY_MEMBER.toString(),
      ],
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (selectedNotificationID) {
      getAllUsers();
      getNotifications();
    }
  }, [selectedNotificationID]);

  useEffect(() => {
    if (companyID) {
      getAllUsers();
    }
  }, [momModalVisibility]);

  const [userIds, setUserIds] = useState<any>([]);
  const { userId } = useAppSelector(state => state.layoutReducer);

  function adduser(id: any) {
    let temp: any = userIds;
    let obj;

    obj = id;

    temp.push(obj);
    setUserIds(temp);
  }

  function removeuser(id: any) {
    let temp: any = userIds;
    temp = temp.filter((temp: any) => temp.userId !== id);

    setUserIds(temp);
  }

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      userid: false,
    },
    onSubmit: (values: any) => {},
  });

  const [
    assignNotificationToCompany,
    {
      loading: loadingAssignNotificationToCompany,
      error: errorAssignNotificationToCompany,
      data: dataAssignNotificationToCompany,
    },
  ] = useMutation<any>(ASSIGN_NOTIFICATION_TO_USERS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataAssignNotificationToCompany) {
      getAllUsers();
    }
  }, [dataAssignNotificationToCompany]);

  return loadingGetNotifications ? (
    <div className="d-flex justify-content-center align-items-center w-100 h-75">
      <i className="bx bx-loader-circle bx-spin bx-lg"></i>
    </div>
  ) : dataGetNotifications === undefined ||
    notifications?.edges?.length === 0 ? (
    <div className="text-center my-4">No New Notifications</div>
  ) : (
    <>
      <div className="w-100">
        <div className="w-100 ">
          <div className="col-12 pb-3">
            <div className="row liborder-notify  bg-white m-0 mt-3 w-100 h-100 ">
              <div className="d-flex flex-column m-0 p-0  gap-2">
                {notifications?.edges.map((edge: any, index: any) => (
                  <div
                    className={` RegHeading  overflow-wrap  text-break rounded border  ${
                      edge?.node?.status ? '' : ''
                    }`}
                    key={index}
                  >
                    <div
                      className={` w-100 d-flex justify-content-between
                    ${index == expandedRow1 ? 'bgsubprimary' : ''}`}
                      onClick={() => {
                        setExpandedRow1(index == expandedRow1 ? -1 : index);
                      }}
                    >
                      <div className="d-flex w-100">
                        <div
                          className={`rounded-start m-0
                    ${
                      index == expandedRow1
                        ? 'indexlogo2'
                        : 'bg-primary indexlogo'
                    }`}
                        >
                          <div className="indextext pt-2">
                            {pageNo.current * itemsPerPage + (index + 1)}
                          </div>
                        </div>

                        <div className="p-3 pb-0 w-100">
                          <h5 className="oneline"> {edge?.node?.title}</h5>
                          <p className="n-subP">
                            {format(edge?.node?.createdAt)}
                          </p>
                        </div>
                      </div>

                      <i
                        className={
                          expandedRow1 == index
                            ? 'bx bx-minus-circle bx-sm  fs-3 text-danger align-self-center me-3'
                            : 'bx bx-plus-circle bx-sm  fs-3 align-self-center  me-3'
                        }
                      ></i>
                    </div>
                    <div className="d-flex flex-column">
                      {expandedRow1 == index && (
                        <div className="d-flex border-top">
                          <h6 className="py-3 ps-4 pt-4 font-weight-600 ">
                            Task
                          </h6>
                          <div
                            className={`d-flex justify-content-end gap-3 my-3 ms-auto me-2 ${
                              !(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.NOTIFICATION,
                                  PermissionCategoryEnum.EDIT
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              )
                                ? 'cursor-not-allowed'
                                : ''
                            }`}
                            onMouseEnter={() =>
                              setMarkAsDoneToolTipIsOpen(true)
                            }
                            onMouseLeave={() =>
                              setMarkAsDoneToolTipIsOpen(false)
                            }
                          >
                            <button
                              type="button"
                              className="btn fw-bold primaryDismissButton  bg-transparent shadow-none d-flex align-self-center  align-items-center rounded-pill ms-auto"
                              onClick={() => {
                                setSelectedNotification(edge?.node?.id);
                                setSelectedNotificationObject(edge?.node);
                                toggleModal();
                                setMomModalVisibility(true);
                              }}
                            >
                              Assign Task
                            </button>
                            <button
                              className={`btn fw-bold successButton  bg-transparent shadow-none d-flex align-self-center  align-items-center rounded-pill ms-auto me-3

                            ${
                              !(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.NOTIFICATION,
                                  PermissionCategoryEnum.EDIT
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ||
                              loadingUpdateTaskStatus ||
                              loadingUpdateNotificationStatus ||
                              loadingGetNotifications ||
                              !edge?.node?.tasks?.every(
                                (task: any) => task.status === true
                              ) ||
                              edge?.node?.status === true
                                ? 'disableBtn'
                                : ''
                            }
                            ${
                              edge?.node?.status === true
                                ? 'btn-primary'
                                : 'btn-danger'
                            } `}
                              id="markAsDoneButton"
                              disabled={
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.NOTIFICATION,
                                    PermissionCategoryEnum.EDIT
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                ) ||
                                loadingUpdateTaskStatus ||
                                loadingUpdateNotificationStatus ||
                                loadingGetNotifications ||
                                !edge?.node?.tasks?.every(
                                  (task: any) => task.status === true
                                ) ||
                                edge?.node?.status === true
                              }
                              onClick={() => {
                                updateNotificationStatus({
                                  variables: {
                                    id: edge?.node?.id,
                                    status: true,
                                  },
                                }).then(() => {
                                  let temp = JSON.parse(
                                    JSON.stringify(notifications)
                                  );
                                  temp.edges[index].node.status = true;
                                  setNotifications(temp);
                                  getNotifications();
                                });
                              }}
                            >
                              {edge?.node?.status === true
                                ? 'Completed'
                                : 'Mark as Done'}
                            </button>
                            {!(
                              GetPermissionsOfUser(
                                selectedUserPermissions,
                                ModuleEnum.NOTIFICATION,
                                PermissionCategoryEnum.EDIT
                              ) || userType === UserTypeEnum.COMPANY_ADMIN
                            ) ? (
                              <Tooltip
                                placement="top"
                                isOpen={markAsDoneToolTipIsOpen}
                                target="addButton"
                              >
                                You don't have permission for this module
                              </Tooltip>
                            ) : null}
                          </div>
                          {/* </button> */}
                        </div>
                      )}
                      {expandedRow1 == index &&
                        edge?.node?.tasks?.map((task: any, i: any) => (
                          <div className="border-top" key={i}>
                            <div
                              className={`ps-4 pt-2 d-flex justify-content-start text-center ${
                                task.status ? '' : 'text-danger'
                              } ${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.NOTIFICATION,
                                    PermissionCategoryEnum.EDIT
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setUpdateNotificationToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setUpdateNotificationToolTipIsOpen(false)
                              }
                            >
                              <input
                                className="me-3 form-check-input "
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                checked={task.status}
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.NOTIFICATION,
                                      PermissionCategoryEnum.EDIT
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  ) ||
                                  loadingUpdateTaskStatus ||
                                  loadingUpdateNotificationStatus ||
                                  loadingGetNotifications ||
                                  edge?.node?.status === true
                                }
                                onChange={e => {
                                  const { checked } = e.target;
                                  updateTaskStatus({
                                    variables: {
                                      id: task.id,
                                      status: checked,
                                    },
                                  }).then(() => {
                                    let temp = JSON.parse(
                                      JSON.stringify(notifications)
                                    );
                                    temp.edges[index].node.tasks[i].status =
                                      checked;
                                    setNotifications(temp);
                                  });
                                }}
                              />
                              <p
                                className={`taskmsg pb-0 ${
                                  task?.url
                                    ? 'text-decoration-underline text-primary cursor-pointer'
                                    : ''
                                }`}
                                onClick={() => {
                                  if (task?.url) {
                                    window.open(task.url, 'new');
                                  }
                                }}
                              >
                                {task.message}
                              </p>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.NOTIFICATION,
                                  PermissionCategoryEnum.EDIT
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={updateNotificationToolTipIsOpen}
                                  target="flexCheckChecked"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 mt-2 mx-1">
          <p className="m-0 text-info">Rows Per Page:</p>
          <select
            className="form-control button-danger text-info width-5vw"
            value={itemsPerPage}
            onChange={e => {
              const { value } = e.target;
              setExpandedRow(-1);
              setItemsPerPage(Number(value));
            }}
          >
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <i
            className={
              notifications?.pageInfo?.hasPreviousPage
                ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                : 'bx bx-caret-left bx-sm ms-auto'
            }
            onClick={() => {
              if (companyID && notifications?.pageInfo?.hasPreviousPage) {
                setExpandedRow(-1);
                getNotifications({
                  variables: {
                    last: itemsPerPage,
                    before: notifications?.pageInfo?.startCursor,
                  },
                });
                pageNo.current = pageNo.current - 1;
              }
            }}
          />
          <i
            className={
              notifications?.pageInfo?.hasNextPage
                ? 'bx bx-caret-right color-primary bx-sm'
                : 'bx bx-caret-right bx-sm'
            }
            onClick={() => {
              if (companyID && notifications?.pageInfo?.hasNextPage) {
                setExpandedRow(-1);
                getNotifications({
                  variables: {
                    after: notifications?.pageInfo?.endCursor,
                  },
                });
                pageNo.current = pageNo.current + 1;
              }
            }}
          />
        </div>
      </div>

      <Modal
        className="modalreact"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluids>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              {/* <i className="bx  text-primary bx-sm me-2"></i>s */}
              <i className="bx bxs-user-check text-primary bx-md me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Assign Task
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            {/*  */}
            <div className="mt-2 w-100">
              <div className="d-flex gap-3 w-100">
                <div className="inputFields w-100">
                  <div className="form-group d-flex gap-3 w-100">
                    {/* <div className="d-flex w-300 pt-2">
                      <label htmlFor="userGroup">Search</label>
                    </div> */}
                    <i className="bx bx-search-alt-2 font-20 iconLeft pt-4"></i>
                    <input
                      type="text"
                      className="form-control ps-5 mt-4 "
                      placeholder="Search.."
                      value={searchName}
                      onChange={e => {
                        setSearchName(e.target.value);
                      }}
                    />

                    <button
                      type="button"
                      className="btn fw-bold dangerButton d-flex align-items-center oneline my-auto "
                      onClick={() => {
                        if (searchName) {
                          setSearchName('');
                          getAllUsers();
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn successButton d-flex align-items-center  align-self-center my-auto fw-bold "
                      onClick={() => {
                        if (searchName) {
                          getAllUsers({
                            variables: {
                              name: searchName,
                            },
                          });
                        }
                      }}
                    >
                      Search
                    </button>

                    <button
                      type="button"
                      className="btn successButton d-flex align-items-center  align-self-center  ms-auto me-0 fw-bold "
                      disabled={
                        dataGetAllGetAllUsers?.users?.edges.some(
                          (item: any) => item?.node?.tbd
                        )
                          ? false
                          : true
                      }
                      onClick={() => {
                        assignNotificationToCompany({
                          variables: {
                            notificationId: selectedNotification,
                            userId: userIds,
                          },
                        })
                          .then(res => {
                            dispatch(
                              setToastSuccess(
                                'Notification assigned to user successfully!'
                              )
                            );
                            setMomModalVisibility(false);
                            if (res?.data?.assignNotificationToUser?.isSent) {
                              getNotifications().then(res => {
                                const notification =
                                  res?.data?.findNotifications?.edges.find(
                                    (notification: any) => {
                                      return (
                                        notification?.node?.id ===
                                        selectedNotification
                                      );
                                    }
                                  );
                                setSelectedNotificationObject(
                                  notification?.node
                                );
                                setToastSuccess(
                                  'Notification assigned to user successfully!'
                                );
                              });
                            }
                          })
                          .catch(error => {
                            console.log(error);
                            setToastError(error);
                          });
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div className="user-card">
                <div className="d-flex gap-1 flex-wrap w-100">
                  {dataGetAllGetAllUsers?.users?.edges.map(
                    (edge: any, index: any) =>
                      edge?.node?.companyId.find(
                        (id: any) => id.companyId == companyID
                      ) && (
                        <div className="col-3x3 mb-3 cardW d-flex ps-0 m-0 p-0">
                          <div className="d-flex w-100">
                            <div className="rounded-circle color-img userIcon m-2 w-100">
                              {edge?.node?.firstName
                                .substring(0, 2)
                                .toUpperCase()}
                            </div>

                            <div className="mt-4 pt-1">
                              <h4>
                                {edge?.node?.firstName +
                                  ' ' +
                                  edge?.node?.lastName}
                              </h4>
                              <p className="text-break">{edge?.node?.email}</p>
                            </div>
                          </div>
                          <div className="d-flex flex-column  mt-2 ">
                            <input
                              className="m-2 checkbox-newer checkbox-md form-check-input"
                              type="checkbox"
                              id={'userid'}
                              name={'userid'}
                              value={'userid'}
                              checked={selectedNotificationObject?.userIds.find(
                                (userId: any) => {
                                  return userId === edge?.node?.id
                                    ? true
                                    : false;
                                }
                              )}
                              disabled={selectedNotificationObject?.userIds.find(
                                (userId: any) => {
                                  return userId === edge?.node?.id
                                    ? true
                                    : false;
                                }
                              )}
                              onChange={e => {
                                edge.node.tbd = e.target.checked;
                                if (e.target.checked === true) {
                                  validation.setFieldValue('userid', true);
                                  adduser(edge.node.id);
                                } else {
                                  validation.setFieldValue('userid', false);
                                  removeuser(edge.node.id);
                                }
                              }}
                            ></input>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {/*  */}

            <br className="my-4"></br>
          </div>
        </Container>
      </Modal>
    </>
  );
});
