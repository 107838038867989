import { createSlice } from '@reduxjs/toolkit';
import { CompanyMemberStoreType } from './types';

const initialState: CompanyMemberStoreType = {
  selectedMemberId: '',
  memberView: false,
};
const companyMemberSlice = createSlice({
  name: 'company-member',
  initialState,
  reducers: {
    setSelectedMemberId: (state, { payload }) => {
      state.selectedMemberId = payload;
    },
    setMemberViewScreen: (state, { payload }) => {
      state.memberView = payload;
    },
  },
});

export const {
  setSelectedMemberId,
  setMemberViewScreen,
} = companyMemberSlice.actions;

export default companyMemberSlice.reducer;
