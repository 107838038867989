import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import {
  FIND_COMPANY_PROFILE_BY_USER_ID,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_DRAFT,
} from '~/Store/graphQL/CompanyProfile';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { CompanyAddressesInput } from '../types';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import {
  CompanyKindEnum,
  ModuleEnum,
  SectorialClassificationEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { Container, Modal } from 'reactstrap';
import { Country } from '~/assets/StatesCities';
import { AddressIcon, building, ContactInfoIcon } from '~/assets/images';
import moment from 'moment';

export const CompanyAddressHistory: FC = () => {
  const companyView = true;
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isDraft, setIsDraft] = useState<boolean>(false);

  const [editableRow, setEditableRow] = useState<number>(-1);
  const [products, setProducts] = useState<
    CompanyAddressesInput[] | undefined
  >();
  const [companyProfile, setCompanyProfile] = useState<any>();

  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };

  const [states, setStates] = useState<any>();

  const [selectedIndex, setSelectedIndex] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Company History',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);
  const [
    draftUpdateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE_DRAFT
    // { errorPolicy: 'all' }
  );
  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE
    // { errorPolicy: 'all' }
  );

  useEffect(() => {
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [userId]);

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      setCompanyProfile(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
      );
      setProducts(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
          .companyHistory
      );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );
      setStates(
        Country.states.find(
          state =>
            state.name ===
            dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
              .province
        )
      );
    } else if (errorCompanyProfileByUserId) {
      console.log('errorCompanyProfileByUserId:', errorCompanyProfileByUserId);
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
      setEditableRow(-1);
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);
  useEffect(() => {
    if (dataupdateCompanyProfile && !errorupdateCompanyProfile) {
      setEditableRow(-1);
    } else if (errorupdateCompanyProfile) {
      dispatch(setToastError(errorupdateCompanyProfile));
    }
  }, [errorupdateCompanyProfile, dataupdateCompanyProfile]);
  // if (products) {
  //   console.log('hiss', products[selectedIndex]);
  // }
  // console.log(selectedIndex);

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3  end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn fw-bold primaryDismissButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile/mortgagesCharges');
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile/advancedCapitalHistory');
                  }}
                >
                  Next
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </span>
        </div>
        {/*  */}
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link active">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link active cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link cursor-pointer"
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        <div id="mainComponentNew">
          {loadingCompanyProfileByUserId ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <div className="w-100" style={{ display: 'contents' }}>
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Province</th>
                      <th>City</th>
                      <th>District</th>
                      <th>Area / Town</th>
                      <th>Postal Code</th>
                      <th>Address</th>
                      <th>Date</th>
                      <th>SECP Ref. ID</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.length === 0 ||
                    products === undefined ||
                    products == null ? (
                      <tr>
                        <td className="border-end">
                          <div className="badge custom-badge-primary">{1}</div>
                        </td>
                        <td className="border-end">
                          {companyProfile?.province
                            ? companyProfile?.province
                            : 'N/A'}
                        </td>
                        <td className="border-end">
                          {companyProfile?.city ? companyProfile?.city : 'N/A'}
                        </td>
                        <td className="border-end">
                          {companyProfile?.district
                            ? companyProfile?.district
                            : 'N/A'}
                        </td>
                        <td className="border-end">
                          {companyProfile?.town ? companyProfile?.town : 'N/A'}
                        </td>
                        <td className="border-end">
                          {companyProfile?.postalCode
                            ? companyProfile?.postalCode
                            : 'N/A'}
                        </td>
                        <td className="border-end">
                          {companyProfile?.address
                            ? companyProfile?.address
                            : 'N/A'}
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-success">
                            {companyProfile?.updatedAt &&
                              new Date(companyProfile?.updatedAt)
                                .toISOString()
                                .slice(0, 10)}
                          </div>
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-success">
                            {companyProfile?.secpRefId
                              ? companyProfile.secpRefId
                              : 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      products?.map((val: any, index: number) => (
                        <tr key={`${index}`}>
                          <td className="border-end">
                            <div className="badge custom-badge-primary">
                              {index + 1}
                            </div>
                          </td>
                          <td className="border-end">
                            {val.province ? val.province : 'N/A'}
                          </td>
                          <td className="border-end">
                            {val.city ? val.city : 'N/A'}
                          </td>
                          <td className="border-end">
                            {val.district ? val.district : 'N/A'}
                          </td>
                          <td className="border-end">
                            {val.town ? val.town : 'N/A'}
                          </td>
                          <td className="border-end">
                            {val.postalCode ? val.postalCode : 'N/A'}
                          </td>
                          <td className="border-end">
                            {val.address ? val.address : 'N/A'}
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-success">
                              {val.updatedAt &&
                                new Date(val.updatedAt)
                                  .toISOString()
                                  .slice(0, 10)}
                            </div>
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-success">
                              {val?.secpRefId ? val.secpRefId : 'N/A'}
                            </div>
                          </td>
                          <td>
                            <div className="editColumn mt-0 gap-1">
                              <i
                                className="bx bx-show-alt text-primary"
                                onClick={() => {
                                  setSelectedIndex(index);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}

      <Modal
        className="modalreact"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluids>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              {/* <i className="bx  text-primary bx-sm me-2"></i>s */}
              <i className="bx bx-history text-primary bx-md me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                History
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            {/*  */}

            <div className="mt-2">
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={building}
                      ></img>
                      <div className="font-weight-600">Company Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="name">Company Name*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.name ===
                            products[selectedIndex + 1]?.name
                            ? ''
                            : 'updated'
                        }`}
                        id="name"
                        name="name"
                        placeholder="Company Name"
                        value={
                          products && products[selectedIndex]?.name
                            ? products[selectedIndex]?.name
                            : 'NA'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cuinNumber">CUIN No*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.cuinNumber ===
                            products[selectedIndex + 1]?.cuinNumber
                            ? ''
                            : 'updated'
                        }`}
                        id="cuinNumber"
                        name="cuinNumber"
                        placeholder="CUIN No"
                        value={
                          products && products[selectedIndex]?.cuinNumber
                            ? products[selectedIndex]?.cuinNumber
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="kind">Company Kind*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            products &&
                            products[selectedIndex]?.kind ===
                              products[selectedIndex + 1]?.kind
                              ? ''
                              : 'updated'
                          }`}
                          id="kind"
                          name="kind"
                          placeholder="Company Kind"
                          value={
                            products && products[selectedIndex]?.kind
                              ? products[selectedIndex]?.kind
                              : 'N/A'
                          }
                          disabled={companyView}
                        >
                          <option value="">Select</option>
                          <option value={CompanyKindEnum.SMC}>SMC</option>
                          <option value={CompanyKindEnum.PRIVATE}>
                            Private
                          </option>
                          <option
                            value={CompanyKindEnum.UNLISTED_PUBLIC_COMPANY}
                          >
                            Unlisted public company
                          </option>
                          <option value={CompanyKindEnum.LISTED_COMPANY}>
                            Listed company
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="incorporationDate">
                          Incorporation Date*
                        </label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.incorporationDate ===
                            products[selectedIndex + 1]?.incorporationDate
                            ? ''
                            : 'updated'
                        }`}
                        id="incorporationDate"
                        name="incorporationDate"
                        value={
                          products && products[selectedIndex]?.incorporationDate
                            ? moment(
                                products[selectedIndex]?.incorporationDate
                              ).format('MMM DD, yyyy')
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="commencementOfBusiness">
                          Commencement of Business*
                        </label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.commencementOfBusiness ===
                            products[selectedIndex + 1]?.commencementOfBusiness
                            ? ''
                            : 'updated'
                        }`}
                        id="commencementOfBusiness"
                        name="commencementOfBusiness"
                        value={
                          products &&
                          products[selectedIndex]?.commencementOfBusiness
                            ? moment(
                                products[selectedIndex]?.commencementOfBusiness
                              ).format('MMM DD, yyyy')
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="sectorialClassification">
                          Sectorial Classification*
                        </label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            products &&
                            products[selectedIndex]?.sectorialClassification ===
                              products[selectedIndex + 1]
                                ?.sectorialClassification
                              ? ''
                              : 'updated'
                          }`}
                          id="sectorialClassification"
                          name="sectorialClassification"
                          placeholder="Sectorial Classification"
                          value={
                            products &&
                            products[selectedIndex]?.sectorialClassification
                              ? products[selectedIndex]?.sectorialClassification
                              : 'N/A'
                          }
                          disabled={companyView}
                        >
                          <option value="">Select</option>
                          <option
                            value={SectorialClassificationEnum.BASIC_MATERIALS}
                          >
                            Basic Materials
                          </option>
                          <option
                            value={SectorialClassificationEnum.CONSUMER_GOODS}
                          >
                            Consumer Goods
                          </option>
                          <option
                            value={
                              SectorialClassificationEnum.CONSUMER_SERVICES
                            }
                          >
                            Consumer Services
                          </option>
                          <option
                            value={SectorialClassificationEnum.FINANCIALS}
                          >
                            Financials
                          </option>
                          <option
                            value={SectorialClassificationEnum.HEALTH_CARE}
                          >
                            Health Care
                          </option>
                          <option
                            value={SectorialClassificationEnum.INDUSTRIALS}
                          >
                            Industrials
                          </option>
                          <option
                            value={SectorialClassificationEnum.OIL_AND_GAS}
                          >
                            Oil and Gas
                          </option>
                          <option
                            value={SectorialClassificationEnum.TECHNOLOGY}
                          >
                            Technology
                          </option>
                          <option
                            value={
                              SectorialClassificationEnum.TELECOMMUNICATIONS
                            }
                          >
                            Telecommunications
                          </option>
                          <option value={SectorialClassificationEnum.UTILITIES}>
                            Utilities
                          </option>
                          <option
                            value={SectorialClassificationEnum.MISCELLANEOUS}
                          >
                            Miscellaneous
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="companyObjects">Company Objects*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.companyObjects ===
                            products[selectedIndex + 1]?.companyObjects
                            ? ''
                            : 'updated'
                        }`}
                        id="companyObjects"
                        name="companyObjects"
                        placeholder="Company Objects"
                        value={
                          products && products[selectedIndex]?.companyObjects
                            ? products[selectedIndex]?.companyObjects
                            : 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="holdingCompany">Holding Company</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.holdingCompany ===
                            products[selectedIndex + 1]?.holdingCompany
                            ? ''
                            : 'updated'
                        }`}
                        id="holdingCompany"
                        name="holdingCompany"
                        placeholder="Holding Company"
                        value={
                          products && products[selectedIndex]?.holdingCompany
                            ? products[selectedIndex]?.holdingCompany
                            : 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4"></div>
              </div>

              <div className="inputFields border-div px-3 pt-3 mt-3">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}

                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={AddressIcon}
                      ></img>
                      <div className="font-weight-600">Address</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="province">Province*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            products &&
                            products[selectedIndex]?.province ===
                              products[selectedIndex + 1]?.province
                              ? ''
                              : 'updated'
                          }`}
                          id="province"
                          name="province"
                          placeholder="Province"
                          value={
                            products && products[selectedIndex]?.province
                              ? products[selectedIndex]?.province
                              : 'N/A'
                          }
                          disabled={companyView}
                        >
                          <option value="">Select</option>
                          {Country.states?.map(state => (
                            <option value={state.name}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex w-400">
                        <label htmlFor="district">District*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            products &&
                            products[selectedIndex]?.district ===
                              products[selectedIndex + 1]?.district
                              ? ''
                              : 'updated'
                          }`}
                          id="district"
                          name="district"
                          placeholder="District"
                          value={
                            products && products[selectedIndex]?.district
                              ? products[selectedIndex]?.district
                              : 'N/A'
                          }
                          disabled={companyView}
                        >
                          <option value="">Select</option>
                          {states?.cities
                            ?.filter((data: any) => data?.district)
                            .map((city: any) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="city">City*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            products &&
                            products[selectedIndex]?.city ===
                              products[selectedIndex + 1]?.city
                              ? ''
                              : 'updated'
                          }`}
                          id="city"
                          name="city"
                          placeholder="City"
                          value={
                            products && products[selectedIndex]?.city
                              ? products[selectedIndex]?.city
                              : 'N/A'
                          }
                          disabled={companyView}
                        >
                          <option value="">Select</option>
                          {states?.cities
                            ?.filter(
                              (data: any) => !data.name.includes('District')
                            )
                            .map((city: any) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="town">Area / Town*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.town ===
                            products[selectedIndex + 1]?.town
                            ? ''
                            : 'updated'
                        }`}
                        id="town"
                        name="town"
                        placeholder="Area / Town"
                        value={
                          products && products[selectedIndex]?.town
                            ? products[selectedIndex]?.town
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="postalCode">Postal Code*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.postalCode ===
                            products[selectedIndex + 1]?.postalCode
                            ? ''
                            : 'updated'
                        } `}
                        id="postalCode"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={
                          products && products[selectedIndex]?.postalCode
                            ? products[selectedIndex]?.postalCode
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="address">Address*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.address ===
                            products[selectedIndex + 1]?.address
                            ? ''
                            : 'updated'
                        } `}
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={
                          products && products[selectedIndex]?.address
                            ? products[selectedIndex]?.address
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inputFields border-div px-3 pt-3 mt-3">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}

                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={ContactInfoIcon}
                      ></img>
                      <div className="font-weight-600">Contact Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="mobile">Mobile*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox  ${
                          products &&
                          products[selectedIndex]?.mobile ===
                            products[selectedIndex + 1]?.mobile
                            ? ''
                            : 'updated'
                        }`}
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        value={
                          products && products[selectedIndex]?.mobile
                            ? products[selectedIndex]?.mobile
                            : 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="phone">Telephone*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.phone ===
                            products[selectedIndex + 1]?.phone
                            ? ''
                            : 'updated'
                        }`}
                        id="phone"
                        name="phone"
                        placeholder="Telephone"
                        value={
                          products && products[selectedIndex]?.phone
                            ? products[selectedIndex]?.phone
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="email">Email ID*</label>
                      </div>
                      <input
                        type="email"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.email ===
                            products[selectedIndex + 1]?.email
                            ? ''
                            : 'updated'
                        }`}
                        id="email"
                        name="email"
                        placeholder="Email ID"
                        value={
                          products && products[selectedIndex]?.email
                            ? products[selectedIndex]?.email
                            : 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="fax">Fax*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.fax ===
                            products[selectedIndex + 1]?.fax
                            ? ''
                            : 'updated'
                        }`}
                        id="fax"
                        name="fax"
                        placeholder="Fax"
                        value={
                          products && products[selectedIndex]?.fax
                            ? products[selectedIndex]?.fax
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="websiteUrl">Website URL*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          products &&
                          products[selectedIndex]?.websiteUrl ===
                            products[selectedIndex + 1]?.websiteUrl
                            ? ''
                            : 'updated'
                        }`}
                        id="websiteUrl"
                        name="websiteUrl"
                        placeholder="Website URL"
                        value={
                          products && products[selectedIndex]?.websiteUrl
                            ? products[selectedIndex]?.websiteUrl
                            : 'N/A'
                        }
                        disabled={companyView}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            <br className="my-4"></br>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
