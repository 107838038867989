import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import {
  CREATE_COMPANY_MEMBER_DRAFT,
  CREATE_COMPANY_MEMBER_FROM_EXCEL_FILE,
  DELETE_COMPANY_MEMBER,
  DRAFT_DELETE_COMPANY_MEMBER,
  EXPORT_COMPANY_MEMBER_DATA_IN_EXCEL_FILE,
  GET_ALL_COMPANY_MEMBERS,
} from '~/Store/graphQL/CompanyMembers';
import { DELETE_FILE_FROM_OBS } from '~/Store/graphQL/CompanyOfficer';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setMemberViewScreen,
  setSelectedMemberId,
} from '~/Store/reducers/companyMembers';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import {
  DesignationEnum,
  MemberCategoryEnum,
  MemberSearchParameterOptionsEnum,
  MemberStatusEnum,
  MemberTypeEnum,
  ModuleEnum,
  OfficerStatusEnum,
  PermissionCategoryEnum,
  SearchParameterOptionsEnum,
  UserTypeEnum,
} from '../../../commons/enums';
import './companyMember.scss';
import * as XLSX from 'xlsx';
import {
  CompanyMemberInputDto,
  CompanyNomineeInfoInput,
  JointShareHolderInfoInput,
} from './types';
import GetCompanyMemberStatus from '~/helpers/CompanyMember/getCompanyMemberStatus';
import GetCompanyMemberCategory from '~/helpers/CompanyMember/getCompanyMemberCategory';
import GetCompanyMemberType from '~/helpers/CompanyMember/getMemberType';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { Tooltip } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const CompanyMembers: FC = () => {
  // const { token } = useAppSelector(state => state.layoutReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [actualMembers, setActualMembers] = useState<boolean>(true);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  // const [filters, setFilters] = useState<any>({
  //   searchKey: '',
  //   searchValue: '',
  // });
  const [companyMembers, setCompanyMembers] = useState<
    CompanyMemberInputDto[] | undefined
  >();
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  const [importToolTipIsOpen, setImportToolTipIsOpen] = useState(false);
  const [exportToolTipIsOpen, setExportToolTipIsOpen] = useState(false);
  function ExcelDateToJSDate(serial: any) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
  }
  useEffect(() => {
    if (companyMembers) {
      createCompanyMemberExcelFile({
        variables: {
          companyId: companyID,
          companyMember: companyMembers,
        },
      })
        .then((res: any) => {
          if (res.data.importCompanyMembers.isSuccessfull) {
            dispatch(setToastSuccess('Company Members Added From Excel File!'));
            getAllCompanyMembers();
          } else {
            dispatch(
              setToastError(
                'Some Error occured while adding company members from excel file!'
              )
            );
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    }
  }, [companyMembers]);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: '',
        },
      ])
    );
  }, []);
  const [
    createCompanyMemberExcelFile,
    {
      loading: loadingCreateCompanyMemberExcelFile,
      error: errorCreateCompanyMemberExcelFile,
      data: dataCreateCompanyMemberExcelFile,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_FROM_EXCEL_FILE);
  // Get all company members query
  const [
    getAllCompanyMembers,
    {
      loading: loadingGetAllCompanyMembers,
      error: errorGetAllCompanyMembers,
      data: dataGetAllCompanyMembers,
    },
  ] = useLazyQuery<any>(GET_ALL_COMPANY_MEMBERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  //Create draft company member
  const [
    createDraftCompanyMember,
    {
      loading: loadingCreateDraftCompanyMember,
      error: errorCreateDraftCompanyMember,
      data: dataCreateDraftCompanyMember,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_DRAFT);
  //delete company member
  const [
    deleteCompanyMember,
    {
      loading: loadingDeleteCompanyMember,
      error: errorDeleteCompanyMember,
      data: dataDeleteCompanyMember,
    },
  ] = useMutation<any>(DELETE_COMPANY_MEMBER);
  // Delete draft company member
  const [
    draftDeleteCompanyMember,
    {
      loading: loadingDraftDeleteCompanyMember,
      error: errorDraftDeleteCompanyMember,
      data: dataDraftDeleteCompanyMember,
    },
  ] = useMutation<any>(DRAFT_DELETE_COMPANY_MEMBER);
  //Export data of company members
  const [
    exportDataOfCompanyMembers,
    {
      loading: loadingExportDataOfCompanyMembers,
      error: errorExportDataOfCompanyMembers,
      data: dataExportDataOfCompanyMembers,
    },
  ] = useLazyQuery<any>(EXPORT_COMPANY_MEMBER_DATA_IN_EXCEL_FILE, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // delete file from OBS
  const [
    deleteFileFromOBS,
    {
      loading: loadingDeleteFileFromOBS,
      error: errorDeleteFileFromOBS,
      data: dataDeleteFileFromOBS,
    },
  ] = useMutation<any>(DELETE_FILE_FROM_OBS);
  useEffect(() => {
    if (companyID) {
      getAllCompanyMembers({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch.values?.searchKey !== 'ALL'
              ? validationSearch.values?.searchKey
              : undefined,
          searchValue:
            validationSearch.values?.searchValue !== 'Select'
              ? validationSearch.values?.searchValue
              : undefined,
        },
      });
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllCompanyMembers) {
      dispatch(setToastError(errorGetAllCompanyMembers));
    }
  }, [errorGetAllCompanyMembers]);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    var FR = new FileReader();
    FR.onload = function (e: any) {
      var workbook = XLSX.readFile(e.target.result, {
        dateNF: 'mm"/"dd"/"yyyy',
      });
      var sheet_name_list = workbook.SheetNames;
      var xlData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[0]]
      );
      let tempCompanyMembers: CompanyMemberInputDto[] = [];
      xlData = xlData.filter(
        (value: any, index, self) =>
          index === self.findIndex((t: any) => t.CNIC === value.CNIC)
      );
      xlData.forEach((element: any) => {
        let companyMember: CompanyMemberInputDto = {};
        let nominee: CompanyNomineeInfoInput[] = [
          {
            firstName: '',
            middleName: '',
            lastName: '',
            nominationReceiptDate: '',
            relativeFirstName: '',
            relativeMiddleName: '',
            relativeLastName: '',
            relationship: '',
            nationality: '',
            cnic: '',
            passportNumber: '',
            residentialAddress: '',
            phone: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',
            trusteeName: '',
            dobMinor: '',
            isActive: false,
          },
        ];
        let jointShareHolders: JointShareHolderInfoInput[] = [];
        companyMember.firstName = element.First_Name;
        companyMember.middleName = element.Middle_Name;
        companyMember.lastName = element.Last_Name;
        companyMember.status = element.Status;
        companyMember.type = element.Type;
        companyMember.category = element.Category;
        companyMember.relativeFirstName = element.Relative_First_Name;
        companyMember.relativeMiddleName = element.Relative_Middle_Name;
        companyMember.relativeLastName = element.Relative_Last_Name;
        companyMember.relationship = element.Relationship;
        companyMember.nationality = element.Nationality;
        companyMember.cnic = element.CNIC;
        companyMember.passportNumber = element.Passport_Number.toString();
        companyMember.ntn = element.NTN.toString();
        companyMember.occupation = element.Occupation;
        companyMember.companyName = element.Company_Name;
        companyMember.companyCuin = element.Company_CUIN.toString();
        companyMember.numOfShares = element.Number_Of_Shares;
        companyMember.holdingCompany = element.Holding_Company;
        companyMember.subsidiaries = element.Subsdiaries;
        companyMember.residentialAddress = element.Residential_Address;
        companyMember.phone = element.Telephone.toString();
        companyMember.city = element.City;
        companyMember.province = element.Province;
        companyMember.country = element.Country;
        companyMember.postalCode = element.Postal_Code.toString();
        companyMember.dateOfMembership = ExcelDateToJSDate(
          element.Date_Of_Membership
        ).toString();
        companyMember.legalPersonName = element.Legal_Person_Name;
        companyMember.cdcAccountNumber = element.CDC_Account_Number;
        companyMember.instructions = element.Instructions;
        companyMember.otherInstructions = element.Other_Instructions;
        companyMember.amountPaid = element.Amount_Paid;
        companyMember.action = element.Action;
        companyMember.ceaseDate = ExcelDateToJSDate(
          element.Cease_Date
        ).toString();
        companyMember.cessationReason = element.Cease_Reason;
        companyMember.isActive = element.IsActive;
        nominee[0].firstName = element.Nominee_First_Name;
        nominee[0].middleName = element.Nominee_Middle_Name;
        nominee[0].lastName = element.Nominee_Last_Name;
        nominee[0].nominationReceiptDate = ExcelDateToJSDate(
          element.Nomination_Receipt_Date
        ).toString();
        nominee[0].relativeFirstName = element.Nominee_Relative_First_Name;
        nominee[0].relativeMiddleName = element.Nominee_Relative_Middle_Name;
        nominee[0].relativeLastName = element.Nominee_Relative_Last_Name;
        nominee[0].relationship = element.Nominee_Relationship;
        nominee[0].nationality = element.Nominee_Nationality;
        nominee[0].cnic = element.Nominee_CNIC;
        nominee[0].passportNumber = element.Nominee_Passport_Number;
        nominee[0].residentialAddress = element.Nominee_Residential_Address;
        nominee[0].phone = element.Nominee_Telephone
          ? element.Nominee_Telephone.toString()
          : undefined;
        nominee[0].city = element.Nominee_City;
        nominee[0].province = element.Nominee_Province;
        nominee[0].country = element.Nominee_Country;
        nominee[0].postalCode = element.Nominee_Postal_Code
          ? element.Nominee_Postal_Code.toString()
          : undefined;
        nominee[0].trusteeName = element.Nominee_Trustee_Name;
        nominee[0].dobMinor = ExcelDateToJSDate(
          element.Nominee_Date_Of_Birth_Minor
        ).toString();
        nominee[0].isActive = element.Nominee_IsActive;
        companyMember.companyNomineeInfo = nominee[0].firstName ? nominee : [];
        let jointShareHoldersArray =
          element.Joint_Share_Holders_Info?.split(';');
        jointShareHoldersArray?.forEach((element: any, index: number) => {
          let nameArray = element?.split(',');
          if (nameArray.length > 0 && nameArray[0] !== '') {
            let jointShareHolder: JointShareHolderInfoInput = {};
            if (nameArray.length === 2) {
              jointShareHolder.firstName = nameArray[0];
              jointShareHolder.middleName = '';
              jointShareHolder.lastName = nameArray[1];
            } else {
              jointShareHolder.firstName = nameArray[0];
              jointShareHolder.middleName = nameArray[1];
              jointShareHolder.lastName = nameArray[2];
            }
            jointShareHolders.push(jointShareHolder);
          }
        });
        companyMember.jointShareHolderInfo = jointShareHolders;
        tempCompanyMembers?.push(companyMember);
      });
      setCompanyMembers(tempCompanyMembers);
    };
    if (e?.target?.files) {
      FR.readAsArrayBuffer(e?.target?.files[0]);
    }
  };
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: (values: any) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        {false ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <i className="bx bx-loader-circle bx-spin bx-lg"></i>
          </div>
        ) : (
          <div style={{ width: 'inherit' }}>
            <div className="row d-flex align-items-center mb-5 mx-1">
              <div className="col-1 dot p-0"></div>
              <div className="col-11 d-flex align-items-center">
                <h5 className="m-0">
                  <b>Company Members</b>
                </h5>
              </div>
            </div>
            <div className="d-flex row mt-2 mb-4">
              {/* Second Row */}
              <div className="companyMemberfields">
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchKey">Choose Option</label>
                    {validationSearch.touched.searchKey &&
                    validationSearch.errors.searchKey ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchKey}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchKey"
                      name="searchKey"
                      className={`form-control ${
                        validationSearch.touched.searchKey &&
                        validationSearch.errors.searchKey &&
                        'is-invalid'
                      }`}
                      value={validationSearch?.values?.searchKey}
                      onChange={e => {
                        validationSearch.setFieldValue('searchValue', '');
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchKey', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={MemberSearchParameterOptionsEnum.FOLIO_NO}>
                        {' '}
                        Folio No{' '}
                      </option>
                      <option value={MemberSearchParameterOptionsEnum.CATEGORY}>
                        {' '}
                        Category{' '}
                      </option>
                      <option
                        value={MemberSearchParameterOptionsEnum.MEMBER_TYPE}
                      >
                        {' '}
                        Member Type{' '}
                      </option>
                      <option value={MemberSearchParameterOptionsEnum.STATUS}>
                        {' '}
                        Status{' '}
                      </option>
                      <option value={MemberSearchParameterOptionsEnum.CITY}>
                        {' '}
                        City{' '}
                      </option>
                      <option value={MemberSearchParameterOptionsEnum.PHONE}>
                        {' '}
                        Contact{' '}
                      </option>
                    </select>
                  </div>
                </div>
                {validationSearch.values.searchKey ===
                MemberSearchParameterOptionsEnum.CATEGORY ? (
                  <div className="form-group">
                    <div className="d-flex w-300">
                      <label htmlFor="shareType">Choose Option</label>
                      {validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validationSearch.errors.searchValue}
                        </p>
                      ) : null}
                    </div>
                    <div className="field">
                      <select
                        id="searchValue"
                        name="searchValue"
                        className={`form-control ${
                          validationSearch.touched.searchValue &&
                          validationSearch.errors.searchValue &&
                          'is-invalid'
                        }`}
                        value={validationSearch.values.searchValue}
                        onChange={e => {
                          const { value } = e.target;
                          // validationSearch.values.searchValue = value;
                          validationSearch.setFieldValue('searchValue', value);

                          // let temp = JSON.parse(JSON.stringify(filters));
                          // temp.searchValue = value;
                          // setFilters(temp);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={MemberCategoryEnum.INDIVIDUAL}>
                          {' '}
                          Individual{' '}
                        </option>
                        <option value={MemberCategoryEnum.LEGAL_PERSON}>
                          {' '}
                          Legal Person{' '}
                        </option>
                      </select>
                    </div>
                  </div>
                ) : validationSearch.values.searchKey ===
                  MemberSearchParameterOptionsEnum.MEMBER_TYPE ? (
                  <div className="form-group">
                    <div className="d-flex w-300">
                      <label htmlFor="shareType">Choose Option</label>
                      {validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validationSearch.errors.searchValue}
                        </p>
                      ) : null}
                    </div>
                    <div className="field">
                      <select
                        id="searchValue"
                        name="searchValue"
                        className={`form-control ${
                          validationSearch.touched.searchValue &&
                          validationSearch.errors.searchValue &&
                          'is-invalid'
                        }`}
                        value={validationSearch.values.searchValue}
                        onChange={e => {
                          const { value } = e.target;
                          // validationSearch.values.searchValue = value;
                          validationSearch.setFieldValue('searchValue', value);

                          // let temp = JSON.parse(JSON.stringify(filters));
                          // temp.searchValue = value;
                          // setFilters(temp);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={MemberTypeEnum.DIRECTOR}>
                          {' '}
                          Director{' '}
                        </option>
                        <option value={MemberTypeEnum.NOMINEE_DIRECTOR}>
                          {' '}
                          Nominee Director{' '}
                        </option>
                        <option value={MemberTypeEnum.AUDITOR}>
                          {' '}
                          Auditor{' '}
                        </option>
                        <option value={MemberTypeEnum.CHIEF_ACCOUNTANT}>
                          {' '}
                          Chief Accountant{' '}
                        </option>
                        <option value={MemberTypeEnum.CHAIRMAN}>
                          {' '}
                          Chairman{' '}
                        </option>
                        <option value={MemberTypeEnum.COMPANY_SECRETARY}>
                          {' '}
                          Company Secretary{' '}
                        </option>
                        <option value={MemberTypeEnum.CHIEF_EXECUTIVE}>
                          {' '}
                          Chief Executive{' '}
                        </option>
                        <option value={MemberTypeEnum.MANAGING_ACCOUNTANT}>
                          {' '}
                          Managing Accountant{' '}
                        </option>
                        <option value={MemberTypeEnum.RELATIVE}>
                          {' '}
                          Relative{' '}
                        </option>
                        <option value={MemberTypeEnum.JOINT_OWNER_SHARE}>
                          {' '}
                          Joint Owner Share{' '}
                        </option>
                        <option
                          value={MemberTypeEnum.ULTIMATE_BENEFICIAL_OWNER}
                        >
                          {' '}
                          Ultimate Beneficial Owner{' '}
                        </option>
                      </select>
                    </div>
                  </div>
                ) : validationSearch.values.searchKey ===
                  MemberSearchParameterOptionsEnum.STATUS ? (
                  <div className="form-group">
                    <div className="d-flex w-300">
                      <label htmlFor="shareType">Choose Option</label>
                      {validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validationSearch.errors.searchValue}
                        </p>
                      ) : null}
                    </div>
                    <div className="field">
                      <select
                        id="searchValue"
                        name="searchValue"
                        className={`form-control ${
                          validationSearch.touched.searchValue &&
                          validationSearch.errors.searchValue &&
                          'is-invalid'
                        }`}
                        value={validationSearch.values.searchValue}
                        onChange={e => {
                          const { value } = e.target;
                          validationSearch.setFieldValue('searchValue', value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={MemberStatusEnum.ACTIVE}>
                          {' '}
                          Active{' '}
                        </option>
                        <option value={MemberStatusEnum.CEASED}>
                          {' '}
                          Ceased{' '}
                        </option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <div className="d-flex w-300">
                      <label htmlFor="searchValue">Search Data</label>
                      {validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validationSearch.errors.searchValue}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type={
                        validationSearch.values.searchKey ===
                        MemberSearchParameterOptionsEnum.FOLIO_NO
                          ? 'number'
                          : 'text'
                      }
                      id="searchValue"
                      name="searchValue"
                      placeholder={
                        validationSearch.values.searchKey ===
                        MemberSearchParameterOptionsEnum.FOLIO_NO
                          ? 'Number'
                          : 'Text'
                      }
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onKeyDown={event => {
                        const keyCode = event.keyCode || event.which;

                        // Check if the key pressed is the decimal point
                        if (keyCode === 190 || keyCode === 110) {
                          event.preventDefault(); // Prevent the decimal point from being entered
                        }
                      }}
                      onChange={e => {
                        let { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    />
                  </div>
                )}

                <div className="d-flex gap-3 align-self-center mt-3">
                  <button
                    type="button"
                    className="btn  fw-bold dangerButton d-flex align-items-center "
                    onClick={() => {
                      if (
                        validationSearch?.values?.searchValue &&
                        validationSearch?.values?.searchKey
                      ) {
                        getAllCompanyMembers({
                          variables: {
                            first: itemsPerPage,
                          },
                        });
                        validationSearch.resetForm();
                      } else {
                        validationSearch.resetForm();
                      }
                    }}
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="btn fw-bold successButton d-flex align-items-center my-auto"
                    onClick={() => {
                      validationSearch.handleSubmit();
                      if (
                        validationSearch.values.searchValue &&
                        validationSearch.values.searchKey
                      ) {
                        getAllCompanyMembers({
                          variables: {
                            first: itemsPerPage,
                            searchKey:
                              validationSearch.values?.searchKey !== 'ALL'
                                ? validationSearch.values?.searchKey
                                : undefined,
                            searchValue:
                              validationSearch.values?.searchValue !== 'Select'
                                ? validationSearch.values?.searchValue
                                : undefined,
                          },
                        });
                      }
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 my-3 align-items-center justify-content-end">
              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_MEMBERS,
                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setExportToolTipIsOpen(true)}
                onMouseLeave={() => setExportToolTipIsOpen(false)}
              >
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center ms-auto"
                  id="exportButton"
                  disabled={
                    loadingExportDataOfCompanyMembers ||
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_MEMBERS,
                        PermissionCategoryEnum.GENERATE_DOCUMENTS
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                  }
                  onClick={() => {
                    exportDataOfCompanyMembers()
                      .then((res: any) => {
                        if (res.data.exportCompanyMembers.isSuccessful) {
                          window.open(
                            res.data.exportCompanyMembers.url,
                            '_self'
                          );
                          setTimeout(function () {
                            deleteFileFromOBS({
                              variables: {
                                fileName:
                                  res.data.exportCompanyMembers.fileName,
                              },
                            });
                          }, 5000);
                        } else {
                          dispatch(
                            setToastError(
                              'Some error occured while exporting data in excel file!'
                            )
                          );
                        }
                      })
                      .catch(err => {
                        dispatch(setToastError(err));
                      });
                  }}
                >
                  Export
                  {loadingExportDataOfCompanyMembers && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                </button>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_MEMBERS,
                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={exportToolTipIsOpen}
                    target="exportButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>
              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_MEMBERS,
                      PermissionCategoryEnum.EDIT
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setExportToolTipIsOpen(true)}
                onMouseLeave={() => setExportToolTipIsOpen(false)}
              >
                <label
                  className={`btn primaryDismissButton d-flex align-items-center ${
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_MEMBERS,
                        PermissionCategoryEnum.EDIT
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                      ? 'icon-disabled'
                      : ''
                  }`}
                  id="importButton"
                >
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChangeCapture={onFileChangeCapture}
                  />
                  Import
                  {loadingCreateCompanyMemberExcelFile && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                </label>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_MEMBERS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={importToolTipIsOpen}
                    target="importButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>
              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_MEMBERS,
                      PermissionCategoryEnum.EDIT
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setAddToolTipIsOpen(true)}
                onMouseLeave={() => setAddToolTipIsOpen(false)}
              >
                <button
                  type="button"
                  className="btn btn-primary primaryButton fw-bold d-flex align-items-center"
                  id="addButton"
                  disabled={
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_MEMBERS,
                        PermissionCategoryEnum.EDIT
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                  }
                  onClick={() => {
                    dispatch(setMemberViewScreen(false));
                    createDraftCompanyMember({
                      variables: {
                        companyId: companyID,
                      },
                    })
                      .then((res: any) => {
                        if (
                          res.data.draft_createCompanyMemberDraft
                            .draftCompanyMember
                        ) {
                          dispatch(
                            setSelectedMemberId(
                              res.data.draft_createCompanyMemberDraft
                                .draftCompanyMember.memberId
                            )
                          );
                          navigate(
                            '/companyMembers/primaryShareHolderInformation'
                          );
                        }
                      })
                      .catch(err => {
                        dispatch(setToastError(err));
                      });
                  }}
                >
                  <i className="bx bx-plus"></i>
                  Add
                </button>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_MEMBERS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={addToolTipIsOpen}
                    target="addButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>
            </div>
            <div id="mainComponent">
              <div className="w-100">
                <ul className="nav nav-tabs mb-3">
                  <li
                    className={`nav-item ${actualMembers ? 'active' : ''}`}
                    onClick={() => {
                      setActualMembers(true);
                    }}
                  >
                    <p className="nav-link">Saved</p>
                  </li>
                  <li
                    className={`nav-item ${!actualMembers ? 'active' : ''}`}
                    onClick={() => {
                      setActualMembers(false);
                    }}
                  >
                    <p className="nav-link">Draft</p>
                  </li>
                </ul>
                {actualMembers ? (
                  <>
                    {loadingGetAllCompanyMembers ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-75">
                        <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                      </div>
                    ) : (
                      <>
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>Folio Number</th>
                                <th>Member Category</th>
                                <th>Member Type</th>
                                <th>Member Status</th>
                                <th>Date of Membership</th>
                                <th>City</th>
                                <th>Contact</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {dataGetAllCompanyMembers?.getAllCompanyMembers?.companyMemberConnection?.edges?.map(
                                (val: any, index: number) => (
                                  <tr key={`${index}`}>
                                    <td>
                                      <div className="badge custom-badge-primary">
                                        {val.node.folioNo
                                          ? val.node.folioNo
                                          : 'N/A'}
                                      </div>
                                    </td>
                                    <td>
                                      {val.node.category
                                        ? GetCompanyMemberCategory(
                                            val.node.category
                                          )
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.type
                                        ? GetCompanyMemberType(val.node.type)
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.status
                                        ? GetCompanyMemberStatus(
                                            val.node.status
                                          )
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      <div className="badge custom-badge-primary">
                                        {val.node.dateOfMembership
                                          ? moment(
                                              val.node.dateOfMembership
                                            ).format('MMM DD, yyyy')
                                          : 'N/A'}
                                      </div>
                                    </td>
                                    <td>
                                      {val.node.city ? val.node.city : 'N/A'}
                                    </td>
                                    <td>
                                      <div className="badge custom-badge-success">
                                        {val.node.phone
                                          ? val.node.phone
                                          : 'N/A'}
                                      </div>
                                    </td>
                                    <td className="editColumn mt-0 gap-1">
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setViewToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setViewToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bx-show-alt text-primary ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.VIEW
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="viewIcon"
                                          onClick={() => {
                                            dispatch(
                                              setSelectedMemberId(
                                                val.node.memberId
                                              )
                                            );
                                            dispatch(setMemberViewScreen(true));
                                            navigate(
                                              '/companyMembers/primaryShareHolderInformation'
                                            );
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={viewToolTipIsOpen}
                                            target="viewIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setEditToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setEditToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bxs-edit text-primary ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="editIcon"
                                          onClick={() => {
                                            dispatch(
                                              setMemberViewScreen(false)
                                            );
                                            dispatch(
                                              setSelectedMemberId(
                                                val.node.memberId
                                              )
                                            );
                                            navigate(
                                              '/companyMembers/primaryShareHolderInformation'
                                            );
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={editToolTipIsOpen}
                                            target="editIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setDeleteToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setDeleteToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bxs-trash-alt text-danger ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.DELETE
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="deleteIcon"
                                          onClick={() => {
                                            dispatch(
                                              setMemberViewScreen(false)
                                            );
                                            ConfirmationDialogue.fire({
                                              title: 'Are you sure?',
                                              text: "You won't be able to revert this!",
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonText: 'Delete',
                                              cancelButtonText: 'Cancel',
                                              reverseButtons: true,
                                            }).then(result => {
                                              if (result.isConfirmed) {
                                                deleteCompanyMember({
                                                  variables: {
                                                    id: val.node.memberId,
                                                  },
                                                })
                                                  .then((res: any) => {
                                                    if (
                                                      res.data
                                                        .deleteCompanyMember
                                                        ?.count
                                                    ) {
                                                      dispatch(
                                                        setToastSuccess(
                                                          'Company Member Deleted!'
                                                        )
                                                      );
                                                      getAllCompanyMembers({
                                                        variables: {
                                                          first: itemsPerPage,
                                                          searchKey:
                                                            validationSearch
                                                              .values
                                                              ?.searchKey !==
                                                            'ALL'
                                                              ? validationSearch
                                                                  .values
                                                                  ?.searchKey
                                                              : undefined,
                                                          searchValue:
                                                            validationSearch
                                                              .values
                                                              ?.searchValue !==
                                                            'Select'
                                                              ? validationSearch
                                                                  .values
                                                                  ?.searchValue
                                                              : undefined,
                                                        },
                                                      });
                                                    }
                                                  })
                                                  .catch(err => {
                                                    dispatch(
                                                      setToastError(err)
                                                    );
                                                  });
                                              }
                                            });
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={deleteToolTipIsOpen}
                                            target="deleteIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                          {(dataGetAllCompanyMembers?.getAllCompanyMembers
                            ?.companyMemberConnection?.edges?.length === 0 ||
                            dataGetAllCompanyMembers?.getAllCompanyMembers
                              ?.companyMemberConnection?.edges ===
                              undefined) && (
                            <div className="addDiv">
                              <h6 className="m-3">No records Found</h6>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                          <p className="m-0 text-info">Rows Per Page:</p>
                          <select
                            className="form-control button-danger text-info width-5vw"
                            value={itemsPerPage}
                            onChange={e => {
                              const { value } = e.target;
                              setItemsPerPage(Number(value));
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <i
                            className={
                              dataGetAllCompanyMembers?.getAllCompanyMembers
                                ?.companyMemberConnection?.pageInfo
                                ?.hasPreviousPage
                                ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                                : 'bx bx-caret-left bx-sm ms-auto'
                            }
                            onClick={() => {
                              if (
                                companyID &&
                                dataGetAllCompanyMembers?.getAllCompanyMembers
                                  ?.companyMemberConnection?.pageInfo
                                  ?.hasPreviousPage
                              ) {
                                getAllCompanyMembers({
                                  variables: {
                                    searchKey:
                                      validationSearch.values?.searchKey !==
                                      'ALL'
                                        ? validationSearch.values?.searchKey
                                        : undefined,
                                    searchValue:
                                      validationSearch.values?.searchValue !==
                                      'Select'
                                        ? validationSearch.values?.searchValue
                                        : undefined,
                                    last: itemsPerPage,
                                    before:
                                      dataGetAllCompanyMembers
                                        ?.getAllCompanyMembers
                                        ?.companyMemberConnection?.pageInfo
                                        ?.startCursor,
                                  },
                                });
                              }
                            }}
                          />

                          <i
                            className={
                              dataGetAllCompanyMembers?.getAllCompanyMembers
                                ?.companyMemberConnection?.pageInfo?.hasNextPage
                                ? 'bx bx-caret-right bx-sm color-primary '
                                : 'bx bx-caret-right bx-sm'
                            }
                            onClick={() => {
                              if (
                                companyID &&
                                dataGetAllCompanyMembers?.getAllCompanyMembers
                                  ?.companyMemberConnection?.pageInfo
                                  ?.hasNextPage
                              ) {
                                getAllCompanyMembers({
                                  variables: {
                                    searchKey:
                                      validationSearch.values?.searchKey !==
                                      'ALL'
                                        ? validationSearch.values?.searchKey
                                        : undefined,
                                    searchValue:
                                      validationSearch.values?.searchValue !==
                                      'Select'
                                        ? validationSearch.values?.searchValue
                                        : undefined,
                                    after:
                                      dataGetAllCompanyMembers
                                        ?.getAllCompanyMembers
                                        ?.companyMemberConnection?.pageInfo
                                        ?.endCursor,
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {loadingGetAllCompanyMembers ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-75">
                        <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                      </div>
                    ) : (
                      <>
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>Folio Number</th>
                                <th>Member Category</th>
                                <th>Member Type</th>
                                <th>Member Status</th>
                                <th>Date of Membership</th>
                                <th>City</th>
                                <th>Contact</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataGetAllCompanyMembers?.getAllCompanyMembers?.draftCompanyMemberConnection?.edges?.map(
                                (val: any, index: number) => (
                                  <tr key={`${index}`}>
                                    <td>
                                      <div className="badge custom-badge-primary">
                                        {val.node.folioNo
                                          ? val.node.folioNo
                                          : 'N/A'}
                                      </div>
                                    </td>
                                    <td>
                                      {val.node.category
                                        ? GetCompanyMemberCategory(
                                            val.node.category
                                          )
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.type
                                        ? GetCompanyMemberType(val.node.type)
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.status
                                        ? GetCompanyMemberStatus(
                                            val.node.status
                                          )
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.dateOfMembership
                                        ? moment(
                                            val.node.dateOfMembership
                                          ).format('MMM DD, yyyy')
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.city ? val.node.city : 'N/A'}
                                    </td>
                                    <td>
                                      {val.node.phone ? val.node.phone : 'N/A'}
                                    </td>
                                    <td className="editColumn mt-0 gap-1">
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setEditToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setEditToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bx-show-alt text-primary ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.VIEW
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="viewIcon"
                                          onClick={() => {
                                            dispatch(
                                              setSelectedMemberId(
                                                val.node.memberId
                                              )
                                            );
                                            dispatch(setMemberViewScreen(true));
                                            navigate(
                                              '/companyMembers/primaryShareHolderInformation'
                                            );
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={editToolTipIsOpen}
                                            target="viewIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setEditToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setEditToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bxs-edit text-primary ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="editIcon"
                                          onClick={() => {
                                            dispatch(
                                              setMemberViewScreen(false)
                                            );
                                            dispatch(
                                              setSelectedMemberId(
                                                val.node.memberId
                                              )
                                            );
                                            navigate(
                                              '/companyMembers/primaryShareHolderInformation'
                                            );
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={editToolTipIsOpen}
                                            target="editIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_MEMBERS,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setDeleteToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setDeleteToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bxs-trash-alt text-danger ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMPANY_MEMBERS,
                                                PermissionCategoryEnum.DELETE
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="deleteIcon"
                                          onClick={() => {
                                            dispatch(
                                              setMemberViewScreen(false)
                                            );
                                            ConfirmationDialogue.fire({
                                              title: 'Are you sure?',
                                              text: "You won't be able to revert this!",
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonText: 'Delete',
                                              cancelButtonText: 'Cancel',
                                              reverseButtons: true,
                                            }).then(result => {
                                              if (result.isConfirmed) {
                                                draftDeleteCompanyMember({
                                                  variables: {
                                                    id: val.node.memberId,
                                                  },
                                                })
                                                  .then((res: any) => {
                                                    if (
                                                      res.data
                                                        .draft_deleteCompanyMemberDraft
                                                        .count
                                                    ) {
                                                      dispatch(
                                                        setToastSuccess(
                                                          'Company Member Draft Deleted!'
                                                        )
                                                      );
                                                      getAllCompanyMembers({
                                                        variables: {
                                                          first: itemsPerPage,
                                                          searchKey:
                                                            validationSearch
                                                              .values
                                                              ?.searchKey !==
                                                            'ALL'
                                                              ? validationSearch
                                                                  .values
                                                                  ?.searchKey
                                                              : undefined,
                                                          searchValue:
                                                            validationSearch
                                                              .values
                                                              ?.searchValue !==
                                                            'Select'
                                                              ? validationSearch
                                                                  .values
                                                                  ?.searchValue
                                                              : undefined,
                                                        },
                                                      });
                                                    }
                                                  })
                                                  .catch(err => {
                                                    dispatch(
                                                      setToastError(err)
                                                    );
                                                  });
                                              }
                                            });
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_MEMBERS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={deleteToolTipIsOpen}
                                            target="deleteIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                          {(dataGetAllCompanyMembers?.getAllCompanyMembers
                            ?.draftCompanyMemberConnection?.edges?.length ===
                            0 ||
                            dataGetAllCompanyMembers?.getAllCompanyMembers
                              ?.draftCompanyMemberConnection?.edges ===
                              undefined) && (
                            <div className="addDiv">
                              <h6 className="">No records Found</h6>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                          <p className="m-0 text-info">Rows Per Page:</p>
                          <select
                            className="form-control button-danger text-info width-5vw"
                            value={itemsPerPage}
                            onChange={e => {
                              const { value } = e.target;
                              setItemsPerPage(Number(value));
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <i
                            className={
                              dataGetAllCompanyMembers?.getAllCompanyMembers
                                ?.draftCompanyMemberConnection?.pageInfo
                                ?.hasPreviousPage
                                ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                                : 'bx bx-caret-left bx-sm ms-auto'
                            }
                            onClick={() => {
                              if (
                                companyID &&
                                dataGetAllCompanyMembers?.getAllCompanyMembers
                                  ?.draftCompanyMemberConnection?.pageInfo
                                  ?.hasPreviousPage
                              ) {
                                getAllCompanyMembers({
                                  variables: {
                                    last: itemsPerPage,
                                    searchKey:
                                      validationSearch.values?.searchKey !==
                                      'ALL'
                                        ? validationSearch.values?.searchKey
                                        : undefined,
                                    searchValue:
                                      validationSearch.values?.searchValue !==
                                      'Select'
                                        ? validationSearch.values?.searchValue
                                        : undefined,
                                    before:
                                      dataGetAllCompanyMembers
                                        ?.getAllCompanyMembers
                                        ?.draftCompanyMemberConnection?.pageInfo
                                        ?.startCursor,
                                  },
                                });
                              }
                            }}
                          />

                          <i
                            className={
                              dataGetAllCompanyMembers?.getAllCompanyMembers
                                ?.draftCompanyMemberConnection?.pageInfo
                                ?.hasNextPage
                                ? 'bx bx-caret-right bx-sm color-primary '
                                : 'bx bx-caret-right bx-sm'
                            }
                            onClick={() => {
                              if (
                                companyID &&
                                dataGetAllCompanyMembers?.getAllCompanyMembers
                                  ?.draftCompanyMemberConnection?.pageInfo
                                  ?.hasNextPage
                              ) {
                                getAllCompanyMembers({
                                  variables: {
                                    searchKey:
                                      validationSearch.values?.searchKey !==
                                      'ALL'
                                        ? validationSearch.values?.searchKey
                                        : undefined,
                                    searchValue:
                                      validationSearch.values?.searchValue !==
                                      'Select'
                                        ? validationSearch.values?.searchValue
                                        : undefined,
                                    after:
                                      dataGetAllCompanyMembers
                                        ?.getAllCompanyMembers
                                        ?.draftCompanyMemberConnection?.pageInfo
                                        ?.endCursor,
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
