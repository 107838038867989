import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import moment from 'moment';
import {
  setModalDataRedux,
  setModalVisibility,
  setSelectedShareId,
  setSelectedShareIdForTransaction,
  setSelectedShareTransferId,
  setShareViewScreen,
  setTransmission,
  setTransmissionMode,
  setTransmissionReason,
  setType,
} from '~/Store/reducers/share';
import {
  DELETE_SHARE,
  GENERATE_SHARE_CERTIFICATE,
  GET_ALL_SHARES_TRANSACTIONS_OF_COMPANY_MEMBER,
} from '~/Store/graphQL/Shares';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  ShareSearchParameterOptionsEnum,
  ShareTransactionTypeEnum,
  ShareTypeEnum,
  UserTypeEnum,
} from '~/commons/enums';
import ShareTransferImg from '/images/shareTransfer.png';
import ShareTransmissionImg from '/images/shareTransmission.png';
import ShareSplitImg from '/images/shareSplit.png';
import ShareDuplicateImg from '/images/shareDuplicate.png';
import GetShareTransactionType from '~/helpers/Share/GetShareTransactionType';
import GetShareType from '~/helpers/Share/GetShareType';
import { Tooltip } from 'reactstrap';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const ShareTransactions: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedFolioNumber } = useAppSelector(state => state.shareReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [dropDownRow, setDropDownRow] = useState(-1);

  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  const [transferToolTipIsOpen, setTransferToolTipIsOpen] = useState(false);
  const [transmissionToolTipIsOpen, setTransmissionToolTipIsOpen] =
    useState(false);
  const [splitToolTipIsOpen, setSplitToolTipIsOpen] = useState(false);
  const [duplicateToolTipIsOpen, setDuplicateToolTipIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Shares',
        },
      ])
    );
  }, []);
  // Get all shares query
  const [
    getAllShareTransactionsOfCompanyMember,
    {
      loading: loadingGetAllShareTransactionsOfCompanyMember,
      error: errorGetAllShareTransactionsOfCompanyMember,
      data: dataGetAllShareTransactionsOfCompanyMember,
    },
  ] = useLazyQuery<any>(GET_ALL_SHARES_TRANSACTIONS_OF_COMPANY_MEMBER, {
    variables: {
      companyId: companyID,
      folioNo: selectedFolioNumber,
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteShare,
    {
      loading: loadingDeleteShare,
      error: errorDeleteShare,
      data: dataDeleteShare,
    },
  ] = useMutation<any>(DELETE_SHARE);
  const [
    generateShareCertificate,
    {
      loading: loadingGenerateShareCertificate,
      error: errorGenerateShareCertificate,
      data: dataGenerateShareCertificate,
    },
  ] = useLazyQuery<any>(GENERATE_SHARE_CERTIFICATE);
  useEffect(() => {
    if (companyID) {
      getAllShareTransactionsOfCompanyMember();
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllShareTransactionsOfCompanyMember) {
      dispatch(setToastError(errorGetAllShareTransactionsOfCompanyMember));
    }
  }, [errorGetAllShareTransactionsOfCompanyMember]);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef?.current?.toString() !== event?.target?.toString()
      ) {
        setDropDownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Share Transactions</b>
              </h5>
            </div>
          </div>
          <div className="d-flex row mt-2 mb-4">
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="shareType">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option
                      value={ShareSearchParameterOptionsEnum.TRANSACTION_TYPE}
                    >
                      {' '}
                      Transaction Type{' '}
                    </option>
                    <option value={ShareSearchParameterOptionsEnum.SHARE_TYPE}>
                      {' '}
                      Share Type{' '}
                    </option>
                    <option
                      value={
                        ShareSearchParameterOptionsEnum.SHARE_CERTIFICATE_NO
                      }
                    >
                      {' '}
                      Certificate Number{' '}
                    </option>
                    {/* <option value={ShareSearchParameterOptionsEnum.DISTINCTIVE_NO_FROM}> Distinctive Number From </option> */}
                    {/* <option value={ShareSearchParameterOptionsEnum.DISTINCTIVE_NO_TO}> Distinctive Number To </option> */}
                    {/* <option value={ShareSearchParameterOptionsEnum.NO_OF_SHARES}> Number of Shares </option> */}
                    {/* <option value={ShareSearchParameterOptionsEnum.PREVIOUS_SHARE_CERTIFICATE_NO}> Old Certificate Number </option> */}
                  </select>
                </div>
              </div>
              {validationSearch?.values?.searchKey ===
              ShareSearchParameterOptionsEnum.TRANSACTION_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={ShareTransactionTypeEnum.ALLOTMENT}>
                        {' '}
                        Allotment{' '}
                      </option>
                      <option value={ShareTransactionTypeEnum.TRANSFER}>
                        {' '}
                        Transfer{' '}
                      </option>
                      <option value={ShareTransactionTypeEnum.SPLIT}>
                        {' '}
                        Split{' '}
                      </option>
                      <option value={ShareTransactionTypeEnum.DUPLICATE}>
                        {' '}
                        Duplicate{' '}
                      </option>
                      <option value={ShareTransactionTypeEnum.TRANSMISSION}>
                        {' '}
                        Transmission{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch?.values?.searchKey ===
                ShareSearchParameterOptionsEnum.SHARE_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={ShareTypeEnum.PREFERENCE_SHARE}>
                        {' '}
                        Preference Share{' '}
                      </option>
                      <option value={ShareTypeEnum.ORDINARY_SHARE}>
                        {' '}
                        Ordinary Share{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="number"
                    id="searchValue"
                    name="searchValue"
                    placeholder="Number"
                    className={`form-control ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    onKeyDown={evt =>
                      ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}

              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn fw-bold dangerButton my-auto mx-3"
                  onClick={() => {
                    if (validationSearch?.values?.searchValue) {
                      getAllShareTransactionsOfCompanyMember({
                        variables: {
                          first: itemsPerPage,
                          shareType: undefined,
                        },
                      });
                    }
                    validationSearch.resetForm();
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton my-auto"
                  disabled={loadingGetAllShareTransactionsOfCompanyMember}
                  onClick={() => {
                    validationSearch.handleSubmit();
                    if (
                      validationSearch?.values?.searchKey &&
                      validationSearch?.values?.searchValue
                    ) {
                      getAllShareTransactionsOfCompanyMember({
                        variables: {
                          first: itemsPerPage,
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.SHARE_MANAGEMENT,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className={`btn btn-primary primary-btn-new d-flex align-items-center fw-bold`}
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.SHARE_MANAGEMENT,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setShareViewScreen(false));
                dispatch(setSelectedShareId(null));
                navigate('/shares/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Allotment of share
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.SHARE_MANAGEMENT,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllShareTransactionsOfCompanyMember ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="col-1">Transaction Type</th>
                            <th className="col-1">Transaction Date</th>
                            <th className="col-1">Share Type</th>
                            <th className="col-1">Certificate No</th>
                            <th className="col-1">Distinctive No: From</th>
                            <th className="col-1">Distinctive No: To</th>
                            <th className="col-1">No of Shares</th>
                            <th className="col-1">Old Certificate No</th>
                            <th className="col-1">Issue Date</th>
                            <th className="col-1">Folio No</th>
                            <th className="col-1">
                              Allotment/ Transfer/Split No
                            </th>
                            <th className="col-1">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllShareTransactionsOfCompanyMember?.findMemberShareTransactions?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  {val?.node?.transactionType
                                    ? GetShareTransactionType(
                                        val?.node?.transactionType
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.createdAt
                                    ? moment(val.node.createdAt).format(
                                        'DD-MM-YYYY'
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.shareType
                                    ? GetShareType(val.node.shareType)
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {val.node.shareCertificateNo
                                      ? String(
                                          val.node.shareCertificateNo
                                        ).padStart(3, '00')
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.DistinctiveNoFrom
                                      ? String(
                                          val.node.DistinctiveNoFrom
                                        ).padStart(3, '00')
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-yellow">
                                    {val.node.DistinctiveNoTo
                                      ? String(
                                          val.node.DistinctiveNoTo
                                        ).padStart(3, '00')
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.noOfShares
                                    ? val.node.noOfShares
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.oldShareCertificateNo ? (
                                    <div className="badge custom-badge-primary">
                                      {String(
                                        val.node.oldShareCertificateNo
                                      ).padStart(3, '00')}
                                    </div>
                                  ) : (
                                    'N/A'
                                  )}
                                </td>
                                <td>
                                  {val.node.issueDate
                                    ? moment(val.node.issueDate).format(
                                        'DD-MM-YYYY'
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.folioNo
                                      ? String(val.node.folioNo).padStart(
                                          3,
                                          '00'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.allotmentSplitTransferNo
                                    ? String(
                                        val.node.allotmentSplitTransferNo
                                      ).padStart(3, '00')
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    {(val?.node?.status ===
                                      ShareTransactionTypeEnum.TRANSFER &&
                                      val?.node?.count !==
                                        val?.node?.allotmentSplitTransferNo) ||
                                    (val?.node?.status ===
                                      ShareTransactionTypeEnum.TRANSFER &&
                                      val?.node?.transactionType ===
                                        ShareTransactionTypeEnum.ALLOTMENT) ||
                                    val?.node?.status ===
                                      ShareTransactionTypeEnum.TRANSMISSION ||
                                    val?.node?.status ===
                                      ShareTransactionTypeEnum.SPLIT ||
                                    val?.node?.status ===
                                      ShareTransactionTypeEnum.DUPLICATE ? null : (
                                      <>
                                        <div
                                          className={`${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setTransferToolTipIsOpen(true)
                                          }
                                          onMouseLeave={() =>
                                            setTransferToolTipIsOpen(false)
                                          }
                                        >
                                          <img
                                            id="transferShareImg"
                                            className={`cursor-pointer height-22 ${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.EDIT
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'icon-disabled'
                                                : ''
                                            }`}
                                            src={ShareTransferImg}
                                            onClick={() => {
                                              dispatch(
                                                setShareViewScreen(false)
                                              );
                                              dispatch(
                                                setSelectedShareId(null)
                                              );
                                              dispatch(
                                                setSelectedShareIdForTransaction(
                                                  val?.node?.shareId
                                                )
                                              );
                                              navigate('/shares/add/transfer');
                                            }}
                                          ></img>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={transferToolTipIsOpen}
                                              target="transferShareImg"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              placement="top"
                                              isOpen={transferToolTipIsOpen}
                                              target="transferShareImg"
                                            >
                                              Transfer Share
                                            </Tooltip>
                                          )}
                                        </div>

                                        {val?.node?.transactionType !==
                                        ShareTransactionTypeEnum.TRANSMISSION ? (
                                          <div
                                            className={`${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.EDIT
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'cursor-not-allowed'
                                                : ''
                                            }`}
                                            onMouseEnter={() =>
                                              setTransmissionToolTipIsOpen(true)
                                            }
                                            onMouseLeave={() =>
                                              setTransmissionToolTipIsOpen(
                                                false
                                              )
                                            }
                                          >
                                            <img
                                              id="transmissionShareImg"
                                              className={`cursor-pointer height-22 ${
                                                !(
                                                  GetPermissionsOfUser(
                                                    selectedUserPermissions,
                                                    ModuleEnum.SHARE_MANAGEMENT,
                                                    PermissionCategoryEnum.EDIT
                                                  ) ||
                                                  userType ===
                                                    UserTypeEnum.COMPANY_ADMIN
                                                )
                                                  ? 'icon-disabled'
                                                  : ''
                                              }`}
                                              src={ShareTransmissionImg}
                                              onClick={() => {
                                                dispatch(setType(null));
                                                dispatch(
                                                  setTransmissionReason(null)
                                                );
                                                dispatch(
                                                  setTransmissionMode(null)
                                                );
                                                dispatch(setTransmission(null));
                                                dispatch(
                                                  setModalVisibility(false)
                                                );
                                                dispatch(setModalDataRedux([]));
                                                dispatch(
                                                  setShareViewScreen(false)
                                                );
                                                dispatch(
                                                  setSelectedShareId(null)
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val?.node?.shareId
                                                  )
                                                );
                                                navigate(
                                                  '/shares/add/transmission'
                                                );
                                              }}
                                            ></img>
                                            {!(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            ) ? (
                                              <Tooltip
                                                placement="top"
                                                isOpen={
                                                  transmissionToolTipIsOpen
                                                }
                                                target="transmissionShareImg"
                                              >
                                                You don't have permission for
                                                this module
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                placement="top"
                                                isOpen={
                                                  transmissionToolTipIsOpen
                                                }
                                                target="transmissionShareImg"
                                              >
                                                Transmit Share
                                              </Tooltip>
                                            )}
                                          </div>
                                        ) : null}
                                        {val?.node?.transactionType !==
                                        ShareTransactionTypeEnum.SPLIT ? (
                                          <div
                                            className={`${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.EDIT
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'cursor-not-allowed'
                                                : ''
                                            }`}
                                            onMouseEnter={() =>
                                              setSplitToolTipIsOpen(true)
                                            }
                                            onMouseLeave={() =>
                                              setSplitToolTipIsOpen(false)
                                            }
                                          >
                                            <img
                                              id="splitShareImg"
                                              className={`cursor-pointer height-22 ${
                                                !(
                                                  GetPermissionsOfUser(
                                                    selectedUserPermissions,
                                                    ModuleEnum.SHARE_MANAGEMENT,
                                                    PermissionCategoryEnum.EDIT
                                                  ) ||
                                                  userType ===
                                                    UserTypeEnum.COMPANY_ADMIN
                                                )
                                                  ? 'icon-disabled'
                                                  : ''
                                              }`}
                                              src={ShareSplitImg}
                                              onClick={() => {
                                                dispatch(
                                                  setShareViewScreen(false)
                                                );
                                                dispatch(
                                                  setSelectedShareId(null)
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val?.node?.shareId
                                                  )
                                                );
                                                navigate('/shares/add/split');
                                              }}
                                            ></img>
                                            {!(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            ) ? (
                                              <Tooltip
                                                placement="top"
                                                isOpen={splitToolTipIsOpen}
                                                target="splitShareImg"
                                              >
                                                You don't have permission for
                                                this module
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                placement="top"
                                                isOpen={splitToolTipIsOpen}
                                                target="splitShareImg"
                                              >
                                                Split Share
                                              </Tooltip>
                                            )}
                                          </div>
                                        ) : null}
                                        <div
                                          className={`${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setDuplicateToolTipIsOpen(true)
                                          }
                                          onMouseLeave={() =>
                                            setDuplicateToolTipIsOpen(false)
                                          }
                                        >
                                          <img
                                            id="duplicateShareImg"
                                            className={`cursor-pointer height-22 ${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.EDIT
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'icon-disabled'
                                                : ''
                                            }`}
                                            src={ShareDuplicateImg}
                                            onClick={() => {
                                              dispatch(
                                                setShareViewScreen(false)
                                              );
                                              dispatch(
                                                setSelectedShareId(null)
                                              );
                                              dispatch(
                                                setSelectedShareIdForTransaction(
                                                  val?.node?.shareId
                                                )
                                              );
                                              navigate('/shares/add/duplicate');
                                            }}
                                          ></img>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={duplicateToolTipIsOpen}
                                              target="duplicateShareImg"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              placement="top"
                                              isOpen={duplicateToolTipIsOpen}
                                              target="duplicateShareImg"
                                            >
                                              Duplicate Share
                                            </Tooltip>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {val?.node?.transactionType !==
                                      ShareTransactionTypeEnum.TRANSMISSION &&
                                    val?.node?.transactionType !==
                                      ShareTransactionTypeEnum.SPLIT &&
                                    val?.node?.transactionType !==
                                      ShareTransactionTypeEnum.DUPLICATE ? (
                                      <>
                                        <div
                                          className={`${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.VIEW
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setViewToolTipIsOpen(true)
                                          }
                                          onMouseLeave={() =>
                                            setViewToolTipIsOpen(false)
                                          }
                                        >
                                          <i
                                            className={`bx bx-show-alt bx-sm text-primary ${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.VIEW
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'icon-disabled'
                                                : ''
                                            }`}
                                            id="viewIcon"
                                            onClick={() => {
                                              dispatch(
                                                setShareViewScreen(true)
                                              );
                                              if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.ALLOTMENT
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareId
                                                  )
                                                );
                                                navigate('/shares/add');
                                              } else if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.TRANSFER
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareId
                                                  )
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val.node.shareTransactionId
                                                  )
                                                );
                                                navigate(
                                                  '/shares/add/transfer'
                                                );
                                              } else if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.DUPLICATE
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareTransactionId
                                                  )
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val.node.shareId
                                                  )
                                                );
                                                navigate(
                                                  '/shares/add/duplicate'
                                                );
                                              }
                                            }}
                                          />
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={viewToolTipIsOpen}
                                              target="viewIcon"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                        <div
                                          className={`${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setEditToolTipIsOpen(true)
                                          }
                                          onMouseLeave={() =>
                                            setEditToolTipIsOpen(false)
                                          }
                                        >
                                          <i
                                            className={`bx bxs-pencil text-success ${
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.SHARE_MANAGEMENT,
                                                  PermissionCategoryEnum.EDIT
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                                ? 'icon-disabled'
                                                : ''
                                            }`}
                                            id="editIcon"
                                            onClick={() => {
                                              dispatch(
                                                setShareViewScreen(false)
                                              );
                                              dispatch(
                                                setSelectedShareIdForTransaction(
                                                  null
                                                )
                                              );
                                              if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.ALLOTMENT
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareId
                                                  )
                                                );
                                                navigate('/shares/add');
                                              } else if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.TRANSFER
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareId
                                                  )
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val.node.id
                                                  )
                                                );
                                                dispatch(
                                                  setSelectedShareTransferId(
                                                    val.node.shareTransactionId
                                                  )
                                                );
                                                navigate(
                                                  '/shares/add/transfer'
                                                );
                                              } else if (
                                                val.node.transactionType ===
                                                ShareTransactionTypeEnum.DUPLICATE
                                              ) {
                                                dispatch(
                                                  setSelectedShareId(
                                                    val.node.shareTransactionId
                                                  )
                                                );
                                                dispatch(
                                                  setSelectedShareIdForTransaction(
                                                    val.node.shareId
                                                  )
                                                );
                                                navigate(
                                                  '/shares/add/duplicate'
                                                );
                                              }
                                            }}
                                          />
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={editToolTipIsOpen}
                                              target="editIcon"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </>
                                    ) : null}
                                    {val?.node?.transactionType ===
                                    ShareTransactionTypeEnum.DUPLICATE ? (
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setViewToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setViewToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bx-show-alt bx-sm text-primary ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.VIEW
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="viewIcon"
                                          onClick={() => {
                                            dispatch(setShareViewScreen(true));
                                            if (
                                              val.node.transactionType ===
                                              ShareTransactionTypeEnum.ALLOTMENT
                                            ) {
                                              dispatch(
                                                setSelectedShareId(
                                                  val.node.shareId
                                                )
                                              );
                                              navigate('/shares/add');
                                            } else if (
                                              val.node.transactionType ===
                                              ShareTransactionTypeEnum.TRANSFER
                                            ) {
                                              dispatch(
                                                setSelectedShareId(
                                                  val.node.shareId
                                                )
                                              );
                                              dispatch(
                                                setSelectedShareIdForTransaction(
                                                  val.node.shareTransactionId
                                                )
                                              );
                                              navigate('/shares/add/transfer');
                                            } else if (
                                              val.node.transactionType ===
                                              ShareTransactionTypeEnum.DUPLICATE
                                            ) {
                                              dispatch(
                                                setSelectedShareId(
                                                  val.node.shareTransactionId
                                                )
                                              );
                                              dispatch(
                                                setSelectedShareIdForTransaction(
                                                  val.node.shareId
                                                )
                                              );
                                              navigate('/shares/add/duplicate');
                                            }
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SHARE_MANAGEMENT,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={viewToolTipIsOpen}
                                            target="viewIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <div
                                        className={`${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.SHARE_MANAGEMENT,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setDeleteToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setDeleteToolTipIsOpen(false)
                                        }
                                      >
                                        <i
                                          className={`bx bxs-trash-alt text-danger ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.SHARE_MANAGEMENT,
                                                PermissionCategoryEnum.DELETE
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          id="deleteIcon"
                                          onClick={() => {
                                            dispatch(setShareViewScreen(false));
                                            ConfirmationDialogue.fire({
                                              title: 'Are you sure?',
                                              text: "You won't be able to revert this!",
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonText: 'Delete',
                                              cancelButtonText: 'Cancel',
                                              reverseButtons: true,
                                            }).then(result => {
                                              if (result.isConfirmed) {
                                                if (
                                                  val.node.transactionType ===
                                                  ShareTransactionTypeEnum.DUPLICATE
                                                ) {
                                                  deleteShare({
                                                    variables: {
                                                      id: val.node.shareId,
                                                      transactionType:
                                                        val.node
                                                          .transactionType,
                                                      transactionId:
                                                        val.node
                                                          .shareTransactionId,
                                                      transferNo:
                                                        val.node
                                                          .allotmentSplitTransferNo,
                                                    },
                                                  })
                                                    .then((res: any) => {
                                                      if (
                                                        res.data.deleteShare
                                                          .isDeleted
                                                      ) {
                                                        dispatch(
                                                          setToastSuccess(
                                                            'Share Deleted!'
                                                          )
                                                        );
                                                        getAllShareTransactionsOfCompanyMember();
                                                      } else if (
                                                        res.data.deleteShare
                                                          .error
                                                      ) {
                                                        dispatch(
                                                          setToastError(
                                                            res.data.deleteShare
                                                              .error
                                                          )
                                                        );
                                                      }
                                                    })
                                                    .catch(err => {
                                                      dispatch(
                                                        setToastError(err)
                                                      );
                                                    });
                                                } else {
                                                  deleteShare({
                                                    variables: {
                                                      id: val.node.shareId,
                                                      transactionType:
                                                        val.node
                                                          .transactionType,
                                                      transactionId:
                                                        val.node.id,
                                                      transferNo:
                                                        val.node
                                                          .allotmentSplitTransferNo,
                                                    },
                                                  })
                                                    .then((res: any) => {
                                                      if (
                                                        res.data.deleteShare
                                                          .isDeleted
                                                      ) {
                                                        dispatch(
                                                          setToastSuccess(
                                                            'Share Deleted!'
                                                          )
                                                        );
                                                        getAllShareTransactionsOfCompanyMember();
                                                      } else if (
                                                        res.data.deleteShare
                                                          .error
                                                      ) {
                                                        dispatch(
                                                          setToastError(
                                                            res.data.deleteShare
                                                              .error
                                                          )
                                                        );
                                                      }
                                                    })
                                                    .catch(err => {
                                                      dispatch(
                                                        setToastError(err)
                                                      );
                                                    });
                                                }
                                              }
                                            });
                                          }}
                                        />
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SHARE_MANAGEMENT,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={deleteToolTipIsOpen}
                                            target="deleteIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    )}

                                    {val?.node?.transactionType ===
                                    ShareTransactionTypeEnum.ALLOTMENT ? (
                                      val?.node?.status ===
                                        ShareTransactionTypeEnum.TRANSMISSION ||
                                      val?.node?.status ===
                                        ShareTransactionTypeEnum.SPLIT ||
                                      val?.node?.status ===
                                        ShareTransactionTypeEnum.DUPLICATE ? null : GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SHARE_MANAGEMENT,
                                          PermissionCategoryEnum.GENERATE_DOCUMENTS
                                        ) ||
                                        userType ===
                                          UserTypeEnum.COMPANY_ADMIN ? (
                                        <div className="dropleft">
                                          <i
                                            id="dropdownMenuButton"
                                            ref={dropdownRef}
                                            className="bx bx-dots-vertical-rounded"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={() => {
                                              setDropDownRow(index);
                                              toggleModal();
                                            }}
                                          ></i>
                                          <ul
                                            className={`dropdown-menu min-width-8 margin-left-190 ${
                                              dropDownVisibility &&
                                              index === dropDownRow &&
                                              'show'
                                            } `}
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            <li className="cursor-pointer">
                                              <div
                                                className="dropdown-item"
                                                onClick={() => {
                                                  generateShareCertificate({
                                                    variables: {
                                                      id: val.node.shareId,
                                                    },
                                                    fetchPolicy: 'no-cache',
                                                  })
                                                    .then(res => {
                                                      if (
                                                        res?.data
                                                          ?.createCertificate
                                                          ?.isGenerated
                                                      ) {
                                                        const uniqueTimestamp =
                                                          Date.now();
                                                        window.open(
                                                          `${res?.data?.createCertificate?.url}?t=${uniqueTimestamp}`,
                                                          'new'
                                                        );
                                                      }
                                                      setDropDownVisibility(
                                                        false
                                                      );
                                                    })
                                                    .catch(err => {
                                                      dispatch(
                                                        setToastError(err)
                                                      );
                                                    });
                                                }}
                                              >
                                                Generate Share Certificate
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      ) : null
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllShareTransactionsOfCompanyMember
                        ?.findMemberShareTransactions?.edges?.length === 0 ||
                        dataGetAllShareTransactionsOfCompanyMember
                          ?.findMemberShareTransactions?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllShareTransactionsOfCompanyMember
                            ?.findMemberShareTransactions?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllShareTransactionsOfCompanyMember
                              ?.findMemberShareTransactions?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllShareTransactionsOfCompanyMember({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllShareTransactionsOfCompanyMember
                                    ?.findMemberShareTransactions?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllShareTransactionsOfCompanyMember
                            ?.findMemberShareTransactions?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllShareTransactionsOfCompanyMember
                              ?.findMemberShareTransactions?.pageInfo
                              ?.hasNextPage
                          ) {
                            getAllShareTransactionsOfCompanyMember({
                              variables: {
                                after:
                                  dataGetAllShareTransactionsOfCompanyMember
                                    ?.findMemberShareTransactions?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
