import { UserTypeEnum } from '../../commons/enums';
const GetUserType = (userType: number) => {
  if (userType === UserTypeEnum.COMPANY_ADMIN) {
    return 'Company Admin';
  } else if (userType === UserTypeEnum.INTERMEDIAL_USER) {
    return 'Intermedial User';
  } else if (userType === UserTypeEnum.SUPER_ADMIN) {
    return 'Super Admin';
  } else if (userType === UserTypeEnum.COMPANY_OFFICER) {
    return 'Company Officer';
  } else if (userType === UserTypeEnum.COMPANY_MEMBER) {
    return 'Company Member';
  }
};

export default GetUserType;
