import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import 'react-multi-email/style.css';
import {
  FIND_SHARE_BY_ID,
  GET_NEXT_SHARE_CERTIFICATE_NO,
  SPLIT_SHARE,
} from '~/Store/graphQL/Shares';
import CalendarImg from '/images/calendar.png';
import {
  setSelectedShareId,
  setSelectedShareIdForTransaction,
} from '~/Store/reducers/share';
import { SplitShareTableDto } from '../types';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { ModuleEnum } from '~/commons/enums';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
export const ShareSplit: FC = () => {
  const { shareView, selectedShareIdForTransaction } = useAppSelector(
    state => state.shareReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [shareData, setShareData] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [tableData, setTableData] = useState<SplitShareTableDto[] | undefined>(
    []
  );
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/shares',
          title: 'Share Management',
        },
        {
          url: location.pathname,
          title: 'Share Split',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting share information
  const [
    findShareById,
    {
      loading: loadingFindShareById,
      error: errorFindShareById,
      data: dataFindShareById,
    },
  ] = useLazyQuery<any>(FIND_SHARE_BY_ID);
  // split share
  const [
    splitShare,
    {
      loading: loadingSplitShare,
      error: errorSplitShare,
      data: dataSplitShare,
    },
  ] = useMutation<any>(SPLIT_SHARE);
  // Get next share certificate number query
  const [
    getNextShareCertificateNo,
    {
      loading: loadingGetNextShareCertificateNo,
      error: errorGetNextShareCertificateNo,
      data: dataGetNextShareCertificateNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_SHARE_CERTIFICATE_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (selectedShareIdForTransaction) {
      findShareById({
        variables: {
          id: selectedShareIdForTransaction,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareIdForTransaction]);
  useEffect(() => {
    if (dataFindShareById) {
      setShareData(dataFindShareById?.findOneShare?.share);
    } else if (errorFindShareById) {
      console.log('errorFindShareById:', errorFindShareById);
    }
  }, [dataFindShareById, errorFindShareById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      dateOfSplit: new Date().toISOString().slice(0, 10),
      dateOfIssue: new Date().toISOString().slice(0, 10),
      certificateNo: shareData?.shareCertificateNo
        ? shareData.shareCertificateNo
        : undefined,
      noOfShares: shareData?.noOfShares ? shareData.noOfShares : undefined,
      distinctiveNoFrom: shareData?.DistinctiveNoFrom
        ? shareData.DistinctiveNoFrom
        : undefined,
      distinctiveNoTo: shareData?.DistinctiveNoTo
        ? shareData?.DistinctiveNoTo
        : '',
    },
    validationSchema: Yup.object({
      dateOfSplit: Yup.string()
        .required('Please select date of split')
        .nullable(),
      dateOfIssue: Yup.string()
        .required('Please select date of issue')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      tableData?.forEach(element => {
        delete element.newCertificateNo;
      });
      splitShare({
        variables: {
          shareId: selectedShareIdForTransaction,
          data: tableData,
        },
      })
        .then((res: any) => {
          if (res?.data?.splitShare?.data) {
            dispatch(setToastSuccess('Share Splitted!'));
            dispatch(setSelectedShareId(null));
            dispatch(setSelectedShareIdForTransaction(null));
            navigate(`/shares`);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });
  return (
    <React.Fragment>
      {loadingFindShareById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 background-white">
          <div className="d-flex justify-content-end background-primary-medium">
            <button
              type="button"
              className="btn dangerButton d-flex align-items-center m-3 fw-bold"
              disabled={loadingSplitShare}
              onMouseDown={() => {
                navigate('/shares');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-bold primaryDismissButton d-flex align-items-center m-3 mx-0"
              disabled={loadingSplitShare}
              onMouseDown={() => {
                navigate('/shares/transactions');
              }}
            >
              Back
            </button>
            {!shareView && (
              <button
                type="button"
                className="btn successButton d-flex align-items-center m-3 fw-bold"
                disabled={loadingSplitShare}
                onMouseDown={() => {
                  validation.handleSubmit();
                }}
              >
                {loadingSplitShare && (
                  <i className="bx bx-loader-circle bx-spin me-1" />
                )}
                Save
                <i className="bx bx-upload mx-1" />
              </button>
            )}
          </div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div className="row mt-4">
                <div className="col">
                  <div className="d-flex justify-content-start align-items-center success-border-div ">
                    <img className="ms-4" src={CalendarImg}></img>
                    <div className="form-group pt-3 mx-5 flex-grow-1">
                      <div className="d-flex ">
                        <label
                          htmlFor="dateOfSplit"
                          className="model-input-heading"
                        >
                          Date of Split*
                        </label>
                        {validation.touched.dateOfSplit &&
                        validation.errors.dateOfSplit ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfSplit}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100   ${
                          validation.touched.dateOfSplit &&
                          validation.errors.dateOfSplit &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="dateOfSplit"
                        name="dateOfSplit"
                        placeholder="Date of Split"
                        value={validation.values.dateOfSplit}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group pt-3 flex-grow-1 me-2">
                      <div className="d-flex ">
                        <label
                          htmlFor="dateOfIssue"
                          className="model-input-heading"
                        >
                          Date of Issue*
                        </label>
                        {validation.touched.dateOfIssue &&
                        validation.errors.dateOfIssue ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfIssue}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 1 ${
                          validation.touched.dateOfIssue &&
                          validation.errors.dateOfIssue &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="dateOfIssue"
                        name="dateOfIssue"
                        placeholder="Date of Issue"
                        value={validation.values.dateOfIssue}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-3"></div> */}
              </div>
              <div className="row mx-0 mt-4 pt-4 backgroung-light-grey">
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="certificateNo"
                        className="model-input-heading"
                      >
                        Certificate No
                      </label>
                      {validation.touched.certificateNo &&
                      validation.errors.certificateNo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.certificateNo}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.certificateNo &&
                        validation.errors.certificateNo &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="certificateNo"
                      name="certificateNo"
                      placeholder="Certificate No"
                      value={validation.values.certificateNo}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="noOfShares"
                        className="model-input-heading"
                      >
                        No of Shares
                      </label>
                      {validation.touched.noOfShares &&
                      validation.errors.noOfShares ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.noOfShares}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.noOfShares &&
                        validation.errors.noOfShares &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="noOfShares"
                      name="noOfShares"
                      placeholder="No of Shares"
                      value={validation.values.noOfShares}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="distinctiveNoFrom"
                        className="model-input-heading"
                      >
                        Distinctive No:From
                      </label>
                      {validation.touched.distinctiveNoFrom &&
                      validation.errors.distinctiveNoFrom ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNoFrom}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.distinctiveNoFrom &&
                        validation.errors.distinctiveNoFrom &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="distinctiveNoFrom"
                      name="distinctiveNoFrom"
                      placeholder="Dictinctive No:From"
                      value={validation.values.distinctiveNoFrom}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="distinctiveNoTo"
                        className="model-input-heading"
                      >
                        Distinctive No:To
                      </label>
                      {validation.touched.distinctiveNoTo &&
                      validation.errors.distinctiveNoTo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNoTo}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.distinctiveNoTo &&
                        validation.errors.distinctiveNoTo &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="distinctiveNoTo"
                      name="distinctiveNoTo"
                      placeholder="Dictinctive No:To"
                      value={validation.values.distinctiveNoTo}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive mt-5">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>No of Shares</th>
                      <th>Distinctive No:From</th>
                      <th>Distinctive No:To</th>
                      <th>Share Certificate No</th>
                      {!shareView && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map(
                      (val: SplitShareTableDto, index: number) => (
                        <tr key={`${index}`}>
                          <td>{index + 1}</td>
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.noOfShares}
                                type="number"
                                min="0"
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp: SplitShareTableDto[] = JSON.parse(
                                    JSON.stringify(tableData)
                                  );
                                  temp[index].noOfShares = Number(value);
                                  setTableData(temp);
                                }}
                              />
                            ) : (
                              val.noOfShares
                            )}
                          </td>
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.DistinctiveNoFrom}
                                type="number"
                                min="0"
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp: SplitShareTableDto[] = JSON.parse(
                                    JSON.stringify(tableData)
                                  );
                                  temp[index].DistinctiveNoFrom = Number(value);
                                  setTableData(temp);
                                }}
                              />
                            ) : (
                              val.DistinctiveNoFrom
                            )}
                          </td>
                          <td>
                            {editableRow === index ? (
                              <input
                                value={val.DistinctiveNoTo}
                                type="number"
                                min="0"
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp: SplitShareTableDto[] = JSON.parse(
                                    JSON.stringify(tableData)
                                  );
                                  temp[index].DistinctiveNoTo = Number(value);
                                  setTableData(temp);
                                }}
                              />
                            ) : (
                              val.DistinctiveNoTo
                            )}
                          </td>
                          <td>
                            {editableRow === index ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <input
                                  type="text"
                                  value={
                                    val?.newCertificateNo
                                      ? val?.newCertificateNo + index
                                      : undefined
                                  }
                                  disabled={true}
                                />
                                {!shareView ? (
                                  <button
                                    type="button"
                                    className="btn primaryDismissButton d-flex align-items-center mx-3 fw-bold"
                                    disabled={loadingGetNextShareCertificateNo}
                                    onMouseDown={() => {
                                      getNextShareCertificateNo()
                                        .then((res: any) => {
                                          if (
                                            res?.data?.getShareCertificateNumber
                                              ?.shareCertificateNo
                                          ) {
                                            const value =
                                              res?.data
                                                ?.getShareCertificateNumber
                                                ?.shareCertificateNo;
                                            const temp: SplitShareTableDto[] =
                                              JSON.parse(
                                                JSON.stringify(tableData)
                                              );
                                            temp[index].newCertificateNo =
                                              Number(value);
                                            setTableData(temp);
                                          }
                                        })
                                        .catch(err => {
                                          dispatch(setToastError(err));
                                        });
                                    }}
                                  >
                                    Next No
                                  </button>
                                ) : null}
                              </div>
                            ) : (
                              val.newCertificateNo
                            )}
                          </td>
                          {!shareView && (
                            <td className="editColumn">
                              {editableRow !== index ? (
                                <>
                                  <i
                                    className={`bx bxs-edit ${
                                      editableRow === -1
                                        ? 'text-primary'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        setEditableRow(index);
                                      }
                                    }}
                                  />
                                  <i
                                    className={`bx bxs-trash-alt ${
                                      editableRow === -1
                                        ? 'text-danger'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            let temp = [...tableData];
                                            temp.splice(index, 1);
                                            setTableData(temp);
                                          } else if (
                                            /* Read more about handling dismissals below */
                                            result.dismiss ===
                                            Swal.DismissReason.cancel
                                          ) {
                                            ConfirmationDialogue.fire(
                                              'Cancelled',
                                              '',
                                              'error'
                                            );
                                          }
                                        });
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <i
                                    className="bx bx-check-circle text-primary"
                                    onClick={() => {
                                      if (
                                        tableData[editableRow].noOfShares &&
                                        tableData[editableRow]
                                          .newCertificateNo &&
                                        tableData[editableRow]
                                          .DistinctiveNoFrom &&
                                        tableData[editableRow].DistinctiveNoTo
                                      ) {
                                        setEditableRow(-1);
                                      } else {
                                        dispatch(
                                          setToastError(
                                            'Please enter data of all input fields!'
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  <i
                                    className="bx bxs-x-circle text-danger"
                                    onClick={() => {
                                      setEditableRow(-1);
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {(tableData?.length === 0 || tableData === undefined) &&
                  shareView && (
                    <div className="addDiv">
                      <h6 className="">No records found</h6>
                    </div>
                  )}
                {!shareView && (
                  <div className="addDiv">
                    <i
                      className="bx bxs-plus-circle font-22 text-success cursor-pointer"
                      onClick={() => {
                        if (editableRow === -1) {
                          if (tableData) {
                            let newProduct = [...tableData];
                            newProduct.push({
                              noOfShares: undefined,
                              DistinctiveNoFrom: undefined,
                              DistinctiveNoTo: undefined,
                              newCertificateNo: undefined,
                            });
                            setTableData(newProduct);
                            setEditableRow(tableData.length);
                          } else {
                            let newProduct = [
                              {
                                noOfShares: undefined,
                                distinctiveNoFrom: undefined,
                                distinctiveNoTo: undefined,
                                newCertificateNo: undefined,
                              },
                            ];
                            setTableData(newProduct);
                            setEditableRow(0);
                          }
                        } else {
                          dispatch(
                            setToastError(
                              'You have some unsaved data. Please save or discard it!'
                            )
                          );
                        }
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
