import React from 'react';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setToastError,
  setToastSuccess,
  setToken,
  setUserId,
  setUserType,
} from '~/Store/reducers/layout';
import jwt_decode from 'jwt-decode';
import { UserTypeEnum } from '~/commons/enums';

export const Middleware: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { token } = useAppSelector(state => state.layoutReducer);

  let paramsToken = '';
  if (location.search?.replace('?token=', '')) {
    paramsToken = location.search?.replace('?token=', '');
    dispatch(setToastError(''));
    dispatch(setToastSuccess(''));
  } else {
    paramsToken = token;
  }
  if (paramsToken) {
    dispatch(setToken(paramsToken));
    const { user } = jwt_decode(paramsToken) as any;
    dispatch(setUserType(user.userType));
    dispatch(setUserId(user.id));
    if (user.userType === UserTypeEnum.INTERMEDIAL_USER) {
      return <Navigate to={'companies'} />;
    } else if (user.userType === UserTypeEnum.SUPER_ADMIN) {
      return <Navigate to={'/admin/dashboard'} />;
    } else {
      return <Navigate to={'dashboard'} />;
    }
  } else {
    return <Navigate to={'dashboard'} />;
  }
};
