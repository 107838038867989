import React, { FC, useEffect, useState } from 'react';
import $ from 'jquery';
import './Notification.scss';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { Noti } from '~/Store/reducers/notification/types';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { setIsOpen } from '~/Store/reducers/layout';

const NotificationDropdown: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [temp, setTemp] = useState<any>();
  const { notificationsArray } = useAppSelector(
    state => state.notificationReducer
  );
  const { isOpen } = useAppSelector(state => state.layoutReducer);
  useEffect(() => {
    if (notificationsArray) {
      let tempNoti = JSON.parse(JSON.stringify(notificationsArray));
      tempNoti.reverse();
      setTemp(tempNoti);
    }
  }, [notificationsArray]);
  useEffect(() => {
    if (isOpen === 3) {
      $('#box').css('height', 'auto');
      $('#box').css('opacity', '1');
      $('.notifications').css('display', 'block');
    } else {
      $('#box').css('height', '0px');
      $('#box').css('opacity', '0');
      $('.notifications').css('display', 'none');
    }
  }, [isOpen]);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center btn header-item noti-icon border-0">
        <i
          className={`bx font-26 bxs-bell text-primary ${
            notificationsArray?.length > 0 && 'bx-tada'
          }`}
          onClick={() => {
            if (isOpen === 3) {
              $('#box').css('height', '0px');
              $('#box').css('opacity', '0');
              $('.notifications').css('display', 'none');
              dispatch(setIsOpen(-1));
            } else {
              $('#box').css('height', 'auto');
              $('#box').css('opacity', '1');
              $('.notifications').css('display', 'block');
              dispatch(setIsOpen(3));
            }
          }}
        />
        <span className="badge bg-danger rounded-pill font-9">
          {notificationsArray?.length > 0 && notificationsArray?.length}
        </span>
      </div>
      <div className="notifications mt-3" id="box">
        <h2>Notifications</h2>
        {temp?.reverse().map((noti: Noti, index: number) => (
          <div
            key={index}
            className="notifications-item cursor-pointer"
            onClick={() => {
              navigate('/notifications?notificationId=' + noti.id);
            }}
          >
            <i className="bx font-28 bx-bell text-primary m-2" />
            <div className="text">
              <h4>{noti.title}</h4>
              <p style={{ whiteSpace: 'pre-line' }}>
                {noti.body}
                {/* <a
                  className="see-more"
                  onClick={() => {
                    navigate('/notifications?notificationId=' + noti.id);
                  }}
                >
                  See More...
                </a> */}
                <div className="mt-2">
                  {moment(noti.createdAt).format('DD-MM-YYYY')} (
                  {moment(noti.createdAt).format('HH:MM')})
                </div>
              </p>
            </div>
          </div>
        ))}
        {temp?.length === 0 && (
          <h6 className="text-center my-4">No new notifications</h6>
        )}
        <div
          className="notifications-item justify-content-center cursor-pointer"
          onClick={() => {
            navigate('/notifications');
            $('.notifications').css('display', 'none');
            dispatch(setIsOpen(-1));
          }}
        >
          <p className="m-0">View All Notifications</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotificationDropdown;
