import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { task, task2 } from '~/assets/images';
import { Container, Modal, Tooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import './register.scss';
import {
  MeetingTypeEnum,
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  DIRECTOR_SHARES_DATA,
  INDEX_DATA,
  MEMBER_DATA,
  MINUTEBOOK_DATA,
  OFFICER_DATA,
  PROXY_DATA,
  PURCHASE_OF_SHARES_DATA,
  SHARETRANSFER_DATA,
} from '~/Store/graphQL/Register';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';

export const Register: FC = () => {
  const { userType } = useAppSelector(state => state.layoutReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [meeting, setMeeting] = useState(false);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const [
    generateDocumentMemberToolTipIsOpen,
    setGenerateDocumentMemberToolTipIsOpen,
  ] = useState(false);
  const [applyFilterMemberToolTipIsOpen, setApplyFilterMemberToolTipIsOpen] =
    useState(false);
  const [
    generateDocumentIndexToolTipIsOpen,
    setGenerateDocumentIndexToolTipIsOpen,
  ] = useState(false);
  const [applyFilterIndexToolTipIsOpen, setApplyFilterIndexToolTipIsOpen] =
    useState(false);
  const [
    generateDocumentProxyToolTipIsOpen,
    setGenerateDocumentProxyToolTipIsOpen,
  ] = useState(false);
  const [applyFilterProxyToolTipIsOpen, setApplyFilterProxyToolTipIsOpen] =
    useState(false);
  const [
    generateDocumentOfficerToolTipIsOpen,
    setGenerateDocumentOfficerToolTipIsOpen,
  ] = useState(false);
  const [applyFilterOfficerToolTipIsOpen, setApplyFilterOfficerToolTipIsOpen] =
    useState(false);
  const [
    generateDocumentMinuteBookToolTipIsOpen,
    setGenerateDocumentMinuteBookToolTipIsOpen,
  ] = useState(false);
  const [
    applyFilterMinuteBookToolTipIsOpen,
    setApplyFilterMinuteBookToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentShareTransferToolTipIsOpen,
    setGenerateDocumentShareTransferToolTipIsOpen,
  ] = useState(false);
  const [
    applyFilterShareTransferToolTipIsOpen,
    setApplyFilterShareTransferToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentSharePurchaseToolTipIsOpen,
    setGenerateDocumentSharePurchaseToolTipIsOpen,
  ] = useState(false);
  const [
    applyFilterSharePurchaseToolTipIsOpen,
    setApplyFilterSharePurchaseToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentDirectorToolTipIsOpen,
    setGenerateDocumentDirectorToolTipIsOpen,
  ] = useState(false);
  const [
    applyFilterDirectorToolTipIsOpen,
    setApplyFilterDirectorToolTipIsOpen,
  ] = useState(false);
  const [temp, setTemp] = useState(-1);

  const [
    proxyData,
    { loading: loadingProxyData, error: errorProxyData, data: dataProxyData },
  ] = useLazyQuery<any>(PROXY_DATA, { fetchPolicy: 'no-cache' });

  const [
    memberData,
    {
      loading: loadingMemberData,
      error: errorMemberData,
      data: dataMemberData,
    },
  ] = useLazyQuery<any>(MEMBER_DATA, { fetchPolicy: 'no-cache' });

  const [
    indexData,
    { loading: loadingIndexData, error: errorIndexData, data: dataIndexData },
  ] = useLazyQuery<any>(INDEX_DATA, { fetchPolicy: 'no-cache' });

  const [
    officerData,
    {
      loading: loadingOfficerData,
      error: errorOfficerData,
      data: dataOfficerData,
    },
  ] = useLazyQuery<any>(OFFICER_DATA, { fetchPolicy: 'no-cache' });

  const [
    shareTransferData,
    {
      loading: loadingShareTransferData,
      error: errorShareTransferData,
      data: dataShareTransferData,
    },
  ] = useLazyQuery<any>(SHARETRANSFER_DATA, { fetchPolicy: 'no-cache' });

  const [
    minuteBookData,
    {
      loading: loadingMinuteBookData,
      error: errorMinuteBookData,
      data: dataMinuteBookData,
    },
  ] = useLazyQuery<any>(MINUTEBOOK_DATA, { fetchPolicy: 'no-cache' });

  const [
    purchaseShareData,
    {
      loading: loadingPurchaseShareData,
      error: errorPurchaseShareData,
      data: dataPurchaseShareData,
    },
  ] = useLazyQuery<any>(PURCHASE_OF_SHARES_DATA, { fetchPolicy: 'no-cache' });

  const [
    directorShareData,
    {
      loading: loadingDirectorShareData,
      error: errorDirectorShareData,
      data: dataDirectorShareData,
    },
  ] = useLazyQuery<any>(DIRECTOR_SHARES_DATA, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    // registerData();

    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Register',
        },
      ])
    );
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      startDate: new Date().toISOString().slice(0, 10),

      endDate: new Date().toISOString().slice(0, 10),
      meetingType: undefined,
    },
    validationSchema: Yup.object({
      startDate: Yup.string().required('Please select start date').nullable(),
      // startTime: Yup.string().required('Please select start time').nullable(),
      endDate: Yup.string().required('Please select end date').nullable(),
      // endTime: Yup.string().required('Please select end time').nullable(),
    }),
    onSubmit: (values: any) => {},
  });
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };
  useEffect(() => {
    if (momModalVisibility) {
      validation.setFieldValue('meetingType', undefined);
    }
  }, [momModalVisibility]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Registers</b>
              </h5>
            </div>
          </div>
          <div id="mainComponent">
            <div className="d-flex w-100">
              <div className="profileContainer w-100">
                <div className="bottom">
                  <div className="mainRegister">
                    <div className="row ">
                      <div className="col RejborderO">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center mt-3">
                            MEMBER REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterMemberToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterMemberToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterMemberButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(1);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterMemberToolTipIsOpen}
                                  target="applyFilterMemberButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentMemberToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentMemberToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentMemberButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  memberData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (res.data?.createMemberRegister) {
                                        window.open(
                                          res?.data?.createMemberRegister?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={generateDocumentMemberToolTipIsOpen}
                                  target="generateDocumentMemberButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col RejborderG">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task2}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center mt-3">
                            INDEX REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterIndexToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterIndexToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterIndexButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(2);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterIndexToolTipIsOpen}
                                  target="applyFilterIndexButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentIndexToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentIndexToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentIndexButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  indexData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (res.data?.createIndexRegister) {
                                        window.open(
                                          res?.data?.createIndexRegister?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={generateDocumentIndexToolTipIsOpen}
                                  target="generateDocumentIndexButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col RejborderO">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center mt-3">
                            PROXY REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterProxyToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterProxyToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterProxyButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(3);
                                  setMeeting(true);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterProxyToolTipIsOpen}
                                  target="applyFilterProxyButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentProxyToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentProxyToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentProxyButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  proxyData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (res.data?.createProxyRegister) {
                                        window.open(
                                          res?.data?.createProxyRegister?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={generateDocumentProxyToolTipIsOpen}
                                  target="generateDocumentProxyButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col RejborderG">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task2}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center mt-3">
                            OFFICER REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterOfficerToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterOfficerToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterOfficerButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(4);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterOfficerToolTipIsOpen}
                                  target="applyFilterOfficerButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentOfficerToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentOfficerToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentOfficerButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  officerData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (res.data?.createRegisterOfOfficers) {
                                        window.open(
                                          res?.data?.createRegisterOfOfficers
                                            ?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={generateDocumentOfficerToolTipIsOpen}
                                  target="generateDocumentOfficerButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col RejborderG">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task2}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center mt-3">
                            MINUTE BOOK REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterMinuteBookToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterMinuteBookToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterMinuteBookButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(5);
                                  setMeeting(true);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterMinuteBookToolTipIsOpen}
                                  target="applyFilterMinuteBookButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentMinuteBookToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentMinuteBookToolTipIsOpen(
                                  false
                                )
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentMinuteBookButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  minuteBookData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (
                                        res.data?.createRegisterOfMinuteBook
                                      ) {
                                        window.open(
                                          res?.data?.createRegisterOfMinuteBook
                                            ?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={
                                    generateDocumentMinuteBookToolTipIsOpen
                                  }
                                  target="generateDocumentMinuteBookButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col RejborderO">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center text-center mt-3">
                            TRANSFER OF SHARES REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterShareTransferToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterShareTransferToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterShareTransferButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(6);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterShareTransferToolTipIsOpen}
                                  target="applyFilterShareTransferButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentShareTransferToolTipIsOpen(
                                  true
                                )
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentShareTransferToolTipIsOpen(
                                  false
                                )
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentShareTransferButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  shareTransferData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (res.data?.createTransferRegister) {
                                        window.open(
                                          res?.data?.createTransferRegister
                                            ?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={
                                    generateDocumentShareTransferToolTipIsOpen
                                  }
                                  target="generateDocumentShareTransferButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col RejborderG">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task2}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center text-center mt-3">
                            ISSUANCE OF SHARES REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterSharePurchaseToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterSharePurchaseToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterSharePurchaseButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(7);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterSharePurchaseToolTipIsOpen}
                                  target="applyFilterSharePurchaseButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentSharePurchaseToolTipIsOpen(
                                  true
                                )
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentSharePurchaseToolTipIsOpen(
                                  false
                                )
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentSharePurchaseButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  purchaseShareData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (
                                        res.data
                                          ?.createRegisterOfPurchaseOfShares
                                      ) {
                                        window.open(
                                          res?.data
                                            ?.createRegisterOfPurchaseOfShares
                                            ?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.error));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={
                                    generateDocumentSharePurchaseToolTipIsOpen
                                  }
                                  target="generateDocumentSharePurchaseButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col RejborderO">
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="img R-img" src={task}></img>
                        </div>
                        <div className="row">
                          <p className="d-flex justify-content-center text-center mt-3">
                            SHARES/SECURITIES OF DIRECTOR REGISTER
                          </p>

                          <div className="d-flex mt-3 justify-content-center gap-3">
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setApplyFilterDirectorToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setApplyFilterDirectorToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnL minW"
                                id="applyFilterDirectorButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  setTemp(8);
                                  setMeeting(false);
                                  toggleModal();
                                  setMomModalVisibility(true);
                                }}
                              >
                                Apply Filter
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={applyFilterDirectorToolTipIsOpen}
                                  target="applyFilterDirectorButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                !(
                                  GetPermissionsOfUser(
                                    selectedUserPermissions,
                                    ModuleEnum.REGISTER,
                                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                                )
                                  ? 'cursor-not-allowed'
                                  : ''
                              }`}
                              onMouseEnter={() =>
                                setGenerateDocumentDirectorToolTipIsOpen(true)
                              }
                              onMouseLeave={() =>
                                setGenerateDocumentDirectorToolTipIsOpen(false)
                              }
                            >
                              <button
                                className="btn RejbtnR minW"
                                id="generateDocumentDirectorButton"
                                disabled={
                                  !(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.REGISTER,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  )
                                }
                                onClick={() => {
                                  directorShareData({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (
                                        res.data?.createRegisterOfDirectorShares
                                      ) {
                                        window.open(
                                          res?.data
                                            ?.createRegisterOfDirectorShares
                                            ?.url,
                                          'new'
                                        );
                                      } else {
                                        dispatch(setToastError(res.errors));
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                View
                              </button>
                              {!(
                                GetPermissionsOfUser(
                                  selectedUserPermissions,
                                  ModuleEnum.REGISTER,
                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                ) || userType === UserTypeEnum.COMPANY_ADMIN
                              ) ? (
                                <Tooltip
                                  placement="top"
                                  isOpen={generateDocumentDirectorToolTipIsOpen}
                                  target="generateDocumentDirectorButton"
                                >
                                  You don't have permission for this module
                                </Tooltip>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluids>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              {/* <i className="bx  text-primary bx-sm me-2"></i>s */}
              <i className="bx bx-filter text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Filters
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mt-3">
              <div className="col">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="startDate"
                      className="model-input-heading mb-3"
                    >
                      Start Date
                    </label>
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.startDate}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="date"
                    className={`form-control ${
                      validation.touched.startDate &&
                      validation.errors.startDate &&
                      'is-invalid'
                    }`}
                    id="startDate"
                    name="startDate"
                    placeholder="Start Date"
                    // min={new Date().toISOString().split('T')[0]}
                    value={validation.values.startDate}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="row mt-3">
              <div className="col">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label
                      htmlFor="endDate"
                      className="model-input-heading mb-3"
                    >
                      End Date
                    </label>
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.endDate}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="date"
                    className={`form-control ${
                      validation.touched.endDate &&
                      validation.errors.endDate &&
                      'is-invalid'
                    }`}
                    id="endDate"
                    name="endDate"
                    placeholder="End Date"
                    // min={new Date().toISOString().split('T')[0]}
                    value={validation.values.endDate}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>

            {meeting ? (
              <div className="row mt-3">
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label
                        htmlFor="meetingType"
                        className="model-input-heading mb-3"
                      >
                        Meeting Type*
                      </label>

                      {/* {validation.touched.meetingType &&
                        validation.errors.meetingType ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.meetingType}
                          </p>
                        ) : null} */}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control form-control-medium bg-transparent ${
                          // validation.touched.meetingType &&
                          // validation.errors.meetingType &&
                          'is-invalid'
                        }`}
                        id="meetingType"
                        name="meetingType"
                        placeholder="Meeting Type"
                        value={validation.values.meetingType}
                        onChange={e => {
                          validation.handleChange(e);
                          // dispatch(setMeetingType(e.currentTarget.value));
                        }}
                      >
                        <option value="">Select</option>
                        <option value={MeetingTypeEnum.BOARD_MEETING}>
                          Board Meeting
                        </option>
                        <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                          Committee Meeting
                        </option>
                        <option value={MeetingTypeEnum.GENERAL_MEETING}>
                          General Meeting
                        </option>
                        <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                          Statutory Meeting
                        </option>
                        <option value={MeetingTypeEnum.OTHER}>
                          Other Meeting
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <br className="my-4"></br>
            <div className="d-flex justify-content-end gap-3 mt-3">
              <button
                type="button"
                className="btn dangerButton d-flex align-items-center my-auto"
                onClick={() => {
                  const tempObj = {
                    variables: {
                      companyId: companyID,
                      startDate: validation.values.startDate + 'T00:00:00.000Z',
                      endDate: validation.values.endDate + 'T23:59:00.000Z',
                      meetingType: validation.values.meetingType,
                    },
                  };

                  switch (temp) {
                    case 1:
                      memberData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createMemberRegister) {
                            window.open(
                              res?.data?.createMemberRegister?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 2:
                      indexData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createIndexRegister) {
                            window.open(
                              res?.data?.createIndexRegister?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 3:
                      proxyData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createProxyRegister) {
                            window.open(
                              res?.data?.createProxyRegister?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 4:
                      officerData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createRegisterOfOfficers) {
                            window.open(
                              res?.data?.createRegisterOfOfficers?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 5:
                      minuteBookData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createRegisterOfMinuteBook) {
                            window.open(
                              res?.data?.createRegisterOfMinuteBook?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 6:
                      shareTransferData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createTransferRegister) {
                            window.open(
                              res?.data?.createTransferRegister?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 7:
                      purchaseShareData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createRegisterOfPurchaseOfShares) {
                            window.open(
                              res?.data?.createRegisterOfPurchaseOfShares?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.error));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    case 8:
                      directorShareData(tempObj)
                        .then((res: any) => {
                          if (res.data?.createRegisterOfDirectorShares) {
                            window.open(
                              res?.data?.createRegisterOfDirectorShares?.url,
                              'new'
                            );
                          } else {
                            dispatch(setToastError(res.errors));
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                      break;

                    default:
                    // code block
                  }
                  setMomModalVisibility(false);
                }}
              >
                View
              </button>
            </div>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
