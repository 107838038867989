import { gql } from '@apollo/client';
export const GET_ALL_SHARES_OF_COMPANY = gql`
  query findAllShare(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $shareType: String
  ) {
    findAllShare(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, shareType: $shareType }
    ) {
      edges {
        node {
          shareId
          noOfShares
          shareType
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const GET_ALL_COMPANY_MEMBERS_FOR_SHARES = gql`
  query findAllCompanyMembersInShare(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
  ) {
    findAllCompanyMembersInShare(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId }
    ) {
      edges {
        node {
          folioNo
          dateOfMembership
          ceaseDate
          totalShares
          totalAmount
          lastName
          firstName
          sharePercentage
          ceaseDate
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const GET_ALL_SHARES_TRANSACTIONS_OF_COMPANY_MEMBER = gql`
  query findMemberShareTransactions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $folioNo: Int
    #$shareType: String
    $searchKey: String
    $searchValue: String
  ) {
    findMemberShareTransactions(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: {
        companyId: $companyId
        folioNo: $folioNo
        #shareType: $shareType
      }
      searchKey: $searchKey
      searchValue: $searchValue
    ) {
      edges {
        node {
          id
          shareId
          shareTransactionId
          folioNo
          shareType
          noOfShares
          shareCertificateNo
          allotmentSplitTransferNo
          DistinctiveNoFrom
          DistinctiveNoTo
          issueDate
          transactionType
          oldShareCertificateNo
          status
          createdAt
          count
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_SHARE_BY_ID = gql`
  query findOneShare($id: ID!) {
    findOneShare(id: $id) {
      share {
        shareId
        folioNo
        AllotmentDate
        issueDate
        shareType
        shareSubType
        shareCertificateNo
        noOfShares
        certificateType
        DistinctiveNoFrom
        DistinctiveNoTo
        issueType
        allotmentMethod
        discountOrPremium
      }
    }
  }
`;
export const FIND_TRANSFER_SHARE_BY_ID = gql`
  query findOneTransferShare($id: ID!) {
    findOneTransferShare(id: $id) {
      id
      shareId
      stampTicket
      transferNo
      transferorName
      TransfereeName
      TransfereeFolio
      considerationPerShare
      lodgmentDate
      approvalDate
      personLodgingTransfer
    }
  }
`;
export const FIND_DUPLICATE_SHARE_BY_ID = gql`
  query findOneDuplicateShare($id: ID!) {
    findOneDuplicateShare(id: $id) {
      id
      shareId
      duplicateReason
      issueDate
      shareCertificateNo
      receiptDate
      approveDate
      url
    }
  }
`;
export const UPDATE_SHARE = gql`
  mutation updateShare(
    $id: String!
    $AllotmentDate: String
    $issueDate: String
    $shareType: String
    $shareSubType: String
    $noOfShares: Int
    $certificateType: String
    $issueType: String
    $allotmentMethod: String
    $discountOrPremium: Int
  ) {
    updateShare(
      data: {
        id: $id
        data: {
          AllotmentDate: $AllotmentDate
          issueDate: $issueDate
          shareType: $shareType
          shareSubType: $shareSubType
          noOfShares: $noOfShares
          certificateType: $certificateType
          issueType: $issueType
          allotmentMethod: $allotmentMethod
          discountOrPremium: $discountOrPremium
        }
      }
    ) {
      share {
        shareId
        AllotmentDate
        shareCertificateNo
      }
    }
  }
`;
export const UPDATE_TRANSFER_SHARE = gql`
  mutation updateTransferShare(
    $id: String!
    $shareId: String!
    $stampTicket: Int
    $approvalDate: String
    $personLodgingTransfer: String
    $considerationPerShare: Int
    $lodgmentDate: String
    $TransfereeFolio: Int
    $transferorName: String
    $TransfereeName: String
    $transferNo: Int
  ) {
    updateTransferShare(
      data: {
        id: $id
        data: {
          shareId: $shareId
          stampTicket: $stampTicket
          approvalDate: $approvalDate
          personLodgingTransfer: $personLodgingTransfer
          considerationPerShare: $considerationPerShare
          lodgmentDate: $lodgmentDate
          TransfereeFolio: $TransfereeFolio
          transferorName: $transferorName
          TransfereeName: $TransfereeName
          transferNo: $transferNo
        }
      }
    ) {
      id
      shareId
      stampTicket
      transferNo
      transferorName
      TransfereeName
      TransfereeFolio
    }
  }
`;
export const UPDATE_DUPLICATE_SHARE = gql`
  mutation updateDuplicateShare(
    $shareId: String!
    $companyId: String
    $duplicateReason: String
    $issueDate: String
    $approveDate: String
    $receiptDate: String
    $shareCertificateNo: Int
  ) {
    updateDuplicateShare(
      data: {
        shareId: $shareId
        companyId: $companyId
        duplicateReason: $duplicateReason
        issueDate: $issueDate
        approveDate: $approveDate
        receiptDate: $receiptDate
        shareCertificateNo: $shareCertificateNo
      }
    ) {
      id
      shareId
      issueDate
      duplicateReason
    }
  }
`;
export const ALLOT_SHARE = gql`
  mutation createShare(
    $companyId: String
    $folioNo: Int
    $AllotmentDate: String
    $issueDate: String
    $shareType: String
    $shareSubType: String
    $noOfShares: Int
    $certificateType: String
    $issueType: String
    $allotmentMethod: String
    $discountOrPremium: Int
  ) {
    createShare(
      data: {
        companyId: $companyId
        folioNo: $folioNo
        AllotmentDate: $AllotmentDate
        issueDate: $issueDate
        shareType: $shareType
        shareSubType: $shareSubType
        noOfShares: $noOfShares
        certificateType: $certificateType
        issueType: $issueType
        allotmentMethod: $allotmentMethod
        discountOrPremium: $discountOrPremium
      }
    ) {
      share {
        shareId
        AllotmentDate
        shareCertificateNo
      }
    }
  }
`;
export const TRANSFER_SHARE = gql`
  mutation transferShare(
    $shareId: String!
    $stampTicket: Int
    $approvalDate: String
    $personLodgingTransfer: String
    $considerationPerShare: Int
    $lodgmentDate: String
    $TransfereeFolio: Int
    $transferorName: String
    $TransfereeName: String
  ) {
    transferShare(
      data: {
        shareId: $shareId
        stampTicket: $stampTicket
        approvalDate: $approvalDate
        personLodgingTransfer: $personLodgingTransfer
        considerationPerShare: $considerationPerShare
        lodgmentDate: $lodgmentDate
        TransfereeFolio: $TransfereeFolio
        transferorName: $transferorName
        TransfereeName: $TransfereeName
      }
    ) {
      shareId
      stampTicket
      transferNo
      transferorName
      TransfereeName
      approvalDate
    }
  }
`;
export const DUPLICATE_SHARE = gql`
  mutation duplicateShare(
    $shareId: String!
    $companyId: String
    $duplicateReason: String
    $issueDate: String
    $approveDate: String
    $receiptDate: String
    $files: [Upload]
  ) {
    duplicateShare(
      data: {
        shareId: $shareId
        companyId: $companyId
        duplicateReason: $duplicateReason
        issueDate: $issueDate
        approveDate: $approveDate
        receiptDate: $receiptDate
        files: $files
      }
    ) {
      id
      shareId
      duplicateReason
      shareCertificateNo
      issueDate
      receiptDate
      approveDate
    }
  }
`;
export const TRANSMIT_SHARE = gql`
  mutation transmissionShare($shareId: String!, $data: [TransmissionShare]) {
    transmissionShare(data: { shareId: $shareId, data: $data }) {
      data {
        id
        shareId
        dateOfReceipt
        shareCertificateNo
        transmitToFolio
        transmissionReason
        transmissionMode
      }
    }
  }
`;
export const SPLIT_SHARE = gql`
  mutation splitShare($shareId: String!, $data: [SplitShare]) {
    splitShare(data: { shareId: $shareId, data: $data }) {
      data {
        shareId
        noOfShares
        dateOfSplit
        dateOfIssue
        shareCertificateNo
      }
    }
  }
`;
export const DELETE_SHARE = gql`
  mutation deleteShare(
    $id: String!
    $transactionType: String
    $transactionId: String
    $transferNo: Int
  ) {
    deleteShare(
      data: {
        id: $id
        transactionType: $transactionType
        transactionId: $transactionId
        transferNo: $transferNo
      }
    ) {
      error
      isDeleted
    }
  }
`;
export const GENERATE_SHARE_CERTIFICATE = gql`
  query createCertificate($id: ID!) {
    createCertificate(id: $id) {
      isGenerated
      url
    }
  }
`;
export const GET_NEXT_SHARE_CERTIFICATE_NO = gql`
  query getShareCertificateNumber($companyId: ID!) {
    getShareCertificateNumber(id: $companyId) {
      shareCertificateNo
    }
  }
`;
export const GET_NEXT_DISTINCTIVE_NO_FROM = gql`
  query getDistinctiveNumber($companyId: ID!) {
    getDistinctiveNumber(id: $companyId) {
      DistinctiveNoFrom
    }
  }
`;
export const GET_NEXT_TRANSFER_NO = gql`
  query getTransferNumber($companyId: ID!) {
    getTransferNumber(id: $companyId) {
      transferNo
    }
  }
`;
