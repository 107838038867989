import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './subscription.scss';
import {
  SubscriptionPlansEnum,
  SubscriptionStatusEnum,
  UserTypeEnum,
} from '~/commons/enums';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import jwt_decode from 'jwt-decode';
import {
  CREATE_USER_SUBSCRIPTION,
  FIND_USER_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION_PLANS,
  UPDATE_USER_SUBSCRIPTION,
} from '~/Store/graphQL/Subscription';
import GetSubscriptionPlanDuration from '~/helpers/Subscriptions/getSubscriptionPlanDuration';
import moment from 'moment';

export const SubscriptionPackage: FC = () => {
  const { userType } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const [val, setVal] = useState(5);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('');
  const [total, setTotal] = useState<string>('0');
  const [date, setDate] = useState<string>('');
  const [diffDays, setDiffDays] = useState(5);
  const { token } = useAppSelector(state => state.layoutReducer);
  const { user } = jwt_decode(token) as any;
  const [
    getUserSubscription,
    {
      loading: loadingUserSubscription,
      error: errorUserSubscription,
      data: dataUserSubscription,
    },
  ] = useLazyQuery<any>(FIND_USER_SUBSCRIPTION, {
    variables: {
      userId: user.id,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    createUserSubscription,
    {
      loading: loadingCreateUserSubscription,
      error: errorCreateUserSubscription,
      data: dataCreateUserSubscription,
    },
  ] = useMutation<any>(CREATE_USER_SUBSCRIPTION, { errorPolicy: 'all' });
  useEffect(() => {
    if (dataUserSubscription) {
      let currentDate = moment(new Date());
      let expiryDate = moment(
        dataUserSubscription?.findOneUserSubscription?.userSubscription?.endDate
      );
      let difference = expiryDate.diff(currentDate, 'days');
      if (
        dataUserSubscription?.findOneUserSubscription?.userSubscription
          ?.status !== SubscriptionStatusEnum.EXPIRED
      ) {
        userType === UserTypeEnum.COMPANY_ADMIN
          ? setVal(
              dataUserSubscription?.findOneUserSubscription?.userSubscription
                ?.numOfUsers
            )
          : setVal(
              dataUserSubscription?.findOneUserSubscription?.userSubscription
                ?.numOfCompanies
            );
        setDiffDays(difference);
      } else {
        setSubscriptionPlanId('');
      }
    }
  }, [dataUserSubscription]);

  const [
    getAllSubscriptionPlans,
    {
      loading: loadingAllSubscriptionPlans,
      error: errorAllSubscriptionPlans,
      data: dataAllSubscriptionPlans,
    },
  ] = useLazyQuery<any>(GET_ALL_SUBSCRIPTION_PLANS, {
    fetchPolicy: 'no-cache',
  });

  const [
    updateUserSubscription,
    {
      loading: loadingUpdateUserSubscription,
      error: errorUpdateUserSubscription,
      data: dataUpdateUserSubscription,
    },
  ] = useMutation<any>(UPDATE_USER_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getAllSubscriptionPlans();
    getUserSubscription();
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          // title: 'Admin Dashboard',
        },
      ])
    );
  }, []);
  useEffect(() => {
    const filterDate = new Date();
    let strt;
    let subscriptionPlanDuration = '';
    dataAllSubscriptionPlans?.getAllSubscriptionPlans?.edges?.find(
      (element: any) => {
        if (element.node.id === subscriptionPlanId) {
          subscriptionPlanDuration = element?.node?.duration;
          if (user?.userType === UserTypeEnum.INTERMEDIAL_USER) {
            setTotal(
              element.node.intermedialUserBaseCost +
                element.node.costPerCompany * Number(val)
            );
          } else {
            setTotal(
              element.node.companyBaseCost +
                element.node.costPerUser * Number(val)
            );
          }
          return true;
        }
      }
    );
    if (subscriptionPlanDuration === SubscriptionPlansEnum.ONE_YEAR_PLAN) {
      strt = new Date(
        filterDate.getFullYear() + Number(1),
        filterDate.getMonth(),
        filterDate.getDate()
      );
    } else if (
      subscriptionPlanDuration === SubscriptionPlansEnum.TWO_YEARS_PLAN
    ) {
      strt = new Date(
        filterDate.getFullYear() + Number(2),
        filterDate.getMonth(),
        filterDate.getDate()
      );
    } else if (
      subscriptionPlanDuration === SubscriptionPlansEnum.THREE_YEARS_PLAN
    ) {
      strt = new Date(
        filterDate.getFullYear() + Number(3),
        filterDate.getMonth(),
        filterDate.getDate()
      );
    }
    strt && setDate(`${moment(strt).format('MMM DD, yyyy')}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, subscriptionPlanId]);
  return (
    <React.Fragment>
      <div id="" className="mainComponentDash">
        <div className="w-100">
          {dataAllSubscriptionPlans && dataUserSubscription ? (
            <>
              <div className="row subPackage bg-white m-0 mt-4 ">
                <div className="row pt-4">
                  <div className="d-flex justify-content-start">
                    <i className="bx bxs-check-circle bx-md text-primary  me-2"></i>

                    <h5 className="RegHeading py-2">Current Package</h5>

                    {dataUserSubscription?.findOneUserSubscription
                      ?.userSubscription?.status ===
                    SubscriptionStatusEnum.PAID ? (
                      <button
                        type="button"
                        className="btn fw-bold successButton d-flex align-items-center rounded-pill ms-auto"
                      >
                        {
                          dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.status
                        }
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn fw-bold dangerButton d-flex align-items-center rounded-pill ms-auto"
                      >
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.status
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.status
                          : 'None'}
                      </button>
                    )}
                  </div>
                  <hr className=""></hr>
                </div>

                <div className=" row p-3 my-2 pt-0 d-flex justify-content-center align-items-center  subHeadings ">
                  <div className="col text-center  border-end">
                    <p>Total Paid Amount</p>
                    <h4 className="text-success">
                      {dataUserSubscription?.findOneUserSubscription
                        ?.userSubscription?.status
                        ? dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.totalAmount
                        : 'None'}
                    </h4>
                  </div>
                  {dataUserSubscription?.findOneUserSubscription
                    ?.userSubscription?.pendingAmount &&
                  dataUserSubscription?.findOneUserSubscription
                    ?.userSubscription?.pendingAmount > 0 ? (
                    <div className="col text-center  border-end">
                      <p>Pending Amount</p>
                      <h4 className="text-warning">
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.pendingAmount
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.pendingAmount
                          : 'None'}
                      </h4>
                    </div>
                  ) : null}
                  <div className="col text-center  border-end ">
                    <p>Subscription</p>
                    <h4>
                      {dataUserSubscription?.findOneUserSubscription
                        ?.userSubscription?.subscriptionPlan?.duration ? (
                        GetSubscriptionPlanDuration(
                          dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.subscriptionPlan?.duration
                        )
                      ) : (
                        <div className=" col text-center  border-end ">
                          <p>Start Date</p>
                          <h4>
                            {' '}
                            {dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.startDate
                              ? moment(
                                  dataUserSubscription?.findOneUserSubscription
                                    ?.userSubscription?.startDate
                                ).format('DD-MM-YYYY')
                              : 'None'}
                          </h4>
                        </div>
                      )}
                    </h4>
                  </div>
                  {userType === UserTypeEnum.COMPANY_ADMIN ? (
                    <div className="col text-center  border-end ">
                      <p>No. of Users</p>
                      <h4>
                        {' '}
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.numOfUsers
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.numOfUsers
                          : 'None'}{' '}
                        Users
                      </h4>
                    </div>
                  ) : (
                    <div className="col text-center  border-end ">
                      <p>No. of Companies</p>
                      <h4>
                        {' '}
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.numOfCompanies
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.numOfCompanies
                          : 'None'}{' '}
                        Companies
                      </h4>
                    </div>
                  )}
                  <div className=" col text-center  border-end ">
                    <p>Start Date</p>
                    <h4>
                      {' '}
                      {dataUserSubscription?.findOneUserSubscription
                        ?.userSubscription?.startDate
                        ? moment(
                            dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.startDate
                          ).format('DD-MM-YYYY')
                        : 'None'}
                    </h4>
                  </div>
                  <div className=" col text-center   ">
                    <p>End Date</p>
                    <h4 className="text-danger">
                      {' '}
                      {dataUserSubscription?.findOneUserSubscription
                        ?.userSubscription?.endDate
                        ? moment(
                            dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.endDate
                          ).format('DD-MM-YYYY')
                        : 'None'}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row subPackage2 bg-white m-0 mt-4 ">
                <div className="row pt-4">
                  <div className="d-flex justify-content-start">
                    <i className="bx bxs-check-circle bx-md text-primary  me-2"></i>

                    <h5 className="packageHeading py-2">Billing : </h5>
                    <p className="ps-2 mt-2  pt-1 packageP">
                      {dataUserSubscription?.findOneUserSubscription
                        ?.userSubscription?.status !==
                      SubscriptionStatusEnum.EXPIRED ? (
                        <>
                          Update your payment Information according to your
                          needs.
                        </>
                      ) : (
                        <>
                          Please select new subscription package according to
                          your needs.
                        </>
                      )}
                    </p>
                  </div>
                  <hr className=""></hr>
                </div>

                <div className=" row p-3 my-2 pt-0 d-flex justify-content-center align-items-center  subHeadings ">
                  <div className="col text-start m-3 d-flex">
                    <div>
                      <h4 className="mb-3">Subscription</h4>
                      <p>Select numbers of years</p>
                    </div>
                    <div className="form-group mt-4 ms-2">
                      <div className="field">
                        <div
                          style={{
                            position: 'relative',
                            width: 'max-content',
                          }}
                        >
                          <select
                            id="duration"
                            name="duration"
                            className={`form-control mx-2`}
                            disabled={
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.status !==
                              SubscriptionStatusEnum.EXPIRED
                                ? true
                                : false
                            }
                            value={
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.status !==
                              SubscriptionStatusEnum.EXPIRED
                                ? dataUserSubscription?.findOneUserSubscription
                                    ?.userSubscription?.subscriptionPlan?.id
                                : subscriptionPlanId
                            }
                            onChange={e => {
                              setSubscriptionPlanId(e.target.value);
                            }}
                          >
                            <option value="">Please Select</option>
                            {dataAllSubscriptionPlans?.getAllSubscriptionPlans?.edges?.map(
                              (item: any, index: number) => (
                                <option value={item?.node?.id} key={index}>
                                  {GetSubscriptionPlanDuration(
                                    item?.node?.duration
                                  )}
                                </option>
                              )
                            )}
                          </select>
                          <i
                            className="bx bx-chevron-down iconRight"
                            style={{
                              position: 'absolute',
                              bottom: '10px',
                              right: '5px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col text-end  ">
                    <h4 className="mb-3">Start & End Date</h4>
                    <div className="d-flex text-end justify-content-end gap-2">
                      <h4 className="text-primary">
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.status !==
                        SubscriptionStatusEnum.EXPIRED
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.startDate
                            ? moment(
                                dataUserSubscription?.findOneUserSubscription
                                  ?.userSubscription?.startDate
                              ).format('DD-MM-YYYY')
                            : 'None'
                          : moment(new Date()).format('DD-MM-YYYY')}
                      </h4>
                      <h4 className="text-danger">
                        {dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.status !==
                        SubscriptionStatusEnum.EXPIRED
                          ? dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.endDate
                            ? moment(
                                dataUserSubscription?.findOneUserSubscription
                                  ?.userSubscription?.endDate
                              ).format('DD-MM-YYYY')
                            : 'None'
                          : date
                          ? moment(date).format('DD-MM-YYYY')
                          : 'NA'}
                      </h4>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex w-100 my-1">
                    <p className="ms-3 pt-2 packageP">
                      Select Number of{' '}
                      {userType === UserTypeEnum.COMPANY_ADMIN ? (
                        <strong className="text-black"> Users</strong>
                      ) : (
                        <strong className="text-black"> Companies</strong>
                      )}
                    </p>
                    <h4 className="mt-3 fs-3 ms-auto">PKR</h4>
                  </div>
                  <div className="d-flex w-100 my-1">
                    <h4 className="mb-3 ms-2 ps-2 fs-3 color-primary">
                      {userType === UserTypeEnum.COMPANY_ADMIN ? (
                        dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.status !==
                        SubscriptionStatusEnum.EXPIRED ? (
                          val >
                          dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.numOfUsers ? (
                            <>
                              <span>
                                {
                                  dataUserSubscription?.findOneUserSubscription
                                    ?.userSubscription?.numOfUsers
                                }
                              </span>
                              <span className="color-success">
                                {' '}
                                +{' '}
                                {val -
                                  dataUserSubscription?.findOneUserSubscription
                                    ?.userSubscription?.numOfUsers}
                              </span>
                            </>
                          ) : (
                            dataUserSubscription?.findOneUserSubscription
                              ?.userSubscription?.numOfUsers
                          )
                        ) : (
                          val
                        )
                      ) : val >
                        dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.numOfCompanies ? (
                        <>
                          <span>
                            {
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.numOfCompanies
                            }
                          </span>
                          <span className="color-success">
                            {' '}
                            +{' '}
                            {val -
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.numOfCompanies}
                          </span>
                        </>
                      ) : (
                        dataUserSubscription?.findOneUserSubscription
                          ?.userSubscription?.numOfCompanies
                      )}
                    </h4>
                    <h4 className="mb-3 fs-3 color-success ms-auto">
                      {userType === UserTypeEnum.COMPANY_ADMIN
                        ? dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.status !==
                          SubscriptionStatusEnum.EXPIRED
                          ? Math.round(
                              (((val -
                                dataUserSubscription?.findOneUserSubscription
                                  ?.userSubscription?.numOfUsers) *
                                dataUserSubscription?.findOneUserSubscription
                                  ?.userSubscription?.subscriptionPlan
                                  ?.costPerUser) /
                                365) *
                                diffDays
                            )
                          : total
                        : dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.status !==
                          SubscriptionStatusEnum.EXPIRED
                        ? Math.round(
                            (((val -
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.numOfCompanies) *
                              dataUserSubscription?.findOneUserSubscription
                                ?.userSubscription?.subscriptionPlan
                                ?.costPerCompany) /
                              365) *
                              diffDays
                          )
                        : total}
                    </h4>
                  </div>
                  <div className="d-flex mt-3 ms-3">
                    <button
                      className="btn d-flex justify-content-center align-items-center"
                      onClick={() => {
                        if (val > 5) {
                          setVal(val - 1);
                        }
                      }}
                    >
                      <i className="bx bx-minus-circle bx-sm fs-4 text-danger"></i>
                    </button>

                    <Slider
                      className="w-100 tooltipx"
                      value={val}
                      min={5}
                      max={100}
                      tooltip={true}
                      orientation="horizontal"
                      onChange={(e: any) => {
                        setVal(e);
                      }}
                    />
                    <button
                      className="btn d-flex justify-content-center align-items-center"
                      onClick={() => {
                        if (val < 100) {
                          setVal(val + 1);
                        }
                      }}
                    >
                      <i className="bx bx-plus-circle bx-sm  fs-4 text-danger me-2"></i>
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn w-100 fw-bold mt-4 bg-primary rounded p-2 "
                onClick={() => {
                  if (
                    dataUserSubscription?.findOneUserSubscription
                      ?.userSubscription?.status !==
                    SubscriptionStatusEnum.EXPIRED
                  ) {
                    updateUserSubscription({
                      variables: {
                        userId: user.id,
                        subscriptionPlanId:
                          dataUserSubscription?.findOneUserSubscription
                            ?.userSubscription?.subscriptionPlan?.id,
                        numOfUsers:
                          userType === UserTypeEnum.COMPANY_ADMIN
                            ? val
                            : undefined,
                        numOfCompanies:
                          userType === UserTypeEnum.INTERMEDIAL_USER
                            ? val
                            : undefined,
                      },
                    })
                      .then(res => {
                        if (res.data) {
                          dispatch(
                            setToastSuccess(
                              'Subscription will be updated once payment is verified'
                            )
                          );
                          navigate('/dashboard');
                        }
                      })
                      .catch(err => {
                        console.log('Failed ', err);
                        dispatch(setToastError(err));
                      });
                  } else {
                    if (subscriptionPlanId) {
                      createUserSubscription({
                        variables: {
                          userId: user.id,
                          subscriptionPlanId: subscriptionPlanId,
                          numOfUsers:
                            userType === UserTypeEnum.COMPANY_ADMIN
                              ? val
                              : undefined,
                          numOfCompanies:
                            userType === UserTypeEnum.INTERMEDIAL_USER
                              ? val
                              : undefined,
                          totalAmount: parseInt(total),
                        },
                      })
                        .then(res => {
                          if (res?.data?.createUserSubscription) {
                            dispatch(
                              setToastSuccess('New Subscription Purchased')
                            );
                            navigate('/dashboard');
                          } else if (res?.errors) {
                            dispatch(setToastError(res?.errors[0]?.message));
                          }
                        })
                        .catch(err => {
                          console.log('Failed ', err);
                          dispatch(setToastError(err));
                        });
                    } else {
                      dispatch(
                        setToastError(
                          'Plaese select no of years for subscription'
                        )
                      );
                    }
                  }
                }}
              >
                <div className="text-center text-white w-100">
                  {' '}
                  {dataUserSubscription?.findOneUserSubscription
                    ?.userSubscription?.status !==
                  SubscriptionStatusEnum.EXPIRED ? (
                    <>Update Subscription</>
                  ) : (
                    <>Add Subscription</>
                  )}
                </div>
              </button>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center w-100 h-75">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
