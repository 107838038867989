import { ShareTransactionTypeEnum } from '../../commons/enums';
const GetShareTransactionType = (type: string) => {
  if (type === ShareTransactionTypeEnum.ALLOTMENT) {
    return 'Allotment';
  } else if (type === ShareTransactionTypeEnum.DUPLICATE) {
    return 'Duplicate';
  } else if (type === ShareTransactionTypeEnum.SPLIT) {
    return 'Split';
  } else if (type === ShareTransactionTypeEnum.TRANSFER) {
    return 'Transfer';
  } else if (type === ShareTransactionTypeEnum.TRANSMISSION) {
    return 'Transmission';
  }
};

export default GetShareTransactionType;
