import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { CompanyKindEnum } from '../../../commons/enums';
import {
  setBusinessRuleViewScreen,
  setSelecteBusinessRuleId,
} from '~/Store/reducers/businessRules';
import {
  DELETE_BUSINESS_RULE,
  GET_ALL_BUSINESS_RULES,
  UPDATE_STATUS_OF_BUSINESS_RULE,
} from '~/Store/graphQL/BusinessRules';
import GetCompanyKind from '~/helpers/CompanyProfile/getCompanyKind';

export const BusinessRules: FC = () => {
  let pageNo = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    companyKind: '',
  });
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Business Rules',
        },
      ])
    );
  }, []);
  // Get all business rules query
  const [
    getAllBusinessRules,
    {
      loading: loadingGetAllBusinessRules,
      error: errorGetAllBusinessRules,
      data: dataGetAllBusinessRules,
    },
  ] = useLazyQuery<any>(GET_ALL_BUSINESS_RULES, {
    variables: {
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });
  // delete business rule
  const [
    deleteBusinessRule,
    {
      loading: loadingDeleteBusinessRule,
      error: errorDeleteBusinessRule,
      data: dataDeleteBusinessRule,
    },
  ] = useMutation<any>(DELETE_BUSINESS_RULE);
  //   update status of business rule
  const [
    updateStatusOfBusinessRule,
    {
      loading: loadingUpdateStatusOfBusinessRule,
      error: errorUpdateStatusOfBusinessRule,
      data: dataUpdateStatusOfBusinessRule,
    },
  ] = useMutation<any>(UPDATE_STATUS_OF_BUSINESS_RULE);
  useEffect(() => {
    getAllBusinessRules();
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllBusinessRules) {
      dispatch(setToastError(errorGetAllBusinessRules));
    }
  }, [errorGetAllBusinessRules]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="companyKind">Company Kind</label>
                </div>
                <div className="field">
                  <select
                    id="companyKind"
                    name="companyKind"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.companyKind}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.companyKind = value;
                      setFilters(temp);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={CompanyKindEnum.PRIVATE}>
                      Private Company
                    </option>
                    <option value={CompanyKindEnum.UNLISTED_PUBLIC_COMPANY}>
                      Public Company
                    </option>
                    <option value={CompanyKindEnum.LISTED_COMPANY}>
                      Listed Company
                    </option>
                    <option value={CompanyKindEnum.SMC}>SMC</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.companyKind) {
                      getAllBusinessRules({
                        variables: {
                          first: itemsPerPage,
                          companyKind: undefined,
                        },
                      });
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.companyKind = '';
                      setFilters(temp);
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  disabled={loadingGetAllBusinessRules}
                  onClick={() => {
                    if (filters.companyKind) {
                      getAllBusinessRules({
                        variables: {
                          first: itemsPerPage,
                          companyKind: filters?.companyKind
                            ? filters?.companyKind
                            : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setBusinessRuleViewScreen(false));
                dispatch(setSelecteBusinessRuleId(null));
                navigate('/admin/businessRules/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Business Rule
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllBusinessRules ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th>S.No</th>
                            <th>Business Rule</th>
                            <th>Company Kind</th>
                            <th>Ordinance</th>
                            <th>Section</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllBusinessRules?.findAllBusinessRules?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={index}>
                                <td>
                                  {pageNo.current * itemsPerPage + (index + 1)}
                                </td>
                                <td>
                                  {val.node.name ? val?.node?.name : 'N/A'}
                                </td>
                                <td>
                                  {val.node.kind
                                    ? GetCompanyKind(val.node.kind)
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.ordinance
                                    ? val.node.ordinance
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.section ? val.node.section : 'N/A'}
                                </td>

                                <td>
                                  <div className="form-check form-switch d-flex justify-content-center align-items-center">
                                    <input
                                      className="form-check-input switch-button"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                      defaultChecked={
                                        val.node.isActive ? true : false
                                      }
                                      disabled={
                                        loadingUpdateStatusOfBusinessRule
                                      }
                                      onChange={e => {
                                        updateStatusOfBusinessRule({
                                          variables: {
                                            id: val?.node?.id,
                                            isActive: e.target.checked,
                                          },
                                        })
                                          .then((res: any) => {
                                            if (
                                              res?.data?.updateBusinessRule
                                                ?.businessRule
                                            ) {
                                              dispatch(
                                                setToastSuccess(
                                                  'Status of Business Rule updated!'
                                                )
                                              );
                                              getAllBusinessRules();
                                            }
                                          })
                                          .catch(err => {
                                            dispatch(setToastError(err));
                                          });
                                      }}
                                    ></input>
                                  </div>
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className="bx bx-show-alt text-primary"
                                      onClick={() => {
                                        dispatch(
                                          setSelecteBusinessRuleId(val.node.id)
                                        );
                                        dispatch(
                                          setBusinessRuleViewScreen(true)
                                        );
                                        navigate('/admin/businessRules/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(
                                          setBusinessRuleViewScreen(false)
                                        );
                                        dispatch(
                                          setSelecteBusinessRuleId(val.node.id)
                                        );
                                        navigate('/admin/businessRules/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(
                                          setBusinessRuleViewScreen(false)
                                        );
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteBusinessRule({
                                              variables: {
                                                id: val.node.id,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data.deleteBusinessRule
                                                    .count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Business Rule Deleted!'
                                                    )
                                                  );
                                                  getAllBusinessRules();
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllBusinessRules?.findAllBusinessRules?.edges
                        ?.length === 0 ||
                        dataGetAllBusinessRules?.findAllBusinessRules?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllBusinessRules?.findAllBusinessRules
                            ?.pageInfo?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllBusinessRules?.findAllBusinessRules
                              ?.pageInfo?.hasPreviousPage
                          ) {
                            getAllBusinessRules({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllBusinessRules?.findAllBusinessRules
                                    ?.pageInfo?.startCursor,
                              },
                            });

                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllBusinessRules?.findAllBusinessRules
                            ?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllBusinessRules?.findAllBusinessRules
                              ?.pageInfo?.hasNextPage
                          ) {
                            getAllBusinessRules({
                              variables: {
                                after:
                                  dataGetAllBusinessRules?.findAllBusinessRules
                                    ?.pageInfo?.endCursor,
                              },
                            });

                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
