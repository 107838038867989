import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  clockD,
  docD,
  draftMem,
  draftOfficer,
  MeetingD,
  nLogo,
  task,
  task2,
  task3,
  CompleteProfileImg,
} from '~/assets/images';
import {
  MeetingTypeEnum,
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { FIND_COMPANY_PROFILE_BY_USER_ID } from '~/Store/graphQL/CompanyProfile';
import {
  FIND_NOTIFICATIONS,
  FIND_TEMP_NOTIFICATIONS,
} from '~/Store/graphQL/Notification';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setCompanyID } from '~/Store/reducers/companyProfile';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { setNotificationsInDropdown } from '~/Store/reducers/notification';
import { format } from 'timeago.js';
import './dashboard.scss';
import {
  DIRECTOR_SHARES_DATA,
  INDEX_DATA,
  MEMBER_DATA,
  MINUTEBOOK_DATA,
  OFFICER_DATA,
  PROXY_DATA,
  PURCHASE_OF_SHARES_DATA,
  SHARETRANSFER_DATA,
} from '~/Store/graphQL/Register';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Tooltip } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';

// import BootstrapTheme from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
import { GET_ALL_MEETINGS_OF_COMPANY } from '~/Store/graphQL/MeetingsManagement';
import { USER_DASHBOARD_DATA } from '~/Store/graphQL/Dashboard';
import moment from 'moment';
import GetMeetingType from '~/helpers/Agenda/getMeetingType';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';

export const Dashboard: FC = () => {
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [expandedRow, setExpandedRow] = useState<number>(-1);
  const componentRef = useRef<HTMLElement | null>(null);

  const [componentHeight, setComponentHeight] = useState(0);

  useEffect(() => {
    if (componentRef.current) {
      const height = componentRef.current.clientHeight;
      setComponentHeight(height);
    }
  }, [componentRef]);

  const [notifications, setNotifications] = useState<any>();
  const [events, setEvents] = useState<any>([]);
  const [
    generateDocumentMemberToolTipIsOpen,
    setGenerateDocumentMemberToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentIndexToolTipIsOpen,
    setGenerateDocumentIndexToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentProxyToolTipIsOpen,
    setGenerateDocumentProxyToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentOfficerToolTipIsOpen,
    setGenerateDocumentOfficerToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentMinuteBookToolTipIsOpen,
    setGenerateDocumentMinuteBookToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentShareTransferToolTipIsOpen,
    setGenerateDocumentShareTransferToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentSharePurchaseToolTipIsOpen,
    setGenerateDocumentSharePurchaseToolTipIsOpen,
  ] = useState(false);
  const [
    generateDocumentDirectorToolTipIsOpen,
    setGenerateDocumentDirectorToolTipIsOpen,
  ] = useState(false);
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID, {
    fetchPolicy: 'no-cache',
  });
  const [
    getTempNotifications,
    {
      loading: loadingGetTempNotifications,
      error: errorGetTempNotifications,
      data: dataGetTempNotifications,
    },
  ] = useLazyQuery<any>(FIND_TEMP_NOTIFICATIONS, {
    variables: {
      userId: userId,
    },
    fetchPolicy: 'no-cache',
  });

  const [
    proxyData,
    { loading: loadingProxyData, error: errorProxyData, data: dataProxyData },
  ] = useLazyQuery<any>(PROXY_DATA, { fetchPolicy: 'no-cache' });

  const [
    memberData,
    {
      loading: loadingMemberData,
      error: errorMemberData,
      data: dataMemberData,
    },
  ] = useLazyQuery<any>(MEMBER_DATA, { fetchPolicy: 'no-cache' });

  const [
    indexData,
    { loading: loadingIndexData, error: errorIndexData, data: dataIndexData },
  ] = useLazyQuery<any>(INDEX_DATA, { fetchPolicy: 'no-cache' });

  const [
    officerData,
    {
      loading: loadingOfficerData,
      error: errorOfficerData,
      data: dataOfficerData,
    },
  ] = useLazyQuery<any>(OFFICER_DATA, { fetchPolicy: 'no-cache' });

  const [
    shareTransferData,
    {
      loading: loadingShareTransferData,
      error: errorShareTransferData,
      data: dataShareTransferData,
    },
  ] = useLazyQuery<any>(SHARETRANSFER_DATA, { fetchPolicy: 'no-cache' });

  const [
    minuteBookData,
    {
      loading: loadingMinuteBookData,
      error: errorMinuteBookData,
      data: dataMinuteBookData,
    },
  ] = useLazyQuery<any>(MINUTEBOOK_DATA, { fetchPolicy: 'no-cache' });

  const [
    purchaseShareData,
    {
      loading: loadingPurchaseShareData,
      error: errorPurchaseShareData,
      data: dataPurchaseShareData,
    },
  ] = useLazyQuery<any>(PURCHASE_OF_SHARES_DATA);

  const [
    directorShareData,
    {
      loading: loadingDirectorShareData,
      error: errorDirectorShareData,
      data: dataDirectorShareData,
    },
  ] = useLazyQuery<any>(DIRECTOR_SHARES_DATA);

  useEffect(() => {
    getAllMeetingsForCompany();
    getNotifications();
    userDasboardData();
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Dashboard',
        },
      ])
    );
    findCompanyByUserId({
      variables: {
        id: userId,
        isIntermedialUser:
          userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
      },
    });
  }, []);
  useEffect(() => {
    if (userId) {
      getTempNotifications();
    }
  }, []);

  useEffect(() => {
    if (dataGetTempNotifications && !errorGetTempNotifications) {
      let temp: any = [];
      dataGetTempNotifications?.getAllTempNotifications?.edges?.forEach(
        (element: any) => {
          let notification: any = {};
          notification = JSON.parse(element?.node?.notificationMessage);
          notification.id = element?.node?.id;
          notification.notificationId = element?.node?.notificationId;
          notification.createdAt = element?.node?.createdAt;
          temp.push(notification);
        }
      );
      dispatch(setNotificationsInDropdown(temp));
    } else if (errorGetTempNotifications) {
      dispatch(setToastError(errorGetTempNotifications));
    }
  }, [dataGetTempNotifications, errorGetTempNotifications]);
  useEffect(() => {
    setToastError('');
  }, []);
  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      dispatch(
        setCompanyID(
          dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
            .companyId
        )
      );
    } else if (errorCompanyProfileByUserId) {
      dispatch(setToastError('Error fetching company data'));
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  const [
    getNotifications,
    {
      loading: loadingGetNotifications,
      error: errorGetNotifications,
      data: dataGetNotifications,
    },
  ] = useLazyQuery<any>(FIND_NOTIFICATIONS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setNotifications(dataGetNotifications);
  }, [dataGetNotifications]);

  const [
    getAllMeetingsForCompany,
    {
      loading: loadingGetAllMeetingsForCompany,
      error: errorGetAllMeetingsForCompany,
      data: dataGetAllMeetingsForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_MEETINGS_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataGetAllMeetingsForCompany?.findAllMeetings) {
      let temp = [...events];
      dataGetAllMeetingsForCompany.findAllMeetings.edges.map(
        (edge: any, index: any) => {
          let event: any = {
            id: edge.node.meetingId,
            title: edge.node.meetingType
              ? GetMeetingType(edge.node.meetingType)
              : 'N/A',
            // start: moment(edge.node.startTime).format('YYYY-MM-DD hh:mm:ss'),
            start: new Date(edge.node.startTime),
            color:
              edge.node.meetingType === MeetingTypeEnum.BOARD_MEETING ||
              edge.node.meetingType === MeetingTypeEnum.GENERAL_MEETING
                ? '#FF7448'
                : '#0DA34F',
          };

          temp.push(event);
          console.log(
            edge.node.startTime,
            '-',
            moment(edge.node.startTime).format('YYYY-MM-DD hh:mm:ss '),
            '-',
            moment(edge.node.startTime).format('YYYY-MM-DD hh:mm:ss A')
          );
        }
      );

      setEvents(temp);
    }
  }, [dataGetAllMeetingsForCompany]);

  const [
    userDasboardData,
    {
      loading: loadingUserDashboardData,
      error: errorUserDashboardData,
      data: dataUserDashboardData,
    },
  ] = useLazyQuery<any>(USER_DASHBOARD_DATA, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  function percentage(x: any, y: any) {
    if (x + y != 0) {
      const per = (x / (x + y)) * 100;

      return Math.round(per) + '%';
    } else return 0 + '%';
  }
  return (
    <React.Fragment>
      {loadingCompanyProfileByUserId ||
      loadingGetAllMeetingsForCompany ||
      loadingGetNotifications ||
      loadingGetTempNotifications ||
      loadingUserDashboardData ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
          ?.isDraft ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="d-flex justify-content-center align-items-center w-75 completeCompanyProfileContainer">
            <img
              className="width-35-percent pt-5 pb-3"
              src={CompleteProfileImg}
            ></img>
            <div className="font-weight-600">Update Company Profile</div>
            <div className="color-font-faded mt-2">
              update your company profile to use this application
            </div>
            <button
              type="button"
              className="btn primaryButton d-flex align-items-center mt-3 mb-5"
              onMouseDown={() => {
                navigate('/companyProfile/companyInformation');
              }}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <div id="" className="mainComponentDash ">
          <div className="d-flex w-100">
            <div className="profileContainer w-100">
              <div className="bottom1">
                <div className="mainDashboard">
                  <div className="row background-main-grey ">
                    <div className="col cardborder pb-0">
                      <div className="row d-flex justify-content-center align-items-center h-60 mb-2">
                        <div className="col-8 h-100 pt-3">
                          <h1 className="r1-h">
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingMeeting
                            }
                          </h1>
                          <p className="r1-p">MEETINGS PENDING</p>
                        </div>
                        <div className="col-4">
                          <img className="cardImg" src={MeetingD}></img>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="progress p-0">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: percentage(
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.completedMeetings,

                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.pendingMeeting
                              ),
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {percentage(
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedMeetings,

                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingMeeting
                            )}
                          </div>
                        </div>
                        <p className="pt-2 ps-0 color-greyLabel">
                          <strong>
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedMeetings
                            }{' '}
                          </strong>{' '}
                          {dataUserDashboardData?.getDashboardMeetingData
                            ?.completedMeetings == 1
                            ? 'Meeting is '
                            : 'Meetings are '}
                          <strong className="color-primary">Completed</strong>
                        </p>
                      </div>
                    </div>

                    {/* -------------------- */}
                    <div className="col cardborder pb-0">
                      <div className="row d-flex justify-content-center align-items-center h-60">
                        <div className="col-10 h-100 pt-3">
                          <h1 className="r1-h">
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingMom
                            }
                          </h1>
                          <p className="r1-p w-100">
                            MINUTES OF MEETING PENDING
                          </p>
                        </div>
                        <div className="col-2">
                          <img className="cardImg" src={clockD}></img>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="progress p-0">
                          <div
                            className="progress-bar bg-success "
                            role="progressbar"
                            style={{
                              width: percentage(
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.completedMom,
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.pendingMom
                              ),
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {percentage(
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedMom,
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingMom
                            )}
                          </div>
                        </div>
                        <p className="pt-2 ps-0 color-greyLabel">
                          <strong>
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedMom
                            }
                          </strong>{' '}
                          {dataUserDashboardData?.getDashboardMeetingData
                            ?.completedMom == 1
                            ? 'Meeting Minute '
                            : 'Meeting Minutes '}
                          <strong className="color-success">Completed</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col cardborder pb-0">
                      <div className="row d-flex justify-content-center align-items-center h-60">
                        <div className="col-8 h-100 pt-3">
                          <h1 className="r1-h">
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingReturn
                            }
                          </h1>
                          <p className="r1-p">RETURNS ARE PENDING</p>
                        </div>
                        <div className="col-4">
                          <img className="cardImg" src={docD}></img>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="progress p-0">
                          <div
                            className="progress-bar bg-warning "
                            role="progressbar"
                            style={{
                              width: percentage(
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.completedReturn,
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.pendingReturn
                              ),
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {percentage(
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedReturn,
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.pendingReturn
                            )}
                          </div>
                        </div>
                        <p className="pt-2 ps-0 color-greyLabel">
                          <strong>
                            {' '}
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.completedReturn
                            }
                          </strong>{' '}
                          {dataUserDashboardData?.getDashboardMeetingData
                            ?.completedReturn == 1
                            ? 'Returns is '
                            : 'Returns are '}
                          <strong className="color-warning">Completed</strong>
                        </p>
                      </div>
                    </div>

                    <div className="col cardborder pb-0">
                      <div className="row d-flex justify-content-center align-items-center h-60">
                        <div className="col-8 h-100 pt-3">
                          <h1 className="r1-h">
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.draftOfficer
                            }
                          </h1>
                          <p className="r1-p">DRAFT OFFICERS</p>
                        </div>
                        <div className="col-4">
                          <img className="cardImg" src={draftOfficer}></img>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="progress p-0">
                          <div
                            className="progress-bar "
                            role="progressbar"
                            style={{
                              width: percentage(
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.actualOfficer,
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.draftOfficer
                              ),
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {percentage(
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.actualOfficer,
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.draftOfficer
                            )}
                          </div>
                        </div>
                        <p className="pt-2 ps-0 color-greyLabel">
                          <strong>
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.actualOfficer
                            }
                          </strong>{' '}
                          {dataUserDashboardData?.getDashboardMeetingData
                            ?.actualOfficer == 1
                            ? 'Officer is '
                            : 'Officers are '}
                          <strong className="color-primary">Completed</strong>
                        </p>
                      </div>
                    </div>

                    <div className="col cardborder pb-0">
                      <div className="row d-flex justify-content-center align-items-center h-60">
                        <div className="col-8 h-100 pt-3">
                          <h1 className="r1-h">
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.draftMember
                            }
                          </h1>
                          <p className="r1-p">DRAFT MEMBERS</p>
                        </div>
                        <div className="col-4">
                          <img className="cardImg" src={draftMem}></img>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="progress p-0">
                          <div
                            className="progress-bar bg-success "
                            role="progressbar"
                            style={{
                              width: percentage(
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.actualMember,
                                dataUserDashboardData?.getDashboardMeetingData
                                  ?.draftMember
                              ),
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {percentage(
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.actualMember,
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.draftMember
                            )}
                          </div>
                        </div>
                        <p className="pt-2 ps-0 color-greyLabel">
                          <strong>
                            {
                              dataUserDashboardData?.getDashboardMeetingData
                                ?.actualMember
                            }{' '}
                          </strong>{' '}
                          {dataUserDashboardData?.getDashboardMeetingData
                            ?.actualMember == 1
                            ? 'Member is '
                            : 'Members are '}
                          <strong className="color-success">Completed</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="d-flex row pb-4 background-main-grey">
                    <div
                      ref={componentRef}
                      className="col-9"
                      style={{ height: 'fit-content' }}
                    >
                      <div className="row calborder bg-white m-0 mt-4 ">
                        <div className="row pt-4">
                          <div className="d-flex justify-content-start">
                            <i className="bx bx-calendar bx-md text-primary  me-2"></i>

                            <h5 className="RegHeading py-2">
                              Meeting Schedule
                            </h5>
                          </div>
                          <hr className=""></hr>
                        </div>
                        <FullCalendar
                          plugins={[dayGridPlugin]}
                          initialView="dayGridMonth"
                          events={events}
                          selectable={true}
                          // select={handleDateSelect}
                        />
                        <div className="row p-0">
                          {/* <Calendar
                            value={new Date()}
                            tileContent={({ date }) => {
                              const event = events.find(
                                event =>
                                  event?.start?.toDateString() ===
                                  date?.toDateString()
                              );
                              return event ? <span>{event.title}</span> : null;
                            }}
                            // onClickDay={handleDateSelect}
                          /> */}
                          {/* <Calendar
                            // height={500}
                            locale={'en'}
                            // defaultView="dayGridMonth"
                            // plugins={[
                            //   BootstrapTheme,
                            //   dayGridPlugin,
                            //   interactionPlugin,
                            // ]}
                            // slotDuration={'00:15:00'}
                            // minTime={'00:00:00'}
                            // maxTime={'23:59:59'}
                            // handleWindowResize={true}
                            // themeSystem="bootstrap5"
                            // header={{
                            //   left: 'prev,next',

                            //   center: 'title',

                            //   right: 'dayGridMonth',
                            // }}
                            events={events}
                            displayEventEnd={true}
                            dayMaxEvents={3}
                            // eventLimit={true}
                            eventTimeFormat={{
                              hour: 'numeric',

                              minute: '2-digit',

                              meridiem: 'short',
                            }}
                            eventDisplay="block"
                            editable={false}
                            droppable={false}
                            selectable={true}
                            // id={'calendar'}
                            eventContent={(eventInfo: any) => {
                              return (
                                <div>
                                  <b className="">{eventInfo.timeText}</b>
                                  <br />
                                  <b className="font-size-16">
                                    {eventInfo.event._def.title}
                                  </b>
                                </div>
                              );
                            }}
                          /> */}
                        </div>
                      </div>
                      <div className="row liborder bg-white m-0 mt-3">
                        <div className="row py-4">
                          <div className="d-flex justify-content-start">
                            <i className="bx bx-task bx-md text-primary  me-2"></i>

                            <h5 className="RegHeading py-2">Register</h5>
                          </div>
                          <hr className=""></hr>
                        </div>

                        <div className="row p-0">
                          <div className="col-12">
                            <div
                              id="carouselExampleControls"
                              className="carousel slide"
                              data-bs-ride="carousel"
                            >
                              <div className="carousel-inner carousel-inner-fix">
                                <div className="carousel-item active">
                                  <div className="d-flex  gap-4  justify-content-center align-items-center">
                                    <div className="col DashborderO minCW ">
                                      <div className="row d-flex justify-content-center align-items-center ">
                                        <img
                                          className="img Reg-img "
                                          src={task}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center subP mt-4">
                                          MEMBER REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentMemberToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentMemberToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnO minW"
                                            id="generateDocumentMemberButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              memberData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createMemberRegister
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createMemberRegister
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentMemberToolTipIsOpen
                                              }
                                              target="generateDocumentMemberButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col DashborderG minCW">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task2}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center   subP mt-3">
                                          INDEX REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentIndexToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentIndexToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnG minW"
                                            id="generateDocumentIndexButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              indexData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createIndexRegister
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createIndexRegister
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentIndexToolTipIsOpen
                                              }
                                              target="generateDocumentIndexButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col DashborderY minCW">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task3}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center subP mt-3">
                                          PROXY REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentProxyToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentProxyToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnY minW"
                                            id="generateDocumentProxyButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              proxyData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createProxyRegister
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createProxyRegister
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentProxyToolTipIsOpen
                                              }
                                              target="generateDocumentProxyButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col DashborderO minCW">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center subP mt-3">
                                          OFFICER REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentOfficerToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentOfficerToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn RejbtnR minW"
                                            id="generateDocumentOfficerButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              officerData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createRegisterOfOfficers
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createRegisterOfOfficers
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentOfficerToolTipIsOpen
                                              }
                                              target="generateDocumentOfficerButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="carousel-item ">
                                  <div className="d-flex gap-4 minCW maxnCW justify-content-center align-items-center">
                                    <div className="col DashborderO">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p
                                          className="d-flex justify-content-center text-center subP mt-4"
                                          style={{ whiteSpace: 'pre-line' }}
                                        >
                                          {'MINUTE BOOK \n REGISTER'}
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentMinuteBookToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentMinuteBookToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn  DashbtnO minW"
                                            id="generateDocumentMinuteBookButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              minuteBookData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createRegisterOfMinuteBook
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createRegisterOfMinuteBook
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentMinuteBookToolTipIsOpen
                                              }
                                              target="generateDocumentMinuteBookButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col DashborderG">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task2}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center  subP mt-4">
                                          TRANSFER OF SHARES REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentShareTransferToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentShareTransferToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnG minW"
                                            id="generateDocumentShareTransferButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              shareTransferData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createTransferRegister
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createTransferRegister
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentShareTransferToolTipIsOpen
                                              }
                                              target="generateDocumentShareTransferButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col DashborderY">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task3}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center subP mt-4">
                                          PURCHASE OF SHARES REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-2 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentSharePurchaseToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentSharePurchaseToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnY mt-2 minW"
                                            id="generateDocumentSharePurchaseButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              purchaseShareData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createRegisterOfPurchaseOfShares
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createRegisterOfPurchaseOfShares
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.error)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentSharePurchaseToolTipIsOpen
                                              }
                                              target="generateDocumentSharePurchaseButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col RejborderO">
                                      <div className="row d-flex justify-content-center align-items-center">
                                        <img
                                          className="img Reg-img"
                                          src={task}
                                        ></img>
                                      </div>
                                      <div className="row">
                                        <p className="d-flex justify-content-center text-center subP mt-4 mx-0 px-0">
                                          SHARES OF DIRECTOR REGISTER
                                        </p>

                                        <div
                                          className={`d-flex mt-3 justify-content-center gap-3 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.REGISTER,
                                                PermissionCategoryEnum.GENERATE_DOCUMENTS
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'cursor-not-allowed'
                                              : ''
                                          }`}
                                          onMouseEnter={() =>
                                            setGenerateDocumentDirectorToolTipIsOpen(
                                              true
                                            )
                                          }
                                          onMouseLeave={() =>
                                            setGenerateDocumentDirectorToolTipIsOpen(
                                              false
                                            )
                                          }
                                        >
                                          <button
                                            className="btn DashbtnO minW"
                                            id="generateDocumentDirectorButton"
                                            disabled={
                                              !(
                                                GetPermissionsOfUser(
                                                  selectedUserPermissions,
                                                  ModuleEnum.REGISTER,
                                                  PermissionCategoryEnum.GENERATE_DOCUMENTS
                                                ) ||
                                                userType ===
                                                  UserTypeEnum.COMPANY_ADMIN
                                              )
                                            }
                                            onClick={() => {
                                              directorShareData({
                                                variables: {
                                                  companyId: companyID,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      ?.createRegisterOfDirectorShares
                                                  ) {
                                                    window.open(
                                                      res?.data
                                                        ?.createRegisterOfDirectorShares
                                                        ?.url,
                                                      'new'
                                                    );
                                                  } else {
                                                    dispatch(
                                                      setToastError(res.errors)
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }}
                                          >
                                            View
                                          </button>
                                          {!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.REGISTER,
                                              PermissionCategoryEnum.GENERATE_DOCUMENTS
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={
                                                generateDocumentDirectorToolTipIsOpen
                                              }
                                              target="generateDocumentDirectorButton"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                className="carousel-control-prev btn-w"
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="prev"
                              >
                                <span
                                  className="bx bx-caret-left color-primary bx-md position"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">
                                  Previous
                                </span>
                              </button>
                              <button
                                className="carousel-control-next btn-w btn-car-end"
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="next"
                              >
                                <span
                                  className="bx bx-caret-right color-primary bx-md position"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-3 pb-3 overflow-scroll"
                      style={{
                        maxHeight: `${componentHeight}px`,
                      }}
                    >
                      <div className="row liborder bg-white m-0 mt-3 w-100 h-100">
                        <div className="d-flex flex-column m-0 p-0 ">
                          <div className="d-flex justify-content-start pt-3 ps-2">
                            <img className="nlogo" src={nLogo}></img>
                            <h5 className="RegHeading pt-3 ps-3 me-2">
                              Action Item Tracking
                            </h5>
                          </div>
                          <hr className="mb-0"></hr>
                          <div>
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                            {notifications?.findNotifications?.edges.map(
                              (edge: any, index: any) => (
                                <div
                                  className={`liborder RegHeading  overflow-wrap  text-break rounded-0 ${
                                    edge?.node?.status ? 'card-status ' : ''
                                  }`}
                                  key={index}
                                >
                                  <div
                                    className="d-flex justify-content-between p-3"
                                    onClick={() => {
                                      setExpandedRow(
                                        index == expandedRow ? -1 : index
                                      );
                                    }}
                                  >
                                    <div className="ps-2 pe-1">
                                      <h5> {edge?.node?.title}</h5>
                                      <p className="n-subP">
                                        {format(edge?.node?.createdAt)}
                                      </p>
                                    </div>

                                    <i
                                      className={
                                        expandedRow == index
                                          ? 'bx bxs-left-arrow iconS'
                                          : 'bx bxs-down-arrow iconS '
                                      }
                                    ></i>
                                  </div>
                                  <div className="subcardborder subitems d-flex flex-column">
                                    {expandedRow == index &&
                                      edge?.node?.tasks?.map(
                                        (task: any, i: any) => (
                                          <div
                                            className="border-top"
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 d-flex justify-content-start text-center ${
                                                task.status ? '' : 'text-danger'
                                              }`}
                                            >
                                              {
                                                <i
                                                  className={
                                                    task.status
                                                      ? 'bx bxs-check-circle color-success bx-sm pe-2  '
                                                      : 'bx bxs-error-alt bx-sm pe-2'
                                                  }
                                                ></i>
                                              }
                                              {task.message}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
