import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_PLANS = gql`
  query getAllSubscriptionPlans {
    getAllSubscriptionPlans {
      edges {
        node {
          id
          duration
          costPerUser
          costPerCompany
          companyBaseCost
          intermedialUserBaseCost
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation deleteSubscriptionPlan($id: String!) {
    deleteSubscriptionPlan(data: { id: $id }) {
      count
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan(
    $id: String!
    $duration: String!
    $costPerUser: Float!
    $costPerCompany: Float!
    $companyBaseCost: Float!
    $intermedialUserBaseCost: Float!
  ) {
    updateSubscriptionPlan(
      data: {
        id: $id
        data: {
          duration: $duration
          costPerUser: $costPerUser
          costPerCompany: $costPerCompany
          companyBaseCost: $companyBaseCost
          intermedialUserBaseCost: $intermedialUserBaseCost
        }
      }
    ) {
      subscriptionPlan {
        duration
        costPerUser
        costPerCompany
        companyBaseCost
        intermedialUserBaseCost
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation createSubscriptionPlan(
    $duration: String!
    $costPerUser: Float!
    $costPerCompany: Float!
    $companyBaseCost: Float!
    $intermedialUserBaseCost: Float!
  ) {
    createSubscriptionPlan(
      data: {
        duration: $duration
        costPerUser: $costPerUser
        costPerCompany: $costPerCompany
        companyBaseCost: $companyBaseCost
        intermedialUserBaseCost: $intermedialUserBaseCost
      }
    ) {
      subscriptionPlan {
        duration
        costPerUser
        costPerCompany
        companyBaseCost
        intermedialUserBaseCost
      }
    }
  }
`;

export const FIND_SUBSCRIPTION_PLAN = gql`
  query findOneSubscriptionPlan($id: ID!) {
    findOneSubscriptionPlan(id: $id) {
      subscriptionPlan {
        duration
        costPerUser
        costPerCompany
        companyBaseCost
        intermedialUserBaseCost
      }
    }
  }
`;

export const FIND_USER_SUBSCRIPTION = gql`
  query findOneUserSubscription($userId: ID!) {
    findOneUserSubscription(userId: $userId) {
      userSubscription {
        id
        userId
        status
        startDate
        endDate
        subscriptionPlan {
          id
          duration
          costPerUser
          costPerCompany
          companyBaseCost
          intermedialUserBaseCost
        }
        pendingAmount
        paidAmount
        numOfUsers
        numOfCompanies
        totalAmount
      }
    }
  }
`;

export const CREATE_USER_SUBSCRIPTION = gql`
  mutation createUserSubscription(
    $userId: String!
    $subscriptionPlanId: String!
    $totalAmount: Float!
    $numOfUsers: Int
    $numOfCompanies: Int
  ) {
    createUserSubscription(
      data: {
        userId: $userId
        subscriptionPlanId: $subscriptionPlanId
        numOfUsers: $numOfUsers
        numOfCompanies: $numOfCompanies
        totalAmount: $totalAmount
      }
    ) {
      userSubscription {
        id
      }
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
  mutation updateUserSubscription(
    $userId: String!
    $subscriptionPlanId: String!
    $numOfUsers: Int
    $numOfCompanies: Int
  ) {
    updateUserSubscription(
      data: {
        userId: $userId
        data: {
          numOfUsers: $numOfUsers
          numOfCompanies: $numOfCompanies
          subscriptionPlanId: $subscriptionPlanId
        }
      }
    ) {
      userSubscription {
        status
        startDate
        endDate
        subscriptionPlanId
        numOfUsers
        numOfCompanies
        totalAmount
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTION_PLANS = gql`
  query getAllSubscriptionPlans {
    getAllSubscriptionPlans {
      edges {
        node {
          id
          duration
          costPerUser
          costPerCompany
          companyBaseCost
          intermedialUserBaseCost
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
