import { useMutation } from '@apollo/client';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { SAVE_DEVICE_TOKEN } from './Store/graphQL/Notification';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: 'AIzaSyAYfKca49wlu3Q_nQ1s2L-kQe13QD2XpkY',
  authDomain: 'essp-dev-ac225.firebaseapp.com',
  projectId: 'essp-dev-ac225',
  storageBucket: 'essp-dev-ac225.appspot.com',
  messagingSenderId: '495172640888',
  appId: '1:495172640888:web:742470c6f85cab779b4d8d',
};
const app = firebase.initializeApp(firebaseConfig);
firebase.analytics(app);
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const getToken: any = async (setTokenFound: any) => {
  //   return getToken(messaging, { vapidKey: 'GENERATED_MESSAGING_KEY' })
  //     .then((currentToken: any) => {
  //       if (currentToken) {
  //         console.log('current token for client: ', currentToken);
  //         setTokenFound(true);
  //         // Track the token -> client mapping, by sending to backend server
  //         // show on the UI that permission is secured
  //       } else {
  //         console.log(
  //           'No registration token available. Request permission to generate one.'
  //         );
  //         setTokenFound(false);
  //         // shows on the UI that permission is required
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //       // catch error while creating client token
  //     });
  let currentToken = '';
  try {
    // to do
    //load key from .env file
    currentToken = await messaging.getToken({
      vapidKey:
        'BI1Nva6jmESG09pvP0UPwJc1YIy-rQnTvwU1bySRwjv_OTArOAQWTG2I872tuVGZ0uYSIM89kig6qcFDQw2ZwJM',
    });

    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (e) {
    console.log('An error occured while retrieving token ', e);
  }
  return currentToken;
};
export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });
