import React, { FC, memo, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from '~/Routes';
import AuthLayout from './components/AuthLayout';
import Notifications from './components/Notifications/Notifications';
import ReactNotificationComponent from './components/Notifications/ReactNotification';
import { onMessageListener } from './firebase';
import { useAppDispatch, useAppSelector } from './Store/hooks';
import { setNotificationsInDropdown } from './Store/reducers/notification';

export const App: FC = () => {
  const { notificationsArray } = useAppSelector(
    state => state.notificationReducer
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  onMessageListener()
    .then((payload: any) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      const temp = JSON.parse(JSON.stringify(notificationsArray));
      temp.push(payload.notification);
      dispatch(setNotificationsInDropdown(temp));
    })
    .catch(err => {
      console.log('Failed ', err);
    });
  return (
    <React.Fragment>
      <div>
        {show && notification?.title && <Notify notification={notification} />}
        <Notifications />
      </div>
      <Router>
        <AuthLayout>
          <MyRoutes />
        </AuthLayout>
      </Router>
    </React.Fragment>
  );
};
export const Notify = memo(({ notification }: any) => {
  return (
    <ReactNotificationComponent
      title={notification.title}
      body={notification.body}
      createdAt={notification.createdAt}
    />
  );
});
