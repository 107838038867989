import { DesignationEnum } from '../../commons/enums';
const GetCompanyOfficerDesignation = (designation: string) => {
  if (designation === DesignationEnum.ADVISOR_CONSULTANT) {
    return 'Advisor Consultant';
  } else if (designation === DesignationEnum.AUDITOR) {
    return 'Auditor';
  } else if (designation === DesignationEnum.CHAIRMAN) {
    return 'Chairman';
  } else if (designation === DesignationEnum.CHIEF_ACCOUNTANT) {
    return 'Chief Accountant';
  } else if (designation === DesignationEnum.CHIEF_EXECUTIVE) {
    return 'Chief Executive';
  } else if (designation === DesignationEnum.COMPANY_SECRETARY) {
    return 'Company Sectary';
  } else if (designation === DesignationEnum.DECLARANT) {
    return 'Declarant';
  } else if (designation === DesignationEnum.DIRECTOR) {
    return 'Director';
  } else if (designation === DesignationEnum.DIRECTOR_AND_SUBSCRIBER) {
    return 'Director and Subscriber';
  } else if (designation === DesignationEnum.FINANCIAL_OFFICER) {
    return 'Financial Officer';
  } else if (designation === DesignationEnum.LEGAL_ADVISOR) {
    return 'Legal Advisor';
  } else if (designation === DesignationEnum.MANAGING_AGENT) {
    return 'Managing Agent';
  }
};

export default GetCompanyOfficerDesignation;
