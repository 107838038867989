import { MeetingStatus } from '../../commons/enums';
const GetMeetingStatus = (status: string) => {
  if (status === MeetingStatus.ADJOURNED) {
    return 'Adjourned';
  } else if (status === MeetingStatus.CANCELLED) {
    return 'Cancelled';
  } else if (status === MeetingStatus.CONCLUDED) {
    return 'Concluded';
  } else if (status === MeetingStatus.CREATED) {
    return 'Created';
  } else if (status === MeetingStatus.SCHEDULED) {
    return 'Scheduled';
  }
};

export default GetMeetingStatus;
