import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import 'react-multi-email/style.css';

import {
  FIND_TRANSFER_SHARE_BY_ID,
  GET_NEXT_TRANSFER_NO,
  TRANSFER_SHARE,
  UPDATE_TRANSFER_SHARE,
} from '~/Store/graphQL/Shares';
import NumberBlockImg from '/images/number-blocks.png';
import CalendarImg from '/images/calendar.png';
import {
  ModuleEnum,
  ShareTransferLodgingPersonTypeEnum,
} from '~/commons/enums';
import {
  CREATE_COMPANY_MEMBER_DRAFT,
  GET_ALL_FOLIO_NO_OF_COMPANY,
} from '~/Store/graphQL/CompanyMembers';
import { useSearchParams } from 'react-router-dom';
import {
  setMemberViewScreen,
  setSelectedMemberId,
} from '~/Store/reducers/companyMembers';
import { FIND_COMPANY_PROFILE_BY_ID } from '~/Store/graphQL/CompanyProfile';
import {
  setSelectedShareId,
  setSelectedShareIdForTransaction,
} from '~/Store/reducers/share';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
export const TransferOfShare: FC = () => {
  const {
    selectedShareId,
    shareView,
    selectedShareIdForTransaction,
    selectedShareTransferId,
  } = useAppSelector(state => state.shareReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareData, setShareData] = useState<any>();
  const [folioNumbers, setFolioNumbers] = useState<any>();
  const [perFaceValue, setPerFaceValue] = useState<number>();
  const [considerationPerShare, setConsiderationPerShare] = useState<number>();
  const [stampTicket, setStampTicket] = useState<number>();
  const newFolioNo = searchParams.get('newFolio');

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/shares',
          title: 'Share Management',
        },
        {
          url: location.pathname,
          title: 'Transfer Of Share',
        },
      ])
    );
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting transfer share information
  const [
    findTransferShareById,
    {
      loading: loadingFindTransferShareById,
      error: errorFindTransferShareById,
      data: dataFindTransferShareById,
    },
  ] = useLazyQuery<any>(FIND_TRANSFER_SHARE_BY_ID);
  //   update transfer share
  const [
    updateTransferShare,
    {
      loading: loadingUpdateTransferShare,
      error: errorUpdateTransferShare,
      data: dataUpdateTransferShare,
    },
  ] = useMutation<any>(UPDATE_TRANSFER_SHARE);
  // transfer share
  const [
    transferShare,
    {
      loading: loadingTransferShare,
      error: errorTransferShare,
      data: dataTransferShare,
    },
  ] = useMutation<any>(TRANSFER_SHARE);
  // Get all folio numbers of company members
  const [
    getAllFolioNoOfCompany,
    {
      loading: loadingGetAllFolioNoOfCompany,
      error: errorGetAllFolioNoOfCompany,
      data: dataGetAllFolioNoOfCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_FOLIO_NO_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
  });
  //Create draft company member
  const [
    createDraftCompanyMember,
    {
      loading: loadingCreateDraftCompanyMember,
      error: errorCreateDraftCompanyMember,
      data: dataCreateDraftCompanyMember,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_DRAFT);
  // Get next transfer number query
  const [
    getNextTransferNo,
    {
      loading: loadingGetNextTransferNo,
      error: errorGetNextTransferNo,
      data: dataGetNextTransferNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_TRANSFER_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // Getting company information
  const [
    findCompanyProfileById,
    {
      loading: loadingFindCompanyProfileById,
      error: errorFindCompanyProfileById,
      data: dataFindCompanyProfileById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_ID);
  useEffect(() => {
    if (selectedShareId) {
      findTransferShareById({
        variables: {
          id: selectedShareTransferId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareId]);
  useEffect(() => {
    if (dataFindTransferShareById) {
      setShareData(dataFindTransferShareById?.findOneTransferShare);
    } else if (errorFindTransferShareById) {
      console.log('errorFindTransferShareById:', errorFindTransferShareById);
    }
  }, [dataFindTransferShareById, errorFindTransferShareById]);
  useEffect(() => {
    if (companyID) {
      getAllFolioNoOfCompany({
        variables: {
          id: companyID,
        },
        fetchPolicy: 'no-cache',
      });
      findCompanyProfileById({
        variables: {
          id: companyID,
        },
      });
    }
  }, [companyID]);
  useEffect(() => {
    if (dataGetAllFolioNoOfCompany) {
      setFolioNumbers(
        dataGetAllFolioNoOfCompany?.getAllFolioNumbersOfCompany?.folioNumbers
      );
    } else if (errorGetAllFolioNoOfCompany) {
      console.log('errorGetAllFolioNoOfCompany:', errorGetAllFolioNoOfCompany);
    }
  }, [dataGetAllFolioNoOfCompany, errorGetAllFolioNoOfCompany]);
  useEffect(() => {
    if (dataFindCompanyProfileById) {
      setPerFaceValue(
        dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
          ?.perFaceValue
      );
    } else if (errorFindCompanyProfileById) {
      setToastError(errorFindCompanyProfileById);
    }
  }, [dataFindCompanyProfileById, errorFindCompanyProfileById]);
  useEffect(() => {
    if (newFolioNo) {
      setFolioNumbers((prev: any) => [...(prev || []), folioNumbers]);
      validation.setFieldValue('folioNumber', newFolioNo);
    }
  }, [newFolioNo]);
  useEffect(() => {
    if (perFaceValue && considerationPerShare) {
      let partialPerFaceValue = (10 * 1.375) / 100;
      if (partialPerFaceValue >= considerationPerShare) {
        validation.setFieldValue('stampTicket', partialPerFaceValue);
        setStampTicket(partialPerFaceValue);
      } else {
        validation.setFieldValue('stampTicket', considerationPerShare);
        setStampTicket(considerationPerShare);
      }
    }
  }, [perFaceValue, considerationPerShare]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      considerationPerShare: shareData?.considerationPerShare
        ? shareData?.considerationPerShare
        : '',
      dateOfLodgmentOfTransfer: shareData?.lodgmentDate
        ? moment(shareData?.lodgmentDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      personLodgingTheTransfer: shareData?.personLodgingTransfer
        ? shareData?.personLodgingTransfer
        : '',
      nameOfTransferor: shareData?.transferorName
        ? shareData?.transferorName
        : '',
      approvalDate: shareData?.approvalDate
        ? moment(shareData?.approvalDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      stampTicket: shareData?.stampTicket ? shareData?.stampTicket : '',
      transferNumber: shareData?.transferNo ? shareData?.transferNo : '',
      transferFolio: shareData?.TransfereeFolio
        ? shareData?.TransfereeFolio
        : '',
      nameOfTransferee: shareData?.TransfereeName
        ? shareData?.TransfereeName
        : '',
    },
    validationSchema: Yup.object({
      considerationPerShare: Yup.number()
        .required('Please enter consideration per share')
        .nullable(),
      dateOfLodgmentOfTransfer: Yup.string()
        .required('Please select date of lodgment of transfer')
        .nullable(),
      personLodgingTheTransfer: Yup.string()
        .required('Please select person lodging the transfer')
        .nullable(),
      nameOfTransferor: Yup.string()
        .required('Please enter name of transferor')
        .nullable(),
      approvalDate: Yup.string()
        .required('Please enter select approval date')
        .nullable(),
      stampTicket: Yup.string()
        .required('Please enter stamp ticket')
        .nullable(),
      transferNumber: Yup.number()
        .required('Please enter transfer number')
        .nullable(),
      transferFolio: Yup.number()
        .required('Please enter transfer folio')
        .nullable(),
      nameOfTransferee: Yup.string()
        .required('Please enter name of transferee')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (shareData && selectedShareId && !shareView) {
        updateTransferShare({
          variables: {
            id: selectedShareIdForTransaction,
            shareId: selectedShareId,
            stampTicket: values.stampTicket,
            approvalDate: values.approvalDate,
            personLodgingTransfer: values.personLodgingTheTransfer,
            considerationPerShare: values.considerationPerShare,
            lodgmentDate: values.dateOfLodgmentOfTransfer,
            TransfereeFolio: parseInt(values.transferFolio),
            transferorName: values.nameOfTransferor,
            TransfereeName: values.nameOfTransferee,
            transferNo: values.transferNumber,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateTransferShare?.shareId) {
              dispatch(setToastSuccess('Share updated!'));
              dispatch(setSelectedShareId(null));
              dispatch(setSelectedShareIdForTransaction(null));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        transferShare({
          variables: {
            shareId: selectedShareIdForTransaction,
            stampTicket: values.stampTicket,
            approvalDate: values.approvalDate,
            personLodgingTransfer: values.personLodgingTheTransfer,
            considerationPerShare: values.considerationPerShare,
            lodgmentDate: values.dateOfLodgmentOfTransfer,
            TransfereeFolio: parseInt(values.transferFolio),
            transferorName: values.nameOfTransferor,
            TransfereeName: values.nameOfTransferee,
          },
        })
          .then((res: any) => {
            if (res?.data?.transferShare?.shareId) {
              dispatch(setToastSuccess('Share Transferred!'));
              dispatch(setSelectedShareId(null));
              dispatch(setSelectedShareIdForTransaction(null));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindTransferShareById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 background-white">
          <div className="d-flex justify-content-end background-primary-medium">
            <button
              type="button"
              className="btn dangerButton d-flex align-items-center m-3 fw-bold"
              disabled={loadingTransferShare || loadingUpdateTransferShare}
              onMouseDown={() => {
                navigate('/shares');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-bold primaryDismissButton d-flex align-items-center m-3 mx-0"
              disabled={loadingTransferShare || loadingUpdateTransferShare}
              onMouseDown={() => {
                navigate('/shares/transactions');
              }}
            >
              Back
            </button>
            {!shareView && (
              <button
                type="button"
                className="btn successButton d-flex align-items-center m-3 fw-bold"
                disabled={loadingTransferShare || loadingUpdateTransferShare}
                onMouseDown={() => {
                  validation.handleSubmit();
                }}
              >
                {(loadingTransferShare || loadingUpdateTransferShare) && (
                  <i className="bx bx-loader-circle bx-spin me-1" />
                )}
                Save
                <i className="bx bx-upload mx-1" />
              </button>
            )}
          </div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div className="row mt-4 d-flex gap-2">
                <div className="col-auto">
                  <div className="d-flex justify-content-center align-items-center primary-medium-border-div">
                    <img className="mx-4" src={NumberBlockImg}></img>
                    <div className="form-group pt-3">
                      <div className="d-flex ">
                        <label htmlFor="transferFolio">Transferee Folio*</label>
                        {validation.touched.transferFolio &&
                        validation.errors.transferFolio ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.transferFolio}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <div className="d-flex justify-content-start align-items-center">
                          <select
                            className={`form-control form-sm w-auto ${
                              validation.touched.transferFolio &&
                              validation.errors.transferFolio &&
                              'is-invalid'
                            }`}
                            id="transferFolio"
                            name="transferFolio"
                            placeholder="Transferee Folio"
                            value={validation.values.transferFolio}
                            disabled={shareView}
                            onChange={e => {
                              validation.setFieldValue(
                                'transferFolio',
                                e.target.value
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {folioNumbers?.map(
                              (folioNumber: any, index: number) => (
                                <option value={folioNumber} key={index}>
                                  {folioNumber}
                                </option>
                              )
                            )}
                          </select>
                          {!shareView ? (
                            <button
                              type="button"
                              className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold"
                              disabled={loadingCreateDraftCompanyMember}
                              onMouseDown={() => {
                                dispatch(setMemberViewScreen(false));
                                createDraftCompanyMember({
                                  variables: {
                                    companyId: companyID,
                                  },
                                })
                                  .then((res: any) => {
                                    if (
                                      res.data.draft_createCompanyMemberDraft
                                        .draftCompanyMember
                                    ) {
                                      dispatch(
                                        setSelectedMemberId(
                                          res.data
                                            .draft_createCompanyMemberDraft
                                            .draftCompanyMember.memberId
                                        )
                                      );
                                      navigate(
                                        '/companyMembers/primaryShareHolderInformation?isNewFolioForTransfer=true'
                                      );
                                    }
                                  })
                                  .catch(err => {
                                    dispatch(setToastError(err));
                                  });
                              }}
                            >
                              New Folio
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto flex-grow-1">
                  <div className="d-flex justify-content-start align-items-center success-border-div w-100">
                    <img className="ms-4" src={CalendarImg}></img>
                    <div className="form-group pt-3 mx-5">
                      <div className="d-flex">
                        <label
                          htmlFor="dateOfLodgmentOfTransfer"
                          className="model-input-heading"
                        >
                          Date of Lodgment of Transfer*
                        </label>
                        {validation.touched.dateOfLodgmentOfTransfer &&
                        validation.errors.dateOfLodgmentOfTransfer ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfLodgmentOfTransfer}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control  w-100 ${
                          validation.touched.dateOfLodgmentOfTransfer &&
                          validation.errors.dateOfLodgmentOfTransfer &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="dateOfLodgmentOfTransfer"
                        name="dateOfLodgmentOfTransfer"
                        placeholder="Date of Lodgment of Transfer"
                        value={validation.values.dateOfLodgmentOfTransfer}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group pt-3">
                      <div className="d-flex ">
                        <label
                          htmlFor="approvalDate"
                          className="model-input-heading"
                        >
                          Approval Date*
                        </label>
                        {validation.touched.approvalDate &&
                        validation.errors.approvalDate ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.approvalDate}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control  w-100 ${
                          validation.touched.approvalDate &&
                          validation.errors.approvalDate &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="approvalDate"
                        name="approvalDate"
                        placeholder="Approval Date"
                        value={validation.values.approvalDate}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-4 pt-4 backgroung-light-grey">
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="considerationPerShare"
                        className="model-input-heading"
                      >
                        Consideration per Share*
                      </label>
                      {validation.touched.considerationPerShare &&
                      validation.errors.considerationPerShare ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.considerationPerShare}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control w-100 ${
                        validation.touched.considerationPerShare &&
                        validation.errors.considerationPerShare &&
                        'is-invalid'
                      }`}
                      disabled={shareView}
                      id="considerationPerShare"
                      name="considerationPerShare"
                      placeholder="Consideration per share"
                      value={validation.values.considerationPerShare}
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={e => {
                        setConsiderationPerShare(parseInt(e.target.value));
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="personLodgingTheTransfer">
                        Person Lodging the Transfer*
                      </label>
                      {validation.touched.personLodgingTheTransfer &&
                      validation.errors.personLodgingTheTransfer ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.personLodgingTheTransfer}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.personLodgingTheTransfer &&
                          validation.errors.personLodgingTheTransfer &&
                          'is-invalid'
                        }`}
                        id="personLodgingTheTransfer"
                        name="personLodgingTheTransfer"
                        placeholder="Person Lodging The Transfer"
                        value={validation.values.personLodgingTheTransfer}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option
                          value={ShareTransferLodgingPersonTypeEnum.BROKER}
                        >
                          Broker
                        </option>
                        <option
                          value={ShareTransferLodgingPersonTypeEnum.AGENT}
                        >
                          Agent
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="nameOfTransferor">
                        Name of Transferor*
                      </label>
                      {validation.touched.nameOfTransferor &&
                      validation.errors.nameOfTransferor ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.nameOfTransferor}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium w-100 ${
                        validation.touched.nameOfTransferor &&
                        validation.errors.nameOfTransferor &&
                        'is-invalid'
                      }`}
                      disabled={shareView}
                      id="nameOfTransferor"
                      name="nameOfTransferor"
                      placeholder="Name of transferor"
                      value={validation.values.nameOfTransferor}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="nameOfTransferee"
                        className="model-input-heading"
                      >
                        Name of Transferee*
                      </label>
                      {validation.touched.nameOfTransferee &&
                      validation.errors.nameOfTransferee ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.nameOfTransferee}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium w-100${
                        validation.touched.nameOfTransferee &&
                        validation.errors.nameOfTransferee &&
                        'is-invalid'
                      }`}
                      id="nameOfTransferee"
                      name="nameOfTransferee"
                      placeholder="Name of transferee"
                      value={validation.values.nameOfTransferee}
                      disabled={shareView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-4 pt-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="transferNumber"
                        className="model-input-heading"
                      >
                        Transfer Number*
                      </label>
                      {validation.touched.transferNumber &&
                      validation.errors.transferNumber ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.transferNumber}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control w-100 ${
                          validation.touched.transferNumber &&
                          validation.errors.transferNumber &&
                          'is-invalid'
                        }`}
                        id="transferNumber"
                        name="transferNumber"
                        placeholder="Transfer Number"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={validation.values.transferNumber}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                      {!shareView ? (
                        <button
                          type="button"
                          className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold"
                          disabled={loadingGetNextTransferNo}
                          onMouseDown={() => {
                            getNextTransferNo()
                              .then((res: any) => {
                                if (res?.data?.getTransferNumber?.transferNo) {
                                  validation.setFieldValue(
                                    'transferNumber',
                                    res?.data?.getTransferNumber?.transferNo
                                  );
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          }}
                        >
                          Next No
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="stampTicket">Stamp Ticket*</label>
                      {validation.touched.stampTicket &&
                      validation.errors.stampTicket ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.stampTicket}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control  w-100 ${
                        validation.touched.stampTicket &&
                        validation.errors.stampTicket &&
                        'is-invalid'
                      }`}
                      id="stampTicket"
                      name="stampTicket"
                      placeholder="Stamp ticket"
                      value={
                        validation.values.stampTicket
                          ? validation.values.stampTicket
                          : stampTicket
                      }
                      disabled={shareView}
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
