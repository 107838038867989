import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import 'react-multi-email/style.css';
import {
  ModuleEnum,
  ShareAllotmentMethodEnum,
  ShareCertificateTypeEnum,
  ShareIssueTypeEnum,
  ShareSubTypeEnum,
  ShareTypeEnum,
} from '~/commons/enums';
import {
  ALLOT_SHARE,
  FIND_SHARE_BY_ID,
  GET_NEXT_DISTINCTIVE_NO_FROM,
  GET_NEXT_SHARE_CERTIFICATE_NO,
  UPDATE_SHARE,
} from '~/Store/graphQL/Shares';
import NumberBlockImg from '/images/number-blocks.png';
import CalendarImg from '/images/calendar.png';
import {
  CREATE_COMPANY_MEMBER_DRAFT,
  GET_ALL_FOLIO_NO_OF_COMPANY,
} from '~/Store/graphQL/CompanyMembers';
import { useSearchParams } from 'react-router-dom';
import {
  setMemberViewScreen,
  setSelectedMemberId,
} from '~/Store/reducers/companyMembers';
import { FIND_COMPANY_PROFILE_BY_ID } from '~/Store/graphQL/CompanyProfile';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const AllotmentOfShare: FC = () => {
  const { selectedShareId, shareView } = useAppSelector(
    state => state.shareReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareData, setShareData] = useState<any>();
  const [folioNumbers, setFolioNumbers] = useState<any>();
  const newFolioNo = searchParams.get('newFolio');

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/shares',
          title: 'Share Management',
        },
        {
          url: location.pathname,
          title: 'Allotment Of Share',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting share information
  const [
    findShareById,
    {
      loading: loadingFindShareById,
      error: errorFindShareById,
      data: dataFindShareById,
    },
  ] = useLazyQuery<any>(FIND_SHARE_BY_ID);
  //   update share
  const [
    updateShare,
    {
      loading: loadingUpdateShare,
      error: errorUpdateShare,
      data: dataUpdateShare,
    },
  ] = useMutation<any>(UPDATE_SHARE);
  // allot new share
  const [
    allotShare,
    {
      loading: loadingAllotShare,
      error: errorAllotShare,
      data: dataAllotShare,
    },
  ] = useMutation<any>(ALLOT_SHARE);
  // Get next share certificate number query
  const [
    getNextShareCertificateNo,
    {
      loading: loadingGetNextShareCertificateNo,
      error: errorGetNextShareCertificateNo,
      data: dataGetNextShareCertificateNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_SHARE_CERTIFICATE_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // Get next distinctive no from query
  const [
    getNextDistinctiveNoFrom,
    {
      loading: loadingGetNextDistinctiveNoFrom,
      error: errorGetNextDistinctiveNoFrom,
      data: dataGetNextDistinctiveNoFrom,
    },
  ] = useLazyQuery<any>(GET_NEXT_DISTINCTIVE_NO_FROM, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // Get all folio numbers of company members
  const [
    getAllFolioNoOfCompany,
    {
      loading: loadingGetAllFolioNoOfCompany,
      error: errorGetAllFolioNoOfCompany,
      data: dataGetAllFolioNoOfCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_FOLIO_NO_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
  });
  //Create draft company member
  const [
    createDraftCompanyMember,
    {
      loading: loadingCreateDraftCompanyMember,
      error: errorCreateDraftCompanyMember,
      data: dataCreateDraftCompanyMember,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_DRAFT);
  // Getting company information
  const [
    findCompanyProfileById,
    {
      loading: loadingFindCompanyProfileById,
      error: errorFindCompanyProfileById,
      data: dataFindCompanyProfileById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_ID);
  useEffect(() => {
    if (selectedShareId) {
      findShareById({
        variables: {
          id: selectedShareId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareId]);
  useEffect(() => {
    if (dataFindShareById) {
      setShareData(dataFindShareById?.findOneShare?.share);
    } else if (errorFindShareById) {
      console.log('errorFindShareById:', errorFindShareById);
    }
  }, [dataFindShareById, errorFindShareById]);
  useEffect(() => {
    if (companyID) {
      getAllFolioNoOfCompany({
        variables: {
          id: companyID,
        },
        fetchPolicy: 'no-cache',
      });
      findCompanyProfileById({
        variables: {
          id: companyID,
        },
      });
    }
  }, [companyID]);
  useEffect(() => {
    if (dataGetAllFolioNoOfCompany) {
      setFolioNumbers(
        dataGetAllFolioNoOfCompany?.getAllFolioNumbersOfCompany?.folioNumbers
      );
    } else if (errorGetAllFolioNoOfCompany) {
      console.log('errorGetAllFolioNoOfCompany:', errorGetAllFolioNoOfCompany);
    }
  }, [dataGetAllFolioNoOfCompany, errorGetAllFolioNoOfCompany]);
  useEffect(() => {
    if (newFolioNo) {
      setFolioNumbers((prev: any) => [...(prev || []), folioNumbers]);
      validation.setFieldValue('folioNumber', newFolioNo);
    }
  }, [newFolioNo]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      allotmentDate: shareData?.AllotmentDate
        ? moment(shareData?.AllotmentDate).format('YYYY-MM-DD')
        : moment(
            dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
              ?.incorporationDate
          ).format('YYYY-MM-DD'),
      dateOfIssue: shareData?.issueDate
        ? moment(shareData?.issueDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      shareType: shareData?.shareType ? shareData?.shareType : '',
      shareSubType: shareData?.shareSubType ? shareData?.shareSubType : '',
      certificateNo: shareData?.shareCertificateNo
        ? shareData?.shareCertificateNo
        : '',
      numOfShares: shareData?.noOfShares ? shareData?.noOfShares : '',
      certificateType: shareData?.certificateType
        ? shareData?.certificateType
        : '',
      folioNumber: shareData?.folioNo ? shareData?.folioNo : '',
      distinctiveNumFrom: shareData?.DistinctiveNoFrom
        ? shareData?.DistinctiveNoFrom
        : '',
      distinctiveNumTo: shareData?.DistinctiveNoTo
        ? shareData?.DistinctiveNoTo
        : '',
      shareIssueType: shareData?.issueType
        ? shareData?.issueType
        : ShareIssueTypeEnum.CASH,
      premiumOrDiscountPerShare: shareData?.discountOrPremium
        ? shareData?.discountOrPremium
        : 0,
      allotmentMethod: shareData?.allotmentMethod
        ? shareData?.allotmentMethod
        : ShareAllotmentMethodEnum.FIRST_ISSUE,
    },
    validationSchema: Yup.object({
      allotmentDate: Yup.string()
        .required('Please select allotment date')
        .nullable(),
      dateOfIssue: Yup.string()
        .required('Please select date of issue')
        .nullable(),
      shareType: Yup.string().required('Please select share type').nullable(),
      shareSubType: Yup.string()
        .required('Please select share sub type')
        .nullable(),
      numOfShares: Yup.number()
        .required('Please enter number of shares')
        .nullable(),
      certificateType: Yup.string()
        .required('Please select certificate type')
        .nullable(),
      folioNumber: Yup.string()
        .required('Please enter folio number')
        .nullable(),
      certificateNo: Yup.number()
        .required('Please enter certificate number')
        .nullable(),
      distinctiveNumFrom: Yup.number()
        .required('Please enter distinctive number from')
        .nullable(),
      distinctiveNumTo: Yup.number()
        .required('Please enter distinctive number to')
        .nullable(),
      shareIssueType: Yup.string()
        .required('Please select share issue type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (shareData && selectedShareId && !shareView) {
        updateShare({
          variables: {
            id: selectedShareId,
            folioNo: parseInt(values.folioNumber),
            AllotmentDate: values.allotmentDate,
            issueDate: values.dateOfIssue,
            shareType: values.shareType,
            shareSubType: values.shareSubType,
            noOfShares: values.numOfShares,
            certificateType: values.certificateType,
            issueType: values.shareIssueType,
            allotmentMethod: values.allotmentMethod,
            discountOrPremium: parseInt(values.premiumOrDiscountPerShare),
          },
        })
          .then((res: any) => {
            if (res?.data?.updateShare?.share) {
              dispatch(setToastSuccess('Share updated!'));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        allotShare({
          variables: {
            companyId: companyID,
            folioNo: parseInt(values.folioNumber),
            AllotmentDate: values.allotmentDate,
            issueDate: values.dateOfIssue,
            shareType: values.shareType,
            shareSubType: values.shareSubType,
            noOfShares: values.numOfShares,
            certificateType: values.certificateType,
            issueType: values.shareIssueType,
            allotmentMethod: values.allotmentMethod,
            discountOrPremium: parseInt(values.premiumOrDiscountPerShare),
          },
        })
          .then((res: any) => {
            if (res?.data?.createShare?.share) {
              dispatch(setToastSuccess('Share allotted!'));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindShareById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 background-white">
          <div className="d-flex justify-content-end background-primary-medium">
            <button
              type="button"
              className="btn dangerButton d-flex align-items-center m-3 mx-0 fw-bold me-3"
              disabled={loadingAllotShare || loadingUpdateShare}
              onMouseDown={() => {
                navigate('/shares');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-bold primaryDismissButton d-flex align-items-center m-3 mx-0 me-3"
              disabled={loadingAllotShare || loadingUpdateShare}
              onMouseDown={() => {
                navigate('/shares/transactions');
              }}
            >
              Back
            </button>
            {!shareView && (
              <button
                type="button"
                className="btn successButton d-flex align-items-center m-3 ms-0 fw-bold"
                disabled={loadingAllotShare || loadingUpdateShare}
                onMouseDown={() => {
                  validation.handleSubmit();
                }}
              >
                {(loadingAllotShare || loadingUpdateShare) && (
                  <i className="bx bx-loader-circle bx-spin me-1" />
                )}
                Save
                <i className="bx bx-upload mx-1" />
              </button>
            )}
          </div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div className="row d-flex">
                <div className="col-4 d-flex justify-content-evenly align-items-center primary-medium-border-div gap-1">
                  <div className="p-1  ">
                    <img src={NumberBlockImg}></img>
                  </div>
                  <div className="pt-1 form-group pt-3">
                    <div className="d-flex w-autox">
                      <label htmlFor="folioNumber">Folio Number*</label>
                      {validation.touched.folioNumber &&
                      validation.errors.folioNumber ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.folioNumber}
                        </p>
                      ) : null}
                    </div>

                    <div className="d-flex justify-content-start align-items-center  ">
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control form-sm w-100 ${
                            validation.touched.folioNumber &&
                            validation.errors.folioNumber &&
                            'is-invalid'
                          }`}
                          id="folioNumber"
                          name="folioNumber"
                          placeholder="Folio Number"
                          value={validation.values.folioNumber}
                          disabled={shareView}
                          onChange={e => {
                            validation.setFieldValue(
                              'folioNumber',
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {folioNumbers?.map(
                            (folioNumber: any, index: number) => (
                              <option value={folioNumber} key={index}>
                                {folioNumber}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  {!shareView ? (
                    <button
                      type="button"
                      className="btn primaryDismissButton d-flex align-items-center mt-4 fw-bold  oneline text-wrap "
                      onMouseDown={() => {
                        dispatch(setMemberViewScreen(false));
                        createDraftCompanyMember({
                          variables: {
                            companyId: companyID,
                          },
                        })
                          .then((res: any) => {
                            if (
                              res.data.draft_createCompanyMemberDraft
                                .draftCompanyMember
                            ) {
                              dispatch(
                                setSelectedMemberId(
                                  res.data.draft_createCompanyMemberDraft
                                    .draftCompanyMember.memberId
                                )
                              );
                              navigate(
                                '/companyMembers/primaryShareHolderInformation?isNewFolio=true'
                              );
                            }
                          })
                          .catch(err => {
                            dispatch(setToastError(err));
                          });
                      }}
                    >
                      New Folio
                    </button>
                  ) : null}
                </div>

                <div className="col-8">
                  <div className="row d-flex justify-content-center align-items-center success-border-div ms-1">
                    <div className="col-2">
                      <img className="ms-4 " src={CalendarImg}></img>
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="form-group col-6 pt-3 ">
                          <div className="">
                            <label
                              htmlFor="allotmentDate"
                              className="model-input-heading"
                            >
                              Allotment Date*
                            </label>
                            {validation.touched.allotmentDate &&
                            validation.errors.allotmentDate ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.allotmentDate}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="date"
                            className={`form-control w-autox ${
                              validation.touched.allotmentDate &&
                              validation.errors.allotmentDate &&
                              'is-invalid'
                            }`}
                            disabled={shareView}
                            id="allotmentDate"
                            name="allotmentDate"
                            placeholder="Allotment Date"
                            value={validation.values.allotmentDate}
                            onChange={e => {
                              validation.handleChange(e);
                            }}
                          />
                        </div>
                        <div className="form-group col-6 pt-3 ">
                          <div className="">
                            <label
                              htmlFor="dateOfIssue"
                              className="model-input-heading"
                            >
                              Date Of Issue*
                            </label>
                            {validation.touched.dateOfIssue &&
                            validation.errors.dateOfIssue ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.dateOfIssue}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="date"
                            className={`form-control w-autox  ${
                              validation.touched.dateOfIssue &&
                              validation.errors.dateOfIssue &&
                              'is-invalid'
                            }`}
                            disabled={shareView}
                            id="dateOfIssue"
                            name="dateOfIssue"
                            placeholder="Date Of Issue"
                            value={validation.values.dateOfIssue}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={e => {
                              validation.handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 pt-4 background-dim-grey mt-3">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="certificateNo">
                        Share Certificate No*
                      </label>
                      {validation.touched.certificateNo &&
                      validation.errors.certificateNo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.certificateNo}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.certificateNo &&
                          validation.errors.certificateNo &&
                          'is-invalid'
                        }`}
                        id="certificateNo"
                        name="certificateNo"
                        placeholder="Share Certificate No"
                        value={
                          validation.values.certificateNo
                            ? validation.values.certificateNo
                            : dataGetNextShareCertificateNo
                                ?.getShareCertificateNumber?.shareCertificateNo
                        }
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                      {shareView || (shareData && selectedShareId) ? null : (
                        <button
                          type="button"
                          className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold oneline"
                          disabled={loadingGetNextShareCertificateNo}
                          onMouseDown={() => {
                            getNextShareCertificateNo()
                              .then((res: any) => {
                                if (
                                  res?.data?.getShareCertificateNumber
                                    ?.shareCertificateNo
                                ) {
                                  validation.setFieldValue(
                                    'certificateNo',
                                    res?.data?.getShareCertificateNumber
                                      ?.shareCertificateNo
                                  );
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          }}
                        >
                          Next No
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="numOfShares">No Of Shares*</label>
                      {validation.touched.numOfShares &&
                      validation.errors.numOfShares ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.numOfShares}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control w-autox  ${
                        validation.touched.numOfShares &&
                        validation.errors.numOfShares &&
                        'is-invalid'
                      }`}
                      id="numOfShares"
                      name="numOfShares"
                      placeholder="No Of Shares"
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={validation.values.numOfShares}
                      disabled={shareView || (shareData && selectedShareId)}
                      onChange={e => {
                        validation.handleChange(e);
                        if (dataGetNextDistinctiveNoFrom) {
                          validation.setFieldValue(
                            'distinctiveNumTo',
                            dataGetNextDistinctiveNoFrom?.getDistinctiveNumber
                              ?.DistinctiveNoFrom +
                              validation.values.numOfShares -
                              1
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className=" col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="shareType">Share Type*</label>
                      {validation.touched.shareType &&
                      validation.errors.shareType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.shareType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-autox ${
                          validation.touched.shareType &&
                          validation.errors.shareType &&
                          'is-invalid'
                        }`}
                        id="shareType"
                        name="shareType"
                        placeholder="Share Type"
                        value={validation.values.shareType}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={ShareTypeEnum.PREFERENCE_SHARE}>
                          Preference Share
                        </option>
                        <option value={ShareTypeEnum.ORDINARY_SHARE}>
                          Ordinary Share
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="shareSubType">Share Sub-Type*</label>
                      {validation.touched.shareSubType &&
                      validation.errors.shareSubType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.shareSubType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-autox ${
                          validation.touched.shareSubType &&
                          validation.errors.shareSubType &&
                          'is-invalid'
                        }`}
                        id="shareSubType"
                        name="shareSubType"
                        placeholder="Share Sub-Type"
                        value={validation.values.shareSubType}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        {validation.values.shareType ==
                        ShareTypeEnum.PREFERENCE_SHARE ? (
                          <>
                            <option
                              value={
                                ShareSubTypeEnum.PARTICIPATING_ACCUMULATIVE
                              }
                            >
                              Participating Accumulative
                            </option>
                            <option
                              value={
                                ShareSubTypeEnum.NON_PARTICIPATING_PREFERENCE
                              }
                            >
                              Non Participating Accumulative
                            </option>
                            <option
                              value={ShareSubTypeEnum.PARTICIPATING_PREFERENCE}
                            >
                              Participating Preference
                            </option>
                            <option
                              value={
                                ShareSubTypeEnum.PARTICIPATING_NON_ACCUMULATIVE
                              }
                            >
                              Participating Non Preference
                            </option>
                            <option value={ShareSubTypeEnum.CLASS_1A_SHARE}>
                              Class 1/A Share
                            </option>
                            <option value={ShareSubTypeEnum.CLASS_2B_SHARE}>
                              Class 2/B Share
                            </option>
                          </>
                        ) : validation.values.shareType ==
                          ShareTypeEnum.ORDINARY_SHARE ? (
                          <>
                            <option value={ShareSubTypeEnum.OTHER}>
                              Other
                            </option>
                          </>
                        ) : null}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 pt-4">
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="distinctiveNumFrom"
                        className="model-input-heading"
                      >
                        Distinctive No: From*
                      </label>
                      {validation.touched.distinctiveNumFrom &&
                      validation.errors.distinctiveNumFrom ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNumFrom}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control w-autox  ${
                          validation.touched.distinctiveNumFrom &&
                          validation.errors.distinctiveNumFrom &&
                          'is-invalid'
                        }`}
                        id="distinctiveNumFrom"
                        name="distinctiveNumFrom"
                        placeholder="Distinctive No: From"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={
                          validation.values.distinctiveNumFrom
                            ? validation.values.distinctiveNumFrom
                            : dataGetNextDistinctiveNoFrom?.getDistinctiveNumber
                                ?.DistinctiveNoFrom
                        }
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                      {shareView || (shareData && selectedShareId) ? null : (
                        <button
                          type="button"
                          className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold oneline"
                          disabled={loadingGetNextDistinctiveNoFrom}
                          onMouseDown={() => {
                            getNextDistinctiveNoFrom()
                              .then((res: any) => {
                                if (
                                  res?.data?.getDistinctiveNumber
                                    ?.DistinctiveNoFrom
                                ) {
                                  validation.setFieldValue(
                                    'distinctiveNumFrom',
                                    res?.data?.getDistinctiveNumber
                                      ?.DistinctiveNoFrom
                                  );
                                  validation.setFieldValue(
                                    'distinctiveNumTo',
                                    res?.data?.getDistinctiveNumber
                                      ?.DistinctiveNoFrom +
                                      validation.values.numOfShares -
                                      1
                                  );
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          }}
                        >
                          Next No
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="distinctiveNumTo"
                        className="model-input-heading"
                      >
                        Distinctive No: To*
                      </label>
                      {validation.touched.distinctiveNumTo &&
                      validation.errors.distinctiveNumTo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNumTo}
                        </p>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        type="number"
                        className={`form-control w-autox ${
                          validation.touched.distinctiveNumTo &&
                          validation.errors.distinctiveNumTo &&
                          'is-invalid'
                        }`}
                        id="distinctiveNumTo"
                        name="distinctiveNumTo"
                        placeholder="Distinctive No: To"
                        min="0"
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={
                          dataGetNextDistinctiveNoFrom?.getDistinctiveNumber
                            ?.DistinctiveNoFrom
                            ? dataGetNextDistinctiveNoFrom?.getDistinctiveNumber
                                ?.DistinctiveNoFrom +
                              validation.values.numOfShares -
                              1
                            : validation.values.distinctiveNumFrom +
                              validation.values.numOfShares -
                              1
                        }
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="shareIssueType">Share Issue Type*</label>
                      {validation.touched.shareIssueType &&
                      validation.errors.shareIssueType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.shareIssueType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-autox ${
                          validation.touched.shareIssueType &&
                          validation.errors.shareIssueType &&
                          'is-invalid'
                        }`}
                        id="shareIssueType"
                        name="shareIssueType"
                        placeholder="Share Issue Type"
                        value={validation.values.shareIssueType}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value={ShareIssueTypeEnum.CASH}>Cash</option>
                        <option value={ShareIssueTypeEnum.OTHER_THAN_CASH}>
                          Other than cash
                        </option>
                        <option value={ShareIssueTypeEnum.BONUS}>Bonus</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="certificateType">Certificate Type*</label>
                      {validation.touched.certificateType &&
                      validation.errors.certificateType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.certificateType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-autox ${
                          validation.touched.certificateType &&
                          validation.errors.certificateType &&
                          'is-invalid'
                        }`}
                        id="certificateType"
                        name="certificateType"
                        placeholder="Certificate Type"
                        value={validation.values.certificateType}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={ShareCertificateTypeEnum.MARKET_LOT}>
                          Market Lot/Fixed
                        </option>
                        <option value={ShareCertificateTypeEnum.JUMBO_LOT}>
                          Denomination/Jumbo Lot
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 pt-4">
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="allotmentMethod">Allotment Method*</label>
                      {validation.touched.allotmentMethod &&
                      validation.errors.allotmentMethod ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.allotmentMethod}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-autox ${
                          validation.touched.allotmentMethod &&
                          validation.errors.allotmentMethod &&
                          'is-invalid'
                        }`}
                        id="allotmentMethod"
                        name="allotmentMethod"
                        placeholder="Allotment Method"
                        value={validation.values.allotmentMethod}
                        disabled={shareView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={ShareAllotmentMethodEnum.FIRST_ISSUE}>
                          First Issue
                        </option>
                        <option value={ShareAllotmentMethodEnum.RIGHT_ISSUE}>
                          Right Issue
                        </option>
                        <option
                          value={
                            ShareAllotmentMethodEnum.OTHER_THAN_RIGHT_ISSUE
                          }
                        >
                          Other Than Right Issue
                        </option>
                        <option
                          value={
                            ShareAllotmentMethodEnum.EMPLOYEE_STOCK_OPTION_SCHEME
                          }
                        >
                          Employee Stock Option Scheme
                        </option>
                        <option
                          value={
                            ShareAllotmentMethodEnum.CONVERSION_OF_LOAN_INTO_SHARE
                          }
                        >
                          Conversion Of Loan Into Share
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="premiumOrDiscountPerShare"
                        className="model-input-heading"
                      >
                        Premium/Discount Per Share
                      </label>
                      {validation.touched.premiumOrDiscountPerShare &&
                      validation.errors.premiumOrDiscountPerShare ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.premiumOrDiscountPerShare}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control w-autox  ${
                        validation.touched.premiumOrDiscountPerShare &&
                        validation.errors.premiumOrDiscountPerShare &&
                        'is-invalid'
                      }`}
                      id="premiumOrDiscountPerShare"
                      name="premiumOrDiscountPerShare"
                      placeholder="Premium/Discount Per Share"
                      value={validation.values.premiumOrDiscountPerShare}
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      disabled={shareView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
