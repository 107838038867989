import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { useNavigate } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { FIND_COMPANY_PROFILE_BY_USER_ID } from '~/Store/graphQL/CompanyProfile';
import { ModuleEnum, UserTypeEnum } from '~/commons/enums';
import Swal from 'sweetalert2';
import { ConfirmationDialogue } from '../Toasts';
import { setToastError } from '~/Store/reducers/layout';
import { Link } from 'react-router-dom';
import { setSelectedHelp } from '~/Store/reducers/help';

const SubMenu = ({ item }: any) => {
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const { breadCrumb } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [subnav, setSubnav] = useState(true);
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);
  useEffect(() => {
    findCompanyByUserId({
      variables: {
        id: userId,
        isIntermedialUser:
          userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
      },
    });
  }, []);
  return (
    <>
      <div className="row">
        <div
          className={`col-1 ${
            breadCrumb[breadCrumb?.length - 1]?.url === item.path &&
            'background-success'
          }`}
        ></div>
        <div className="col-11 ps-0">
          <div
            id="sideBarLink"
            onClick={() => {
              dispatch(setSelectedHelp(item?.id));
              if (
                userType !== UserTypeEnum.SUPER_ADMIN &&
                !item.path.includes('companyProfile') &&
                !item.path.includes('dashboard')
              ) {
                findCompanyByUserId({
                  variables: {
                    id: userId,
                    isIntermedialUser:
                      userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
                  },
                  fetchPolicy: 'no-cache',
                })
                  .then((res: any) => {
                    if (
                      res?.data?.findCompanyProfileByUserId?.companyProfile
                        ?.isDraft
                    ) {
                      ConfirmationDialogue.fire({
                        title: 'Yours company profile is not completed yet',
                        text:
                          'Please complete company profile to access this feature',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Complete Profile',
                        cancelButtonText: 'Cancel',
                        reverseButtons: true,
                      }).then(result => {
                        if (result.isConfirmed) {
                          navigate('/companyProfile/companyInformation');
                        } else if (
                          /* Read more about handling dismissals below */
                          result.dismiss === Swal.DismissReason.cancel
                        ) {
                          console.log('Cancelled');
                        }
                      });
                    } else {
                      navigate(item.path);
                    }
                  })
                  .catch(err => {
                    dispatch(setToastError(err));
                  });
              } else {
                navigate(item.path);
              }
            }}
            className={`cursor-pointer ${
              breadCrumb[breadCrumb?.length - 1]?.url === item.path ||
              (item.path === '/intermediary' &&
                breadCrumb[breadCrumb?.length - 1]?.url ===
                  '/intermediary/append') ||
              (item.path === '/shares' &&
                breadCrumb[breadCrumb?.length - 1]?.url ===
                  '/shares/transactions')
                ? 'active'
                : ''
            }`}
          >
            <div
              id=""
              className={` ${
                breadCrumb[breadCrumb?.length - 1]?.url === item.path ||
                (item.path === '/shares' &&
                  breadCrumb[breadCrumb?.length - 1]?.url ===
                    '/shares/transactions')
                  ? 'd-flex'
                  : 'd-none'
              } align-items-center text-nowrap m-1 p-1 fw-bold`}
            >
              {item.greenIcon}
              {item.title}
            </div>
            <div
              id=""
              className={`hover-title ${
                breadCrumb[breadCrumb?.length - 1]?.url === item.path ||
                (item.path === '/shares' &&
                  breadCrumb[breadCrumb?.length - 1]?.url ===
                    '/shares/transactions')
                  ? 'd-none'
                  : 'd-flex'
              } align-items-center text-nowrap m-1 p-1 fw-bold`}
            >
              {item.whiteIcon}
              {item.title}
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </div>
        </div>
      </div>

      {/* {subnav &&
        item.subNav?.map((item: any, index: number) => {
          return (
            <Link
              to={item.path}
              key={index}
              className={`subnav ${
                breadCrumb[breadCrumb?.length - 1]?.url === item.path &&
                'active'
              }`}
            >
              <div className="d-flex align-items-center text-nowrap mb-1">
                {item.icon}
                {item.title}
              </div>
            </Link>
          );
        })} */}
    </>
  );
};
export default SubMenu;
