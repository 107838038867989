import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  FIND_COMPANY_MEMBER_BY_ID,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';

export const Instructions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [membersData, setMembersData] = useState<any>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Instructions',
        },
      ])
    );
  }, []);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);
  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      otherInstructions: membersData ? membersData.otherInstructions : '',
      sendingNotesInstructions: membersData ? membersData.instructions : '',
    },
    validationSchema: Yup.object({}),
    onSubmit: (values: any) => {
      if (isDraft) {
        draftUpdateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            otherInstructions: values.otherInstructions,
            instructions: values.sendingNotesInstructions,
          },
        })
          .then((res: any) => {
            if (res.data.draft_updateCompanyMemberDraft.draftCompanyMember) {
              dispatch(setToastSuccess('Company member draft updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/jointShareholderInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            otherInstructions: values.otherInstructions,
            instructions: values.sendingNotesInstructions,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyMember?.companyMember) {
              dispatch(setToastSuccess('Company Member updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/jointShareholderInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div id="mainComponent">
            {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
            <div className="inputFields">
              <div className="form-group">
                <div className="d-flex">
                  <label htmlFor="otherInstructions">
                    Particular of Dividend Mandates, Power of Attorney and Other
                    Instructions
                  </label>
                  {validation.touched.otherInstructions &&
                  validation.errors.otherInstructions ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.otherInstructions}
                    </p>
                  ) : null}
                </div>
                <textarea
                  className={`form-control ${
                    validation.touched.otherInstructions &&
                    validation.errors.otherInstructions &&
                    'is-invalid'
                  }`}
                  id="otherInstructions"
                  name="otherInstructions"
                  placeholder="Details here ..."
                  value={validation.values.otherInstructions}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.otherInstructions &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.otherInstructions &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            otherInstructions:
                              validation.values.otherInstructions,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex">
                  <label htmlFor="sendingNotesInstructions">
                    Instructions for Sending Notices
                  </label>
                  {validation.touched.sendingNotesInstructions &&
                  validation.errors.sendingNotesInstructions ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.sendingNotesInstructions}
                    </p>
                  ) : null}
                </div>
                <textarea
                  className={`form-control ${
                    validation.touched.sendingNotesInstructions &&
                    validation.errors.sendingNotesInstructions &&
                    'is-invalid'
                  }`}
                  id="sendingNotesInstructions"
                  name="sendingNotesInstructions"
                  placeholder="Details here ..."
                  value={validation.values.sendingNotesInstructions}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.sendingNotesInstructions &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.sendingNotesInstructions &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            instructions:
                              validation.values.sendingNotesInstructions,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    if (isNewFolio) {
                      navigate('/shares/add');
                    } else if (isNewFolioForTransfer) {
                      navigate('/shares/add/transfer');
                    } else if (isNewFolioForTransmission) {
                      navigate('/shares/add/transmission');
                    } else {
                      navigate('/companyMembers');
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    if (isNewFolio) {
                      navigate(
                        '/companyMembers/membershipInformation?isNewFolio=true'
                      );
                    } else if (isNewFolioForTransfer) {
                      navigate(
                        '/companyMembers/membershipInformation?isNewFolioForTransfer=true'
                      );
                    } else {
                      navigate('/companyMembers/membershipInformation');
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary primaryButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    memberView
                      ? navigate('/companyMembers/jointShareholderInformation')
                      : validation.handleSubmit();
                  }}
                >
                  {(loadingDraftUpdateCompanyMember ||
                    loadingupdateCompanyMember) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  {memberView ? 'Next' : 'Save & Next'}
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </>
      )}
    </React.Fragment>
  );
};
