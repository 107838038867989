import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import {
  MemberTypeEnum,
  RelationshipEnum,
  MemberCategoryEnum,
  ModuleEnum,
} from '../../../../commons/enums';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import {
  DRAFT_DELETE_COMPANY_MEMBER,
  FIND_COMPANY_MEMBER_BY_ID,
  GET_NEXT_FOLIO_NO,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';
import { useSearchParams } from 'react-router-dom';
import {
  AddressIcon,
  CategoryIcon,
  ContactInfoIcon,
  PersonalInfoLogo,
} from '~/assets/images';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import 'react-phone-input-2/lib/style.css';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { setSelectedMemberId } from '~/Store/reducers/companyMembers';
import NumberBlockImg from '/images/number-blocks.png';
import {
  FIND_ALL_COMPANY_OFFICERS,
  FIND_COMPANY_OFFICER_BY_ID,
} from '~/Store/graphQL/CompanyOfficer';

export const PrimaryShareHolderInfo: FC = () => {
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [companyOfficers, setCompanyOfficers] = useState<any>();
  const [membersData, setMembersData] = useState<any>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const [countries, setCountries] = useState<ICountry>();
  const [states, setStates] = useState<IState>();
  const [cities, setCities] = useState<ICity>();
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [selectedState, setSelectedState] = useState<number>();
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  useEffect(() => {
    setCountries(Country.getAllCountries());

    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Personal Information',
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedState, selectedCountry]);

  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  // Delete draft company member
  const [
    draftDeleteCompanyMember,
    {
      loading: loadingDraftDeleteCompanyMember,
      error: errorDraftDeleteCompanyMember,
      data: dataDraftDeleteCompanyMember,
    },
  ] = useMutation<any>(DRAFT_DELETE_COMPANY_MEMBER);
  // Get next folio number query
  const [
    getFolioNo,
    {
      loading: loadingGetFolioNo,
      error: errorGetFolioNo,
      data: dataGetFolioNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_FOLIO_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findAllCompanyOfficers,
    {
      loading: loadingFindllCompanyOfficers,
      error: errorFindAllCompanyOfficers,
      data: dataFindAllCompanyOfficers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_OFFICERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findCompanyOfficerById,
    {
      loading: loadingFindCompanyOfficerById,
      error: errorFindCompanyOfficerById,
      data: dataFindCompanyOfficerById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_OFFICER_BY_ID);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      secpRefId: membersData ? membersData.secpRefId : '',
      folioNumber: membersData ? membersData.folioNo : '',
      memberName: {
        firstName: membersData ? membersData.firstName : '',
        middleName: membersData ? membersData.middleName : '',
        lastName: membersData ? membersData.lastName : '',
      },
      memberType: membersData ? membersData.type : '',
      memberCategory: membersData ? membersData.category : '',
      relationship: membersData ? membersData.relationship : '',
      nationality: membersData ? membersData.nationality : '',
      fatherSpouseName: {
        firstName: membersData ? membersData.relativeFirstName : '',
        middleName: membersData ? membersData.relativeMiddleName : '',
        lastName: membersData ? membersData.relativeLastName : '',
      },
      passportNumber: membersData ? membersData.passportNumber : '',
      cnicNumber: membersData ? membersData.cnic : '',
      cnicExpiry: membersData?.cnicExpiry
        ? new Date(membersData.cnicExpiry).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      businessOccupation: membersData ? membersData.occupation : '',
      cuinNumber: membersData ? membersData.companyCuin : '',
      legalPersonNumber: membersData ? membersData.legalPersonName : '',
      numberOfShares: membersData ? membersData.numOfShares : '',
      holdingCompany: membersData?.holdingCompany ? true : false,
      subsidiaries: membersData?.subsidiaries ? true : false,

      // ////
      residentialAddress: membersData ? membersData.residentialAddress : '',
      city: membersData ? membersData.city : '',
      province: membersData ? membersData.province : '',
      country: membersData ? membersData.country : '',
      postalCode: membersData ? membersData.postalCode : '',
      mobile: membersData && membersData?.phone ? membersData?.phone : '',
      ntn: membersData ? membersData.ntn : '',
      email: membersData ? membersData.email : '',
      alreadyOfficer: false,
      selectedOfficerId: '',
    },
    validationSchema: Yup.object({
      folioNumber: Yup.string()
        .required('Please select folio number')
        .nullable(),
      memberName: Yup.object()
        .shape({
          firstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          lastName: Yup.string().required('Please enter last name').nullable(),
        })
        .nullable(),
      memberType: Yup.string().required('Please select member type').nullable(),
      memberCategory: Yup.string()
        .required('Please select member category')
        .nullable(),
      relationship: Yup.string()
        .required('Please select relationship')
        .nullable(),
      nationality: Yup.string().required('Please enter nationality').nullable(),
      fatherSpouseName: Yup.object()
        .shape({
          firstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          lastName: Yup.string().required('Please enter last name').nullable(),
        })
        .nullable(),
      passportNumber: Yup.string().nullable(),
      cnicNumber: Yup.string()
        .matches(
          /^\d{5}-\d{7}-\d$/,
          'CNIC must be in the format xxxxx-xxxxxxx-x'
        )

        .required('Please enter CNIC number')

        .nullable(),
      cnicExpiry: Yup.string()
        .required('Please select date of expiry of cnic')
        .nullable(),
      // ///
      residentialAddress: Yup.string()
        .required('Please enter residential address')
        .nullable(),
      city: Yup.string().required('Please enter city').nullable(),
      province: Yup.string().required('Please enter province').nullable(),
      country: Yup.string().required('Please enter country').nullable(),
      postalCode: Yup.string().required('Please enter postal code').nullable(),
      mobile: Yup.string()
        .required('Please enter Telephone')
        .min(11, 'Telephone must contains atleast 11 numbers')
        .nullable(),
      email: Yup.string()
        .required('Please enter email')
        .email('Incorrect email')
        .nullable(),

      // ntn: Yup.string().required('Please enter ntn').nullable(),
      // businessOccupation: Yup.string()
      //   .required('Please enter business occupation')
      //   .nullable(),
      // cuinNumber: Yup.string()
      //   .required('Please enter cuin number')
      //   .nullable()
      // .when(
      //   validation.values.memberCategory === MemberCategoryEnum.LEGAL_PERSON,
      //   {
      //     is: true,
      //     then: Yup.string().required('Must enter email address'),
      //   }
      // ),
      // legalPersonNumber: Yup.string()
      //   .required('Please enter legal person number')
      //   .nullable(),
      // numberOfShares: Yup.number()
      //   .required('Please enter number of shares')
      //   .nullable(),
    }),
    onSubmit: (values: any) => {
      if (isDraft) {
        draftUpdateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            secpRefId: values.secpRefId ? values.secpRefId.toString() : null,
            firstName: values.memberName.firstName,
            middleName: values.memberName.middleName,
            lastName: values.memberName.lastName,
            email: values.email,
            type: values.memberType,
            category: values.memberCategory,
            relationship: values.relationship,
            nationality: values.nationality,
            relativeFirstName: values.fatherSpouseName.firstName,
            relativeMiddleName: values.fatherSpouseName.middleName,
            relativeLastName: values.fatherSpouseName.lastName,
            passportNumber: values.passportNumber,
            cnic: values.cnicNumber,
            cnicExpiry: values.cnicExpiry,
            occupation: values.businessOccupation,
            legalPersonName: values.legalPersonNumber,
            companyCuin: values.cuinNumber,
            numOfShares: parseFloat(values.numberOfShares),
            holdingCompany: values.holdingCompany,
            subsidiaries: values.subsidiaries,
            // //
            residentialAddress: values.residentialAddress,
            city: values.city,
            province: values.province,
            country: values.country,
            postalCode: values.postalCode,
            phone: values.mobile.toString(),
            ntn: values.ntn,
          },
        })
          .then((res: any) => {
            if (res.data.draft_updateCompanyMemberDraft.draftCompanyMember) {
              dispatch(setToastSuccess('Company member draft updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/primaryShareHolderAddress?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/membershipInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            secpRefId: values.secpRefId ? values.secpRefId.toString() : null,
            firstName: values.memberName.firstName,
            middleName: values.memberName.middleName,
            lastName: values.memberName.lastName,
            email: values.email,
            type: values.memberType,
            category: values.memberCategory,
            relationship: values.relationship,
            nationality: values.nationality,
            relativeFirstName: values.fatherSpouseName.firstName,
            relativeMiddleName: values.fatherSpouseName.middleName,
            relativeLastName: values.fatherSpouseName.lastName,
            passportNumber: values.passportNumber,
            cnic: values.cnicNumber,
            cnicExpiry: values.cnicExpiry,
            occupation: values.businessOccupation,
            legalPersonName: values.legalPersonNumber,
            companyCuin: values.cuinNumber,
            numOfShares: parseFloat(values.numberOfShares),
            holdingCompany: values.holdingCompany,
            subsidiaries: values.subsidiaries,
            // //
            residentialAddress: values.residentialAddress,
            city: values.city,
            province: values.province,
            country: values.country,
            postalCode: values.postalCode,
            phone: values.mobile.toString(),
            ntn: values.ntn,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyMember?.companyMember) {
              dispatch(setToastSuccess('Company Member updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/primaryShareHolderAddress?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/membershipInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  useEffect(() => {
    if (validation.values.selectedOfficerId) {
      findCompanyOfficerById({
        variables: {
          id: validation.values.selectedOfficerId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [validation.values.selectedOfficerId]);
  useEffect(() => {
    if (dataFindCompanyOfficerById) {
      // setCompanyOfficerData(
      //   dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
      // );

      let officerName = {
        firstName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.firstName,
        middleName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.middleName,
        lastName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.lastName,
      };
      let officerRelativeName = {
        firstName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.relativeFirstName,
        middleName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.relativeMiddleName,
        lastName:
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.relativeLastName,
      };
      validation.setFieldValue('memberName', officerName);
      validation.setFieldValue('fatherSpouseName', officerRelativeName);
      validation.setFieldValue(
        'relationship',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.relationship
      );
      validation.setFieldValue(
        'nationality',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.nationality
      );
      validation.setFieldValue(
        'passportNumber',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.passportNumber
      );
      validation.setFieldValue(
        'cnicNumber',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer?.cnic
      );
      validation.setFieldValue(
        'cnicExpiry',
        new Date(
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer?.cnicExpiry
        )
          .toISOString()
          .slice(0, 10)
      );
      validation.setFieldValue(
        'businessOccupation',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.otherOccupation
      );
      validation.setFieldValue(
        'ntn',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer?.ntn
      );
      validation.setFieldValue(
        'country',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.country
      );
      validation.setFieldValue(
        'province',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.province
      );
      validation.setFieldValue(
        'city',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer?.city
      );
      validation.setFieldValue(
        'postalCode',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.postalCode
      );
      validation.setFieldValue(
        'residentialAddress',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.residentialAddress
      );
      validation.setFieldValue(
        'email',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer?.email
      );
      validation.setFieldValue(
        'mobile',
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.telephone
      );
      let country: ICountry = countries?.find(
        (x: ICountry) =>
          x.name ===
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.country
      );
      if (country) {
        setSelectedCountry(country.isoCode);
      }
    } else if (errorFindCompanyOfficerById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyOfficerById);
    }
  }, [dataFindCompanyOfficerById, errorFindCompanyOfficerById]);
  useEffect(() => {
    if (companyID) {
      findAllCompanyOfficers();
    }
  }, []);
  useEffect(() => {
    if (dataFindAllCompanyOfficers) {
      setCompanyOfficers(
        dataFindAllCompanyOfficers?.findAllCompanyOfficers?.edges
      );
    } else if (errorFindAllCompanyOfficers) {
      console.log('errorFindAllCompanyOfficers', errorFindAllCompanyOfficers);
      dispatch(setToastError(errorFindAllCompanyOfficers));
    }
  }, [dataFindAllCompanyOfficers, errorFindAllCompanyOfficers]);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);
  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );
      if (
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      ) {
        setIsDraft(
          dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
            ?.isDraft
        );
      } else {
        setIsDraft(false);
      }
      if (
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.country
      ) {
        let country: ICountry = countries?.find(
          (x: ICountry) =>
            x.name ===
            dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
              .country
        );
        if (country) {
          setSelectedCountry(country.isoCode);
        }
      }
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);

  return (
    <React.Fragment>
      {loadingRelevantProcesses ||
      loadingReliventDocumnets ||
      loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 d-flex parent">
            <div className="w-100 bg-white">
              <div
                id="mainComponentHeading"
                className="background-primary-medium "
              >
                <span className="ms-auto">
                  <div className="d-flex gap-3 end-0 mx-4">
                    <button
                      type="button"
                      className="btn fw-bold dangerButton d-flex align-items-center "
                      disabled={
                        loadingFindCompanyMemberById ||
                        loadingDraftUpdateCompanyMember ||
                        loadingupdateCompanyMember
                      }
                      onMouseDown={() => {
                        if (
                          dataFindCompanyMemberById?.findOneCompanyMember
                            ?.companyMember
                        ) {
                          let tempObj: any = validation.values;
                          tempObj.holdingCompany = null;
                          tempObj.subsidiaries = null;
                          tempObj.fatherSpouseName = null;
                          tempObj.memberName = null;
                          tempObj.cnicExpiry = null;
                          tempObj.alreadyOfficer = null;
                          const isEmpty = Object.values(tempObj).every(
                            (x: any) => x === null || x === ''
                          );
                          if (isEmpty) {
                            draftDeleteCompanyMember({
                              variables: {
                                id: selectedMemberId,
                              },
                            })
                              .then((res: any) => {
                                if (
                                  res.data.draft_deleteCompanyMemberDraft.count
                                ) {
                                  dispatch(setSelectedMemberId(''));
                                  navigate('/companyMembers');
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          } else {
                            dispatch(setSelectedMemberId(''));
                            navigate('/companyMembers');
                          }
                          if (isNewFolio) {
                            navigate('/shares/add');
                          } else if (isNewFolioForTransfer) {
                            navigate('/shares/add/transfer');
                          } else {
                            navigate('/companyMembers');
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn fw-bold successButton d-flex align-items-center "
                      disabled={
                        loadingFindCompanyMemberById ||
                        loadingDraftUpdateCompanyMember ||
                        loadingupdateCompanyMember
                      }
                      onMouseDown={() => {
                        memberView
                          ? navigate('/companyMembers/membershipInformation')
                          : validation.handleSubmit();
                      }}
                    >
                      {(loadingFindCompanyMemberById ||
                        loadingDraftUpdateCompanyMember ||
                        loadingupdateCompanyMember) && (
                        <i className="bx bx-loader-circle bx-spin me-1" />
                      )}
                      {memberView ? 'Next' : 'Save & Next'}
                      <i className="bx bx-chevron-right ms-1" />
                    </button>
                  </div>
                </span>
              </div>
              <div className="c-nav">
                <span>
                  <ul className="nav nav-tabs w-100">
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link active cursor-pointer">
                          Company Member Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/primaryShareHolderInformation'
                            )
                          }
                          className="nav-link active cursor-pointer"
                        >
                          Company Member Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link cursor-pointer">
                          Joint Shareholder Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/jointShareholderInformation'
                            )
                          }
                          className="nav-link cursor-pointer "
                        >
                          Joint Shareholder Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link  cursor-pointer">
                          Nominee Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate('/companyMembers/nomineeInformation')
                          }
                          className="nav-link  cursor-pointer"
                        >
                          Nominee Information
                        </a>
                      )}
                    </li>
                    {!isDraft && (
                      <li className="nav-item border-end flex-grow-1 text-center">
                        <a
                          onClick={() =>
                            navigate('/companyMembers/memberHistory')
                          }
                          className="nav-link cursor-pointer"
                        >
                          Member History
                        </a>
                      </li>
                    )}
                  </ul>
                </span>
              </div>

              <div className="c-subnav">
                <span>
                  <ul className="nav nav-tabs">
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link active cursor-pointer">
                          Personal Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/primaryShareHolderInformation'
                            )
                          }
                          className="nav-link active cursor-pointer"
                        >
                          Personal Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link cursor-pointer">
                          Membership Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate('/companyMembers/membershipInformation')
                          }
                          className="nav-link cursor-pointer"
                        >
                          Membership Information
                        </a>
                      )}
                    </li>
                  </ul>
                </span>
              </div>

              <div id="mainComponentNew" className="scroll-content">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
                <div className="inputFields">
                  <div className="row mb-2 w-100">
                    {isDraft ? (
                      <div
                        className={`col-2 ${
                          validation.values.alreadyOfficer === false
                            ? ' d-flex align-items-center'
                            : ''
                        }`}
                      >
                        <div className="form-group">
                          <div className="d-flex">
                            <div className="form-check m-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="alreadyOfficer"
                                name="alreadyOfficer"
                                checked={validation.values.alreadyOfficer}
                                disabled={memberView}
                                onChange={validation.handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="alreadyOfficer"
                              >
                                Already an officer?
                              </label>
                            </div>
                            {validation.touched.selectedOfficerId &&
                            validation.errors.selectedOfficerId ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.selectedOfficerId}
                              </p>
                            ) : null}
                          </div>
                          {validation.values.alreadyOfficer ? (
                            <div style={{ position: 'relative' }}>
                              <select
                                className={`form-control w-100 ${
                                  validation.touched.selectedOfficerId &&
                                  validation.errors.selectedOfficerId &&
                                  'is-invalid'
                                }`}
                                id="selectedOfficerId"
                                name="selectedOfficerId"
                                placeholder="Sectorial Classification"
                                value={validation.values.selectedOfficerId}
                                disabled={memberView}
                                onChange={validation.handleChange}
                              >
                                <option value="">Select</option>
                                {companyOfficers?.map(
                                  (officer: any, index: number) => (
                                    <option
                                      value={officer?.node?.id}
                                      key={index}
                                    >
                                      {officer?.node?.email}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    <div className="col-4">
                      <div className="form-group">
                        <div className="d-flex w-autox">
                          <label htmlFor="folioNumber">Folio No*</label>
                          {validation.touched.folioNumber &&
                          validation.errors.folioNumber ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.folioNumber}
                            </p>
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                          <input
                            type="text"
                            className={`form-control w-autox ${
                              validation.touched.folioNumber &&
                              validation.errors.folioNumber &&
                              'is-invalid'
                            }`}
                            id="folioNumber"
                            name="folioNumber"
                            placeholder="Folio No"
                            value={
                              validation.values.folioNumber
                                ? validation.values.folioNumber
                                : dataGetFolioNo?.getFolioNumber?.folioNo
                            }
                            disabled={true}
                            onChange={validation.handleChange}
                          />
                          {isDraft ? (
                            <button
                              type="button"
                              className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold width-150 oneline"
                              disabled={loadingGetFolioNo}
                              onMouseDown={() => {
                                getFolioNo()
                                  .then((res: any) => {
                                    if (res?.data?.getFolioNumber?.folioNo) {
                                      validation.setFieldValue(
                                        'folioNumber',
                                        res?.data?.getFolioNumber?.folioNo
                                      );
                                    }
                                  })
                                  .catch(err => {
                                    dispatch(setToastError(err));
                                  });
                              }}
                            >
                              Next No
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-flex namebox gap-2 justify-content-start align-items-center px-3">
                        <div>
                          <img
                            className="companyIcon me-1 my-3 bg-transparent align-items-center"
                            src={NumberBlockImg}
                          ></img>
                        </div>
                        <div className="form-group w-100">
                          <div className="d-flex">
                            <label htmlFor="secpRefId">SECP Ref ID</label>
                            {validation.touched.secpRefId &&
                            validation.errors.secpRefId ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.secpRefId}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="number"
                            className={`form-control w-100 ${
                              validation.touched.secpRefId &&
                              validation.errors.secpRefId &&
                              'is-invalid'
                            }`}
                            id="secpRefId"
                            name="secpRefId"
                            placeholder="SECP Ref ID"
                            value={validation.values.secpRefId}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.secpRefId &&
                                  isDraft &&
                                  validation.values.secpRefId &&
                                  !loadingDraftUpdateCompanyMember &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      secpRefId:
                                        validation.values.secpRefId.toString(),
                                    },
                                  });
                              }
                            }}
                            onKeyDown={evt =>
                              ['e', 'E', '+', '-'].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onChange={validation.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={PersonalInfoLogo}
                        ></img>
                        <div className="font-weight-600">Personal Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex w-400">
                          <label htmlFor="memberName">Member Name*</label>
                          {validation.touched.memberName &&
                          validation.errors.memberName ? (
                            <p className="text-danger font-12 ms-2 mb-0">
                              {validation.errors.memberName.firstName
                                ? validation.errors.memberName.firstName
                                : validation.errors.memberName.lastName}
                            </p>
                          ) : null}
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                          <input
                            type="text"
                            id="memberName.firstName"
                            name="memberName.firstName"
                            placeholder="First Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.memberName?.firstName &&
                              validation.errors.memberName?.firstName &&
                              'is-invalid'
                            }`}
                            value={validation.values.memberName.firstName}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberName?.firstName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberName.firstName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      firstName:
                                        validation.values.memberName.firstName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                          <input
                            type="text"
                            id="memberName.middleName"
                            name="memberName.middleName"
                            placeholder="Middle Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.memberName?.middleName &&
                              validation.errors.memberName?.middleName &&
                              'is-invalid'
                            }`}
                            value={validation.values.memberName.middleName}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberName?.middleName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberName.middleName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      middleName:
                                        validation.values.memberName.middleName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                          <input
                            type="text"
                            id="memberName.lastName"
                            name="memberName.lastName"
                            placeholder="Last Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.memberName?.lastName &&
                              validation.errors.memberName?.lastName &&
                              'is-invalid'
                            }`}
                            value={validation.values.memberName.lastName}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberName?.lastName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberName.lastName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      lastName:
                                        validation.values.memberName.lastName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex w-400 mt-2">
                          <label htmlFor="name">Father/Spouse Name*</label>
                          {validation.touched.fatherSpouseName &&
                          validation.errors.fatherSpouseName ? (
                            <p className="text-danger font-12 ms-2 mb-0">
                              {validation.errors.fatherSpouseName.firstName
                                ? validation.errors.fatherSpouseName.firstName
                                : validation.errors.fatherSpouseName.lastName}
                            </p>
                          ) : null}
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                          <input
                            type="text"
                            id="fatherSpouseName.firstName"
                            name="fatherSpouseName.firstName"
                            placeholder="First Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.fatherSpouseName?.firstName &&
                              validation.errors.fatherSpouseName?.firstName &&
                              'is-invalid'
                            }`}
                            value={validation.values.fatherSpouseName.firstName}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.fatherSpouseName
                                  ?.firstName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.fatherSpouseName
                                    ?.firstName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      relativeFirstName:
                                        validation.values.fatherSpouseName
                                          .firstName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                          <input
                            type="text"
                            id="fatherSpouseName.middleName"
                            name="fatherSpouseName.middleName"
                            placeholder="Middle Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.fatherSpouseName?.middleName &&
                              validation.errors.fatherSpouseName?.middleName &&
                              'is-invalid'
                            }`}
                            value={
                              validation.values.fatherSpouseName.middleName
                            }
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.fatherSpouseName
                                  ?.middleName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.fatherSpouseName
                                    ?.middleName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      relativeMiddleName:
                                        validation.values.fatherSpouseName
                                          .middleName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                          <input
                            type="text"
                            id="fatherSpouseName.lastName"
                            name="fatherSpouseName.lastName"
                            placeholder="Last Name"
                            className={`form-control form-sm mb-0 ${
                              validation.touched.fatherSpouseName?.lastName &&
                              validation.errors.fatherSpouseName?.lastName &&
                              'is-invalid'
                            }`}
                            value={validation.values.fatherSpouseName.lastName}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.fatherSpouseName?.lastName &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.fatherSpouseName
                                    ?.lastName &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      relativeLastName:
                                        validation.values.fatherSpouseName
                                          .lastName,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 background-dim-grey mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="memberType">Member Type*</label>
                          {validation.touched.memberType &&
                          validation.errors.memberType ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.memberType}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.memberType &&
                              validation.errors.memberType &&
                              'is-invalid'
                            }`}
                            id="memberType"
                            name="memberType"
                            placeholder="Sectorial Classification"
                            value={validation.values.memberType}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberType &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberType &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      type: validation.values.memberType,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          >
                            <option value="">Select</option>
                            <option value={MemberTypeEnum.AUDITOR}>
                              Auditor
                            </option>
                            <option value={MemberTypeEnum.CHAIRMAN}>
                              Chairman
                            </option>
                            <option value={MemberTypeEnum.CHIEF_ACCOUNTANT}>
                              Chief Accountant
                            </option>
                            <option value={MemberTypeEnum.CHIEF_EXECUTIVE}>
                              Chief Executive
                            </option>
                            <option value={MemberTypeEnum.COMPANY_SECRETARY}>
                              Company Secretary
                            </option>
                            <option value={MemberTypeEnum.DIRECTOR}>
                              Director
                            </option>
                            <option value={MemberTypeEnum.JOINT_OWNER_SHARE}>
                              Joint Owner Share
                            </option>
                            <option value={MemberTypeEnum.MANAGING_ACCOUNTANT}>
                              Managing Accountant
                            </option>
                            <option value={MemberTypeEnum.NOMINEE_DIRECTOR}>
                              Nominee Director
                            </option>
                            <option value={MemberTypeEnum.RELATIVE}>
                              Relative
                            </option>
                            <option
                              value={MemberTypeEnum.ULTIMATE_BENEFICIAL_OWNER}
                            >
                              Ultimate Beneficial Owner
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="relationship">Relationship*</label>
                          {validation.touched.relationship &&
                          validation.errors.relationship ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.relationship}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.relationship &&
                              validation.errors.relationship &&
                              'is-invalid'
                            }`}
                            id="relationship"
                            name="relationship"
                            placeholder="Relationship"
                            value={validation.values.relationship}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.relationship &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.relationship &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      relationship:
                                        validation.values.relationship,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          >
                            <option value="">Select</option>
                            <option value={RelationshipEnum.SON_OF}>
                              Son of
                            </option>
                            <option value={RelationshipEnum.FATHER_OF}>
                              Father of
                            </option>
                            <option value={RelationshipEnum.SPOUSE_OF}>
                              Spouse of
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="nationality">Nationality*</label>
                          {validation.touched.nationality &&
                          validation.errors.nationality ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.nationality}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          id="nationality"
                          name="nationality"
                          placeholder="Nationality"
                          className={`form-control w-100
                    ${
                      validation.touched.nationality &&
                      validation.errors.nationality &&
                      'is-invalid'
                    }`}
                          value={validation.values.nationality}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.nationality &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.nationality &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    nationality: validation.values.nationality,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="passportNumber">
                            Passport No. (Foreign National)
                          </label>
                          {validation.touched.passportNumber &&
                          validation.errors.passportNumber ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.passportNumber}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          id="passportNumber"
                          name="passportNumber"
                          placeholder="Passport Number"
                          className={`form-control w-100 ${
                            validation.touched.passportNumber &&
                            validation.errors.passportNumber &&
                            'is-invalid'
                          }`}
                          value={validation.values.passportNumber}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.passportNumber &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.passportNumber &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    passportNumber:
                                      validation.values.passportNumber,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cnicNumber">CNIC No* (PK)</label>
                          {validation.touched.cnicNumber &&
                          validation.errors.cnicNumber ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.cnicNumber}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          id="cnicNumber"
                          name="cnicNumber"
                          placeholder="CNIC Number"
                          className={`form-control w-100 ${
                            validation.touched.cnicNumber &&
                            validation.errors.cnicNumber &&
                            'is-invalid'
                          }`}
                          value={validation.values.cnicNumber}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.cnicNumber &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.cnicNumber &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    cnic: validation.values.cnicNumber,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.setFieldValue(
                              'cnicNumber',
                              e.target.value.replace(/[^0-9]/gi, '-')
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cnicExpiry">CNIC Expiry Date*</label>
                          {validation.touched.cnicExpiry &&
                          validation.errors.cnicExpiry ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.cnicExpiry}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="date"
                          className={`form-control w-100 ${
                            validation.touched.cnicExpiry &&
                            validation.errors.cnicExpiry &&
                            'is-invalid'
                          }`}
                          id="cnicExpiry"
                          name="cnicExpiry"
                          value={validation.values.cnicExpiry}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.cnicExpiry &&
                                isDraft &&
                                validation.values.cnicExpiry &&
                                !loadingDraftUpdateCompanyMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    cnicExpiry: validation.values.cnicExpiry,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="businessOccupation">
                            Business Occupation
                          </label>
                          {validation.touched.businessOccupation &&
                          validation.errors.businessOccupation ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.businessOccupation}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          id="businessOccupation"
                          name="businessOccupation"
                          placeholder="Business Occupation"
                          className={`form-control w-100 ${
                            validation.touched.businessOccupation &&
                            validation.errors.businessOccupation &&
                            'is-invalid'
                          }`}
                          value={validation.values.businessOccupation}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.businessOccupation &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.businessOccupation &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    occupation:
                                      validation.values.businessOccupation,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="ntn">NTN</label>
                          {validation.touched.ntn && validation.errors.ntn ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.ntn}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.ntn &&
                            validation.errors.ntn &&
                            'is-invalid'
                          }`}
                          id="ntn"
                          name="ntn"
                          placeholder="NTN"
                          value={validation.values.ntn}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.ntn &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.ntn &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    ntn: validation.values.ntn,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={AddressIcon}
                        ></img>
                        <div className="font-weight-600">Address</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="country">Country*</label>
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.country}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.country &&
                              validation.errors.country &&
                              'is-invalid'
                            }`}
                            id="country"
                            name="country"
                            placeholder="Country"
                            value={validation.values.country}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.country &&
                                  isDraft &&
                                  validation.values.country &&
                                  !loadingDraftUpdateCompanyMember &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      country: validation.values.country,
                                    },
                                  });
                              }
                            }}
                            onChange={e => {
                              let country: ICountry = countries.find(
                                (x: ICountry) =>
                                  x.name === e.currentTarget.value
                              );
                              if (country) {
                                setSelectedCountry(country.isoCode);
                              }
                              setSelectedState(undefined);
                              validation.setFieldValue('city', '');
                              validation.setFieldValue('province', '');
                              validation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {countries?.map((country: ICountry) => (
                              <option value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="province">Province*</label>
                          {validation.touched.province &&
                          validation.errors.province ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.province}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.province &&
                              validation.errors.province &&
                              'is-invalid'
                            }`}
                            id="province"
                            name="province"
                            placeholder="Province"
                            value={validation.values.province}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.province &&
                                  isDraft &&
                                  validation.values.province &&
                                  !loadingDraftUpdateCompanyMember &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      province: validation.values.province,
                                    },
                                  });
                              }
                            }}
                            onChange={e => {
                              let state: IState = states.find(
                                (x: IState) => x.name === e.target.value
                              );
                              setSelectedCountry(selectedCountry);
                              if (state) {
                                setSelectedState(state.isoCode);
                              }
                              validation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {states?.map((state: IState) => (
                              <option value={state.name}>{state.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="city">City*</label>
                          {validation.touched.city && validation.errors.city ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.city}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.city &&
                              validation.errors.city &&
                              'is-invalid'
                            }`}
                            id="city"
                            name="city"
                            placeholder="City"
                            value={validation.values.city}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.city &&
                                  isDraft &&
                                  validation.values.city &&
                                  !loadingDraftUpdateCompanyMember &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      city: validation.values.city,
                                    },
                                  });
                              }
                            }}
                            onChange={e => {
                              validation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {cities?.map((city: ICity) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="postalCode">Postal Code*</label>
                          {validation.touched.postalCode &&
                          validation.errors.postalCode ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.postalCode}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.postalCode &&
                            validation.errors.postalCode &&
                            'is-invalid'
                          }`}
                          id="postalCode"
                          name="postalCode"
                          placeholder="Postal Code"
                          value={validation.values.postalCode}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.postalCode &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.postalCode &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    postalCode: validation.values.postalCode,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 background-dim-grey">
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="residentialAddress">
                            Residential Address*
                          </label>
                          {validation.touched.residentialAddress &&
                          validation.errors.residentialAddress ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.residentialAddress}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.residentialAddress &&
                            validation.errors.residentialAddress &&
                            'is-invalid'
                          }`}
                          id="residentialAddress"
                          name="residentialAddress"
                          placeholder="Residential Address"
                          value={validation.values.residentialAddress}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.residentialAddress &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.residentialAddress &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    residentialAddress:
                                      validation.values.residentialAddress,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                  </div>
                </div>
                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={ContactInfoIcon}
                        ></img>
                        <div className="font-weight-600">Contact Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="mobile">Telephone*</label>
                          {validation.touched.mobile &&
                          validation.errors.mobile ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.mobile}
                            </p>
                          ) : null}
                        </div>
                        <PhoneInput
                          containerStyle={{ padding: 0, width: '100%' }}
                          buttonClass="button-class"
                          containerClass="form-control"
                          inputClass="my-input-class"
                          inputStyle={{
                            border: 0,
                            height: 48,
                            width: '100%',
                          }}
                          buttonStyle={{
                            height: 48,
                          }}
                          countryCodeEditable={false}
                          preferredCountries={['pk']}
                          country={'pk'}
                          value={validation.values.mobile}
                          onChange={e => {
                            validation.setFieldValue('mobile', e);
                          }}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);

                            {
                              !validation.errors.mobile &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.mobile &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    phone: validation.values.mobile.toString(),
                                  },
                                });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="email">Email*</label>
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.email}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.email &&
                            validation.errors.email &&
                            'is-invalid'
                          }`}
                          id="email"
                          name="email"
                          placeholder="Email"
                          value={validation.values.email}
                          disabled={memberView || !isDraft}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.email &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.email &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    email: validation.values.email,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                  </div>
                </div>
                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={CategoryIcon}
                        ></img>
                        <div className="font-weight-600">Category</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="memberCategory">
                            Member Category*
                          </label>
                          {validation.touched.memberCategory &&
                          validation.errors.memberCategory ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.memberCategory}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 ${
                              validation.touched.memberCategory &&
                              validation.errors.memberCategory &&
                              'is-invalid'
                            }`}
                            id="memberCategory"
                            name="memberCategory"
                            placeholder="Sectorial Classification"
                            value={validation.values.memberCategory}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberCategory &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberCategory &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      category:
                                        validation.values.memberCategory,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          >
                            <option value="">Select</option>
                            <option value={MemberCategoryEnum.INDIVIDUAL}>
                              Individual
                            </option>
                            <option value={MemberCategoryEnum.LEGAL_PERSON}>
                              Legal Person
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {validation.values.memberCategory ===
                    MemberCategoryEnum.LEGAL_PERSON ? (
                      <>
                        <div className="col-3">
                          <div className="form-group">
                            <div className="d-flex">
                              <label htmlFor="legalPersonNumber">
                                Legal Person Name
                              </label>
                              {validation.touched.legalPersonNumber &&
                              validation.errors.legalPersonNumber ? (
                                <p className="text-danger font-12 ms-auto mb-0">
                                  {validation.errors.legalPersonNumber}
                                </p>
                              ) : null}
                            </div>
                            <input
                              type="text"
                              id="legalPersonNumber"
                              name="legalPersonNumber"
                              placeholder="Name"
                              className={`form-control w-100 ${
                                validation.touched.legalPersonNumber &&
                                validation.errors.legalPersonNumber &&
                                'is-invalid'
                              }`}
                              value={validation.values.legalPersonNumber}
                              disabled={memberView}
                              onBlur={e => {
                                validation.handleBlur(e);
                                {
                                  !validation.errors.legalPersonNumber &&
                                    isDraft &&
                                    !loadingDraftUpdateCompanyMember &&
                                    validation.values.legalPersonNumber &&
                                    draftUpdateCompanyMember({
                                      variables: {
                                        id: selectedMemberId,
                                        companyId: companyID,
                                        legalPersonNumber:
                                          validation.values.legalPersonNumber,
                                      },
                                    });
                                }
                              }}
                              onChange={validation.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <div className="d-flex">
                              <label htmlFor="cuinNumber">CUIN No</label>
                              {validation.touched.cuinNumber &&
                              validation.errors.cuinNumber ? (
                                <p className="text-danger font-12 ms-auto mb-0">
                                  {validation.errors.cuinNumber}
                                </p>
                              ) : null}
                            </div>
                            <input
                              type="text"
                              id="cuinNumber"
                              name="cuinNumber"
                              placeholder="CUIN Number"
                              className={`form-control w-100 ${
                                validation.touched.cuinNumber &&
                                validation.errors.cuinNumber &&
                                'is-invalid'
                              }`}
                              value={validation.values.cuinNumber}
                              disabled={memberView}
                              onBlur={e => {
                                validation.handleBlur(e);
                                {
                                  !validation.errors.cuinNumber &&
                                    isDraft &&
                                    !loadingDraftUpdateCompanyMember &&
                                    validation.values.cuinNumber &&
                                    draftUpdateCompanyMember({
                                      variables: {
                                        id: selectedMemberId,
                                        companyId: companyID,
                                        companyCuin:
                                          validation.values.cuinNumber,
                                      },
                                    });
                                }
                              }}
                              onChange={validation.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <div className="d-flex">
                              <label htmlFor="numberOfShares">
                                Number of Shares
                              </label>
                              {validation.touched.numberOfShares &&
                              validation.errors.numberOfShares ? (
                                <p className="text-danger font-12 ms-auto mb-0">
                                  {validation.errors.numberOfShares}
                                </p>
                              ) : null}
                            </div>
                            <input
                              type="number"
                              id="numberOfShares"
                              name="numberOfShares"
                              placeholder="Number of Shares"
                              className={`form-control w-100 ${
                                validation.touched.numberOfShares &&
                                validation.errors.numberOfShares &&
                                'is-invalid'
                              }`}
                              value={validation.values.numberOfShares}
                              disabled={memberView}
                              onBlur={e => {
                                validation.handleBlur(e);
                                {
                                  !validation.errors.numberOfShares &&
                                    isDraft &&
                                    !loadingDraftUpdateCompanyMember &&
                                    validation.values.numberOfShares &&
                                    draftUpdateCompanyMember({
                                      variables: {
                                        id: selectedMemberId,
                                        companyId: companyID,
                                        numOfShares:
                                          validation.values.numberOfShares,
                                      },
                                    });
                                }
                              }}
                              onKeyDown={evt =>
                                ['e', 'E', '+', '-'].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={validation.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {validation.values.memberCategory ===
                  MemberCategoryEnum.LEGAL_PERSON ? (
                    <div className="row mb-4">
                      <div className="col-3"></div>
                      <div className="col-3"></div>
                      <div className="col-3"></div>
                      <div className="col-3">
                        <div className="d-flex justify-content-around">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="holdingCompany"
                              name="holdingCompany"
                              checked={validation.values.holdingCompany}
                              disabled={memberView}
                              onChange={validation.handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="holdingCompany"
                            >
                              Holding Company
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="subsidiaries"
                              name="subsidiaries"
                              checked={validation.values.subsidiaries}
                              disabled={memberView}
                              onChange={validation.handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="subsidiaries"
                            >
                              Subsidiary (ies)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {!loadingReliventDocumnets &&
              !loadingRelevantProcesses &&
              !loadingGetHelp &&
              (dataReliventDocumnets ||
                dataRelevantProcesses ||
                dataGetHelp) && (
                <RightSideBar
                  documents={
                    dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                  }
                  processes={
                    dataRelevantProcesses?.getRelevantProcessesByEvent
                      ?.eventRelevantProcesses
                  }
                  help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
                />
              )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
