import { number } from 'yup';
import { CompanyKindEnum } from '../../commons/enums';
const GetCompanyKind = (status: number) => {
    if (status == CompanyKindEnum.SMC) {
        return 'SMC';
    } else if (status == CompanyKindEnum.LISTED_COMPANY) {
        return 'Listed Company';
    } else if (status == CompanyKindEnum.PRIVATE) {
        return 'Private';
    } else if (status == CompanyKindEnum.UNLISTED_PUBLIC_COMPANY) {
        return 'Unlisted Public Company';
    }
};

export default GetCompanyKind;
