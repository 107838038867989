import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch } from '~/Store/hooks';
import { setAgendaViewScreen } from '~/Store/reducers/agenda';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './subscription.scss';

import {
  DELETE_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLANS,
} from '~/Store/graphQL/Subscription';
import { setSelectedSubscriptionId } from '~/Store/reducers/subscription';
import GetSubscriptionPlanDuration from '~/helpers/Subscriptions/getSubscriptionPlanDuration';

export const AdminSubscription: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Subscription Plan',
        },
      ])
    );
  }, []);
  // Get all agendas query
  const [
    getAllSubscriptionPlans,
    {
      loading: loadingGetAllSubscriptionPlans,
      error: errorGetAllSubscriptionPlans,
      data: dataGetAllSubscriptionPlans,
    },
  ] = useLazyQuery<any>(GET_SUBSCRIPTION_PLANS, {
    fetchPolicy: 'no-cache',
  });

  const [
    deleteSubscriptionPlan,
    {
      loading: loadingDeleteSubscriptionPlan,
      error: errorDeleteSubscriptionPlan,
      data: dataDeleteSubscriptionPlan,
    },
  ] = useMutation<any>(DELETE_SUBSCRIPTION_PLAN);

  useEffect(() => {
    getAllSubscriptionPlans();
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllSubscriptionPlans) {
      dispatch(setToastError(errorGetAllSubscriptionPlans));
    }
  }, [errorGetAllSubscriptionPlans]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex w-300">
            <h5>Subscription Plan</h5>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setSelectedSubscriptionId(null));
                navigate('/admin/subscription/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Subscription
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllSubscriptionPlans ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th>S.No</th>
                            <th>Duration</th>
                            <th>Cost Per User</th>
                            <th>Cost Per Company</th>
                            <th>Company Base Cost</th>
                            <th>Intermediate User Base Cost</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllSubscriptionPlans?.getAllSubscriptionPlans?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  {val.node.duration
                                    ? GetSubscriptionPlanDuration(
                                        val?.node?.duration
                                      )
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.costPerUser
                                    ? val?.node?.costPerUser
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.costPerCompany
                                    ? val?.node?.costPerCompany
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.companyBaseCost
                                    ? val?.node?.companyBaseCost
                                    : 'N/A'}
                                </td>

                                <td>
                                  {val.node.intermedialUserBaseCost
                                    ? val?.node?.intermedialUserBaseCost
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(
                                          setSelectedSubscriptionId(val.node.id)
                                        );
                                        navigate('/admin/subscription/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(setAgendaViewScreen(false));
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteSubscriptionPlan({
                                              variables: {
                                                id: val.node.id,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data
                                                    .deleteSubscriptionPlan
                                                    .count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Subscription Deleted!'
                                                    )
                                                  );
                                                  getAllSubscriptionPlans();
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllSubscriptionPlans?.getAllSubscriptionPlans
                        ?.edges?.length === 0 ||
                        dataGetAllSubscriptionPlans?.getAllSubscriptionPlans
                          ?.edges === undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllSubscriptionPlans?.findAllAgendas?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllSubscriptionPlans?.findAllAgendas
                              ?.pageInfo?.hasPreviousPage
                          ) {
                            getAllSubscriptionPlans({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllSubscriptionPlans?.findAllAgendas
                                    ?.pageInfo?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllSubscriptionPlans?.findAllAgendas?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllSubscriptionPlans?.findAllAgendas
                              ?.pageInfo?.hasNextPage
                          ) {
                            getAllSubscriptionPlans({
                              variables: {
                                after:
                                  dataGetAllSubscriptionPlans?.findAllAgendas
                                    ?.pageInfo?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
