import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import 'react-multi-email/style.css';
import {
  FIND_SHARE_BY_ID,
  GET_NEXT_SHARE_CERTIFICATE_NO,
  TRANSMIT_SHARE,
} from '~/Store/graphQL/Shares';
import NumberBlockImg from '/images/number-blocks.png';
import CalendarImg from '/images/calendar.png';
import {
  ModuleEnum,
  ShareTransmissionModeEnum,
  ShareTransmissionReasonEnum,
  ShareTransmissionTypeEnum,
  ShareTypeEnum,
} from '~/commons/enums';
import {
  CREATE_COMPANY_MEMBER_DRAFT,
  GET_ALL_FOLIO_NO_OF_COMPANY,
} from '~/Store/graphQL/CompanyMembers';
import { useSearchParams } from 'react-router-dom';
import {
  setMemberViewScreen,
  setSelectedMemberId,
} from '~/Store/reducers/companyMembers';
import {
  setModalDataRedux,
  setModalVisibility,
  setSelectedShareId,
  setSelectedShareIdForTransaction,
  setTransmission,
  setTransmissionMode,
  setTransmissionReason,
  setType,
} from '~/Store/reducers/share';
import { Container, Modal } from 'reactstrap';
import {
  TransmissionShareDto,
  TransmissionShareModalDto,
  TransmissionShareTableDto,
} from '../types';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
export const ShareTransmission: FC = () => {
  const {
    shareView,
    selectedShareIdForTransaction,
    transmissionMode,
    transmissionReason,
    type,
    transmission,
    modalVisibility,
    modalDataRedux,
  } = useAppSelector(state => state.shareReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareData, setShareData] = useState<any>();
  const [folioNumbers, setFolioNumbers] = useState<any>();
  const newFolioNo = searchParams.get('newFolio');
  const [modalData, setModalData] = useState<TransmissionShareModalDto[]>([]);
  const [conflictingShares, setConflictingShares] = useState<number>();
  const [conflictingSharesTableData, setConflictingSharesTableData] = useState<
    TransmissionShareTableDto[]
  >([]);
  const [tableData, setTableData] = useState<TransmissionShareTableDto[]>([]);
  const [modalError, setModalError] = useState<string>('');

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/shares',
          title: 'Share Management',
        },
        {
          url: location.pathname,
          title: 'Share Transmission',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SHARE_MANAGEMENT,
    },
    fetchPolicy: 'no-cache',
  });

  // Getting share information
  const [
    findShareById,
    {
      loading: loadingFindShareById,
      error: errorFindShareById,
      data: dataFindShareById,
    },
  ] = useLazyQuery<any>(FIND_SHARE_BY_ID);
  // transmit share
  const [
    transmitShare,
    {
      loading: loadingTransmitShare,
      error: errorTransmitShare,
      data: dataTransmitShare,
    },
  ] = useMutation<any>(TRANSMIT_SHARE);
  // Get all folio numbers of company members
  const [
    getAllFolioNoOfCompany,
    {
      loading: loadingGetAllFolioNoOfCompany,
      error: errorGetAllFolioNoOfCompany,
      data: dataGetAllFolioNoOfCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_FOLIO_NO_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
  });
  //Create draft company member
  const [
    createDraftCompanyMember,
    {
      loading: loadingCreateDraftCompanyMember,
      error: errorCreateDraftCompanyMember,
      data: dataCreateDraftCompanyMember,
    },
  ] = useMutation<any>(CREATE_COMPANY_MEMBER_DRAFT);
  // Get next share certificate number query
  const [
    getNextShareCertificateNo,
    {
      loading: loadingGetNextShareCertificateNo,
      error: errorGetNextShareCertificateNo,
      data: dataGetNextShareCertificateNo,
    },
  ] = useLazyQuery<any>(GET_NEXT_SHARE_CERTIFICATE_NO, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (selectedShareIdForTransaction) {
      findShareById({
        variables: {
          id: selectedShareIdForTransaction,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedShareIdForTransaction]);
  useEffect(() => {
    if (dataFindShareById) {
      setShareData(dataFindShareById?.findOneShare?.share);
    } else if (errorFindShareById) {
      console.log('errorFindShareById:', errorFindShareById);
    }
  }, [dataFindShareById, errorFindShareById]);
  useEffect(() => {
    if (companyID) {
      getAllFolioNoOfCompany({
        variables: {
          id: companyID,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [companyID]);
  useEffect(() => {
    if (dataGetAllFolioNoOfCompany) {
      setFolioNumbers(
        dataGetAllFolioNoOfCompany?.getAllFolioNumbersOfCompany?.folioNumbers
      );
    } else if (errorGetAllFolioNoOfCompany) {
      console.log('errorGetAllFolioNoOfCompany:', errorGetAllFolioNoOfCompany);
    }
  }, [dataGetAllFolioNoOfCompany, errorGetAllFolioNoOfCompany]);
  useEffect(() => {
    if (newFolioNo) {
      setFolioNumbers((prev: any) => [...(prev || []), folioNumbers]);
      validation.setFieldValue('folioNumber', newFolioNo);
    }
  }, [newFolioNo]);
  useEffect(() => {
    if (modalDataRedux) {
      let tempModalDataRedux = [
        ...modalDataRedux.map((data: any) => ({ ...data })),
      ];
      setModalData(tempModalDataRedux);
    }
  }, [newFolioNo]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      certificateNo: shareData?.shareCertificateNo
        ? shareData.shareCertificateNo
        : undefined,
      noOfShares: shareData?.noOfShares ? shareData.noOfShares : undefined,
      distinctiveNoFrom: shareData?.DistinctiveNoFrom
        ? shareData.DistinctiveNoFrom
        : undefined,
      distinctiveNoTo: shareData?.DistinctiveNoTo
        ? shareData?.DistinctiveNoTo
        : '',
      transmitDate: new Date().toISOString().slice(0, 10),
      transmissionMode: transmissionMode,
      transmissionReason: transmissionReason,
      receiptDateOfTransmissionDeed: new Date().toISOString().slice(0, 10),
      type: type,
      shareType: shareData?.shareType ? shareData?.shareType : '',
      transmission: transmission,
    },
    validationSchema: Yup.object({
      transmitDate: Yup.string()
        .required('Please select transmit date')
        .nullable(),
      transmissionMode: Yup.string()
        .required('Please select transmission mode')
        .nullable(),
      transmissionReason: Yup.string()
        .required('Please select transmission reason')
        .nullable(),
      type: Yup.string().required('Please select type').nullable(),
      transmission: Yup.number()
        .required('Please enter transmission')
        .nullable(),
      receiptDateOfTransmissionDeed: Yup.string()
        .required('Please select receipt date of transmission deed')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      let inputDataArray: TransmissionShareDto[] = [];
      tableData.forEach(element => {
        let tempObj: TransmissionShareDto = {};
        tempObj.ratio = element.percentage;
        tempObj.dateOfReceipt = values.receiptDateOfTransmissionDeed;
        tempObj.transmissionMode = values.transmissionMode;
        tempObj.transmissionReason = values.transmissionReason;
        tempObj.transmitToFolio = element.folioNo;
        tempObj.type = values.type;
        tempObj.DistinctiveNoFrom = element.distinctiveNoFrom;
        tempObj.DistinctiveNoTo = element.distinctiveNoTo;
        inputDataArray.push(tempObj);
      });
      let conflictingSharesFolioError = false;
      conflictingSharesTableData.forEach(element => {
        if (element.folioNo) {
          let tempObj: TransmissionShareDto = {};
          tempObj.dateOfReceipt = values.receiptDateOfTransmissionDeed;
          tempObj.transmissionMode = values.transmissionMode;
          tempObj.transmissionReason = values.transmissionReason;
          tempObj.transmitToFolio = element.folioNo;
          tempObj.type = values.type;
          tempObj.DistinctiveNoFrom = element.distinctiveNoFrom;
          tempObj.DistinctiveNoTo = element.distinctiveNoTo;
          inputDataArray.push(tempObj);
        } else {
          conflictingSharesFolioError = true;
        }
      });
      if (conflictingSharesFolioError) {
        dispatch(
          setToastError('Please select folio numbers of all conflicting shares')
        );
      } else {
        transmitShare({
          variables: {
            shareId: selectedShareIdForTransaction,
            data: inputDataArray,
          },
        })
          .then((res: any) => {
            if (res?.data?.transmissionShare?.data) {
              dispatch(setToastSuccess('Share Transmitted!'));
              dispatch(setSelectedShareId(null));
              dispatch(setSelectedShareIdForTransaction(null));
              navigate(`/shares`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  const toggleModal = () => {
    dispatch(setModalVisibility(!modalVisibility));
  };
  return (
    <React.Fragment>
      {loadingFindShareById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 background-white">
          <div className="d-flex justify-content-end background-primary-medium">
            <button
              type="button"
              className="btn dangerButton d-flex align-items-center m-3 fw-bold"
              disabled={loadingTransmitShare}
              onMouseDown={() => {
                navigate('/shares');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn fw-bold primaryDismissButton d-flex align-items-center m-3 mx-0"
              disabled={loadingTransmitShare}
              onMouseDown={() => {
                navigate('/shares/transactions');
              }}
            >
              Back
            </button>
            {!shareView && (
              <button
                type="button"
                className="btn successButton d-flex align-items-center m-3 fw-bold"
                disabled={loadingTransmitShare}
                onMouseDown={() => {
                  validation.handleSubmit();
                }}
              >
                {loadingTransmitShare && (
                  <i className="bx bx-loader-circle bx-spin me-1" />
                )}
                Save
                <i className="bx bx-upload mx-1" />
              </button>
            )}
          </div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div className="row mx-0 mt-4 pt-4 backgroung-light-grey">
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="certificateNo"
                        className="model-input-heading"
                      >
                        Certificate No
                      </label>
                      {validation.touched.certificateNo &&
                      validation.errors.certificateNo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.certificateNo}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-auto ${
                        validation.touched.certificateNo &&
                        validation.errors.certificateNo &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="certificateNo"
                      name="certificateNo"
                      placeholder="Certificate No"
                      value={validation.values.certificateNo}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="noOfShares"
                        className="model-input-heading"
                      >
                        No of Shares
                      </label>
                      {validation.touched.noOfShares &&
                      validation.errors.noOfShares ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.noOfShares}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-auto ${
                        validation.touched.noOfShares &&
                        validation.errors.noOfShares &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="noOfShares"
                      name="noOfShares"
                      placeholder="No of Shares"
                      value={validation.values.noOfShares}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label
                        htmlFor="distinctiveNoFrom"
                        className="model-input-heading"
                      >
                        Distinctive No:From
                      </label>
                      {validation.touched.distinctiveNoFrom &&
                      validation.errors.distinctiveNoFrom ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNoFrom}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-auto ${
                        validation.touched.distinctiveNoFrom &&
                        validation.errors.distinctiveNoFrom &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="distinctiveNoFrom"
                      name="distinctiveNoFrom"
                      placeholder="Dictinctive No:From"
                      value={validation.values.distinctiveNoFrom}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex">
                      <label
                        htmlFor="distinctiveNoTo"
                        className="model-input-heading"
                      >
                        Distinctive No:To
                      </label>
                      {validation.touched.distinctiveNoTo &&
                      validation.errors.distinctiveNoTo ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.distinctiveNoTo}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-auto ${
                        validation.touched.distinctiveNoTo &&
                        validation.errors.distinctiveNoTo &&
                        'is-invalid'
                      }`}
                      disabled={true}
                      id="distinctiveNoTo"
                      name="distinctiveNoTo"
                      placeholder="Dictinctive No:To"
                      value={validation.values.distinctiveNoTo}
                      onChange={e => {
                        validation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="d-flex justify-content-start align-items-center primary-medium-border-div">
                    <img className="mx-4" src={NumberBlockImg}></img>
                    <div className="form-group pt-3">
                      <div className="d-flex">
                        <label htmlFor="transmission">Transmission*</label>
                        {validation.touched.transmission &&
                        validation.errors.transmission ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.transmission}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          type="number"
                          className={`form-control form-sm w-auto ${
                            validation.touched.transmission &&
                            validation.errors.transmission &&
                            'is-invalid'
                          }`}
                          id="transmission"
                          name="transmission"
                          placeholder="Transmission"
                          min="0"
                          onKeyDown={evt =>
                            ['e', 'E', '+', '-'].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          value={validation.values.transmission}
                          onChange={e => {
                            dispatch(setTransmission(e.target.value));
                            validation.handleChange(e);
                          }}
                        />
                        {!shareView ? (
                          <button
                            type="button"
                            className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold"
                            onMouseDown={() => {
                              if (validation.values.transmission) {
                                if (
                                  parseInt(validation.values.transmission) > 0
                                ) {
                                  dispatch(setModalVisibility(true));
                                } else {
                                  dispatch(
                                    setToastError(
                                      'Transmission value must be greater than 0'
                                    )
                                  );
                                }
                              } else {
                                dispatch(
                                  setToastError('Please Enter Transmission')
                                );
                              }
                            }}
                          >
                            Transmit
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex justify-content-center align-items-center success-border-div">
                    <img className="ms-1" src={CalendarImg}></img>
                    <div className="form-group pt-3 mx-5">
                      <div className="d-flex ">
                        <label
                          htmlFor="transmitDate"
                          className="model-input-heading"
                        >
                          Transmit Date*
                        </label>
                        {validation.touched.transmitDate &&
                        validation.errors.transmitDate ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.transmitDate}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.transmitDate &&
                          validation.errors.transmitDate &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="transmitDate"
                        name="transmitDate"
                        placeholder="Transmit Date"
                        value={validation.values.transmitDate}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    <div className="form-group pt-3">
                      <div className="d-flex">
                        <label
                          htmlFor="receiptDateOfTransmissionDeed"
                          className="model-input-heading"
                        >
                          Receipt Date of Transmission Deed*
                        </label>
                        {validation.touched.receiptDateOfTransmissionDeed &&
                        validation.errors.receiptDateOfTransmissionDeed ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.receiptDateOfTransmissionDeed}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-auto ${
                          validation.touched.receiptDateOfTransmissionDeed &&
                          validation.errors.receiptDateOfTransmissionDeed &&
                          'is-invalid'
                        }`}
                        disabled={shareView}
                        id="receiptDateOfTransmissionDeed"
                        name="receiptDateOfTransmissionDeed"
                        placeholder="Receipt Date of Transmission Deed"
                        value={validation.values.receiptDateOfTransmissionDeed}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {tableData.length > 0 ? (
                <div className="row mx-0 mt-4 pt-4">
                  <div className="col fw-bold mb-1">Transmitted Share Data</div>
                  <div className="col">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th>S.No</th>
                            <th>Folio No</th>
                            <th>Distinctive No: From</th>
                            <th>Distinctive No: To</th>
                            <th>New Certificate No</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.map(
                            (val: TransmissionShareTableDto, index: number) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{val.folioNo}</td>
                                <td>{val.distinctiveNoFrom}</td>
                                <td>{val?.distinctiveNoTo}</td>
                                <td>{val.newCertificateNo}</td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <button
                                      type="button"
                                      className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold"
                                      disabled={
                                        loadingGetNextShareCertificateNo
                                      }
                                      onMouseDown={() => {
                                        if (index === 0) {
                                          getNextShareCertificateNo()
                                            .then((res: any) => {
                                              if (
                                                res?.data
                                                  ?.getShareCertificateNumber
                                                  ?.shareCertificateNo
                                              ) {
                                                let tempTableData = [
                                                  ...tableData,
                                                ];
                                                tempTableData[
                                                  index
                                                ].newCertificateNo =
                                                  res?.data?.getShareCertificateNumber?.shareCertificateNo;
                                                setTableData(tempTableData);
                                              }
                                            })
                                            .catch(err => {
                                              dispatch(setToastError(err));
                                            });
                                        } else {
                                          let tempTableData = [...tableData];
                                          const prevNewCertificateNo =
                                            tempTableData[index - 1]
                                              .newCertificateNo;
                                          if (prevNewCertificateNo) {
                                            tempTableData[
                                              index
                                            ].newCertificateNo =
                                              prevNewCertificateNo + 1;
                                          }
                                          setTableData(tempTableData);
                                        }
                                      }}
                                    >
                                      Next No
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row mx-0 mt-4 pt-4 backgroung-light-grey">
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="transmissionMode">
                        Transmission Mode*
                      </label>
                      {validation.touched.transmissionMode &&
                      validation.errors.transmissionMode ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.transmissionMode}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.transmissionMode &&
                          validation.errors.transmissionMode &&
                          'is-invalid'
                        }`}
                        id="transmissionMode"
                        name="transmissionMode"
                        placeholder="Transmission Mode"
                        value={validation.values.transmissionMode}
                        disabled={shareView}
                        onChange={e => {
                          dispatch(setTransmissionMode(e.target.value));
                          validation.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={ShareTransmissionModeEnum.LEGAL_HEIR}>
                          Legal Heir
                        </option>
                        <option value={ShareTransmissionModeEnum.AMALGAMATION}>
                          Amalgamation
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="transmissionReason">
                        Transmission Reason*
                      </label>
                      {validation.touched.transmissionReason &&
                      validation.errors.transmissionReason ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.transmissionReason}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.transmissionReason &&
                          validation.errors.transmissionReason &&
                          'is-invalid'
                        }`}
                        id="transmissionReason"
                        name="transmissionReason"
                        placeholder="Transmission Reason"
                        value={validation.values.transmissionReason}
                        disabled={shareView}
                        onChange={e => {
                          dispatch(setTransmissionReason(e.target.value));
                          validation.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={ShareTransmissionReasonEnum.DEATH}>
                          Death
                        </option>
                        <option
                          value={ShareTransmissionReasonEnum.BANK_CORRUPT}
                        >
                          Bank Corrupt
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="type">Type*</label>
                      {validation.touched.type && validation.errors.type ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.type}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.type &&
                          validation.errors.type &&
                          'is-invalid'
                        }`}
                        id="type"
                        name="type"
                        placeholder="Type"
                        value={validation.values.type}
                        disabled={shareView}
                        onChange={e => {
                          dispatch(setType(e.target.value));
                          validation.handleChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value={ShareTransmissionTypeEnum.PROBATE}>
                          Probate
                        </option>
                        <option
                          value={
                            ShareTransmissionTypeEnum.SUCCESSION_CERTIFICATE
                          }
                        >
                          Succession Certificate
                        </option>
                        <option
                          value={ShareTransmissionTypeEnum.INDEMNITY_BOND}
                        >
                          Indemnity Bond
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="shareType">Share Type*</label>
                      {validation.touched.shareType &&
                      validation.errors.shareType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.shareType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.shareType &&
                          validation.errors.shareType &&
                          'is-invalid'
                        }`}
                        id="shareType"
                        name="shareType"
                        placeholder="Share Type"
                        value={validation.values.shareType}
                        disabled={true}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={ShareTypeEnum.ORDINARY_SHARE}>
                          Ordinary Share
                        </option>
                        <option value={ShareTypeEnum.PREFERENCE_SHARE}>
                          Preference Share
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {conflictingShares && conflictingShares > 0 ? (
                <div className="row mx-0 mt-4 pt-4">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 fw-bold mb-1">
                    Conflicting Share Data
                  </div>
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th>S.No</th>
                            <th>No Of Shares</th>
                            <th>Distinctive No: From</th>
                            <th>Distinctive No: To</th>
                            <th>Folio No</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {conflictingSharesTableData?.map(
                            (val: TransmissionShareTableDto, index: number) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{1}</td>
                                <td>{val.distinctiveNoFrom}</td>
                                <td>{val?.distinctiveNoTo}</td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <select
                                      className="form-control-table"
                                      value={val.folioNo}
                                      onChange={e => {
                                        const { value } = e.target;
                                        const temp = [
                                          ...conflictingSharesTableData,
                                        ];
                                        temp[index].folioNo = parseInt(value);
                                        setConflictingSharesTableData(temp);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {folioNumbers?.map(
                                        (folioNumber: any, index: number) => (
                                          <option
                                            value={folioNumber}
                                            key={index}
                                          >
                                            {folioNumber}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className={
                                        'bx bx-upload bx-sm text-primary'
                                      }
                                      // onClick={() => {
                                      //   if (editableRow === -1) {
                                      //     setEditableRow(index);
                                      //     setTempProducts(products);
                                      //   }
                                      // }}
                                    />
                                    <i
                                      className={
                                        'bx bxs-pencil bx-sm text-success'
                                      }
                                    />
                                  </div>
                                </td> */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <Modal
        size="lg"
        isOpen={modalVisibility}
        toggle={() => {
          toggleModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-plus-circle text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Transmit
              </h5>
            </div>

            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-danger fw-bold ms-auto text-center mb-3">
              {modalError}
            </div>
            <div className="inputFields">
              {Array.from(
                { length: parseInt(validation.values.transmission) },
                (_, i) => (
                  <div className="row" key={i}>
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="percentage">Percentage*</label>
                        </div>
                        <input
                          type="number"
                          className={`form-control form-control-medium`}
                          id="percentage"
                          name="percentage"
                          placeholder="Percentage"
                          min="0"
                          onKeyDown={evt =>
                            ['e', 'E', '+', '-'].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          value={
                            modalData && modalData[i] && modalData[i].percentage
                              ? modalData[i].percentage
                              : undefined
                          }
                          onChange={e => {
                            setModalError('');
                            if (
                              modalData.length < i ||
                              modalData.length === 0 ||
                              modalData.length === i
                            ) {
                              const { value } = e.target;
                              let tempModalData = [...modalData];
                              let tempObj = {
                                percentage: parseFloat(value),
                                folioNo: undefined,
                              };
                              tempModalData.push(tempObj);
                              setModalData(tempModalData);
                            } else {
                              const { value } = e.target;
                              let tempModalData = [...modalData];
                              tempModalData[i].percentage = parseFloat(value);
                              setModalData(tempModalData);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="folioNo">Folio Number*</label>
                        </div>
                        <div
                          style={{ position: 'relative', width: 'max-content' }}
                        >
                          <div className="d-flex justify-content-start align-items-center">
                            <select
                              className={`form-control form-sm`}
                              id="folioNo"
                              name="folioNo"
                              placeholder="Folio No"
                              disabled={shareView}
                              value={
                                modalData &&
                                modalData[i] &&
                                modalData[i].folioNo
                                  ? modalData[i].folioNo
                                  : undefined
                              }
                              onChange={e => {
                                setModalError('');
                                if (
                                  modalData.length < i ||
                                  modalData.length === 0 ||
                                  modalData.length === i
                                ) {
                                  const { value } = e.target;
                                  let tempModalData = [...modalData];
                                  let tempObj = {
                                    percentage: undefined,
                                    folioNo: parseInt(value),
                                  };
                                  tempModalData.push(tempObj);
                                  setModalData(tempModalData);
                                } else {
                                  const { value } = e.target;
                                  let tempModalData = [...modalData];
                                  tempModalData[i].folioNo = parseInt(value);
                                  setModalData(tempModalData);
                                }
                              }}
                            >
                              <option value="">Select</option>
                              {folioNumbers?.map(
                                (folioNumber: any, index: number) => (
                                  <option value={folioNumber} key={index}>
                                    {folioNumber}
                                  </option>
                                )
                              )}
                            </select>
                            {!shareView ? (
                              <button
                                type="button"
                                className="btn primaryDismissButton d-flex align-items-center mb-3 mx-3 fw-bold"
                                onMouseDown={() => {
                                  dispatch(setModalDataRedux(modalData));
                                  dispatch(setMemberViewScreen(false));
                                  createDraftCompanyMember({
                                    variables: {
                                      companyId: companyID,
                                    },
                                  })
                                    .then((res: any) => {
                                      if (
                                        res.data.draft_createCompanyMemberDraft
                                          .draftCompanyMember
                                      ) {
                                        dispatch(
                                          setSelectedMemberId(
                                            res.data
                                              .draft_createCompanyMemberDraft
                                              .draftCompanyMember.memberId
                                          )
                                        );
                                        navigate(
                                          '/companyMembers/primaryShareHolderInformation?isNewFolioForTransmission=true'
                                        );
                                      }
                                    })
                                    .catch(err => {
                                      dispatch(setToastError(err));
                                    });
                                }}
                              >
                                New Folio
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  onMouseDown={() => {
                    let sumOfPercentage = 0;
                    if (modalData.length === 0) {
                      setModalError('Please enter values of all fields');
                    }
                    let lastDistinctiveNoTo = 0;
                    modalData.forEach((element: any) => {
                      if (!element.percentage || !element.folioNo) {
                        setModalError('Please enter values of all fields');
                      } else {
                        var tempConflictingShares = 0;
                        modalData.forEach((element: any, index: number) => {
                          let tempModalData = [...modalData];
                          let shares =
                            (element.percentage *
                              validation.values.noOfShares) /
                            100;
                          tempConflictingShares =
                            tempConflictingShares + shares - Math.floor(shares);
                          tempModalData[index].noOfShares = Math.floor(shares);
                          setModalData(tempModalData);
                        });
                        setConflictingShares(Math.ceil(tempConflictingShares));
                        let tempTableData: TransmissionShareTableDto[] = [];
                        let lastIndexDistinctiveNoTo = 0;
                        modalData.forEach((element: any, index: number) => {
                          let tempObj: TransmissionShareTableDto = {
                            folioNo: element.folioNo,
                            distinctiveNoFrom:
                              index === 0
                                ? shareData.DistinctiveNoFrom
                                : element.percentage
                                ? lastIndexDistinctiveNoTo + 1
                                : null,
                            distinctiveNoTo:
                              lastIndexDistinctiveNoTo + element.noOfShares,
                            // index === 0
                            //   ? element.percentage
                            //     ? shareData.DistinctiveNoFrom +
                            //       element.noOfShares -
                            //       1
                            //     : null
                            //   : element.percentage
                            //   ? shareData.DistinctiveNoFrom +
                            //     element.noOfShares +
                            //     element.noOfShares -
                            //     1
                            //   : null,
                            percentage: element.percentage,
                          };

                          if (element.percentage) {
                            sumOfPercentage =
                              sumOfPercentage + element.percentage;
                          }
                          if (index === 0 && element.percentage) {
                            lastIndexDistinctiveNoTo =
                              shareData.DistinctiveNoFrom +
                              element.noOfShares -
                              1;
                          } else {
                            lastIndexDistinctiveNoTo =
                              lastIndexDistinctiveNoTo + element.noOfShares;
                          }
                          tempTableData.push(tempObj);
                        });
                        if (sumOfPercentage === 100) {
                          var filteredTableData = tempTableData.filter(
                            element => {
                              if (
                                element?.distinctiveNoFrom &&
                                element?.distinctiveNoTo &&
                                element?.distinctiveNoTo > 0 &&
                                element?.distinctiveNoFrom <
                                  element?.distinctiveNoTo
                              ) {
                                lastDistinctiveNoTo = element?.distinctiveNoTo;
                              }
                              return (
                                element?.distinctiveNoFrom &&
                                element?.distinctiveNoTo &&
                                element?.distinctiveNoTo > 0 &&
                                element?.distinctiveNoFrom <=
                                  element?.distinctiveNoTo
                              );
                            }
                          );
                          setTableData(filteredTableData);
                          dispatch(setModalVisibility(false));
                        } else {
                          setModalError(
                            'Sum of percentage of all shares should be equal to 100'
                          );
                        }
                        if (tempConflictingShares) {
                          let tempConflictingTableData: TransmissionShareTableDto[] =
                            [];
                          for (
                            let index = 0;
                            index < tempConflictingShares;
                            index++
                          ) {
                            let tempObj: TransmissionShareTableDto = {
                              distinctiveNoFrom:
                                lastDistinctiveNoTo + index + 1,
                              distinctiveNoTo: lastDistinctiveNoTo + index + 1,
                            };
                            tempConflictingTableData.push(tempObj);
                          }
                          setConflictingSharesTableData(
                            tempConflictingTableData
                          );
                        }
                      }
                    });
                    // var filteredModalData = modalData.filter(element => {
                    //   return element?.noOfShares && element?.noOfShares > 0;
                    // });

                    // setModalData(filteredModalData);
                  }}
                >
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
