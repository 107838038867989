import { createSlice } from '@reduxjs/toolkit';
import { FinancialYearStoreType } from './types';

const initialState: FinancialYearStoreType = {
  selectedFinancialYearId: '',
  financialYearView: false,
};
const financialYearSlice = createSlice({
  name: 'financialYear',
  initialState,
  reducers: {
    setSelectedFinancialYearId: (state, { payload }) => {
      state.selectedFinancialYearId = payload;
    },
    setFinancialYearViewScreen: (state, { payload }) => {
      state.financialYearView = payload;
    },
  },
});

export const {
  setSelectedFinancialYearId,
  setFinancialYearViewScreen,
} = financialYearSlice.actions;

export default financialYearSlice.reducer;
