import { gql } from '@apollo/client';

export const GET_ALL_ORDINANCE = gql`
query findAllOrdinance(
  $ordinance: String   
  $first: Int
  $last: Int
  $after: String
  $before: String
)
{
  findAllOrdinance(
    filterBy: { ordinanceName: $ordinance}    
    first: $first
    last: $last
    after: $after
    before: $before


  )
  {
    edges{
      node{
        ordinanceId
        ordinanceName
        section{
          sectionId
          sectionName
        }
      }
      cursor
    }
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    
`;



export const DELETE_ORDINANCE = gql`

mutation deleteOrdinance(
  $id: String!
) {
    deleteOrdinance(data:{
      id:$id
      
    }){
      count
    }
  }
 `;




export const CREATE_ORDINANCE = gql`
mutation createOrdinance(
  $ordinanceName: String!
  $section: [SectionInput]
)
{
  createOrdinance(data:{
    ordinanceName: $ordinanceName
    section:$section
  }){
    ordinance{
      ordinanceId
      ordinanceName
      section{
        sectionId
        sectionName
      }
    }
  }
  
}`
  





export const FIND_ONE_ORDINANCE = gql`
query findOneOrdinance(
  $id: ID!
)
{
  findOneOrdinance(id:$id){
    ordinance{
      ordinanceId
      ordinanceName
      section{
        sectionId
        sectionName
        ordinanceId
      }
    }
  }
  
}`


export const UPDATE_ORDINANCE = gql`
mutation updateOrdinance(
  $id: String!
  $ordinanceName: String!
  $section: [SectionInput]
)
{
  updateOrdinance(data:{
    id: $id
    data:{
      ordinanceName: $ordinanceName
          section: $section
    }
  }){
    ordinance{
      ordinanceId
      ordinanceName
      section{
        sectionId
        sectionName
        ordinanceId
      }
    }   
  }
}
`
  

