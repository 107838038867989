import React from 'react';
import {
  bank,
  bankaccount,
  bankaccountG,
  committe,
  committeG,
  companymembers,
  companymembersG,
  companyofficer,
  companyofficerG,
  companyprofile,
  companyprofileG,
  dashboard,
  dashboardG,
  financialyear,
  financialyearG,
  legaldocument,
  legaldocumentG,
  meetingmanagement,
  meetingmanagementG,
  notification,
  notificationG,
  register,
  registerG,
  sharemanagement,
  sharemanagementG,
  systemuser,
  systemuserG,
  systemusergroup,
  systemusergroupG,
} from '~/assets/images';
import { ModuleEnum } from '~/commons/enums';

export const SidebarData = [
  {
    id: 'DASHBOARD',
    title: 'Dashboard',
    path: '/dashboard',
    whiteIcon: <img className="sidebar-icons" src={dashboard}></img>,
    greenIcon: <img className="sidebar-icons" src={dashboardG}></img>,
  },
  {
    id: ModuleEnum.COMPANY_PROFILE,
    title: 'Company Profile',
    path: '/companyProfile',
    whiteIcon: <img className="sidebar-icons" src={companyprofile}></img>,
    greenIcon: <img className="sidebar-icons" src={companyprofileG}></img>,
  },
  {
    id: ModuleEnum.COMPANY_OFFICERS,
    title: 'Company Officers',
    path: '/companyOfficer',

    whiteIcon: <img className="sidebar-icons" src={companyofficer}></img>,
    greenIcon: <img className="sidebar-icons" src={companyofficerG}></img>,
  },
  {
    id: ModuleEnum.COMPANY_MEMBERS,
    title: 'Company Members',
    path: '/companyMembers',
    whiteIcon: <img className="sidebar-icons" src={companymembers}></img>,
    greenIcon: <img className="sidebar-icons" src={companymembersG}></img>,
  },
  // {
  //   title: 'Agenda',
  //   path: '/agenda',
  //   whiteIcon: <i className="bx bx-home-circle" />,
  // greenIcon: <i className="bx bx-home-circle" />,
  // },
  {
    id: ModuleEnum.COMMITTEE,
    title: 'Committee',
    path: '/committee',
    whiteIcon: <img className="sidebar-icons" src={committe}></img>,
    greenIcon: <img className="sidebar-icons" src={committeG}></img>,
  },
  {
    id: ModuleEnum.MEETING,
    title: 'Meeting Management',
    path: '/meetings',
    whiteIcon: <img className="sidebar-icons" src={meetingmanagement}></img>,
    greenIcon: <img className="sidebar-icons" src={meetingmanagementG}></img>,
  },
  {
    id: ModuleEnum.SHARE_MANAGEMENT,
    title: 'Shares Management',
    path: '/shares',
    whiteIcon: <img className="sidebar-icons" src={sharemanagement}></img>,
    greenIcon: <img className="sidebar-icons" src={sharemanagementG}></img>,
  },
  {
    id: ModuleEnum.BANK_ACCOUNTS,
    title: 'Bank Account',
    path: '/bankAccounts',
    whiteIcon: <img className="sidebar-icons" src={bankaccount}></img>,
    greenIcon: <img className="sidebar-icons" src={bankaccountG}></img>,
  },
  {
    id: ModuleEnum.NOTIFICATION,
    title: 'Notifications',
    path: '/notifications',
    whiteIcon: <img className="sidebar-icons" src={notification}></img>,
    greenIcon: <img className="sidebar-icons" src={notificationG}></img>,
  },
  {
    id: ModuleEnum.FINANCIAL_YEAR,
    title: 'Financial Year',
    path: '/financialYear',
    whiteIcon: <img className="sidebar-icons" src={financialyear}></img>,
    greenIcon: <img className="sidebar-icons" src={financialyearG}></img>,
  },

  {
    id: ModuleEnum.LEGAL_DOCUMENTS,
    title: 'Legal Documents',
    path: '/legalDoc',
    whiteIcon: <img className="sidebar-icons" src={legaldocument}></img>,
    greenIcon: <img className="sidebar-icons" src={legaldocumentG}></img>,
  },

  {
    id: ModuleEnum.REGISTER,
    title: 'Registers',
    path: '/register',
    whiteIcon: <img className="sidebar-icons" src={register}></img>,
    greenIcon: <img className="sidebar-icons" src={registerG}></img>,
  },
  {
    id: ModuleEnum.SYSTEM_USER,
    title: 'System Users',
    path: '/systemuser',
    whiteIcon: <img className="sidebar-icons" src={systemuser}></img>,
    greenIcon: <img className="sidebar-icons" src={systemuserG}></img>,
  },
  {
    id: ModuleEnum.SYSTEM_USER_GROUPS,
    title: 'System User Groups',
    path: '/usergroup',
    whiteIcon: <img className="sidebar-icons" src={systemusergroup}></img>,
    greenIcon: <img className="sidebar-icons" src={systemusergroupG}></img>,
  },
  // {
  //   title: 'Access Privileges',
  //   path: '/permission',
  //   whiteIcon: <i className="bx bx-home-circle" />,
  // greenIcon: <i className="bx bx-home-circle" />,
  // },
];

export const AdminSidebarData = [
  {
    title: 'Dashboard',
    path: '/admin/dashboard',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'Agenda',
    path: '/admin/agenda',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'Ordinance',
    path: '/admin/ordinance',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'System Users',
    path: '/admin/user',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'Subscription Plans',
    path: '/admin/subscription',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Help Section',
    path: '/admin/help',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Business Rules',
    path: '/admin/businessRules',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'Company Profiles',
    path: '/admin/companyProfiles',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
];

export const IntermediarySidebarData = [
  {
    title: 'Intermediary User',
    path: '/intermediary',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },

  {
    title: 'Subscription Package',
    path: '/subscriptionPackage',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
];
export const AddCompany = [
  {
    title: 'Company Information',
    path: '/companyProfile/companyInformation',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Registered Office Address',
    path: '/companyProfile/registeredOfficeAddress',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Subsidiary(ies)',
    path: '/companyProfile/subsidiary',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Mortgages / Charges',
    path: '/companyProfile/mortgagesCharges',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Company Address History',
    path: '/companyProfile/companyAddressHistory',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Capital Structure',
    path: '#',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
    iconClosed: <i className="bx bxs-chevron-down" />,
    iconOpened: <i className="bx bx-chevron-up" />,

    subNav: [
      {
        title: 'Advanced Capital History',
        path: '/companyProfile/advancedCapitalHistory',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Paid Up Capital History',
        path: '/companyProfile/paidUpCapitalHistory',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
    ],
  },
];

export const AddOfficer = [
  {
    title: 'Officer Information',
    path: '#',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
    iconClosed: <i className="bx bxs-chevron-down" />,
    iconOpened: <i className="bx bx-chevron-up" />,

    subNav: [
      {
        title: 'Personal Information',
        path: '/companyOfficer/personalInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Officer Address',
        path: '/companyOfficer/officerAddress',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Other Information',
        path: '/companyOfficer/otherInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
    ],
  },
  {
    title: 'Officer History',
    path: '/companyOfficer/officerHistory',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
];

export const AddCompanyMembers = [
  {
    title: 'Member Information',
    path: '/companyMembers/primaryShareHolderInformation',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
    iconClosed: <i className="bx bxs-chevron-down" />,
    iconOpened: <i className="bx bx-chevron-up" />,
    subNav: [
      {
        title: 'Primary Shareholder Information',
        path: '/companyMembers/primaryShareHolderInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Primary Shareholder Address',
        path: '/companyMembers/primaryShareHolderAddress',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Membership Information',
        path: '/companyMembers/membershipInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Instructions',
        path: '/companyMembers/instructions',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
    ],
  },
  {
    title: 'Joint Shareholder Information',
    path: '/companyMembers/jointShareholderInformation',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Nominee Information',
    path: '/companyMembers/nomineeInformation',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
    iconClosed: <i className="bx bxs-chevron-down" />,
    iconOpened: <i className="bx bx-chevron-up" />,
    subNav: [
      {
        title: 'Nominee Information',
        path: '/companyMembers/nomineeInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Nominee Address',
        path: '/companyMembers/nomineeAddress',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
      {
        title: 'Minor Information',
        path: '/companyMembers/minorInformation',
        whiteIcon: <i className="bx bx-home-circle" />,
        greenIcon: <i className="bx bx-home-circle" />,
      },
    ],
  },
];
// export const AddAgenda = [
//   {
//     title: 'Agenda Information',
//     path: '/agenda/add',
//     whiteIcon: <i className="bx bx-home-circle" />,
// greenIcon: <i className="bx bx-home-circle" />,
//   },
// ];
export const AddMeeting = [
  {
    title: 'Meeting Info',
    path: '/meetings/info',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Agenda',
    path: '/meetings/agenda',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
  {
    title: 'Review',
    path: '/meetings/review',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
];
export const AddBankAccount = [
  {
    title: 'Bank Accounts',
    path: '/bankAccounts/add',
    whiteIcon: <i className="bx bx-home-circle" />,
    greenIcon: <i className="bx bx-home-circle" />,
  },
];
