import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import { useLazyQuery, useMutation } from '@apollo/client';
import { ActionItemsInputDto } from './types';
import { v4 as uuidv4 } from 'uuid';
import './users.scss';
import { FIND_USER, UPDATE_PROFILE_OF_USER } from '~/Store/graphQL/Users';

export const EditProfile: FC = () => {
  const { selectedUserId, agendaView } = useAppSelector(
    state => state.userReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>();
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/admin/user',
          title: 'System Users',
        },
        {
          url: location.pathname,
          title: 'Edit User Profile',
        },
      ])
    );
  }, []);
  // Find data of user
  const [
    findUserData,
    { loading: loadingUserData, error: errorUserData, data: dataUserData },
  ] = useLazyQuery<any>(FIND_USER, {
    fetchPolicy: 'no-cache',
  });
  //   update profile
  const [
    updateProfile,
    {
      loading: loadingUpdateProfile,
      error: errorUpdateProfile,
      data: dataUpdateProfile,
    },
  ] = useMutation<any>(UPDATE_PROFILE_OF_USER);

  useEffect(() => {
    if (selectedUserId) {
      findUserData({
        variables: {
          id: selectedUserId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedUserId]);
  useEffect(() => {
    if (dataUserData) {
      setUserData(dataUserData?.user);
    } else if (errorUserData) {
      console.log('errorUserData:', errorUserData);
    }
  }, [dataUserData, errorUserData]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      firstName: userData ? userData.firstName : '',
      lastName: userData ? userData.lastName : '',
      email: userData ? userData.email : '',
      phoneNumber: userData ? userData.phoneNumber : '',
      password: userData ? userData.password : '',
      cnic: userData ? userData.cnic : '',
      isActive: userData ? userData.isActive : '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please enter first name').nullable(),
      lastName: Yup.string().required('Please enter last name').nullable(),
      email: Yup.string()
        .email('Incorrect email')
        .required('Please enter Email')
        .nullable(),
      phoneNumber: Yup.string()
        .required('Please enter phone number')
        .nullable(),
      // password: Yup.string().required('Please enter password').nullable(),
      cnic: Yup.string()
        .matches(
          /^\d{5}-\d{7}-\d$/,
          'CNIC must be in the format xxxxx-xxxxxxx-x'
        )
        .required('Please enter action item type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (userData && selectedUserId) {
        updateProfile({
          variables: {
            id: selectedUserId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            phoneNumber: values.phoneNumber,
            cnic: values.cnic,
            isActive: values.isActive,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateProfile?.user) {
              dispatch(setToastSuccess('User profile updated!'));
              navigate(`/admin/user`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingUserData ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentHeading">User Info</div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">User Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="firstName">First Name*</label>
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.firstName}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.firstName &&
                        validation.errors.firstName &&
                        'is-invalid'
                      }`}
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      value={validation.values.firstName}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="lastName">Last Name*</label>
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.lastName &&
                        validation.errors.lastName &&
                        'is-invalid'
                      }`}
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      value={validation.values.lastName}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="email">Email*</label>
                      {validation.touched.email && validation.errors.email ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.email &&
                        validation.errors.email &&
                        'is-invalid'
                      }`}
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={validation.values.email}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="phoneNumber">Phone Number*</label>
                      {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.phoneNumber}
                        </p>
                      ) : null}
                    </div>
                    <PhoneInput
                      containerStyle={{ padding: 0, width: '100%' }}
                      buttonClass="button-class"
                      containerClass="form-control form-control-medium"
                      inputClass="my-input-class"
                      inputStyle={{
                        border: 0,
                        height: 48,
                        width: '100%',
                      }}
                      buttonStyle={{
                        height: 48,
                      }}
                      countryCodeEditable={false}
                      preferredCountries={['pk']}
                      country={'pk'}
                      value={validation.values.phoneNumber}
                      onChange={e => {
                        validation.setFieldValue('phoneNumber', e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="password">Password*</label>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.password}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.password &&
                        validation.errors.password &&
                        'is-invalid'
                      }`}
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={validation.values.password}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="cnic">CNIC*</label>
                      {validation.touched.cnic && validation.errors.cnic ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.cnic}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium ${
                        validation.touched.cnic &&
                        validation.errors.cnic &&
                        'is-invalid'
                      }`}
                      id="cnic"
                      name="cnic"
                      placeholder="CNIC"
                      value={validation.values.cnic}
                      disabled={agendaView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group h-100">
                    <div className="h-100">
                      <label className="form-check-label">Activate User*</label>
                      <div className="d-flex form-check  form-switch mb-1 ms-2 mt-3 ">
                        <input
                          className="form-check-input"
                          style={{ transform: 'scale(1.5)' }}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={validation.values.isActive}
                          onChange={e => {
                            const { checked } = e.target;
                            validation.setFieldValue('isActive', checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"></div>
                <div className="col-xxl-4 col-xl-6"></div>
              </div>

              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  disabled={loadingUserData || loadingUpdateProfile}
                  onMouseDown={() => {
                    navigate('/admin/user');
                  }}
                >
                  Cancel
                </button>
                {!agendaView ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={loadingUserData || loadingUpdateProfile}
                    onMouseDown={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {(loadingUserData || loadingUpdateProfile) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
