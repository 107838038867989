import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './committee.scss';
import {
  setCommitteeHead,
  setCommitteeMembers,
  setCommitteeName,
  setCommitteeViewScreen,
  setIsSendEmailInvitation,
  setSelectedCommitteeId,
  setStartDate,
} from '~/Store/reducers/committee';
import {
  CONCLUDE_Committee,
  DELETE_COMMITTEE,
  GET_ALL_COMMITTEES_OF_COMPANY,
} from '~/Store/graphQL/CommitteeManagement';
import {
  CommitteeSearchParameterOptionsEnum,
  CommitteeStatusEnum,
  DesignationEnum,
  MemberSearchParameterOptionsEnum,
  ModuleEnum,
  OfficerStatusEnum,
  PermissionCategoryEnum,
  SearchParameterOptionsEnum,
  UserTypeEnum,
} from '~/commons/enums';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { Tooltip } from 'reactstrap';
import { ConcludeIcon } from '~/assets/images';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const Committee: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  // const [filters, setFilters] = useState<any>({
  //   searchKey: '',
  //   searchValue: '',
  // });
  const [dropDownRow, setDropDownRow] = useState(-1);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  const [concludeToolTipIsOpen, setConcludeToolTipIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Committee',
        },
      ])
    );
  }, []);
  // Get all committees query
  const [
    getAllCommitteesForCompany,
    {
      loading: loadingGetAllCommitteesForCompany,
      error: errorGetAllCommitteesForCompany,
      data: dataGetAllCommitteesForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_COMMITTEES_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  // delete committee
  const [
    deleteCommittee,
    {
      loading: loadingDeleteCommittee,
      error: errorDeleteCommittee,
      data: dataDeleteCommittee,
    },
  ] = useMutation<any>(DELETE_COMMITTEE);
  //   conclude committee
  const [
    concludeCommittee,
    {
      loading: loadingConcludeCommittee,
      error: errorConcludeCommittee,
      data: dataConcludeCommittee,
    },
  ] = useMutation<any>(CONCLUDE_Committee);
  useEffect(() => {
    if (companyID) {
      getAllCommitteesForCompany({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllCommitteesForCompany) {
      dispatch(setToastError(errorGetAllCommitteesForCompany));
    }
  }, [errorGetAllCommitteesForCompany]);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef?.current?.toString() !== event?.target?.toString()
      ) {
        setDropDownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {
      // generatePowerOfAttorneyLetter({
      //   variables: {
      //     sentTo: values.sentToName,
      //     sentToAddress: values.sentToAddress,
      //     auhtorizedToName: values.authorizedToName,
      //     authorizedtoAddress: values.authorizedToAddress,
      //     auhtorizedFromName: values.authorizedFromName,
      //     authorizedFromAddress: values.authorizedFromAddress,
      //     companyId: companyID,
      //   },
      // })
      //   .then((res: any) => {
      //     if (res?.data?.createLetterOfAttorney?.isGenerated) {
      //       window.open(res?.data?.createLetterOfAttorney?.url, '_self');
      //       setModalVisibility(false);
      //       setDropDownVisibility(false);
      //     }
      //   })
      //   .catch(err => {
      //     dispatch(setToastError(err));
      //   });
    },
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Committees</b>
              </h5>
            </div>
          </div>
          <div className="d-flex row mt-2 mb-4">
            <div className="committeefields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="committeeName">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={CommitteeSearchParameterOptionsEnum.NAME}>
                      {' '}
                      Name{' '}
                    </option>
                    <option
                      value={
                        CommitteeSearchParameterOptionsEnum.COMMITTEE_NUMBER
                      }
                    >
                      {' '}
                      Committee number{' '}
                    </option>
                    <option
                      value={
                        CommitteeSearchParameterOptionsEnum.COMMITTEE_HEAD_EMAIL
                      }
                    >
                      {' '}
                      Head Email{' '}
                    </option>
                    <option value={CommitteeSearchParameterOptionsEnum.STATUS}>
                      {' '}
                      Status{' '}
                    </option>
                  </select>
                </div>
              </div>

              {validationSearch?.values.searchKey ===
              CommitteeSearchParameterOptionsEnum.STATUS ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={CommitteeStatusEnum.FORMED}>
                        {' '}
                        Formed{' '}
                      </option>
                      <option value={CommitteeStatusEnum.CONCLUDED}>
                        {' '}
                        Concluded{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type={
                      validationSearch?.values.searchKey ===
                      CommitteeSearchParameterOptionsEnum.COMMITTEE_NUMBER
                        ? 'number'
                        : 'text'
                    }
                    id="searchValue"
                    name="searchValue"
                    placeholder={
                      validationSearch?.values.searchKey ===
                      CommitteeSearchParameterOptionsEnum.COMMITTEE_NUMBER
                        ? 'Number'
                        : 'Text'
                    }
                    className={`form-control ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    onKeyDown={event => {
                      const keyCode = event.keyCode || event.which;

                      // Check if the key pressed is the decimal point
                      if (keyCode === 190 || keyCode === 110) {
                        event.preventDefault(); // Prevent the decimal point from being entered
                      }
                    }}
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}

              <button
                type="button"
                className="btn fw-bold dangerButton d-flex align-items-center my-auto"
                onClick={() => {
                  if (
                    validationSearch?.values?.searchValue &&
                    validationSearch?.values?.searchKey
                  ) {
                    getAllCommitteesForCompany({
                      variables: {
                        first: itemsPerPage,
                        committeeName: undefined,
                      },
                    });
                    validationSearch.resetForm();
                  } else {
                    validationSearch.resetForm();
                  }
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn fw-bold successButton d-flex align-items-center my-auto"
                disabled={loadingGetAllCommitteesForCompany}
                onClick={() => {
                  validationSearch.handleSubmit();
                  if (
                    validationSearch.values.searchValue &&
                    validationSearch.values.searchKey
                  ) {
                    if (
                      validationSearch.values.searchKey !=
                      SearchParameterOptionsEnum.STATUS
                    ) {
                      getAllCommitteesForCompany({
                        variables: {
                          first: itemsPerPage,
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                        },
                      });
                    } else {
                      if (validationSearch.values.searchValue === 'ALL') {
                        getAllCommitteesForCompany({
                          variables: {
                            first: itemsPerPage,
                          },
                        });
                      } else {
                        getAllCommitteesForCompany({
                          variables: {
                            first: itemsPerPage,
                            status: validationSearch.values.searchValue,
                          },
                        });
                      }
                    }
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.COMMITTEE,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMMITTEE,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setCommitteeViewScreen(false));
                dispatch(setSelectedCommitteeId(null));
                dispatch(setCommitteeName(''));
                dispatch(setStartDate(''));
                dispatch(setCommitteeHead(''));
                dispatch(setCommitteeMembers(''));
                dispatch(setIsSendEmailInvitation(false));
                navigate('/committee/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Committee
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.COMMITTEE,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllCommitteesForCompany ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="">
                            <th>S.No</th>
                            <th>Committee Name</th>
                            <th>Committee No</th>
                            <th>Committee Head</th>
                            <th>Committee Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllCommitteesForCompany?.findAllCommittees?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  <div className="badge custom-badge-dark-purple">
                                    {index + 1}
                                  </div>
                                </td>
                                <td>
                                  {val.node.committeeName
                                    ? val.node.committeeName
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-dark-purple">
                                    {val.node.committeeNumber
                                      ? val.node.committeeNumber
                                      : 'N/A'}
                                  </div>
                                </td>

                                <td>
                                  {val.node.committeeHeadEmail
                                    ? val.node.committeeHeadEmail
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-purple">
                                    {val.node.committeeStatus
                                      ? val.node.committeeStatus
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMMITTEE,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMMITTEE,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedCommitteeId(
                                              val.node.committeeId
                                            )
                                          );
                                          dispatch(
                                            setCommitteeViewScreen(true)
                                          );
                                          dispatch(setCommitteeName(''));
                                          dispatch(setStartDate(''));
                                          dispatch(setCommitteeHead(''));
                                          dispatch(setCommitteeMembers(''));
                                          dispatch(
                                            setIsSendEmailInvitation(false)
                                          );
                                          navigate('/committee/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMMITTEE,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMMITTEE,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-edit text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMMITTEE,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(
                                            setCommitteeViewScreen(false)
                                          );
                                          dispatch(
                                            setSelectedCommitteeId(
                                              val.node.committeeId
                                            )
                                          );
                                          dispatch(setCommitteeName(''));
                                          dispatch(setStartDate(''));
                                          dispatch(setCommitteeHead(''));
                                          dispatch(setCommitteeMembers(''));
                                          dispatch(
                                            setIsSendEmailInvitation(false)
                                          );
                                          navigate('/committee/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMMITTEE,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMMITTEE,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMMITTEE,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(
                                            setCommitteeViewScreen(false)
                                          );
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteCommittee({
                                                variables: {
                                                  id: val.node.committeeId,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data.deleteCommittee
                                                      .count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Committee Deleted!'
                                                      )
                                                    );
                                                    getAllCommitteesForCompany({
                                                      variables: {
                                                        first: itemsPerPage,
                                                        searchKey:
                                                          validationSearch
                                                            ?.values
                                                            ?.searchKey !==
                                                          'ALL'
                                                            ? validationSearch
                                                                ?.values
                                                                ?.searchKey
                                                            : undefined,
                                                        searchValue:
                                                          validationSearch
                                                            ?.values
                                                            ?.searchValue !==
                                                          'Select'
                                                            ? validationSearch
                                                                ?.values
                                                                ?.searchValue
                                                            : undefined,
                                                      },
                                                    });
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMMITTEE,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    {val.node.committeeStatus ===
                                    CommitteeStatusEnum.FORMED ? (
                                      <div
                                        className={`d-flex justify-content-center align-items-center ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMMITTEE,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setConcludeToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setConcludeToolTipIsOpen(false)
                                        }
                                      >
                                        <img
                                          id="concludeIcon"
                                          className={`cursor-pointer height-22 padding-2 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.COMMITTEE,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          src={ConcludeIcon}
                                          onClick={e => {
                                            dispatch(
                                              setCommitteeViewScreen(false)
                                            );
                                            dispatch(
                                              setSelectedCommitteeId(
                                                val.node.committeeId
                                              )
                                            );
                                            concludeCommittee({
                                              variables: {
                                                committeeId:
                                                  val.node.committeeId,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res?.data?.concludeCommittee
                                                    ?.committee
                                                ) {
                                                  setDropDownVisibility(false);
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Committee concluded!'
                                                    )
                                                  );
                                                }
                                                getAllCommitteesForCompany({
                                                  variables: {
                                                    first: itemsPerPage,
                                                    searchKey:
                                                      validationSearch?.values
                                                        ?.searchKey !== 'ALL'
                                                        ? validationSearch
                                                            ?.values?.searchKey
                                                        : undefined,
                                                    searchValue:
                                                      validationSearch?.values
                                                        ?.searchValue !==
                                                      'Select'
                                                        ? validationSearch
                                                            ?.values
                                                            ?.searchValue
                                                        : undefined,
                                                  },
                                                });
                                              })
                                              .catch(err => {
                                                setDropDownVisibility(false);
                                                dispatch(setToastError(err));
                                              });
                                          }}
                                        ></img>
                                        {document.getElementById(
                                          'concludeIcon'
                                        ) &&
                                          (!(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMMITTEE,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          ) ? (
                                            <Tooltip
                                              placement="top"
                                              isOpen={concludeToolTipIsOpen}
                                              target="concludeIcon"
                                            >
                                              You don't have permission for this
                                              module
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              placement="top"
                                              isOpen={concludeToolTipIsOpen}
                                              target="concludeIcon"
                                            >
                                              Conclude Committee
                                            </Tooltip>
                                          ))}
                                      </div>
                                    ) : null}

                                    {(GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.COMMITTEE,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) ||
                                      userType ===
                                        UserTypeEnum.COMPANY_ADMIN) &&
                                    val.node.committeeStatus ===
                                      CommitteeStatusEnum.CONCLUDED ? (
                                      <div className="dropleft d-flex justify-content-center align-items-center">
                                        <i
                                          id="dropdownMenuButton"
                                          ref={dropdownRef}
                                          className="bx bx-dots-vertical-rounded"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          onClick={() => {
                                            setDropDownRow(index);
                                            toggleModal();
                                          }}
                                        ></i>
                                        <ul
                                          className={`dropdown-menu min-width-8 ${
                                            dropDownVisibility &&
                                            index === dropDownRow &&
                                            'show'
                                          } `}
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {val.node.committeeStatus ===
                                          CommitteeStatusEnum.CONCLUDED ? (
                                            <li className="cursor-pointer">
                                              <div
                                                className="dropdown-item"
                                                onFocus={() => {
                                                  setDropDownVisibility(false);
                                                }}
                                                onClick={() => {
                                                  dispatch(
                                                    setCommitteeViewScreen(
                                                      false
                                                    )
                                                  );
                                                  const uniqueTimestamp =
                                                    Date.now();
                                                  window.open(
                                                    `${val?.node?.url}?t=${uniqueTimestamp}`,
                                                    'new'
                                                  );
                                                  setDropDownVisibility(false);
                                                }}
                                              >
                                                Generate Report
                                              </div>
                                            </li>
                                          ) : null}
                                        </ul>
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllCommitteesForCompany?.findAllCommittees?.edges
                        ?.length === 0 ||
                        dataGetAllCommitteesForCompany?.findAllCommittees
                          ?.edges === undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllCommitteesForCompany?.findAllCommittees
                            ?.pageInfo?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllCommitteesForCompany?.findAllCommittees
                              ?.pageInfo?.hasPreviousPage
                          ) {
                            getAllCommitteesForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                last: itemsPerPage,
                                before:
                                  dataGetAllCommitteesForCompany
                                    ?.findAllCommittees?.pageInfo?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllCommitteesForCompany?.findAllCommittees
                            ?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllCommitteesForCompany?.findAllCommittees
                              ?.pageInfo?.hasNextPage
                          ) {
                            getAllCommitteesForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                after:
                                  dataGetAllCommitteesForCompany
                                    ?.findAllCommittees?.pageInfo?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
