import { gql } from '@apollo/client';

export const PROXY_DATA = gql`
query createProxyRegister(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createProxyRegister(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;

export const MINUTEBOOK_DATA = gql`
query createRegisterOfMinuteBook(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createRegisterOfMinuteBook(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;
export const OFFICER_DATA = gql`
query createRegisterOfOfficer(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createRegisterOfOfficers(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;

export const MEMBER_DATA = gql`
query createMemberRegister(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createMemberRegister(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;

export const INDEX_DATA = gql`
query createIndexRegister(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createIndexRegister(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;

export const DIRECTOR_SHARES_DATA = gql`
query createRegisterOfDirectorShares(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createRegisterOfDirectorShares(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;

export const SHARETRANSFER_DATA = gql`
query createshareTransferRegister(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String


) {
  createTransferRegister(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;


export const PURCHASE_OF_SHARES_DATA = gql`
query createRegisterOfPurchaseOfShares(
  $startDate: String
  $endDate: String
  $companyId: String
  $meetingType: String

  
) {
  createRegisterOfPurchaseOfShares(
    filterBy: {
      companyId: $companyId
      startDate: $startDate
      endDate: $endDate
      meetingType:$meetingType
    }
  ) {
    isGenerated
    url
  }
}
 
`;















