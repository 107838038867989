import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './profiles.scss';

import {
  GET_ALL_ADMIN_COMPANY_PROFILES,
  UPDATE_COMPANY_PROFILE,
} from '~/Store/graphQL/CompanyProfile';
import GetCompanyKind from '~/helpers/CompanyProfile/getCompanyKind';

export const AdminCompanyProfiles: FC = () => {
  let pageNo = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    profile: '',
  });
  useEffect(() => {
    getAllProfiles();
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Company Profiles',
        },
      ])
    );
  }, []);

  useEffect(() => {
    getAllProfiles();
  }, [itemsPerPage]);

  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(UPDATE_COMPANY_PROFILE);

  useEffect(() => {
    getAllProfiles();
  }, [dataupdateCompanyProfile]);

  const [
    getAllProfiles,
    {
      loading: loadingGetAllProfiles,
      error: errorGetAllProfiles,
      data: dataGetAllProfiles,
    },
  ] = useLazyQuery<any>(GET_ALL_ADMIN_COMPANY_PROFILES, {
    variables: {
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            {/* Second Row */}
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label> Company Profiles</label>
                </div>
                <div className="field">
                  <input
                    id="profile"
                    name="profile"
                    placeholder="Search"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.profile}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.profile = value;
                      setFilters(temp);
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.profile) {
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.profile = '';
                      setFilters(temp);
                      getAllProfiles();
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  // disabled={loadingGetAllAgendaForCompany}
                  onClick={() => {
                    getAllProfiles({
                      variables: {
                        profile: filters?.profile
                          ? filters?.profile
                          : undefined,
                      },
                    });
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>

          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllProfiles ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th className="col-1">S.No</th>
                            <th className="col">Company Name</th>
                            <th className="col">Email</th>
                            <th className="col">Mobile</th>
                            <th className="col">CUIN</th>
                            <th className="col">Kind</th>

                            <th className="col-1">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllProfiles?.findCompanyProfiles?.edges?.map(
                            // const n;
                            (profile: any, profileIndex: number) => (
                              // profile?.node?.section.map(
                              //   (section: any, sectionIndex: number) => (

                              <tr key={`${profileIndex}`}>
                                <td>
                                  {pageNo.current * itemsPerPage +
                                    (profileIndex + 1)}
                                </td>
                                <td>{profile.node.name}</td>
                                <td>{profile.node.email}</td>
                                <td>{profile.node.mobile}</td>
                                <td>{profile.node.cuinNumber}</td>
                                <td>{GetCompanyKind(profile.node.kind)}</td>

                                {/* <td>{section.sectionName}</td> */}

                                <td>
                                  <div className="col-1 w-100 ">
                                    <div className="d-flex form-check  form-switch d-flex text-center  w-100   justify-content-center  ">
                                      <input
                                        className="form-check-input  text switcher"
                                        style={{
                                          transform: 'scale(1)',
                                        }}
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        checked={profile.node.isActive}
                                        onChange={e => {
                                          const { checked } = e.target;

                                          {
                                            updateCompanyProfile({
                                              variables: {
                                                id: profile.node.companyId,
                                                isActive: checked,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                            //   )
                            // )
                            ///
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllProfiles?.findCompanyProfiles?.edges
                        ?.length === 0 ||
                        dataGetAllProfiles?.findCompanyProfiles?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                  <p className="m-0 text-info">Rows Per Page:</p>
                  <select
                    className="form-control button-danger width-5vw"
                    value={itemsPerPage}
                    onChange={e => {
                      const { value } = e.target;
                      setItemsPerPage(Number(value));
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <i
                    className={
                      dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                        ?.hasPreviousPage
                        ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                        : 'bx bx-caret-left bx-sm ms-auto'
                    }
                    onClick={() => {
                      if (
                        dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                          ?.hasPreviousPage
                      ) {
                        getAllProfiles({
                          variables: {
                            last: itemsPerPage,
                            before:
                              dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                                ?.startCursor,
                          },
                        });
                        pageNo.current = pageNo.current - 1;
                      }
                    }}
                  />

                  <i
                    className={
                      dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                        ?.hasNextPage
                        ? 'bx bx-caret-right color-primary bx-sm'
                        : 'bx bx-caret-right bx-sm'
                    }
                    onClick={() => {
                      if (
                        dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                          ?.hasNextPage
                      ) {
                        getAllProfiles({
                          variables: {
                            after:
                              dataGetAllProfiles?.findCompanyProfiles?.pageInfo
                                ?.endCursor,
                          },
                        });
                        pageNo.current = pageNo.current + 1;
                      }
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
