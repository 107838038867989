import { gql } from '@apollo/client';
export const GET_ALL_BUSINESS_RULES = gql`
  query findAllBusinessRules(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyKind: String
    $isActive: Boolean
  ) {
    findAllBusinessRules(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { kind: $companyKind, isActive: $isActive }
      orderBy: "createdAt"
    ) {
      edges {
        node {
          id
          name
          ordinance
          section
          kind
          isActive
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_BUSINESS_RULE_BY_ID = gql`
  query findOneBusinessRule($id: ID!) {
    findOneBusinessRule(id: $id) {
      businessRule {
        id
        name
        ordinance
        section
        kind
        ruleEvents {
          id
          name
          duration
          businessRuleId
          attachment
          url
        }
      }
    }
  }
`;
export const UPDATE_BUSINESS_RULE = gql`
  mutation updateBusinessRule(
    $id: String!
    $name: String
    $kind: String
    $ordinance: String
    $section: String
    $ruleEvents: [RuleEventInput]
  ) {
    updateBusinessRule(
      data: {
        id: $id
        data: {
          name: $name
          kind: $kind
          ordinance: $ordinance
          section: $section
          ruleEvents: $ruleEvents
        }
      }
    ) {
      businessRule {
        id
        name
        ordinance
        section
        kind
        ruleEvents {
          id
          name
          duration
          businessRuleId
        }
      }
    }
  }
`;
export const UPDATE_STATUS_OF_BUSINESS_RULE = gql`
  mutation updateBusinessRule($id: String!, $isActive: Boolean) {
    updateBusinessRule(data: { id: $id, data: { isActive: $isActive } }) {
      businessRule {
        id
        isActive
      }
    }
  }
`;
export const CREATE_BUSINESS_RULE = gql`
  mutation createBusinessRule(
    $name: String
    $kind: String
    $ordinance: String
    $section: String
    $ruleEvents: [RuleEventInput]
  ) {
    createBusinessRule(
      data: {
        name: $name
        kind: $kind
        ordinance: $ordinance
        section: $section
        ruleEvents: $ruleEvents
      }
    ) {
      businessRule {
        id
      }
    }
  }
`;
export const DELETE_BUSINESS_RULE = gql`
  mutation deleteBusinessRule($id: String!) {
    deleteBusinessRule(data: { id: $id }) {
      count
    }
  }
`;
