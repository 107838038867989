import { OfficerStatusEnum } from '../../commons/enums';
const GetCompanyOfficerStatus = (status: string) => {
  if (status === OfficerStatusEnum.APPOINTED) {
    return 'Appointed';
  } else if (status === OfficerStatusEnum.CEASED) {
    return 'Ceased';
  } else if (status === OfficerStatusEnum.ELECTED) {
    return 'Elected';
  } else if (status === OfficerStatusEnum.REELECTED) {
    return 'Re-Elected';
  } else if (status === OfficerStatusEnum.RESIGNED) {
    return 'Resigned';
  } else if (status === OfficerStatusEnum.RETIRED) {
    return 'Retired';
  }
};

export default GetCompanyOfficerStatus;
