import React, {
  Component,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './bankAccount.scss';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import {
  FIND_BANK_ACCOUNT_BY_ID,
  UPDATE_BANK_ACCOUNT,
  CREATE_BANK_ACCOUNT,
} from '~/Store/graphQL/BankAccountManagement';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import './bankAccount.scss';
import { AddressIcon, bank, people } from '~/assets/images';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';

import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';

export const AddBankAccount: FC = () => {
  const { selectedBankAccountId, bankAccountView } = useAppSelector(
    state => state.bankAccountReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [bankAccountData, setBankAccountData] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [cities, setCities] = useState<ICity>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/bankAccounts',
          title: 'Bank account',
        },
        {
          url: location.pathname,
          title: 'Add Bank Account',
        },
      ])
    );
  }, []);
  useEffect(() => {
    setCities(City.getCitiesOfCountry('PK'));
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.BANK_ACCOUNTS,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.BANK_ACCOUNTS,
    },
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });

  // Getting bank account information
  const [
    findBankAccountById,
    {
      loading: loadingFindBankAccountById,
      error: errorFindBankAccountById,
      data: dataFindBankAccountById,
    },
  ] = useLazyQuery<any>(FIND_BANK_ACCOUNT_BY_ID);
  //   update bank account
  const [
    updateBankAccount,
    {
      loading: loadingUpdateBankAccount,
      error: errorUpdateBankAccount,
      data: dataUpdateBankAccount,
    },
  ] = useMutation<any>(UPDATE_BANK_ACCOUNT);
  // create bank account
  const [
    createBankAccount,
    {
      loading: loadingCreateBankAccount,
      error: errorCreateBankAccount,
      data: dataCreateBankAccount,
    },
  ] = useMutation<any>(CREATE_BANK_ACCOUNT);

  useEffect(() => {
    if (selectedBankAccountId) {
      findBankAccountById({
        variables: {
          id: selectedBankAccountId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedBankAccountId]);
  useEffect(() => {
    if (dataFindBankAccountById) {
      setBankAccountData(
        dataFindBankAccountById?.findOneBankAccount?.bankAccount
      );
      //setActionItems(dataFindBankAccountById?.findOneBankAccount?.agenda?.actionItems);
    } else if (errorFindBankAccountById) {
      console.log('errorFindBankAccountById:', errorFindBankAccountById);
    }
  }, [dataFindBankAccountById, errorFindBankAccountById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      bankName: bankAccountData ? bankAccountData.bankName : '',
      branch: bankAccountData ? bankAccountData.branch : '',
      accountNumber: bankAccountData ? bankAccountData.accountNumber : '',
      city: bankAccountData ? bankAccountData.city : '',
    },
    validationSchema: Yup.object({
      bankName: Yup.string()
        .matches(/^[a-zA-Z0-9 ]+$/, 'Remove special characters')
        .required('Please enter bank name')
        .nullable(),
      branch: Yup.string()
        .matches(/^[a-zA-Z0-9 ]+$/, 'Remove special characters')
        .required('Please enter branch name')
        .nullable(),
      accountNumber: Yup.string()
        .matches(/^[a-zA-Z0-9 ]+$/, 'Remove special characters')
        .required('Please enter account number')
        .nullable(),
      city: Yup.string()
        // .matches(/^[a-zA-Z0-9 ]+$/, 'Remove special characters')
        .required('Please select city name')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (bankAccountData && selectedBankAccountId && !bankAccountView) {
        updateBankAccount({
          variables: {
            id: selectedBankAccountId,
            bankName: values.bankName,
            branch: values.branch,
            accountNumber: values.accountNumber,
            city: values.city,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateBankAccount?.bankAccount) {
              dispatch(setToastSuccess('Bank account updated!'));
              navigate(`/bankAccounts`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createBankAccount({
          variables: {
            companyId: companyID,
            bankName: values.bankName,
            branch: values.branch,
            accountNumber: values.accountNumber,
            city: values.city,
          },
        })
          .then((res: any) => {
            if (res?.data?.createBankAccount?.bankAccount) {
              dispatch(setToastSuccess('Bank account created!'));
              navigate(`/bankAccounts`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingFindBankAccountById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 d-flex parent">
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="justify-content-end  background-primary-medium"
            >
              <div className="">
                <div className="d-flex gap-3 position-relative end-0 mx-4">
                  <button
                    type="button"
                    className="btn primaryButton d-flex align-items-center my-auto fw-bold height-100"
                    onClick={() => {
                      navigate('/bankAccounts');

                      // dispatch(setSelectedUserGroupId(null));
                    }}
                  >
                    Cancel
                  </button>
                  {!bankAccountView ? (
                    <button
                      type="button"
                      className="btn successButton d-flex align-items-center my-auto fw-bold"
                      disabled={bankAccountView}
                      onClick={() => {
                        validation.handleSubmit();
                      }}
                    >
                      {(loadingFindBankAccountById ||
                        loadingUpdateBankAccount ||
                        loadingCreateBankAccount) && (
                        <i className="bx bx-loader-circle bx-spin me-1" />
                      )}
                      Save
                      <i className="bx bx-upload mx-1" />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div id="mainComponentNew">
              <div className="inputFields p-3">
                {/* Fist row starts from here */}

                <div className="row rounded border px-2 pb-4">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center mt-3">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={bank}
                        ></img>
                        <div className="font-weight-600">Bank Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-5 pt-4">
                    <div className="form-group w-50">
                      <div className="d-flex ">
                        <label htmlFor="bankName">Bank Name*</label>
                        {validation.touched.bankName &&
                        validation.errors.bankName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.bankName}
                          </p>
                        ) : null}
                      </div>

                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.bankName &&
                          validation.errors.bankName &&
                          'is-invalid'
                        }`}
                        id="bankName"
                        name="bankName"
                        placeholder="Bank Account Name"
                        value={validation.values.bankName}
                        disabled={bankAccountView}
                        onChange={validation.handleChange}
                      />
                    </div>
                    <div className="form-group w-50">
                      <div className="d-flex ">
                        <label htmlFor="accountNumber">Account Number*</label>
                        {validation.touched.accountNumber &&
                        validation.errors.accountNumber ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.accountNumber}
                          </p>
                        ) : null}
                      </div>

                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.accountNumber &&
                          validation.errors.accountNumber &&
                          'is-invalid'
                        }`}
                        id="accountNumber"
                        name="accountNumber"
                        placeholder="Account Number"
                        value={validation.values.accountNumber}
                        disabled={bankAccountView}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>

                  <div className=""></div>
                </div>

                {/* 2nd row starts from here */}
                <div className="row rounded border px-2 pb-4 mt-4 ">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center mt-3">
                      <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={AddressIcon}
                        ></img>
                        <div className="font-weight-600">Address</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-5 pt-4">
                    <div className="form-group w-50">
                      <div className="d-flex ">
                        <label htmlFor="city">City*</label>
                        {validation.touched.city && validation.errors.city ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.city}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            validation.touched.city &&
                            validation.errors.city &&
                            'is-invalid'
                          }`}
                          id="city"
                          name="city"
                          placeholder="City"
                          value={validation.values.city}
                          disabled={bankAccountView}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {cities?.map((city: ICity) => (
                            <option value={city.name}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group w-50">
                      <div className="d-flex ">
                        <label htmlFor="branch">Branch Name*</label>
                        {validation.touched.branch &&
                        validation.errors.branch ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.branch}
                          </p>
                        ) : null}
                      </div>

                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.branch &&
                          validation.errors.branch &&
                          'is-invalid'
                        }`}
                        id="branch"
                        name="branch"
                        placeholder="Branch Name"
                        value={validation.values.branch}
                        disabled={bankAccountView}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>

                  <div className=""></div>
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};
