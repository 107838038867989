import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { intermediary, task } from '~/assets/images';
import { useNavigate } from 'react-router';

import { FIND_TEMP_NOTIFICATIONS } from '~/Store/graphQL/Notification';
import {
  ADD_USER_TO_COMPANY,
  DELETE_USER_FROM_COMPANY,
  FIND_ALL_USERS,
} from '~/Store/graphQL/Users';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import './intermediary.scss';
import { useFormik } from 'formik';
import { UserTypeEnum } from '~/commons/enums';

export const Intermediary: FC = () => {
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const [userIds, setUserIds] = useState<any>([]);
  const [searchName, setSearchName] = useState<any>();

  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  // const  companyID  = "bcd226a0-8ce2-11ed-8a5e-a306ecc18dec"

  const [
    getAllUsers,
    {
      loading: loadingGetAllUsers,
      error: errorGetAllGetAllUsers,
      data: dataGetAllGetAllUsers,
    },
  ] = useLazyQuery<any>(FIND_ALL_USERS, {
    variables: {
      userType: UserTypeEnum.INTERMEDIAL_USER.toString(),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataGetAllGetAllUsers) {
    }
  }, [dataGetAllGetAllUsers]);

  useEffect(() => {
    getAllUsers();
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          // title: 'Admin Dashboard',
        },
      ])
    );
  }, []);

  // const [
  //   searchUser,
  //   {
  //     loading: loadingsearchUser,
  //     error: errorsearchUser,
  //     data: datasearchUser,
  //   },
  // ] = useLazyQuery<any>(SEARCH_USER, {
  //   fetchPolicy: 'no-cache',
  // });
  // useEffect(() => {
  //   if (datasearchUser?.users) {
  //     // setTemp(datasearchUser.users.edges);
  //   }
  // }, [datasearchUser]);

  const [
    deleteUserFromCompany,
    {
      loading: loadingDeleteUserToCompany,
      error: errorDeleteUserToCompany,
      data: dataDeleteUserToCompany,
    },
  ] = useMutation<any>(DELETE_USER_FROM_COMPANY, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (dataDeleteUserToCompany?.deleteIntermediaryFromCompany?.isDeleted) {
      getAllUsers();
      // console.log(
      //   'ddd res',
      //   dataDeleteUserToCompany.deleteIntermediaryFromCompany.isDeleted
      // );
      // setTemp(dataFindUserGroup.userGroups.edges);
    }
  }, [dataDeleteUserToCompany]);

  function adduser(id: any) {
    let temp: any = userIds;
    let obj = {
      userId,
    };

    obj.userId = id;

    temp.push(obj);
    setUserIds(temp);
  }

  function removeuser(id: any) {
    let temp: any = userIds;

    temp = temp.filter((temp: any) => temp.userId !== id);

    setUserIds(temp);
  }
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      userid: false,
    },
    onSubmit: (values: any) => {},
  });

  return (
    <React.Fragment>
      <div id="mainComponent" className="p-0">
        <div className="w-100">
          <div className="row  m-0 ">
            <div className="row pt-3">
              <div className="d-flex justify-content-start">
                <ul className="nav nav-tabs  w-100">
                  <li
                    className={`nav-item ${true ? 'active' : ''}`}
                    onClick={() => {
                      // setAllNotifications(true);
                    }}
                  >
                    <p className="nav-link">Added Users</p>
                  </li>
                  <li
                    className={`nav-item ${!true ? 'active' : ''}`}
                    onClick={() => {
                      navigate('/intermediary/append');
                      // setAllNotifications(false);
                    }}
                  >
                    <p className="nav-link">Add New User</p>
                  </li>
                </ul>
              </div>
              {/* <hr className="z-n1"></hr> */}
            </div>

            <div className="row mt-2 px-4">
              <div className="d-flex ps-3 gap-3">
                <div className="inputFields ">
                  <div className="form-group d-flex gap-3">
                    {/* <div className="d-flex w-300 pt-2">
                      <label htmlFor="userGroup">Search</label>
                    </div> */}
                    <i className="bx bx-search-alt-2 font-20 iconLeft pt-4"></i>
                    <input
                      type="text"
                      className="form-control ps-5 mt-4 "
                      placeholder="Search.."
                      value={searchName}
                      onChange={e => {
                        setSearchName(e.target.value);
                      }}
                    />

                    <button
                      type="button"
                      className="btn fw-bold dangerButton d-flex align-items-center oneline my-auto "
                      onClick={() => {
                        if (searchName) {
                          setSearchName('');
                          getAllUsers();
                        }
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="btn successButton d-flex align-items-center  align-self-center my-auto fw-bold "
                      onClick={() => {
                        if (searchName) {
                          getAllUsers({
                            variables: {
                              name: searchName,
                            },
                          });
                        }
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="d-flex justify-content-end align-items-center gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center my-auto"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center oneline my-auto "
                  disabled={userIds?.length < 1}
                  onClick={() => {
                    deleteUserFromCompany({
                      variables: {
                        companyId: companyID,
                        ids: userIds,
                      },
                    });
                  }}
                >
                  <i className="bx bxs-trash-alt text-danger me-2"></i>
                  Delete Users
                </button>
              </div>
            </div>
            <div className="row  mt-2 ps-4">
              <div className="user-card ">
                <div className="d-flex gap-1 flex-wrap">
                  {dataGetAllGetAllUsers?.users?.edges.map(
                    (edge: any, index: any) =>
                      edge?.node?.companyId.find(
                        (id: any) => id.companyId == companyID
                      ) && (
                        <div className="col-new mb-3 d-flex ps-0 cardW">
                          <div className="d-flex">
                            <div className="rounded-circle color-img userIcon m-2 w-100">
                              {edge?.node?.firstName
                                .substring(0, 2)
                                .toUpperCase()}
                            </div>

                            <div className="mt-4 pt-1">
                              <h4>
                                {edge?.node?.firstName +
                                  ' ' +
                                  edge?.node?.lastName}
                              </h4>
                              <p className="text-break">{edge?.node?.email}</p>
                            </div>
                          </div>
                          <input
                            className="m-2 checkbox-md checkbox-newer form-check-input"
                            type="checkbox"
                            id={'userid'}
                            name={'userid'}
                            value={'userid'}
                            checked={edge.node.tbd}
                            onChange={e => {
                              edge.node.tbd = e.target.checked;
                              if (e.target.checked === true) {
                                validation.setFieldValue('userid', true);
                                adduser(edge.node.id);
                              } else {
                                validation.setFieldValue('userid', false);
                                removeuser(edge.node.id);
                              }
                            }}
                          ></input>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
