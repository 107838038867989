import React from 'react';
import { number } from 'yup';
import { MortgageChangeTypeEnum } from '../../commons/enums';
const GetMortgageStatus = (status: number) => {
  if (status === MortgageChangeTypeEnum.MORTGAGE_NEW) {
    return 'Mortgage Added';
  } else if (status === MortgageChangeTypeEnum.MORTGAGE_MODIFICATION) {
    return 'Mortgage Modified';
  } else if (status === MortgageChangeTypeEnum.MORTGAGE_RECTIFICATION) {
    return 'Mortgage Rectified';
  } else if (status === MortgageChangeTypeEnum.MORTGAGE_SATISFACTION) {
    return 'Mortgage Satisfied';
  }
};

export default GetMortgageStatus;
