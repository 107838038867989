import { createSlice } from '@reduxjs/toolkit';
import { MeetingStoreType } from './types';

const initialState: MeetingStoreType = {
  selectedMeetingId: '',
  meetingView: false,
  meetingType: '',
  meetingSubType: '',
  status: '',
  subject: '',
  room: '',
  otherRoom: '',
  chair: '',
  invitees: [],
  minuteTaker: '',
  auditor: '',
  venue: '',
  city: '',
  province: '',
  country: '',
  isVirtualMeeting: false,
  actionItems: [],
  otherAgendaIds: [],
  zoomLink: '',
  committeeId: '',
};
const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setSelectedMeetingId: (state, { payload }) => {
      state.selectedMeetingId = payload;
    },
    setMeetingViewScreen: (state, { payload }) => {
      state.meetingView = payload;
    },
    setMeetingType: (state, { payload }) => {
      state.meetingType = payload;
    },
    setMeetingSubType: (state, { payload }) => {
      state.meetingSubType = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setSubject: (state, { payload }) => {
      state.subject = payload;
    },
    setRoom: (state, { payload }) => {
      state.room = payload;
    },
    setOtherRoom: (state, { payload }) => {
      state.otherRoom = payload;
    },
    setChair: (state, { payload }) => {
      state.chair = payload;
    },
    setMinuteTaker: (state, { payload }) => {
      state.minuteTaker = payload;
    },
    setAuditor: (state, { payload }) => {
      state.auditor = payload;
    },
    setInvitees: (state, { payload }) => {
      state.invitees = payload;
    },
    setActionItems: (state, { payload }) => {
      state.actionItems = payload;
    },
    setOtherAgendaIds: (state, { payload }) => {
      state.otherAgendaIds = payload;
    },
    setVenue: (state, { payload }) => {
      state.venue = payload;
    },
    setCountry: (state, { payload }) => {
      state.country = payload;
    },
    setProvince: (state, { payload }) => {
      state.province = payload;
    },
    setCity: (state, { payload }) => {
      state.city = payload;
    },
    setIsVirtualMeeting: (state, { payload }) => {
      state.isVirtualMeeting = payload;
    },
    setZoomLink: (state, { payload }) => {
      state.zoomLink = payload;
    },
    setCommitteeId: (state, { payload }) => {
      state.committeeId = payload;
    },
  },
});

export const {
  setSelectedMeetingId,
  setMeetingViewScreen,
  setMeetingType,
  setMeetingSubType,
  setStatus,
  setSubject,
  setRoom,
  setOtherRoom,
  setChair,
  setMinuteTaker,
  setAuditor,
  setInvitees,
  setActionItems,
  setOtherAgendaIds,
  setVenue,
  setCountry,
  setProvince,
  setCity,
  setIsVirtualMeeting,
  setZoomLink,
  setCommitteeId,
} = meetingSlice.actions;

export default meetingSlice.reducer;
