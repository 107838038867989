import React, { FC } from 'react';
import { RouteProps, useLocation, useRoutes } from 'react-router-dom';
import { useAppSelector } from '~/Store/hooks';
import Header from './Header';
import Sidebar from './Sidebar';
import { ToastError, ToastSuccess } from './Toasts';
import { BreadCrumb } from '~/Store/reducers/layout/types';

interface AuthLayoutProps {
  children: RouteProps['children'];
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { breadCrumb, token } = useAppSelector(state => state.layoutReducer);

  return (
    <React.Fragment>
      {!location.pathname.includes('companies') ? <Header /> : null}
      <div className="d-flex" style={{ overflow: 'hidden' }}>
        {location.pathname.includes('companies') ||
        location.pathname.includes('add') ||
        location.pathname.includes('companyInformation') ||
        location.pathname.includes('subsidiary') ||
        location.pathname.includes('mortgagesCharges') ||
        location.pathname.includes('companyAddressHistory') ||
        location.pathname.includes('advancedCapitalHistory') ||
        location.pathname.includes('paidUpCapitalHistory') ||
        location.pathname.includes('/companyProfile/companyMembers') ||
        location.pathname.includes('/companyOfficer/officerHistory') ||
        location.pathname.includes(
          '/companyMembers/primaryShareHolderInformation'
        ) ||
        location.pathname.includes('/companyMembers/membershipInformation') ||
        location.pathname.includes(
          '/companyMembers/jointShareholderInformation'
        ) ||
        location.pathname.includes('/companyMembers/nomineeInformation') ||
        location.pathname.includes('/companyMembers/memberHistory') ||
        location.pathname.includes('personalInformation') ? null : (
          <Sidebar />
        )}
        <div
          className={`w-100 ${
            location.pathname.includes('companies')
              ? null
              : ' background-main-grey'
          } `}
          style={{
            minHeight: 'calc(100vh - 10vh)',
            height: 'inherit',
            overflow: 'scroll',
          }}
        >
          {/* <ToastSuccess />
          <ToastError /> */}
          {location.pathname.includes('add') ||
          location.pathname.includes('companyInformation') ||
          location.pathname.includes('subsidiary') ||
          location.pathname.includes('companyMembers') ||
          location.pathname.includes('mortgagesCharges') ||
          location.pathname.includes('companyAddressHistory') ||
          location.pathname.includes('advancedCapitalHistory') ||
          location.pathname.includes('paidUpCapitalHistory') ||
          location.pathname.includes('memberHistory') ||
          location.pathname.includes('personalInformation') ? (
            <div className="padding-left-65 pt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {breadCrumb?.map((crumb: BreadCrumb, index: number) =>
                    index === breadCrumb.length - 1 ? (
                      <li
                        key={index}
                        className="breadcrumb breadcrumb-item active"
                        aria-current="page"
                      >
                        {crumb.title}
                      </li>
                    ) : (
                      <li key={index} className="breadcrumb breadcrumb-item">
                        <a className="text-decoration-none" href={crumb.url}>
                          {crumb.title}
                        </a>
                      </li>
                    )
                  )}
                </ol>
              </nav>
            </div>
          ) : null}

          <div
            className={`${
              location.pathname.includes('companies')
                ? null
                : 'addComponent px-3 pb-3'
            }`}
            style={{ height: 'calc(100vh - 22vh)', display: 'flex' }}
          >
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
