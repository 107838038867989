import React, { memo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReactNotificationComponent = ({ title, body }: any) => {
  toast.info(<Display title={title} body={body} />);
  return <ToastContainer />;
};
const Display = memo(({ title, body, createdAt }: any) => {
  return (
    <div>
      <h4>{title}</h4>
      <p>{body}</p>
      <p>{createdAt}</p>
    </div>
  );
});
export default ReactNotificationComponent;
