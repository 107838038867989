const GetPermissionsOfUser = (
  userPermissions: any,
  moduleName: string,
  permission: string
) => {
  let hasPermission = false;
  for (let index = 0; index < userPermissions?.length; index++) {
    const element = userPermissions[index];
    if (element.moduleName === moduleName && element[permission] === true) {
      hasPermission = true;
      break;
    }
  }
  return hasPermission;
};

export default GetPermissionsOfUser;
