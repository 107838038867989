import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { store } from '~/Store/configStore';
import { useDispatch } from 'react-redux';
import { clearStore } from '~/Store/reducers/layout';
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      if (
        error.message === 'jwt expired' ||
        error.message === 'invalid signature'
      ) {
        window.location.href = '/';
      } else {
        if (error.extensions.code === 'UNAUTHENTICATED') {
          client.clearStore();
          client.cache.reset();
          window.open(`https://compliancedigital.com.pk`, '_self');
          //todo clear store here
        }
        console.log('Err Response : ', error);
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = store.getState().layoutReducer.token;
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});
const link = ApolloLink.from([
  errorLink,
  concat(
    authMiddleware,
    createUploadLink({
      uri: 'https://backend.compliancedigital.com.pk/graphql',
      // uri: 'http://localhost:3000/graphql',
      // uri: "http://159.138.83.17:3000/graphql"
    })
  ),
]);
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

const ApolloClientProvider = (props: any) => {
  const dispatch = useDispatch();
  const token = store.getState().layoutReducer.token;
  const routeToken = window.location?.search.includes('token');
  if (token || routeToken) {
    return <ApolloProvider client={client} {...props} />;
  } else {
    dispatch(clearStore());
    return <></>;
  }
};

export default ApolloClientProvider;
