import { useLazyQuery } from '@apollo/client';
import React, { useState, memo } from 'react';
import GetEventName from '~/helpers/getEventsName';
import { FIND_ONE_DOCUMENT } from '~/Store/graphQL/LegalDoc';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setDocumentTab,
  setRightSidebar,
  setToastError,
} from '~/Store/reducers/layout';

export const RightSideBar = memo(({ documents, processes, help }: any) => {
  const dispatch = useAppDispatch();
  const { rightSidebar, documentTab } = useAppSelector(
    state => state.layoutReducer
  );

  const [releventProcesses, setReleventProcesses] = useState<number>(-1);
  const [
    findOneDocument,
    {
      loading: loadingFindOneDocument,
      error: errorFindOneDocument,
      data: dataFindOneDocument,
    },
  ] = useLazyQuery<any>(FIND_ONE_DOCUMENT, {
    fetchPolicy: 'no-cache',
  });
  let tempDocs = [
    ...new Map(
      documents?.map((item: any) => [item?.node?.docName, item?.node])
    ).values(),
  ];
  return (
    <div className="d-flex" style={{ height: '90vh', overflow: 'scroll' }}>
      {rightSidebar && (
        <div id="rightSideContent" className="scroll-content">
          <div>
            {documentTab === 1 ? (
              <div>
                <h5 className="mb-4">Relevant Document</h5>
                {tempDocs?.map((doc: any, index: number) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-3 cursor-pointer text-success"
                    onClick={() => {
                      findOneDocument({
                        variables: {
                          id: doc.documentId,
                        },
                      })
                        .then((val: any) => {
                          if (val?.data?.findOneDocument?.contentUrl) {
                            window.open(
                              val.data.findOneDocument.contentUrl,
                              'new'
                            );
                          } else {
                            dispatch(
                              setToastError(val.data.findOneDocument.error)
                            );
                          }
                        })
                        .catch(e => {
                          dispatch(setToastError(e));
                        });
                    }}
                  >
                    <i className="bx bxs-file-doc text-info font-20 me-2" />
                    {doc.docName}
                  </div>
                ))}
              </div>
            ) : null}
            {/* {documentTab === 2 ? (
              <div>
                <h5 className="mb-4">Relevant Processes</h5>
                {processes?.map((process: any, index: number) => (
                  <div key={index}>
                    <div
                      className="d-flex align-items-center mt-3 cursor-pointer "
                      onClick={() => {
                        if (releventProcesses === index) {
                          setReleventProcesses(-1);
                        } else {
                          setReleventProcesses(index);
                        }
                      }}
                    >
                      <i className="bx bxs-package text-info font-20 me-2" />
                      <p className="m-0">{GetEventName(process.event)}</p>
                      <i
                        className={`bx ${
                          releventProcesses === index
                            ? 'bx-chevron-up'
                            : 'bxs-chevron-down'
                        } ms-auto`}
                      />
                    </div>
                    {releventProcesses === index &&
                      process.businessRule?.length > 0 &&
                      process.businessRule?.map(
                        (businessRule: any, index: number) => (
                          <div className="d-flex ms-1 ps-1">
                            <i className="bx bxs-circle m-2 font-6"></i>
                            <div key={index} className="font-12">
                              {businessRule.artifactName}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                ))}
              </div>
            ) : null} */}
            {documentTab === 2 ? (
              <div>
                <h5 className="mb-3">Help</h5>
                <h6 className="my-4">{help?.heading}</h6>
                <div
                  className="content "
                  dangerouslySetInnerHTML={{ __html: help?.instructions }}
                ></div>
                {/* <pre> */}
                {/* {convert(help?.instructions, {
                    wordwrap: 130,
                  })} */}
                {/* {} */}
                {/* </pre> */}
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div
        id="rightSideBar"
        className={` ${rightSidebar ? '' : 'barCloseGap'}`}
      >
        <i
          className={`bx ${
            rightSidebar ? 'bx-chevrons-right' : 'bx-chevrons-left'
          }`}
          onClick={() => {
            if (rightSidebar) {
              dispatch(setRightSidebar(false));
            } else {
              dispatch(setRightSidebar(true));
            }
          }}
        />
        <i
          className={`bx bxs-file-doc ${documentTab === 1 ? 'active' : ''}`}
          onClick={() => {
            dispatch(setDocumentTab(1));
          }}
        />
        {/* <i
          className={`bx bxs-package ${documentTab === 2 ? 'active' : ''}`}
          onClick={() => {
            dispatch(setDocumentTab(2));
          }}
        /> */}
        {/* <i
          className={`bx bxs-help-circle ${documentTab === 2 ? 'active' : ''}`}
          onClick={() => {
            dispatch(setDocumentTab(2));
          }}
        /> */}
      </div>
    </div>
  );
});
