import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { APP_ROOT } from '~/config';
import { App } from './App';
// import 'bootstrap/dist/css/bootstrap.css';

import './index.scss';
import ApolloClientProvider from './Store/graphQL/configs/apollo';
import { persistor, store } from './Store/configStore';

const rootElement = document.getElementById(APP_ROOT);

function ReactApp(): JSX.Element {
  return (
    <Suspense fallback={<div>Loading..</div>}>
      <Provider store={store}>
        <PersistGate loading={<div>Gate Loading..</div>} persistor={persistor}>
          <ApolloClientProvider>
            <App />
          </ApolloClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

render(<ReactApp />, rootElement);
