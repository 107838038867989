export enum MeetingTypeEnum {
  BOARD_MEETING = 'BOARD_MEETING',
  GENERAL_MEETING = 'GENERAL_MEETING',
  COMMITTEE_MEETING = 'COMMITTEE_MEETING',
  STATUTORY_MEETING = 'STATUTORY_MEETING',
  OTHER = 'OTHER',
}
export enum MeetingSubTypeEnum {
  BOARD_MEETING = 'BOARD_MEETING',
  ANNUAL_GENERAL_MEETING = 'ANNUAL_GENERAL_MEETING',
  EXTRAORDINARY_GENERAL_MEETING = 'EXTRAORDINARY_GENERAL_MEETING',
  AUDIT_COMMITTEE_MEETING = 'AUDIT_COMMITTEE_MEETING',
  HR_COMMITTEE_MEETING = 'HR_COMMITTEE_MEETING',
  PROCUREMENT_COMMITTEE_MEETING = 'PROCUREMENT_COMMITTEE_MEETING',
  NOMINATION_COMMITTEE_MEETING = 'NOMINATION_COMMITTEE_MEETING',
  RISK_MANAGEMENT_COMMITTEE_MEETING = 'RISK_MANAGEMENT_COMMITTEE_MEETING',
  EXECUTIVE_COMMITTEE_MEETING = 'EXECUTIVE_COMMITTEE_MEETING',
  OTHER_COMMITTEE_MEETING = 'OTHER_COMMITTEE_MEETING',
  STATUTORY_MEETING = 'STATUTORY_MEETING',
  OTHER = 'OTHER',
}
export enum BusinessTypeEnum {
  ORDINARY_BUSINESS = 'ORDINARY_BUSINESS',
  SPECIAL_BUSINESS = 'SPECIAL_BUSINESS',
}
export enum MemberTypeEnum {
  DIRECTOR = 'DIRECTOR',
  NOMINEE_DIRECTOR = 'NOMINEE_DIRECTOR',
  AUDITOR = 'AUDITOR',
  CHIEF_ACCOUNTANT = 'CHIEF_ACCOUNTANT',
  CHAIRMAN = 'CHAIRMAN',
  COMPANY_SECRETARY = 'COMPANY_SECRETARY',
  CHIEF_EXECUTIVE = 'CHIEF_EXECUTIVE',
  MANAGING_ACCOUNTANT = 'MANAGING_ACCOUNTANT',
  RELATIVE = 'RELATIVE',
  JOINT_OWNER_SHARE = 'JOINT_OWNER_SHARE',
  ULTIMATE_BENEFICIAL_OWNER = 'ULTIMATE_BENEFICIAL_OWNER',
}
export enum MemberCategoryEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL_PERSON = 'LEGAL_PERSON',
}
export enum MemberStatusEnum {
  ACTIVE = 'ACTIVE',
  CEASED = 'CEASED',
}
export enum OfficerStatusEnum {
  APPOINTED = 'APPOINTED',
  RESIGNED = 'RESIGNED',
  ELECTED = 'ELECTED',
  REELECTED = 'REELECTED',
  RETIRED = 'RETIRED',
  CEASED = 'CEASED',
}

export enum DesignationEnum {
  DIRECTOR = 'DIRECTOR',
  DIRECTOR_AND_SUBSCRIBER = 'DIRECTOR_AND_SUBSCRIBER',
  SPONSOR = 'SPONSOR',
  DECLARANT = 'DECLARANT',
  ADVISOR_CONSULTANT = 'ADVISOR_CONSULTANT',
  NOMINEE_DIRECTOR = 'NOMINEE_DIRECTOR',
  LEGAL_ADVISOR = 'LEGAL_ADVISOR',
  AUDITOR = 'AUDITOR',
  CHIEF_ACCOUNTANT = 'CHIEF_ACCOUNTANT',
  CHAIRMAN = 'CHAIRMAN',
  COMPANY_SECRETARY = 'COMPANY_SECRETARY',
  CHIEF_EXECUTIVE = 'CHIEF_EXECUTIVE',
  MANAGING_AGENT = 'MANAGING_AGENT',
  SALE_AGENT = 'SALE_AGENT',
  FINANCIAL_OFFICER = 'FINANCIAL_OFFICER',
}
export enum RelationshipEnum {
  SON_OF = 'SON_OF',
  FATHER_OF = 'FATHER_OF',
  SPOUSE_OF = 'SPOUSE_OF',
}
export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
export enum NOCEnum {
  YES = 'YES',
  NO = 'NO',
  NA = 'N/A',
}
export enum ActionEnum {
  APPOINTED = 'APPOINTED',
  RESIGNED = 'RESIGNED',
  ELECTED = 'ELECTED',
  REELECTED = 'REELECTED',
  RETIRED = 'RETIRED',
  CEASED = 'CEASED',
}
export enum SearchParameterOptionsEnum {
  NAME = 'name',
  DESIGNATION = 'designation',
  STATUS = 'status',
  DATE_OF_BIRTH = 'dateOfBirth',
  NATIONALITY = 'nationality',
  CITY = 'city',
  TELEPHONE = 'telephone',
  POSTAL_CODE = 'postalCode',
}
export enum UserGroupSearchParameterOptionsEnum {
  GROUP_NAME = 'groupName',
  DESCRIPTION = 'description',
}
export enum MemberSearchParameterOptionsEnum {
  FOLIO_NO = 'folioNo',
  CATEGORY = 'category',
  MEMBER_TYPE = 'type',
  STATUS = 'status',
  CITY = 'city',
  PHONE = 'phone',
}
export enum CommitteeSearchParameterOptionsEnum {
  NAME = 'committeeName',
  COMMITTEE_NUMBER = 'committeeNumber',
  COMMITTEE_HEAD_EMAIL = 'committeeHeadEmail',
  STATUS = 'committeeStatus',
}
export enum MeetingSearchParameterOptionsEnum {
  MEETING_NUMBER = 'meetingNumber',
  MEETING_TYPE = 'meetingType',
  MEETING_SUB_TYPE = 'meetingSubType',
  STATUS = 'status',
}
export enum ShareSearchParameterOptionsEnum {
  TRANSACTION_TYPE = 'transactionType',
  TRANSACTION_DATE = 'createdAt',
  SHARE_TYPE = 'shareType',
  SHARE_CERTIFICATE_NO = 'shareCertificateNo',
  DISTINCTIVE_NO_FROM = 'distinctiveNoFrom',
  DISTINCTIVE_NO_TO = 'distinctiveNoTo',
  NO_OF_SHARES = 'noOfShares',
  PREVIOUS_SHARE_CERTIFICATE_NO = 'previousShareCertificateNo',
  FOLIO_NO = 'folioNo',
}
export enum BankSearchParameterOptionsEnum {
  BANK_NAME = 'bankName',
  CITY = 'city',
  BRANCH = 'branch',
  ACCOUNT_NUMBER = 'accountNumber',
}
export enum SystemUserSearchParameterOptionsEnum {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  USER_TYPE = 'userType',
  USER_GROUP = 'userGroupId',
}
export enum LegalDocSearchParameterOptionsEnum {
  FILE_NAME = 'fileName',
  DOCUMENT_TYPE = 'documentType',
  MODULE_NAME = 'moduleName',
}
export enum MortgageChangeTypeEnum {
  MORTGAGE_MODIFICATION = 1,
  MORTGAGE_RECTIFICATION = 2,
  MORTGAGE_SATISFACTION = 3,
  MORTGAGE_NEW = 4,
}
export enum SectorialClassificationEnum {
  BASIC_MATERIALS = 1,
  CONSUMER_GOODS = 2,
  CONSUMER_SERVICES = 3,
  OIL_AND_GAS = 4,
  FINANCIALS = 5,
  HEALTH_CARE = 6,
  INDUSTRIALS = 7,
  TECHNOLOGY = 8,
  TELECOMMUNICATIONS = 9,
  UTILITIES = 10,
  MISCELLANEOUS = 11,
}
export enum CompanyKindEnum {
  SMC = 1,
  PRIVATE = 2,
  UNLISTED_PUBLIC_COMPANY = 3,
  LISTED_COMPANY = 4,
}
export enum MeetingStatus {
  CREATED = 'CREATED',
  SCHEDULED = 'SCHEDULED',
  ADJOURNED = 'ADJOURNED',
  CONCLUDED = 'CONCLUDED',
  CANCELLED = 'CANCELLED',
}

export enum ModuleEnum {
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_OFFICERS = 'COMPANY_OFFICERS',
  COMPANY_MEMBERS = 'COMPANY_MEMBERS',
  COMMITTEE = 'COMMITTEE',
  MEETING = 'MEETING',
  SHARE_MANAGEMENT = 'SHARE_MANAGEMENT',
  NOTIFICATION = 'NOTIFICATION',
  REGISTER = 'REGISTER',
  LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
  FINANCIAL_YEAR = 'FINANCIAL_YEAR',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  SYSTEM_USER = 'SYSTEM_USER',
  SYSTEM_USER_GROUPS = 'SYSTEM_USER_GROUPS',
}

export enum DocumentTypeEnum {
  Minutes_of_Meeting = 'Minutes_of_Meeting',
  Agreements = 'Agreements',
  Resolutions = 'Resolutions',
  Appointment_of_Auditor = 'Appointment_of_Auditor',
  Appointment_of_Chairman = 'Appointment_of_Chairman',
  Court_order = 'Court_order',
  Charges = 'Charges',
  Mortgages = 'Mortgages',
  Participations_in_other_entities = 'Participations_in_other_entities',
  Indemnity_letters = 'Indemnity_letters',
  Letters_of_engagement = 'Letters_of_engagement',
  Letters_of_consent = 'Letters_of_consent',
  Resignation_letters = 'Resignation_letters',
  Powers_of_attorneys = 'Powers_of_attorneys',
  Resolution_Files = 'Resolution_Files',
  Mom_Files = 'Mom_Files',
}
export enum PermissionCategoryEnum {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  GENERATE_DOCUMENTS = 'generateDocument',
}
export enum CommitteeStatusEnum {
  FORMED = 'FORMED',
  CONCLUDED = 'CONCLUDED',
}
export enum ShareTypeEnum {
  PREFERENCE_SHARE = 'PREFERENCE_SHARE',
  ORDINARY_SHARE = 'ORDINARY_SHARE',
}

export enum ShareSubTypeEnum {
  PARTICIPATING_PREFERENCE = 'PARTICIPATING_PREFERENCE',
  NON_PARTICIPATING_PREFERENCE = 'NON_PARTICIPATING_PREFERENCE',
  PARTICIPATING_ACCUMULATIVE = 'PARTICIPATING_ACCUMULATIVE',
  PARTICIPATING_NON_ACCUMULATIVE = 'PARTICIPATING_NON_ACCUMULATIVE',
  CLASS_1A_SHARE = 'CLASS_1A_SHARE',
  CLASS_2B_SHARE = 'CLASS_2B_SHARE',
  OTHER = 'OTHER',
}
export enum ShareCertificateTypeEnum {
  MARKET_LOT = 'MARKET_LOT',
  JUMBO_LOT = 'JUMBO_LOT',
}
export enum ShareIssueTypeEnum {
  CASH = 'CASH',
  OTHER_THAN_CASH = 'OTHER_THAN_CASH',
  BONUS = 'BONUS',
}
export enum ShareAllotmentMethodEnum {
  FIRST_ISSUE = 'FIRST_ISSUE',
  RIGHT_ISSUE = 'RIGHT_ISSUE',
  OTHER_THAN_RIGHT_ISSUE = 'OTHER_THAN_RIGHT_ISSUE',
  EMPLOYEE_STOCK_OPTION_SCHEME = 'EMPLOYEE_STOCK_OPTION_SCHEME',
  CONVERSION_OF_LOAN_INTO_SHARE = 'CONVERSION_OF_LOAN_INTO_SHARE',
}
export enum ShareTransactionTypeEnum {
  ALLOTMENT = 'ALLOTMENT',
  TRANSFER = 'TRANSFER',
  SPLIT = 'SPLIT',
  DUPLICATE = 'DUPLICATE',
  TRANSMISSION = 'TRANSMISSION',
}
export enum ShareTransferLodgingPersonTypeEnum {
  BROKER = 'BROKER',
  AGENT = 'AGENT',
}
export enum ShareTransmissionModeEnum {
  LEGAL_HEIR = 'LEGAL_HEIR',
  AMALGAMATION = 'AMALGAMATION',
}
export enum ShareTransmissionReasonEnum {
  DEATH = 'DEATH',
  BANK_CORRUPT = 'BANK_CORRUPT',
}
export enum ShareTransmissionTypeEnum {
  PROBATE = 'PROBATE',
  SUCCESSION_CERTIFICATE = 'SUCCESSION_CERTIFICATE',
  INDEMNITY_BOND = 'INDEMNITY_BOND',
}
export enum UserTypeEnum {
  COMPANY_ADMIN = 1,
  INTERMEDIAL_USER = 2,
  SUPER_ADMIN = 3,
  COMPANY_OFFICER = 4,
  COMPANY_MEMBER = 5,
}

export enum SubscriptionPlansEnum {
  ONE_YEAR_PLAN = 'ONE_YEAR_PLAN',
  TWO_YEARS_PLAN = 'TWO_YEARS_PLAN',
  THREE_YEARS_PLAN = 'THREE_YEARS_PLAN',
}
export enum UserCategoryEnum {
  COMPANY_OFFICER = 'COMPANY_OFFICER',
  COMPANY_MEMBER = 'COMPANY_MEMBER',
  INTERMEDIAL_USER = 'INTERMEDIAL_USER',
}
export enum BusinessRuleDurationEnum {
  'FIFTEEN_DAYS' = 15,
  'THIRTY_DAYS' = 30,
}
export enum MeetingActionItemStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export enum SubscriptionStatusEnum {
  PAID = 'PAID',
  UN_PAID = 'UN_PAID',
  EXPIRED = 'EXPIRED',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
}
