import { createSlice } from '@reduxjs/toolkit';
import { ShareStoreType } from './types';

const initialState: ShareStoreType = {
  selectedShareId: '',
  selectedShareIdForTransaction: '',
  selectedShareTransferId: '',
  shareView: false,
  selectedFolioNumber: -1,
  transmissionMode: '',
  transmissionReason: '',
  type: '',
  transmission: 0,
  modalVisibility: false,
  modalDataRedux: [],
};
const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    setSelectedShareId: (state, { payload }) => {
      state.selectedShareId = payload;
    },
    setSelectedShareIdForTransaction: (state, { payload }) => {
      state.selectedShareIdForTransaction = payload;
    },
    setSelectedShareTransferId: (state, { payload }) => {
      state.selectedShareTransferId = payload;
    },
    setShareViewScreen: (state, { payload }) => {
      state.shareView = payload;
    },
    setSelectedFolioNumber: (state, { payload }) => {
      state.selectedFolioNumber = payload;
    },
    setTransmissionMode: (state, { payload }) => {
      state.transmissionMode = payload;
    },
    setTransmissionReason: (state, { payload }) => {
      state.transmissionReason = payload;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
    setTransmission: (state, { payload }) => {
      state.transmission = payload;
    },
    setModalVisibility: (state, { payload }) => {
      state.modalVisibility = payload;
    },
    setModalDataRedux: (state, { payload }) => {
      state.modalDataRedux = payload;
    },
  },
});

export const {
  setSelectedShareId,
  setShareViewScreen,
  setSelectedFolioNumber,
  setSelectedShareIdForTransaction,
  setSelectedShareTransferId,
  setTransmissionMode,
  setTransmissionReason,
  setType,
  setTransmission,
  setModalVisibility,
  setModalDataRedux,
} = shareSlice.actions;

export default shareSlice.reducer;
