import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { useSearchParams } from 'react-router-dom';
import { FIND_COMPANY_MEMBER_BY_ID } from '~/Store/graphQL/CompanyMembers';
import GetCompanyMemberStatus from '~/helpers/CompanyMember/getCompanyMemberStatus';
import GetCompanyMemberType from '~/helpers/CompanyMember/getMemberType';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import {
  MemberCategoryEnum,
  MemberStatusEnum,
  MemberTypeEnum,
  ModuleEnum,
  RelationshipEnum,
} from '~/commons/enums';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { Container, Modal } from 'reactstrap';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import {
  AddressIcon,
  CategoryIcon,
  ContactInfoIcon,
  PersonalInfoLogo,
} from '~/assets/images';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import moment from 'moment';

export const MemberHistory: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [membersData, setMembersData] = useState<any>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const [countries, setCountries] = useState<ICountry>();
  const [states, setStates] = useState<IState>();
  const [cities, setCities] = useState<ICity>();
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [selectedState, setSelectedState] = useState<number>();

  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };

  const [selectedIndex, setSelectedIndex] = useState<any>();

  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  useEffect(() => {
    setCountries(Country.getAllCountries());

    dispatch(
      setBreadCrumb([
        {
          url: '/companyMember',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Member History',
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedState, selectedCountry]);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);

  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );

      if (
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.country
      ) {
        let country: ICountry = countries?.find(
          (x: ICountry) =>
            x.name ===
            dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
              .country
        );
        if (country) {
          setSelectedCountry(country.isoCode);
        }
      }
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
      dispatch(setToastError(errorFindCompanyMemberById));
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3  end-0 mx-4">
              <button
                type="button"
                className="btn fw-bold dangerButton d-flex align-items-center "
                // disabled={editableRow !== -1}
                onClick={() => {
                  if (isNewFolio) {
                    navigate('/shares/add');
                  } else if (isNewFolioForTransfer) {
                    navigate('/shares/add/transfer');
                  } else {
                    navigate('/companyMembers');
                  }
                }}
              >
                {memberView ? 'Close' : 'Cancel'}
              </button>
              <button
                type="button"
                className="btn fw-bold primaryDismissButton d-flex align-items-center "
                onClick={() => {
                  if (isNewFolio) {
                    navigate(
                      `/companyMembers/nomineeInformation?isNewFolio=true`
                    );
                  } else if (isNewFolioForTransfer) {
                    navigate(
                      `/companyMembers/nomineeInformation?isNewFolioForTransfer=true`
                    );
                  } else if (isNewFolioForTransmission) {
                    navigate(
                      `/companyMembers/nomineeInformation?isNewFolioForTransmission=true`
                    );
                  } else {
                    navigate(`/companyMembers/nomineeInformation`);
                  }
                }}
              >
                Back
              </button>
            </div>
          </span>
        </div>

        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs w-100">
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link cursor-pointer">
                    Company Member Information
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      navigate('/companyMembers/primaryShareHolderInformation')
                    }
                    className="nav-link cursor-pointer"
                  >
                    Company Member Information
                  </a>
                )}
              </li>
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link cursor-pointer">
                    Joint Shareholder Information
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      navigate('/companyMembers/jointShareholderInformation')
                    }
                    className="nav-link cursor-pointer "
                  >
                    Joint Shareholder Information
                  </a>
                )}
              </li>
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link cursor-pointer">Nominee Information</a>
                ) : (
                  <a
                    onClick={() =>
                      navigate('/companyMembers/nomineeInformation')
                    }
                    className="nav-link cursor-pointer"
                  >
                    Nominee Information
                  </a>
                )}
              </li>
              <li className="nav-item border-end flex-grow-1 text-center">
                <a
                  onClick={() => navigate('/companyMembers/memberHistory')}
                  className="nav-link active cursor-pointer"
                >
                  Member History
                </a>
              </li>
            </ul>
          </span>
        </div>
        <div id="mainComponentNew">
          {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>CNIC</th>

                  <th>Address</th>
                  <th>SECP Ref.ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {membersData?.companyMemberHistory?.length === 0 ||
                membersData?.companyMemberHistory === undefined ||
                membersData?.companyMemberHistory == null ? (
                  <tr>
                    <td>
                      <div className="badge custom-badge-primary">{1}</div>
                    </td>
                    <td>
                      {membersData?.date ? (
                        <div className="badge custom-badge-success">
                          {new Date(membersData?.date)
                            .toISOString()
                            .slice(0, 10)}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {membersData?.status
                        ? GetCompanyMemberStatus(membersData?.status)
                        : 'N/A'}
                    </td>
                    <td>
                      {membersData?.middleName
                        ? membersData?.firstName +
                          ' ' +
                          membersData?.middleName +
                          ' ' +
                          membersData?.lastName
                        : membersData?.firstName + ' ' + membersData?.lastName}
                    </td>
                    <td>
                      {membersData?.type
                        ? GetCompanyMemberType(membersData?.type)
                        : 'N/A'}
                    </td>
                    <td>{membersData?.cnic ? membersData?.cnic : 'N/A'}</td>
                    <td>
                      {membersData?.passportNumber ? (
                        <div className="badge custom-badge-primary">
                          {membersData?.passportNumber}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {membersData?.residentialAddress
                        ? membersData?.residentialAddress
                        : 'N/A'}
                    </td>
                    <td>
                      {membersData?.secpRefId ? (
                        <div className="badge custom-badge-primary">
                          {membersData?.secpRefId}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                ) : (
                  membersData?.companyMemberHistory?.map(
                    (val: any, index: number) => (
                      <tr key={`${index}`}>
                        <td>
                          <div className="badge custom-badge-primary">
                            {index + 1}
                          </div>
                        </td>
                        <td>
                          {val.date ? (
                            <div className="badge custom-badge-success">
                              {new Date(val.date).toISOString().slice(0, 10)}
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          {val?.status
                            ? GetCompanyMemberStatus(val?.status)
                            : 'N/A'}
                        </td>
                        <td>
                          {val?.middleName
                            ? val?.firstName +
                              ' ' +
                              val?.middleName +
                              ' ' +
                              val?.lastName
                            : val?.firstName + ' ' + val?.lastName}
                        </td>
                        <td>
                          {val?.type ? GetCompanyMemberType(val?.type) : 'N/A'}
                        </td>
                        <td>{val?.cnic ? val?.cnic : 'N/A'}</td>

                        <td>
                          {val?.residentialAddress
                            ? val?.residentialAddress
                            : 'N/A'}
                        </td>
                        <td>
                          {val?.secpRefId ? (
                            <div className="badge custom-badge-primary">
                              {val?.secpRefId}
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          <div className="editColumn mt-0 gap-1">
                            <i
                              className="bx bx-show-alt text-primary"
                              onClick={() => {
                                setSelectedIndex(index);
                                toggleModal();
                                setMomModalVisibility(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}

      <Modal
        className="modalreact"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluids>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              {/* <i className="bx  text-primary bx-sm me-2"></i>s */}
              <i className="bx bx-history text-primary bx-md me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                History
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            {/*  */}

            <div className="mt-2">
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={PersonalInfoLogo}
                      ></img>
                      <div className="font-weight-600">Personal Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex w-400">
                        <label htmlFor="memberName">Member Name*</label>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="memberName.firstName"
                          name="memberName.firstName"
                          placeholder="First Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.firstName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.firstName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.firstName || 'N/A'
                          }
                          disabled={true}
                        />
                        <input
                          type="text"
                          id="memberName.middleName"
                          name="memberName.middleName"
                          placeholder="Middle Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.middleName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.middleName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.middleName || 'N/A'
                          }
                          disabled={true}
                        />
                        <input
                          type="text"
                          id="memberName.lastName"
                          name="memberName.lastName"
                          placeholder="Last Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.lastName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.lastName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.lastName || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex w-400 mt-2">
                        <label htmlFor="name">Father/Spouse Name*</label>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="fatherSpouseName.firstName"
                          name="fatherSpouseName.firstName"
                          placeholder="First Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relativeFirstName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.relativeFirstName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.firstName || 'N/A'
                          }
                          disabled={true}
                        />
                        <input
                          type="text"
                          id="fatherSpouseName.middleName"
                          name="fatherSpouseName.middleName"
                          placeholder="Middle Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relativeMiddleName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.relativeMiddleName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relativeMiddleName || 'N/A'
                          }
                          disabled={true}
                        />
                        <input
                          type="text"
                          id="fatherSpouseName.lastName"
                          name="fatherSpouseName.lastName"
                          placeholder="Last Name"
                          className={`form-control form-sm mb-0 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relativeLastName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.relativeLastName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relativeLastName || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="memberType">Member Type*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.type ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.type
                              ? ''
                              : 'updated'
                          }`}
                          id="memberType"
                          name="memberType"
                          placeholder="Sectorial Classification"
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.type || 'N/A'
                          }
                          disabled={true}
                        >
                          <option value="">Select</option>
                          <option value={MemberTypeEnum.AUDITOR}>
                            Auditor
                          </option>
                          <option value={MemberTypeEnum.CHAIRMAN}>
                            Chairman
                          </option>
                          <option value={MemberTypeEnum.CHIEF_ACCOUNTANT}>
                            Chief Accountant
                          </option>
                          <option value={MemberTypeEnum.CHIEF_EXECUTIVE}>
                            Chief Executive
                          </option>
                          <option value={MemberTypeEnum.COMPANY_SECRETARY}>
                            Company Secretary
                          </option>
                          <option value={MemberTypeEnum.DIRECTOR}>
                            Director
                          </option>
                          <option value={MemberTypeEnum.JOINT_OWNER_SHARE}>
                            Joint Owner Share
                          </option>
                          <option value={MemberTypeEnum.MANAGING_ACCOUNTANT}>
                            Managing Accountant
                          </option>
                          <option value={MemberTypeEnum.NOMINEE_DIRECTOR}>
                            Nominee Director
                          </option>
                          <option value={MemberTypeEnum.RELATIVE}>
                            Relative
                          </option>
                          <option
                            value={MemberTypeEnum.ULTIMATE_BENEFICIAL_OWNER}
                          >
                            Ultimate Beneficial Owner
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="relationship">Relationship*</label>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relationship ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.relationship
                              ? ''
                              : 'updated'
                          }`}
                          id="relationship"
                          name="relationship"
                          placeholder="Relationship"
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.relationship || 'N/A'
                          }
                          disabled={true}
                          // onBlur={e => {
                          //   validation.handleBlur(e);
                          //   {
                          //     !validation.errors.relationship &&
                          //       isDraft &&
                          //       !loadingDraftUpdateCompanyMember &&
                          //       validation.values.relationship &&
                          //       draftUpdateCompanyMember({
                          //         variables: {
                          //           id: selectedMemberId,
                          //           relationship:
                          //             validation.values.relationship,
                          //         },
                          //       });
                          //   }
                          // }}
                          // onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={RelationshipEnum.SON_OF}>
                            Son of
                          </option>
                          <option value={RelationshipEnum.FATHER_OF}>
                            Father of
                          </option>
                          <option value={RelationshipEnum.SPOUSE_OF}>
                            Spouse of
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="nationality">Nationality*</label>
                      </div>
                      <input
                        type="text"
                        id="nationality"
                        name="nationality"
                        placeholder="Nationality"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.nationality ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.nationality
                            ? ''
                            : 'updated'
                        }`}
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.nationality || 'N/A'
                        }
                        disabled={true}
                        // onBlur={e => {
                        //   validation.handleBlur(e);
                        //   {
                        //     !validation.errors.nationality &&
                        //       isDraft &&
                        //       !loadingDraftUpdateCompanyMember &&
                        //       validation.values.nationality &&
                        //       draftUpdateCompanyMember({
                        //         variables: {
                        //           id: selectedMemberId,
                        //           nationality: validation.values.nationality,
                        //         },
                        //       });
                        //   }
                        // }}
                        // onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="passportNumber">
                          Passport No. (Foreign National)
                        </label>
                      </div>
                      <input
                        type="text"
                        id="passportNumber"
                        name="passportNumber"
                        placeholder="Passport Number"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.passportNumber ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.passportNumber
                            ? ''
                            : 'updated'
                        }`}
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.passportNumber || 'N/A'
                        }
                        disabled={true}
                        // onBlur={e => {
                        //   validation.handleBlur(e);
                        //   {
                        //     !validation.errors.passportNumber &&
                        //       isDraft &&
                        //       !loadingDraftUpdateCompanyMember &&
                        //       validation.values.passportNumber &&
                        //       draftUpdateCompanyMember({
                        //         variables: {
                        //           id: selectedMemberId,
                        //           passportNumber:
                        //             validation.values.passportNumber,
                        //         },
                        //       });
                        //   }
                        // }}
                        // onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnicNumber">CNIC No* (PK)</label>
                      </div>
                      <input
                        type="text"
                        id="cnicNumber"
                        name="cnicNumber"
                        placeholder="CNIC Number"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.cnic ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.cnic
                            ? ''
                            : 'updated'
                        }`}
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.cnic || 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnicExpiry">CNIC Expiry Date*</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.cnicExpiry ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.cnicExpiry
                            ? ''
                            : 'updated'
                        }`}
                        id="cnicExpiry"
                        name="cnicExpiry"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.cnicExpiry || 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="businessOccupation">
                          Business Occupation
                        </label>
                      </div>
                      <input
                        type="text"
                        id="businessOccupation"
                        name="businessOccupation"
                        placeholder="Business Occupation"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.occupation ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.occupation
                            ? ''
                            : 'updated'
                        }`}
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.occupation || 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="ntn">NTN</label>
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.ntn ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.ntn
                            ? ''
                            : 'updated'
                        }`}
                        id="ntn"
                        name="ntn"
                        placeholder="NTN"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.ntn || 'N/A'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="inputFields border-div px-3 pt-3 mt-2">
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                    <img
                      className="height-30 me-2 my-1 bg-transparent align-items-center"
                      src={AddressIcon}
                    ></img>
                    <div className="font-weight-600">Address</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="country">Country*</label>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.country ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.country
                            ? ''
                            : 'updated'
                        }`}
                        id="country"
                        name="country"
                        placeholder="Country"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.country || 'N/A'
                        }
                        disabled={true}
                      >
                        {countries?.map((country: ICountry) => (
                          <option value={country.name}>{country.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="province">Province*</label>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.province ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.province
                            ? ''
                            : 'updated'
                        }`}
                        id="province"
                        name="province"
                        placeholder="Province"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.province || 'N/A'
                        }
                        disabled={true}
                        // onBlur={e => {
                        //   validation.handleBlur(e);
                        //   {
                        //     !validation.errors.province &&
                        //       isDraft &&
                        //       validation.values.province &&
                        //       !loadingDraftUpdateCompanyMember &&
                        //       draftUpdateCompanyMember({
                        //         variables: {
                        //           id: selectedMemberId,
                        //           province: validation.values.province,
                        //         },
                        //       });
                        //   }
                        // }}
                        // onChange={e => {
                        //   let state: IState = states.find(
                        //     (x: IState) => x.name === e.target.value
                        //   );
                        //   setSelectedCountry(selectedCountry);
                        //   if (state) {
                        //     setSelectedState(state.isoCode);
                        //   }
                        //   validation.handleChange(e);
                        // }}
                      >
                        {states?.map((state: IState) => (
                          <option value={state.name}>{state.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="city">City*</label>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.city ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.city
                            ? ''
                            : 'updated'
                        }`}
                        id="city"
                        name="city"
                        placeholder="City"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.city || 'N/A'
                        }
                        disabled={true}
                      >
                        {cities?.map((city: ICity) => (
                          <option value={city.name}>{city.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="postalCode">Postal Code*</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.postalCode ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.postalCode
                          ? ''
                          : 'updated'
                      }`}
                      id="postalCode"
                      name="postalCode"
                      placeholder="Postal Code"
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.postalCode || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row py-4 background-dim-grey">
                <div className="col-6">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="residentialAddress">
                        Residential Address*
                      </label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.residentialAddress ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.residentialAddress
                          ? ''
                          : 'updated'
                      }`}
                      id="residentialAddress"
                      name="residentialAddress"
                      placeholder="Residential Address"
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.residentialAddress || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inputFields border-div px-3 pt-3 mt-2">
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                    <img
                      className="height-30 me-2 my-1 bg-transparent align-items-center"
                      src={ContactInfoIcon}
                    ></img>
                    <div className="font-weight-600">Contact Info</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="mobile">Telephone*</label>
                    </div>
                    <PhoneInput
                      containerStyle={{ padding: 0, width: '100%' }}
                      buttonClass="button-class"
                      containerClass={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.phone ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.phone
                          ? ''
                          : 'updated'
                      }`}
                      inputClass="my-input-class"
                      inputStyle={{
                        border: 0,
                        height: 48,
                        width: '100%',
                      }}
                      buttonStyle={{
                        height: 48,
                      }}
                      countryCodeEditable={false}
                      preferredCountries={['pk']}
                      country={'pk'}
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.phone || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="email">Email*</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.email ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.email
                          ? ''
                          : 'updated'
                      }`}
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.email || 'N/A'
                      }
                      disabled={true || !isDraft}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inputFields border-div px-3 pt-3 mt-2">
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                    <img
                      className="height-30 me-2 my-1 bg-transparent align-items-center"
                      src={CategoryIcon}
                    ></img>
                    <div className="font-weight-600">Category</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="memberCategory">Member Category*</label>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.category ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.category
                            ? ''
                            : 'updated'
                        }`}
                        id="memberCategory"
                        name="memberCategory"
                        placeholder="Sectorial Classification"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.category || 'N/A'
                        }
                        disabled={true}
                      >
                        <option value={MemberCategoryEnum.INDIVIDUAL}>
                          Individual
                        </option>
                        <option value={MemberCategoryEnum.LEGAL_PERSON}>
                          Legal Person
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {membersData?.companyMemberHistory[selectedIndex]?.category ===
                MemberCategoryEnum.LEGAL_PERSON ? (
                  <>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="legalPersonNumber">
                            Legal Person Name
                          </label>
                        </div>
                        <input
                          type="text"
                          id="legalPersonNumber"
                          name="legalPersonNumber"
                          placeholder="Name"
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.legalPersonName ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.legalPersonName
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.legalPersonNumber || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cuinNumber">CUIN No</label>
                        </div>
                        <input
                          type="text"
                          id="cuinNumber"
                          name="cuinNumber"
                          placeholder="CUIN Number"
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.companyCuin ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.companyCuin
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.companyCuin || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="numberOfShares">
                            Number of Shares
                          </label>
                        </div>
                        <input
                          type="text"
                          id="numberOfShares"
                          name="numberOfShares"
                          placeholder="Number of Shares"
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.numOfShares ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.numOfShares
                              ? ''
                              : 'updated'
                          }`}
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.numOfShares || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {membersData?.companyMemberHistory[selectedIndex]?.category ===
              MemberCategoryEnum.LEGAL_PERSON ? (
                <div className="row mb-4">
                  {/* <div className="col-3"></div>
                  <div className="col-3"></div>
                  <div className="col-3"></div> */}
                  <div className="col-3">
                    <div className="d-flex justify-content-around">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="holdingCompany"
                          name="holdingCompany"
                          checked={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.holdingCompany
                          }
                          disabled={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="holdingCompany"
                        >
                          Holding Company
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="subsidiaries"
                          name="subsidiaries"
                          checked={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.subsidiaries
                          }
                          disabled={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="subsidiaries"
                        >
                          Subsidiary (ies)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="inputFields border-div px-3 pt-3 mt-2">
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                    <img
                      className="height-30 me-2 my-1 bg-transparent align-items-center"
                      src={PersonalInfoLogo}
                    ></img>
                    <div className="font-weight-600">Personal Info</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="cdcAccountNo">CDC Account No</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.cdcAccountNumber ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.cdcAccountNumber
                          ? ''
                          : 'updated'
                      }`}
                      id="cdcAccountNo"
                      name="cdcAccountNo"
                      placeholder=" Account No"
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.cdcAccountNumber || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="dateOfBecomingAMember">
                        Date of Becoming a Member
                      </label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.dateOfMembership ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.dateOfMembership
                          ? ''
                          : 'updated'
                      }`}
                      id="dateOfBecomingAMember"
                      name="dateOfBecomingAMember"
                      placeholder="Date of Becoming a Member"
                      value={
                        moment(
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.dateOfMembership
                        ).format('YYYY-MM-DD') || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                {/* <div className="col-3"></div>
                <div className="col-3"></div> */}
              </div>
              <div className="row py-4 background-dim-grey mb-4">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="sendingNotesInstructions">
                        Instructions for Sending Notices
                      </label>
                    </div>
                    <textarea
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.instructions ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.instructions
                          ? ''
                          : 'updated'
                      }`}
                      id="sendingNotesInstructions"
                      name="sendingNotesInstructions"
                      placeholder="Details here ..."
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.instructions || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="otherInstructions">
                        Particular of Dividend Mandates, Power of Attorney and
                        Other Instructions
                      </label>
                    </div>
                    <textarea
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.otherInstructions ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.otherInstructions
                          ? ''
                          : 'updated'
                      }`}
                      id="otherInstructions"
                      name="otherInstructions"
                      placeholder="Details here ..."
                      value={
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.otherInstructions || 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inputFields border-div px-3 pt-3 mt-2">
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                    <img
                      className="height-30 me-2 my-1 bg-transparent align-items-center"
                      src={PersonalInfoLogo}
                    ></img>
                    <div className="font-weight-600">Member Status</div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="memberStatus">Member Status*</label>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 d-flex ${
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.status ===
                          membersData?.companyMemberHistory[selectedIndex + 1]
                            ?.status
                            ? ''
                            : 'updated'
                        }`}
                        id="memberStatus"
                        name="memberStatus"
                        placeholder="Sectorial Classification"
                        value={
                          membersData?.companyMemberHistory[selectedIndex]
                            ?.status || 'N/A'
                        }
                        disabled={true}
                      >
                        <option value="">Select</option>
                        <option value={MemberStatusEnum.ACTIVE}>Active</option>
                        <option value={MemberStatusEnum.CEASED}>Ceased</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="date">Date*</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        membersData?.companyMemberHistory[selectedIndex]
                          ?.date ===
                        membersData?.companyMemberHistory[selectedIndex + 1]
                          ?.date
                          ? ''
                          : 'updated'
                      }`}
                      id="date"
                      name="date"
                      value={
                        membersData?.companyMemberHistory[selectedIndex]?.date
                          ? moment(
                              membersData?.companyMemberHistory[selectedIndex]
                                ?.date
                            ).format('YYYY-MM-DD')
                          : 'N/A'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                {membersData?.companyMemberHistory[selectedIndex]?.category ===
                MemberStatusEnum.CEASED ? (
                  <>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="dateOfCeasingToBeMember">
                            Date of Ceasing
                          </label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.ceaseDate ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.ceaseDate
                              ? ''
                              : 'updated'
                          }`}
                          id="dateOfCeasingToBeMember"
                          name="dateOfCeasingToBeMember"
                          placeholder="Date of Ceasing to be Member"
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.ceaseDate
                              ? moment(
                                  membersData?.companyMemberHistory[
                                    selectedIndex
                                  ]?.ceaseDate
                                ).format('YYYY-MM-DD')
                              : 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="reasonOfCeasingToBeMember">
                            Reason of Ceasing
                          </label>
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.cessationReason ===
                            membersData?.companyMemberHistory[selectedIndex + 1]
                              ?.cessationReason
                              ? ''
                              : 'updated'
                          }`}
                          id="reasonOfCeasingToBeMember"
                          name="reasonOfCeasingToBeMember"
                          placeholder="Reason of Ceasing to be Member"
                          value={
                            membersData?.companyMemberHistory[selectedIndex]
                              ?.cessationReason || 'N/A'
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <br className="my-4"></br>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
