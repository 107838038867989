import { useLazyQuery, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  CompanyProfileImg,
  RevenueIcon,
  ActiveSub,
  ActiveUser,
  InactiveUser,
  TotalUser,
  TotalSub,
  AdminBg,
  InactiveSub,
  profileIcon,
} from '~/assets/images';

import { DASHBOARD_DATA } from '~/Store/graphQL/Dashboard';
import { FIND_TEMP_NOTIFICATIONS } from '~/Store/graphQL/Notification';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import './dashboard.scss';

export const AdminDashboard: FC = () => {
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [
    dashboardData,
    {
      loading: loadingDashboardData,
      error: errorDashboardData,
      data: dataDashboardData,
    },
  ] = useLazyQuery<any>(DASHBOARD_DATA);

  useEffect(() => {
    dashboardData();

    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Admin Dashboard',
        },
      ])
    );
  }, []);

  return (
    <React.Fragment>
      <div id="mainComponent">
        <div className="d-flex w-100">
          <div className="profileContainer w-100">
            <div className="top px-3 w-100 d-flex justify-content-start align-items-end">
              <div>
                <img className="adminPic" src={profileIcon}></img>
              </div>
              <div className="mx-2">
                <h5 className="mt-1">Welcome Back!</h5>

                <p className="text-light">
                  {dataDashboardData?.getDashboardUserData?.email}
                </p>
              </div>
            </div>
            <div className="bottom">
              <div className="box1">
                <div className="row">
                  <div className="col-11">
                    <p>Total Revenue</p>
                    <h4>
                      {' '}
                      {dataDashboardData?.getDashboardUserData?.totalRevenue
                        ? dataDashboardData?.getDashboardUserData?.totalRevenue
                        : '0'}
                    </h4>
                  </div>
                  <div className="col-1">
                    <img className="img c-img" src={RevenueIcon}></img>
                  </div>
                </div>
              </div>

              <div className="mainCom">
                <div className="row ">
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Total Users</p>
                      <h4>
                        {dataDashboardData?.getDashboardUserData?.totalUser}
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img  c-img" src={TotalUser}></img>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Active Users</p>
                      <h4>
                        {dataDashboardData?.getDashboardUserData?.activeUser}
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img c-img" src={ActiveUser}></img>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Inactive Users</p>
                      <h4>
                        {dataDashboardData?.getDashboardUserData?.totalUser -
                          dataDashboardData?.getDashboardUserData?.activeUser}
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img c-img" src={InactiveUser}></img>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Total Subscription</p>
                      <h4>
                        {
                          dataDashboardData?.getDashboardUserData
                            ?.totalSubcription
                        }
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img c-img" src={TotalSub}></img>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Active Subscription</p>
                      <h4>
                        {
                          dataDashboardData?.getDashboardUserData
                            ?.paidSubcription
                        }
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img c-img" src={ActiveSub}></img>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="col-10">
                      <p>Inactive Subscription</p>
                      <h4>
                        {dataDashboardData?.getDashboardUserData
                          ?.unpaidSubcription +
                          dataDashboardData?.getDashboardUserData
                            ?.expiredSubcription}
                      </h4>
                    </div>
                    <div className="col-2">
                      <img className="img c-img" src={InactiveSub}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
