import React, { Component, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './subscription.scss';
import { SubscriptionPlansEnum } from '../../../commons/enums';
import { useLazyQuery, useMutation } from '@apollo/client';

import './subscription.scss';

import {
  CREATE_SUBSCRIPTION_PLAN,
  FIND_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN,
} from '~/Store/graphQL/Subscription';

export const AddAdminSubscription: FC = () => {
  const { selectedSubscriptionId } = useAppSelector(
    state => state.subscriptionReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/admin/subscription',
          title: 'Subscription Plans',
        },
        {
          url: location.pathname,
          title: 'Add Subscription',
        },
      ])
    );
  }, []);

  const [
    findSubscriptionById,
    {
      loading: loadingfindSubscriptionById,
      error: errorfindSubscriptionById,
      data: datafindSubscriptionById,
    },
  ] = useLazyQuery<any>(FIND_SUBSCRIPTION_PLAN);

  useEffect(() => {
    if (datafindSubscriptionById) {
      setSubscriptionData(
        datafindSubscriptionById?.findOneSubscriptionPlan?.subscriptionPlan
      );
    } else if (errorfindSubscriptionById) {
      console.log('errorFindAgendaById:', errorfindSubscriptionById);
    }
  }, [datafindSubscriptionById, errorfindSubscriptionById]);

  useEffect(() => {
    if (selectedSubscriptionId) {
      findSubscriptionById({
        variables: {
          id: selectedSubscriptionId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedSubscriptionId]);

  // update agenda

  const [
    updateSubscriptionPlan,
    {
      loading: loadingupdateSubscriptionPlan,
      error: errorupdateSubscriptionPlan,
      data: dataupdateSubscriptionPlan,
    },
  ] = useMutation<any>(UPDATE_SUBSCRIPTION_PLAN);

  const [
    createSubscriptionPlan,
    {
      loading: loadingcreateSubscriptionPlan,
      error: errorcreateSubscriptionPlan,
      data: datacreateSubscriptionPlan,
    },
  ] = useMutation<any>(CREATE_SUBSCRIPTION_PLAN);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      companyBaseCost: subscriptionData ? subscriptionData.companyBaseCost : '',
      intermedialUserBaseCost: subscriptionData
        ? subscriptionData.intermedialUserBaseCost
        : '',
      costPerUser: subscriptionData ? subscriptionData.costPerUser : '',
      costPerCompany: subscriptionData ? subscriptionData.costPerCompany : '',
      duration: subscriptionData ? subscriptionData.duration : '',
    },
    validationSchema: Yup.object({
      companyBaseCost: Yup.string()
        .required('Please select companyBaseCost')
        .nullable(),
      intermedialUserBaseCost: Yup.string()
        .required('Please select intermedialUserBaseCost')
        .nullable(),
      duration: Yup.string().required('Please select duration').nullable(),
      costPerUser: Yup.string()
        .required('Please enter cost per user')
        .nullable(),
      costPerCompany: Yup.string()
        .required('Please enter cost per company')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (selectedSubscriptionId) {
        updateSubscriptionPlan({
          variables: {
            id: selectedSubscriptionId,
            duration: values.duration,
            costPerUser: values.costPerUser,
            costPerCompany: values.costPerCompany,
            companyBaseCost: values.companyBaseCost,
            intermedialUserBaseCost: values.intermedialUserBaseCost,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateSubscriptionPlan) {
              dispatch(setToastSuccess('Subscription updated!'));
              navigate(`/admin/subscription`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createSubscriptionPlan({
          variables: {
            companyId: null,
            duration: values.duration,
            costPerUser: values.costPerUser,
            costPerCompany: values.costPerCompany,
            companyBaseCost: values.companyBaseCost,
            intermedialUserBaseCost: values.intermedialUserBaseCost,
          },
        })
          .then((res: any) => {
            if (res?.data?.createSubscriptionPlan) {
              dispatch(setToastSuccess('Subscription created!'));
              navigate(`/admin/subscription`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingfindSubscriptionById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentHeading">Subscription Plan</div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Subscription Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="duration">Duration*</label>
                      {validation.touched.duration &&
                      validation.errors.duration ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.duration}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.duration &&
                          validation.errors.duration &&
                          'is-invalid'
                        }`}
                        id="duration"
                        name="duration"
                        placeholder="duration"
                        value={validation.values.duration}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={SubscriptionPlansEnum.ONE_YEAR_PLAN}>
                          1 Year
                        </option>
                        <option value={SubscriptionPlansEnum.TWO_YEARS_PLAN}>
                          2 Years
                        </option>
                        <option value={SubscriptionPlansEnum.THREE_YEARS_PLAN}>
                          3 Years
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="actionItem">Cost Per User*</label>
                      {validation.touched.costPerUser &&
                      validation.errors.costPerUser ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.costPerUser}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control form-control-medium ${
                        validation.touched.costPerUser &&
                        validation.errors.costPerUser &&
                        'is-invalid'
                      }`}
                      id="costPerUser"
                      name="costPerUser"
                      placeholder="Cost per user"
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={validation.values.costPerUser}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="costPerCompany">Cost Per Company*</label>
                      {validation.touched.costPerCompany &&
                      validation.errors.costPerCompany ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.costPerCompany}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control form-control-medium ${
                        validation.touched.costPerCompany &&
                        validation.errors.costPerCompany &&
                        'is-invalid'
                      }`}
                      id="costPerCompany"
                      name="costPerCompany"
                      placeholder="Cost per company"
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={validation.values.costPerCompany}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="actionItem">Company Based Cost*</label>
                      {validation.touched.companyBaseCost &&
                      validation.errors.companyBaseCost ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.companyBaseCost}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control form-control-medium ${
                        validation.touched.companyBaseCost &&
                        validation.errors.companyBaseCost &&
                        'is-invalid'
                      }`}
                      id="companyBaseCost"
                      name="companyBaseCost"
                      placeholder="company based cost"
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={validation.values.companyBaseCost}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="actionItem">
                        Intermediate User Based Cost*
                      </label>
                      {validation.touched.intermedialUserBaseCost &&
                      validation.errors.intermedialUserBaseCost ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.intermedialUserBaseCost}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="number"
                      className={`form-control form-control-medium ${
                        validation.touched.intermedialUserBaseCost &&
                        validation.errors.intermedialUserBaseCost &&
                        'is-invalid'
                      }`}
                      id="intermedialUserBaseCost"
                      name="intermedialUserBaseCost"
                      placeholder="intermediate user based cost"
                      min="0"
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={validation.values.intermedialUserBaseCost}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div>

              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  disabled={
                    loadingfindSubscriptionById ||
                    loadingupdateSubscriptionPlan ||
                    loadingcreateSubscriptionPlan
                  }
                  onMouseDown={() => {
                    navigate('/admin/subscription');
                  }}
                >
                  Cancel
                </button>
                {true ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={
                      loadingfindSubscriptionById ||
                      loadingupdateSubscriptionPlan ||
                      loadingcreateSubscriptionPlan
                    }
                    onMouseDown={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {(loadingfindSubscriptionById ||
                      loadingupdateSubscriptionPlan ||
                      loadingcreateSubscriptionPlan) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
