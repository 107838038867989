import { gql } from '@apollo/client';

export const SAVE_DEVICE_TOKEN = gql`
  mutation addDeviceToken($token: String!) {
    addDeviceToken(data: { os: "web", token: $token }) {
      id
      userId
      os
      token
    }
  }
`;
export const UPDATE_TASK_STATUS = gql`
  mutation updateTaskStatus($id: String, $status: Boolean) {
    updateTaskStatus(data: { id: $id, status: $status }) {
      id
      notificationId
      taskId
      status
    }
  }
`;
export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus($id: String, $status: Boolean) {
    updateNotificationStatus(data: { id: $id, status: $status }) {
      id
      companyId
      eventId
      status
      expiryDate
    }
  }
`;

export const FIND_NOTIFICATIONS = gql`
  query findNotifications(
    $first: Int
    $last: Int
    $status: Boolean
    $companyId: String
    $after: String
    $before: String
  ) {
    findNotifications(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, status: $status }
      orderBy: "-updatedAt"
    ) {
      edges {
        node {
          id
          companyId
          eventId
          expiryDate
          status
          title
          message
          tasks {
            id
            taskId
            status
            sequence
            message
            url
          }
          userIds
          createdAt
          updatedAt
          version
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_TEMP_NOTIFICATIONS = gql`
  query getAllTempNotifications(
    $first: Int
    $last: Int
    $userId: String
    $after: String
    $before: String
  ) {
    getAllTempNotifications(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { userId: $userId }
    ) {
      edges {
        node {
          id
          notificationMessage
          notificationId
          userId
          status
          createdAt
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const DELETE_TEMP_NOTIFICATION = gql`
  mutation deleteTempNotificationById($id: String!) {
    deleteTempNotificationById(data: { id: $id }) {
      count
    }
  }
`;

export const ASSIGN_NOTIFICATION_TO_USERS = gql`
  mutation assignNotificationToUser(
    $notificationId: String
    $userId: [String]
  ) {
    assignNotificationToUser(
      data: { notificationId: $notificationId, userId: $userId }
    ) {
      isSent
    }
  }
`;
