import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { GET_ALL_COMPANY_PROFILES } from '~/Store/graphQL/CompanyProfile';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  clearStore,
  setBreadCrumb,
  setToastError,
  setUserId,
} from '~/Store/reducers/layout';
import jwt_decode from 'jwt-decode';
import {
  Companies,
  companyWhite,
  Logo,
  RegistrationType,
} from '~/assets/images';
import { LOG_OUT } from '~/Store/graphQL/Auth';
import { FIND_USER } from '~/Store/graphQL/Users';
import { setSelectedUserPermissions } from '~/Store/reducers/users';
import Cookies from 'js-cookie';

export const CompanyProfiles: FC = () => {
  const { token, userId } = useAppSelector(state => state.layoutReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [companiesList, setCompaniesList] = useState<any>();
  const { deviceToken } = useAppSelector(state => state.notificationReducer);
  const client = useApolloClient();
  const [
    getAllCompanyProfiles,
    {
      loading: loadingGetAllCompanyProfiles,
      error: errorGetAllCompanyProfiles,
      data: dataGetAllCompanyProfiles,
    },
  ] = useLazyQuery<any>(GET_ALL_COMPANY_PROFILES);
  const [
    logout,
    { loading: loadinglogout, error: errorlogout, data: datalogout },
  ] = useMutation<any>(LOG_OUT);
  // Get users data
  const [
    userData,
    { loading: loadingUserData, error: errorUserData, data: dataUserData },
  ] = useLazyQuery<any>(FIND_USER, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Company Profile',
        },
      ])
    );
  }, []);
  useEffect(() => {
    if (token) {
      const { user } = jwt_decode(token) as any;
      getAllCompanyProfiles({
        variables: {
          userId: user.id,
        },
      });
      userData({
        variables: {
          id: user.id,
        },
      });
    }
  }, [token]);
  useEffect(() => {
    if (dataGetAllCompanyProfiles && !errorGetAllCompanyProfiles) {
      let temp: any[] = [];
      dataGetAllCompanyProfiles.getAllCompanyProfiles.companyProfileConnection.edges?.forEach(
        (company: any) => {
          temp.push(company.node);
        }
      );
      dataGetAllCompanyProfiles.getAllCompanyProfiles.draftCompanyProfileConnection.edges?.forEach(
        (company: any) => {
          let tempCompany = JSON.parse(JSON.stringify(company.node));
          tempCompany.isDraft = true;
          temp.push(tempCompany);
        }
      );
      setCompaniesList(temp);
    } else if (errorGetAllCompanyProfiles) {
      dispatch(setToastError(errorGetAllCompanyProfiles));
    }
  }, [dataGetAllCompanyProfiles, errorGetAllCompanyProfiles]);
  useEffect(() => {
    let permissionsArray: any = [];
    if (dataUserData) {
      dataUserData?.user?.userGroups?.forEach((element: any) => {
        element.permissions.forEach((permission: any) => {
          permissionsArray.push(permission);
        });
      });
      dispatch(setSelectedUserPermissions(permissionsArray));
    } else if (errorUserData) {
      console.log('errorUserData', errorUserData);
      dispatch(setToastError(errorUserData));
    }
  });
  return (
    <React.Fragment>
      <div id="mainComponentCompaniesPage">
        {loadingGetAllCompanyProfiles ? (
          <div className="d-flex justify-content-center align-items-center w-100 height-100vh">
            <i className="bx bx-loader-circle bx-spin bx-lg"></i>
          </div>
        ) : (
          <div style={{ width: 'inherit' }}>
            <div className="w-100 m-0 p-0">
              <div className="row w-100 m-0 p-0">
                <img className="m-0 p-0" src={Companies}></img>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <img src={Logo} className={'mb-3 width180'} />
              </div>
            </div>
            {companiesList?.length === 0 ? (
              <div className="addDiv d-flex justify-content-center align-items-center flex-column mt-5">
                <h5 className="m-3">No company found</h5>
                <p className="font-14 mt-2 text-disabled mb-0 d-flex justify-content-center align-items-center">
                  Want to Logout?{' '}
                  <span
                    className="text-primary cursor-pointer d-flex justify-content-center align-items-center"
                    onClick={() => {
                      logout({
                        variables: {
                          deviceToken: deviceToken,
                        },
                      })
                        .then((res: any) => {
                          if (res.data.logout) {
                            dispatch(clearStore());
                            client.clearStore();
                            client.cache.reset();
                            const options = {
                              path: '/',
                              domain: 'compliancedigital.com.pk',
                            };
                            Cookies.remove('NEXT_AUTH_TOKEN', options);
                            window.open(
                              `https://compliancedigital.com.pk/home`,
                              '_self'
                            );
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                    }}
                  >
                    Click Here
                    <i className="bx bx-log-out font-20 me-2 text-primary rotate-180"></i>
                  </span>
                </p>
              </div>
            ) : (
              <>
                <div className="row mt-2">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img src={RegistrationType} />
                    <h3 className="color-grey m-0 p-0 mx-3">
                      Select Company you want to Login
                    </h3>
                  </div>
                </div>

                <div className="row my-5 margin-x-4-vw gap-5">
                  {companiesList?.map((element: any, index: number) => (
                    <div
                      className={`width-20-vw border-div-with-shadow p-0 cursor-pointer ${
                        userId === element.companyId ? ' selectedCard' : ''
                      }`}
                      key={index}
                      onClick={e => {
                        dispatch(setUserId(element.companyId));
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center flex-column my-3 mx-3">
                        <img src={companyWhite} className={'width-30'} />
                        {element.name}
                      </div>
                      <hr className="color-font-faded my-2"></hr>
                      <div className="color-font-faded d-flex justify-content-between align-items-center mx-3">
                        <div>Pending Meetings</div>
                        <div className="fw-bold color-grey">
                          {element?.dashboardData?.pendingMeeting}
                        </div>
                      </div>
                      <hr className="color-font-faded my-2"></hr>
                      <div className="color-font-faded d-flex justify-content-between align-items-center mx-3">
                        <div>Pending Minutes</div>
                        <div className="fw-bold color-grey">
                          {element?.dashboardData?.pendingMom}
                        </div>
                      </div>
                      <hr className="color-font-faded my-2"></hr>
                      <div className="color-font-faded d-flex justify-content-between align-items-center mx-3 mb-2">
                        <div>Pending Returns</div>
                        <div className="fw-bold color-grey">
                          {element?.dashboardData?.pendingReturn}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                    <button
                      className="btn btn-primary color-whiter font-weight-600 width-20vw"
                      disabled={
                        !companiesList?.find((element: any) => {
                          return element.companyId === userId;
                        })
                      }
                      onClick={() => {
                        navigate('/dashboard');
                      }}
                    >
                      Next
                    </button>
                    <div className="font-14">
                      <p className="mt-2 text-disabled mb-0 d-flex justify-content-center align-items-center">
                        Want to Logout?{' '}
                        <span
                          className="text-primary cursor-pointer d-flex justify-content-center align-items-center"
                          onClick={() => {
                            logout({
                              variables: {
                                deviceToken: deviceToken,
                              },
                            })
                              .then((res: any) => {
                                if (res.data.logout) {
                                  dispatch(clearStore());
                                  client.clearStore();
                                  client.cache.reset();
                                  const options = {
                                    path: '/',
                                    domain: 'compliancedigital.com.pk',
                                  };
                                  Cookies.remove('NEXT_AUTH_TOKEN', options);
                                  window.open(
                                    `https://compliancedigital.com.pk/home`,
                                    '_self'
                                  );
                                  // window.open(
                                  //   `http://localhost:3001/home`,
                                  //   '_self'
                                  // );
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          }}
                        >
                          Click Here
                          <i className="bx bx-log-out font-20 me-2 text-primary rotate-180"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
