import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_OF_USER } from '~/Store/graphQL/Users';

export const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);
  const [showCurrentPass, setShowCurrentPass] = useState<boolean>(false);
  const [passStrength, setPassStrength] = useState<number>(0);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Change Password',
        },
      ])
    );
  }, []);
  const [
    updatePassword,
    {
      loading: loadingUpdatePassword,
      error: errorUpdatePassword,
      data: dataUpdatePassword,
    },
  ] = useMutation<any>(CHANGE_PASSWORD_OF_USER);
  useEffect(() => {
    if (pass.length < 8) {
      setPassStrength(0);
    } else if (pass.length >= 8) {
      setPassStrength(1);
      //   if (
      //       (/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(pass))
      //       || (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(pass))
      //       || (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(pass))
      //   ) {
      //       setPassStrength(2)
      //       if (confirmPass === pass) {
      //           setPassword(pass)
      //       } else {
      //           setPassword('')
      //       }
      //   }
      if (/((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pass)) {
        setPassStrength(2);
      }
      if (
        /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pass) &&
        pass.length >= 12
      ) {
        setPassStrength(3);
      }
    }
  }, [pass, confirmPassword]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      currentPassword: currentPassword ? currentPassword : '',
      newPassword: pass ? pass : '',
      confirmPassword: confirmPassword ? confirmPassword : '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required('Please enter current password')
        .nullable(),
      newPassword: Yup.string()
        .required('Please enter new password')
        .nullable(),
      confirmPassword: Yup.string()
        .required('Please enter confirm password')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      updatePassword({
        variables: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      })
        .then((res: any) => {
          if (res?.data?.updatePassword?.user) {
            dispatch(setToastSuccess('Password updated!'));
            navigate(`/`);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });
  return (
    <React.Fragment>
      <div className="w-100">
        <div id="mainComponentHeading">Change Password Info</div>
        <div id="mainComponentNew">
          <div className="inputFields">
            <div className="mb-4">
              <div className="formGroupHeading">Change Password</div>
              <hr className="formGroupHeadingUnderline"></hr>
            </div>
            <div className="form-group">
              <div className="d-flex w-400">
                <label htmlFor="password">Current Password*</label>
                {validation.touched.currentPassword &&
                validation.errors.currentPassword ? (
                  <p className="text-danger font-12 ms-auto mb-0">
                    {validation.errors.currentPassword}
                  </p>
                ) : null}
              </div>
              <div style={{ position: 'relative', width: 'max-content' }}>
                <input
                  type={showCurrentPass ? 'text' : 'password'}
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Current Password"
                  className={`form-control`}
                  onChange={e => {
                    validation.setFieldValue('currentPassword', e.target.value);
                    setCurrentPassword(e.target.value);
                  }}
                  value={currentPassword}
                />
                {showCurrentPass ? (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowCurrentPass(false)}
                    className="bx bx-hide eyeIcon cursor-pointer"
                  />
                ) : (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowCurrentPass(true)}
                    className="bx bx-show eyeIcon cursor-pointer"
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex w-400">
                <label htmlFor="password">New Password*</label>
                {pass && passStrength === 0 ? (
                  <p className="text-danger font-12 ms-auto mb-0">
                    Password is too weak!
                  </p>
                ) : null}
                {validation.touched.newPassword &&
                validation.errors.newPassword ? (
                  <p className="text-danger font-12 ms-auto mb-0">
                    {validation.errors.newPassword}
                  </p>
                ) : null}
              </div>
              <div style={{ position: 'relative', width: 'max-content' }}>
                <input
                  type={showNewPass ? 'text' : 'password'}
                  id="newPassword"
                  name="newPassword"
                  placeholder="Password"
                  className={`form-control`}
                  key="pass"
                  onChange={e => {
                    validation.setFieldValue('newPassword', e.target.value);
                    setPass(e.target.value);
                  }}
                  value={pass}
                />
                {showNewPass ? (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowNewPass(false)}
                    className="bx bx-hide eyeIcon cursor-pointer"
                  />
                ) : (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowNewPass(true)}
                    className="bx bx-show eyeIcon cursor-pointer"
                  />
                )}
              </div>
              <div className="d-flex align-items-center mb-3 width-400">
                <div
                  className={`flex-grow-1 rounded height-4 me-2 ${
                    passStrength === 1 || passStrength === 2
                      ? 'bg-warning'
                      : passStrength === 0
                      ? 'bg-danger'
                      : 'bg-success'
                  }`}
                ></div>
                <div
                  className={`flex-grow-1 rounded height-4 me-2 ${
                    passStrength <= 1
                      ? 'bg-info'
                      : passStrength === 2
                      ? 'bg-warning'
                      : 'bg-success'
                  }`}
                >
                  <p></p>
                </div>
                <div
                  className={`flex-grow-1 rounded height-4 me-2 ${
                    passStrength > 2 ? 'bg-success' : 'bg-info'
                  }`}
                >
                  <p></p>
                </div>
                <div
                  className={`${
                    passStrength === 0
                      ? 'text-danger'
                      : passStrength === 1 || passStrength === 2
                      ? 'text-warning'
                      : passStrength === 3 && 'text-success'
                  } font-10`}
                >
                  {passStrength === 0
                    ? 'Weak'
                    : passStrength === 1
                    ? 'Normal'
                    : passStrength === 2
                    ? 'Good'
                    : passStrength === 3
                    ? 'Strong'
                    : null}
                </div>
              </div>
              {passStrength < 2 && (
                <p className="font-14 passWarning width-400 text-center">
                  Password must be 8 characters long, and contain at least one
                  uppercase letter, one lowercase letter, one number and one
                  special character
                </p>
              )}
            </div>
            <div className="form-group">
              <div className="d-flex w-400">
                <label htmlFor="password">Confirm Password*</label>
                {confirmPassword.length > 0 && pass !== confirmPassword ? (
                  <p className="text-danger font-12 ms-auto mb-0">
                    Passwords must match!
                  </p>
                ) : null}
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <p className="text-danger font-12 ms-auto mb-0">
                    {validation.errors.confirmPassword}
                  </p>
                ) : null}
              </div>
              <div style={{ position: 'relative', width: 'max-content' }}>
                <input
                  type={showConfirmPass ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className={`form-control`}
                  onChange={e => {
                    validation.setFieldValue('confirmPassword', e.target.value);
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                />
                {showConfirmPass ? (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowConfirmPass(false)}
                    className="bx bx-hide eyeIcon cursor-pointer"
                  />
                ) : (
                  <i
                    style={{ position: 'absolute', right: '15px', top: '18px' }}
                    onClick={() => setShowConfirmPass(true)}
                    className="bx bx-show eyeIcon cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 w-100">
            <button
              type="button"
              className="btn primaryButton d-flex align-items-center"
              disabled={loadingUpdatePassword}
              onMouseDown={() => {
                navigate('/');
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn successButton d-flex align-items-center"
              disabled={loadingUpdatePassword}
              onMouseDown={() => {
                validation.handleSubmit();
              }}
            >
              {loadingUpdatePassword && (
                <i className="bx bx-loader-circle bx-spin me-1" />
              )}
              Save
              <i className="bx bx-upload mx-1" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
