import { gql } from '@apollo/client';
export const GET_ALL_HELP_SECTION = gql`
  query getAllHelpSections(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $moduleName: String
  ) {
    getAllHelpSections(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { moduleName: $moduleName }
      orderBy: "createdAt"
    ) {
      edges {
        node {
          id
          moduleName
          heading
          instructions
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const DELETE_HELP_SECTION = gql`
  mutation deleteHelpSection($id: String!) {
    deleteHelpSection(data: { id: $id }) {
      count
    }
  }
`;
export const CREATE_HELP_OF_MODULE = gql`
  mutation createHelpSection(
    $moduleName: String
    $heading: String
    $instructions: String
  ) {
    createHelpSection(
      data: {
        moduleName: $moduleName
        heading: $heading
        instructions: $instructions
      }
    ) {
      helpSection {
        id
        moduleName
        heading
        instructions
      }
    }
  }
`;
export const UPDATE_HELP_OF_MODULE = gql`
  mutation updateHelpSection(
    $id: String!
    $moduleName: String
    $heading: String
    $instructions: String
  ) {
    updateHelpSection(
      data: {
        id: $id
        data: {
          moduleName: $moduleName
          heading: $heading
          instructions: $instructions
        }
      }
    ) {
      helpSection {
        id
        moduleName
        heading
        instructions
      }
    }
  }
`;
export const FIND_HELP_BY_ID = gql`
  query findOneHelpSection($id: ID!) {
    findOneHelpSection(id: $id) {
      helpSection {
        id
        moduleName
        heading
        instructions
      }
    }
  }
`;
