import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBankAccountViewScreen,
  setSelectedBankAccountId,
} from '~/Store/reducers/bankAccount';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './bankAccount.scss';
import {
  DELETE_BANK_ACCOUNT,
  GET_ALL_BANK_ACCOUNTS_OF_COMPANY,
} from '~/Store/graphQL/BankAccountManagement';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import {
  BankSearchParameterOptionsEnum,
  MeetingSearchParameterOptionsEnum,
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { Tooltip } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const BankAccount: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Bank Account',
        },
      ])
    );
  }, []);
  // Get all bank accounts query
  const [
    getAllBankAccountForCompany,
    {
      loading: loadingGetAllBankAccountForCompany,
      error: errorGetAllBankAccountForCompany,
      data: dataGetAllBankAccountForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_BANK_ACCOUNTS_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteBankAccount,
    {
      loading: loadingDeleteBankAccount,
      error: errorDeleteBankAccount,
      data: dataDeleteBankAccount,
    },
  ] = useMutation<any>(DELETE_BANK_ACCOUNT);
  useEffect(() => {
    if (companyID) {
      getAllBankAccountForCompany({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllBankAccountForCompany) {
      dispatch(setToastError(errorGetAllBankAccountForCompany));
    }
  }, [errorGetAllBankAccountForCompany]);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0 ">
                <b>Bank Account</b>
              </h5>
            </div>
          </div>
          <div className="d-flex  row mt-2 mb-4 ">
            {/* Second Row */}
            <div className="committeefields w-auto">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="meetingType">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={BankSearchParameterOptionsEnum.BANK_NAME}>
                      {' '}
                      Bank Name{' '}
                    </option>
                    <option value={BankSearchParameterOptionsEnum.CITY}>
                      {' '}
                      City{' '}
                    </option>
                    <option value={BankSearchParameterOptionsEnum.BRANCH}>
                      {' '}
                      Branch{' '}
                    </option>
                    <option
                      value={BankSearchParameterOptionsEnum.ACCOUNT_NUMBER}
                    >
                      {' '}
                      Account Number{' '}
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="searchValue">Search Data</label>
                  {validationSearch.touched.searchValue &&
                  validationSearch.errors.searchValue ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchValue}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  id="searchValue"
                  name="searchValue"
                  placeholder="Text"
                  className={`form-control ${
                    validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue &&
                    'is-invalid'
                  }`}
                  value={validationSearch.values.searchValue}
                  onKeyDown={event => {
                    const keyCode = event.keyCode || event.which;
                    // Check if the key pressed is the decimal point
                    if (keyCode === 190 || keyCode === 110) {
                      event.preventDefault(); // Prevent the decimal point from being entered
                    }
                  }}
                  onChange={e => {
                    const { value } = e.target;
                    validationSearch.setFieldValue('searchValue', value);
                  }}
                />
              </div>
              <button
                type="button"
                className="btn dangerButton fw-bold d-flex align-items-center my-auto"
                onClick={() => {
                  if (validationSearch?.values.searchKey) {
                    getAllBankAccountForCompany({
                      variables: {
                        first: itemsPerPage,
                        bankName: undefined,
                      },
                    });
                  }
                  validationSearch?.resetForm();
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn successButton fw-bold d-flex align-items-center my-auto"
                disabled={loadingGetAllBankAccountForCompany}
                onClick={() => {
                  validationSearch.handleSubmit();
                  if (
                    validationSearch?.values.searchValue &&
                    validationSearch.values.searchKey
                  ) {
                    getAllBankAccountForCompany({
                      variables: {
                        first: itemsPerPage,
                        searchKey: validationSearch?.values?.searchKey
                          ? validationSearch?.values?.searchKey
                          : undefined,
                        searchValue: validationSearch?.values?.searchValue
                          ? validationSearch?.values?.searchValue
                          : undefined,
                      },
                    });
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <div
            className={`d-flex  justify-content-end w-auto gap-3 my-3 ms-auto me-0 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.BANK_ACCOUNTS,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary  fw-bold primary-btn-new d-flex align-items-center"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.BANK_ACCOUNTS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setBankAccountViewScreen(false));
                dispatch(setSelectedBankAccountId(null));
                navigate('/bankAccounts/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Bank Account
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.BANK_ACCOUNTS,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllBankAccountForCompany ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Bank Name</th>
                            <th>City</th>
                            <th>Branch</th>
                            <th>Account Number</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllBankAccountForCompany?.findAllBankAccounts?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {index + 1}
                                  </div>
                                </td>
                                <td>
                                  {val.node.bankName
                                    ? val.node.bankName
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.city ? val.node.city : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.branch ? val.node.branch : 'N/A'}
                                </td>

                                <td>
                                  <div className="badge custom-badge-success">
                                    {val.node.accountNumber
                                      ? val.node.accountNumber
                                      : 'N/A'}
                                  </div>
                                </td>

                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.BANK_ACCOUNTS,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.BANK_ACCOUNTS,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedBankAccountId(
                                              val.node.id
                                            )
                                          );
                                          dispatch(
                                            setBankAccountViewScreen(true)
                                          );
                                          navigate('/bankAccounts/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.BANK_ACCOUNTS,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.BANK_ACCOUNTS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-edit text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.BANK_ACCOUNTS,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(
                                            setBankAccountViewScreen(false)
                                          );
                                          dispatch(
                                            setSelectedBankAccountId(
                                              val.node.id
                                            )
                                          );
                                          navigate('/bankAccounts/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.BANK_ACCOUNTS,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.BANK_ACCOUNTS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.BANK_ACCOUNTS,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(
                                            setBankAccountViewScreen(false)
                                          );
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteBankAccount({
                                                variables: {
                                                  id: val.node.id,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data.deleteBankAccount
                                                      .count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Bank Account Deleted!'
                                                      )
                                                    );
                                                    getAllBankAccountForCompany(
                                                      {
                                                        variables: {
                                                          first: itemsPerPage,
                                                          searchKey:
                                                            validationSearch
                                                              ?.values
                                                              ?.searchKey !==
                                                            'ALL'
                                                              ? validationSearch
                                                                  ?.values
                                                                  ?.searchKey
                                                              : undefined,
                                                          searchValue:
                                                            validationSearch
                                                              ?.values
                                                              ?.searchValue !==
                                                            'Select'
                                                              ? validationSearch
                                                                  ?.values
                                                                  ?.searchValue
                                                              : undefined,
                                                        },
                                                      }
                                                    );
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.BANK_ACCOUNTS,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllBankAccountForCompany?.findAllBankAccounts
                        ?.edges?.length === 0 ||
                        dataGetAllBankAccountForCompany?.findAllBankAccounts
                          ?.edges === undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllBankAccountForCompany?.findAllBankAccounts
                            ?.pageInfo?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllBankAccountForCompany?.findAllBankAccounts
                              ?.pageInfo?.hasPreviousPage
                          ) {
                            getAllBankAccountForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                last: itemsPerPage,
                                before:
                                  dataGetAllBankAccountForCompany
                                    ?.findAllBankAccounts?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllBankAccountForCompany?.findAllBankAccounts
                            ?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllBankAccountForCompany?.findAllBankAccounts
                              ?.pageInfo?.hasNextPage
                          ) {
                            getAllBankAccountForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                after:
                                  dataGetAllBankAccountForCompany
                                    ?.findAllBankAccounts?.pageInfo?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
