import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './financialYear.scss';
import {
  setFinancialYearViewScreen,
  setSelectedFinancialYearId,
} from '~/Store/reducers/financialYear';
import {
  DELETE_FINANCIAL_YEAR,
  GET_ALL_FINANCIAL_YEARS_OF_COMPANY,
} from '~/Store/graphQL/FinancialYearManagement';
import moment from 'moment';

import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { Tooltip } from 'reactstrap';

export const FinancialYear: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Financial Year',
        },
      ])
    );
  }, []);

  // Get all financial year query
  const [
    getAllFinancialYearForCompany,
    {
      loading: loadingGetAllFinancialYearForCompany,
      error: errorGetAllFinancialYearForCompany,
      data: dataGetAllFinancialYearForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_FINANCIAL_YEARS_OF_COMPANY, {
    variables: {
      companyId: companyID,
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });
  // delete financial year
  const [
    deleteFinancialYear,
    {
      loading: loadingDeleteFinancialYear,
      error: errorDeleteFinancialYear,
      data: dataDeleteFinancialYear,
    },
  ] = useMutation<any>(DELETE_FINANCIAL_YEAR);
  useEffect(() => {
    if (companyID) {
      getAllFinancialYearForCompany();
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllFinancialYearForCompany) {
      dispatch(setToastError(errorGetAllFinancialYearForCompany));
    }
  }, [errorGetAllFinancialYearForCompany]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Financial Year</b>
              </h5>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.FINANCIAL_YEAR,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary fw-bold primary-btn-new d-flex align-items-center"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.FINANCIAL_YEAR,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setFinancialYearViewScreen(false));
                dispatch(setSelectedFinancialYearId(null));
                navigate('/financialYear/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Financial Year
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.FINANCIAL_YEAR,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllFinancialYearForCompany ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="col-1">S.No</th>
                            <th className="col-3">Financial Year Name</th>
                            <th className="col-3">Start Date</th>
                            <th className="col-3">End Date</th>
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllFinancialYearForCompany?.findAllFinancialYears?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {index + 1}
                                  </div>
                                </td>
                                <td>{val.node.name ? val.node.name : 'N/A'}</td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.startDate
                                      ? moment(val.node.startDate).format(
                                          'DD-MM-YYYY'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {val.node.endDate
                                      ? moment(val.node.endDate).format(
                                          'DD-MM-YYYY'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.FINANCIAL_YEAR,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.FINANCIAL_YEAR,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedFinancialYearId(
                                              val.node.id
                                            )
                                          );
                                          dispatch(
                                            setFinancialYearViewScreen(true)
                                          );
                                          navigate('/financialYear/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.FINANCIAL_YEAR,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.FINANCIAL_YEAR,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-edit text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.FINANCIAL_YEAR,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(
                                            setFinancialYearViewScreen(false)
                                          );
                                          dispatch(
                                            setSelectedFinancialYearId(
                                              val.node.id
                                            )
                                          );
                                          navigate('/financialYear/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.FINANCIAL_YEAR,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.FINANCIAL_YEAR,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.FINANCIAL_YEAR,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(
                                            setFinancialYearViewScreen(false)
                                          );
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteFinancialYear({
                                                variables: {
                                                  id: val.node.id,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data.deleteFinancialYear
                                                      .count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Financial Year Deleted!'
                                                      )
                                                    );
                                                    getAllFinancialYearForCompany();
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.FINANCIAL_YEAR,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllFinancialYearForCompany?.findAllFinancialYears
                        ?.edges?.length === 0 ||
                        dataGetAllFinancialYearForCompany?.findAllFinancialYears
                          ?.edges === undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllFinancialYearForCompany
                            ?.findAllFinancialYears?.pageInfo?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllFinancialYearForCompany
                              ?.findAllFinancialYears?.pageInfo?.hasPreviousPage
                          ) {
                            getAllFinancialYearForCompany({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllFinancialYearForCompany
                                    ?.findAllFinancialYears?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllFinancialYearForCompany
                            ?.findAllFinancialYears?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllFinancialYearForCompany
                              ?.findAllFinancialYears?.pageInfo?.hasNextPage
                          ) {
                            getAllFinancialYearForCompany({
                              variables: {
                                after:
                                  dataGetAllFinancialYearForCompany
                                    ?.findAllFinancialYears?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
