import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './legalDoc.scss';
import { useMutation, useQuery } from '@apollo/client';

import { documentLogo } from '~/assets/images';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';

import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { DocumentTypeEnum, ModuleEnum } from '~/commons/enums';
import { UPLOAD_DOCUMENT } from '~/Store/graphQL/LegalDoc';

export const AddLegalDoc: FC = () => {
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [moduleName, setmoduleName] = useState<any>();
  const [documentType, setDocumentType] = useState<any>();
  const [attachment, setAttachment] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/legalDoc',
          title: 'Legal Documents',
        },
        {
          url: location.pathname,
          title: 'Add Legal Documents',
        },
      ])
    );
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.LEGAL_DOCUMENTS,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });

  const [
    uploadDocument,
    {
      loading: loadingUploadDocument,
      error: errorUploadDocument,
      data: dataUploadDocument,
    },
  ] = useMutation<any>(UPLOAD_DOCUMENT);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      moduleName: moduleName,
      documentType: documentType,
      attachment: attachment,
    },
    validationSchema: Yup.object({
      moduleName: Yup.string().required('Please select module name').nullable(),
      documentType: Yup.string()
        .required('Please select document type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (validation.values.attachment) {
        uploadDocument({
          variables: {
            companyId: companyID,
            moduleName: values.moduleName,
            documentType: values.documentType,
            file: values.attachment,
          },
        })
          .then((res: any) => {
            if (res?.data?.uploadDocument?.isUploaded) {
              dispatch(setToastSuccess('Document created!'));
              navigate(`/legalDoc`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        dispatch(setToastError('Please attach document!'));
      }
    },
  });
  return (
    <React.Fragment>
      {false ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 d-flex parent">
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="justify-content-end  background-primary-medium"
            >
              <div className="">
                <div className="d-flex gap-3 position-relative end-0 mx-4">
                  <button
                    type="button"
                    className="btn primaryButton d-flex align-items-center my-auto fw-bold height-100"
                    onClick={() => {
                      navigate('/legalDoc');
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto fw-bold"
                    onClick={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {loadingUploadDocument && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                </div>
              </div>
            </div>
            <div id="mainComponentNew">
              <div className="inputFields p-3">
                {/* Fist row starts from here */}

                <div className="row rounded border px-2 pb-4">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center mt-3">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={documentLogo}
                        ></img>
                        <div className="font-weight-600">
                          Legal Document Info
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inputFields">
                    <div className="row mb-2 w-100">
                      <div className="col-3">
                        <div className="form-group">
                          <div className="d-flex ">
                            <label htmlFor="moduleName">Module*</label>
                            {validation.touched.moduleName &&
                            validation.errors.moduleName ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.moduleName}
                              </p>
                            ) : null}
                          </div>

                          <div className="field-fix w-100">
                            <select
                              id="moduleName"
                              name="moduleName"
                              className={`form-control w-autox`}
                              value={moduleName}
                              onChange={e => {
                                setmoduleName(e.target.value);
                                validation.handleChange(e);
                              }}
                            >
                              <option>Select</option>
                              <option value={ModuleEnum.COMPANY_PROFILE}>
                                Company Profile
                              </option>
                              <option value={ModuleEnum.COMPANY_OFFICERS}>
                                Company Officers
                              </option>
                              <option value={ModuleEnum.COMPANY_MEMBERS}>
                                Company Members
                              </option>
                              <option value={ModuleEnum.REGISTER}>
                                Registers
                              </option>
                              <option value={ModuleEnum.NOTIFICATION}>
                                Notifications
                              </option>
                              <option value={ModuleEnum.MEETING}>
                                Meeting Management
                              </option>
                              <option value={ModuleEnum.COMMITTEE}>
                                Committee Management
                              </option>
                              <option value={ModuleEnum.SHARE_MANAGEMENT}>
                                Company Shares Management
                              </option>
                              <option value={ModuleEnum.BANK_ACCOUNTS}>
                                Bank Account Management
                              </option>
                              <option value={ModuleEnum.FINANCIAL_YEAR}>
                                Financial Year Management
                              </option>
                              <option value={ModuleEnum.SYSTEM_USER}>
                                System Users Management
                              </option>
                              <option value={ModuleEnum.SYSTEM_USER_GROUPS}>
                                User Groups Management
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <div className="d-flex ">
                            <label htmlFor="documentType">Document Type*</label>
                            {validation.touched.documentType &&
                            validation.errors.documentType ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {validation.errors.documentType}
                              </p>
                            ) : null}
                          </div>

                          <div className="field-fix w-100">
                            <select
                              id="documentType"
                              name="documentType"
                              className={`form-control w-autox`}
                              value={documentType}
                              onChange={e => {
                                setDocumentType(e.target.value);
                                validation.handleChange(e);
                              }}
                            >
                              <option>Select</option>
                              <option
                                value={DocumentTypeEnum.Minutes_of_Meeting}
                              >
                                Minutes of Meeting
                              </option>
                              <option value={DocumentTypeEnum.Agreements}>
                                Agreements
                              </option>
                              <option value={DocumentTypeEnum.Resolutions}>
                                Resolutions
                              </option>
                              <option
                                value={DocumentTypeEnum.Appointment_of_Auditor}
                              >
                                Letters of engagement
                              </option>
                              <option
                                value={DocumentTypeEnum.Appointment_of_Chairman}
                              >
                                Appointment of Chairman
                              </option>
                              <option value={DocumentTypeEnum.Court_order}>
                                Court orders
                              </option>
                              <option value={DocumentTypeEnum.Charges}>
                                Charges
                              </option>
                              <option value={DocumentTypeEnum.Mortgages}>
                                Mortgages
                              </option>
                              <option
                                value={DocumentTypeEnum.Appointment_of_Auditor}
                              >
                                Appointment of Auditor
                              </option>
                              <option
                                value={
                                  DocumentTypeEnum.Participations_in_other_entities
                                }
                              >
                                Participations in other entities
                              </option>
                              <option
                                value={DocumentTypeEnum.Indemnity_letters}
                              >
                                Indemnity letters
                              </option>
                              <option
                                value={DocumentTypeEnum.Letters_of_consent}
                              >
                                Letters of consent
                              </option>
                              <option
                                value={DocumentTypeEnum.Powers_of_attorneys}
                              >
                                Powers of attorneys
                              </option>
                              <option
                                value={DocumentTypeEnum.Resignation_letters}
                              >
                                Resignation letters
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row mb-4">
                          <div className="col-2">
                            <div className="font-weight-600">Attachment</div>
                            {/* <div className="font-11">Documents</div> */}
                            <div
                              className={`attachedDocumentDiv cursor-pointer`}
                            >
                              <label
                                htmlFor={'file-input'}
                                className="cursor-pointer"
                              >
                                <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                              </label>
                              <input
                                id="file-input"
                                type="file"
                                accept=".jpg,.jpeg,.png,.pdf,.docx"
                                onChangeCapture={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (e?.target?.files) {
                                    const selectedFile = e?.target?.files[0];

                                    // Perform file type validation
                                    if (selectedFile) {
                                      const allowedFileTypes = [
                                        '.jpg',
                                        '.jpeg',
                                        '.png',
                                        '.pdf',
                                        '.docx',
                                      ];
                                      const fileExtension =
                                        selectedFile.name.substring(
                                          selectedFile.name.lastIndexOf('.')
                                        );
                                      if (
                                        !allowedFileTypes.includes(
                                          fileExtension.toLowerCase()
                                        )
                                      ) {
                                        // File type not allowed
                                        dispatch(
                                          setToastError(
                                            'Only JPG, PNG, PDF and DOCX files are allowed.'
                                          )
                                        );
                                        e.target.value = ''; // Clear the selected file
                                        return;
                                      }

                                      // Perform further processing with the selected file
                                      setAttachment(e?.target?.files[0]);
                                      validation.setFieldValue(
                                        'attachment',
                                        e?.target?.files[0]
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-10 d-flex justify-content-start align-items-center">
                            {validation.values.attachment &&
                            validation.values.attachment?.name ? (
                              <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                  <ul className="d-flex ms-5 justify-content-center align-items-center">
                                    <li>
                                      {validation.values.attachment?.name}
                                    </li>
                                    <i
                                      className="bx bx-x bx-sm mx-3 cursor-pointer"
                                      onClick={e => {
                                        setAttachment('');
                                        validation.setFieldValue(
                                          'attachment',
                                          ''
                                        );
                                      }}
                                    ></i>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex pt-4 gap-5">
                    <div className="d-flex"></div>
                    <div className="d-flex"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getEventDocsByEventIDs?.eventDocs
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};
