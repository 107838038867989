import { createSlice } from '@reduxjs/toolkit';
import { UserGroupStoreType } from './types';

const initialState: UserGroupStoreType = {
  selectedUserGroupId: '',
};
const userGroupSlice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    setSelectedUserGroupId: (state, { payload }) => {
      state.selectedUserGroupId = payload;
    },

  },
});

export const { setSelectedUserGroupId } = userGroupSlice.actions;

export default userGroupSlice.reducer;
