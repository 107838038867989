import { useLazyQuery } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBankAccountViewScreen,
  setSelectedBankAccountId,
} from '~/Store/reducers/bankAccount';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './legalDoc.scss';
import {
  DesignationEnum,
  DocumentTypeEnum,
  LegalDocSearchParameterOptionsEnum,
  ModuleEnum,
  OfficerStatusEnum,
  SearchParameterOptionsEnum,
} from '~/commons/enums';
import {
  DELETE_DOCUMENTS,
  FIND_ONE_DOCUMENT,
  GET_DOCUMENTS,
} from '~/Store/graphQL/LegalDoc';
import GetModules from '~/helpers/Permissions/getModules';
import moment from 'moment';
import GetDocumentTypes from './documentType';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export const LegalDoc: FC = () => {
  let pageNo = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [moduleName, setmoduleName] = useState<any>();
  const [fileName, setFileName] = useState<any>();
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Bank Account',
        },
      ])
    );
  }, []);
  // Get all bank accounts query
  const [
    getDocuments,
    {
      loading: loadingGetDocuments,
      error: errorGetDocuments,
      data: dataGetDocuments,
    },
  ] = useLazyQuery<any>(GET_DOCUMENTS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteDocument,
    {
      loading: loadingDeleteDocument,
      error: errorDeleteDocument,
      data: dataDeleteDocument,
    },
  ] = useLazyQuery<any>(DELETE_DOCUMENTS, {
    fetchPolicy: 'no-cache',
  });

  const [
    findOneDocument,
    {
      loading: loadingFindOneDocument,
      error: errorFindOneDocument,
      data: dataFindOneDocument,
    },
  ] = useLazyQuery<any>(FIND_ONE_DOCUMENT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataDeleteDocument?.deleteDocument?.isDelete) {
      getDocuments({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
    } else if (errorGetDocuments) {
      dispatch(setToastError(errorGetDocuments));
    }
  }, [dataDeleteDocument, errorGetDocuments]);

  useEffect(() => {
    if (companyID) {
      getDocuments({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
    }
  }, [itemsPerPage]);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Legal Documents</b>
              </h5>
            </div>
          </div>
          <div className="d-flex  row mt-2 mb-4 ">
            {/* Second Row */}
            <div className="officerfields w-auto">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="searchKey">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option
                      value={LegalDocSearchParameterOptionsEnum.FILE_NAME}
                    >
                      File Name{' '}
                    </option>
                    <option
                      value={LegalDocSearchParameterOptionsEnum.DOCUMENT_TYPE}
                    >
                      {' '}
                      Document Type{' '}
                    </option>
                    <option
                      value={LegalDocSearchParameterOptionsEnum.MODULE_NAME}
                    >
                      {' '}
                      Module Name{' '}
                    </option>
                  </select>
                </div>
              </div>
              {validationSearch?.values?.searchKey ===
              LegalDocSearchParameterOptionsEnum.DOCUMENT_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={DocumentTypeEnum.Minutes_of_Meeting}>
                        {' '}
                        Minutes of Meeting{' '}
                      </option>
                      <option value={DocumentTypeEnum.Agreements}>
                        {' '}
                        Agreements{' '}
                      </option>
                      <option value={DocumentTypeEnum.Resolutions}>
                        {' '}
                        Resolutions{' '}
                      </option>
                      <option value={DocumentTypeEnum.Appointment_of_Auditor}>
                        {' '}
                        Appointment of Auditor{' '}
                      </option>
                      <option value={DocumentTypeEnum.Appointment_of_Chairman}>
                        {' '}
                        Appointment of Chairman{' '}
                      </option>
                      <option value={DocumentTypeEnum.Court_order}>
                        {' '}
                        Court order{' '}
                      </option>
                      <option value={DocumentTypeEnum.Charges}>
                        {' '}
                        Charges{' '}
                      </option>
                      <option value={DocumentTypeEnum.Mortgages}>
                        {' '}
                        Mortgages{' '}
                      </option>
                      <option
                        value={
                          DocumentTypeEnum.Participations_in_other_entities
                        }
                      >
                        {' '}
                        Participations in Other Entities{' '}
                      </option>
                      <option value={DocumentTypeEnum.Indemnity_letters}>
                        {' '}
                        Indemnity Letters{' '}
                      </option>
                      <option value={DocumentTypeEnum.Letters_of_engagement}>
                        {' '}
                        Letters of Engagement{' '}
                      </option>
                      <option value={DocumentTypeEnum.Letters_of_consent}>
                        {' '}
                        Letters of Consent{' '}
                      </option>
                      <option value={DocumentTypeEnum.Resignation_letters}>
                        {' '}
                        Resignation Letters{' '}
                      </option>
                      <option value={DocumentTypeEnum.Powers_of_attorneys}>
                        {' '}
                        Powers of Attorneys{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch?.values?.searchKey ===
                LegalDocSearchParameterOptionsEnum.MODULE_NAME ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={ModuleEnum.COMPANY_PROFILE}>
                        {' '}
                        Company Profile{' '}
                      </option>
                      <option value={ModuleEnum.COMPANY_OFFICERS}>
                        {' '}
                        Company Officer{' '}
                      </option>
                      <option value={ModuleEnum.COMPANY_MEMBERS}>
                        {' '}
                        Company Member{' '}
                      </option>
                      <option value={ModuleEnum.COMMITTEE}> Committee </option>
                      <option value={ModuleEnum.MEETING}> Meeting </option>
                      <option value={ModuleEnum.SHARE_MANAGEMENT}>
                        {' '}
                        Share{' '}
                      </option>
                      <option value={ModuleEnum.NOTIFICATION}>
                        {' '}
                        Notification{' '}
                      </option>
                      <option value={ModuleEnum.REGISTER}> Register </option>
                      <option value={ModuleEnum.LEGAL_DOCUMENTS}>
                        {' '}
                        Legal Document{' '}
                      </option>
                      <option value={ModuleEnum.FINANCIAL_YEAR}>
                        {' '}
                        Financial Year{' '}
                      </option>
                      <option value={ModuleEnum.BANK_ACCOUNTS}>
                        {' '}
                        Bank Account{' '}
                      </option>
                      <option value={ModuleEnum.SYSTEM_USER}>
                        {' '}
                        System User{' '}
                      </option>
                      <option value={ModuleEnum.SYSTEM_USER_GROUPS}>
                        {' '}
                        User Group{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    id="searchValue"
                    name="searchValue"
                    placeholder="Text"
                    className={`form-control ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}
              <div className="d-flex gap-3 align-self-center mt-2">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  onClick={() => {
                    if (validationSearch?.values?.searchValue) {
                      getDocuments({
                        variables: {
                          first: itemsPerPage,
                        },
                      });
                    }
                    validationSearch?.resetForm();
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center my-auto"
                  onClick={() => {
                    validationSearch.handleSubmit();
                    if (
                      validationSearch?.values?.searchValue &&
                      validationSearch.values.searchKey
                    ) {
                      getDocuments({
                        variables: {
                          first: itemsPerPage,
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-end w-auto gap-3 my-3 ms-auto me-0 ">
            <button
              type="button"
              className="btn btn-primary  fw-bold primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setBankAccountViewScreen(false));
                dispatch(setSelectedBankAccountId(null));
                navigate('/legalDoc/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Legal Document
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetDocuments ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Document Type</th>
                            <th>Date</th>
                            <th>Module</th>
                            <th>Uploaded by</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetDocuments?.getDocuments?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {pageNo.current * itemsPerPage +
                                      (index + 1)}
                                  </div>
                                </td>
                                <td>
                                  {val.node.fileName ? val.node.fileName : 'NA'}
                                </td>
                                <td>
                                  {val.node.documentType
                                    ? GetDocumentTypes(val.node.documentType)
                                    : 'NA'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {val.node.createdAt
                                      ? moment(val.node.createdAt).format(
                                          'YYYY-MM-DD'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.moduleName
                                    ? GetModules(val.node.moduleName)
                                    : 'N/A'}
                                </td>

                                <td>
                                  {val.node?.userData?.firstName &&
                                  val.node?.userData?.lastName
                                    ? val.node?.userData?.firstName +
                                      ' ' +
                                      val.node?.userData?.lastName
                                    : val.node?.userData?.firstName
                                    ? val.node?.userData?.firstName
                                    : val.node?.userData?.lastName
                                    ? val.node?.userData?.lastName
                                    : 'N/A'}
                                </td>

                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className="bx bx-show-alt text-primary"
                                      onClick={() => {
                                        findOneDocument({
                                          variables: {
                                            id: val.node.documentId,
                                          },
                                        })
                                          .then((val: any) => {
                                            if (
                                              val?.data?.findOneDocument
                                                ?.contentUrl
                                            ) {
                                              window.open(
                                                val.data.findOneDocument
                                                  .contentUrl,
                                                'new'
                                              );
                                            } else {
                                              dispatch(
                                                setToastError(
                                                  val.data.findOneDocument.error
                                                )
                                              );
                                            }
                                          })
                                          .catch(e => {
                                            dispatch(setToastError(e));
                                          });
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteDocument({
                                              variables: {
                                                id: val.node.documentId,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res?.data?.deleteDocument
                                                    ?.isDelete
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Document Deleted!'
                                                    )
                                                  );
                                                  getDocuments({
                                                    variables: {
                                                      first: itemsPerPage,
                                                      searchKey:
                                                        validationSearch?.values
                                                          ?.searchKey !== 'ALL'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchKey
                                                          : undefined,
                                                      searchValue:
                                                        validationSearch?.values
                                                          ?.searchValue !==
                                                        'Select'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchValue
                                                          : undefined,
                                                    },
                                                  });
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetDocuments?.getDocuments?.edges?.length === 0 ||
                        dataGetDocuments?.getDocuments?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetDocuments?.getDocuments?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto cursor-pointer'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetDocuments?.getDocuments?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getDocuments({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                last: itemsPerPage,
                                before:
                                  dataGetDocuments?.getDocuments?.pageInfo
                                    ?.startCursor,
                              },
                            });
                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetDocuments?.getDocuments?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm cursor-pointer'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetDocuments?.getDocuments?.pageInfo
                              ?.hasNextPage
                          ) {
                            getDocuments({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                first: itemsPerPage,
                                after:
                                  dataGetDocuments?.getDocuments?.pageInfo
                                    ?.endCursor,
                              },
                            });
                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
