import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch } from '~/Store/hooks';
import { setAgendaViewScreen } from '~/Store/reducers/agenda';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';

import { ModuleEnum } from '~/commons/enums';
import {
  setHelpSectionViewScreen,
  setSelectedHelpSectionId,
} from '~/Store/reducers/helpSection';
import {
  DELETE_HELP_SECTION,
  GET_ALL_HELP_SECTION,
} from '~/Store/graphQL/HelpSection';
import GetModules from '~/helpers/Permissions/getModules';

export const AdminHelpSection: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [helpSectionData, setHelpSectionData] = useState<any>();
  const [filters, setFilters] = useState<any>({
    moduleName: '',
  });
  let pageNo = useRef(0);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Help Section',
        },
      ])
    );
  }, []);
  // Get all help sections query
  const [
    getAllHelpSections,
    {
      loading: loadingGetAllHelpSections,
      error: errorGetAllHelpSections,
      data: dataGetAllHelpSections,
    },
  ] = useLazyQuery<any>(GET_ALL_HELP_SECTION, {
    fetchPolicy: 'no-cache',
  });

  // Delete help section of a module
  const [
    deleteHelpSection,
    {
      loading: loadingDeleteHelpSection,
      error: errorDeleteHelpSection,
      data: dataDeleteHelpSection,
    },
  ] = useMutation<any>(DELETE_HELP_SECTION);

  useEffect(() => {
    getAllHelpSections({
      variables: {
        first: itemsPerPage,
      },
    });
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllHelpSections) {
      dispatch(setToastError(errorGetAllHelpSections));
    }
    if (dataGetAllHelpSections) {
      setHelpSectionData(dataGetAllHelpSections);
    }
  }, [errorGetAllHelpSections, dataGetAllHelpSections]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="module">Module</label>
                </div>
                <div className="field">
                  <select
                    id="module"
                    name="module"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.moduleName}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.moduleName = value;
                      setFilters(temp);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={ModuleEnum.COMPANY_PROFILE}>
                      Company Profile
                    </option>
                    <option value={ModuleEnum.COMPANY_OFFICERS}>
                      Company Officers
                    </option>
                    <option value={ModuleEnum.COMPANY_MEMBERS}>
                      Company Members
                    </option>
                    <option value={ModuleEnum.REGISTER}>Registers</option>
                    <option value={ModuleEnum.NOTIFICATION}>
                      Notifications
                    </option>
                    <option value={ModuleEnum.MEETING}>
                      Meeting Management
                    </option>
                    <option value={ModuleEnum.COMMITTEE}>
                      Committee Management
                    </option>
                    <option value={ModuleEnum.SHARE_MANAGEMENT}>
                      Company Shares Management
                    </option>
                    <option value={ModuleEnum.LEGAL_DOCUMENTS}>
                      Legal Documents Management
                    </option>
                    <option value={ModuleEnum.BANK_ACCOUNTS}>
                      Bank Account Management
                    </option>
                    <option value={ModuleEnum.FINANCIAL_YEAR}>
                      Financial Year Management
                    </option>
                    <option value={ModuleEnum.SYSTEM_USER}>
                      System Users Management
                    </option>
                    <option value={ModuleEnum.SYSTEM_USER_GROUPS}>
                      User Groups Management
                    </option>
                  </select>
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.moduleName) {
                      getAllHelpSections({
                        variables: {
                          first: itemsPerPage,
                          moduleName: undefined,
                        },
                      });
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.moduleName = '';
                      setFilters(temp);
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  disabled={loadingGetAllHelpSections}
                  onClick={() => {
                    if (filters.moduleName) {
                      getAllHelpSections({
                        variables: {
                          first: itemsPerPage,
                          moduleName: filters?.moduleName
                            ? filters?.moduleName
                            : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setSelectedHelpSectionId(null));
                dispatch(setHelpSectionViewScreen(false));
                navigate('/admin/help/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Help
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllHelpSections ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th>S.No</th>
                            <th>Module Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {helpSectionData?.getAllHelpSections?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  {pageNo.current * itemsPerPage + (index + 1)}
                                </td>
                                <td>
                                  {val.node.moduleName
                                    ? GetModules(val?.node?.moduleName)
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(
                                          setSelectedHelpSectionId(val.node.id)
                                        );
                                        dispatch(
                                          setHelpSectionViewScreen(false)
                                        );
                                        navigate('/admin/help/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(setAgendaViewScreen(false));
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteHelpSection({
                                              variables: {
                                                id: val.node.id,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data.deleteHelpSection
                                                    .count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Help Deleted!'
                                                    )
                                                  );
                                                  getAllHelpSections({
                                                    variables: {
                                                      first: itemsPerPage,
                                                      before:
                                                        dataGetAllHelpSections
                                                          ?.getAllHelpSections
                                                          ?.pageInfo
                                                          ?.startCursor,
                                                    },
                                                  });
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllHelpSections?.getAllHelpSections?.edges
                        ?.length === 0 ||
                        dataGetAllHelpSections?.getAllHelpSections?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllHelpSections?.getAllHelpSections?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllHelpSections?.getAllHelpSections?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllHelpSections({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllHelpSections?.getAllHelpSections
                                    ?.pageInfo?.startCursor,
                              },
                            });
                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllHelpSections?.getAllHelpSections?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllHelpSections?.getAllHelpSections?.pageInfo
                              ?.hasNextPage
                          ) {
                            getAllHelpSections({
                              variables: {
                                after:
                                  dataGetAllHelpSections?.getAllHelpSections
                                    ?.pageInfo?.endCursor,
                              },
                            });
                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
