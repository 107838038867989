import React, { FC, useEffect, useRef, useState } from 'react';
import './companyofficer.scss';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useNavigate } from 'react-router-dom';
import {
  CREATE_COMPANY_OFFICER_DRAFT,
  CREATE_COMPANY_OFFICER_FROM_EXCEL_FILE,
  CREATE_POWER_OF_ATTORNEY_LETTER,
  CREATE_RESIGNATION_LETTER,
  DELETE_COMPANY_OFFICER,
  DELETE_FILE_FROM_OBS,
  DRAFT_DELETE_COMPANY_OFFICER,
  EXPORT_COMPANY_OFFICER_DATA_IN_EXCEL_FILE,
  GET_ALL_COMPANY_OFFICERS,
} from '~/Store/graphQL/CompanyOfficer';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { ConfirmationDialogue } from '~/components/Toasts';
import {
  setSelectedOfficerId,
  setOfficerViewScreen,
} from '~/Store/reducers/companyOfficer';
import * as XLSX from 'xlsx';
import { CompanyOfficerInputDto } from './types';
import GetCompanyOfficerDesignation from '../../../helpers/CompanyOfficer/getCompanyOfficerDesignation';
import GetCompanyOfficerStatus from '../../../helpers/CompanyOfficer/getCompanyOfficerStatus';
import {
  DesignationEnum,
  ModuleEnum,
  OfficerStatusEnum,
  PermissionCategoryEnum,
  SearchParameterOptionsEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { Container, Modal, Tooltip } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
export const CompanyOfficer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [actualOfficers, setActualOfficers] = useState<boolean>(true);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchName, setSearchName] = useState<string>('');
  const [companyOfficers, setCompanyOfficers] = useState<
    CompanyOfficerInputDto[] | undefined
  >();
  // const [filters, setFilters] = useState<any>({
  //   searchKey: '',
  //   searchValue: '',
  // });
  const [dropDownRow, setDropDownRow] = useState(-1);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [authorizedFromName, setAuthorizedFromName] = useState('');
  const [authorizedFromAddress, setAuthorizedFromAddress] = useState('');
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  const [importToolTipIsOpen, setImportToolTipIsOpen] = useState(false);
  const [exportToolTipIsOpen, setExportToolTipIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  function ExcelDateToJSDate(serial: any) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
  }
  useEffect(() => {
    if (companyOfficers) {
      createCompanyOfficerExcelFile({
        variables: {
          companyId: companyID,
          companyOfficer: companyOfficers,
        },
      })
        .then((res: any) => {
          if (res.data.importCompanyOfficers.isSuccessfull) {
            dispatch(
              setToastSuccess('Company Officers Added From Excel File!')
            );
            getCompanyOfficers({
              variables: {
                first: itemsPerPage,
                searchKey:
                  validationSearch.values?.searchKey !== 'ALL'
                    ? validationSearch.values?.searchKey
                    : undefined,
                searchValue:
                  validationSearch.values?.searchValue !== 'Select'
                    ? validationSearch.values?.searchValue
                    : undefined,
              },
            });
          } else {
            dispatch(
              setToastError(
                'Some Error occured while adding company officers from excel file!'
              )
            );
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    }
  }, [companyOfficers]);
  useEffect(() => {
    //breadcrumb
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Company Officers',
        },
      ])
    );
  }, []);
  const [
    createDraftCompanyOfficer,
    {
      loading: loadingCreateDraftCompanyOfficer,
      error: errorCreateDraftCompanyOfficer,
      data: dataCreateDraftCompanyOfficer,
    },
  ] = useMutation<any>(CREATE_COMPANY_OFFICER_DRAFT);
  const [
    deleteFileFromOBS,
    {
      loading: loadingDeleteFileFromOBS,
      error: errorDeleteFileFromOBS,
      data: dataDeleteFileFromOBS,
    },
  ] = useMutation<any>(DELETE_FILE_FROM_OBS);

  //delete company officer
  const [
    deleteCompanyOfficer,
    {
      loading: loadingDeleteCompanyOfficer,
      error: errorDeleteCompanyOfficer,
      data: dataDeleteCompanyOfficer,
    },
  ] = useMutation<any>(DELETE_COMPANY_OFFICER);

  const [
    draftDeleteCompanyOfficer,
    {
      loading: loadingDraftDeleteCompanyOfficer,
      error: errorDraftDeleteCompanyOfficer,
      data: dataDraftDeleteCompanyOfficer,
    },
  ] = useMutation<any>(DRAFT_DELETE_COMPANY_OFFICER);
  const [
    createCompanyOfficerExcelFile,
    {
      loading: loadingCreateCompanyOfficerExcelFile,
      error: errorCreateCompanyOfficerExcelFile,
      data: dataCreateCompanyOfficerExcelFile,
    },
  ] = useMutation<any>(CREATE_COMPANY_OFFICER_FROM_EXCEL_FILE);

  // get all company officers
  const [
    getCompanyOfficers,
    {
      loading: loadingGetAllCompanyOfficer,
      error: errorGetAllCompanyOfficer,
      data: dataGetAllCompanyOfficer,
    },
  ] = useLazyQuery<any>(GET_ALL_COMPANY_OFFICERS, {
    variables: {
      companyId: companyID,
      // first: itemsPerPage,
      // name: searchName ? searchName : undefined,
      // status: [OfficerStatusEnum.APPOINTED, OfficerStatusEnum.REELECTED],
    },
    fetchPolicy: 'no-cache',
  });
  //Export data of company officers
  const [
    exportDataOfCompanyOfficers,
    {
      loading: loadingExportDataOfCompanyOfficers,
      error: errorExportDataOfCompanyOfficers,
      data: dataExportDataOfCompanyOfficers,
    },
  ] = useLazyQuery<any>(EXPORT_COMPANY_OFFICER_DATA_IN_EXCEL_FILE, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // generate resignation letter of company officers
  const [
    generateResignationLetter,
    {
      loading: loadingGenerateResignationLetter,
      error: errorGenerateResignationLetter,
      data: dataGenerateResignationLetter,
    },
  ] = useLazyQuery<any>(CREATE_RESIGNATION_LETTER, {
    fetchPolicy: 'no-cache',
  });
  // generate power of attorney letter of company officers
  const [
    generatePowerOfAttorneyLetter,
    {
      loading: loadingGeneratePowerOfAttorneyLetter,
      error: errorGeneratePowerOfAttorneyLetter,
      data: dataGeneratePowerOfAttorneyLetter,
    },
  ] = useMutation<any>(CREATE_POWER_OF_ATTORNEY_LETTER);
  useEffect(() => {
    if (companyID) {
      getCompanyOfficers({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch.values?.searchKey !== 'ALL'
              ? validationSearch.values?.searchKey
              : undefined,
          searchValue:
            validationSearch.values?.searchValue !== 'Select'
              ? validationSearch.values?.searchValue
              : undefined,
          status: [OfficerStatusEnum.APPOINTED, OfficerStatusEnum.REELECTED],
        },
      });
    }
  }, [itemsPerPage]);

  useEffect(() => {
    if (errorGetAllCompanyOfficer) {
      dispatch(setToastError(errorGetAllCompanyOfficer));
    }
  }, [errorGetAllCompanyOfficer]);

  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    var FR = new FileReader();
    FR.onload = function (e: any) {
      var workbook = XLSX.readFile(e.target.result, {
        dateNF: 'mm"/"dd"/"yyyy',
      });
      var sheet_name_list = workbook.SheetNames;
      var xlData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[0]]
      );
      let tempCompanyOfficers: CompanyOfficerInputDto[] = [];
      xlData = xlData.filter(
        (value: any, index, self) =>
          index === self.findIndex((t: any) => t.CNIC === value.CNIC)
      );
      xlData.forEach((element: any) => {
        let companyOfficer: CompanyOfficerInputDto = {};
        companyOfficer.firstName = element.First_Name;
        companyOfficer.middleName = element.Middle_Name;
        companyOfficer.lastName = element.Last_Name;
        companyOfficer.status = element.Status;
        companyOfficer.designation = element.Designation;
        companyOfficer.relativeFirstName = element.Relative_First_Name;
        companyOfficer.relativeMiddleName = element.Relative_Middle_Name;
        companyOfficer.relativeLastName = element.Relative_Last_Name;
        companyOfficer.relationship = element.Relationship;
        companyOfficer.nationality = element.Nationality;
        companyOfficer.cnic = element.CNIC;
        companyOfficer.passportNumber = element.Passport_Number;
        companyOfficer.dateOfBirth = ExcelDateToJSDate(
          element.Date_Of_Birth
        ).toString();
        companyOfficer.gender = element.Gender;
        companyOfficer.residentialAddress = element.Residential_Address;
        companyOfficer.otherOccupation = element.Other_Occupation;
        companyOfficer.noc = element.Noc;
        companyOfficer.city = element.City;
        companyOfficer.province = element.Province;
        companyOfficer.country = element.Country;
        companyOfficer.postalCode = element.Postal_Code;
        companyOfficer.telephone = element.Telephone;
        companyOfficer.ntn = element.NTN;
        companyOfficer.date = ExcelDateToJSDate(element.Date).toString();
        companyOfficer.renumeration = element.Renumiration;
        companyOfficer.cessationReason = element.Cessation_Reason;
        companyOfficer.isActive = element.IsActive;
        tempCompanyOfficers?.push(companyOfficer);
      });
      setCompanyOfficers(tempCompanyOfficers);
    };
    if (e?.target?.files) {
      FR.readAsArrayBuffer(e?.target?.files[0]);
    }
  };
  const toggleDropDownModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleModal = () => {
    setModalVisibility(!modalVisibility);
  };
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      authorizedFromName: authorizedFromName,
      authorizedFromAddress: authorizedFromAddress,
      authorizedToName: '',
      authorizedToAddress: '',
      sentToName: '',
      sentToAddress: '',
    },
    validationSchema: Yup.object({
      authorizedFromName: Yup.string()
        .required('Please enter authorized from name')
        .nullable(),
      authorizedFromAddress: Yup.string()
        .required('Please enter authorized from address')
        .nullable(),
      authorizedToName: Yup.string()
        .required('Please enter authorized to name')
        .nullable(),
      authorizedToAddress: Yup.string()
        .required('Please enter authorized to address')
        .nullable(),
      sentToName: Yup.string().required('Please enter sent to name').nullable(),
      sentToAddress: Yup.string()
        .required('Please enter sent to address')
        .nullable(),
    }),

    onSubmit: values => {
      generatePowerOfAttorneyLetter({
        variables: {
          sentTo: values.sentToName,
          sentToAddress: values.sentToAddress,
          auhtorizedToName: values.authorizedToName,
          authorizedtoAddress: values.authorizedToAddress,
          auhtorizedFromName: values.authorizedFromName,
          authorizedFromAddress: values.authorizedFromAddress,
          companyId: companyID,
        },
      })
        .then((res: any) => {
          if (res?.data?.createLetterOfAttorney?.isGenerated) {
            window.open(res?.data?.createLetterOfAttorney?.url, 'new');
            setModalVisibility(false);
            setDropDownVisibility(false);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });

  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef?.current?.toString() !== event?.target?.toString()
      ) {
        setDropDownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <React.Fragment>
      <div id="mainComponent" className="mt-3">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Designated Officers</b>
              </h5>
            </div>
          </div>
          <div className="d-flex row mt-2 mb-4">
            <div className="officerfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="searchKey">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={SearchParameterOptionsEnum.NAME}>
                      {' '}
                      Name{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.DESIGNATION}>
                      {' '}
                      Designation{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.STATUS}>
                      {' '}
                      Status{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.NATIONALITY}>
                      {' '}
                      Nationality{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.CITY}>
                      {' '}
                      City{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.TELEPHONE}>
                      {' '}
                      Telephone{' '}
                    </option>
                    <option value={SearchParameterOptionsEnum.POSTAL_CODE}>
                      {' '}
                      Postal Code{' '}
                    </option>
                  </select>
                </div>
              </div>
              {validationSearch.values.searchKey ===
              SearchParameterOptionsEnum.DESIGNATION ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={DesignationEnum.DIRECTOR}>
                        {' '}
                        Director{' '}
                      </option>
                      <option value={DesignationEnum.DIRECTOR_AND_SUBSCRIBER}>
                        {' '}
                        Director and Subscriber{' '}
                      </option>
                      <option value={DesignationEnum.SPONSOR}> Sponser </option>
                      <option value={DesignationEnum.DECLARANT}>
                        {' '}
                        Declarant{' '}
                      </option>
                      <option value={DesignationEnum.ADVISOR_CONSULTANT}>
                        {' '}
                        Advisor consultant{' '}
                      </option>
                      <option value={DesignationEnum.NOMINEE_DIRECTOR}>
                        {' '}
                        Nominee Director{' '}
                      </option>
                      <option value={DesignationEnum.LEGAL_ADVISOR}>
                        {' '}
                        Legal Advisor{' '}
                      </option>
                      <option value={DesignationEnum.AUDITOR}> Auditor </option>
                      <option value={DesignationEnum.CHIEF_ACCOUNTANT}>
                        {' '}
                        Chief Accountant{' '}
                      </option>
                      <option value={DesignationEnum.CHAIRMAN}>
                        {' '}
                        Chairman{' '}
                      </option>
                      <option value={DesignationEnum.COMPANY_SECRETARY}>
                        {' '}
                        Company Secretary{' '}
                      </option>
                      <option value={DesignationEnum.CHIEF_EXECUTIVE}>
                        {' '}
                        Chief Executive{' '}
                      </option>
                      <option value={DesignationEnum.MANAGING_AGENT}>
                        {' '}
                        Managing Agent{' '}
                      </option>
                      <option value={DesignationEnum.SALE_AGENT}>
                        {' '}
                        Sale Agent{' '}
                      </option>
                      <option value={DesignationEnum.FINANCIAL_OFFICER}>
                        {' '}
                        Financial Officer{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch.values.searchKey ===
                SearchParameterOptionsEnum.STATUS ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={'ALL'}> Show All </option>
                      <option value={OfficerStatusEnum.APPOINTED}>
                        {' '}
                        Appointed{' '}
                      </option>
                      <option value={OfficerStatusEnum.RESIGNED}>
                        {' '}
                        Resigned{' '}
                      </option>
                      <option value={OfficerStatusEnum.ELECTED}>
                        {' '}
                        Elected{' '}
                      </option>
                      <option value={OfficerStatusEnum.REELECTED}>
                        {' '}
                        Re-elected{' '}
                      </option>
                      <option value={OfficerStatusEnum.RETIRED}>
                        {' '}
                        Retired{' '}
                      </option>
                      <option value={OfficerStatusEnum.CEASED}> Ceased </option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control  ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    id="searchValue"
                    name="searchValue"
                    placeholder="Text"
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}

              <div className="d-flex gap-3 align-self-center mt-2">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  onClick={() => {
                    if (
                      validationSearch?.values?.searchValue &&
                      validationSearch?.values?.searchKey
                    ) {
                      getCompanyOfficers({
                        variables: {
                          first: itemsPerPage,
                          status: [
                            OfficerStatusEnum.APPOINTED,
                            OfficerStatusEnum.REELECTED,
                          ],
                        },
                      });
                      validationSearch.resetForm();
                    } else {
                      validationSearch.resetForm();
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center my-auto"
                  onClick={() => {
                    validationSearch.handleSubmit();
                    if (
                      validationSearch.values.searchValue &&
                      validationSearch.values.searchKey
                    ) {
                      if (
                        validationSearch.values.searchKey !=
                        SearchParameterOptionsEnum.STATUS
                      ) {
                        getCompanyOfficers({
                          variables: {
                            first: itemsPerPage,
                            searchKey:
                              validationSearch.values?.searchKey !== 'ALL'
                                ? validationSearch.values?.searchKey
                                : undefined,
                            searchValue:
                              validationSearch.values?.searchValue !== 'Select'
                                ? validationSearch.values?.searchValue
                                : undefined,
                            status: [
                              OfficerStatusEnum.APPOINTED,
                              OfficerStatusEnum.REELECTED,
                            ],
                          },
                        });
                      } else {
                        if (validationSearch.values.searchValue === 'ALL') {
                          getCompanyOfficers({
                            variables: {
                              first: itemsPerPage,
                            },
                          });
                        } else {
                          getCompanyOfficers({
                            variables: {
                              first: itemsPerPage,
                              status: validationSearch.values.searchValue,
                            },
                          });
                        }
                      }
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <div className="d-flex gap-3 my-3">
              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_OFFICERS,
                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setExportToolTipIsOpen(true)}
                onMouseLeave={() => setExportToolTipIsOpen(false)}
              >
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center ms-auto"
                  id="exportButton"
                  disabled={
                    loadingExportDataOfCompanyOfficers ||
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_OFFICERS,
                        PermissionCategoryEnum.GENERATE_DOCUMENTS
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                  }
                  onClick={() => {
                    exportDataOfCompanyOfficers()
                      .then((res: any) => {
                        if (res.data.exportCompanyOfficers.isSuccessful) {
                          window.open(
                            res.data.exportCompanyOfficers.url,
                            'new'
                          );
                          setTimeout(function () {
                            deleteFileFromOBS({
                              variables: {
                                fileName:
                                  res.data.exportCompanyOfficers.fileName,
                              },
                            });
                          }, 5000);
                        } else {
                          dispatch(
                            setToastError(
                              'Some error occured while exporting data in excel file!'
                            )
                          );
                        }
                      })
                      .catch(err => {
                        dispatch(setToastError(err));
                      });
                  }}
                >
                  Export
                </button>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_OFFICERS,
                    PermissionCategoryEnum.GENERATE_DOCUMENTS
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={exportToolTipIsOpen}
                    target="exportButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>

              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_OFFICERS,
                      PermissionCategoryEnum.EDIT
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setImportToolTipIsOpen(true)}
                onMouseLeave={() => setImportToolTipIsOpen(false)}
              >
                <label
                  className={`btn primaryDismissButton d-flex align-items-center ${
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_OFFICERS,
                        PermissionCategoryEnum.EDIT
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                      ? 'icon-disabled'
                      : ''
                  }`}
                  id="importButton"
                >
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChangeCapture={onFileChangeCapture}
                  />
                  Import
                </label>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_OFFICERS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={importToolTipIsOpen}
                    target="importButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>

              <div
                className={`${
                  !(
                    GetPermissionsOfUser(
                      selectedUserPermissions,
                      ModuleEnum.COMPANY_OFFICERS,
                      PermissionCategoryEnum.EDIT
                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                  )
                    ? 'cursor-not-allowed'
                    : ''
                }`}
                onMouseEnter={() => setAddToolTipIsOpen(true)}
                onMouseLeave={() => setAddToolTipIsOpen(false)}
              >
                <button
                  type="button"
                  className="btn fw-bold primaryButton d-flex align-items-center"
                  id="addButton"
                  disabled={
                    !(
                      GetPermissionsOfUser(
                        selectedUserPermissions,
                        ModuleEnum.COMPANY_OFFICERS,
                        PermissionCategoryEnum.EDIT
                      ) || userType === UserTypeEnum.COMPANY_ADMIN
                    )
                  }
                  onClick={() => {
                    dispatch(setOfficerViewScreen(false));
                    createDraftCompanyOfficer({
                      variables: {
                        companyId: companyID,
                        files: [],
                      },
                    })
                      .then((res: any) => {
                        if (
                          res.data.draft_createCompanyOfficerDraft
                            .draftCompanyOfficer
                        ) {
                          dispatch(
                            setSelectedOfficerId(
                              res.data.draft_createCompanyOfficerDraft
                                .draftCompanyOfficer.id
                            )
                          );
                          navigate('/companyOfficer/personalInformation');
                        }
                      })
                      .catch(err => {
                        dispatch(setToastError(err));
                      });
                  }}
                >
                  <i className="bx bx-plus"></i>
                  Add
                </button>
                {!(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.COMPANY_OFFICERS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                ) ? (
                  <Tooltip
                    placement="top"
                    isOpen={addToolTipIsOpen}
                    target="addButton"
                  >
                    You don't have permission for this module
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <ul className="nav nav-tabs mb-3">
                <li
                  className={`nav-item ${actualOfficers ? 'active' : ''}`}
                  onClick={() => {
                    setActualOfficers(true);
                  }}
                >
                  <p className="nav-link">Saved</p>
                </li>
                <li
                  className={`nav-item ${!actualOfficers ? 'active' : ''}`}
                  onClick={() => {
                    setActualOfficers(false);
                  }}
                >
                  <p className="nav-link">Draft</p>
                </li>
              </ul>
              {actualOfficers ? (
                <>
                  {loadingGetAllCompanyOfficer ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-75">
                      <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              {/* <th>S.No</th> */}
                              <th>Name</th>
                              <th>Designation</th>
                              <th>Officer Status</th>
                              <th>DOB</th>
                              <th>CNIC</th>
                              <th>Nationality</th>
                              <th>City</th>
                              <th>Telephone</th>
                              <th>Postal Code</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {dataGetAllCompanyOfficer?.getAllCompanyOfficers?.companyOfficerConnection?.edges?.map(
                              (val: any, index: number) => (
                                <tr key={`${index}`}>
                                  {/* <td>{index + 1}</td> */}
                                  <td>
                                    {val.node.middleName
                                      ? val.node.firstName +
                                        ' ' +
                                        val.node.middleName +
                                        ' ' +
                                        val.node.lastName
                                      : val.node.firstName +
                                        ' ' +
                                        val.node.lastName}
                                  </td>
                                  <td>
                                    {GetCompanyOfficerDesignation(
                                      val.node.designation
                                    )
                                      ? GetCompanyOfficerDesignation(
                                          val.node.designation
                                        )
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {GetCompanyOfficerStatus(val.node.status)
                                      ? GetCompanyOfficerStatus(val.node.status)
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    <div className="badge custom-badge-success">
                                      {moment(val.node.dateOfBirth).format(
                                        'MMM DD, yyyy'
                                      )}
                                    </div>
                                  </td>
                                  <td>{val.node.cnic}</td>
                                  <td>{val.node.nationality}</td>
                                  <td>{val.node.city}</td>
                                  <td>{val.node.telephone}</td>
                                  <td>
                                    <div className="badge custom-badge-primary">
                                      {val.node.postalCode}
                                    </div>
                                  </td>
                                  <td className="editColumn">
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_OFFICERS,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_OFFICERS,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedOfficerId(val.node.id)
                                          );
                                          dispatch(setOfficerViewScreen(true));
                                          navigate(
                                            `/companyOfficer/personalInformation`
                                          );
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMPANY_OFFICERS,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_OFFICERS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-edit text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_OFFICERS,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(setOfficerViewScreen(false));
                                          dispatch(
                                            setSelectedOfficerId(val.node.id)
                                          );
                                          navigate(
                                            `/companyOfficer/personalInformation`
                                          );
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMPANY_OFFICERS,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_OFFICERS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_OFFICERS,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(setOfficerViewScreen(false));
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteCompanyOfficer({
                                                variables: {
                                                  id: val.node.id,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      .deleteCompanyOfficer
                                                      .count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Company Officer Deleted!'
                                                      )
                                                    );
                                                    getCompanyOfficers({
                                                      variables: {
                                                        first: itemsPerPage,
                                                        searchKey:
                                                          validationSearch
                                                            .values
                                                            ?.searchKey !==
                                                          'ALL'
                                                            ? validationSearch
                                                                .values
                                                                ?.searchKey
                                                            : undefined,
                                                        searchValue:
                                                          validationSearch
                                                            .values
                                                            ?.searchValue !==
                                                          'Select'
                                                            ? validationSearch
                                                                .values
                                                                ?.searchValue
                                                            : undefined,
                                                        status: [
                                                          OfficerStatusEnum.APPOINTED,
                                                          OfficerStatusEnum.REELECTED,
                                                        ],
                                                      },
                                                    });
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMPANY_OFFICERS,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    {GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.COMPANY_OFFICERS,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) ||
                                    userType === UserTypeEnum.COMPANY_ADMIN ? (
                                      <div className="dropleft">
                                        <i
                                          id="dropdownMenuButton"
                                          ref={dropdownRef}
                                          className="bx bx-dots-vertical-rounded"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          onClick={() => {
                                            setDropDownRow(index);
                                            toggleDropDownModal();
                                          }}
                                        ></i>
                                        <ul
                                          className={`dropdown-menu min-width-8 dropDownMargin ${
                                            dropDownVisibility &&
                                            index === dropDownRow &&
                                            'show'
                                          } `}
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li
                                            className="cursor-pointer"
                                            aria-disabled={
                                              loadingGenerateResignationLetter
                                            }
                                          >
                                            <a
                                              aria-disabled={
                                                loadingGenerateResignationLetter
                                              }
                                              className="dropdown-item"
                                              onClick={e => {
                                                generateResignationLetter({
                                                  variables: {
                                                    id: val.node.id,
                                                  },
                                                })
                                                  .then((res: any) => {
                                                    if (
                                                      res?.data
                                                        ?.createResignationLetter
                                                        ?.isGenerated
                                                    ) {
                                                      window.open(
                                                        res?.data
                                                          ?.createResignationLetter
                                                          ?.url,
                                                        'new'
                                                      );
                                                      setDropDownVisibility(
                                                        false
                                                      );
                                                    }
                                                  })
                                                  .catch(err => {
                                                    setDropDownVisibility(
                                                      false
                                                    );
                                                    dispatch(
                                                      setToastError(err)
                                                    );
                                                  });
                                              }}
                                            >
                                              Generate Resignation Letter
                                            </a>
                                          </li>
                                          <li className="cursor-pointer">
                                            <div
                                              className="dropdown-item"
                                              onClick={() => {
                                                setAuthorizedFromName(
                                                  val?.node?.middleName
                                                    ? val?.node?.firstName +
                                                        ' ' +
                                                        val?.node?.middleName +
                                                        ' ' +
                                                        val?.node?.lastName
                                                    : val?.node?.firstName +
                                                        ' ' +
                                                        val?.node?.lastName
                                                );
                                                setAuthorizedFromAddress(
                                                  val?.node?.residentialAddress
                                                );
                                                setModalVisibility(true);
                                                setDropDownVisibility(false);
                                              }}
                                            >
                                              Generate Power of Attorney
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : null}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        {(dataGetAllCompanyOfficer?.getAllCompanyOfficers
                          ?.companyOfficerConnection?.edges?.length === 0 ||
                          dataGetAllCompanyOfficer?.getAllCompanyOfficers
                            ?.companyOfficerConnection?.edges ===
                            undefined) && (
                          <div className="addDiv">
                            <h6 className="">No records Found</h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                        <p className="m-0 text-info">Rows Per Page:</p>
                        <select
                          className="form-control button-danger text-info width-5vw"
                          value={itemsPerPage}
                          onChange={e => {
                            const { value } = e.target;
                            setItemsPerPage(Number(value));
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={30}>30</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <i
                          className={
                            dataGetAllCompanyOfficer?.getAllCompanyOfficers
                              ?.companyOfficerConnection?.pageInfo
                              ?.hasPreviousPage
                              ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                              : 'bx bx-caret-left bx-sm ms-auto'
                          }
                          onClick={() => {
                            if (
                              companyID &&
                              dataGetAllCompanyOfficer?.getAllCompanyOfficers
                                ?.companyOfficerConnection?.pageInfo
                                ?.hasPreviousPage
                            ) {
                              getCompanyOfficers({
                                variables: {
                                  searchKey:
                                    validationSearch.values?.searchKey !== 'ALL'
                                      ? validationSearch.values?.searchKey
                                      : undefined,
                                  searchValue:
                                    validationSearch.values?.searchValue !==
                                    'Select'
                                      ? validationSearch.values?.searchValue
                                      : undefined,
                                  last: itemsPerPage,
                                  before:
                                    dataGetAllCompanyOfficer
                                      ?.getAllCompanyOfficers
                                      ?.companyOfficerConnection?.pageInfo
                                      ?.startCursor,
                                  status: [
                                    OfficerStatusEnum.APPOINTED,
                                    OfficerStatusEnum.REELECTED,
                                  ],
                                },
                              });
                            }
                          }}
                        />

                        <i
                          className={
                            dataGetAllCompanyOfficer?.getAllCompanyOfficers
                              ?.companyOfficerConnection?.pageInfo?.hasNextPage
                              ? 'bx bx-caret-right bx-sm color-primary '
                              : 'bx bx-caret-right bx-sm'
                          }
                          onClick={() => {
                            if (
                              companyID &&
                              dataGetAllCompanyOfficer?.getAllCompanyOfficers
                                ?.companyOfficerConnection?.pageInfo
                                ?.hasNextPage
                            ) {
                              getCompanyOfficers({
                                variables: {
                                  searchKey:
                                    validationSearch.values?.searchKey !== 'ALL'
                                      ? validationSearch.values?.searchKey
                                      : undefined,
                                  searchValue:
                                    validationSearch.values?.searchValue !==
                                    'Select'
                                      ? validationSearch.values?.searchValue
                                      : undefined,
                                  after:
                                    dataGetAllCompanyOfficer
                                      ?.getAllCompanyOfficers
                                      ?.companyOfficerConnection?.pageInfo
                                      ?.endCursor,
                                  status: [
                                    OfficerStatusEnum.APPOINTED,
                                    OfficerStatusEnum.REELECTED,
                                  ],
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {loadingGetAllCompanyOfficer ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-75">
                      <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              {/* <th>S.No</th> */}
                              <th>Name</th>
                              <th>Designation</th>
                              <th>Officer Status</th>
                              <th>DOB</th>
                              <th>CNIC</th>
                              <th>Nationality</th>
                              <th>City</th>
                              <th>Telephone</th>
                              <th>Postal Code</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataGetAllCompanyOfficer?.getAllCompanyOfficers?.draftCompanyOfficerConnection?.edges?.map(
                              (val: any, index: number) => (
                                <tr key={`${index}`}>
                                  {/* <td>{index + 1}</td> */}
                                  <td>
                                    {val.node.lastName && val.node.firstName
                                      ? val.node.firstName +
                                        ' ' +
                                        val.node.lastName
                                      : val.node.firstName
                                      ? val.node.firstName
                                      : val.node.lastName
                                      ? val.node.lastName
                                      : 'N/A'}
                                    {/* {val.node.firstName || val.node.lastName
                                      ? val.node.firstName +
                                        ' ' +
                                        val.node.lastName
                                      : 'N/A'} */}
                                  </td>
                                  <td>
                                    {GetCompanyOfficerDesignation(
                                      val.node.designation
                                    )
                                      ? GetCompanyOfficerDesignation(
                                          val.node.designation
                                        )
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {GetCompanyOfficerStatus(val.node.status)
                                      ? GetCompanyOfficerStatus(val.node.status)
                                      : 'N/A'}{' '}
                                  </td>
                                  <td>
                                    {val.node.dateOfBirth
                                      ? moment(val.node.dateOfBirth).format(
                                          'MMM DD, yyyy'
                                        )
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {val.node.cnic ? val.node.cnic : 'N/A'}
                                  </td>
                                  <td>
                                    {val.node.nationality
                                      ? val.node.nationality
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {val.node.city ? val.node.city : 'N/A'}
                                  </td>
                                  <td>
                                    {val.node.telephone
                                      ? val.node.telephone
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {val.node.postalCode
                                      ? val.node.postalCode
                                      : 'N/A'}
                                  </td>
                                  <td className="editColumn">
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_OFFICERS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-edit text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_OFFICERS,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(setOfficerViewScreen(false));
                                          dispatch(
                                            setSelectedOfficerId(val.node.id)
                                          );
                                          navigate(
                                            `/companyOfficer/personalInformation`
                                          );
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMPANY_OFFICERS,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.COMPANY_OFFICERS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.COMPANY_OFFICERS,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(setOfficerViewScreen(false));
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              draftDeleteCompanyOfficer({
                                                variables: {
                                                  id: val.node.id,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data
                                                      .draft_deleteCompanyOfficerDraft
                                                      .count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Company Officer Draft Deleted!'
                                                      )
                                                    );
                                                    getCompanyOfficers({
                                                      variables: {
                                                        first: itemsPerPage,
                                                        searchKey:
                                                          validationSearch
                                                            .values
                                                            ?.searchKey !==
                                                          'ALL'
                                                            ? validationSearch
                                                                .values
                                                                ?.searchKey
                                                            : undefined,
                                                        searchValue:
                                                          validationSearch
                                                            .values
                                                            ?.searchValue !==
                                                          'Select'
                                                            ? validationSearch
                                                                .values
                                                                ?.searchValue
                                                            : undefined,
                                                        status: [
                                                          OfficerStatusEnum.APPOINTED,
                                                          OfficerStatusEnum.REELECTED,
                                                        ],
                                                      },
                                                    });
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.COMPANY_OFFICERS,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        {(dataGetAllCompanyOfficer?.getAllCompanyOfficers
                          ?.draftCompanyOfficerConnection?.edges?.length ===
                          0 ||
                          dataGetAllCompanyOfficer?.getAllCompanyOfficers
                            ?.draftCompanyOfficerConnection?.edges ===
                            undefined) && (
                          <div className="addDiv">
                            <h6 className="">No records Found</h6>
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                        <p className="m-0 text-info">Rows Per Page:</p>
                        <select
                          className="form-control button-danger text-info width-5vw"
                          value={itemsPerPage}
                          onChange={e => {
                            const { value } = e.target;
                            setItemsPerPage(Number(value));
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={30}>30</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <i
                          className={
                            dataGetAllCompanyOfficer?.getAllCompanyOfficers
                              ?.draftCompanyOfficerConnection?.pageInfo
                              ?.hasPreviousPage
                              ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                              : 'bx bx-caret-left bx-sm ms-auto'
                          }
                          onClick={() => {
                            if (
                              companyID &&
                              dataGetAllCompanyOfficer?.getAllCompanyOfficers
                                ?.draftCompanyOfficerConnection?.pageInfo
                                ?.hasPreviousPage
                            ) {
                              getCompanyOfficers({
                                variables: {
                                  searchKey:
                                    validationSearch.values?.searchKey !== 'ALL'
                                      ? validationSearch.values?.searchKey
                                      : undefined,
                                  searchValue:
                                    validationSearch.values?.searchValue !==
                                    'Select'
                                      ? validationSearch.values?.searchValue
                                      : undefined,
                                  last: itemsPerPage,
                                  before:
                                    dataGetAllCompanyOfficer
                                      ?.getAllCompanyOfficers
                                      ?.draftCompanyOfficerConnection?.pageInfo
                                      ?.startCursor,
                                  status: [
                                    OfficerStatusEnum.APPOINTED,
                                    OfficerStatusEnum.REELECTED,
                                  ],
                                },
                              });
                            }
                          }}
                        />

                        <i
                          className={
                            dataGetAllCompanyOfficer?.getAllCompanyOfficers
                              ?.draftCompanyOfficerConnection?.pageInfo
                              ?.hasNextPage
                              ? 'bx bx-caret-right bx-sm color-primary '
                              : 'bx bx-caret-right bx-sm'
                          }
                          onClick={() => {
                            if (
                              companyID &&
                              dataGetAllCompanyOfficer?.getAllCompanyOfficers
                                ?.draftCompanyOfficerConnection?.pageInfo
                                ?.hasNextPage
                            ) {
                              getCompanyOfficers({
                                variables: {
                                  searchKey:
                                    validationSearch.values?.searchKey !== 'ALL'
                                      ? validationSearch.values?.searchKey
                                      : undefined,
                                  searchValue:
                                    validationSearch.values?.searchValue !==
                                    'Select'
                                      ? validationSearch.values?.searchValue
                                      : undefined,
                                  last: itemsPerPage,
                                  after:
                                    dataGetAllCompanyOfficer
                                      ?.getAllCompanyOfficers
                                      ?.draftCompanyOfficerConnection?.pageInfo
                                      ?.endCursor,
                                  status: [
                                    OfficerStatusEnum.APPOINTED,
                                    OfficerStatusEnum.REELECTED,
                                  ],
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modalVisibility}
        toggle={() => {
          toggleModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bxs-file-blank text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Power of Attorney Info
              </h5>
            </div>

            <button
              onClick={() => {
                toggleModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="authorizedFromName">
                      Authorized From Name*
                    </label>
                    {validation.touched.authorizedFromName &&
                    validation.errors.authorizedFromName ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.authorizedFromName}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.authorizedFromName &&
                      validation.errors.authorizedFromName &&
                      'is-invalid'
                    }`}
                    id="authorizedFromName"
                    name="authorizedFromName"
                    placeholder="Authorized From Name"
                    value={validation.values.authorizedFromName}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="authorizedFromAddress">
                      Authorized From Address*
                    </label>
                    {validation.touched.authorizedFromAddress &&
                    validation.errors.authorizedFromAddress ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.authorizedFromAddress}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.authorizedFromAddress &&
                      validation.errors.authorizedFromAddress &&
                      'is-invalid'
                    }`}
                    id="authorizedFromAddress"
                    name="authorizedFromAddress"
                    placeholder="Authorized From Address"
                    value={validation.values.authorizedFromAddress}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="authorizedToName">
                      Authorized To Name*
                    </label>
                    {validation.touched.authorizedToName &&
                    validation.errors.authorizedToName ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.authorizedToName}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.authorizedToName &&
                      validation.errors.authorizedToName &&
                      'is-invalid'
                    }`}
                    id="authorizedToName"
                    name="authorizedToName"
                    placeholder="Authorized To Name"
                    value={validation.values.authorizedToName}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="authorizedToAddress">
                      Authorized To Address*
                    </label>
                    {validation.touched.authorizedToAddress &&
                    validation.errors.authorizedToAddress ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.authorizedToAddress}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.authorizedToAddress &&
                      validation.errors.authorizedToAddress &&
                      'is-invalid'
                    }`}
                    id="authorizedToAddress"
                    name="authorizedToAddress"
                    placeholder="Authorized To Address"
                    value={validation.values.authorizedToAddress}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="sentToName">Sent To Name*</label>
                    {validation.touched.sentToName &&
                    validation.errors.sentToName ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.sentToName}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.sentToName &&
                      validation.errors.sentToName &&
                      'is-invalid'
                    }`}
                    id="sentToName"
                    name="sentToName"
                    placeholder="Sent To Name"
                    value={validation.values.sentToName}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-12">
                <div className="form-group">
                  <div className="d-flex">
                    <label htmlFor="sentToAddress">Sent To Address*</label>
                    {validation.touched.sentToAddress &&
                    validation.errors.sentToAddress ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.sentToAddress}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className={`form-control w-100 ${
                      validation.touched.sentToAddress &&
                      validation.errors.sentToAddress &&
                      'is-invalid'
                    }`}
                    id="sentToAddress"
                    name="sentToAddress"
                    placeholder="Sent To Address"
                    value={validation.values.sentToAddress}
                    // disabled={officerView}
                    onChange={validation.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  disabled={loadingGeneratePowerOfAttorneyLetter}
                  onMouseDown={() => {
                    validation.handleSubmit();
                  }}
                >
                  {loadingGeneratePowerOfAttorneyLetter && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
