import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query getDocuments(
    $companyId: String!
    $moduleName: String
    $fileName: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $searchKey: String
    $searchValue: String
  ) {
    getDocuments(
      filterBy: {
        companyId: $companyId
        moduleName: $moduleName
        fileName: $fileName
      }
      first: $first
      last: $last
      after: $after
      before: $before
      searchKey: $searchKey
      searchValue: $searchValue
    ) {
      edges {
        node {
          id
          fileName
          documentId
          companyId
          documentType
          userData {
            firstName
            lastName
          }
          createdAt
          documentId
          previewUrl
          moduleName
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const DELETE_DOCUMENTS = gql`
  query deleteDocument($id: String!) {
    deleteDocument(input: { id: $id }) {
      isDelete
    }
  }
`;

export const FIND_ONE_DOCUMENT = gql`
query findOneDocument($id:String){
  findOneDocument(input:{
    id:$id
  }){
    contentUrl
    previewUrl
  }
}

`

export const UPLOAD_DOCUMENT = gql`
  mutation uploadDocument(
    $companyId: String!
    $file: Upload
    $documentType: String!
    $moduleName: String!
  ) {
    uploadDocument(
      data: {
        companyId: $companyId
        moduleName: $moduleName
        documentType: $documentType
        file: $file
      }
    ) {
      isUploaded
    }
  }
`;

// mutation uploadDocument( $file:Upload) {
//    uploadDocument(
//    data: {
//    companyId:"b776dbe0-0733-11ed-bb10-8d720035cc06"
//    moduleName:"agenda-management"
//   file:$file
//   }
//  ) {
//    isUploaded
//   }
//  }
// query getDocument {
//     getDocuments(filterBy: {
//         companyId: "b776dbe0-0733-11ed-bb10-8d720035cc06"
//       moduleName:"agenda-management"
//         # startDate: "2021-11-07 17:00:07.643 +0500"
//         # endDate: "2023-12-1 12:35:55.259 +0500"
//       }){
//   edges{
//     node{
//       id
//       fileName
//       documentId
//       companyId
//       createdBy
//       createdAt
//       documentId
//       previewUrl
//     }
//   }
//     }
//   }

// query deletedocument {
//     deleteDocument(input:{
//       id:"1209f307-9266-41fe-9dce-0558d5b44816"
//     }){
//     isDelete
//     }
//   }

// query getFolderChildren {
//     getFolderChildrenInfo(input:{
//       id:"80a94ac8-3ece-47ad-864e-5d939424c47c"
//     }){
//       data{
//       id
//       parentId
//       isFile
//       isFolder
//       name
//       createdByUser
//       modifiedByUser
//       createdAt
//       modifiedAt
//       previewUrl
//       contentUrl
//     }
//     }
//   }

// export const USER_DASHBOARD_DATA = gql`
// query getDashboardData(
//   $companyId: ID!  )

// {
//   getDashboardMeetingData(
//     filterBy: { companyId: $companyId}
//   )
//   {

//     pendingMom
//     completedMom
//     pendingMeeting
//     completedMeetings
//     completedReturn
//     pendingReturn
//     actualMember
//     actualOfficer
//     draftMember
//     draftOfficer
//   }
// }

// `;
