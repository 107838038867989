import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './users.scss';

import {
  FIND_ALL_USERS,
  UPDATE_SUBSCRIPTION_STATUS,
  FIND_USER,
} from '~/Store/graphQL/Users';
import { Container, Modal } from 'reactstrap';

import { UserTypeEnum } from '../../../commons/enums';
import GetUserType from '~/helpers/User/getUserType';
import GetSubscriptionStatus from '~/helpers/Subscriptions/getSubscriptionStatus';
import { setSelectedUserId } from '~/Store/reducers/users';

('~/Store/reducers/meeting');

export const AdminUsers: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dropDownRow, setDropDownRow] = useState(-1);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  // const [submit, setSubmit] = useState(false);

  const [userId, setUserId] = useState<string>('');
  const [userType, setUserType] = useState<number>();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    userType: '',
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    getAllUsers();

    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Company',
        },
      ])
    );
  }, []);

  const [
    getAllUsers,
    {
      loading: loadingGetAllUsers,
      error: errorGetAllGetAllUsers,
      data: dataGetAllGetAllUsers,
    },
  ] = useLazyQuery<any>(FIND_ALL_USERS, {
    variables: {
      first: itemsPerPage,
    },
    fetchPolicy: 'no-cache',
  });

  const [
    userPaymentData,
    {
      loading: loadingUserPaymentData,
      error: errorUserPaymentData,
      data: dataUserPaymentData,
    },
  ] = useLazyQuery<any>(FIND_USER, {
    fetchPolicy: 'no-cache',
  });

  const [
    updateSubscriptionStatus,
    {
      loading: loadingUpdateSubscriptionStatus,
      error: errorUpdateSubscriptionStatus,
      data: dataUpdateSubscriptionStatus,
    },
  ] = useMutation<any>(UPDATE_SUBSCRIPTION_STATUS);

  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef?.current?.toString() !== event?.target?.toString()
      ) {
        setDropDownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            {/* Second Row */}
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="userType">Company Type</label>
                </div>
                <div className="field">
                  <select
                    id="userType"
                    name="userType"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.userType}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.userType = value;
                      setFilters(temp);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={UserTypeEnum.COMPANY_ADMIN}>
                      Company Admin
                    </option>
                    <option value={UserTypeEnum.INTERMEDIAL_USER}>
                      Intermedial User
                    </option>
                    <option value={UserTypeEnum.SUPER_ADMIN}>
                      Super Admin
                    </option>
                  </select>
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.userType) {
                      getAllUsers({
                        variables: {
                          first: itemsPerPage,
                          userType: undefined,
                        },
                      });
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.userType = '';
                      setFilters(temp);
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  disabled={loadingGetAllUsers}
                  onClick={() => {
                    if (filters.userType) {
                      getAllUsers({
                        variables: {
                          first: itemsPerPage,
                          userType: filters?.userType
                            ? filters?.userType
                            : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllUsers ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th className="col-2">Name</th>
                            <th className="col-1">Type</th>
                            <th className="col-1">Email</th>
                            <th className="col-1">Phone</th>
                            <th className="col-1">CNIC</th>
                            <th className="col-1">IsActive</th>
                            <th className="col-1">Email Status</th>
                            <th className="col-1">Subscription Status</th>

                            <th className="col-1">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllGetAllUsers?.users?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  {val?.node?.firstName +
                                    ' ' +
                                    val?.node?.lastName}
                                </td>
                                <td>{GetUserType(val?.node.userType)}</td>

                                <td>{val?.node?.email}</td>

                                <td>{val?.node?.phoneNumber}</td>

                                <td>{val?.node?.cnic}</td>

                                <td>
                                  {val?.node?.isActive
                                    ? 'Active'
                                    : 'Not active'}
                                </td>

                                <td>
                                  {val?.node?.isEmailVerified
                                    ? 'Verified'
                                    : 'Not verified'}
                                </td>

                                <td>
                                  {val?.node?.subcriptionData?.status
                                    ? GetSubscriptionStatus(
                                        val?.node?.subcriptionData?.status
                                      )
                                    : 'N/A'}
                                </td>

                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(
                                          setSelectedUserId(val.node.id)
                                        );
                                        navigate('/admin/user/edit');
                                      }}
                                    />
                                    {/* <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(setAgendaViewScreen(false));
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          // if (result.isConfirmed) {
                                          //   deleteAgenda({
                                          //     variables: {
                                          //       id: val.node.agendaId,
                                          //     },
                                          //   })
                                          //     .then((res: any) => {
                                          //       if (
                                          //         res.data.deleteAgenda.count
                                          //       ) {
                                          //         dispatch(
                                          //           setToastSuccess(
                                          //             'Agenda Deleted!'
                                          //           )
                                          //         );
                                          //         getAllAgendaForCompany();
                                          //       }
                                          //     })
                                          //     .catch(err => {
                                          //       dispatch(setToastError(err));
                                          //     });
                                          // }
                                        });
                                      }}
                                    /> */}

                                    <div className="dropleft">
                                      <i
                                        id="dropdownMenuButton"
                                        ref={dropdownRef}
                                        className="bx bx-dots-vertical-rounded"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={() => {
                                          setDropDownRow(index);
                                          toggleModal();
                                        }}
                                      ></i>
                                      <ul
                                        className={`dropdown-menu min-width-8 ${
                                          dropDownVisibility &&
                                          index === dropDownRow &&
                                          'show'
                                        } `}
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li className="cursor-pointer">
                                          <a
                                            className="dropdown-item"
                                            onClick={e => {
                                              toggleModal();
                                              setMomModalVisibility(true);
                                              setUserId(val.node.id);
                                              setUserType(val?.node.userType);
                                              userPaymentData({
                                                variables: {
                                                  id: val.node.id,
                                                },
                                              });
                                            }}
                                          >
                                            Payment
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {/* {(dataGetAllAgendaForCompany?.findAllAgendas?.edges
                        ?.length === 0 ||
                        dataGetAllAgendaForCompany?.findAllAgendas?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )} */}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllGetAllUsers?.users?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllGetAllUsers?.users?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllUsers({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllGetAllUsers?.users?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllGetAllUsers?.users?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllGetAllUsers?.users?.pageInfo?.hasNextPage
                          ) {
                            getAllUsers({
                              variables: {
                                after:
                                  dataGetAllGetAllUsers?.users?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bxs-file-blank text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Payment info
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/*  */}
            <div className="row mt-3">
              <div className="col-xxl-4 col-xl-6">
                <div className="form-group">
                                      
                  <div className="d-flex w-350">
                                          
                    <label htmlFor="Email">Email</label>
                                                               
                  </div>
                                      
                  <input
                    type="text"
                    className={`form-control form-control-medium `}
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={
                      dataUserPaymentData?.user
                        ? dataUserPaymentData?.user?.email
                        : 'Null'
                    }
                    disabled={true}
                    // onChange={"validation.handleChange"}
                  />
                                    
                </div>
              </div>
              <div className="col-xxl-4 col-xl-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label htmlFor="payment">Payment Status</label>
                  </div>
                  <input
                    type="text"
                    className={`form-control form-control-medium `}
                    id="payment"
                    name="payment"
                    placeholder="payment"
                    value={
                      dataUserPaymentData?.user
                        ? GetSubscriptionStatus(
                            dataUserPaymentData?.user?.subcriptionData?.status
                          )
                        : 'N/A'
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {/* <div className="col-xxl-4 col-xl-6">
                <div className="form-group">
                                      
                  <div className="d-flex w-350">
                                          
                    <label htmlFor="duration">Duration</label>
                                                               
                  </div>
                                      
                  <input
                    type="text"
                    className={`form-control form-control-medium `}
                    id="duration"
                    name="duration"
                    placeholder="duration"
                    value={'5min'}
                    disabled={true}
                    // onChange={"validation.handleChange"}
                  />
                                    
                </div>
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-xxl-4 col-xl-6">
                {userType === UserTypeEnum.INTERMEDIAL_USER ? (
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="usersNo">No of companies</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium `}
                      id="noOfCompanies"
                      name="noOfCompanies"
                      placeholder="No of companies"
                      value={
                        dataUserPaymentData?.user
                          ? dataUserPaymentData?.user?.subcriptionData
                              ?.numOfCompanies
                          : 'Null'
                      }
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="usersNo">No of users</label>
                    </div>
                    <input
                      type="text"
                      className={`form-control form-control-medium `}
                      id="usersNo"
                      name="usersNo"
                      placeholder="users Number"
                      value={
                        dataUserPaymentData?.user
                          ? dataUserPaymentData?.user?.subcriptionData
                              ?.numOfUsers
                          : 'Null'
                      }
                      disabled={true}
                    />
                  </div>
                )}
              </div>

              <div className="col-xxl-4 col-xl-6">
                <div className="form-group">
                  <div className="d-flex w-350">
                    <label htmlFor="amount">Amount</label>
                  </div>
                  <input
                    type="text"
                    className={`form-control form-control-medium `}
                    id="amount"
                    name="amount"
                    placeholder="amount"
                    value={
                      dataUserPaymentData?.user
                        ? dataUserPaymentData?.user?.subcriptionData
                            ?.pendingAmount
                        : 'Null'
                    }
                    disabled={true}
                  />
                  <br className="my-4"></br>
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    <button
                      type="button"
                      className="btn dangerButton d-flex align-items-center my-auto"
                      onClick={() => {
                        setMomModalVisibility(false);
                        navigate('/admin/user');
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn successButton d-flex align-items-center my-auto"
                      onClick={() => {
                        updateSubscriptionStatus({
                          variables: {
                            id: userId,
                            isSubscriptionVerified: true,
                          },
                        }).then((res: any) => {
                          if (res.data) {
                            setUserId('');
                            setUserType(undefined);
                            getAllUsers();
                            setMomModalVisibility(false);
                            navigate('/admin/user');
                          }
                        });
                      }}
                    >
                      Approve
                      <i className="bx bx-check mx-1" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
