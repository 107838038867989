import { gql } from '@apollo/client';

export const GET_RELEVANT_DOCS_BY_MODULE_NAME = gql`

  query getRelevantDocsByModuleName($companyId:String!,
  $moduleName:String!) {
  getRelevantDocsByModuleName(
    filterBy: {
      companyId: $companyId
      moduleName:$moduleName
    }
  ) {
    edges{
      node {
        docName
        url
        documentId
      }
    }
  }
}
`;

export const GET_RELEVANT_PROCESSES_BY_EVENT_ID = gql`
  query getRelevantProcessesByEvent($eventIds: [ID]) {
    getRelevantProcessesByEvent(eventIds: $eventIds) {
      eventRelevantProcesses {
        event
        businessRule {
          duration
          artifactName
          companyKind
        }
      }
    }
  }
`;
