import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  FIND_COMPANY_MEMBER_BY_ID,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { MemberStatusEnum, ModuleEnum } from '../../../../commons/enums';
import { useSearchParams } from 'react-router-dom';
import { calendar, cdc, idCard2, PersonalInfoLogo } from '~/assets/images';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const MembershipInformation: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [membersData, setMembersData] = useState<any>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Membership Information',
        },
      ])
    );
  }, []);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);
  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      dateOfBecomingAMember: membersData?.dateOfMembership
        ? new Date(membersData.dateOfMembership).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      memberStatus: membersData ? membersData.status : '',
      date: membersData?.date
        ? new Date(membersData.date).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      dateOfCeasingToBeMember: membersData?.ceaseDate
        ? new Date(membersData.ceaseDate).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      reasonOfCeasingToBeMember: membersData ? membersData.cessationReason : '',
      cdcAccountNo: membersData ? membersData.cdcAccountNumber : '',
      // //
      otherInstructions: membersData ? membersData.otherInstructions : '',
      sendingNotesInstructions: membersData ? membersData.instructions : '',
    },
    validationSchema: Yup.object({
      // dateOfBecomingAMember: Yup.string().nullable(),
      memberStatus: Yup.string()
        .required('Please select member status')
        .nullable(),
      date: Yup.string().required('Please select date').nullable(),
      dateOfBecomingAMember: Yup.string()
        .required('Please enter a date')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      if (isDraft) {
        draftUpdateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            dateOfMembership: values.dateOfBecomingAMember,
            status: values.memberStatus,
            date: values.date,
            ceaseDate: values.dateOfCeasingToBeMember,
            cessationReason: values.reasonOfCeasingToBeMember,
            cdcAccountNumber: values.cdcAccountNo,
            otherInstructions: values.otherInstructions,
            instructions: values.sendingNotesInstructions,
          },
        })
          .then((res: any) => {
            if (res.data.draft_updateCompanyMemberDraft.draftCompanyMember) {
              dispatch(setToastSuccess('Company member draft updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/jointShareholderInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            dateOfMembership: values.dateOfBecomingAMember,
            status: values.memberStatus,
            date: values.date,
            ceaseDate: values.dateOfCeasingToBeMember,
            cessationReason: values.reasonOfCeasingToBeMember,
            cdcAccountNo: values.cdcAccountNo,
            otherInstructions: values.otherInstructions,
            instructions: values.sendingNotesInstructions,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyMember?.companyMember) {
              dispatch(setToastSuccess('Company Member updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/jointShareholderInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/jointShareholderInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingRelevantProcesses ||
      loadingReliventDocumnets ||
      loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 d-flex parent">
            <div className="w-100 bg-white">
              <div
                id="mainComponentHeading"
                className="background-primary-medium"
              >
                <span className="ms-auto">
                  <div className="d-flex justify-content-end gap-3  end-0 mx-4">
                    <button
                      type="button"
                      className="btn fw-bold dangerButton d-flex align-items-center "
                      disabled={loadingDraftUpdateCompanyMember}
                      onMouseDown={() => {
                        if (isNewFolio) {
                          navigate('/shares/add');
                        } else if (isNewFolioForTransfer) {
                          navigate('/shares/add/transfer');
                        } else {
                          navigate('/companyMembers');
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn fw-bold primaryDismissButton d-flex align-items-center "
                      disabled={loadingDraftUpdateCompanyMember}
                      onMouseDown={() => {
                        if (isNewFolio) {
                          navigate(
                            '/companyMembers/primaryShareHolderInformation?isNewFolio=true'
                          );
                        } else if (isNewFolioForTransfer) {
                          navigate(
                            '/companyMembers/primaryShareHolderInformation?isNewFolioForTransfer=true'
                          );
                        } else {
                          navigate(
                            '/companyMembers/primaryShareHolderInformation'
                          );
                        }
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary fw-bold successButton primaryButton d-flex align-items-center "
                      disabled={loadingDraftUpdateCompanyMember}
                      onMouseDown={() => {
                        memberView
                          ? navigate(
                              '/companyMembers/jointShareholderInformation'
                            )
                          : validation.handleSubmit();
                      }}
                    >
                      {(loadingDraftUpdateCompanyMember ||
                        loadingupdateCompanyMember) && (
                        <i className="bx bx-loader-circle bx-spin me-1" />
                      )}
                      {memberView ? 'Next' : 'Save & Next'}
                      <i className="bx bx-chevron-right ms-1" />
                    </button>
                  </div>
                </span>
              </div>

              <div className="c-nav">
                <span>
                  <ul className="nav nav-tabs w-100">
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link active cursor-pointer">
                          Company Member Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/primaryShareHolderInformation'
                            )
                          }
                          className="nav-link active cursor-pointer"
                        >
                          Company Member Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link cursor-pointer">
                          Joint Shareholder Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/jointShareholderInformation'
                            )
                          }
                          className="nav-link cursor-pointer "
                        >
                          Joint Shareholder Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link cursor-pointer">
                          Nominee Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate('/companyMembers/nomineeInformation')
                          }
                          className="nav-link cursor-pointer"
                        >
                          Nominee Information
                        </a>
                      )}
                    </li>
                    {!isDraft && (
                      <li className="nav-item border-end flex-grow-1 text-center">
                        <a
                          onClick={() =>
                            navigate('/companyMembers/memberHistory')
                          }
                          className="nav-link cursor-pointer"
                        >
                          Member History
                        </a>
                      </li>
                    )}
                  </ul>
                </span>
              </div>

              <div className="c-subnav">
                <span>
                  <ul className="nav nav-tabs">
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link  cursor-pointer">
                          Personal Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate(
                              '/companyMembers/primaryShareHolderInformation'
                            )
                          }
                          className="nav-link  cursor-pointer"
                        >
                          Personal Information
                        </a>
                      )}
                    </li>
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link  active cursor-pointer">
                          Membership Information
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate('/companyMembers/membershipInformation')
                          }
                          className="nav-link active cursor-pointer"
                        >
                          Membership Information
                        </a>
                      )}
                    </li>
                  </ul>
                </span>
              </div>
              <div id="mainComponentNew" className="scroll-content">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={PersonalInfoLogo}
                        ></img>
                        <div className="font-weight-600">Personal Info</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cdcAccountNo">CDC Account No</label>
                          {validation.touched.cdcAccountNo &&
                          validation.errors.cdcAccountNo ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.cdcAccountNo}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.cdcAccountNo &&
                            validation.errors.cdcAccountNo &&
                            'is-invalid'
                          }`}
                          id="cdcAccountNo"
                          name="cdcAccountNo"
                          placeholder=" Account No"
                          value={validation.values.cdcAccountNo}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.cdcAccountNo &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.cdcAccountNo &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    cdcAccountNumber:
                                      validation.values.cdcAccountNo,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="dateOfBecomingAMember">
                            Date of Becoming a Member
                          </label>
                          {validation.touched.dateOfBecomingAMember &&
                          validation.errors.dateOfBecomingAMember ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.dateOfBecomingAMember}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="date"
                          className={`form-control w-100 ${
                            validation.touched.dateOfBecomingAMember &&
                            validation.errors.dateOfBecomingAMember &&
                            'is-invalid'
                          }`}
                          id="dateOfBecomingAMember"
                          name="dateOfBecomingAMember"
                          placeholder="Date of Becoming a Member"
                          value={validation.values.dateOfBecomingAMember}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.dateOfBecomingAMember &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.dateOfBecomingAMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    dateOfMembership:
                                      validation.values.dateOfBecomingAMember,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                  </div>
                  <div className="row py-4 background-dim-grey mb-4">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="sendingNotesInstructions">
                            Instructions for Sending Notices
                          </label>
                          {validation.touched.sendingNotesInstructions &&
                          validation.errors.sendingNotesInstructions ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.sendingNotesInstructions}
                            </p>
                          ) : null}
                        </div>
                        <textarea
                          className={`form-control w-100 ${
                            validation.touched.sendingNotesInstructions &&
                            validation.errors.sendingNotesInstructions &&
                            'is-invalid'
                          }`}
                          id="sendingNotesInstructions"
                          name="sendingNotesInstructions"
                          placeholder="Details here ..."
                          value={validation.values.sendingNotesInstructions}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.sendingNotesInstructions &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.sendingNotesInstructions &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    instructions:
                                      validation.values
                                        .sendingNotesInstructions,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="otherInstructions">
                            Particular of Dividend Mandates, Power of Attorney
                            and Other Instructions
                          </label>
                          {validation.touched.otherInstructions &&
                          validation.errors.otherInstructions ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.otherInstructions}
                            </p>
                          ) : null}
                        </div>
                        <textarea
                          className={`form-control w-100 ${
                            validation.touched.otherInstructions &&
                            validation.errors.otherInstructions &&
                            'is-invalid'
                          }`}
                          id="otherInstructions"
                          name="otherInstructions"
                          placeholder="Details here ..."
                          value={validation.values.otherInstructions}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.otherInstructions &&
                                isDraft &&
                                !loadingDraftUpdateCompanyMember &&
                                !loadingDraftUpdateCompanyMember &&
                                validation.values.otherInstructions &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    otherInstructions:
                                      validation.values.otherInstructions,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inputFields border-div px-3 pt-3">
                  <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                        <img
                          className="height-30 me-2 my-1 bg-transparent align-items-center"
                          src={PersonalInfoLogo}
                        ></img>
                        <div className="font-weight-600">Member Status</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="memberStatus">Member Status*</label>
                          {validation.touched.memberStatus &&
                          validation.errors.memberStatus ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.memberStatus}
                            </p>
                          ) : null}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <select
                            className={`form-control w-100 d-flex${
                              validation.touched.memberStatus &&
                              validation.errors.memberStatus &&
                              'is-invalid'
                            }`}
                            id="memberStatus"
                            name="memberStatus"
                            placeholder="Sectorial Classification"
                            value={validation.values.memberStatus}
                            disabled={memberView}
                            onBlur={e => {
                              validation.handleBlur(e);
                              {
                                !validation.errors.memberStatus &&
                                  isDraft &&
                                  !loadingDraftUpdateCompanyMember &&
                                  validation.values.memberStatus &&
                                  draftUpdateCompanyMember({
                                    variables: {
                                      id: selectedMemberId,
                                      companyId: companyID,
                                      Status: validation.values.memberStatus,
                                    },
                                  });
                              }
                            }}
                            onChange={validation.handleChange}
                          >
                            <option value="">Select</option>
                            <option value={MemberStatusEnum.ACTIVE}>
                              Active
                            </option>
                            <option value={MemberStatusEnum.CEASED}>
                              Ceased
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="date">Date*</label>
                          {validation.touched.date && validation.errors.date ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.date}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="date"
                          className={`form-control w-100 ${
                            validation.touched.date &&
                            validation.errors.date &&
                            'is-invalid'
                          }`}
                          id="date"
                          name="date"
                          value={validation.values.date}
                          disabled={memberView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.date &&
                                isDraft &&
                                validation.values.date &&
                                !loadingDraftUpdateCompanyMember &&
                                draftUpdateCompanyMember({
                                  variables: {
                                    id: selectedMemberId,
                                    companyId: companyID,
                                    date: validation.values.date,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                    {validation.values.memberStatus ===
                    MemberStatusEnum.CEASED ? (
                      <>
                        <div className="col-3">
                          <div className="form-group">
                            <div className="d-flex">
                              <label htmlFor="dateOfCeasingToBeMember">
                                Date of Ceasing to be Member
                              </label>
                              {validation.touched.dateOfCeasingToBeMember &&
                              validation.errors.dateOfCeasingToBeMember ? (
                                <p className="text-danger font-12 ms-auto mb-0">
                                  {validation.errors.dateOfCeasingToBeMember}
                                </p>
                              ) : null}
                            </div>
                            <input
                              type="date"
                              className={`form-control w-100 ${
                                validation.touched.dateOfCeasingToBeMember &&
                                validation.errors.dateOfCeasingToBeMember &&
                                'is-invalid'
                              }`}
                              id="dateOfCeasingToBeMember"
                              name="dateOfCeasingToBeMember"
                              placeholder="Date of Ceasing to be Member"
                              value={validation.values.dateOfCeasingToBeMember}
                              disabled={memberView}
                              onBlur={e => {
                                validation.handleBlur(e);
                                {
                                  !validation.errors.dateOfCeasingToBeMember &&
                                    isDraft &&
                                    !loadingDraftUpdateCompanyMember &&
                                    validation.values.dateOfCeasingToBeMember &&
                                    draftUpdateCompanyMember({
                                      variables: {
                                        id: selectedMemberId,
                                        companyId: companyID,
                                        ceaseDate:
                                          validation.values
                                            .dateOfCeasingToBeMember,
                                      },
                                    });
                                }
                              }}
                              onChange={validation.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <div className="d-flex">
                              <label htmlFor="reasonOfCeasingToBeMember">
                                Reason of Ceasing to be Member
                              </label>
                              {validation.touched.reasonOfCeasingToBeMember &&
                              validation.errors.reasonOfCeasingToBeMember ? (
                                <p className="text-danger font-12 ms-auto mb-0">
                                  {validation.errors.reasonOfCeasingToBeMember}
                                </p>
                              ) : null}
                            </div>
                            <input
                              type="text"
                              className={`form-control w-100 ${
                                validation.touched.reasonOfCeasingToBeMember &&
                                validation.errors.reasonOfCeasingToBeMember &&
                                'is-invalid'
                              }`}
                              id="reasonOfCeasingToBeMember"
                              name="reasonOfCeasingToBeMember"
                              placeholder="Reason of Ceasing to be Member"
                              value={
                                validation.values.reasonOfCeasingToBeMember
                              }
                              disabled={memberView}
                              onBlur={e => {
                                validation.handleBlur(e);
                                {
                                  !validation.errors
                                    .reasonOfCeasingToBeMember &&
                                    isDraft &&
                                    !loadingDraftUpdateCompanyMember &&
                                    validation.values
                                      .reasonOfCeasingToBeMember &&
                                    draftUpdateCompanyMember({
                                      variables: {
                                        id: selectedMemberId,
                                        companyId: companyID,
                                        cessationReason:
                                          validation.values
                                            .reasonOfCeasingToBeMember,
                                      },
                                    });
                                }
                              }}
                              onChange={validation.handleChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {!loadingReliventDocumnets &&
              !loadingRelevantProcesses &&
              !loadingGetHelp &&
              (dataReliventDocumnets ||
                dataRelevantProcesses ||
                dataGetHelp) && (
                <RightSideBar
                  documents={
                    dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                  }
                  processes={
                    dataRelevantProcesses?.getRelevantProcessesByEvent
                      ?.eventRelevantProcesses
                  }
                  help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
                />
              )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
