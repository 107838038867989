import { MemberCategoryEnum } from '../../commons/enums';
const GetCompanyMemberCategory = (category: string) => {
  if (category === MemberCategoryEnum.INDIVIDUAL) {
    return 'Individual';
  } else if (category === MemberCategoryEnum.LEGAL_PERSON) {
    return 'Legal Person';
  }
};

export default GetCompanyMemberCategory;
