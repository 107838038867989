import { gql } from '@apollo/client';
export const GET_ALL_BANK_ACCOUNTS_OF_COMPANY = gql`
  query findAllBankAccounts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $bankName: String
    $searchKey: String
    $searchValue: String
  ) {
    findAllBankAccounts(
      first: $first
      last: $last
      after: $after
      before: $before
      q: $bankName
      filterBy: {
        companyId: $companyId
      }
      searchKey: $searchKey
      searchValue: $searchValue
    ) {
      edges {
        node {
          id
          bankName
          branch
          accountNumber
          city
          companyId
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_BANK_ACCOUNT_BY_ID = gql`
  query findOneBankAccount($id: ID!) {
    findOneBankAccount(id: $id) {
      bankAccount {
        id
        bankName
        branch
        accountNumber
        city
        companyId
      }
    }
  }
`;
export const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount(
    $id: String!
    $bankName: String
    $branch: String
    $accountNumber: String
    $city: String
  ) {
    updateBankAccount(
      data: {
        id: $id
        data: {
          bankName: $bankName
          branch: $branch
          accountNumber: $accountNumber
          city: $city
        }
      }
    ) {
      bankAccount {
        id
        bankName
        branch
        accountNumber
        city
        companyId
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount(
    $companyId: String
    $bankName: String
    $branch: String
    $accountNumber: String
    $city: String
  ) {
    createBankAccount(
      data: {
        companyId: $companyId
        bankName: $bankName
        branch: $branch
        accountNumber: $accountNumber
        city: $city
      }
    ) {
      bankAccount {
        id
        bankName
        branch
        accountNumber
        city
        companyId
      }
    }
  }
`;
export const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($id: String!) {
    deleteBankAccount(data: { id: $id }) {
      count
    }
  }
`;
