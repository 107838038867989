import { createSlice } from '@reduxjs/toolkit';
import { BusinessRulesStoreType } from './types';

const initialState: BusinessRulesStoreType = {
  selectedBusinessRuleId: '',
  businessRuleView: false,
};
const businessRuleSlice = createSlice({
  name: 'businessRule',
  initialState,
  reducers: {
    setSelecteBusinessRuleId: (state, { payload }) => {
      state.selectedBusinessRuleId = payload;
    },
    setBusinessRuleViewScreen: (state, { payload }) => {
      state.businessRuleView = payload;
    },
  },
});

export const {
  setSelecteBusinessRuleId,
  setBusinessRuleViewScreen,
} = businessRuleSlice.actions;

export default businessRuleSlice.reducer;
