import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionType } from './types';

const initialState: SubscriptionType = {
  selectedSubscriptionId: '',
  // ordinanceView: false,
};
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSelectedSubscriptionId: (state, { payload }) => {
      state.selectedSubscriptionId = payload;
    },
  },
});

export const { setSelectedSubscriptionId } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
