import { createSlice } from '@reduxjs/toolkit';
import { BankAccountStoreType } from './types';

const initialState: BankAccountStoreType = {
  selectedBankAccountId: '',
  bankAccountView: false,
};
const bankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    setSelectedBankAccountId: (state, { payload }) => {
      state.selectedBankAccountId = payload;
    },
    setBankAccountViewScreen: (state, { payload }) => {
      state.bankAccountView = payload;
    },
  },
});

export const { setSelectedBankAccountId, setBankAccountViewScreen } = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
