import { MemberTypeEnum } from '../../commons/enums';
const GetCompanyMemberType = (type: string) => {
  if (type === MemberTypeEnum.AUDITOR) {
    return 'Auditor';
  } else if (type === MemberTypeEnum.CHAIRMAN) {
    return 'Chairman';
  } else if (type === MemberTypeEnum.CHIEF_ACCOUNTANT) {
    return 'Chief Accountant';
  } else if (type === MemberTypeEnum.CHIEF_EXECUTIVE) {
    return 'Chief Executive';
  } else if (type === MemberTypeEnum.COMPANY_SECRETARY) {
    return 'Company Sectary';
  } else if (type === MemberTypeEnum.DIRECTOR) {
    return 'Director';
  } else if (type === MemberTypeEnum.JOINT_OWNER_SHARE) {
    return 'Joint Owner Share';
  } else if (type === MemberTypeEnum.MANAGING_ACCOUNTANT) {
    return 'Managing Accountant';
  } else if (type === MemberTypeEnum.NOMINEE_DIRECTOR) {
    return 'Nominee Director';
  } else if (type === MemberTypeEnum.RELATIVE) {
    return 'Relative';
  } else if (type === MemberTypeEnum.ULTIMATE_BENEFICIAL_OWNER) {
    return 'Ultimate Beneficial Owner';
  }
};

export default GetCompanyMemberType;
