import { gql } from '@apollo/client';
export const GET_ALL_COMPANY_MEMBERS = gql`
  query getAllCompanyMembers(
    $first: Int
    $last: Int
    $companyId: String
    $after: String
    $before: String
    $status: String
    $category: String
    $folioNo: String
    $shareType: String
    $shareSubType: String
    $searchKey: String
    $searchValue: String
  ) {
    getAllCompanyMembers(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: {
        companyId: $companyId
        status: $status
        category: $category
        folioNo: $folioNo
        shareType: $shareType
        shareSubType: $shareSubType
      }
      searchKey: $searchKey
      searchValue: $searchValue
      orderBy: "date"
    ) {
      draftCompanyMemberConnection {
        edges {
          node {
            memberId
            firstName
            lastName
            category
            type
            status
            date
            dateOfMembership
            folioNo
            city
            phone
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      companyMemberConnection {
        edges {
          node {
            memberId
            firstName
            lastName
            category
            type
            status
            folioNo
            date
            dateOfMembership
            city
            phone
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const FIND_ALL_COMPANY_MEMBERS = gql`
  query findAllCompanyMembers(
    $first: Int
    $last: Int
    $companyId: String
    $after: String
    $before: String
    $status: String
    $category: String
  ) {
    findAllCompanyMembers(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, status: $status, category: $category }
    ) {
      edges {
        node {
          memberId
          firstName
          lastName
          category
          type
          status
          date
          email
          dateOfMembership
          folioNo
          city
          phone
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const CREATE_COMPANY_MEMBER_DRAFT = gql`
  mutation draft_createCompanyMemberDraft(
    $companyId: String!
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $date: String
    $type: String
    $category: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $passportNumber: String
    $ntn: String
    $occupation: String
    $companyName: String
    $companyCuin: String
    $numOfShares: Float
    $holdingCompany: Boolean
    $subsidiaries: Boolean
    $residentialAddress: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $email: String
    $interestHeld: Float
    $dateOfMembership: String
    $amountPaid: Float
    $action: String
    $ceaseDate: String
    $cessationReason: String
    $companyNomineeInfo: [CompanyNomineeInfoInput]
    $jointShareHolderInfo: [JointShareHolderInfoInput]
  ) {
    draft_createCompanyMemberDraft(
      data: {
        companyId: $companyId
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        status: $status
        date: $date
        type: $type
        category: $category
        relativeFirstName: $relativeFirstName
        relativeMiddleName: $relativeMiddleName
        relativeLastName: $relativeLastName
        relationship: $relationship
        nationality: $nationality
        cnic: $cnic
        passportNumber: $passportNumber
        ntn: $ntn
        occupation: $occupation
        companyName: $companyName
        companyCuin: $companyCuin
        numOfShares: $numOfShares
        holdingCompany: $holdingCompany
        subsidiaries: $subsidiaries
        residentialAddress: $residentialAddress
        city: $city
        province: $province
        country: $country
        postalCode: $postalCode
        email: $email
        interestHeld: $interestHeld
        dateOfMembership: $dateOfMembership
        amountPaid: $amountPaid
        action: $action
        ceaseDate: $ceaseDate
        cessationReason: $cessationReason
        companyNomineeInfo: $companyNomineeInfo
        jointShareHolderInfo: $jointShareHolderInfo
      }
    ) {
      draftCompanyMember {
        memberId
        firstName
        middleName
        lastName
        status
        type
        category
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        ntn
        occupation
        companyName
        companyCuin
        numOfShares
        holdingCompany
        subsidiaries
        residentialAddress
        phone
        city
        province
        country
        postalCode
        email
        interestHeld
        dateOfMembership
        amountPaid
        action
        ceaseDate
        cessationReason
        companyId
        companyNomineeInfo {
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          passportNumber
          residentialAddress
          phone
          city
          province
          country
          postalCode
          trusteeName
          dobMinor
        }
        jointShareHolderInfo {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;
export const DELETE_COMPANY_MEMBER = gql`
  mutation deleteCompanyMember($id: String!) {
    deleteCompanyMember(data: { id: $id }) {
      count
    }
  }
`;
export const DRAFT_DELETE_COMPANY_MEMBER = gql`
  mutation draft_deleteCompanyMemberDraft($id: String!) {
    draft_deleteCompanyMemberDraft(data: { id: $id }) {
      count
    }
  }
`;
export const FIND_COMPANY_MEMBER_BY_ID = gql`
  query findOneCompanyMember($id: ID!) {
    findOneCompanyMember(id: $id) {
      companyMember {
        memberId
        folioNo
        secpRefId
        firstName
        middleName
        lastName
        status
        date
        type
        category
        email
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        companyId
        passportNumber
        legalPersonName
        companyCuin
        occupation
        cnic
        cnicExpiry
        numOfShares
        holdingCompany
        subsidiaries
        city
        province
        country
        postalCode
        email
        phone
        ntn
        residentialAddress
        dateOfMembership
        ceaseDate
        cessationReason
        isDraft
        cdcAccountNumber
        otherInstructions
        instructions
        companyNomineeInfo {
          id
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          cnicExpiry
          passportNumber
          residentialAddress
          country
          province
          city
          postalCode
          phone
          trusteeName
          dobMinor
          url
        }
        jointShareHolderInfo {
          id
          firstName
          middleName
          lastName
        }
        companyMemberHistory {

          memberId
          folioNo
          secpRefId
          firstName
          middleName
          lastName
          status
          date
          type
          category
          email
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          companyId
          passportNumber
          legalPersonName
          occupation
          cnic
          cnicExpiry
          companyCuin
          numOfShares
          holdingCompany
          subsidiaries
          city
          province
          country
          postalCode

          email
          phone
          ntn
          residentialAddress
          dateOfMembership
          ceaseDate
          cessationReason
          isDraft
          cdcAccountNumber
          otherInstructions
          instructions
        }
      }
    }
  }
`;
export const FIND_COMPANY_MEMBER_BY_Email = gql`
  query findOneCompanyMember($email: String!) {
    findOneCompanyMember(filterBy: { email: $email }) {
      companyMember {
        memberId
        folioNo
        secpRefId
        firstName
        middleName
        lastName
        status
        date
        type
        category
        email
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        companyId
        passportNumber
        legalPersonName
        companyCuin
        occupation
        cnic
        numOfShares
        holdingCompany
        subsidiaries
        city
        province
        country
        postalCode
        email
        phone
        ntn
        residentialAddress
        dateOfMembership
        ceaseDate
        cessationReason
        isDraft
        cdcAccountNumber
        otherInstructions
        instructions
        companyNomineeInfo {
          id
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          passportNumber
          residentialAddress
          country
          province
          city
          postalCode
          phone
          trusteeName
          dobMinor
          url
        }
        jointShareHolderInfo {
          id
          firstName
          middleName
          lastName
        }
        companyMemberHistory {
          memberId
          date
          status
          date
          type
          cnic
          firstName
          middleName
          lastName
          passportNumber
          residentialAddress
          secpRefId
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_MEMBER = gql`
  mutation update_companyMember(
    $id: String!
    $companyId: String!
    $secpRefId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $date: String
    $type: String
    $category: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $passportNumber: String
    $ntn: String
    $occupation: String
    $companyName: String
    $legalPersonName: String
    $companyCuin: String
    $numOfShares: Float
    $holdingCompany: Boolean
    $subsidiaries: Boolean
    $residentialAddress: String
    $city: String
    $province: String
    $country: String
    $email: String
    $phone: String
    $postalCode: String
    $interestHeld: Float
    $dateOfMembership: String
    $amountPaid: Float
    $action: String
    $ceaseDate: String
    $cessationReason: String
    $cdcAccountNumber: String
    $otherInstructions: String
    $instructions: String
    $companyNomineeInfo: [CompanyNomineeInfoInput]
    $jointShareHolderInfo: [JointShareHolderInfoInput]
  ) {
    update_companyMember(
      data: {
        id: $id
        data: {
          companyId: $companyId
          secpRefId: $secpRefId
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          status: $status
          date: $date
          type: $type
          category: $category
          relativeFirstName: $relativeFirstName
          relativeMiddleName: $relativeMiddleName
          relativeLastName: $relativeLastName
          relationship: $relationship
          nationality: $nationality
          cnic: $cnic
          passportNumber: $passportNumber
          ntn: $ntn
          occupation: $occupation
          companyName: $companyName
          legalPersonName: $legalPersonName
          companyCuin: $companyCuin
          numOfShares: $numOfShares
          holdingCompany: $holdingCompany
          subsidiaries: $subsidiaries
          residentialAddress: $residentialAddress
          city: $city
          province: $province
          country: $country
          email: $email
          phone: $phone
          postalCode: $postalCode
          interestHeld: $interestHeld
          dateOfMembership: $dateOfMembership
          amountPaid: $amountPaid
          action: $action
          ceaseDate: $ceaseDate
          cessationReason: $cessationReason
          cdcAccountNumber: $cdcAccountNumber
          otherInstructions: $otherInstructions
          instructions: $instructions
          companyNomineeInfo: $companyNomineeInfo
          jointShareHolderInfo: $jointShareHolderInfo
        }
      }
    ) {
      companyMember {
        memberId
        firstName
        middleName
        lastName
        status
        type
        category
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        ntn
        occupation
        companyName
        legalPersonName
        companyCuin
        numOfShares
        holdingCompany
        subsidiaries
        residentialAddress
        email
        phone
        city
        province
        country
        postalCode
        interestHeld
        dateOfMembership
        amountPaid
        action
        ceaseDate
        cessationReason
        companyId
        cdcAccountNumber
        companyNomineeInfo {
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          passportNumber
          residentialAddress
          phone
          city
          province
          country
          postalCode
          trusteeName
          dobMinor
        }
        jointShareHolderInfo {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_MEMBER_DRAFT = gql`
  mutation draft_updateCompanyMemberDraft(
    $id: String!
    $companyId: String
    $secpRefId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $email: String
    $status: String
    $date: String
    $type: String
    $category: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $passportNumber: String
    $ntn: String
    $occupation: String
    $companyName: String
    $legalPersonName: String
    $companyCuin: String
    $numOfShares: Float
    $holdingCompany: Boolean
    $subsidiaries: Boolean
    $residentialAddress: String
    $city: String
    $province: String
    $country: String
    $phone: String
    $postalCode: String
    $interestHeld: Float
    $dateOfMembership: String
    $amountPaid: Float
    $action: String
    $ceaseDate: String
    $cessationReason: String
    $cdcAccountNumber: String
    $otherInstructions: String
    $instructions: String
    $companyNomineeInfo: [CompanyNomineeInfoInput]
    $jointShareHolderInfo: [JointShareHolderInfoInput]
  ) {
    draft_updateCompanyMemberDraft(
      data: {
        id: $id
        data: {
          companyId: $companyId
          secpRefId: $secpRefId
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          email: $email
          status: $status
          date: $date
          type: $type
          category: $category
          relativeFirstName: $relativeFirstName
          relativeMiddleName: $relativeMiddleName
          relativeLastName: $relativeLastName
          relationship: $relationship
          nationality: $nationality
          cnic: $cnic
          passportNumber: $passportNumber
          ntn: $ntn
          occupation: $occupation
          companyName: $companyName
          legalPersonName: $legalPersonName
          companyCuin: $companyCuin
          numOfShares: $numOfShares
          holdingCompany: $holdingCompany
          subsidiaries: $subsidiaries
          residentialAddress: $residentialAddress
          city: $city
          province: $province
          country: $country
          phone: $phone
          postalCode: $postalCode
          interestHeld: $interestHeld
          dateOfMembership: $dateOfMembership
          amountPaid: $amountPaid
          action: $action
          ceaseDate: $ceaseDate
          cessationReason: $cessationReason
          cdcAccountNumber: $cdcAccountNumber
          otherInstructions: $otherInstructions
          instructions: $instructions
          companyNomineeInfo: $companyNomineeInfo
          jointShareHolderInfo: $jointShareHolderInfo
        }
      }
    ) {
      draftCompanyMember {
        memberId
        firstName
        middleName
        lastName
        status
        type
        category
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        ntn
        occupation
        companyName
        legalPersonName
        companyCuin
        numOfShares
        holdingCompany
        subsidiaries
        residentialAddress
        email
        phone
        city
        province
        country
        postalCode
        interestHeld
        dateOfMembership
        amountPaid
        action
        ceaseDate
        cessationReason
        companyId
        cdcAccountNumber
        companyNomineeInfo {
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          passportNumber
          residentialAddress
          phone
          city
          province
          country
          postalCode
          trusteeName
          dobMinor
        }
        jointShareHolderInfo {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;
export const CREATE_COMPANY_MEMBER_FROM_DRAFT = gql`
  mutation create_companyMemberFromDraft(
    $secpRefId: String
    $companyId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $date: String
    $email: String
    $type: String
    $category: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $passportNumber: String
    $ntn: String
    $occupation: String
    $companyName: String
    $legalPersonName: String
    $companyCuin: String
    $numOfShares: Float
    $holdingCompany: Boolean
    $subsidiaries: Boolean
    $residentialAddress: String
    $city: String
    $province: String
    $country: String
    $phone: String
    $postalCode: String
    $interestHeld: Float
    $dateOfMembership: String
    $amountPaid: Float
    $action: String
    $ceaseDate: String
    $cessationReason: String
    $cdcAccountNumber: String
    $otherInstructions: String
    $instructions: String
    $companyNomineeInfo: [CompanyNomineeInfoInput]
    $jointShareHolderInfo: [JointShareHolderInfoInput]
  ) {
    create_companyMemberFromDraft(
      data: {
        companyId: $companyId
        secpRefId: $secpRefId
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        status: $status
        date: $date
        email: $email
        type: $type
        category: $category
        relativeFirstName: $relativeFirstName
        relativeMiddleName: $relativeMiddleName
        relativeLastName: $relativeLastName
        relationship: $relationship
        nationality: $nationality
        cnic: $cnic
        passportNumber: $passportNumber
        ntn: $ntn
        occupation: $occupation
        companyName: $companyName
        legalPersonName: $legalPersonName
        companyCuin: $companyCuin
        numOfShares: $numOfShares
        holdingCompany: $holdingCompany
        subsidiaries: $subsidiaries
        residentialAddress: $residentialAddress
        city: $city
        province: $province
        country: $country
        phone: $phone
        postalCode: $postalCode
        interestHeld: $interestHeld
        dateOfMembership: $dateOfMembership
        amountPaid: $amountPaid
        action: $action
        ceaseDate: $ceaseDate
        cessationReason: $cessationReason
        cdcAccountNumber: $cdcAccountNumber
        otherInstructions: $otherInstructions
        instructions: $instructions
        companyNomineeInfo: $companyNomineeInfo
        jointShareHolderInfo: $jointShareHolderInfo
      }
    ) {
      companyMember {
        memberId
        firstName
        middleName
        lastName
        status
        type
        category
        folioNo
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        ntn
        occupation
        companyName
        legalPersonName
        companyCuin
        numOfShares
        holdingCompany
        subsidiaries
        residentialAddress
        email
        phone
        city
        province
        country
        postalCode
        interestHeld
        dateOfMembership
        amountPaid
        action
        ceaseDate
        cessationReason
        companyId
        cdcAccountNumber
        companyNomineeInfo {
          firstName
          middleName
          lastName
          nominationReceiptDate
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          passportNumber
          residentialAddress
          phone
          city
          province
          country
          postalCode
          trusteeName
          dobMinor
        }
        jointShareHolderInfo {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;
export const EXPORT_COMPANY_MEMBER_DATA_IN_EXCEL_FILE = gql`
  query exportCompanyMembers($companyId: ID!) {
    exportCompanyMembers(companyId: $companyId) {
      isSuccessful
      url
      fileName
    }
  }
`;
export const CREATE_COMPANY_MEMBER_FROM_EXCEL_FILE = gql`
  mutation importCompanyMembers(
    $companyId: String!
    $companyMember: [CreateCompanyMemberInput!]!
  ) {
    importCompanyMembers(
      data: { companyId: $companyId, companyMember: $companyMember }
    ) {
      isSuccessfull
    }
  }
`;
export const GET_ALL_FOLIO_NO_OF_COMPANY = gql`
  query getAllFolioNumbersOfCompany($id: ID!) {
    getAllFolioNumbersOfCompany(companyId: $id) {
      folioNumbers
    }
  }
`;
export const GET_NEXT_FOLIO_NO = gql`
  query getFolioNumber($companyId: ID!) {
    getFolioNumber(id: $companyId) {
      folioNo
    }
  }
`;
