import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import {
  BusinessTypeEnum,
  CommitteeStatusEnum,
  DesignationEnum,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
  ModuleEnum,
} from '../../../commons/enums';
import {
  CREATE_AGENDA,
  FIND_ACTION_ITEMS_OF_AGENDA,
} from '~/Store/graphQL/AgendaManagement';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ActionItemsInputDto } from './types';
import './committee.scss';
import moment from 'moment';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {
  CREATE_COMMITTEE,
  FIND_COMMITTEE_BY_ID,
  UPDATE_COMMITTEE,
} from '~/Store/graphQL/CommitteeManagement';
import {
  setCommitteeHead,
  setCommitteeMembers,
  setCommitteeName,
  setIsSendEmailInvitation,
  setStartDate,
} from '~/Store/reducers/committee';
import { Container, Modal } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';
import { FIND_ALL_COMPANY_OFFICERS } from '~/Store/graphQL/CompanyOfficer';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';

export const AddCommittee: FC = () => {
  const {
    selectedCommitteeId,
    committeeView,
    committeeName,
    committeeHead,
    committeeMembers,
    startDate,
    isSendEmailInvitation,
  } = useAppSelector(state => state.committeeReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [committeeData, setCommitteeData] = useState<any>();
  const [email, setEmail] = useState<any>([]);
  const [meetingSubType, setMeetingSubType] = useState<string>();
  const [actionItems, setActionItems] = useState<any>([]);
  const [actionItemsData, setActionItemsData] = useState<
    ActionItemsInputDto[] | undefined
  >();
  const [committeeActionItems, setCommitteeActionItems] = useState<any>([]);
  const [committeeActionItemIds, setCommitteeActionItemIds] = useState<any>([]);
  const [agendaInfoModalVisibility, setAgendaInfoModalVisibility] =
    useState(false);
  const [selectOrdinanceName, setSelectOrdinanceName] = useState<any>();
  const [selectSection, setSelectSection] = useState<any>();
  const [committeeHeadOptions, setCommitteeHeadOptions] = useState<any>([]);
  const [emailAlreadyExistsError, setEmailAlreadyExistsError] =
    useState<string>('');

  useEffect(() => {
    getAllOrdinance();
    dispatch(
      setBreadCrumb([
        {
          url: '/committee',
          title: 'Committee',
        },
        {
          url: location.pathname,
          title: 'Add Committee',
        },
      ])
    );
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMMITTEE,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMMITTEE,
    },
  });

  // get all ordinance info
  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    fetchPolicy: 'no-cache',
  });

  // Getting committee information
  const [
    findCommitteeById,
    {
      loading: loadingFindCommitteeById,
      error: errorFindCommitteeById,
      data: dataFindCommitteeById,
    },
  ] = useLazyQuery<any>(FIND_COMMITTEE_BY_ID);
  //   update committeee
  const [
    updateCommittee,
    {
      loading: loadingUpdateCommittee,
      error: errorUpdateCommittee,
      data: dataUpdateCommittee,
    },
  ] = useMutation<any>(UPDATE_COMMITTEE);
  // create committee
  const [
    createCommittee,
    {
      loading: loadingCreateCommittee,
      error: errorCreateCommittee,
      data: dataCreateCommittee,
    },
  ] = useMutation<any>(CREATE_COMMITTEE);
  // Get action items of agenda query
  const [
    getActionItemsOfAgenda,
    {
      loading: loadingGetActionItemsOfAgenda,
      error: errorGetActionItemsOfAgenda,
      data: dataGetActionItemsOfAgenda,
    },
  ] = useLazyQuery<any>(FIND_ACTION_ITEMS_OF_AGENDA, {
    variables: {
      meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
    },
    fetchPolicy: 'no-cache',
  });
  // get data of directors for committee head dropdown
  const [
    getAllDirectorsFromCompanyOfficers,
    {
      loading: loadingGetAllDirectorsFromCompanyOfficers,
      error: errorGetAllDirectorsFromCompanyOfficers,
      data: dataGetAllDirectorsFromCompanyOfficers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_OFFICERS, {
    variables: {
      companyId: companyID,
      designation: DesignationEnum.DIRECTOR,
    },
    fetchPolicy: 'no-cache',
  });
  // create agenda
  const [
    createAgenda,
    {
      loading: loadingCreateAgenda,
      error: errorCreateAgenda,
      data: dataCreateAgenda,
    },
  ] = useMutation<any>(CREATE_AGENDA);
  useEffect(() => {
    if (companyID) {
      getAllDirectorsFromCompanyOfficers();
    }
  }, [companyID]);
  useEffect(() => {
    if (dataGetAllDirectorsFromCompanyOfficers) {
      let commmitteeMembers: string[] = [];
      dataGetAllDirectorsFromCompanyOfficers?.findAllCompanyOfficers?.edges.forEach(
        (element: any) => {
          commmitteeMembers.push(element?.node?.email);
        }
      );
      setCommitteeHeadOptions(
        dataGetAllDirectorsFromCompanyOfficers?.findAllCompanyOfficers?.edges
      );
      if (!selectedCommitteeId) {
        dispatch(setCommitteeMembers(commmitteeMembers.join(';')));
      }
    } else if (errorGetAllDirectorsFromCompanyOfficers) {
      dispatch(setToastError(errorGetAllDirectorsFromCompanyOfficers));
      console.log(
        'errorGetAllDirectorsFromCompanyOfficers:',
        errorGetAllDirectorsFromCompanyOfficers
      );
    }
  }, [
    dataGetAllDirectorsFromCompanyOfficers,
    errorGetAllDirectorsFromCompanyOfficers,
  ]);
  useEffect(() => {
    if (meetingSubType && !committeeView && committeeActionItems) {
      getActionItemsOfAgenda({
        variables: {
          actionItemIds: committeeActionItemIds,
          meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
          meetingSubType: meetingSubType,
          q: '',
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [meetingSubType, committeeActionItems]);
  useEffect(() => {
    if (dataGetActionItemsOfAgenda) {
      setActionItemsData(
        dataGetActionItemsOfAgenda?.findActionItemByMeetingTypeAndSubType
          ?.actionItems
      );
    } else if (errorGetActionItemsOfAgenda) {
      dispatch(setToastError(errorGetActionItemsOfAgenda));
      console.log('errorGetActionItemsOfAgenda:', errorGetActionItemsOfAgenda);
    }
  }, [dataGetActionItemsOfAgenda, errorGetActionItemsOfAgenda]);
  useEffect(() => {
    if (selectedCommitteeId) {
      findCommitteeById({
        variables: {
          id: selectedCommitteeId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedCommitteeId]);
  useEffect(() => {
    if (dataFindCommitteeById) {
      setCommitteeData(dataFindCommitteeById?.findOneCommittee?.committee);
      dispatch(
        setCommitteeName(
          dataFindCommitteeById?.findOneCommittee?.committee?.committeeName
        )
      );
      dispatch(
        setCommitteeHead(
          dataFindCommitteeById?.findOneCommittee?.committee?.committeeHeadEmail
        )
      );
      dispatch(
        setCommitteeMembers(
          dataFindCommitteeById?.findOneCommittee?.committee
            ?.committeeMembersEmails
        )
      );
      dispatch(
        setStartDate(
          moment(
            dataFindCommitteeById?.findOneCommittee?.committee
              ?.committeeStartDate
          ).format('YYYY-MM-DD')
        )
      );
      setEmail(
        dataFindCommitteeById?.findOneCommittee?.committee?.committeeMembersEmails.split(
          ';'
        )
      );
      setMeetingSubType(
        dataFindCommitteeById?.findOneCommittee?.committee?.meetingSubType
      );
      let stringifyActionItems: any = [];
      dataFindCommitteeById?.findOneCommittee?.committee.committeeObjectives.forEach(
        (element: any) => {
          delete element['__typename'];
          let parseActionItem = JSON.stringify(element);
          stringifyActionItems.push(parseActionItem);
        }
      );
      setActionItems(stringifyActionItems);
      if (committeeView) {
        setActionItemsData(
          dataFindCommitteeById?.findOneCommittee?.committee.committeeObjectives
        );
      }
    } else if (errorFindCommitteeById) {
      console.log('errorFindCommitteeById:', errorFindCommitteeById);
    }
  }, [dataFindCommitteeById, errorFindCommitteeById]);
  useEffect(() => {
    if (actionItems) {
      let temp = [...committeeActionItems];
      let tempActionItemIds = [...committeeActionItemIds];
      actionItems.forEach((element: any) => {
        let parseActionItem = JSON.parse(element);
        parseActionItem.actionItemId = parseActionItem.id;
        delete parseActionItem['id'];
        delete parseActionItem['__typename'];
        var index = temp.findIndex(
          (x: any) => x.actionItemId === parseActionItem.actionItemId
        );
        if (index === -1) {
          temp.push(parseActionItem);
        }
        tempActionItemIds.push(parseActionItem.actionItemId);
      });
      setCommitteeActionItemIds(tempActionItemIds);
      setCommitteeActionItems(temp);
    }
  }, [actionItems]);
  useEffect(() => {
    if (selectOrdinanceName) {
      const result = dataGetAllOrdinance?.findAllOrdinance?.edges.filter(
        (ordinance: any, ordinanceIndex: number) =>
          ordinance?.node?.ordinanceName === selectOrdinanceName
      );
      setSelectSection(result);
    }
  }, [selectOrdinanceName]);
  useEffect(() => {
    if (emailAlreadyExistsError) {
      setTimeout(() => {
        setEmailAlreadyExistsError('');
      }, 8000);
    }
  }, [emailAlreadyExistsError]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
      meetingSubType: meetingSubType ? meetingSubType : '',
      committeeName: committeeName ? committeeName : '',
      committeeStatus: dataFindCommitteeById?.findOneCommittee?.committee
        ?.committeeStatus
        ? dataFindCommitteeById?.findOneCommittee?.committee?.committeeStatus
        : CommitteeStatusEnum.FORMED,
      startDate: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      committeeHead: committeeHead ? committeeHead : '',
      committeeMembers: committeeMembers ? committeeMembers : '',
      isSendEmail: isSendEmailInvitation ? isSendEmailInvitation : false,
    },
    validationSchema: Yup.object({
      meetingType: Yup.string()
        .required('Please select meeting type')
        .nullable(),
      meetingSubType: Yup.string()
        .required('Please select meeting sub type')
        .nullable(),
      committeeName: Yup.string()
        .required('Please enter committee name')
        .nullable(),
      committeeStatus: Yup.string()
        .required('Please select committee status')
        .nullable(),
      startDate: Yup.string().required('Please select start date').nullable(),
      committeeHead: Yup.string()
        .required('Please select committee head')

        .nullable(),
      committeeMembers: Yup.string()
        .required('Please enter committee members')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      actionItems?.forEach((element: any) => {
        delete element['__typename'];
        delete element['attachmentUrl'];
      });
      committeeActionItems?.forEach((element: any) => {
        delete element['__typename'];
        delete element['agendaId'];
        delete element['resolution'];
        delete element['businessType'];
        delete element['attachmentUrl'];
      });
      if (committeeData && selectedCommitteeId && !committeeView) {
        updateCommittee({
          variables: {
            id: selectedCommitteeId,
            meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
            meetingSubType: values.meetingSubType,
            committeeName: values.committeeName,
            committeeStatus: values.committeeStatus,
            committeeStartDate: new Date(values.startDate),
            committeeHeadEmail: values.committeeHead,
            committeeMembersEmails: values.committeeMembers,
            isSendEmail: values.isSendEmail,
            committeeObjectives: committeeActionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateCommittee?.committee) {
              dispatch(setToastSuccess('Committee updated!'));
              dispatch(setCommitteeName(''));
              dispatch(setStartDate(''));
              dispatch(setCommitteeHead(''));
              dispatch(setCommitteeMembers(''));
              dispatch(setIsSendEmailInvitation(false));
              navigate(`/committee`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        committeeActionItems?.forEach((element: any) => {
          delete element['__typename'];
          delete element['resolution'];
          delete element['businessType'];
        });
        createCommittee({
          variables: {
            companyId: companyID,
            meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
            meetingSubType: values.meetingSubType,
            committeeName: values.committeeName,
            committeeStatus: CommitteeStatusEnum.FORMED,
            committeeStartDate: new Date(values.startDate),
            committeeHeadEmail: values.committeeHead,
            committeeMembersEmails: values.committeeMembers,
            isSendEmail: values.isSendEmail,
            committeeObjectives: committeeActionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.createCommittee?.committee) {
              dispatch(setToastSuccess('Committee created!'));
              dispatch(setCommitteeName(''));
              dispatch(setStartDate(''));
              dispatch(setCommitteeHead(''));
              dispatch(setCommitteeMembers(''));
              dispatch(setIsSendEmailInvitation(false));
              navigate(`/committee`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  const agendaValidation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      actionItem: '',
      ordinance: '',
      section: '',
      businessType: '',
      attachment: {
        name: '',
      },
    },
    validationSchema: Yup.object({
      actionItem: Yup.string().required('Please enter action item').nullable(),
      ordinance: Yup.string().required('Please enter ordinance').nullable(),
      section: Yup.string().required('Please select section').nullable(),
      businessType: Yup.string()
        .required('Please enter business type')
        .nullable(),
    }),
    onSubmit: (values: any, { resetForm }: any) => {
      createAgenda({
        variables: {
          companyId: companyID,
          ordinanceType: values.ordinance,
          ordinanceSection: values.section,
          meetingType: MeetingTypeEnum.OTHER,
          meetingSubType: MeetingSubTypeEnum.OTHER,
          businessType: values.businessType,
          actionItems: [
            {
              id: uuidv4(),
              actionItem: values.actionItem,
              agendaId: '',
              attachment: values.attachment,
            },
          ],
        },
      })
        .then((res: any) => {
          if (res?.data?.createAgenda?.agenda) {
            let tempCommitteeActionItemIds = [...committeeActionItemIds];
            tempCommitteeActionItemIds.push(
              res?.data?.createAgenda?.agenda?.actionItems[0].id
            );
            setCommitteeActionItemIds(tempCommitteeActionItemIds);
            getActionItemsOfAgenda({
              variables: {
                actionItemIds: tempCommitteeActionItemIds,
                meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
                meetingSubType: meetingSubType,
                q: '',
              },
              fetchPolicy: 'no-cache',
            });
            resetForm();
            setAgendaInfoModalVisibility(false);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });
  const checkIfActionItemSelected = (actionItem: any) => {
    let temp = actionItems?.find(
      (o: any) => JSON.parse(o).id === actionItem.id
    );
    if (temp) {
      return true;
    } else {
      return false;
    }
  };
  const toggleAgendaInfoModal = () => {
    setAgendaInfoModalVisibility(!agendaInfoModalVisibility);
  };
  return (
    <React.Fragment>
      {loadingFindCommitteeById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="background-primary-medium"
            >
              <span className="ms-auto">
                <div className="d-flex justify-content-end gap-3 end-0 mx-4">
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      type="button"
                      className="btn primaryButton d-flex align-items-center fw-bold"
                      disabled={
                        loadingFindCommitteeById ||
                        loadingUpdateCommittee ||
                        loadingCreateCommittee
                      }
                      onMouseDown={() => {
                        dispatch(setCommitteeName(''));
                        dispatch(setStartDate(''));
                        dispatch(setCommitteeHead(''));
                        dispatch(setCommitteeMembers(''));
                        dispatch(setIsSendEmailInvitation(false));
                        navigate('/committee');
                      }}
                    >
                      Cancel
                    </button>
                    {!committeeView ? (
                      <button
                        type="button"
                        className="btn successButton d-flex align-items-center my-auto fw-bold"
                        disabled={
                          loadingFindCommitteeById || loadingUpdateCommittee
                        }
                        onMouseDown={() => {
                          if (actionItems && actionItems?.length !== 0) {
                            validation.handleSubmit();
                          } else {
                            dispatch(
                              setToastError('Please add atleast 1 action item!')
                            );
                          }
                        }}
                      >
                        {(loadingFindCommitteeById ||
                          loadingUpdateCommittee ||
                          loadingCreateCommittee) && (
                          <i className="bx bx-loader-circle bx-spin me-1" />
                        )}
                        Save
                        <i className="bx bx-upload mx-1" />
                      </button>
                    ) : null}
                  </div>
                </div>
              </span>
            </div>
            <div id="mainComponentNew" className="scroll-content">
              <div className="inputFields">
                <div></div>
                <div className="row">
                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="meetingType">Meeting Type*</label>
                        {validation.touched.meetingType &&
                        validation.errors.meetingType ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.meetingType}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control  w-100 ${
                            validation.touched.meetingType &&
                            validation.errors.meetingType &&
                            'is-invalid'
                          }`}
                          id="meetingType"
                          name="meetingType"
                          placeholder="Meeting Type"
                          value={validation.values.meetingType}
                          disabled={true}
                          onChange={validation.handleChange}
                        >
                          <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                            Committee Meeting
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex w-350">
                        <label htmlFor="meetingSubType">
                          Meeting Sub Type*
                        </label>
                        {validation.touched.meetingSubType &&
                        validation.errors.meetingSubType ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.meetingSubType}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.meetingSubType &&
                            validation.errors.meetingSubType &&
                            'is-invalid'
                          }`}
                          id="meetingSubType"
                          name="meetingSubType"
                          placeholder="Meeting Sub Type"
                          value={validation.values.meetingSubType}
                          disabled={committeeView}
                          onChange={e => {
                            setMeetingSubType(e.target.value);
                            validation.setFieldValue(
                              'meetingSubType',
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Select</option>
                          <option
                            value={MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING}
                          >
                            Audit Committee Meeting
                          </option>
                          <option
                            value={
                              MeetingSubTypeEnum.EXECUTIVE_COMMITTEE_MEETING
                            }
                          >
                            Executive Committee Meeting
                          </option>
                          <option
                            value={MeetingSubTypeEnum.HR_COMMITTEE_MEETING}
                          >
                            HR Committee Meeting
                          </option>
                          <option
                            value={
                              MeetingSubTypeEnum.NOMINATION_COMMITTEE_MEETING
                            }
                          >
                            Nomination Committee Meeting
                          </option>
                          <option
                            value={
                              MeetingSubTypeEnum.PROCUREMENT_COMMITTEE_MEETING
                            }
                          >
                            Precurement Committee Meeting
                          </option>
                          <option
                            value={
                              MeetingSubTypeEnum.RISK_MANAGEMENT_COMMITTEE_MEETING
                            }
                          >
                            Risk Management Committee Meeting
                          </option>
                          <option
                            value={MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING}
                          >
                            Other Committee Meeting
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="committeeName">Committee Name*</label>
                        {validation.touched.committeeName &&
                        validation.errors.committeeName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.committeeName}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.committeeName &&
                          validation.errors.committeeName &&
                          'is-invalid'
                        }`}
                        id="committeeName"
                        name="committeeName"
                        placeholder="Committee Name"
                        value={validation.values.committeeName}
                        disabled={committeeView}
                        onChange={e => {
                          dispatch(setCommitteeName(e.target.value));
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="committeeStatus">
                          Committee Status*
                        </label>
                        {validation.touched.committeeStatus &&
                        validation.errors.committeeStatus ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.committeeStatus}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.committeeStatus &&
                            validation.errors.committeeStatus &&
                            'is-invalid'
                          }`}
                          id="committeeStatus"
                          name="committeeStatus"
                          placeholder="Committee Status"
                          value={validation.values.committeeStatus}
                          disabled={true}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={CommitteeStatusEnum.FORMED}>
                            Formed
                          </option>
                          <option value={CommitteeStatusEnum.CONCLUDED}>
                            Concluded
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="committeeHead">Committee Head*</label>
                        {validation.touched.committeeHead &&
                        validation.errors.committeeHead ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.committeeHead}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.committeeHead &&
                            validation.errors.committeeHead &&
                            'is-invalid'
                          }`}
                          id="committeeHead"
                          name="committeeHead"
                          placeholder="Committee Head"
                          value={validation.values.committeeHead}
                          onChange={e => {
                            dispatch(setCommitteeHead(e.target.value));
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {committeeHeadOptions?.map(
                            (head: any, headIndex: number) => (
                              <option value={head?.node?.email} key={headIndex}>
                                {head?.node?.email}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-6">
                    {/* <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="committeeHead">Committee Head*</label>
                      {validation.touched.committeeHead &&
                      validation.errors.committeeHead ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.committeeHead}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control ${
                        validation.touched.committeeHead &&
                        validation.errors.committeeHead &&
                        'is-invalid'
                      }`}
                      id="committeeHead"
                      name="committeeHead"
                      placeholder="Committee Head"
                      value={validation.values.committeeHead}
                      disabled={committeeView}
                      // onChange={e => {
                      //   dispatch(setCommitteeHead(e.target.value));
                      //   validation.handleChange(e);
                      // }}

                      onChange={e => {
                        const { value } = e.target;
                        const re = /^[A-Za-z]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          dispatch(setCommitteeHead(e.target.value));
                          validation.handleChange(e);
                        }
                      }}
                    />
                  </div> */}
                    <div className="form-group">
                      <div className="d-flex ">
                        <label
                          htmlFor="startDate"
                          className="model-input-heading"
                        >
                          Start Date*
                        </label>
                        {validation.touched.startDate &&
                        validation.errors.startDate ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.startDate}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.startDate &&
                          validation.errors.startDate &&
                          'is-invalid'
                        }`}
                        disabled={committeeView}
                        id="startDate"
                        name="startDate"
                        placeholder="Start Date"
                        value={validation.values.startDate}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={e => {
                          dispatch(setStartDate(e.target.value));
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                    {/* <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="endDate" className="model-input-heading">
                        End Date
                      </label>
                      {validation.touched.endDate &&
                      validation.errors.endDate ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.endDate}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="date"
                      className={`form-control ${
                        validation.touched.endDate &&
                        validation.errors.endDate &&
                        'is-invalid'
                      }`}
                      id="endDate"
                      name="endDate"
                      placeholder="End Date"
                      disabled={committeeView}
                      value={validation.values.endDate}
                      onChange={validation.handleChange}
                    />
                  </div> */}
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label
                        htmlFor="startTime"
                        className="model-input-heading"
                      >
                        Start Time
                      </label>
                      {validation.touched.startTime &&
                      validation.errors.startTime ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.startTime}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="time"
                      className={`form-control ${
                        validation.touched.startTime &&
                        validation.errors.startTime &&
                        'is-invalid'
                      }`}
                      id="startTime"
                      name="startTime"
                      placeholder="Start Time"
                      disabled={committeeView}
                      value={validation.values.startTime}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="endTime" className="model-input-heading">
                        End Time
                      </label>
                      {validation.touched.endTime &&
                      validation.errors.endTime ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.endTime}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="time"
                      className={`form-control ${
                        validation.touched.endTime &&
                        validation.errors.endTime &&
                        'is-invalid'
                      }`}
                      id="endTime"
                      name="endTime"
                      placeholder="End Time"
                      disabled={committeeView}
                      value={validation.values.endTime}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="committeeHead">Committee Head*</label>
                      {validation.touched.committeeHead &&
                      validation.errors.committeeHead ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.committeeHead}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="email"
                      className={`form-control ${
                        validation.touched.committeeHead &&
                        validation.errors.committeeHead &&
                        'is-invalid'
                      }`}
                      id="committeeHead"
                      name="committeeHead"
                      placeholder="Committee Head"
                      value={validation.values.committeeHead}
                      disabled={committeeView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
              </div> */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex w-100">
                        <label htmlFor="committeeMembers">
                          Committee Members*
                        </label>
                        {validation.touched.committeeMembers &&
                        validation.errors.committeeMembers ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.committeeMembers}
                          </p>
                        ) : emailAlreadyExistsError ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {emailAlreadyExistsError}
                          </p>
                        ) : null}
                      </div>
                      <ReactMultiEmail
                        placeholder="Committee Members"
                        className={`multiple_emails-container w-auto ${
                          committeeView ? 'pointer-none' : ''
                        }`}
                        emails={committeeMembers.split(';')}
                        onChange={(_emails: string[]) => {
                          setEmail(_emails);
                          console.log(_emails);
                          let stringifyEmails = _emails.join(';');
                          dispatch(setCommitteeMembers(stringifyEmails));
                          validation.setFieldValue(
                            'committeeMembers',
                            stringifyEmails
                          );
                        }}
                        validateEmail={email => {
                          let committeeMembersArray =
                            committeeMembers.split(';');
                          var index = committeeMembersArray.findIndex(
                            (x: any) => x === email
                          );
                          if (index >= 0) {
                            // dispatch(setToastError('Email already added'));
                            setEmailAlreadyExistsError('Email already added');
                          }
                          return isEmail(email);
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                x
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6"></div>
                </div>
                {!committeeView ? (
                  <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      <label htmlFor="isSendEmail">Send Email Invitation</label>
                      <input
                        className="mx-3 form-check-input c-mx "
                        type="checkbox"
                        id={'isSendEmail'}
                        name={'isSendEmail'}
                        value={validation.values.isSendEmail.toString()}
                        disabled={committeeView}
                        checked={validation.values.isSendEmail ? true : false}
                        onChange={e => {
                          dispatch(setToastError(''));
                          if (e.target.checked === true) {
                            dispatch(setIsSendEmailInvitation(true));
                            validation.setFieldValue('isSendEmail', true);
                          } else {
                            dispatch(setIsSendEmailInvitation(false));
                            validation.setFieldValue('isSendEmail', false);
                          }
                        }}
                      ></input>
                    </div>
                  </div>
                ) : null}

                <hr className="my-4"></hr>
                <div>
                  <div className="formGroupHeading">TORs</div>
                  <hr className="formGroupHeadingUnderline background-primary"></hr>
                </div>
                {actionItemsData?.map((actionItem: any, index: number) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <input
                      className="me-3 form-check-input"
                      type="checkbox"
                      id={actionItem.id}
                      name={actionItem.id}
                      value={JSON.stringify(actionItem)}
                      disabled={committeeView}
                      checked={
                        committeeView
                          ? true
                          : checkIfActionItemSelected(actionItem)
                      }
                      onChange={e => {
                        dispatch(setToastError(''));
                        if (e.target.checked === true) {
                          let temp = [...actionItems];
                          temp.push(e.target.value);
                          setActionItems(temp);
                        } else {
                          let temp = [...actionItems];
                          let parsedActionItems: any = [];
                          actionItems.forEach((element: any) => {
                            let parseActionItem = JSON.parse(element);
                            parseActionItem.actionItemId = parseActionItem.id;
                            delete parseActionItem['id'];
                            delete parseActionItem['__typename'];
                            var index = parsedActionItems.findIndex(
                              (x: any) =>
                                x.actionItemId === parseActionItem.actionItemId
                            );
                            if (index === -1) {
                              parsedActionItems.push(parseActionItem);
                            }
                          });
                          var index = parsedActionItems.findIndex(
                            (x: any) =>
                              x.actionItemId ===
                              JSON.parse(e.currentTarget.value).id
                          );
                          if (index !== -1) {
                            temp.splice(index, 1);
                          }
                          let parsedTemp: any = [];
                          temp.forEach(element => {
                            parsedTemp.push(JSON.parse(element));
                          });
                          setCommitteeActionItems(parsedTemp);
                          setActionItems(temp);
                        }
                      }}
                    ></input>
                    <div className="action-items my-3">
                      {actionItem?.actionItem}
                    </div>
                  </div>
                ))}
                {(actionItemsData?.length === 0 ||
                  actionItemsData === undefined) && (
                  <div className="addDiv">
                    <h6 className="m-3">No records Found</h6>
                  </div>
                )}
                {!committeeView ? (
                  <div className="row my-4">
                    <div className="col-12 d-flex">
                      <button
                        type="button"
                        className="btn primary-btn-new-modal d-flex align-items-center my-auto fw-bold"
                        disabled={loadingGetActionItemsOfAgenda}
                        onClick={() => {
                          setAgendaInfoModalVisibility(true);
                        }}
                      >
                        <i className="bx bx-plus"></i>
                        <div className="ms-1">Add More</div>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
      <Modal
        size="md"
        isOpen={agendaInfoModalVisibility}
        toggle={() => {
          toggleAgendaInfoModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-plus-circle text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Add Committee TOR
              </h5>
            </div>

            <button
              onClick={() => {
                toggleAgendaInfoModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="inputFields">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="actionItem">Action Item*</label>
                      {agendaValidation.touched.actionItem &&
                      agendaValidation.errors.actionItem ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.actionItem}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control ${
                        agendaValidation.touched.actionItem &&
                        agendaValidation.errors.actionItem &&
                        'is-invalid'
                      }`}
                      id="actionItem"
                      name="actionItem"
                      placeholder="Action Item"
                      value={agendaValidation.values.actionItem}
                      disabled={committeeView}
                      onChange={agendaValidation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="ordinance">Ordinance*</label>
                      {agendaValidation.touched.ordinance &&
                      agendaValidation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.ordinance &&
                          agendaValidation.errors.ordinance &&
                          'is-invalid'
                        }`}
                        id="ordinance"
                        name="ordinance"
                        placeholder="Ordinance"
                        value={agendaValidation.values.ordinance}
                        disabled={committeeView}
                        onChange={e => {
                          agendaValidation.setFieldValue(
                            'ordinance',
                            e.target.value
                          );
                          setSelectOrdinanceName(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                          (ordinance: any, ordinanceIndex: number) => (
                            <option
                              value={ordinance?.node?.ordinanceName}
                              key={ordinanceIndex}
                            >
                              {ordinance?.node?.ordinanceName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="section">Section*</label>
                      {agendaValidation.touched.section &&
                      agendaValidation.errors.section ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.section}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.section &&
                          agendaValidation.errors.section &&
                          'is-invalid'
                        }`}
                        id="section"
                        name="section"
                        placeholder="Section"
                        value={agendaValidation.values.section}
                        disabled={committeeView}
                        onChange={agendaValidation.handleChange}
                      >
                        <option value="">Select</option>
                        {selectSection &&
                          selectSection[0]?.node?.section.map(
                            (section: any, sectionIndex: number) => (
                              <>
                                <option value={section?.id} key={sectionIndex}>
                                  {section?.sectionName}
                                </option>
                              </>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="businessType">Business Type*</label>
                      {agendaValidation.touched.businessType &&
                      agendaValidation.errors.businessType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.businessType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.businessType &&
                          agendaValidation.errors.businessType &&
                          'is-invalid'
                        }`}
                        id="businessType"
                        name="businessType"
                        placeholder="Business Type"
                        value={agendaValidation.values.businessType}
                        disabled={committeeView}
                        onChange={agendaValidation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={BusinessTypeEnum.ORDINARY_BUSINESS}>
                          Ordinary Business
                        </option>
                        <option value={BusinessTypeEnum.SPECIAL_BUSINESS}>
                          Special Business
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="font-weight-600">Attachment</div>
                  {/* <div className="font-11">Documents</div> */}
                  <div
                    className={`attachedDocumentDiv cursor-pointer ${
                      committeeView && 'icon-disabled'
                    }`}
                  >
                    <label htmlFor={'file-input'} className="cursor-pointer">
                      <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.docx"
                      onChangeCapture={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (e?.target?.files) {
                          const selectedFile = e?.target?.files[0];

                          // Perform file type validation
                          if (selectedFile) {
                            const allowedFileTypes = [
                              '.jpg',
                              '.jpeg',
                              '.png',
                              '.pdf',
                              '.docx',
                            ];
                            const fileExtension = selectedFile.name.substring(
                              selectedFile.name.lastIndexOf('.')
                            );
                            if (
                              !allowedFileTypes.includes(
                                fileExtension.toLowerCase()
                              )
                            ) {
                              // File type not allowed
                              dispatch(
                                setToastError(
                                  'Only JPG, PNG, PDF and DOCX files are allowed.'
                                )
                              );
                              e.target.value = ''; // Clear the selected file
                              return;
                            }

                            // Perform further processing with the selected file
                            agendaValidation.setFieldValue(
                              'attachment',
                              e?.target?.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-10 d-flex justify-content-start align-items-center">
                  {agendaValidation.values.attachment?.name ? (
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <ul className="d-flex justify-content-center align-items-center">
                          <li>{agendaValidation.values.attachment.name}</li>
                          {!committeeView ? (
                            <i
                              className="bx bx-x bx-sm mx-3 cursor-pointer"
                              onClick={e => {
                                agendaValidation.setFieldValue(
                                  'attachment',
                                  ''
                                );
                              }}
                            ></i>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  disabled={loadingCreateAgenda}
                  onMouseDown={() => {
                    agendaValidation.handleSubmit();
                  }}
                >
                  {loadingCreateAgenda && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </React.Fragment>
  );
};
