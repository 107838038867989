import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import '../meetings.scss';
import {
  CONCLUDE_MEETING,
  FIND_MEETING_BY_ID,
} from '~/Store/graphQL/MeetingsManagement';
import { Container, Modal } from 'reactstrap';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import { DefaultEditor } from 'react-simple-wysiwyg';
import {
  MeetingActionItemStatusEnum,
  MeetingTypeEnum,
  ModuleEnum,
} from '~/commons/enums';
import {
  FIND_ALL_COMPANY_MEMBERS,
  FIND_COMPANY_MEMBER_BY_Email,
} from '~/Store/graphQL/CompanyMembers';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
export const ConcludeMeeting: FC = () => {
  const { selectedMeetingId, meetingView } = useAppSelector(
    state => state.meetingReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [meetingType, setMeetingType] = useState<string>('');
  const [momModalVisibility, setMomModalVisibility] = useState(false);
  const [companyMembers, setCompanyMembers] = useState<any>([]);
  const [meetingActionItems, setMeetingActionItems] = useState<any>([]);
  const [absenteesEmails, setAbsenteesEmails] = useState<any>([]);
  const [attendeesEmails, setAttendeesEmails] = useState<any>([]);
  const [proxiesEmails, setProxiesEmails] = useState<any>([]);
  const [generalMoms, setGeneralMoms] = useState<any>([]);
  const [proxies, setProxies] = useState<any>([]);
  const [currentMomText, setCurrentMomText] = useState<string>('');
  const [expandedRow, setExpandedRow] = useState<number>(-1);
  const [generalMomExpandedRow, setGeneralMomExpandedRow] =
    useState<number>(-1);
  const [meetingInvitees, setMeetingInvitees] = useState<any>([]);
  const [emailOfProxy, setEmailOfProxy] = useState<string>('');
  const [proxyHolder, setProxyHolder] = useState<string>('');
  const [emailOfProxyError, setEmailOfProxyError] = useState<string>('');
  const [proxyHolderError, setProxyHolderError] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [sendPdfReport, setSendPdfReport] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/meetings',
          title: 'Meeting',
        },
        {
          url: location.pathname,
          title: 'Conclude Meeting',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.MEETING,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.MEETING,
    },
    fetchPolicy: 'no-cache',
  });
  // Getting meeting information
  const [
    findMeetingById,
    {
      loading: loadingFindMeetingById,
      error: errorFindMeetingById,
      data: dataFindMeetingById,
    },
  ] = useLazyQuery<any>(FIND_MEETING_BY_ID);
  //   conclude meeting
  const [
    concludeMeeting,
    {
      loading: loadingConcludeMeeting,
      error: errorConcludeMeeting,
      data: dataConcludeMeeting,
    },
  ] = useMutation<any>(CONCLUDE_MEETING);
  // Find all company members for proxy holders drop down
  const [
    findAllCompanyMembers,
    {
      loading: loadingFindllCompanyMembers,
      error: errorFindAllCompanyMembers,
      data: dataFindAllCompanyMembers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_MEMBERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // Getting company member information
  const [
    findCompanyMemberByEmail,
    {
      loading: loadingFindCompanyMemberByEmail,
      error: errorFindCompanyMemberByEmail,
      data: dataFindCompanyMemberByEmail,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_Email);
  useEffect(() => {
    if (companyID) {
      findAllCompanyMembers();
    }
  }, [companyID]);
  useEffect(() => {
    if (dataFindAllCompanyMembers) {
      setCompanyMembers(
        dataFindAllCompanyMembers?.findAllCompanyMembers?.edges
      );
    } else if (errorFindAllCompanyMembers) {
      console.log('errorFindAllCompanyMembers', errorFindAllCompanyMembers);
      dispatch(setToastError(errorFindAllCompanyMembers));
    }
  });
  useEffect(() => {
    if (selectedMeetingId) {
      findMeetingById({
        variables: {
          id: selectedMeetingId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMeetingId]);
  useEffect(() => {
    if (dataFindMeetingById) {
      let tempActionItems: any = [];
      dataFindMeetingById?.findOneMeeting?.meeting.meetingActionItems.forEach(
        (element: any) => {
          delete element['__typename'];
          delete element.actionItem['__typename'];
          element.actionItemId = element.id;
          element.status = MeetingActionItemStatusEnum.PENDING;
          delete element['id'];
          tempActionItems.push(element);
        }
      );
      setMeetingActionItems(tempActionItems);
      setMeetingType(dataFindMeetingById?.findOneMeeting?.meeting?.meetingType);
      setMeetingInvitees(
        dataFindMeetingById?.findOneMeeting?.meeting?.invitees.split(';')
      );
      setAbsenteesEmails(
        dataFindMeetingById?.findOneMeeting?.meeting?.invitees.split(';')
      );
    } else if (errorFindMeetingById) {
      console.log('errorFindMeetingById:', errorFindMeetingById);
    }
  }, [dataFindMeetingById, errorFindMeetingById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      attendees: attendeesEmails ? attendeesEmails.join(';') : '',
      absentees: absenteesEmails ? absenteesEmails.join(';') : '',
      proxies: proxiesEmails ? proxiesEmails.join(';') : '',
    },
    validationSchema: Yup.object({
      attendees: Yup.string().required('Please enter attendees').nullable(),
    }),
    onSubmit: (values: any) => {
      meetingActionItems.forEach((element: any) => {
        element.id = element.meetingActionItemId;
        delete element['meetingActionItemId'];
      });
      concludeMeeting({
        variables: {
          meetingId: selectedMeetingId,
          attendees: attendeesEmails.join(';'),
          absentees: absenteesEmails.join(';'),
          proxies: proxies.length > 0 ? JSON.stringify(proxies) : null,
          mom: meetingActionItems,
          generalMom: generalMoms,
          sendPdfReport: sendPdfReport,
        },
      })
        .then((res: any) => {
          if (res?.data?.concludeMeeting?.meeting) {
            dispatch(setToastSuccess('Meeting concluded!'));
            navigate(`/meetings`);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });
  const toggleMomModal = () => {
    setMomModalVisibility(!momModalVisibility);
  };
  return (
    <React.Fragment>
      {loadingFindMeetingById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 dashed-border">
          <div className="row background-white border-bottom">
            <div className="col-12 border-right">
              <div className="row selected-row-border">
                <div className="col-12 my-3">
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      className="btn primaryButton d-flex align-items-center mx-3 fw-bold"
                      disabled={
                        loadingConcludeMeeting || loadingFindMeetingById
                      }
                      onMouseDown={() => {
                        navigate('/meetings');
                      }}
                    >
                      Cancel
                    </button>
                    {!meetingView ? (
                      <button
                        type="button"
                        className="btn successButton d-flex align-items-center fw-bold"
                        disabled={
                          loadingConcludeMeeting || loadingFindMeetingById
                        }
                        onMouseDown={() => {
                          setSendPdfReport(false);
                          validation.handleSubmit();
                        }}
                      >
                        {(loadingConcludeMeeting || loadingFindMeetingById) && (
                          <i className="bx bx-loader-circle bx-spin me-1" />
                        )}
                        {'Save'}
                        <i className="bx bx-upload mx-1" />
                      </button>
                    ) : null}
                    {!meetingView ? (
                      <button
                        type="button"
                        className="btn successButton d-flex align-items-center fw-bold mx-3"
                        disabled={
                          loadingConcludeMeeting || loadingFindMeetingById
                        }
                        onMouseDown={() => {
                          setSendPdfReport(true);
                          validation.handleSubmit();
                        }}
                      >
                        {(loadingConcludeMeeting || loadingFindMeetingById) && (
                          <i className="bx bx-loader-circle bx-spin me-1" />
                        )}
                        {'Save & Send'}
                        <i className="bx bx-upload mx-1" />
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row background-white">
            <div className="col-5 inputFields border-right">
              <div className="row my-3 ms-2">
                <div className="col-12">
                  <div className="m-2">
                    <div className="formGroupHeading">Add Info</div>
                    <hr className="formGroupHeadingUnderline"></hr>
                  </div>
                </div>
                <div className="row border-div">
                  <div className="col-12 pe-0 mt-3">
                    <div className="background-primary-light p-2">Invitees</div>
                    {meetingInvitees?.map((invitee: any, index: number) => (
                      <>
                        <div className="row" key={index}>
                          {meetingInvitees[index] ? (
                            <div className="col-12 mt-2">
                              <div className="row">
                                <div className="col-2">
                                  <input
                                    className="ms-1 form-check-input"
                                    type="checkbox"
                                    id={'isVirtualMeeting'}
                                    name={'isVirtualMeeting'}
                                    value={invitee}
                                    disabled={meetingView}
                                    onChange={e => {
                                      if (e.target.checked === true) {
                                        let tempAttendees = [
                                          ...attendeesEmails,
                                        ];
                                        tempAttendees.push(e.target.value);
                                        setAttendeesEmails(tempAttendees);
                                        let tempAbsentees = [
                                          ...absenteesEmails,
                                        ];
                                        var index = tempAbsentees.indexOf(
                                          e.target.value
                                        );
                                        if (index !== -1) {
                                          tempAbsentees.splice(index, 1);
                                        }
                                        setAbsenteesEmails(tempAbsentees);
                                      } else {
                                        let tempAttendees = [
                                          ...attendeesEmails,
                                        ];
                                        var index = tempAttendees.indexOf(
                                          e.target.value
                                        );
                                        if (index !== -1) {
                                          tempAttendees.splice(index, 1);
                                        }
                                        setAttendeesEmails(tempAttendees);
                                        let tempAbsentees = [
                                          ...absenteesEmails,
                                        ];
                                        tempAbsentees.push(e.target.value);
                                        setAbsenteesEmails(tempAbsentees);
                                      }
                                    }}
                                  ></input>
                                </div>
                                <div className="col-10 p-0">
                                  {meetingInvitees[index]}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {/* {meetingInvitees[index + 1] ? (
                              <div className="col-lg-12 col-xxl-6 mt-2">
                                <div className="row">
                                  <div className="col-2">
                                    <input
                                      className="mx-3 form-check-input"
                                      type="checkbox"
                                      id={'isVirtualMeeting'}
                                      name={'isVirtualMeeting'}
                                      value={meetingInvitees[index + 1]}
                                      disabled={meetingView}
                                      onChange={e => {
                                        if (e.target.checked === true) {
                                          let tempAttendees = [
                                            ...attendeesEmails,
                                          ];
                                          tempAttendees.push(e.target.value);
                                          setAttendeesEmails(tempAttendees);
                                          let tempAbsentees = [
                                            ...absenteesEmails,
                                          ];
                                          var index = tempAbsentees.indexOf(
                                            e.target.value
                                          );
                                          if (index !== -1) {
                                            tempAbsentees.splice(index, 1);
                                          }
                                          setAbsenteesEmails(tempAbsentees);
                                        } else {
                                          let tempAttendees = [
                                            ...attendeesEmails,
                                          ];
                                          var index = tempAttendees.indexOf(
                                            e.target.value
                                          );
                                          if (index !== -1) {
                                            tempAttendees.splice(index, 1);
                                          }
                                          setAttendeesEmails(tempAttendees);
                                          let tempAbsentees = [
                                            ...absenteesEmails,
                                          ];
                                          tempAbsentees.push(e.target.value);
                                          setAbsenteesEmails(tempAbsentees);
                                        }
                                      }}
                                    ></input>
                                  </div>
                                  <div className="col-10">
                                    {meetingInvitees[index + 1]}
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                        </div>
                        <hr className="m-1"></hr>
                      </>
                    ))}
                  </div>
                  <div className="col-12 pe-0 mt-4">
                    <div className="form-group">
                      <div className="d-flex w-100">
                        <label htmlFor="attendees">Attendees*</label>
                        {validation.touched.attendees &&
                        validation.errors.attendees ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.attendees}
                          </p>
                        ) : null}
                      </div>
                      <ReactMultiEmail
                        placeholder="Attendees"
                        className={`multiple_emails-container bg-transparent ${
                          meetingView ? 'pointer-none' : ''
                        }`}
                        emails={attendeesEmails}
                        onChange={(_emails: string[]) => {
                          setAttendeesEmails(_emails);
                          let stringifyEmails = _emails.join(';');
                          validation.setFieldValue(
                            'attendees',
                            stringifyEmails
                          );
                        }}
                        validateEmail={email => {
                          let attendeesEmailArray = attendeesEmails.split(';');
                          var index = attendeesEmailArray.findIndex(
                            (x: any) => x === email
                          );
                          if (index >= 0) {
                            dispatch(setToastError('Email already added'));
                          }
                          return isEmail(email);
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 pe-0 mb-3">
                    <div className="form-group">
                      <div className="d-flex w-100">
                        <label htmlFor="absentees">Absentees</label>
                        {validation.touched.absentees &&
                        validation.errors.absentees ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.absentees}
                          </p>
                        ) : null}
                      </div>
                      <ReactMultiEmail
                        placeholder="Absentees"
                        className={`multiple_emails-container bg-transparent ${
                          meetingView ? 'pointer-none' : ''
                        }`}
                        emails={absenteesEmails}
                        onChange={(_emails: string[]) => {
                          setAbsenteesEmails(_emails);
                          let stringifyEmails = _emails.join(';');
                          validation.setFieldValue(
                            'absentees',
                            stringifyEmails
                          );
                        }}
                        validateEmail={email => {
                          let absenteesEmailArray = absenteesEmails.split(';');
                          var index = absenteesEmailArray.findIndex(
                            (x: any) => x === email
                          );
                          if (index >= 0) {
                            dispatch(setToastError('Email already added'));
                          }
                          return isEmail(email);
                        }}
                        getLabel={(
                          email: string,
                          index: number,
                          removeEmail: (index: number) => void
                        ) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              ></span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                {meetingType === MeetingTypeEnum.GENERAL_MEETING ? (
                  <>
                    <div className="row pe-0 mt-4">
                      <div className="row">
                        <label>Proxies</label>
                      </div>
                      <div className="col-lg-12 col-xxl-5 d-flex justify-content-center align-items-center">
                        <div
                          className="form-group"
                          style={{ minWidth: '100%' }}
                        >
                          <div className="d-flex">
                            {emailOfProxyError ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {emailOfProxyError}
                              </p>
                            ) : null}
                          </div>
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <select
                              className={`form-control w-100 ${
                                emailOfProxyError && 'is-invalid'
                              }
                            `}
                              id="emailOfProxy"
                              name="emailOfProxy"
                              placeholder="Email of proxy"
                              value={emailOfProxy}
                              onChange={e => {
                                setEmailOfProxyError('');
                                setEmailOfProxy(e.target.value);
                              }}
                            >
                              <option value="">Email of proxy</option>
                              {absenteesEmails?.map(
                                (absentee: any, index: number) => (
                                  <option value={absentee} key={index}>
                                    {absentee}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xxl-5 d-flex justify-content-center align-items-center">
                        <div
                          className="form-group"
                          style={{ minWidth: '100%' }}
                        >
                          <div className="d-flex">
                            {proxyHolderError ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {proxyHolderError}
                              </p>
                            ) : null}
                          </div>
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <select
                              className={`form-control w-100 ${
                                proxyHolderError && 'is-invalid'
                              }
                            `}
                              id="proxyHolder"
                              name="proxyHolder"
                              placeholder="Email of proxy"
                              value={proxyHolder}
                              onChange={e => {
                                setProxyHolderError('');
                                setProxyHolder(e.target.value);
                              }}
                            >
                              <option value="">Proxy holder</option>
                              {companyMembers?.map(
                                (member: any, index: number) => (
                                  <option
                                    value={member?.node?.email}
                                    key={index}
                                  >
                                    {member?.node?.email}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xxl-2">
                        <div
                          className="d-flex justify-content-center align-items-center upload-danger-button cursor-pointer"
                          onClick={e => {
                            if (!emailOfProxy && !proxyHolder) {
                              setEmailOfProxyError(
                                'Please select email of proxy'
                              );
                              setProxyHolderError('Please enter proxy holder');
                            } else if (!emailOfProxy) {
                              setEmailOfProxyError(
                                'Please select email of proxy'
                              );
                            } else if (!proxyHolder) {
                              setProxyHolderError('Please enter proxy holder');
                            } else {
                              let tempProxies = [...proxies];
                              let proxyObj = {
                                emailOfProxy: emailOfProxy,
                                nameOfProxy: '',
                                folioNoOfProxy: '',
                                emailOfProxyHolder: proxyHolder,
                                nameOfProxyHolder: '',
                                folioNoOfProxyHolder: '',
                              };
                              findCompanyMemberByEmail({
                                variables: {
                                  email: emailOfProxy,
                                },
                                fetchPolicy: 'no-cache',
                              })
                                .then((res: any) => {
                                  if (res) {
                                    proxyObj.nameOfProxy =
                                      res?.data?.findOneCompanyMember
                                        ?.companyMember?.firstName +
                                      ' ' +
                                      res?.data?.findOneCompanyMember
                                        ?.companyMember?.lastName;
                                    proxyObj.folioNoOfProxy =
                                      res?.data?.findOneCompanyMember?.companyMember?.folioNo;
                                    let companyMember = companyMembers?.find(
                                      (member: any) => {
                                        return (
                                          member?.node?.email === proxyHolder
                                        );
                                      }
                                    );
                                    proxyObj.nameOfProxyHolder =
                                      companyMember?.node?.firstName +
                                      ' ' +
                                      companyMember?.node?.lastName;
                                    proxyObj.folioNoOfProxyHolder =
                                      companyMember?.node?.folioNo;
                                    tempProxies.push(proxyObj);
                                    setProxies(tempProxies);
                                    setEmailOfProxy('');
                                    setProxyHolder('');
                                    setEmailOfProxyError('');
                                    setProxyHolderError('');
                                  }
                                })
                                .catch(err => {
                                  let companyMember = companyMembers?.find(
                                    (member: any) => {
                                      return (
                                        member?.node?.email === proxyHolder
                                      );
                                    }
                                  );
                                  proxyObj.nameOfProxyHolder =
                                    companyMember?.node?.firstName +
                                    ' ' +
                                    companyMember?.node?.lastName;
                                  proxyObj.folioNoOfProxyHolder =
                                    companyMember?.node?.folioNo;
                                  tempProxies.push(proxyObj);
                                  setProxies(tempProxies);
                                  setEmailOfProxy('');
                                  setProxyHolder('');
                                  setEmailOfProxyError('');
                                  setProxyHolderError('');
                                });
                            }
                          }}
                        >
                          <i className="bx bx-upload text-primary bx-md"></i>
                        </div>
                      </div>
                    </div>

                    <div className="row pe-0 mt-4">
                      <div className="col-6 background-primary-light p-2 ps-3">
                        Email of proxy
                      </div>
                      <div className="col-6 background-primary-light p-2">
                        Proxy Holder
                      </div>
                      {proxies?.map((proxy: any, index: number) => (
                        <>
                          <div className="row" key={index}>
                            <div className="col-6 mt-2">
                              <ul>
                                <li>{proxy.emailOfProxy}</li>
                              </ul>
                            </div>
                            <div className="col-6 mt-2">
                              <ul>
                                <li>{proxy.emailOfProxyHolder}</li>
                              </ul>
                            </div>
                          </div>
                          <hr className="m-1"></hr>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-7">
              <div className="row my-3 ms-2 me-0">
                <div className="col-12">
                  <div className="m-2">
                    <div className="formGroupHeading">
                      Add Minutes Of Meeting
                    </div>
                    <hr className="formGroupHeadingUnderline"></hr>
                  </div>
                </div>
                {meetingActionItems?.map((actionItem: any, index: number) => (
                  <div className="col-12 border-div mt-3 py-2 me-0" key={index}>
                    <div className="row">
                      <div className="col-11">
                        <div>{actionItem?.actionItem}</div>
                        <div className="mt-2 d-flex justify-content-between w-75">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`actionItemStatus+${index}`}
                              id="actionItemStatus"
                              defaultChecked={true}
                              onChange={e => {
                                if (e.target.checked) {
                                  let tempMeetingActionItems = [
                                    ...meetingActionItems,
                                  ];
                                  tempMeetingActionItems[index].status =
                                    MeetingActionItemStatusEnum.PENDING;
                                  setMeetingActionItems(tempMeetingActionItems);
                                }
                              }}
                            ></input>
                            <label className="form-check-label">Pending</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`actionItemStatus+${index}`}
                              id="actionItemStatus"
                              onChange={e => {
                                if (e.target.checked) {
                                  let tempMeetingActionItems = [
                                    ...meetingActionItems,
                                  ];
                                  tempMeetingActionItems[index].status =
                                    MeetingActionItemStatusEnum.APPROVED;
                                  setMeetingActionItems(tempMeetingActionItems);
                                }
                              }}
                            ></input>
                            <label className="form-check-label">Approved</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`actionItemStatus+${index}`}
                              id="actionItemStatus"
                              onChange={e => {
                                if (e.target.checked) {
                                  let tempMeetingActionItems = [
                                    ...meetingActionItems,
                                  ];
                                  tempMeetingActionItems[index].status =
                                    MeetingActionItemStatusEnum.REJECTED;
                                  setMeetingActionItems(tempMeetingActionItems);
                                }
                              }}
                            ></input>
                            <label className="form-check-label">Rejected</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-1 d-flex justify-content-center align-items-center">
                        <i
                          className={`bx bx-sm ${
                            expandedRow === index
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          } mx-2 my-1 cursor-pointer`}
                          onClick={() => {
                            expandedRow === index
                              ? setExpandedRow(-1)
                              : setExpandedRow(index);
                          }}
                        ></i>
                      </div>
                    </div>
                    {expandedRow === index ? (
                      <div className="row">
                        <div className="col-12 my-2 d-flex justify-content-end align-items-center">
                          <button
                            type="button"
                            className="btn primary-btn-new d-flex align-items-center mx-3 fw-bold"
                            disabled={
                              loadingConcludeMeeting || loadingFindMeetingById
                            }
                            onMouseDown={() => {
                              setExpandedRow(-1);
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-12">
                          <ul className="nav nav-tabs">
                            <li
                              className="nav-item cursor-pointer"
                              onClick={e => {
                                setSelectedTab(1);
                              }}
                            >
                              <a
                                className={`nav-link ${
                                  selectedTab === 1 && 'active'
                                }`}
                              >
                                Minutes of Meeting
                              </a>
                            </li>
                            <li
                              className="nav-item cursor-pointer"
                              onClick={e => {
                                setSelectedTab(2);
                              }}
                            >
                              <a
                                className={`nav-link ${
                                  selectedTab === 2 && 'active'
                                }`}
                              >
                                Resolution
                              </a>
                            </li>
                          </ul>
                        </div>
                        {selectedTab === 1 ? (
                          <div className="col-12">
                            <DefaultEditor
                              value={actionItem?.mom}
                              onChange={e => {
                                let tempMeetingActionItems = [
                                  ...meetingActionItems,
                                ];
                                tempMeetingActionItems[index].mom =
                                  e.target.value.replaceAll('&nbsp;', ' ');
                                setMeetingActionItems(tempMeetingActionItems);
                              }}
                            />
                          </div>
                        ) : null}
                        {selectedTab === 2 ? (
                          <div className="col-12">
                            <DefaultEditor
                              value={actionItem?.resolution}
                              onChange={e => {
                                let tempMeetingActionItems = [
                                  ...meetingActionItems,
                                ];
                                tempMeetingActionItems[index].resolution =
                                  e.target.value.replaceAll('&nbsp;', ' ');
                                setMeetingActionItems(tempMeetingActionItems);
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ))}
                {generalMoms?.map((generalMom: any, index: number) => (
                  <div className="col-12 border-div mt-3 py-2 me-0" key={index}>
                    <div className="row">
                      <div className="col-11">Minutes Of Meeting</div>
                      <div className="col-1 d-flex justify-content-center mt-1">
                        <i
                          className={`bx ${
                            generalMomExpandedRow === index
                              ? 'bx-chevron-up'
                              : 'bx-chevron-down'
                          } mx-2 my-1 cursor-pointer`}
                          onClick={() => {
                            generalMomExpandedRow === index
                              ? setGeneralMomExpandedRow(-1)
                              : setGeneralMomExpandedRow(index);
                          }}
                        ></i>
                      </div>
                    </div>
                    {generalMomExpandedRow === index ? (
                      <div className="row">
                        <div className="col-12 my-2 d-flex justify-content-end align-items-center">
                          <button
                            type="button"
                            className="btn primary-btn-new d-flex align-items-center mx-3 fw-bold"
                            disabled={
                              loadingConcludeMeeting || loadingFindMeetingById
                            }
                            onMouseDown={() => {
                              setGeneralMomExpandedRow(-1);
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-12">
                          <DefaultEditor
                            value={generalMom}
                            onChange={e => {
                              let tempGeneralMom = [...generalMom];
                              tempGeneralMom[index] = e.target.value.replaceAll(
                                '&nbsp;',
                                ' '
                              );
                              setGeneralMoms(tempGeneralMom);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center mx-3 fw-bold"
                    disabled={loadingConcludeMeeting || loadingFindMeetingById}
                    onMouseDown={() => {
                      setCurrentMomText('');
                      setMomModalVisibility(true);
                    }}
                  >
                    Add More Minutes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        size="lg"
        isOpen={momModalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bxs-file-blank text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Add Minutes Of Meeting
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row min-height-40">
              <div className="col-12 mom-modal">
                <DefaultEditor
                  value={currentMomText}
                  onChange={e => {
                    setCurrentMomText(e.target.value.replaceAll('&nbsp;', ' '));
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  disabled={loadingConcludeMeeting || loadingFindMeetingById}
                  onMouseDown={() => {
                    let tempGeneralMom = [...generalMoms];
                    tempGeneralMom.push(currentMomText);
                    setGeneralMoms(tempGeneralMom);
                    setMomModalVisibility(false);
                  }}
                >
                  {(loadingConcludeMeeting || loadingFindMeetingById) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
