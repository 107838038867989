import { createSlice } from '@reduxjs/toolkit';
import { BreadCrumb, LayoutType } from './types';

const initialState: LayoutType = {
  isPreloader: false,
  screen: 'Dashboard',
  breadCrumb: [] as BreadCrumb[],
  userType: 0,
  userId: '',
  sidebar: 'main',
  token: '',
  toastSuccess: '',
  toastError: '',
  rightSidebar: true,
  documentTab: 1,
  isOpen: -1,
};
const loaderSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setPreloader: (state, action) => {
      state.isPreloader = action.payload;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setBreadCrumb: (state, action) => {
      state.breadCrumb = action.payload;
    },
    setToastSuccess: (state, action) => {
      state.toastSuccess = action.payload;
    },
    setToastError: (state, action) => {
      state.toastError = action.payload;
    },
    setRightSidebar: (state, action) => {
      state.rightSidebar = action.payload;
    },
    setDocumentTab: (state, action) => {
      state.documentTab = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsOpen(state,action){
      state.isOpen = action.payload
    },
    clearStore(state) {
      state.token = '';
    },
  },
});

export const {
  clearStore,
  setPreloader,
  setScreen,
  setSidebar,
  setToken,
  setBreadCrumb,
  setToastSuccess,
  setToastError,
  setRightSidebar,
  setDocumentTab,
  setUserType,
  setUserId,
  setIsOpen,
} = loaderSlice.actions;

export default loaderSlice.reducer;
