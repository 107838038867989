import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { memo, useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import {
  FIND_COMPANY_PROFILE_BY_USER_ID,
  GET_ALL_AUTHORIZED_CAPITAL_hISTORY,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_DRAFT,
} from '~/Store/graphQL/CompanyProfile';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { CompanyAuthorizedCapitalsInput } from '../types';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import {
  ModuleEnum,
  ShareSubTypeEnum,
  ShareTypeEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { Container, Modal } from 'reactstrap';
import moment from 'moment';
import GetShareType from '~/helpers/Share/GetShareType';
import GetShareSubType from '~/helpers/Share/GetShareSubType';

export const AdvancedCapitalHistory: FC = () => {
  const { companyID, companyView } = useAppSelector(
    state => state.companyProfileReducer
  );
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [products, setProducts] = useState<
    CompanyAuthorizedCapitalsInput[] | undefined
  >();
  const [totalShares, setTotalShares] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [modalVisibility, setmodalVisibility] = useState(false);
  const [authorizedCapitalId, setauthorizedCapitalId] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const [tempProducts, setTempProducts] = useState<
    CompanyAuthorizedCapitalsInput[] | undefined
  >();

  useEffect(() => {
    if (products) {
      let amount = 0;
      let shares = 0;
      for (let i = 0; i < products?.length; i++) {
        let element = products[i];

        if (element?.totalAmount && element?.numOfShares) {
          amount += element?.totalAmount;
          shares += element?.numOfShares;
        }
      }
      setTotalAmount(amount);
      setTotalShares(shares);
    }
  }, [products]);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Capital Structure',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);

  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(UPDATE_COMPANY_PROFILE);
  const [
    draftUpdateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(UPDATE_COMPANY_PROFILE_DRAFT);
  const [
    getAuthorizedCapitalHistroyData,
    {
      loading: loadingAuthorizedHistory,
      error: errorAuhtorizedHistory,
      data: dataAuthorizedHistory,
    },
  ] = useLazyQuery<any>(GET_ALL_AUTHORIZED_CAPITAL_hISTORY, {
    variables: {
      authorizedCapitalId: authorizedCapitalId,
      first: itemsPerPage,
    },
  });
  useEffect(() => {
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [userId]);

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      setProducts(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
          .companyAuthorizedCapitals
      );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );
    } else if (errorCompanyProfileByUserId) {
      console.log('errorCompanyProfileByUserId:', errorCompanyProfileByUserId);
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
      setEditableRow(-1);
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);

  useEffect(() => {
    if (dataupdateCompanyProfile && !errorupdateCompanyProfile) {
      setEditableRow(-1);
    } else if (errorupdateCompanyProfile) {
      dispatch(setToastError(errorupdateCompanyProfile));
    }
  }, [errorupdateCompanyProfile, dataupdateCompanyProfile]);

  useEffect(() => {
    if (modalVisibility && authorizedCapitalId) {
      getAuthorizedCapitalHistroyData({
        fetchPolicy: 'no-cache',
      });
    }
  }, [modalVisibility, authorizedCapitalId, itemsPerPage]);

  const toggleMomModal = () => {
    setmodalVisibility(!modalVisibility);
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      authorizedCapitalShare: totalShares ? totalShares : undefined,
      authorizedCapitalTotal: totalAmount ? totalAmount : undefined,
    },
    validationSchema: Yup.object({
      authorizedCapitalShare: Yup.string().required('Please fill').nullable(),
      authorizedCapitalTotal: Yup.string().required('Please fill').nullable(),
    }),
    onSubmit: values => {
      if (isDraft) {
        draftUpdateCompanyProfile({
          variables: {
            id: companyID,
            authorizedCapitalShare: Number(values.authorizedCapitalShare),
            authorizedCapitalTotal: Number(values.authorizedCapitalTotal),
          },
        })
          .then((res: any) => {
            if (res.data.draftUpdateProfileCompanyDraft.draftCompanyProfile) {
              dispatch(setToastSuccess('Company profile draft updated!'));
              navigate('/companyProfile/paidUpCapitalHistory');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyProfile({
          variables: {
            id: companyID,
            authorizedCapitalShare: Number(values.authorizedCapitalShare),
            authorizedCapitalTotal: Number(values.authorizedCapitalTotal),
          },
        })
          .then(res => {
            if (res.data.updateCompanyProfile.companyProfile) {
              dispatch(setToastSuccess('Company profile updated!'));
              navigate('/companyProfile/paidUpCapitalHistory');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3 end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  {companyView ? 'Close' : 'Cancel'}
                </button>
                <button
                  type="button"
                  className="btn fw-bold primaryDismissButton d-flex align-items-center "
                  onMouseDown={() => {
                    navigate('/companyProfile/companyAddressHistory');
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onMouseDown={() => {
                    companyView
                      ? navigate('/companyProfile/paidUpCapitalHistory')
                      : validation.handleSubmit();
                  }}
                >
                  {(loadingCompanyDraft || loadingupdateCompanyProfile) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  {companyView ? 'Next' : 'Save & Next'}
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </span>
        </div>
        {/*  */}
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold ">
                {isDraft ? (
                  <a className="nav-link ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link active">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link active cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link cursor-pointer "
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        {/*  */}
        <div className="c-subnav">
          <span>
            <ul className="nav nav-tabs w-100">
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link active cursor-pointer">
                    Authorized Capital History
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link  active cursor-pointer"
                  >
                    Authorized Capital History
                  </a>
                )}
              </li>
              <li className="nav-item border-end flex-grow-1 text-center">
                {isDraft ? (
                  <a className="nav-link  cursor-pointer">
                    Paid Up Capital History
                  </a>
                ) : (
                  <a
                    className="nav-link  cursor-pointer"
                    onClick={() => {
                      navigate('/companyProfile/paidUpCapitalHistory');
                    }}
                  >
                    Paid Up Capital History
                  </a>
                )}
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        <div id="mainComponentNew">
          {loadingCompanyProfileByUserId ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <div className="w-100" style={{ display: 'contents' }}>
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div style={{ width: 'inherit' }}>
                <AdvancedCapitalFields
                  isDraft={isDraft}
                  companyID={companyID}
                  companyView={companyView}
                  validation={validation}
                  totalAmount={totalAmount}
                  totalShares={totalShares}
                />
                <div className="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Share Type</th>
                        <th>Share Sub Type</th>
                        <th>No. of Shares</th>
                        <th>Issue Price</th>
                        <th>Total Amount</th>
                        {/* <th>Form No.</th> */}
                        <th>SECP Ref. ID</th>
                        {!companyView && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((val: any, index: number) => (
                        <tr key={`${index}`}>
                          <td className="border-end">
                            <div className="badge custom-badge-primary">
                              {index + 1}
                            </div>
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-success">
                              {editableRow === index ? (
                                <input
                                  className=""
                                  value={
                                    val.date
                                      ? new Date(val.date)
                                          .toISOString()
                                          .slice(0, 10)
                                      : val.date
                                  }
                                  type="date"
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].date = value;
                                    setProducts(temp);
                                  }}
                                />
                              ) : (
                                val.date &&
                                new Date(val.date).toISOString().slice(0, 10)
                              )}
                            </div>
                          </td>
                          <td className="border-end">
                            {editableRow === index ? (
                              <div className="d-flex justify-content-center">
                                <select
                                  className="form-control p-1"
                                  value={val.shareType}
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].shareType = value;
                                    setProducts(temp);
                                  }}
                                >
                                  <option value="">Please Select</option>
                                  <option
                                    value={ShareTypeEnum.PREFERENCE_SHARE}
                                  >
                                    Preference Share
                                  </option>
                                  <option value={ShareTypeEnum.ORDINARY_SHARE}>
                                    Ordinary
                                  </option>
                                </select>
                              </div>
                            ) : val.shareType ? (
                              GetShareType(val.shareType)
                            ) : (
                              'N/A'
                            )}
                          </td>
                          <td className="border-end">
                            {editableRow === index ? (
                              <div className="d-flex justify-content-center">
                                <select
                                  className="form-control p-1"
                                  value={val.shareSubType}
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].shareSubType = value;
                                    setProducts(temp);
                                  }}
                                >
                                  <option value="">Please Select</option>
                                  {val.shareType ==
                                  ShareTypeEnum.PREFERENCE_SHARE ? (
                                    <>
                                      <option
                                        value={
                                          ShareSubTypeEnum.PARTICIPATING_ACCUMULATIVE
                                        }
                                      >
                                        Participating Accumulative
                                      </option>
                                      <option
                                        value={
                                          ShareSubTypeEnum.NON_PARTICIPATING_PREFERENCE
                                        }
                                      >
                                        Non Participating Accumulative
                                      </option>
                                      <option
                                        value={
                                          ShareSubTypeEnum.PARTICIPATING_PREFERENCE
                                        }
                                      >
                                        Participating Preference
                                      </option>
                                      <option
                                        value={
                                          ShareSubTypeEnum.PARTICIPATING_NON_ACCUMULATIVE
                                        }
                                      >
                                        Participating Non Preference
                                      </option>
                                      <option
                                        value={ShareSubTypeEnum.CLASS_1A_SHARE}
                                      >
                                        Class 1/A Share
                                      </option>
                                      <option
                                        value={ShareSubTypeEnum.CLASS_2B_SHARE}
                                      >
                                        Class 2/B Share
                                      </option>
                                    </>
                                  ) : val.shareType ==
                                    ShareTypeEnum.ORDINARY_SHARE ? (
                                    <>
                                      <option value={ShareSubTypeEnum.OTHER}>
                                        Other
                                      </option>
                                    </>
                                  ) : null}
                                </select>
                              </div>
                            ) : val.shareSubType ? (
                              GetShareSubType(val.shareSubType)
                            ) : (
                              'N/A'
                            )}
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-primary">
                              {editableRow === index ? (
                                <input
                                  className="editableField"
                                  value={val.numOfShares}
                                  type="number"
                                  onKeyDown={evt =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].numOfShares = Number(value);
                                    setProducts(temp);
                                  }}
                                />
                              ) : (
                                val.numOfShares
                              )}
                            </div>
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-success">
                              {editableRow === index ? (
                                <input
                                  className="editableField"
                                  value={val.issuePrice}
                                  type="number"
                                  onKeyDown={evt =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].issuePrice = Number(value);
                                    setProducts(temp);
                                  }}
                                />
                              ) : (
                                val.issuePrice
                              )}
                            </div>
                          </td>
                          <td className="border-end">
                            <div className="badge custom-badge-primary">
                              {editableRow === index ? (
                                <input
                                  className="editableField"
                                  value={val.totalAmount}
                                  type="number"
                                  onKeyDown={evt =>
                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].totalAmount = Number(value);
                                    setProducts(temp);
                                  }}
                                />
                              ) : (
                                val.totalAmount
                              )}
                            </div>
                          </td>
                          {/* <td className="border-end">
                            <div className="badge custom-badge-success">
                              {editableRow === index ? (
                                <input
                                  className="editableField"
                                  value={val.formNumber}
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].formNumber = value;
                                    setProducts(temp);
                                  }}
                                />
                              ) : val?.formNumber ? (
                                val.formNumber
                              ) : (
                                'N/A'
                              )}
                            </div>
                          </td> */}
                          <td className="border-end">
                            <div className="badge custom-badge-primary">
                              {editableRow === index ? (
                                <input
                                  className="editableField"
                                  value={val.secpRefId}
                                  onChange={e => {
                                    const { value } = e.target;
                                    const temp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    temp[index].secpRefId = value;
                                    setProducts(temp);
                                  }}
                                />
                              ) : val?.secpRefId ? (
                                val.secpRefId
                              ) : (
                                'N/A'
                              )}
                            </div>
                          </td>
                          {!companyView && (
                            <td className="editColumn">
                              {editableRow !== index ? (
                                <>
                                  <i
                                    className={`bx bx-show ${
                                      editableRow === -1
                                        ? 'text-primary'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        setmodalVisibility(true);
                                        setauthorizedCapitalId(val?.id);
                                        setSelectedIndex(index);
                                      }
                                    }}
                                  />
                                  <i
                                    className={`bx bxs-edit ${
                                      editableRow === -1
                                        ? 'text-primary'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        setEditableRow(index);
                                        setTempProducts(products);
                                      }
                                    }}
                                  />
                                  <i
                                    className={`bx bxs-trash-alt ${
                                      editableRow === -1
                                        ? 'text-danger'
                                        : 'text-info'
                                    }`}
                                    onClick={() => {
                                      if (editableRow === -1) {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            let temp = [...products];
                                            temp.splice(index, 1);
                                            if (isDraft) {
                                              let temp2 = JSON.parse(
                                                JSON.stringify(temp)
                                              );
                                              temp2.forEach((element: any) => {
                                                delete element['__typename'];
                                              });
                                              draftUpdateCompanyProfile({
                                                variables: {
                                                  id: companyID,
                                                  companyAuthorizedCapitalsInput:
                                                    temp2,
                                                },
                                              }).then(res => {
                                                res.data && setProducts(temp);
                                                ConfirmationDialogue.fire(
                                                  'Deleted!',
                                                  '',
                                                  'success'
                                                );
                                              });
                                            } else {
                                              let temp2 = JSON.parse(
                                                JSON.stringify(products)
                                              );
                                              temp2.forEach((element: any) => {
                                                delete element['__typename'];
                                              });
                                              temp2[index].toBeDeleted = true;
                                              updateCompanyProfile({
                                                variables: {
                                                  id: companyID,
                                                  companyAuthorizedCapitalsInput:
                                                    temp2,
                                                },
                                              }).then(res => {
                                                res.data && setProducts(temp);
                                                ConfirmationDialogue.fire(
                                                  'Deleted!',
                                                  '',
                                                  'success'
                                                );
                                              });
                                            }
                                          } else if (
                                            /* Read more about handling dismissals below */
                                            result.dismiss ===
                                            Swal.DismissReason.cancel
                                          ) {
                                            ConfirmationDialogue.fire(
                                              'Cancelled',
                                              '',
                                              'error'
                                            );
                                          }
                                        });
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <i
                                    className="bx bx-check-circle text-primary"
                                    onClick={() => {
                                      if (
                                        products[editableRow].date &&
                                        products[editableRow].shareType &&
                                        products[editableRow].shareSubType &&
                                        products[editableRow].numOfShares &&
                                        products[editableRow].issuePrice &&
                                        products[editableRow].totalAmount
                                      ) {
                                        if (isDraft) {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          draftUpdateCompanyProfile({
                                            variables: {
                                              id: companyID,
                                              companyAuthorizedCapitalsInput:
                                                temp,
                                            },
                                          });
                                        } else {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          updateCompanyProfile({
                                            variables: {
                                              id: companyID,
                                              companyAuthorizedCapitalsInput:
                                                temp,
                                            },
                                          });
                                        }
                                      } else {
                                        dispatch(
                                          setToastError(
                                            'Please enter data of all input fields!'
                                          )
                                        );
                                      }
                                    }}
                                  />
                                  <i
                                    className="bx bxs-x-circle text-danger"
                                    onClick={() => {
                                      setEditableRow(-1);
                                      setProducts(tempProducts);
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {(products?.length === 0 || products === undefined) &&
                    companyView && (
                      <div className="addDiv">
                        <h6 className="">No records found</h6>
                      </div>
                    )}
                  {!companyView && (
                    <div className="addDiv">
                      <i
                        className="bx bxs-plus-circle font-22 text-primary cursor-pointer"
                        onClick={() => {
                          if (editableRow === -1) {
                            if (products) {
                              setTempProducts(products);
                              let newProduct = [...products];
                              newProduct.push({
                                id: uuidv4(),
                                serialNumber: products.length + 1,
                                date: '',
                                shareType: '',
                                shareSubType: '',
                                numOfShares: undefined,
                                issuePrice: undefined,
                                totalAmount: undefined,
                                // formNumber: '',
                                secpRefId: '',
                              });
                              setProducts(newProduct);
                              setEditableRow(products.length);
                            } else {
                              let newProduct = [
                                {
                                  id: uuidv4(),
                                  serialNumber: 1,
                                  date: '',
                                  shareType: '',
                                  shareSubType: '',
                                  numOfShares: undefined,
                                  issuePrice: undefined,
                                  totalAmount: undefined,
                                  // formNumber: '',
                                  secpRefId: '',
                                },
                              ];
                              setProducts(newProduct);
                              setEditableRow(0);
                            }
                          } else {
                            dispatch(
                              setToastError(
                                'You have some unsaved data. Please save or discard it!'
                              )
                            );
                          }
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        size="xl"
        isOpen={modalVisibility}
        toggle={() => {
          toggleMomModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center ">
              <i className="bx bxs-file-blank text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Authorized Capital History
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMomModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loadingAuthorizedHistory ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-75">
                <i className="bx bx-loader-circle bx-spin bx-lg"></i>
              </div>
            ) : (
              <>
                <div className="table-responsive w-100">
                  <table>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Share Type</th>
                        <th>Share SubType</th>
                        <th>date</th>
                        <th>Number Of Shares</th>
                        <th>Issue Price</th>
                        <th>Total Amount</th>
                        <th>Secp RefId</th>
                        <th>Updated By</th>
                        <th>Updated At</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataAuthorizedHistory?.findHistoryOfAuthorizedCapital?.edges?.map(
                        (val: any, index: number) => (
                          <tr key={`${index}`}>
                            <td>{index + 1}</td>
                            <td
                              className={` ${
                                val.node?.shareType !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.shareType
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val.node?.shareType
                                ? GetShareType(val.node?.shareType)
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.shareSubType !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.shareSubType
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val.node?.shareSubType
                                ? GetShareSubType(val.node?.shareSubType)
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.date !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.date
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.date
                                ? moment(val.node.date).format('MMM DD, yyyy')
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.numOfShares !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.numOfShares
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.numOfShares
                                ? val?.node?.numOfShares
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.issuePrice !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.issuePrice
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.issuePrice
                                ? val?.node?.issuePrice
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.totalAmount !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.totalAmount
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.totalAmount
                                ? val?.node?.totalAmount
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.shareSubType !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.shareSubType
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.secpRefId
                                ? val?.node?.secpRefId
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                val.node?.updatedBy !==
                                dataAuthorizedHistory
                                  ?.findHistoryOfAuthorizedCapital?.edges[
                                  index + 1
                                ]?.node?.updatedBy
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.updatedBy
                                ? val?.node?.updatedBy
                                : 'N/A'}
                            </td>
                            <td
                              className={` ${
                                moment(val.node.updatedAt).format(
                                  'MMM DD, yyyy'
                                ) !==
                                moment(
                                  dataAuthorizedHistory
                                    ?.findHistoryOfAuthorizedCapital?.edges[
                                    index + 1
                                  ]?.node?.updatedAt
                                ).format('MMM DD, yyyy')
                                  ? 'text-primary'
                                  : ''
                              }`}
                            >
                              {val?.node?.updatedAt
                                ? moment(val.node.updatedAt).format(
                                    'MMM DD, yyyy'
                                  )
                                : 'N/A'}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  {(dataAuthorizedHistory?.findHistoryOfAuthorizedCapital.edges
                    ?.length === 0 ||
                    dataAuthorizedHistory?.findHistoryOfAuthorizedCapital
                      .edges === undefined) && (
                    <div className="addDiv">
                      <h6 className="">No records Found</h6>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                  <p className="m-0 text-info">Rows Per Page:</p>
                  <select
                    className="form-control button-danger text-info width-5vw"
                    value={itemsPerPage}
                    onChange={e => {
                      const { value } = e.target;
                      setItemsPerPage(Number(value));
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <i
                    className={
                      dataAuthorizedHistory?.findHistoryOfAuthorizedCapital
                        .pageInfo?.hasPreviousPage
                        ? 'bx bx-caret-left bx-sm color-primary ms-auto cursor-pointer'
                        : 'bx bx-caret-left bx-sm ms-auto'
                    }
                    onClick={() => {
                      if (
                        companyID &&
                        dataAuthorizedHistory?.findHistoryOfAuthorizedCapital
                          .pageInfo?.hasPreviousPage
                      ) {
                        getAuthorizedCapitalHistroyData({
                          variables: {
                            last: itemsPerPage,
                            before:
                              dataAuthorizedHistory
                                ?.findHistoryOfAuthorizedCapital.pageInfo
                                ?.startCursor,
                          },
                        });
                      }
                    }}
                  />

                  <i
                    className={
                      dataAuthorizedHistory?.findHistoryOfAuthorizedCapital
                        .pageInfo?.hasNextPage
                        ? 'bx bx-caret-right bx-sm color-primary cursor-pointer '
                        : 'bx bx-caret-right bx-sm'
                    }
                    onClick={() => {
                      if (
                        companyID &&
                        dataAuthorizedHistory?.findHistoryOfAuthorizedCapital
                          ?.pageInfo?.hasNextPage
                      ) {
                        getAuthorizedCapitalHistroyData({
                          variables: {
                            after:
                              dataAuthorizedHistory
                                ?.findHistoryOfAuthorizedCapital?.pageInfo
                                ?.endCursor,
                          },
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </Container>
      </Modal>
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
const AdvancedCapitalFields = memo(
  ({
    isDraft,
    companyID,
    companyView,
    validation,
    totalAmount,
    totalShares,
  }: any) => {
    const [
      draftUpdateCompanyProfile,
      {
        loading: loadingCompanyDraft,
        error: errorCompanyDraft,
        data: dataCompanyDraft,
      },
    ] = useMutation<any>(
      UPDATE_COMPANY_PROFILE_DRAFT
      // { errorPolicy: 'all' }
    );

    return (
      <div className="d-flex gap-3 inputFields">
        <div className="form-group">
          <div className="d-flex w-400">
            <label htmlFor="authorizedCapitalShare">
              Authorized Capital Shares*
            </label>
            {validation.touched.authorizedCapitalShare &&
            validation.errors.authorizedCapitalShare ? (
              <p className="text-danger font-12 ms-auto mb-0">
                {validation.errors.authorizedCapitalShare}
              </p>
            ) : null}
          </div>
          <input
            type="number"
            className={`form-control ${
              validation.touched.authorizedCapitalShare &&
              validation.errors.authorizedCapitalShare &&
              'is-invalid'
            }`}
            id="authorizedCapitalShare"
            name="authorizedCapitalShare"
            placeholder="Authorized Capital Shares"
            min="0"
            onKeyDown={evt =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            value={totalShares}
            disabled={true}
          />
        </div>
        <div className="form-group">
          <div className="d-flex w-400">
            <label htmlFor="authorizedCapitalTotal">
              Authorized Capital Total*
            </label>
            {validation.touched.authorizedCapitalTotal &&
            validation.errors.authorizedCapitalTotal ? (
              <p className="text-danger font-12 ms-auto mb-0">
                {validation.errors.authorizedCapitalTotal}
              </p>
            ) : null}
          </div>
          <input
            type="number"
            className={`form-control ${
              validation.touched.authorizedCapitalTotal &&
              validation.errors.authorizedCapitalTotal &&
              'is-invalid'
            }`}
            id="authorizedCapitalTotal"
            name="authorizedCapitalTotal"
            placeholder="Authorized Capital Total"
            min="0"
            onKeyDown={evt =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            value={totalAmount}
            disabled={true}
          />
        </div>
      </div>
    );
  }
);
