import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setToastError, setToastSuccess } from '~/Store/reducers/layout';
import Swal from 'sweetalert2';

const ToastSuccess = () => {
  const dispatch = useAppDispatch();
  const { toastSuccess } = useAppSelector(state => state.layoutReducer);
  if (toastSuccess) {
    setTimeout(() => {
      dispatch(setToastSuccess(''));
    }, 3000);
    return (
      <div className="position-relative">
        <div className={'successToast toast-div'}>
          <i className={`bx bx-check font-22 ms-3 align-self-center`}></i>
          <p className="m-0 font-weight-600 font-16">{toastSuccess}</p>
          <i
            className="bx bx-x font-22 ms-auto me-3 align-self-center cursor-pointer"
            onClick={() => {
              dispatch(setToastSuccess(''));
            }}
          ></i>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
const ToastError = () => {
  const dispatch = useAppDispatch();
  const { toastError } = useAppSelector(state => state.layoutReducer);
  if (toastError) {
    setTimeout(() => {
      dispatch(setToastError(''));
    }, 3000);
    let errorArray =
      toastError && toastError?.message
        ? toastError?.message?.split(':')
        : toastError?.split(':');
    let parsedError = errorArray[errorArray.length - 1];
    return (
      <div className="position-relative">
        <div className={'errorToast toast-div'}>
          <i
            className={`bx bx-error-circle font-22 ms-3 align-self-center`}
          ></i>
          <p className="m-0 font-weight-600 font-16">
            {typeof parsedError === 'string'
              ? parsedError
              : ErrorHandle(parsedError)}
          </p>
          <i
            className="bx bx-x font-22 ms-auto me-3 align-self-center cursor-pointer"
            onClick={() => {
              dispatch(setToastError(''));
            }}
          ></i>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
const ErrorHandle = (err: ApolloError) => {
  if (err?.graphQLErrors[0]?.extensions?.response) {
    return err?.graphQLErrors[0]?.extensions?.response?.message.toString();
  } else {
    return err?.message;
  }
};

const ConfirmationDialogue = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ms-2 text-white',
    cancelButton: 'btn btn-danger text-white',
  },
  buttonsStyling: false,
});

export { ToastSuccess, ToastError, ErrorHandle, ConfirmationDialogue };
