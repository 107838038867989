export enum EventsEnum {
  // Company name change (Related forms: Form1, Form8, Form26)
  CHANGE_OF_NAME_OF_COMPANY = 1,
  // Company address change withing city (Related forms: Form21)
  CHANGE_IN_ADDRESS_WITHIN_CITY = 2,
  // Company address change withing province (Related forms: Form21, Form26)
  CHANGE_IN_ADDRESS_WITHIN_PROVINCE = 3,
  // Company address change out of province (Related forms: Form21, Form26)
  CHANGE_IN_ADDRESS_ACROSS_PROVINCE = 4,
  // Paid up capital increase (Related forms: Form3)
  PAID_UP_CAPITAL_INCREASE = 5,
  // Paid up capital decrease (Related forms: Form26)
  PAID_UP_CAPITAL_DECREASE = 6,
  // Paid up capital change (Related forms:Form5, Form7, Form26)
  AUTHORIZED_CAPITAL_INCREASE = 7,
  // Add new mortgage related forms (Form 10, Form11)
  NEW_MORTGAGE_ADDED = 8,
  // Change in mortgage related forms (Form 16)
  MODIFICATION_OF_MORTGAGE_CHARGE = 9,
  // Change in mortgage related forms (Form 16)
  RECTIFICATION_OF_MORTGAGE_CHARGE = 10,
  // Change in mortgage related forms (Form 17)
  SATISFACTION_OF_MORTGAGE_CHARGE = 11,
}
export enum OfficerEventsEnum {
  // Relevant docs (Form28, Form 29)
  APPOINTMENT_OF_DIRECTOR = 2001,

  // Relevant docs (Form28, Form 29)
  APPOINTMENT_OF_CHIEF_EXECUTIVE = 2002,

  // Relevant docs (Form 29)
  APPOINTMENT_OF_AUDITOR = 2003,

  // Relevant docs (Form 29)
  APPOINTMENT_OF_COMPANY_SECRETARY = 2004,

  // Relevant docs (Form 29)
  APPOINTMENT_OF_LEGAL_ADVISOR = 2005,

  // Relevant docs (Form 29)
  APPOINTMENT_OF_FINANCIAL_OFFICER = 2006,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_DIRECTOR = 2007,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_CHIEF_EXECUTIVE = 2008,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_AUDITOR = 2009,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_COMPANY_SECRETARY = 2010,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_LEGAL_ADVISOR = 2011,

  // In GM at least 3/4th votes are enough to remove them
  REMOVAL_OF_FINANCIAL_OFFICER = 2012,
}
export enum MemberEventsEnum {
  APPOINTMENT_OF_ULTIMATE_BENEFICIAL_OWNER = 3001,
  RECTIFICATION_OF_REGISTERS = 3002,
  CLOSING_OF_REGISTERS = 3003,
}
export const COMPANY_PROFILE_ENUM_ARRAY = [
  EventsEnum.CHANGE_OF_NAME_OF_COMPANY,
  EventsEnum.CHANGE_IN_ADDRESS_WITHIN_CITY,
  EventsEnum.CHANGE_IN_ADDRESS_WITHIN_PROVINCE,
  EventsEnum.CHANGE_IN_ADDRESS_ACROSS_PROVINCE,
  EventsEnum.PAID_UP_CAPITAL_INCREASE,
  EventsEnum.PAID_UP_CAPITAL_DECREASE,
  EventsEnum.AUTHORIZED_CAPITAL_INCREASE,
  EventsEnum.NEW_MORTGAGE_ADDED,
  EventsEnum.MODIFICATION_OF_MORTGAGE_CHARGE,
  EventsEnum.RECTIFICATION_OF_MORTGAGE_CHARGE,
  EventsEnum.SATISFACTION_OF_MORTGAGE_CHARGE,
];
export const COMPANY_OFFICER_ENUM_ARRAY = [
  OfficerEventsEnum.APPOINTMENT_OF_DIRECTOR,
  OfficerEventsEnum.APPOINTMENT_OF_CHIEF_EXECUTIVE,
  OfficerEventsEnum.APPOINTMENT_OF_AUDITOR,
  OfficerEventsEnum.APPOINTMENT_OF_COMPANY_SECRETARY,
  OfficerEventsEnum.APPOINTMENT_OF_LEGAL_ADVISOR,
  OfficerEventsEnum.APPOINTMENT_OF_FINANCIAL_OFFICER,
  OfficerEventsEnum.REMOVAL_OF_DIRECTOR,
  OfficerEventsEnum.REMOVAL_OF_CHIEF_EXECUTIVE,
  OfficerEventsEnum.REMOVAL_OF_AUDITOR,
  OfficerEventsEnum.REMOVAL_OF_LEGAL_ADVISOR,
  OfficerEventsEnum.REMOVAL_OF_FINANCIAL_OFFICER,
];
export const COMPANY_MEMBER_ENUM_ARRAY = [
  MemberEventsEnum.APPOINTMENT_OF_ULTIMATE_BENEFICIAL_OWNER,
  MemberEventsEnum.CLOSING_OF_REGISTERS,
  MemberEventsEnum.RECTIFICATION_OF_REGISTERS,
];
