import { gql } from '@apollo/client';
export const CHANGE_PASSWORD_OF_USER = gql`
  mutation updatePassword(
    $currentPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updatePassword(
      data: {
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      user {
        id
        email
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROFILE_OF_USER = gql`
  mutation updateProfile(
    $id: String
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $phoneNumber: String
    $cnic: String
    $isActive: Boolean
  ) {
    updateProfile(
      data: {
        id: $id
        data: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          phoneNumber: $phoneNumber
          cnic: $cnic
          isActive: $isActive
        }
      }
    ) {
      user {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const FIND_ALL_USERS = gql`
  query findUsers(
    $userType: String
    $companyId: ID
    $userGroupId: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
    $name: String
    $searchKey: String
    $searchValue: String
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
      q: $name
      searchKey: $searchKey
      searchValue: $searchValue
      companyId: $companyId
      userGroupId: $userGroupId
      filterBy: { userType: $userType }
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
          cnic
          userType
          phoneNumber
          isActive
          isEmailVerified
          isSubscriptionVerified
          companyId {
            companyId
          }
          userGroups {
            id
            companyId
            groupName
            description
          }
          subcriptionData {
            id
            userId
            numOfUsers
            numOfCompanies
            totalAmount
            subscriptionPlanId
            status
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FIND_ALL_USERS_NOTIFICATIONS = gql`
  query findUsers(
    $userType: [String]
    $companyId: ID
    $userGroupId: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
    $name: String
    $searchKey: String
    $searchValue: String
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
      q: $name
      searchKey: $searchKey
      searchValue: $searchValue
      companyId: $companyId
      userGroupId: $userGroupId
      filterBy: { userType: $userType }
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
          cnic
          userType
          phoneNumber
          isActive
          isEmailVerified
          isSubscriptionVerified
          companyId {
            companyId
          }
          userGroups {
            id
            companyId
            groupName
            description
          }
          subcriptionData {
            id
            userId
            numOfUsers
            numOfCompanies
            totalAmount
            subscriptionPlanId
            status
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_STATUS = gql`
  mutation updateIsSubscriptionVerifiedFlag(
    $id: String!
    $isSubscriptionVerified: Boolean!
  ) {
    updateIsSubscriptionVerifiedFlag(
      data: { id: $id, isSubscriptionVerified: $isSubscriptionVerified }
    ) {
      user {
        id
        email
        isSubscriptionVerified
      }
      errors {
        field
        message
      }
    }
  }
`;

export const FIND_USER = gql`
  query findUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      cnic
      userType
      phoneNumber
      isActive
      userGroups {
        id
        companyId
        userCategory
        permissions {
          moduleName
          view
          edit
          delete
          generateDocument
        }
      }
      subcriptionData {
        id
        userId
        numOfUsers
        numOfCompanies
        totalAmount
        pendingAmount
        subscriptionPlanId
        status
        endDate
      }
    }
  }
`;
export const FIND_USER_BY_EMAIL = gql`
  query findUserByEmail($email: String!) {
    user(email: $email) {
      id
      firstName
      lastName
      email
      cnic
    }
  }
`;

export const ADD_USER_TO_COMPANY = gql`
  mutation addIntermediaryUserToCompany(
    $companyId: String!
    $ids: [IntermediaryCompanyUser!]!
  ) {
    addIntermediaryUserToCompany(data: { companyId: $companyId, ids: $ids }) {
      isAdded
    }
  }
`;

export const DELETE_USER_FROM_COMPANY = gql`
  mutation deleteIntermediaryFromCompany(
    $companyId: String!
    $ids: [IntermediaryCompanyUser!]!
  ) {
    deleteIntermediaryFromCompany(data: { companyId: $companyId, ids: $ids }) {
      isDeleted
    }
  }
`;
