import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import { Country } from '~/assets/StatesCities';

import * as Yup from 'yup';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_COMPANY_PROFILE_BY_USER_ID,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_DRAFT,
} from '~/Store/graphQL/CompanyProfile';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  CompanyKindEnum,
  ModuleEnum,
  SectorialClassificationEnum,
  UserTypeEnum,
} from '../../../../commons/enums';
import '.././companyprofile.scss';
import {
  calendar,
  building,
  AddressIcon,
  ContactInfoIcon,
  profileIcon,
} from '~/assets/images';

import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const CompanyInformation: FC = () => {
  const { companyID, companyView } = useAppSelector(
    state => state.companyProfileReducer
  );
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [states, setStates] = useState<any>();

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [tempData, setTempData] = useState<any>();
  // const [logoPic, setLogoPic] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Company Information',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);

  const [
    draftUpdateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE_DRAFT
    // { errorPolicy: 'all' }
  );
  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE
    // { errorPolicy: 'all' }
  );

  useEffect(() => {
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [userId]);

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      setTempData(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
      );

      // setLogoPic(
      //   dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
      //     ?.logoUrl
      // );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );

      setPreview(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
          ?.logoUrl
      );
      //
      setStates(
        Country.states.find(
          state =>
            state.name ===
            dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
              .province
        )
      );
    } else if (errorCompanyProfileByUserId) {
      console.log('errorCompanyProfileByUserId:', errorCompanyProfileByUserId);
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  // useEffect(() => {}, [logoPic]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      name: tempData ? tempData.name : '',
      cuinNumber: tempData ? tempData.cuinNumber : '',
      kind: tempData ? tempData.kind : '',
      sectorialClassification: tempData ? tempData.sectorialClassification : '',
      companyObjects: tempData ? tempData.companyObjects : '',
      incorporationDate: tempData?.incorporationDate
        ? new Date(tempData.incorporationDate).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      commencementOfBusiness: tempData?.commencementOfBusiness
        ? new Date(tempData.commencementOfBusiness).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      holdingCompany: tempData?.holdingCompany ? tempData.holdingCompany : '',
      isActive: tempData?.isActive ? true : false,

      //
      address: tempData?.address ? tempData.address : '',
      city: tempData?.city ? tempData.city : '',
      district: tempData?.district ? tempData.district : '',
      province: tempData?.province ? tempData.province : '',
      town: tempData?.town ? tempData.town : '',
      postalCode: tempData?.postalCode ? tempData.postalCode : '',
      mobile: tempData?.mobile ? tempData.mobile : '',
      phone: tempData?.phone ? tempData.phone : '',
      fax: tempData?.fax ? tempData.fax : '',
      email: tempData?.email ? tempData.email : '',
      websiteUrl: tempData?.websiteUrl ? tempData.websiteUrl : '',
      logoUrl: tempData?.logoUrl ? tempData.logoUrl : '',
      logoFile: {
        name: '',
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter company name').nullable(),
      cuinNumber: Yup.string().required('Please enter CUIN Number').nullable(),
      kind: Yup.string().required('Please select an option').nullable(),
      sectorialClassification: Yup.string()
        .required('Please select an option')
        .nullable(),
      companyObjects: Yup.string()
        .required('Please enter company object')
        .nullable(),
      incorporationDate: Yup.string()
        .required('Please select incorporation date')
        .nullable(),
      commencementOfBusiness: Yup.string()
        .required('Please select a date')
        .nullable(),
      //
      address: Yup.string().required('Please enter address').nullable(),
      city: Yup.string().required('Please enter city').nullable(),
      district: Yup.string().required('Please select a district').nullable(),
      province: Yup.string().required('Please select a province').nullable(),
      town: Yup.string().required('Please enter area/town').nullable(),
      postalCode: Yup.string().required('Please enter postal code').nullable(),
      mobile: Yup.string().required('Please enter mobile number').nullable(),
      phone: Yup.string().required('Please enter phone number').nullable(),
      fax: Yup.string().required('Please enter fax number').nullable(),
      email: Yup.string()
        .email('Incorrect email')
        .required('Please enter email address')
        .nullable(),
      websiteUrl: Yup.string().required('Please enter website url').nullable(),
    }),
    onSubmit: values => {
      if (isDraft) {
        draftUpdateCompanyProfile({
          variables: {
            id: companyID,
            name: values.name,
            cuinNumber: values.cuinNumber,
            sectorialClassification: values.sectorialClassification,
            companyObjects: values.companyObjects,
            holdingCompany: values.holdingCompany,
            kind: values.kind,
            incorporationDate: values.incorporationDate,
            commencementOfBusiness: values.commencementOfBusiness,
            isActive: values.isActive,
            //
            address: values.address,
            city: values.city,
            district: values.district,
            province: values.province,
            town: values.town,
            postalCode: values.postalCode,
            mobile: values.mobile,
            phone: values.phone,
            fax: values.fax,
            email: values.email,
            websiteUrl: values.websiteUrl,
            logoUrl: values.logoUrl,
            logoFile: values.logoFile,
          },
        })
          .then((res: any) => {
            if (res.data.draftUpdateProfileCompanyDraft.draftCompanyProfile) {
              dispatch(setToastSuccess('Company profile draft updated!'));
              navigate('/companyProfile/subsidiary');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyProfile({
          variables: {
            id: companyID,
            name: values.name,
            cuinNumber: values.cuinNumber,
            sectorialClassification: values.sectorialClassification,
            companyObjects: values.companyObjects,
            holdingCompany: values.holdingCompany,
            kind: values.kind,
            incorporationDate: values.incorporationDate,
            commencementOfBusiness: values.commencementOfBusiness,
            isActive: values.isActive,

            //
            address: values.address,
            city: values.city,
            district: values.district,
            province: values.province,
            town: values.town,
            postalCode: values.postalCode,
            mobile: values.mobile,
            phone: values.phone,
            fax: values.fax,
            email: values.email,
            websiteUrl: values.websiteUrl,
            logoUrl: values.logoUrl,
            logoFile: values.logoFile,
          },
        })
          .then((res: any) => {
            if (res.data.updateCompanyProfile.companyProfile) {
              dispatch(setToastSuccess('Company profile updated!'));
              navigate('/companyProfile/subsidiary');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState<any>(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    e.target.value = null;
  };
  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium ">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3 end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn  fw-bold dangerButton d-flex align-items-center "
                  disabled={loadingCompanyDraft}
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center "
                  disabled={loadingCompanyDraft}
                  onMouseDown={() => {
                    companyView
                      ? navigate('/companyProfile/subsidiary')
                      : validation.handleSubmit();
                  }}
                >
                  {(loadingCompanyDraft || loadingupdateCompanyProfile) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  {companyView ? 'Next' : 'Save & Next'}
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </span>
        </div>
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link active ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link active cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link cursor-pointer"
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}

        <div id="mainComponentNew" className="scroll-content">
          {loadingCompanyProfileByUserId ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <>
              <div className="inputFields border-div px-3 pt-3">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}

                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={building}
                      ></img>
                      <div className="font-weight-600">Company Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group ">
                      <div className="d-flex">
                        <label htmlFor="name">Company Name*</label>
                        {validation.touched.name && validation.errors.name ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.name}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.name &&
                          validation.errors.name &&
                          'is-invalid'
                        }`}
                        id="name"
                        name="name"
                        placeholder="Company Name"
                        value={validation.values.name}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.name &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.name &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  name: validation.values.name,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cuinNumber">CUIN No*</label>
                        {validation.touched.cuinNumber &&
                        validation.errors.cuinNumber ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.cuinNumber}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.cuinNumber &&
                          validation.errors.cuinNumber &&
                          'is-invalid'
                        }`}
                        id="cuinNumber"
                        name="cuinNumber"
                        placeholder="CUIN No"
                        value={validation.values.cuinNumber}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.cuinNumber &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.cuinNumber &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  cuinNumber: validation.values.cuinNumber,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="kind">Company Kind*</label>
                        {validation.touched.kind && validation.errors.kind ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.kind}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox  ${
                            validation.touched.kind &&
                            validation.errors.kind &&
                            'is-invalid'
                          }`}
                          id="kind"
                          name="kind"
                          placeholder="Company Kind"
                          value={validation.values.kind}
                          disabled={companyView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.kind &&
                                isDraft &&
                                validation.values.kind &&
                                draftUpdateCompanyProfile({
                                  variables: {
                                    id: companyID,
                                    kind: validation.values.kind,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={CompanyKindEnum.SMC}>SMC</option>
                          <option value={CompanyKindEnum.PRIVATE}>
                            Private
                          </option>
                          <option
                            value={CompanyKindEnum.UNLISTED_PUBLIC_COMPANY}
                          >
                            Unlisted public company
                          </option>
                          <option value={CompanyKindEnum.LISTED_COMPANY}>
                            Listed company
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="incorporationDate">
                          Incorporation Date*
                        </label>
                        {validation.touched.incorporationDate &&
                        validation.errors.incorporationDate ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.incorporationDate}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-autox ${
                          validation.touched.incorporationDate &&
                          validation.errors.incorporationDate &&
                          'is-invalid'
                        }`}
                        id="incorporationDate"
                        name="incorporationDate"
                        value={validation.values.incorporationDate}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.incorporationDate &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.incorporationDate &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  incorporationDate:
                                    validation.values.incorporationDate,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="commencementOfBusiness">
                          Commencement of Business*
                        </label>
                        {validation.touched.commencementOfBusiness &&
                        validation.errors.commencementOfBusiness ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.commencementOfBusiness}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-autox ${
                          validation.touched.commencementOfBusiness &&
                          validation.errors.commencementOfBusiness &&
                          'is-invalid'
                        }`}
                        id="commencementOfBusiness"
                        name="commencementOfBusiness"
                        value={validation.values.commencementOfBusiness}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.commencementOfBusiness &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.commencementOfBusiness &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  commencementOfBusiness:
                                    validation.values.commencementOfBusiness,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="sectorialClassification">
                          Sectorial Classification*
                        </label>
                        {validation.touched.sectorialClassification &&
                        validation.errors.sectorialClassification ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.sectorialClassification}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            validation.touched.sectorialClassification &&
                            validation.errors.sectorialClassification &&
                            'is-invalid'
                          }`}
                          id="sectorialClassification"
                          name="sectorialClassification"
                          placeholder="Sectorial Classification"
                          value={validation.values.sectorialClassification}
                          disabled={companyView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.sectorialClassification &&
                                isDraft &&
                                validation.values.sectorialClassification &&
                                draftUpdateCompanyProfile({
                                  variables: {
                                    id: companyID,
                                    sectorialClassification:
                                      validation.values.sectorialClassification,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option
                            value={SectorialClassificationEnum.BASIC_MATERIALS}
                          >
                            Basic Materials
                          </option>
                          <option
                            value={SectorialClassificationEnum.CONSUMER_GOODS}
                          >
                            Consumer Goods
                          </option>
                          <option
                            value={
                              SectorialClassificationEnum.CONSUMER_SERVICES
                            }
                          >
                            Consumer Services
                          </option>
                          <option
                            value={SectorialClassificationEnum.FINANCIALS}
                          >
                            Financials
                          </option>
                          <option
                            value={SectorialClassificationEnum.HEALTH_CARE}
                          >
                            Health Care
                          </option>
                          <option
                            value={SectorialClassificationEnum.INDUSTRIALS}
                          >
                            Industrials
                          </option>
                          <option
                            value={SectorialClassificationEnum.OIL_AND_GAS}
                          >
                            Oil and Gas
                          </option>
                          <option
                            value={SectorialClassificationEnum.TECHNOLOGY}
                          >
                            Technology
                          </option>
                          <option
                            value={
                              SectorialClassificationEnum.TELECOMMUNICATIONS
                            }
                          >
                            Telecommunications
                          </option>
                          <option value={SectorialClassificationEnum.UTILITIES}>
                            Utilities
                          </option>
                          <option
                            value={SectorialClassificationEnum.MISCELLANEOUS}
                          >
                            Miscellaneous
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="companyObjects">Company Objects*</label>
                        {validation.touched.companyObjects &&
                        validation.errors.companyObjects ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.companyObjects}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.companyObjects &&
                          validation.errors.companyObjects &&
                          'is-invalid'
                        }`}
                        id="companyObjects"
                        name="companyObjects"
                        placeholder="Company Objects"
                        value={validation.values.companyObjects}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.companyObjects &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.companyObjects &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  companyObjects:
                                    validation.values.companyObjects,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="holdingCompany">Holding Company</label>
                        {validation.touched.holdingCompany &&
                        validation.errors.holdingCompany ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.holdingCompany}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.holdingCompany &&
                          validation.errors.holdingCompany &&
                          'is-invalid'
                        }`}
                        id="holdingCompany"
                        name="holdingCompany"
                        placeholder="Holding Company"
                        value={validation.values.holdingCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4 w-100">
                  <div className="col-3 d-flex w-100">
                    <div className="col-2">
                      <div className="font-weight-600">Logo</div>
                      <div className="font-11">Documents</div>
                      <div className=" ">
                        <label className="cursor-pointer">
                          {preview ? (
                            <div className=" p-1   cursor-pointer filelogobox d-flex ms-0 ps-0 ">
                              <div className="p-4">
                                <img
                                  className="filelogopic "
                                  src={
                                    preview
                                    // dataCompanyProfileByUserId
                                    //   ?.findCompanyProfileByUserId
                                    //   ?.companyProfile?.logoUrl
                                  }
                                ></img>
                              </div>

                              <div className="d-flex flex-column align-self-center gap-3 me-2 mb-2">
                                <label htmlFor={'file-input'}>
                                  <i
                                    className={
                                      'bx bxs-pencil text-success fs-3  cursor-pointer'
                                    }
                                    // onChangeCapture={(
                                    //   e: React.ChangeEvent<HTMLInputElement>
                                    // ) => {
                                    //   // console.log('hehwee');

                                    //   if (e?.target?.files) {
                                    //     validation.setFieldValue(
                                    //       'logoFile',
                                    //       e?.target?.files[0]
                                    //     );
                                    //   }
                                    // }}

                                    // onClick={() => {
                                    //   // dispatch(setAgendaViewScreen(false));
                                    //   // dispatch(
                                    //   //   setSelectedAgendaId(val.node.agendaId)
                                    //   // );
                                    //   // navigate('/admin/agenda/add');
                                    // }}
                                  />
                                </label>

                                <i
                                  className={
                                    'bx bxs-trash-alt text-danger fs-3  '
                                  }
                                  onClick={() => {
                                    validation.setFieldValue('logoFile', '');
                                    validation.setFieldValue('logoUrl', '');
                                    setPreview(null);
                                    setSelectedFile(null);
                                    // console.log('Aaaa', logoPic);

                                    // draftUpdateCompanyProfile({
                                    //   variables: {
                                    //     id: companyID,
                                    //     commencementOfBusiness:
                                    //       validation.values.logoUrl,

                                    //   },
                                    // });
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <label htmlFor={'file-input'}>
                                <i
                                  className="bx bx-image-add  cursor-pointer bx-lg p-1 m-2 ms-0 greyLabel "
                                  // htmlFor={'file-input'}
                                  // onChangeCapture={(
                                  //   e: React.ChangeEvent<HTMLInputElement>
                                  // ) => {
                                  //   console.log('hehwee');

                                  //   if (e?.target?.files) {
                                  //     validation.setFieldValue(
                                  //       'logoFile',
                                  //       e?.target?.files[0]
                                  //     );
                                  //   }
                                  // }}
                                />
                              </label>
                            </>
                            // <i className="bx bx-image-add bx-sm  m-2 text-success"></i>
                          )}
                          {/* <i className="bx bx-image-add bx-sm  m-2 text-primary"></i> */}
                        </label>
                        {/* <input type="file" onChange={onSelectFile} />
                        {selectedFile && <img src={preview} />} */}

                        <input
                          className="indexer bg-success d-none"
                          style={{ display: 'none' }}
                          id="file-input"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          multiple={false}
                          onChange={onSelectFile}
                          onChangeCapture={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (e?.target?.files) {
                              // setLogoPic(preview);

                              validation.setFieldValue(
                                'logoFile',
                                e?.target?.files[0]
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="col d-flex justify-content-start align-items-center">
                      {validation.values.logoFile?.name ? (
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <ul
                              className={
                                'd-flex justify-content-start align-items-center'
                              }
                            >
                              <li>{validation.values.logoFile.name}</li>
                              {!companyView ? (
                                <i
                                  className="bx bx-x bx-sm mx-3 cursor-pointer"
                                  onClick={e => {
                                    validation.setFieldValue('logoFile', '');
                                    validation.setFieldValue('logoUrl', '');
                                    setPreview(null);
                                  }}
                                ></i>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      ) : validation.values.logoUrl ? (
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <ul
                              className={
                                'd-flex justify-content-start align-items-center'
                              }
                            >
                              <li>{validation.values.logoUrl.split('/')[3]}</li>
                              {!companyView ? (
                                <i
                                  className="bx bx-x bx-sm mx-3 cursor-pointer"
                                  onClick={e => {
                                    validation.setFieldValue('logoFile', '');
                                    validation.setFieldValue('logoUrl', '');
                                    setPreview(null);
                                  }}
                                ></i>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  {/* <div className="col-3">
                    <div className="form-group h-100">
                      <div className="h-100">
                        <label className="form-check-label">
                          Activate Company Profile*
                        </label>
                        <div className="d-flex form-check  form-switch mb-1 ms-2 mt-3 ">
                          <input
                            className="form-check-input"
                            style={{ transform: 'scale(1.5)' }}
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={validation.values.isActive}
                            disabled={companyView}
                            onChange={e => {
                              const { checked } = e.target;
                              validation.setFieldValue('isActive', checked);
                              {
                                !validation.errors.commencementOfBusiness &&
                                  isDraft &&
                                  !loadingCompanyDraft &&
                                  validation.values.commencementOfBusiness &&
                                  draftUpdateCompanyProfile({
                                    variables: {
                                      id: companyID,
                                      isActive: checked,
                                    },
                                  });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="inputFields border-div px-3 pt-3">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}

                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={AddressIcon}
                      ></img>
                      <div className="font-weight-600">Address</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="province">Province*</label>
                        {validation.touched.province &&
                        validation.errors.province ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.province}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            validation.touched.province &&
                            validation.errors.province &&
                            'is-invalid'
                          }`}
                          id="province"
                          name="province"
                          placeholder="Province"
                          value={validation.values.province}
                          disabled={companyView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.province &&
                                isDraft &&
                                validation.values.province &&
                                draftUpdateCompanyProfile({
                                  variables: {
                                    id: companyID,
                                    province: validation.values.province,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                            const { value } = e.target;
                            value &&
                              setStates(
                                Country.states.find(
                                  state => state.name === value
                                )
                              );
                            validation.setFieldValue('city', '');
                            validation.setFieldValue('district', '');
                          }}
                        >
                          <option value="">Select</option>
                          {Country.states?.map(state => (
                            <option value={state.name}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex w-400">
                        <label htmlFor="district">District*</label>
                        {validation.touched.district &&
                        validation.errors.district ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.district}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            validation.touched.district &&
                            validation.errors.district &&
                            'is-invalid'
                          }`}
                          id="district"
                          name="district"
                          placeholder="District"
                          value={validation.values.district}
                          disabled={companyView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.district &&
                                isDraft &&
                                validation.values.district &&
                                draftUpdateCompanyProfile({
                                  variables: {
                                    id: companyID,
                                    district: validation.values.district,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          {states?.cities
                            ?.filter((data: any) => data?.district)
                            .map((city: any) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="city">City*</label>
                        {validation.touched.city && validation.errors.city ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.city}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-autox ${
                            validation.touched.city &&
                            validation.errors.city &&
                            'is-invalid'
                          }
                  `}
                          id="city"
                          name="city"
                          placeholder="City"
                          value={validation.values.city}
                          disabled={companyView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.city &&
                                isDraft &&
                                validation.values.city &&
                                draftUpdateCompanyProfile({
                                  variables: {
                                    id: companyID,
                                    city: validation.values.city,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          {states?.cities
                            ?.filter(
                              (data: any) => !data.name.includes('District')
                            )
                            .map((city: any) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="town">Area / Town*</label>
                        {validation.touched.town && validation.errors.town ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.town}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.town &&
                          validation.errors.town &&
                          'is-invalid'
                        }`}
                        id="town"
                        name="town"
                        placeholder="Area / Town"
                        value={validation.values.town}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.town &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.town &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  town: validation.values.town,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="postalCode">Postal Code*</label>
                        {validation.touched.postalCode &&
                        validation.errors.postalCode ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.postalCode}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.postalCode &&
                          validation.errors.postalCode &&
                          'is-invalid'
                        }`}
                        id="postalCode"
                        name="postalCode"
                        placeholder="Postal Code"
                        value={validation.values.postalCode}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.postalCode &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.postalCode &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  postalCode: validation.values.postalCode,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="address">Address*</label>
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.address}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.address &&
                          validation.errors.address &&
                          'is-invalid'
                        }`}
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={validation.values.address}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.address &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.address &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  email: validation.values.address,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>
              </div>

              <div className="inputFields border-div px-3 pt-3">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}

                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={ContactInfoIcon}
                      ></img>
                      <div className="font-weight-600">Contact Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="mobile">Mobile*</label>
                        {validation.touched.mobile &&
                        validation.errors.mobile ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.mobile}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.mobile &&
                          validation.errors.mobile &&
                          'is-invalid'
                        }`}
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        value={validation.values.mobile}
                        disabled={true}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.mobile &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.mobile &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  mobile: validation.values.mobile,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="phone">Telephone*</label>
                        {validation.touched.phone && validation.errors.phone ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.phone &&
                          validation.errors.phone &&
                          'is-invalid'
                        }`}
                        id="phone"
                        name="phone"
                        placeholder="Telephone"
                        value={validation.values.phone}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.phone &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.phone &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  phone: validation.values.phone,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="email">Email ID*</label>
                        {validation.touched.email && validation.errors.email ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.email}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="email"
                        className={`form-control w-autox ${
                          validation.touched.email &&
                          validation.errors.email &&
                          'is-invalid'
                        }`}
                        id="email"
                        name="email"
                        placeholder="Email ID"
                        value={validation.values.email}
                        disabled={true}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.email &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.email &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  email: validation.values.email,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="fax">Fax*</label>
                        {validation.touched.fax && validation.errors.fax ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.fax}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.fax &&
                          validation.errors.fax &&
                          'is-invalid'
                        }`}
                        id="fax"
                        name="fax"
                        placeholder="Fax"
                        value={validation.values.fax}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.fax &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.fax &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  fax: validation.values.fax,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="websiteUrl">Website URL*</label>
                        {validation.touched.websiteUrl &&
                        validation.errors.websiteUrl ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.websiteUrl}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-autox ${
                          validation.touched.websiteUrl &&
                          validation.errors.websiteUrl &&
                          'is-invalid'
                        }`}
                        id="websiteUrl"
                        name="websiteUrl"
                        placeholder="Website URL"
                        value={validation.values.websiteUrl}
                        disabled={companyView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.websiteUrl &&
                              isDraft &&
                              !loadingCompanyDraft &&
                              validation.values.websiteUrl &&
                              draftUpdateCompanyProfile({
                                variables: {
                                  id: companyID,
                                  websiteUrl: validation.values.websiteUrl,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-6"></div>
                  <div className="col-3"></div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
