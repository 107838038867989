import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './financialYear.scss';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ADD_FINANCIAL_YEAR,
  FIND_FINANCIAL_YEAR_BY_ID,
  UPDATE_FINANCIAL_YEAR,
} from '~/Store/graphQL/FinancialYearManagement';
import moment from 'moment';
import { bank, year } from '~/assets/images';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';

export const AddFinancialYear: FC = () => {
  const { selectedFinancialYearId, financialYearView } = useAppSelector(
    state => state.financialYearReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [financialYearData, setFinancialYearData] = useState<any>();
  const [financialYearName, setFinancialYearName] = useState<string>();
  const [financialYearStartDate, setFinancialYearStartDate] = useState<string>(
    new Date().toISOString().slice(0, 10)
  );
  const [financialYearEndDate, setFinancialYearEndDate] = useState<string>(
    new Date().toISOString().slice(0, 10)
  );
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/financialYear',
          title: 'Financial Year',
        },
        {
          url: location.pathname,
          title: 'Add Financial Year',
        },
      ])
    );
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.FINANCIAL_YEAR,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.FINANCIAL_YEAR,
    },
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });

  // Getting financial information
  const [
    findFinancialYearById,
    {
      loading: loadingFindFinancialYearById,
      error: errorFindFinancialYearById,
      data: dataFindFinancialYearById,
    },
  ] = useLazyQuery<any>(FIND_FINANCIAL_YEAR_BY_ID);
  //   update financial year
  const [
    updateFinancialYear,
    {
      loading: loadingUpdateFinancialYear,
      error: errorUpdateFinancialYear,
      data: dataUpdateFinancialYear,
    },
  ] = useMutation<any>(UPDATE_FINANCIAL_YEAR);
  // add financial year
  const [
    addFinancialYear,
    {
      loading: loadingAddFinancialYear,
      error: errorAddFinancialYear,
      data: dataAddFinancialYear,
    },
  ] = useMutation<any>(ADD_FINANCIAL_YEAR);

  useEffect(() => {
    if (selectedFinancialYearId) {
      findFinancialYearById({
        variables: {
          id: selectedFinancialYearId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedFinancialYearId]);
  useEffect(() => {
    if (dataFindFinancialYearById) {
      setFinancialYearData(
        dataFindFinancialYearById?.findOneFinancialYear?.financialYear
      );
      setFinancialYearStartDate(
        dataFindFinancialYearById?.findOneFinancialYear?.financialYear
          ?.startDate
      );
      setFinancialYearStartDate(
        dataFindFinancialYearById?.findOneFinancialYear?.financialYear?.endDate
      );
    } else if (errorFindFinancialYearById) {
      console.log('errorFindFinancialYearById:', errorFindFinancialYearById);
    }
  }, [dataFindFinancialYearById, errorFindFinancialYearById]);
  useEffect(() => {
    if (financialYearStartDate && financialYearEndDate) {
      setFinancialYearName(
        new Date(financialYearStartDate).getFullYear().toString() +
          '-' +
          new Date(financialYearEndDate).getFullYear().toString()
      );
    }
  }, [financialYearStartDate, financialYearEndDate]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      financialYearName: financialYearData
        ? financialYearData.name
        : financialYearName,
      startDate: financialYearData?.startDate
        ? moment(financialYearData.startDate).format('YYYY-MM-DD')
        : new Date().toISOString().slice(0, 10),
      // endDate: financialYearData?.endDate
      //   ? moment(financialYearData.endDate).format('YYYY-MM-DD')
      //   : new Date().toISOString().slice(0, 10),

      endDate: financialYearData?.startDate
        ? moment(financialYearData?.startDate)
            .add(1, 'years')
            .format('YYYY-MM-DD')
        : moment().add(1, 'years').format('YYYY-MM-DD'),
    },
    validationSchema: Yup.object({
      financialYearName: Yup.string()
        .matches(/[0-9]+$/, 'Remove alphabets characters')
        .required('Please enter financial year name')
        .max(9, 'Follow this format (2023-2025)')
        .nullable(),
      startDate: Yup.string().required('Please select start date').nullable(),
      endDate: Yup.string().required('Please select end date').nullable(),
    }),
    onSubmit: (values: any) => {
      if (financialYearData && selectedFinancialYearId && !financialYearView) {
        updateFinancialYear({
          variables: {
            id: selectedFinancialYearId,
            name: values.financialYearName,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateFinancialYear?.financialYear) {
              dispatch(setToastSuccess('Financial Year updated!'));
              navigate(`/financialYear`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        addFinancialYear({
          variables: {
            companyId: companyID,
            name: values.financialYearName,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        })
          .then((res: any) => {
            if (res?.data?.createFinancialYear?.financialYear) {
              dispatch(setToastSuccess('Financial Year Added!'));
              navigate(`/financialYear`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingFindFinancialYearById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 d-flex parent">
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="justify-content-end  background-primary-medium"
            >
              <div className="d-flex gap-3 position-relative end-0 mx-4">
                <button
                  type="button"
                  className="btn dangerButton fw-bold d-flex align-items-center my-auto"
                  disabled={
                    loadingFindFinancialYearById ||
                    loadingUpdateFinancialYear ||
                    loadingAddFinancialYear
                  }
                  onMouseDown={() => {
                    navigate('/financialYear');
                  }}
                >
                  Cancel
                </button>
                {!financialYearView ? (
                  <button
                    type="button"
                    className="btn successButton fw-bold d-flex align-items-center my-auto"
                    disabled={
                      loadingFindFinancialYearById ||
                      loadingFindFinancialYearById ||
                      loadingUpdateFinancialYear
                    }
                    onMouseDown={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {(loadingFindFinancialYearById ||
                      loadingUpdateFinancialYear ||
                      loadingFindFinancialYearById) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
            <div id="mainComponentNew">
              <div className="inputFields  p-2">
                <div className="rounded border ">
                  <div className="row  px-4 pb-4">
                    <div className="row mb-3">
                      <div className="col-12 d-flex justify-content-start align-items-center mt-3">
                        <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                          <img
                            className="height-30 me-2 my-1 bg-transparent align-items-center"
                            src={year}
                          ></img>
                          <div className="font-weight-600">
                            Financial Year Info
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-5 pt-4">
                      <div className="form-group w-50">
                        <div className="d-flex">
                          <label htmlFor="financialYearName">
                            Financial Year Name*
                          </label>
                          {validation.touched.financialYearName &&
                          validation.errors.financialYearName ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.financialYearName}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.financialYearName &&
                            validation.errors.financialYearName &&
                            'is-invalid'
                          }`}
                          id="financialYearName"
                          name="financialYearName"
                          placeholder="Financial Year Name"
                          value={validation.values.financialYearName}
                          disabled={financialYearView}
                          onChange={validation.handleChange}
                        />
                      </div>
                      <div className="form-group w-50">
                        <div className="d-flex w-50 action-items">
                          <label
                            htmlFor="startDate"
                            className="model-input-heading"
                          >
                            Start Date
                          </label>
                          {validation.touched.startDate &&
                          validation.errors.startDate ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.startDate}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="date"
                          className={`form-control w-100 ${
                            validation.touched.startDate &&
                            validation.errors.startDate &&
                            'is-invalid'
                          }`}
                          id="startDate"
                          name="startDate"
                          placeholder="Start Date"
                          disabled={financialYearView}
                          value={validation.values.startDate}
                          // onChange={validation.handleChange}
                          onChange={e => {
                            setFinancialYearStartDate(e.target.value);
                            validation.setFieldValue(
                              'startDate',
                              e.target.value
                            );
                            validation.setFieldValue(
                              'endDate',
                              moment(e.target.value)
                                .add(1, 'years')
                                .format('YYYY-MM-DD')
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row px-2  py-3 background-dim-grey mx-0">
                    <div className="d-flex gap-5 pt-4">
                      <div className="form-group w-50 px-4 ps-0 ">
                        <div className="d-flex w-50 action-items">
                          <label
                            htmlFor="endDate"
                            className="model-input-heading"
                          >
                            End Date
                          </label>
                          {validation.touched.endDate &&
                          validation.errors.endDate ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.endDate}
                            </p>
                          ) : null}
                        </div>

                        <input
                          type="date"
                          className={`form-control w-100 ${
                            validation.touched.endDate &&
                            validation.errors.endDate &&
                            'is-invalid'
                          }`}
                          id="endDate"
                          name="endDate"
                          placeholder="End Date"
                          disabled={true}
                          value={validation.values.endDate}
                          // onChange={validation.handleChange}
                          onChange={e => {
                            setFinancialYearEndDate(e.target.value);
                            validation.setFieldValue('endDate', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};
