import { MemberStatusEnum } from '../../commons/enums';
const GetCompanyMemberStatus = (status: string) => {
  if (status === MemberStatusEnum.ACTIVE) {
    return 'Active';
  } else if (status === MemberStatusEnum.CEASED) {
    return 'Ceased';
  }
};

export default GetCompanyMemberStatus;
