import React from 'react';
import {
  AddCompany,
  AddCompanyMembers,
  SidebarData,
  AdminSidebarData,
  AddOfficer,
  AddMeeting,
  AddBankAccount,
  IntermediarySidebarData,
} from './SidebarData';
import SubMenu from './SubMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '~/Store/hooks';
import { PermissionCategoryEnum, UserTypeEnum } from '~/commons/enums';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { goToDashboard, nLogo } from '~/assets/images';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userType } = useAppSelector(state => state.layoutReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  // useEffect(() => {
  //     var path = location.pathname;
  //     //set active on reload
  //     $('.nav-sidebar a').filter(function () {
  //         return $(this).attr('href') === path;
  //     }).addClass('active');
  //     //set active on click
  //     $(".nav a").on("click", function () {
  //         $(".nav").find(".active").removeClass("active");
  //         $(this).addClass("active");
  //     });
  // }, [])
  return (
    <div
      className={`sidebarDiv  bg-primary p-0  ${
        pathname.includes('companyMembers/') && 'max-w-335'
      }`}
    >
      <nav className="sidebar vh-100  ">
        {/* <p className="esspLogo">eSSP</p> */}

        <p className="font-14 text-white ms-4 fw-bold mt-4">MENU</p>
        <div className="ulu nav nav-sidebar">
          {userType === UserTypeEnum.SUPER_ADMIN
            ? AdminSidebarData
                // pathname.includes('companyProfile/')
                // ? AddCompany
                // : pathname.includes('companyOfficer/')
                // ? AddOfficer
                // : pathname.includes('companyMembers/')
                // ? AddCompanyMembers
                // : pathname.includes('agenda/')
                // ? AddAgenda
                // : pathname.includes('meetings/')
                // ? AddMeeting
                // :
                .map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })
            : pathname.includes('/intermediary') ||
              pathname.includes('/subscriptionPackage')
            ? IntermediarySidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
            : SidebarData.map((item, index) => {
                if (
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    item.id,
                    PermissionCategoryEnum.VIEW
                  ) ||
                  userType === UserTypeEnum.COMPANY_ADMIN ||
                  item.id === 'DASHBOARD'
                ) {
                  return <SubMenu item={item} key={index} />;
                }
              })}
        </div>
        {pathname.includes('/intermediary') ||
        pathname.includes('/subscriptionPackage') ? (
          <div className="row">
            <div
              className="col-12 go-to-dashboard d-flex gap-3 cursor-pointer"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <img
                className="nlogo d-flex align-self-center"
                src={goToDashboard}
              ></img>
              <h6 className="d-flex align-self-center pt-2 ">
                Go To DashBoard
              </h6>
            </div>
          </div>
        ) : null}
      </nav>
    </div>
  );
};
export default Sidebar;
