import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setAgendaViewScreen,
  setSelectedAgendaId,
} from '~/Store/reducers/agenda';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './agenda.scss';
import {
  DELETE_AGENDA,
  GET_ALL_AGENDA_OF_COMPANY,
} from '~/Store/graphQL/AgendaManagement';
import { MeetingTypeEnum } from '../../../commons/enums';
import GetMeetingType from '~/helpers/Agenda/getMeetingType';
import GetMeetingSubType from '~/helpers/Agenda/getMeetingSubType';
import GetAgendaBusinessType from '~/helpers/Agenda/getBusinessType';

export const AdminAgenda: FC = () => {
  let pageNo = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    meetingType: '',
  });
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Agenda',
        },
      ])
    );
  }, []);
  // Get all agendas query
  const [
    getAllAgendaForCompany,
    {
      loading: loadingGetAllAgendaForCompany,
      error: errorGetAllAgendaForCompany,
      data: dataGetAllAgendaForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_AGENDA_OF_COMPANY, {
    variables: {
      first: itemsPerPage,
      meetingType: filters?.meetingType
        ? filters?.meetingType !== 'All'
          ? filters?.meetingType
          : undefined
        : undefined,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteAgenda,
    {
      loading: loadingDeleteAgenda,
      error: errorDeleteAgenda,
      data: dataDeleteAgenda,
    },
  ] = useMutation<any>(DELETE_AGENDA);
  useEffect(() => {
    getAllAgendaForCompany();
  }, [itemsPerPage]);
  useEffect(() => {
    if (errorGetAllAgendaForCompany) {
      dispatch(setToastError(errorGetAllAgendaForCompany));
    }
  }, [errorGetAllAgendaForCompany]);
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="d-flex row mt-2 mb-4">
            {/* Second Row */}
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="meetingType">Meeting Type</label>
                </div>
                <div className="field">
                  <select
                    id="meetingType"
                    name="meetingType"
                    className={`form-control form-control-no-margin-bottom`}
                    value={filters.meetingType}
                    onChange={e => {
                      const { value } = e.target;
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.meetingType = value;
                      setFilters(temp);
                    }}
                  >
                    <option value="All">All Meetings</option>
                    <option value={MeetingTypeEnum.BOARD_MEETING}>
                      Board Meeting
                    </option>
                    <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                      Committee Meeting
                    </option>
                    <option value={MeetingTypeEnum.GENERAL_MEETING}>
                      General Meeting
                    </option>
                    <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                      Statutory Meeting
                    </option>
                    <option value={MeetingTypeEnum.OTHER}>Other Meeting</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto mx-3"
                  onClick={() => {
                    if (filters.meetingType) {
                      getAllAgendaForCompany({
                        variables: {
                          first: itemsPerPage,
                          meetingType: undefined,
                        },
                      });
                      let temp = JSON.parse(JSON.stringify(filters));
                      temp.meetingType = '';
                      setFilters(temp);
                    }
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto"
                  disabled={loadingGetAllAgendaForCompany}
                  onClick={() => {
                    if (filters.meetingType) {
                      getAllAgendaForCompany({
                        variables: {
                          first: itemsPerPage,
                          meetingType: filters?.meetingType
                            ? filters?.meetingType !== 'All'
                              ? filters?.meetingType
                              : undefined
                            : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 my-3">
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center"
              onClick={() => {
                dispatch(setAgendaViewScreen(false));
                dispatch(setSelectedAgendaId(null));
                navigate('/admin/agenda/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add Agenda
            </button>
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllAgendaForCompany ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tr">
                            <th className="col-1">S.No</th>
                            <th className="col-1">Ordinance</th>
                            <th className="col-1">Section</th>
                            <th className="col-2">Meeting Type</th>
                            <th className="col-2">Meeting Sub Type</th>
                            <th className="col-3">Business Type</th>
                            <th className="col-3">Action item</th>
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllAgendaForCompany?.findAllAgendas?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  {pageNo.current * itemsPerPage + (index + 1)}
                                </td>
                                <td>
                                  {val.node.ordinanceType
                                    ? val?.node?.ordinanceType
                                    : 'N/A'}
                                </td>
                                <td>
                                  {val.node.ordinanceSection
                                    ? val.node.ordinanceSection
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div className="badge custom-badge-dark-purple">
                                    {val.node.meetingType
                                      ? GetMeetingType(val.node.meetingType)
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-purple">
                                    {val.node.meetingSubType
                                      ? GetMeetingSubType(
                                          val.node.meetingSubType
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>

                                <td>
                                  {val.node.businessType
                                    ? GetAgendaBusinessType(
                                        val.node.businessType
                                      )
                                    : 'N/A'}
                                </td>
                                <td>{val?.node?.actionItems[0]?.actionItem}</td>
                                <td>
                                  <div className="editColumn mt-0 gap-1">
                                    <i
                                      className="bx bx-show-alt text-primary"
                                      onClick={() => {
                                        dispatch(
                                          setSelectedAgendaId(val.node.agendaId)
                                        );
                                        dispatch(setAgendaViewScreen(true));
                                        navigate('/admin/agenda/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-edit text-primary'}
                                      onClick={() => {
                                        dispatch(setAgendaViewScreen(false));
                                        dispatch(
                                          setSelectedAgendaId(val.node.agendaId)
                                        );
                                        navigate('/admin/agenda/add');
                                      }}
                                    />
                                    <i
                                      className={'bx bxs-trash-alt text-danger'}
                                      onClick={() => {
                                        dispatch(setAgendaViewScreen(false));
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteAgenda({
                                              variables: {
                                                id: val.node.agendaId,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data.deleteAgenda.count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'Agenda Deleted!'
                                                    )
                                                  );
                                                  getAllAgendaForCompany();
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllAgendaForCompany?.findAllAgendas?.edges
                        ?.length === 0 ||
                        dataGetAllAgendaForCompany?.findAllAgendas?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllAgendaForCompany?.findAllAgendas?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            dataGetAllAgendaForCompany?.findAllAgendas?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            getAllAgendaForCompany({
                              variables: {
                                last: itemsPerPage,
                                before:
                                  dataGetAllAgendaForCompany?.findAllAgendas
                                    ?.pageInfo?.startCursor,
                              },
                            });

                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllAgendaForCompany?.findAllAgendas?.pageInfo
                            ?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            dataGetAllAgendaForCompany?.findAllAgendas?.pageInfo
                              ?.hasNextPage
                          ) {
                            getAllAgendaForCompany({
                              variables: {
                                after:
                                  dataGetAllAgendaForCompany?.findAllAgendas
                                    ?.pageInfo?.endCursor,
                              },
                            });

                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
