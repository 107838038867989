import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CompanyProfile, CompanyProfiles, Dashboard } from '~/Screens';
import { CompanyMembers } from '~/Screens/User/CompanyMembers';
import {
  Instructions,
  JointShareholderInformation,
  MembershipInformation,
  NomineeInformation,
  PrimaryShareHolderAddress,
  PrimaryShareHolderInfo,
} from '~/Screens/User/CompanyMembers/AddCompanyMember';
import {
  AdvancedCapitalHistory,
  CompanyAddressHistory,
  CompanyInformation,
  MortgagesCharges,
  PaidUpCapitalHistory,
  RegisteredOfficeAddress,
  Subsidiary,
} from '~/Screens/User/CompanyProfile/AddCompanyScreens';
import { NotificationScreen } from '~/Screens/User/Notifications';
import { Middleware } from './middleware';
import { ShareSplit } from '~/Screens/User/Share/SplitShare';
import { AdminHelpSection } from '~/Screens/Admin/HelpSection';
import { AddAdminHelpSection } from '~/Screens/Admin/HelpSection/AddHelp';
import { BusinessRules } from '~/Screens/Admin/BusinessRules';
import { AddBusinessRule } from '~/Screens/Admin/BusinessRules/addBusinessRule';
import { CompanyProfileMembers } from '~/Screens/User/CompanyProfile/AddCompanyScreens/CompanyMembers';
import { CompanyOfficer } from '~/Screens/User/CompanyOfficers';
import { OfficerPersonalInformation } from '~/Screens/User/CompanyOfficers/AddOfficerScreens/PersonalInformation';

import { OfficerHistory } from '~/Screens/User/CompanyOfficers/AddOfficerScreens/OfficerHistory';
import { Agenda } from '~/Screens/User/Agenda';
import { AddAgenda } from '~/Screens/User/Agenda/addAgenda';
import { UserGroup } from '~/Screens/User/UserGroup';
import { AddUserGroup } from '~/Screens/User/UserGroup/AddUserGroup';
import { SystemUser } from '~/Screens/User/SystemUser';
import { AddSystemUser } from '~/Screens/User/SystemUser/AddSystemUser';
import { Permissions } from '~/Screens/User/Permissions';
import { AddPermission } from '~/Screens/User/Permissions/AddPermission';
import { FinancialYear } from '~/Screens/User/FinancialYear';
import { AddFinancialYear } from '~/Screens/User/FinancialYear/addFinancialYear';
import { Committee } from '~/Screens/User/Committee';
import { AddCommittee } from '~/Screens/User/Committee/addCommittee';
import { Meetings } from '~/Screens/User/Meetings';
import { MeetingInfo } from '~/Screens/User/Meetings/AddMeeting/meetingInfo';
import { ConcludeMeeting } from '~/Screens/User/Meetings/ConcludeMeeting';
import { AdjournMeeting } from '~/Screens/User/Meetings/AdjournMeeting';
import { Share } from '~/Screens/User/Share';
import { ShareTransactions } from '~/Screens/User/Share/ShareTransactions';
import { AllotmentOfShare } from '~/Screens/User/Share/AllotmentOfShare';
import { TransferOfShare } from '~/Screens/User/Share/TransferShare';
import { DuplicateShare } from '~/Screens/User/Share/DuplicateShare';
import { ShareTransmission } from '~/Screens/User/Share/ShareTransmission';
import { ChangePassword } from '~/Screens/User/UserProfile/ChangePassword';
import { BankAccount } from '~/Screens/User/BankAccount';
import { AddBankAccount } from '~/Screens/User/BankAccount/addBankAccount';
import { Register } from '~/Screens/User/Register';
import { AdminDashboard } from '~/Screens/Admin/Dashboard';
import { AdminAgenda } from '~/Screens/Admin/Agenda';
import { AddAdminAgenda } from '~/Screens/Admin/Agenda/addAdminAgenda';
import { AdminOrdinance } from '~/Screens/Admin/Ordinance';
import { AdminCompanyProfiles } from '~/Screens/Admin/CompanyProfiles';

import { AddAdminOrdinance } from '~/Screens/Admin/Ordinance/addAdminOrdinance';
import { AdminUsers } from '~/Screens/Admin/Users';
import { AdminSubscription } from '~/Screens/Admin/Subscription';
import { AddAdminSubscription } from '~/Screens/Admin/Subscription/addAdminSubscription';
import { Intermediary } from '~/Screens/User/Intermediary';
import { AddIntermediary } from '~/Screens/User/Intermediary/addIntermediaryUser';
import { MemberHistory } from '~/Screens/User/CompanyMembers/AddCompanyMember/MemberHistory';
import { SubscriptionPackage } from '~/Screens/User/subscription';
import { LegalDoc } from '~/Screens/User/LegalDoc';
import { AddLegalDoc } from '~/Screens/User/LegalDoc/addLegalDoc';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { useAppSelector } from '~/Store/hooks';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { EditProfile } from '~/Screens/Admin/Users/editProfile';
// import { Intermediary } from '~/Screens/User/Intermediary';

const MyRoutes = () => {
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  return (
    // <Router>
    //   <AuthLayout>
    <Routes>
      <Route path="/*" element={<Middleware />} />
      {/* <Route path='/*' element={<Navigate to={"dashboard"} />} /> */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/companies" element={<CompanyProfiles />} />
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.COMPANY_PROFILE,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/companyProfile">
          <Route index element={<CompanyProfile />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.COMPANY_PROFILE,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <>
              <Route
                path="/companyProfile/companyInformation"
                element={<CompanyInformation />}
              />
              <Route
                path="/companyProfile/registeredOfficeAddress"
                element={<RegisteredOfficeAddress />}
              />
              <Route
                path="/companyProfile/subsidiary"
                element={<Subsidiary />}
              />
              <Route
                path="/companyProfile/mortgagesCharges"
                element={<MortgagesCharges />}
              />
              <Route
                path="/companyProfile/companyAddressHistory"
                element={<CompanyAddressHistory />}
              />
              <Route
                path="/companyProfile/paidUpCapitalHistory"
                element={<PaidUpCapitalHistory />}
              />
              <Route
                path="/companyProfile/companyMembers"
                element={<CompanyProfileMembers />}
              />
              <Route
                path="/companyProfile/advancedCapitalHistory"
                element={<AdvancedCapitalHistory />}
              />
            </>
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.COMPANY_OFFICERS,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/companyOfficer">
          <Route index element={<CompanyOfficer />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.COMPANY_OFFICERS,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <>
              <Route
                path="/companyOfficer/personalInformation"
                element={<OfficerPersonalInformation />}
              />

              <Route
                path="/companyOfficer/officerHistory"
                element={<OfficerHistory />}
              />
            </>
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.COMPANY_MEMBERS,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/companyMembers">
          <Route index element={<CompanyMembers />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.COMPANY_MEMBERS,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <>
              <Route
                path="/companyMembers/primaryShareHolderInformation"
                element={<PrimaryShareHolderInfo />}
              />
              <Route
                path="/companyMembers/primaryShareHolderAddress"
                element={<PrimaryShareHolderAddress />}
              />
              <Route
                path="/companyMembers/membershipInformation"
                element={<MembershipInformation />}
              />
              <Route
                path="/companyMembers/memberHistory"
                element={<MemberHistory />}
              />
              <Route
                path="/companyMembers/instructions"
                element={<Instructions />}
              />
              <Route
                path="/companyMembers/jointShareholderInformation"
                element={<JointShareholderInformation />}
              />
              <Route
                path="/companyMembers/nomineeInformation"
                element={<NomineeInformation />}
              />
            </>
          ) : null}
        </Route>
      ) : null}

      <Route path="/agenda">
        <Route index element={<Agenda />} />
        <Route path="/agenda/add" element={<AddAgenda />} />
      </Route>
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.SYSTEM_USER_GROUPS,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <>
          <Route path="/usergroup">
            <Route index element={<UserGroup />} />
            {GetPermissionsOfUser(
              selectedUserPermissions,
              ModuleEnum.SYSTEM_USER_GROUPS,
              PermissionCategoryEnum.EDIT
            ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
              <Route path="/usergroup/add" element={<AddUserGroup />} />
            ) : null}
          </Route>
          <Route path="/permission">
            <Route index element={<Permissions />} />
            {GetPermissionsOfUser(
              selectedUserPermissions,
              ModuleEnum.SYSTEM_USER_GROUPS,
              PermissionCategoryEnum.EDIT
            ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
              <Route path="/permission/add" element={<AddPermission />} />
            ) : null}
          </Route>
        </>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.SYSTEM_USER,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/systemuser">
          <Route index element={<SystemUser />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.SYSTEM_USER,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <Route path="/systemuser/add" element={<AddSystemUser />} />
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.FINANCIAL_YEAR,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/financialYear">
          <Route index element={<FinancialYear />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.FINANCIAL_YEAR,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <Route path="/financialYear/add" element={<AddFinancialYear />} />
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.COMMITTEE,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/committee">
          <Route index element={<Committee />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.COMMITTEE,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <Route path="/committee/add" element={<AddCommittee />} />
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.MEETING,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/meetings">
          <Route index element={<Meetings />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.MEETING,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <>
              <Route path="/meetings/add" element={<MeetingInfo />} />
              <Route
                path="/meetings/add/conclude"
                element={<ConcludeMeeting />}
              />
              <Route
                path="/meetings/add/adjourn"
                element={<AdjournMeeting />}
              />
            </>
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.SHARE_MANAGEMENT,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/shares">
          <Route index element={<Share />} />
          <Route path="/shares/transactions" element={<ShareTransactions />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.SHARE_MANAGEMENT,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <>
              <Route path="/shares/add" element={<AllotmentOfShare />} />
              <Route
                path="/shares/add/transfer"
                element={<TransferOfShare />}
              />
              <Route
                path="/shares/add/duplicate"
                element={<DuplicateShare />}
              />
              <Route
                path="/shares/add/transmission"
                element={<ShareTransmission />}
              />
              <Route path="/shares/add/split" element={<ShareSplit />} />
            </>
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.NOTIFICATION,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/notifications" element={<NotificationScreen />} />
      ) : null}
      <Route path="/changePassword" element={<ChangePassword />} />
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.BANK_ACCOUNTS,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/bankAccounts">
          <Route index element={<BankAccount />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.BANK_ACCOUNTS,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <Route path="/bankAccounts/add" element={<AddBankAccount />} />
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.LEGAL_DOCUMENTS,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/legalDoc">
          <Route index element={<LegalDoc />} />
          {GetPermissionsOfUser(
            selectedUserPermissions,
            ModuleEnum.LEGAL_DOCUMENTS,
            PermissionCategoryEnum.EDIT
          ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
            <Route path="/legalDoc/add" element={<AddLegalDoc />} />
          ) : null}
        </Route>
      ) : null}
      {GetPermissionsOfUser(
        selectedUserPermissions,
        ModuleEnum.REGISTER,
        PermissionCategoryEnum.VIEW
      ) || userType === UserTypeEnum.COMPANY_ADMIN ? (
        <Route path="/register" element={<Register />} />
      ) : null}

      <Route path="/intermediary">
        <Route index element={<Intermediary />} />
        <Route path="/intermediary/append" element={<AddIntermediary />} />
      </Route>

      <Route path="/subscriptionPackage">
        <Route index element={<SubscriptionPackage />} />
      </Route>

      <Route path="/admin/dashboard" element={<AdminDashboard />} />

      <Route path="/admin/agenda">
        <Route index element={<AdminAgenda />} />
        <Route path="/admin/agenda/add" element={<AddAdminAgenda />} />
      </Route>

      <Route path="/admin/ordinance">
        <Route index element={<AdminOrdinance />} />
        <Route path="/admin/ordinance/add" element={<AddAdminOrdinance />} />
      </Route>

      <Route path="/admin/companyProfiles">
        <Route index element={<AdminCompanyProfiles />} />
      </Route>

      <Route path="/admin/user">
        <Route index element={<AdminUsers />} />
        <Route path="/admin/user/edit" element={<EditProfile />} />
      </Route>

      <Route path="/admin/subscription">
        <Route index element={<AdminSubscription />} />
        <Route
          path="/admin/subscription/add"
          element={<AddAdminSubscription />}
        />
      </Route>
      <Route path="/admin/help">
        <Route index element={<AdminHelpSection />} />
        <Route path="/admin/help/add" element={<AddAdminHelpSection />} />
      </Route>
      <Route path="/admin/businessRules">
        <Route index element={<BusinessRules />} />
        <Route path="/admin/businessRules/add" element={<AddBusinessRule />} />
      </Route>

      {/*
      //for sub childs
      <Route path='/dashboard'>
        <Route index element={<Dashboard />} />
        <Route path='/dashboard/one' element={<One />} />
        <Route path='/dashboard/two' element={<Two />} />
      </Route>
        <Route path="/patient" element={<App />} >
          <Route index element={<Navigate to={"edit"} />} />
          <Route path='/patient/edit' element={<PP />} />
        </Route>
        */}
    </Routes>
    //   </AuthLayout>
    // </Router>
  );
};

export default MyRoutes;
