import { gql } from '@apollo/client';
export const GET_ALL_COMMITTEES_OF_COMPANY = gql`
  query findAllCommittees(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $committeeName: String
    $committeeNumber: String
    $searchKey: String
    $searchValue: String
  ) {
    findAllCommittees(
      first: $first
      last: $last
      after: $after
      before: $before
      q: $committeeName
      searchKey: $searchKey
      searchValue: $searchValue
      filterBy: { companyId: $companyId, committeeNumber: $committeeNumber }
    ) {
      edges {
        node {
          committeeId
          meetingType
          meetingSubType
          committeeNumber
          committeeName
          committeeStatus
          committeeStartDate
          committeeEndDate
          committeeHeadEmail
          committeeMembersEmails
          url
          committeeObjectives {
            id
            committeeId
            actionItemId
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const GET_ALL_COMMITTEES_FOR_MEETING = gql`
  query findAllCommitteesForMeeting(
    $companyId: String
    $meetingType: String
    $meetingSubType: String
    $committeeStatus: String
  ) {
    findAllCommitteesForMeeting(
      filterBy: {
        companyId: $companyId
        meetingType: $meetingType
        meetingSubType: $meetingSubType
        committeeStatus: $committeeStatus
      }
    ) {
      edges {
        node {
          committeeId
          meetingType
          meetingSubType
          committeeNumber
          committeeName
          committeeStatus
          committeeStartDate
          committeeEndDate
          committeeHeadEmail
          committeeMembersEmails
          url
          committeeObjectives {
            id
            actionItem
            committeeId
            actionItemId
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_COMMITTEE_BY_ID = gql`
  query findOneCommittee($id: ID!) {
    findOneCommittee(id: $id) {
      committee {
        committeeId
        meetingType
        meetingSubType
        committeeNumber
        committeeName
        committeeStatus
        committeeStartDate
        committeeEndDate
        committeeHeadEmail
        committeeMembersEmails
        committeeObjectives {
          id
          committeeId
          actionItemId
          actionItem
        }
      }
    }
  }
`;
export const UPDATE_COMMITTEE = gql`
  mutation updateCommittee(
    $id: String!
    $meetingType: String
    $meetingSubType: String
    $committeeName: String
    $committeeStatus: String
    $committeeStartDate: String
    $committeeEndDate: String
    $committeeHeadEmail: String
    $committeeMembersEmails: String
    $isSendEmail: Boolean
    $committeeObjectives: [CommitteeObjectiveInput]
  ) {
    updateCommittee(
      data: {
        id: $id
        data: {
          meetingType: $meetingType
          meetingSubType: $meetingSubType
          committeeName: $committeeName
          committeeStatus: $committeeStatus
          committeeStartDate: $committeeStartDate
          committeeEndDate: $committeeEndDate
          committeeHeadEmail: $committeeHeadEmail
          committeeMembersEmails: $committeeMembersEmails
          isSendEmail: $isSendEmail
          committeeObjectives: $committeeObjectives
        }
      }
    ) {
      committee {
        committeeId
        meetingType
        meetingSubType
        committeeName
        committeeStatus
        committeeStartDate
        committeeEndDate
        committeeHeadEmail
        committeeMembersEmails
        committeeObjectives {
          id
          committeeId
          actionItemId
        }
      }
    }
  }
`;
export const CREATE_COMMITTEE = gql`
  mutation createCommittee(
    $companyId: String
    $meetingType: String
    $meetingSubType: String
    $committeeName: String
    $committeeStatus: String
    $committeeStartDate: String
    $committeeEndDate: String
    $committeeHeadEmail: String
    $committeeMembersEmails: String
    $isSendEmail: Boolean
    $committeeObjectives: [CommitteeObjectiveInput]
  ) {
    createCommittee(
      data: {
        companyId: $companyId
        meetingType: $meetingType
        meetingSubType: $meetingSubType
        committeeName: $committeeName
        committeeStatus: $committeeStatus
        committeeStartDate: $committeeStartDate
        committeeEndDate: $committeeEndDate
        committeeHeadEmail: $committeeHeadEmail
        committeeMembersEmails: $committeeMembersEmails
        isSendEmail: $isSendEmail
        committeeObjectives: $committeeObjectives
      }
    ) {
      committee {
        committeeId
        meetingType
        meetingSubType
        committeeName
        committeeStatus
        committeeStartDate
        committeeEndDate
        committeeHeadEmail
        committeeMembersEmails
        committeeObjectives {
          id
          committeeId
          actionItemId
        }
      }
    }
  }
`;
export const DELETE_COMMITTEE = gql`
  mutation deleteCommittee($id: String!) {
    deleteCommittee(data: { id: $id }) {
      count
    }
  }
`;
export const CONCLUDE_Committee = gql`
  mutation concludeCommittee($committeeId: String!) {
    concludeCommittee(data: { committeeId: $committeeId }) {
      committee {
        url
      }
    }
  }
`;
