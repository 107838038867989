import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  FIND_COMPANY_MEMBER_BY_ID,
  UPDATE_COMPANY_MEMBER,
  UPDATE_COMPANY_MEMBER_DRAFT,
} from '~/Store/graphQL/CompanyMembers';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import 'react-phone-input-2/lib/style.css';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';

export const PrimaryShareHolderAddress: FC = () => {
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedMemberId, memberView } = useAppSelector(
    state => state.companyMemberReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [membersData, setMembersData] = useState<any>();
  const [countries, setCountries] = useState<ICountry>();
  const [states, setStates] = useState<IState>();
  const [cities, setCities] = useState<ICity>();
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [selectedState, setSelectedState] = useState<number>();
  const isNewFolio = searchParams.get('isNewFolio');
  const isNewFolioForTransfer = searchParams.get('isNewFolioForTransfer');
  const isNewFolioForTransmission = searchParams.get(
    'isNewFolioForTransmission'
  );
  useEffect(() => {
    setCountries(Country.getAllCountries());

    dispatch(
      setBreadCrumb([
        {
          url: '/companyMembers',
          title: 'Company Member',
        },
        {
          url: location.pathname,
          title: 'Primary Shareholder Address',
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedState, selectedCountry]);
  // For fetching relevant documents
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
    fetchPolicy: 'no-cache',
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_MEMBERS,
    },
  });
  // Getting company member information
  const [
    findCompanyMemberById,
    {
      loading: loadingFindCompanyMemberById,
      error: errorFindCompanyMemberById,
      data: dataFindCompanyMemberById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_MEMBER_BY_ID);
  // update company member draft
  const [
    draftUpdateCompanyMember,
    {
      loading: loadingDraftUpdateCompanyMember,
      error: errorDraftUpdateCompanyMember,
      data: dataDraftUpdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER_DRAFT);
  //   update actual company member
  const [
    updateCompanyMember,
    {
      loading: loadingupdateCompanyMember,
      error: errorupdateCompanyMember,
      data: dataupdateCompanyMember,
    },
  ] = useMutation<any>(UPDATE_COMPANY_MEMBER);
  useEffect(() => {
    if (selectedMemberId) {
      findCompanyMemberById({
        variables: {
          id: selectedMemberId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMemberId]);
  useEffect(() => {
    if (dataFindCompanyMemberById) {
      setMembersData(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
      );
      setIsDraft(
        dataFindCompanyMemberById?.findOneCompanyMember?.companyMember?.isDraft
      );
      let country: ICountry = countries.find(
        (x: ICountry) =>
          x.name ===
          dataFindCompanyMemberById?.findOneCompanyMember?.companyMember
            ?.country
      );
      if (country) {
        setSelectedCountry(country.isoCode);
      }
    } else if (errorFindCompanyMemberById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyMemberById);
    }
  }, [dataFindCompanyMemberById, errorFindCompanyMemberById]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      residentialAddress: membersData ? membersData.residentialAddress : '',
      city: membersData ? membersData.city : '',
      province: membersData ? membersData.province : '',
      country: membersData ? membersData.country : '',
      postalCode: membersData ? membersData.postalCode : '',
      email: membersData ? membersData.email : '',
      mobile: membersData ? membersData.phone : '',
      ntn: membersData ? membersData.ntn : '',
    },
    validationSchema: Yup.object({
      residentialAddress: Yup.string()
        .required('Please enter residential address')
        .nullable(),
      city: Yup.string().required('Please enter city').nullable(),
      province: Yup.string().required('Please enter province').nullable(),
      country: Yup.string().required('Please enter country').nullable(),
      postalCode: Yup.string().required('Please enter postal code').nullable(),
      email: Yup.string()
        .email('Incorrect email')
        .required('Please enter Email')
        .nullable(),
      mobile: Yup.string()
        .required('Please enter Telephone')
        .min(11, 'Telephone must contains atleast 11 numbers')
        .nullable(),
      // ntn: Yup.string().required('Please enter ntn').nullable(),
    }),
    onSubmit: (values: any) => {
      if (isDraft) {
        draftUpdateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            residentialAddress: values.residentialAddress,
            city: values.city,
            province: values.province,
            country: values.country,
            postalCode: values.postalCode,
            email: values.email,
            phone: values.mobile.toString(),
            ntn: values.ntn,
          },
        })
          .then((res: any) => {
            if (res.data.draft_updateCompanyMemberDraft.draftCompanyMember) {
              dispatch(setToastSuccess('Company member draft updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/membershipInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyMember({
          variables: {
            id: selectedMemberId,
            companyId: companyID,
            residentialAddress: values.residentialAddress,
            city: values.city,
            province: values.province,
            country: values.country,
            postalCode: values.postalCode,
            email: values.email,
            phone: values.mobile.toString(),
            ntn: values.ntn,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyMember?.companyMember) {
              dispatch(setToastSuccess('Company Member updated!'));
              if (isNewFolio) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolio=true`
                );
              } else if (isNewFolioForTransfer) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransfer=true`
                );
              } else if (isNewFolioForTransmission) {
                navigate(
                  `/companyMembers/membershipInformation?isNewFolioForTransmission=true`
                );
              } else {
                navigate(`/companyMembers/membershipInformation`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingRelevantProcesses ||
      loadingReliventDocumnets ||
      loadingFindCompanyMemberById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div id="mainComponent">
            {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
            <div className="inputFields">
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="residentialAddress">
                    Residential Address*
                  </label>
                  {validation.touched.residentialAddress &&
                  validation.errors.residentialAddress ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.residentialAddress}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.residentialAddress &&
                    validation.errors.residentialAddress &&
                    'is-invalid'
                  }`}
                  id="residentialAddress"
                  name="residentialAddress"
                  placeholder="Residential Address"
                  value={validation.values.residentialAddress}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.residentialAddress &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.residentialAddress &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            residentialAddress:
                              validation.values.residentialAddress,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="country">Country*</label>
                  {validation.touched.country && validation.errors.country ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.country}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.country &&
                      validation.errors.country &&
                      'is-invalid'
                    }`}
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={validation.values.country}
                    disabled={memberView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.country &&
                          isDraft &&
                          validation.values.country &&
                          !loadingDraftUpdateCompanyMember &&
                          draftUpdateCompanyMember({
                            variables: {
                              id: selectedMemberId,
                              companyId: companyID,
                              country: validation.values.country,
                            },
                          });
                      }
                    }}
                    onChange={e => {
                      let country: ICountry = countries.find(
                        (x: ICountry) => x.name === e.currentTarget.value
                      );
                      if (country) {
                        setSelectedCountry(country.isoCode);
                      }
                      setSelectedState(undefined);
                      validation.setFieldValue('city', '');
                      validation.setFieldValue('province', '');
                      validation.handleChange(e);
                    }}
                  >
                    <option value="">Select</option>
                    {countries?.map((country: ICountry) => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="province">Province*</label>
                  {validation.touched.province && validation.errors.province ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.province}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.province &&
                      validation.errors.province &&
                      'is-invalid'
                    }`}
                    id="province"
                    name="province"
                    placeholder="Province"
                    value={validation.values.province}
                    disabled={memberView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.province &&
                          isDraft &&
                          validation.values.province &&
                          !loadingDraftUpdateCompanyMember &&
                          draftUpdateCompanyMember({
                            variables: {
                              id: selectedMemberId,
                              companyId: companyID,
                              province: validation.values.province,
                            },
                          });
                      }
                    }}
                    onChange={e => {
                      let state: IState = states.find(
                        (x: IState) => x.name === e.target.value
                      );
                      setSelectedCountry(selectedCountry);
                      if (state) {
                        setSelectedState(state.isoCode);
                      }
                      validation.handleChange(e);
                    }}
                  >
                    <option value="">Select</option>
                    {states?.map((state: IState) => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="city">City*</label>
                  {validation.touched.city && validation.errors.city ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.city}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.city &&
                      validation.errors.city &&
                      'is-invalid'
                    }`}
                    id="city"
                    name="city"
                    placeholder="City"
                    value={validation.values.city}
                    disabled={memberView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.city &&
                          isDraft &&
                          validation.values.city &&
                          !loadingDraftUpdateCompanyMember &&
                          draftUpdateCompanyMember({
                            variables: {
                              id: selectedMemberId,
                              companyId: companyID,
                              city: validation.values.city,
                            },
                          });
                      }
                    }}
                    onChange={e => {
                      validation.handleChange(e);
                    }}
                  >
                    <option value="">Select</option>
                    {cities?.map((city: ICity) => (
                      <option value={city.name}>{city.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="postalCode">Postal Code*</label>
                  {validation.touched.postalCode &&
                  validation.errors.postalCode ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.postalCode}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.postalCode &&
                    validation.errors.postalCode &&
                    'is-invalid'
                  }`}
                  id="postalCode"
                  name="postalCode"
                  placeholder="Postal Code"
                  value={validation.values.postalCode}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.postalCode &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.postalCode &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            postalCode: validation.values.postalCode,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="email">Email*</label>
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.email &&
                    validation.errors.email &&
                    'is-invalid'
                  }`}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={validation.values.email}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.email &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.email &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            email: validation.values.email,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>

              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="mobile">Telephone*</label>
                  {validation.touched.mobile && validation.errors.mobile ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.mobile}
                    </p>
                  ) : null}
                </div>
                <PhoneInput
                  containerStyle={{ padding: 0 }}
                  buttonClass="button-class"
                  containerClass="form-control"
                  inputClass="my-input-class"
                  inputStyle={{
                    border: 0,
                    height: 48,
                    width: '100%',
                  }}
                  buttonStyle={{
                    height: 48,
                  }}
                  countryCodeEditable={false}
                  preferredCountries={['pk']}
                  country={'pk'}
                  value={validation.values.mobile}
                  onChange={e => {
                    validation.setFieldValue('mobile', e);
                  }}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);

                    {
                      !validation.errors.mobile &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.mobile &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            phone: validation.values.mobile.toString(),
                          },
                        });
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="ntn">NTN</label>
                  {validation.touched.ntn && validation.errors.ntn ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.ntn}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.ntn &&
                    validation.errors.ntn &&
                    'is-invalid'
                  }`}
                  id="ntn"
                  name="ntn"
                  placeholder="NTN"
                  value={validation.values.ntn}
                  disabled={memberView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.ntn &&
                        isDraft &&
                        !loadingDraftUpdateCompanyMember &&
                        validation.values.ntn &&
                        draftUpdateCompanyMember({
                          variables: {
                            id: selectedMemberId,
                            companyId: companyID,
                            ntn: validation.values.ntn,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    if (isNewFolio) {
                      navigate('/shares/add');
                    } else if (isNewFolioForTransfer) {
                      navigate('/shares/add/transfer');
                    } else if (isNewFolioForTransmission) {
                      navigate('/shares/add/transmission');
                    } else {
                      navigate('/companyMembers');
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    if (isNewFolio) {
                      navigate(
                        '/companyMembers/primaryShareHolderInformation?isNewFolio=true'
                      );
                    } else if (isNewFolioForTransfer) {
                      navigate(
                        '/companyMembers/primaryShareHolderInformation?isNewFolioForTransfer=true'
                      );
                    } else {
                      navigate('/companyMembers/primaryShareHolderInformation');
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary primaryButton d-flex align-items-center "
                  disabled={loadingDraftUpdateCompanyMember}
                  onMouseDown={() => {
                    memberView
                      ? navigate('/companyMembers/membershipInformation')
                      : validation.handleSubmit();
                  }}
                >
                  {(loadingDraftUpdateCompanyMember ||
                    loadingupdateCompanyMember) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  {memberView ? 'Next' : 'Save & Next'}
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </>
      )}
    </React.Fragment>
  );
};
