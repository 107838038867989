import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import {
  FIND_COMPANY_PROFILE_BY_USER_ID,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_DRAFT,
} from '~/Store/graphQL/CompanyProfile';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { CompanySubsidiariesInput } from '../types';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import Swal from 'sweetalert2';
import { ConfirmationDialogue } from '~/components/Toasts';
import { ModuleEnum, UserTypeEnum } from '~/commons/enums';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const Subsidiary: FC = () => {
  const { companyID, companyView } = useAppSelector(
    state => state.companyProfileReducer
  );
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [products, setProducts] = useState<
    CompanySubsidiariesInput[] | undefined
  >();
  const [tempProducts, setTempProducts] = useState<
    CompanySubsidiariesInput[] | undefined
  >();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Subsidiary(ies)',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);
  const [
    draftUpdateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE_DRAFT
    // { errorPolicy: 'all' }
  );
  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE
    // { errorPolicy: 'all' }
  );

  useEffect(() => {
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [userId]);

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      setProducts(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
          .companySubsidiaries
      );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );
    } else if (errorCompanyProfileByUserId) {
      console.log('errorCompanyProfileByUserId:', errorCompanyProfileByUserId);
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
      setEditableRow(-1);
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);
  useEffect(() => {
    if (dataupdateCompanyProfile && !errorupdateCompanyProfile) {
      setEditableRow(-1);
    } else if (errorupdateCompanyProfile) {
      dispatch(setToastError(errorupdateCompanyProfile));
    }
  }, [errorupdateCompanyProfile, dataupdateCompanyProfile]);

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3 end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn fw-bold primaryDismissButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile/companyInformation');
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile/mortgagesCharges');
                  }}
                >
                  Next
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </span>
        </div>
        {/*  */}
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link active">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link active cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link cursor-pointer"
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        <div id="mainComponentNew">
          {loadingCompanyProfileByUserId ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <div className="w-100" style={{ display: 'contents' }}>
              <div className="table-responsive">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Company Name</th>
                      <th>Incorporation No</th>
                      <th>Share Holding %</th>
                      {!companyView && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((val: any, index: number) => (
                      <tr key={`${index}`}>
                        <td className="border-end">
                          <div className="badge custom-badge-primary">
                            {index + 1}
                          </div>
                        </td>
                        <td className="border-end">
                          {editableRow === index ? (
                            <input
                              value={val.companyName}
                              onChange={e => {
                                const { value } = e.target;
                                const temp = JSON.parse(
                                  JSON.stringify(products)
                                );
                                temp[index].companyName = value;
                                setProducts(temp);
                              }}
                            />
                          ) : (
                            val.companyName
                          )}
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-success">
                            {editableRow === index ? (
                              <input
                                value={val.incorporationNumber}
                                type="number"
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  temp[index].incorporationNumber =
                                    Number(value);
                                  setProducts(temp);
                                }}
                              />
                            ) : (
                              val.incorporationNumber
                            )}
                          </div>
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-primary ">
                            {editableRow === index ? (
                              <input
                                value={val.shareHolding}
                                type="number"
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onChange={e => {
                                  const { value } = e.target;
                                  const temp = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  temp[index].shareHolding = Number(value);
                                  setProducts(temp);
                                }}
                              />
                            ) : (
                              val.shareHolding && `${val.shareHolding}%`
                            )}
                          </div>
                        </td>
                        {!companyView && (
                          <td className="editColumn">
                            {editableRow !== index ? (
                              <>
                                <i
                                  className={`bx bxs-edit ${
                                    editableRow === -1
                                      ? 'text-primary'
                                      : 'text-info'
                                  }`}
                                  onClick={() => {
                                    if (editableRow === -1) {
                                      setEditableRow(index);
                                      setTempProducts(products);
                                    }
                                  }}
                                />
                                <i
                                  className={`bx bxs-trash-alt ${
                                    editableRow === -1
                                      ? 'text-danger'
                                      : 'text-info'
                                  }`}
                                  onClick={() => {
                                    if (editableRow === -1) {
                                      ConfirmationDialogue.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: 'Delete',
                                        cancelButtonText: 'Cancel',
                                        reverseButtons: true,
                                      }).then(result => {
                                        if (result.isConfirmed) {
                                          let temp = [...products];
                                          temp.splice(index, 1);
                                          let holdingCompany;
                                          let newProducts = JSON.parse(
                                            JSON.stringify(temp)
                                          );
                                          newProducts.forEach(
                                            (
                                              product: CompanySubsidiariesInput,
                                              index: number
                                            ) => {
                                              if (
                                                product &&
                                                product?.shareHolding &&
                                                product?.shareHolding > 50
                                              ) {
                                                holdingCompany =
                                                  product.companyName;
                                              }
                                            }
                                          );
                                          if (isDraft) {
                                            let temp2 = JSON.parse(
                                              JSON.stringify(temp)
                                            );
                                            temp2.forEach((element: any) => {
                                              delete element['__typename'];
                                            });
                                            draftUpdateCompanyProfile({
                                              variables: {
                                                id: companyID,
                                                companySubsidiariesInput: temp2,
                                                holdingCompany: holdingCompany
                                                  ? holdingCompany
                                                  : '',
                                              },
                                            }).then(res => {
                                              res.data && setProducts(temp);
                                              ConfirmationDialogue.fire(
                                                'Deleted!',
                                                '',
                                                'success'
                                              );
                                            });
                                          } else {
                                            let temp2 = JSON.parse(
                                              JSON.stringify(products)
                                            );
                                            temp2.forEach((element: any) => {
                                              delete element['__typename'];
                                            });
                                            temp2[index].toBeDeleted = true;
                                            updateCompanyProfile({
                                              variables: {
                                                id: companyID,
                                                companySubsidiariesInput: temp2,
                                                holdingCompany: holdingCompany
                                                  ? holdingCompany
                                                  : '',
                                              },
                                            }).then(res => {
                                              res.data && setProducts(temp);
                                              ConfirmationDialogue.fire(
                                                'Deleted!',
                                                '',
                                                'success'
                                              );
                                            });
                                          }
                                        } else if (
                                          /* Read more about handling dismissals below */
                                          result.dismiss ===
                                          Swal.DismissReason.cancel
                                        ) {
                                          ConfirmationDialogue.fire(
                                            'Cancelled',
                                            '',
                                            'error'
                                          );
                                        }
                                      });
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <i
                                  className="bx bx-check-circle text-primary"
                                  onClick={() => {
                                    if (
                                      products[editableRow].companyName &&
                                      products[editableRow]
                                        .incorporationNumber &&
                                      products[editableRow].shareHolding
                                    ) {
                                      let holdingCompany;
                                      let sumOfShares = 0;
                                      products.forEach(
                                        (
                                          product: CompanySubsidiariesInput,
                                          index: number
                                        ) => {
                                          if (product.shareHolding) {
                                            sumOfShares =
                                              sumOfShares +
                                              product.shareHolding;
                                          }
                                        }
                                      );
                                      if (sumOfShares <= 100) {
                                        products.forEach(
                                          (
                                            product: CompanySubsidiariesInput,
                                            index: number
                                          ) => {
                                            if (
                                              product &&
                                              product?.shareHolding &&
                                              product?.shareHolding > 50
                                            ) {
                                              holdingCompany =
                                                product.companyName;
                                            }
                                          }
                                        );
                                        if (isDraft) {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          draftUpdateCompanyProfile({
                                            variables: {
                                              id: companyID,
                                              companySubsidiariesInput: temp,
                                              holdingCompany: holdingCompany
                                                ? holdingCompany
                                                : '',
                                            },
                                          });
                                        } else {
                                          let temp = JSON.parse(
                                            JSON.stringify(products)
                                          );
                                          temp.forEach((element: any) => {
                                            delete element['__typename'];
                                          });
                                          updateCompanyProfile({
                                            variables: {
                                              id: companyID,
                                              companySubsidiariesInput: temp,
                                              holdingCompany: holdingCompany
                                                ? holdingCompany
                                                : '',
                                            },
                                          });
                                        }
                                      } else {
                                        dispatch(
                                          setToastError(
                                            'Sum of share holding% of all subsdiaries should be less than 100!'
                                          )
                                        );
                                      }
                                    } else {
                                      dispatch(
                                        setToastError(
                                          'Please enter data of all input fields!'
                                        )
                                      );
                                    }
                                  }}
                                />
                                <i
                                  className="bx bxs-x-circle text-danger"
                                  onClick={() => {
                                    setEditableRow(-1);
                                    setProducts(tempProducts);
                                  }}
                                />
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {(products?.length === 0 || products === undefined) &&
                  companyView && (
                    <div className="addDiv">
                      <h6 className="">No records found</h6>
                    </div>
                  )}
                {!companyView && (
                  <div className="addDiv ">
                    <i
                      className="bx bxs-plus-circle font-22 text-primary cursor-pointer"
                      onClick={() => {
                        if (editableRow === -1) {
                          if (products) {
                            setTempProducts(products);
                            let newProduct = [...products];
                            newProduct.push({
                              id: uuidv4(),
                              serialNumber: products.length + 1,
                              companyName: '',
                              incorporationNumber: undefined,
                              shareHolding: undefined,
                            });
                            setProducts(newProduct);
                            setEditableRow(products.length);
                          } else {
                            let newProduct = [
                              {
                                id: uuidv4(),
                                serialNumber: 1,
                                companyName: '',
                                incorporationNumber: undefined,
                                shareHolding: undefined,
                              },
                            ];
                            setProducts(newProduct);
                            setEditableRow(0);
                          }
                        } else {
                          dispatch(
                            setToastError(
                              'You have some unsaved data. Please save or discard it!'
                            )
                          );
                        }
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
