import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import {
  BusinessRuleDurationEnum,
  CompanyKindEnum,
} from '../../../commons/enums';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';
import { ActionItemsInputDto } from './types';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import attachFile from '/images/attachFile.png';
import {
  CREATE_BUSINESS_RULE,
  FIND_BUSINESS_RULE_BY_ID,
  UPDATE_BUSINESS_RULE,
} from '~/Store/graphQL/BusinessRules';

export const AddBusinessRule: FC = () => {
  const { selectedBusinessRuleId, businessRuleView } = useAppSelector(
    state => state.businessRuleReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [businessRuleData, setBusinessRuleData] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [selectOrdinanceName, setSelectOrdinanceName] = useState<any>();
  const [selectSection, setSelectSection] = useState<any>();
  const [actionItemError, setActionItemError] = useState<string>();
  const [durationError, setDurationError] = useState<string>();
  const [actionItems, setActionItems] = useState<
    ActionItemsInputDto[] | undefined
  >();
  const [tempActionItems, setTempActionItems] = useState<
    ActionItemsInputDto[] | undefined
  >();
  useEffect(() => {
    getAllOrdinance();

    dispatch(
      setBreadCrumb([
        {
          url: '/admin/businessRules',
          title: 'Business Rules',
        },
        {
          url: location.pathname,
          title: 'Add Business Rule',
        },
      ])
    );
  }, []);

  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    fetchPolicy: 'no-cache',
  });
  // find business rule by id query
  const [
    findBusinessRuleById,
    {
      loading: loadingFindBusinessRuleById,
      error: errorFindBusinessRuleById,
      data: dataFindBusinessRuleById,
    },
  ] = useLazyQuery<any>(FIND_BUSINESS_RULE_BY_ID);
  //   update business rule
  const [
    updateBusinessRule,
    {
      loading: loadingUpdateBusinessRule,
      error: errorUpdateBusinessRule,
      data: dataUpdateBusinessRule,
    },
  ] = useMutation<any>(UPDATE_BUSINESS_RULE);
  // create business rule
  const [
    createBusinessRule,
    {
      loading: loadingCreateBusinessRule,
      error: errorCreateBusinessRule,
      data: dataCreateBusinessRule,
    },
  ] = useMutation<any>(CREATE_BUSINESS_RULE);

  useEffect(() => {
    if (errorGetAllOrdinance) {
      console.log('errorGetAllOrdinance', errorGetAllOrdinance);
      dispatch(setToastError(errorGetAllOrdinance));
    }
  }, [errorGetAllOrdinance]);

  useEffect(() => {
    if (selectOrdinanceName && dataGetAllOrdinance) {
      const result = dataGetAllOrdinance?.findAllOrdinance?.edges.filter(
        (ordinance: any, ordinanceIndex: number) =>
          ordinance?.node?.ordinanceName === selectOrdinanceName
      );
      setSelectSection(result);
    }
  }, [selectOrdinanceName, dataGetAllOrdinance]);

  useEffect(() => {
    if (selectedBusinessRuleId) {
      findBusinessRuleById({
        variables: {
          id: selectedBusinessRuleId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedBusinessRuleId]);
  useEffect(() => {
    if (dataFindBusinessRuleById) {
      setBusinessRuleData(
        dataFindBusinessRuleById?.findOneBusinessRule?.businessRule
      );
      setActionItems(
        dataFindBusinessRuleById?.findOneBusinessRule?.businessRule?.ruleEvents
      );
      setSelectOrdinanceName(
        dataFindBusinessRuleById?.findOneBusinessRule?.businessRule?.ordinance
      );
    } else if (errorFindBusinessRuleById) {
      console.log('errorFindBusinessRuleById:', errorFindBusinessRuleById);
    }
  }, [dataFindBusinessRuleById, errorFindBusinessRuleById]);
  useEffect(() => {
    actionItems?.forEach(element => {
      if (element.url) {
        element.fileName = element?.url?.split('/')[3];
      }
    });
  }, [actionItems]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      eventName: businessRuleData ? businessRuleData.name : '',
      companyKind: businessRuleData ? businessRuleData.kind : '',
      ordinance: businessRuleData ? businessRuleData.ordinance : '',
      section: businessRuleData ? businessRuleData.section : '',
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required('Please enter event name').nullable(),
      companyKind: Yup.string()
        .required('Please select company kind')
        .nullable(),
      section: Yup.string().required('Please select section').nullable(),
      ordinance: Yup.string().required('Please enter ordinance').nullable(),
    }),
    onSubmit: (values: any) => {
      if (
        (actionItems && actionItems?.length <= 0) ||
        (actionItems?.length === 1 && actionItems[0].toBeDeleted === true) ||
        !actionItems
      ) {
        dispatch(setToastError('Please add atleast 1 action item!'));
      } else {
        actionItems?.forEach((element: any) => {
          delete element['__typename'];
          delete element['fileName'];
        });
        if (businessRuleData && selectedBusinessRuleId && !businessRuleView) {
          updateBusinessRule({
            variables: {
              id: selectedBusinessRuleId,
              name: values.eventName,
              kind: values.companyKind,
              ordinance: values.ordinance,
              section: values.section,
              ruleEvents: actionItems,
            },
          })
            .then((res: any) => {
              if (res?.data?.updateBusinessRule?.businessRule) {
                dispatch(setToastSuccess('Business Rule updated!'));
                navigate(`/admin/businessRules`);
              }
            })
            .catch(err => {
              dispatch(setToastError(err));
            });
        } else {
          createBusinessRule({
            variables: {
              name: values.eventName,
              kind: values.companyKind,
              ordinance: values.ordinance,
              section: values.section,
              ruleEvents: actionItems,
            },
          })
            .then((res: any) => {
              if (res?.data?.createBusinessRule?.businessRule) {
                dispatch(setToastSuccess('Business Rule created!'));
                navigate(`/admin/businessRules`);
              }
            })
            .catch(err => {
              dispatch(setToastError(err));
            });
        }
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindBusinessRuleById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Business Rule Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="eventName">Event Name*</label>
                      {validation.touched.eventName &&
                      validation.errors.eventName ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.eventName}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        validation.touched.eventName &&
                        validation.errors.eventName &&
                        'is-invalid'
                      }`}
                      id="eventName"
                      name="eventName"
                      placeholder="Event Name"
                      value={validation.values.eventName}
                      disabled={businessRuleView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <label htmlFor="companyKind">Company Kind*</label>
                      {validation.touched.companyKind &&
                      validation.errors.companyKind ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.companyKind}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.companyKind &&
                          validation.errors.companyKind &&
                          'is-invalid'
                        }`}
                        id="companyKind"
                        name="companyKind"
                        placeholder="Company Kind"
                        value={validation.values.companyKind}
                        disabled={businessRuleView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={CompanyKindEnum.PRIVATE}>
                          Private Company
                        </option>
                        <option value={CompanyKindEnum.UNLISTED_PUBLIC_COMPANY}>
                          Public Company
                        </option>
                        <option value={CompanyKindEnum.LISTED_COMPANY}>
                          Listed Company
                        </option>
                        <option value={CompanyKindEnum.SMC}>SMC</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"></div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="ordinance">Ordinance*</label>
                      {validation.touched.ordinance &&
                      validation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.ordinance &&
                          validation.errors.ordinance &&
                          'is-invalid'
                        }`}
                        id="ordinance"
                        name="ordinance"
                        placeholder="Ordinance"
                        value={validation.values.ordinance}
                        disabled={businessRuleView}
                        onChange={e => {
                          validation.setFieldValue('ordinance', e.target.value);
                          setSelectOrdinanceName(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                          (ordinance: any, ordinanceIndex: number) => (
                            <option value={ordinance?.node?.ordinanceName}>
                              {ordinance?.node?.ordinanceName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex ">
                      <label htmlFor="section">Section*</label>
                      {validation.touched.section &&
                      validation.errors.section ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.section}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <select
                        className={`form-control w-100 ${
                          validation.touched.section &&
                          validation.errors.section &&
                          'is-invalid'
                        }`}
                        id="section"
                        name="section"
                        placeholder="Section"
                        value={validation.values.section}
                        disabled={businessRuleView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        {selectSection &&
                          selectSection[0]?.node?.section.map(
                            (section: any, sectionIndex: number) => (
                              <>
                                <option value={section?.id}>
                                  {section?.sectionName}
                                </option>
                              </>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6"></div>
              </div>

              <hr className="my-4"></hr>
              <div>
                <div className="formGroupHeading">Action Items</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              {actionItems?.map(
                (actionItem: ActionItemsInputDto, index: number) =>
                  actionItem.toBeDeleted !== true ? (
                    <div
                      key={index}
                      className="d-flex justify-content-start align-items-center"
                    >
                      {editableRow !== index ? (
                        <>
                          <div className="row w-100 d-flex justify-content-start align-items-center">
                            <div className="col-4">
                              <div className="form-group">
                                <div className="d-flex">
                                  <label htmlFor="actionItem">
                                    Action Item
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    actionItemError &&
                                    editableRow === index &&
                                    'is-invalid'
                                  }`}
                                  id="actionItem"
                                  name="actionItem"
                                  placeholder="Action Item"
                                  value={actionItem?.name}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-3 col-4">
                              <div className="form-group">
                                <div className="d-flex ">
                                  <label htmlFor="duration">Duration</label>
                                </div>
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    durationError &&
                                    editableRow === index &&
                                    'is-invalid'
                                  }`}
                                  id="duration"
                                  name="duration"
                                  placeholder="Action Item"
                                  value={actionItem?.duration}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label htmlFor="attachment">
                                      Attachment
                                    </label>
                                  </div>
                                  {actionItems[index].attachment ||
                                  actionItems[index].url ? (
                                    <ul className="d-flex align-items-center input-label-margin">
                                      <li>{actionItems[index].fileName}</li>
                                    </ul>
                                  ) : (
                                    <ul className="d-flex align-items-center input-label-margin">
                                      <li>N/A</li>
                                    </ul>
                                  )}
                                </div>
                              </>
                            </div>
                            {!businessRuleView ? (
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                <div className="form-group">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <label htmlFor="actions">Actions</label>
                                  </div>
                                  <div className="input-label-margin">
                                    <i
                                      className={
                                        'bx bxs-edit-alt bx-sm text-primary mx-2 cursor-pointer'
                                      }
                                      onClick={() => {
                                        if (editableRow === -1) {
                                          setEditableRow(index);
                                          setTempActionItems(actionItems);
                                        }
                                      }}
                                    />

                                    <i
                                      className={
                                        'bx bxs-trash-alt bx-sm text-danger cursor-pointer'
                                      }
                                      onClick={() => {
                                        if (editableRow === -1) {
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              let temp = [...actionItems];
                                              temp[index].toBeDeleted = true;
                                              setActionItems(temp);
                                              ConfirmationDialogue.fire(
                                                'Deleted!',
                                                '',
                                                'success'
                                              );
                                            } else if (
                                              /* Read more about handling dismissals below */
                                              result.dismiss ===
                                              Swal.DismissReason.cancel
                                            ) {
                                              ConfirmationDialogue.fire(
                                                'Cancelled',
                                                '',
                                                'error'
                                              );
                                            }
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row w-100 d-flex justify-content-start align-items-center">
                            <div className="col-4">
                              <div className="form-group">
                                <div className="d-flex ">
                                  <label htmlFor="actionItem">
                                    Action Item*
                                  </label>
                                  {actionItemError ? (
                                    <p className="text-danger font-12 ms-auto mb-0">
                                      {actionItemError}
                                    </p>
                                  ) : null}
                                </div>
                                <input
                                  type="text"
                                  className={`form-control w-100 ${
                                    actionItemError && 'is-invalid'
                                  }`}
                                  id="actionItem"
                                  name="actionItem"
                                  placeholder="Action Item"
                                  value={actionItem?.name}
                                  disabled={businessRuleView}
                                  onChange={e => {
                                    setActionItemError(undefined);
                                    const { value } = e.target;
                                    const temp = [...actionItems];
                                    temp[index].name = value;
                                    setActionItems(temp);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-3 col-4">
                              <div className="form-group">
                                <div className="d-flex ">
                                  <label htmlFor="duration">Duration*</label>
                                  {durationError ? (
                                    <p className="text-danger font-12 ms-auto mb-0">
                                      {durationError}
                                    </p>
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <select
                                    className={`form-control w-100 ${
                                      durationError && 'is-invalid'
                                    }`}
                                    id="duration"
                                    name="duration"
                                    placeholder="duration"
                                    value={actionItem?.duration}
                                    disabled={businessRuleView}
                                    onChange={e => {
                                      setDurationError(undefined);
                                      const { value } = e.target;
                                      const temp = [...actionItems];
                                      temp[index].duration = parseInt(value);
                                      setActionItems(temp);
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option
                                      value={
                                        BusinessRuleDurationEnum.FIFTEEN_DAYS
                                      }
                                    >
                                      15 Days
                                    </option>
                                    <option
                                      value={
                                        BusinessRuleDurationEnum.THIRTY_DAYS
                                      }
                                    >
                                      30 Days
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 d-flex justify-content-start align-items-center">
                              {actionItems[index].attachment ||
                              actionItems[index].url ? (
                                <div className="form-group">
                                  <div className="d-flex ">
                                    <label htmlFor="attachment">
                                      Attachment
                                    </label>
                                  </div>
                                  <ul className="d-flex align-items-center input-label-margin">
                                    <li>{actionItems[index].fileName}</li>
                                    <i
                                      className="bx bx-x bx-sm mx-3 cursor-pointer"
                                      onClick={e => {
                                        let tempActionItems = [...actionItems];
                                        tempActionItems[index].attachment =
                                          undefined;
                                        tempActionItems[index].fileName =
                                          undefined;
                                        tempActionItems[index].url = '';
                                        setActionItems(tempActionItems);
                                      }}
                                    ></i>
                                  </ul>
                                </div>
                              ) : (
                                <div className="form-group">
                                  <div className="d-flex">
                                    <label htmlFor="attachment">
                                      Attachment
                                    </label>
                                  </div>
                                  <label className="d-flex align-items-center width-60 text-disabled cursor-pointer me-5 input-label-margin">
                                    <input
                                      type="file"
                                      accept=".jpg,.jpeg,.png,.pdf,.docx"
                                      onChangeCapture={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (e?.target?.files) {
                                          const selectedFile =
                                            e?.target?.files[0];

                                          // Perform file type validation
                                          if (selectedFile) {
                                            const allowedFileTypes = [
                                              '.jpg',
                                              '.jpeg',
                                              '.png',
                                              '.pdf',
                                              '.docx',
                                            ];
                                            const fileExtension =
                                              selectedFile.name.substring(
                                                selectedFile.name.lastIndexOf(
                                                  '.'
                                                )
                                              );
                                            if (
                                              !allowedFileTypes.includes(
                                                fileExtension.toLowerCase()
                                              )
                                            ) {
                                              // File type not allowed
                                              dispatch(
                                                setToastError(
                                                  'Only JPG, PNG, PDF and DOCX files are allowed.'
                                                )
                                              );
                                              e.target.value = ''; // Clear the selected file
                                              return;
                                            }

                                            // Perform further processing with the selected file
                                            let tempActionItems = [
                                              ...actionItems,
                                            ];
                                            tempActionItems[
                                              editableRow
                                            ].attachment = e?.target?.files[0];
                                            tempActionItems[
                                              editableRow
                                            ].fileName = e.target.files[0].name;

                                            setActionItems(tempActionItems);
                                          }
                                        }
                                      }}
                                    />
                                    <img
                                      className="cursor-pointer height-32 me-2"
                                      src={attachFile}
                                    ></img>
                                    Upload File
                                  </label>
                                </div>
                              )}
                            </div>
                            <div className="col-2 d-flex justify-content-center align-items-center">
                              <div className="form-group">
                                <div className="d-flex justify-content-center align-items-center">
                                  <label htmlFor="actions">Actions</label>
                                </div>
                                <div className="input-label-margin">
                                  <i
                                    className="bx bx-check-circle bx-md me-2 text-success cursor-pointer"
                                    onClick={() => {
                                      if (
                                        actionItems[editableRow].name &&
                                        actionItems[editableRow].duration
                                      ) {
                                        let temp = [...actionItems];
                                        temp.forEach((element: any) => {
                                          delete element['__typename'];
                                        });
                                        temp[editableRow] = actionItem;
                                        setEditableRow(-1);
                                        setActionItems(actionItems);
                                      } else {
                                        if (!actionItems[editableRow].name) {
                                          setActionItemError(
                                            'Please enter action item'
                                          );
                                        }
                                        if (
                                          !actionItems[editableRow].duration
                                        ) {
                                          setDurationError(
                                            'Please enter duration'
                                          );
                                        }
                                      }
                                    }}
                                  ></i>
                                  <i
                                    className="bx bx-x-circle bx-md text-danger cursor-pointer"
                                    onClick={() => {
                                      setEditableRow(-1);
                                      setActionItems(tempActionItems);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null
              )}

              {editableRow === -1 && !businessRuleView ? (
                <div className="d-flex justify-content-start align-items-center">
                  <i className="bx bx-plus me-1 color-disabled"></i>
                  <div
                    className="color-disabled cursor-pointer"
                    onClick={() => {
                      if (editableRow === -1) {
                        if (actionItems) {
                          setTempActionItems(actionItems);
                          let newActionItem = [...actionItems];
                          newActionItem.push({
                            name: '',
                            duration: undefined,
                          });
                          setActionItems(newActionItem);
                          setEditableRow(actionItems.length);
                        } else {
                          let newActionItem = [
                            {
                              name: '',
                              duration: undefined,
                            },
                          ];
                          setActionItems(newActionItem);
                          setEditableRow(0);
                        }
                      }
                    }}
                  >
                    Add New
                  </div>
                </div>
              ) : null}
              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  disabled={
                    loadingFindBusinessRuleById ||
                    loadingUpdateBusinessRule ||
                    loadingCreateBusinessRule
                  }
                  onMouseDown={() => {
                    navigate('/admin/businessRules');
                  }}
                >
                  Cancel
                </button>
                {!businessRuleView ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={
                      loadingCreateBusinessRule ||
                      loadingUpdateBusinessRule ||
                      loadingFindBusinessRuleById
                    }
                    onMouseDown={() => {
                      if (editableRow !== -1) {
                        dispatch(
                          setToastError(
                            'You have some unsaved changes, please save them first!'
                          )
                        );
                      } else {
                        validation.handleSubmit();
                      }
                    }}
                  >
                    {(loadingFindBusinessRuleById ||
                      loadingCreateBusinessRule ||
                      loadingUpdateBusinessRule) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
