import { createSlice } from "@reduxjs/toolkit";
import { Noti, Notification } from "./types";

const initialState: Notification = {
  deviceToken: '',
  notificationsArray: [],
}
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    setNotificationsInDropdown: (state, action) => {
      state.notificationsArray = action.payload;
    },
    
  },
});

export const { setDeviceToken, setNotificationsInDropdown } = notificationSlice.actions;

export default notificationSlice.reducer;

