import React, {
  Component,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './agenda.scss';
import {
  BusinessTypeEnum,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
} from '../../../commons/enums';
import {
  CREATE_AGENDA,
  DELETE_AGENDA,
  FIND_AGENDA_BY_ID,
  UPDATE_AGENDA,
} from '~/Store/graphQL/AgendaManagement';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ActionItemsInputDto } from './types';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import './agenda.scss';
import { GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';

export class AddAgenda extends Component {
  // componentWillUnmount() {
  //   // debugger;
  //   window.alert('Alert!!');
  // }

  render() {
    return <AddAgendaCom />;
  }
}

const AddAgendaCom = ({}: {}): JSX.Element => {
  const { selectedAgendaId, agendaView } = useAppSelector(
    state => state.agendaReducer
  );

  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [agendaData, setAgendaData] = useState<any>();
  const [selectOrdinanceName, setSelectOrdinanceName] = useState<any>();
  const [selectSection, setSelectSection] = useState<any>();

  const [editableRow, setEditableRow] = useState<number>(-1);
  const [actionItemError, setActionItemError] = useState<string>();
  const [actionItems, setActionItems] = useState<
    ActionItemsInputDto[] | undefined
  >();
  const [tempActionItems, setTempActionItems] = useState<
    ActionItemsInputDto[] | undefined
  >();
  useEffect(() => {
    getAllOrdinance();

    dispatch(
      setBreadCrumb([
        {
          url: '/agenda',
          title: 'Agenda',
        },
        {
          url: location.pathname,
          title: 'Add Agenda',
        },
      ])
    );
  }, []);
  // get all ordinance info
  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    fetchPolicy: 'no-cache',
  });

  // Getting agenda information
  const [
    findAgendaById,
    {
      loading: loadingFindAgendaById,
      error: errorFindAgendaById,
      data: dataFindAgendaById,
    },
  ] = useLazyQuery<any>(FIND_AGENDA_BY_ID);
  //   update agenda
  const [
    updateAgenda,
    {
      loading: loadingUpdateAgenda,
      error: errorUpdateAgenda,
      data: dataUpdateAgenda,
    },
  ] = useMutation<any>(UPDATE_AGENDA);
  // create agenda
  const [
    createAgenda,
    {
      loading: loadingCreateAgenda,
      error: errorCreateAgenda,
      data: dataCreateAgenda,
    },
  ] = useMutation<any>(CREATE_AGENDA);

  useEffect(() => {
    if (selectOrdinanceName) {
      const result = dataGetAllOrdinance?.findAllOrdinance?.edges.filter(
        (ordinance: any, ordinanceIndex: number) =>
          ordinance?.node?.ordinanceName === selectOrdinanceName
      );
      setSelectSection(result);
    }
  }, [selectOrdinanceName]);

  useEffect(() => {
    if (selectedAgendaId) {
      findAgendaById({
        variables: {
          id: selectedAgendaId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedAgendaId]);
  useEffect(() => {
    if (dataFindAgendaById) {
      setAgendaData(dataFindAgendaById?.findOneAgenda?.agenda);
      setActionItems(dataFindAgendaById?.findOneAgenda?.agenda?.actionItems);
    } else if (errorFindAgendaById) {
      console.log('errorFindAgendaById:', errorFindAgendaById);
    }
  }, [dataFindAgendaById, errorFindAgendaById]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      meetingType: agendaData ? agendaData.meetingType : '',
      meetingSubType: agendaData ? agendaData.meetingSubType : '',
      section: agendaData ? agendaData.ordinanceSection : '',
      ordinance: agendaData ? agendaData.ordinanceType : '',
      businessType: agendaData ? agendaData.businessType : '',
    },
    validationSchema: Yup.object({
      meetingType: Yup.string()
        .required('Please select meeting type')
        .nullable(),
      meetingSubType: Yup.string()
        .required('Please select meeting sub type')
        .nullable(),
      section: Yup.string().required('Please select section').nullable(),
      ordinance: Yup.string().required('Please enter ordinance').nullable(),
      businessType: Yup.string()
        .required('Please enter business type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      actionItems?.forEach((element: any) => {
        delete element['__typename'];
      });
      if (agendaData && selectedAgendaId && !agendaView) {
        updateAgenda({
          variables: {
            id: selectedAgendaId,
            companyId: companyID,
            ordinanceType: values.ordinance,
            ordinanceSection: values.section,
            meetingType: values.meetingType,
            meetingSubType: values.meetingSubType,
            businessType: values.businessType,
            actionItems: actionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateAgenda?.agenda) {
              dispatch(setToastSuccess('Agenda updated!'));
              navigate(`/agenda`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createAgenda({
          variables: {
            companyId: companyID,
            ordinanceType: values.ordinance,
            ordinanceSection: values.section,
            meetingType: values.meetingType,
            meetingSubType: values.meetingSubType,
            businessType: values.businessType,
            actionItems: actionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.createAgenda?.agenda) {
              dispatch(setToastSuccess('Agenda created!'));
              navigate(`/agenda`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindAgendaById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100">
          <div id="mainComponentHeading">Agenda Info</div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Agenda Info</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="meetingType">Meeting Type*</label>
                      {validation.touched.meetingType &&
                      validation.errors.meetingType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.meetingType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.meetingType &&
                          validation.errors.meetingType &&
                          'is-invalid'
                        }`}
                        id="meetingType"
                        name="meetingType"
                        placeholder="Meeting Type"
                        value={validation.values.meetingType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={MeetingTypeEnum.BOARD_MEETING}>
                          Board Meeting
                        </option>
                        <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                          Committee Meeting
                        </option>
                        <option value={MeetingTypeEnum.GENERAL_MEETING}>
                          General Meeting
                        </option>
                        <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                          Statutory Meeting
                        </option>
                        <option value={MeetingTypeEnum.OTHER}>
                          Other Meeting
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="meetingSubType">Meeting Sub Type*</label>
                      {validation.touched.meetingSubType &&
                      validation.errors.meetingSubType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.meetingSubType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.meetingSubType &&
                          validation.errors.meetingSubType &&
                          'is-invalid'
                        }`}
                        id="meetingSubType"
                        name="meetingSubType"
                        placeholder="Meeting Sub Type"
                        value={validation.values.meetingSubType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        {validation.values.meetingType ===
                        MeetingTypeEnum.BOARD_MEETING ? (
                          <>
                            <option value={MeetingSubTypeEnum.BOARD_MEETING}>
                              Board Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.GENERAL_MEETING ? (
                          <>
                            <option
                              value={MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING}
                            >
                              Annual General Meeting
                            </option>

                            <option
                              value={
                                MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING
                              }
                            >
                              Extraordinary General Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.COMMITTEE_MEETING ? (
                          <>
                            <option
                              value={MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING}
                            >
                              Audit Committee Meeting
                            </option>
                            <option
                              value={
                                MeetingSubTypeEnum.EXECUTIVE_COMMITTEE_MEETING
                              }
                            >
                              Executive Committee Meeting
                            </option>
                            <option
                              value={MeetingSubTypeEnum.HR_COMMITTEE_MEETING}
                            >
                              HR Committee Meeting
                            </option>
                            <option
                              value={
                                MeetingSubTypeEnum.NOMINATION_COMMITTEE_MEETING
                              }
                            >
                              Nomination Committee Meeting
                            </option>
                            <option
                              value={
                                MeetingSubTypeEnum.PROCUREMENT_COMMITTEE_MEETING
                              }
                            >
                              Precurement Committee Meeting
                            </option>
                            <option
                              value={
                                MeetingSubTypeEnum.RISK_MANAGEMENT_COMMITTEE_MEETING
                              }
                            >
                              Risk Management Committee Meeting
                            </option>
                            <option
                              value={MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING}
                            >
                              Other Committee Meeting
                            </option>
                          </>
                        ) : null}
                        {validation.values.meetingType ===
                        MeetingTypeEnum.STATUTORY_MEETING ? (
                          <option value={MeetingSubTypeEnum.STATUTORY_MEETING}>
                            Statutory Meeting
                          </option>
                        ) : null}

                        <option value={MeetingSubTypeEnum.OTHER}>
                          Other Meeting
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="businessType">Business Type*</label>
                      {validation.touched.businessType &&
                      validation.errors.businessType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.businessType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.businessType &&
                          validation.errors.businessType &&
                          'is-invalid'
                        }`}
                        id="businessType"
                        name="businessType"
                        placeholder="Business Type"
                        value={validation.values.businessType}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={BusinessTypeEnum.ORDINARY_BUSINESS}>
                          Ordinary Business
                        </option>
                        <option value={BusinessTypeEnum.SPECIAL_BUSINESS}>
                          Special Business
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="ordinance">Ordinance*</label>
                      {validation.touched.ordinance &&
                      validation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.ordinance &&
                          validation.errors.ordinance &&
                          'is-invalid'
                        }`}
                        id="ordinance"
                        name="ordinance"
                        placeholder="Ordinance"
                        value={validation.values.ordinance}
                        disabled={agendaView}
                        onChange={e => {
                          validation.setFieldValue('ordinance', e.target.value);
                          setSelectOrdinanceName(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                          (ordinance: any, ordinanceIndex: number) => (
                            <option value={ordinance?.node?.ordinanceName}>
                              {ordinance?.node?.ordinanceName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="section">Section*</label>
                      {validation.touched.section &&
                      validation.errors.section ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.section}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control form-control-medium ${
                          validation.touched.section &&
                          validation.errors.section &&
                          'is-invalid'
                        }`}
                        id="section"
                        name="section"
                        placeholder="Section"
                        value={validation.values.section}
                        disabled={agendaView}
                        onChange={validation.handleChange}
                      >
                        <option value="">Select</option>

                        {selectSection &&
                          selectSection[0]?.node?.section.map(
                            (section: any, sectionIndex: number) => (
                              <>
                                <option value={section?.id}>
                                  {section?.sectionName}
                                </option>
                              </>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-xl-6"></div>
              </div>

              <hr className="my-4"></hr>
              <div>
                <div className="formGroupHeading">Action Items</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>
              {actionItems?.map((actionItem: any, index: number) => (
                <div
                  key={index}
                  className="d-flex justify-content-start align-items-center"
                >
                  <div className="me-3">{index + 1}.</div>
                  {editableRow !== index ? (
                    <>
                      <div className="action-items my-3">
                        {actionItem?.actionItem}
                      </div>
                      {!agendaView ? (
                        <>
                          <i
                            className={
                              'bx bxs-edit-alt text-primary mx-2 cursor-pointer'
                            }
                            onClick={() => {
                              if (editableRow === -1) {
                                setEditableRow(index);
                                setTempActionItems(actionItems);
                              }
                            }}
                          />
                          <i
                            className={
                              'bx bxs-trash-alt text-danger cursor-pointer'
                            }
                            onClick={() => {
                              if (editableRow === -1) {
                                // ConfirmationDialogue.fire({
                                //   title: 'Are you sure?',
                                //   text: "You won't be able to revert this!",
                                //   icon: 'warning',
                                //   showCancelButton: true,
                                //   confirmButtonText: 'Delete',
                                //   cancelButtonText: 'Cancel',
                                //   reverseButtons: true,
                                // }).then(result => {
                                //   if (result.isConfirmed) {
                                let temp = [...actionItems];
                                temp.splice(index, 1);
                                let temp2 = JSON.parse(
                                  JSON.stringify(actionItems)
                                );
                                temp2.forEach((element: any) => {
                                  delete element['__typename'];
                                });
                                temp2[index].toBeDeleted = true;
                                setActionItems(temp);
                                // ConfirmationDialogue.fire(
                                //   'Deleted!',
                                //   '',
                                //   'success'
                                // );
                                // } else if (
                                //   /* Read more about handling dismissals below */
                                //   result.dismiss === Swal.DismissReason.cancel
                                // ) {
                                //   ConfirmationDialogue.fire(
                                //     'Cancelled',
                                //     '',
                                //     'error'
                                //   );
                                // }
                                // });
                              }
                            }}
                          />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="text-end">
                          {actionItemError ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {actionItemError}
                            </p>
                          ) : null}
                          <input
                            type="text"
                            id="actionItem"
                            name="actionItem"
                            placeholder="Action Item"
                            className={`form-control form-medium form-control-no-margin-bottom`}
                            value={actionItem?.actionItem}
                            disabled={agendaView}
                            onChange={e => {
                              setActionItemError(undefined);
                              const { value } = e.target;
                              const temp = JSON.parse(
                                JSON.stringify(actionItems)
                              );
                              temp[index].actionItem = value;
                              setActionItems(temp);
                            }}
                          />
                        </div>

                        <i
                          className="bx bx-check-circle bx-md ms-4 me-2 text-success cursor-pointer"
                          onClick={() => {
                            if (actionItems[editableRow].actionItem) {
                              let temp = JSON.parse(
                                JSON.stringify(actionItems)
                              );
                              temp.forEach((element: any) => {
                                delete element['__typename'];
                              });
                              setEditableRow(-1);
                              setActionItems(actionItems);
                            } else {
                              setActionItemError('Please enter action item');
                            }
                          }}
                        ></i>
                        <i
                          className="bx bx-x-circle bx-md text-danger cursor-pointer"
                          onClick={() => {
                            setEditableRow(-1);
                            setActionItems(tempActionItems);
                          }}
                        ></i>
                      </div>
                    </>
                  )}
                </div>
              ))}

              {!agendaView && editableRow === -1 ? (
                <div className="d-flex justify-content-start align-items-center">
                  <i className="bx bx-plus me-1 color-disabled"></i>
                  <div
                    className="color-disabled cursor-pointer"
                    onClick={() => {
                      if (editableRow === -1) {
                        if (actionItems) {
                          setTempActionItems(actionItems);
                          let newActionItem = [...actionItems];
                          newActionItem.push({
                            id: uuidv4(),
                            actionItem: '',
                            agendaId: selectedAgendaId,
                          });
                          setActionItems(newActionItem);
                          setEditableRow(actionItems.length);
                        } else {
                          let newActionItem = [
                            {
                              id: uuidv4(),
                              actionItem: '',
                              agendaId: selectedAgendaId,
                            },
                          ];
                          setActionItems(newActionItem);
                          setEditableRow(0);
                        }
                      }
                    }}
                  >
                    Add New
                  </div>
                </div>
              ) : null}

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn primaryButton d-flex align-items-center my-auto"
                  disabled={
                    loadingFindAgendaById ||
                    loadingUpdateAgenda ||
                    loadingCreateAgenda
                  }
                  onMouseDown={() => {
                    navigate('/agenda');
                  }}
                >
                  Cancel
                </button>
                {!agendaView ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={
                      loadingFindAgendaById ||
                      loadingUpdateAgenda ||
                      editableRow !== -1
                    }
                    onMouseDown={() => {
                      if (actionItems && actionItems?.length !== 0) {
                        validation.handleSubmit();
                      } else {
                        dispatch(
                          setToastError('Please add atleast 1 action item!')
                        );
                      }
                    }}
                  >
                    {(loadingFindAgendaById ||
                      loadingUpdateAgenda ||
                      loadingCreateAgenda) && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
