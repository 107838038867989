import { gql } from '@apollo/client';

export const DASHBOARD_DATA = gql`
query getDashboardData {
  getDashboardUserData {
    totalUser
    totalSubcription
    activeUser
    paidSubcription
    unpaidSubcription
    expiredSubcription
    totalRevenue
    email
   }
  }
    
`;




export const USER_DASHBOARD_DATA = gql`
query getDashboardData(
  $companyId: ID!  )
 
{
  getDashboardMeetingData(
    filterBy: { companyId: $companyId}    
  )
  {
  
    pendingMom
    completedMom
    pendingMeeting
    completedMeetings
    completedReturn
    pendingReturn
    actualMember
    actualOfficer
    draftMember
    draftOfficer
  }
}
    
`;





