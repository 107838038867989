import { createSlice } from '@reduxjs/toolkit';
import { HelpStoreType } from './types';

const initialState: HelpStoreType = {
  selectedHelp: 'DASHBOARD',
};
const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    setSelectedHelp: (state, { payload }) => {
      state.selectedHelp = payload;
    },

  },
});

export const { setSelectedHelp } = helpSlice.actions;

export default helpSlice.reducer;
