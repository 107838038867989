import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setActionItems,
  setAuditor,
  setChair,
  setCity,
  setCountry,
  setInvitees,
  setIsVirtualMeeting,
  setMeetingSubType,
  setMeetingType,
  setMeetingViewScreen,
  setMinuteTaker,
  setOtherRoom,
  setProvince,
  setRoom,
  setSelectedMeetingId,
  setSubject,
  setVenue,
  setZoomLink,
} from '~/Store/reducers/meeting';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import {
  CommitteeSearchParameterOptionsEnum,
  DesignationEnum,
  MeetingSearchParameterOptionsEnum,
  MeetingStatus,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
  ModuleEnum,
  OfficerStatusEnum,
  PermissionCategoryEnum,
  SearchParameterOptionsEnum,
  UserTypeEnum,
} from '../../../commons/enums';
import GetMeetingType from '~/helpers/Agenda/getMeetingType';
import {
  DELETE_MEETING,
  FIND_MEETING_BY_ID,
  GET_ALL_MEETINGS_OF_COMPANY,
} from '~/Store/graphQL/MeetingsManagement';
import GetMeetingSubType from '~/helpers/MeetingManagement/GetMeetingSubType';
import moment from 'moment';
import GetMeetingStatus from '~/helpers/MeetingManagement/GetMeetingStatus';
import { ScheduleMeetingModal } from './ScheduleMeeting';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import { Tooltip } from 'reactstrap';
import { AdjournIcon, ConcludeIcon, ScheduleIcon } from '~/assets/images';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const Meetings: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedMeetingId, invitees } = useAppSelector(
    state => state.meetingReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [dropDownRow, setDropDownRow] = useState(-1);
  // const [filters, setFilters] = useState<any>({
  //   searchKey: '',
  //   searchValue: '',
  // });
  const [viewToolTipIsOpen, setViewToolTipIsOpen] = useState(false);
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  const [scheduleToolTipIsOpen, setScheduleToolTipIsOpen] = useState(false);
  const [concludeToolTipIsOpen, setConcludeToolTipIsOpen] = useState(false);
  const [adjournToolTipIsOpen, setAdjournToolTipIsOpen] = useState(false);
  let pageNo = useRef(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'Meetings',
        },
      ])
    );
  }, []);
  // Get all meetings query
  const [
    getAllMeetingsForCompany,
    {
      loading: loadingGetAllMeetingsForCompany,
      error: errorGetAllMeetingsForCompany,
      data: dataGetAllMeetingsForCompany,
    },
  ] = useLazyQuery<any>(GET_ALL_MEETINGS_OF_COMPANY, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteMeeting,
    {
      loading: loadingDeleteMeeting,
      error: errorDeleteMeeting,
      data: dataDeleteMeeting,
    },
  ] = useMutation<any>(DELETE_MEETING);
  // Getting meeting information
  const [
    findMeetingById,
    {
      loading: loadingFindMeetingById,
      error: errorFindMeetingById,
      data: dataFindMeetingById,
    },
  ] = useLazyQuery<any>(FIND_MEETING_BY_ID);
  useEffect(() => {
    if (companyID && !modalVisibility) {
      getAllMeetingsForCompany({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
    }
  }, [itemsPerPage, modalVisibility]);
  useEffect(() => {
    if (errorGetAllMeetingsForCompany) {
      dispatch(setToastError(errorGetAllMeetingsForCompany));
    }
  }, [errorGetAllMeetingsForCompany]);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef?.current?.toString() !== event?.target?.toString()
      ) {
        setDropDownVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>Meetings</b>
              </h5>
            </div>
          </div>
          <div className="d-flex row mt-2 mb-4">
            <div className="committeefields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="meetingType">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option
                      value={MeetingSearchParameterOptionsEnum.MEETING_NUMBER}
                    >
                      {' '}
                      Meeting Number{' '}
                    </option>
                    <option
                      value={MeetingSearchParameterOptionsEnum.MEETING_TYPE}
                    >
                      {' '}
                      Meeting Type{' '}
                    </option>
                    <option
                      value={MeetingSearchParameterOptionsEnum.MEETING_SUB_TYPE}
                    >
                      {' '}
                      Meeting Sub TYpe{' '}
                    </option>
                    <option value={MeetingSearchParameterOptionsEnum.STATUS}>
                      {' '}
                      Status{' '}
                    </option>
                  </select>
                </div>
              </div>
              {validationSearch?.values?.searchKey ===
              MeetingSearchParameterOptionsEnum.MEETING_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={MeetingTypeEnum.BOARD_MEETING}>
                        {' '}
                        Board Meeting{' '}
                      </option>
                      <option value={MeetingTypeEnum.GENERAL_MEETING}>
                        {' '}
                        General Meeting{' '}
                      </option>
                      <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                        {' '}
                        Committee Meeting{' '}
                      </option>
                      <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                        {' '}
                        Statutory Meeting{' '}
                      </option>
                      <option value={MeetingTypeEnum.OTHER}> Other </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch?.values?.searchKey ===
                MeetingSearchParameterOptionsEnum.MEETING_SUB_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={MeetingSubTypeEnum.BOARD_MEETING}>
                        {' '}
                        Board Meeting{' '}
                      </option>
                      <option value={MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING}>
                        {' '}
                        Annual General Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING}
                      >
                        {' '}
                        Extra Ordinary General Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING}
                      >
                        {' '}
                        Audit Committee Meeting{' '}
                      </option>
                      <option value={MeetingSubTypeEnum.HR_COMMITTEE_MEETING}>
                        {' '}
                        HR Committee Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.PROCUREMENT_COMMITTEE_MEETING}
                      >
                        {' '}
                        Procurement Committee Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.NOMINATION_COMMITTEE_MEETING}
                      >
                        {' '}
                        Nomination Committee Meeting{' '}
                      </option>
                      <option
                        value={
                          MeetingSubTypeEnum.RISK_MANAGEMENT_COMMITTEE_MEETING
                        }
                      >
                        {' '}
                        Risk Management Committee Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.EXECUTIVE_COMMITTEE_MEETING}
                      >
                        {' '}
                        Executive Committee Meeting{' '}
                      </option>
                      <option
                        value={MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING}
                      >
                        {' '}
                        Other Committee Meeting{' '}
                      </option>
                      <option value={MeetingSubTypeEnum.STATUTORY_MEETING}>
                        {' '}
                        Statutory Meeting{' '}
                      </option>
                      <option value={MeetingSubTypeEnum.OTHER}> Other </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch?.values?.searchKey ===
                MeetingSearchParameterOptionsEnum.STATUS ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      } select`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={MeetingStatus.CREATED}> Created </option>
                      <option value={MeetingStatus.SCHEDULED}>
                        {' '}
                        Scheduled{' '}
                      </option>
                      <option value={MeetingStatus.ADJOURNED}>
                        {' '}
                        Adjourned{' '}
                      </option>
                      <option value={MeetingStatus.CONCLUDED}>
                        {' '}
                        Concluded{' '}
                      </option>
                      {/* <option value={MeetingStatus.CANCELLED}>
                        {' '}
                        Cancelled{' '}
                      </option> */}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type={
                      validationSearch?.values?.searchKey ===
                      MeetingSearchParameterOptionsEnum.MEETING_NUMBER
                        ? 'number'
                        : 'text'
                    }
                    id="searchValue"
                    name="searchValue"
                    placeholder={
                      validationSearch?.values?.searchKey ===
                      MeetingSearchParameterOptionsEnum.MEETING_NUMBER
                        ? 'Number'
                        : 'Text'
                    }
                    className={`form-control ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    onKeyDown={event => {
                      const keyCode = event.keyCode || event.which;
                      // Check if the key pressed is the decimal point
                      if (keyCode === 190 || keyCode === 110) {
                        event.preventDefault(); // Prevent the decimal point from being entered
                      }
                    }}
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}

              <button
                type="button"
                className="btn fw-bold dangerButton d-flex align-items-center my-auto"
                onClick={() => {
                  if (validationSearch?.values?.searchValue) {
                    getAllMeetingsForCompany({
                      variables: {
                        first: itemsPerPage,
                        searchKey: undefined,
                        searchValue: undefined,
                      },
                    });
                    validationSearch.resetForm();
                  } else {
                    validationSearch.resetForm();
                  }
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn fw-bold successButton d-flex align-items-center my-auto"
                disabled={loadingGetAllMeetingsForCompany}
                onClick={() => {
                  validationSearch.handleSubmit();
                  if (
                    validationSearch?.values?.searchValue &&
                    validationSearch.values.searchKey
                  ) {
                    getAllMeetingsForCompany({
                      variables: {
                        first: itemsPerPage,
                        searchKey:
                          validationSearch?.values?.searchKey !== 'ALL'
                            ? validationSearch?.values?.searchKey
                            : undefined,
                        searchValue:
                          validationSearch?.values?.searchValue !== 'Select'
                            ? validationSearch?.values?.searchValue
                            : undefined,
                      },
                    });
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.MEETING,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.MEETING,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setMeetingViewScreen(false));
                dispatch(setSelectedMeetingId(null));
                dispatch(setMeetingType(''));
                dispatch(setMeetingSubType(''));
                dispatch(setSubject(''));
                dispatch(setRoom(''));
                dispatch(setOtherRoom(''));
                dispatch(setChair(''));
                dispatch(setMinuteTaker(''));
                dispatch(setAuditor(''));
                dispatch(setInvitees([]));
                dispatch(setActionItems([]));
                dispatch(setVenue(''));
                dispatch(setProvince(''));
                dispatch(setCity(''));
                dispatch(setCountry(''));
                dispatch(setIsVirtualMeeting(false));
                dispatch(setZoomLink(''));
                navigate('/meetings/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add New Meeting
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.MEETING,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingGetAllMeetingsForCompany ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="col-1">S.No</th>
                            <th className="col-2">Meeting Number</th>
                            <th className="col-2">Meeting Type</th>
                            <th className="col-2">Meeting Sub Type</th>
                            <th className="col-1">Status</th>
                            <th className="col-1">Date</th>
                            <th className="col-1">Start Time</th>
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataGetAllMeetingsForCompany?.findAllMeetings?.edges?.map(
                            (val: any, index: number) => (
                              <tr key={`${index}`}>
                                <td>
                                  {pageNo.current * itemsPerPage + (index + 1)}
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.meetingNumber
                                      ? String(val.node.meetingNumber).padStart(
                                          3,
                                          '00'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  {val.node.meetingType
                                    ? GetMeetingType(val.node.meetingType)
                                    : 'N/A'}
                                </td>
                                <td>
                                  <div>
                                    {val.node.meetingSubType
                                      ? GetMeetingSubType(
                                          val.node.meetingSubType
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.meetingSubType
                                      ? GetMeetingStatus(val.node.status)
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-success">
                                    {val.node.startTime
                                      ? moment(val.node.startTime).format(
                                          'DD-MM-YYYY'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="badge custom-badge-primary">
                                    {val.node.startTime
                                      ? moment(val.node.startTime).format(
                                          'hh:mm a'
                                        )
                                      : 'N/A'}
                                  </div>
                                </td>
                                <td>
                                  <div className="editColumn mt-0 gap-1 justify-content-end">
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.VIEW
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setViewToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setViewToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bx-show-alt text-primary ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.VIEW
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="viewIcon"
                                        onClick={() => {
                                          dispatch(
                                            setSelectedMeetingId(
                                              val.node.meetingId
                                            )
                                          );
                                          dispatch(setMeetingViewScreen(true));
                                          dispatch(setMeetingType(''));
                                          dispatch(setMeetingSubType(''));
                                          dispatch(setSubject(''));
                                          dispatch(setRoom(''));
                                          dispatch(setOtherRoom(''));
                                          dispatch(setChair(''));
                                          dispatch(setMinuteTaker(''));
                                          dispatch(setAuditor(''));
                                          dispatch(setInvitees([]));
                                          dispatch(setActionItems([]));
                                          dispatch(setVenue(''));
                                          dispatch(setProvince(''));
                                          dispatch(setCity(''));
                                          dispatch(setCountry(''));
                                          dispatch(setIsVirtualMeeting(false));
                                          dispatch(setZoomLink(''));
                                          navigate('/meetings/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.MEETING,
                                          PermissionCategoryEnum.VIEW
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={viewToolTipIsOpen}
                                          target="viewIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setEditToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setEditToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-pencil text-success ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="editIcon"
                                        onClick={() => {
                                          dispatch(setMeetingViewScreen(false));
                                          dispatch(
                                            setSelectedMeetingId(
                                              val.node.meetingId
                                            )
                                          );
                                          dispatch(setMeetingType(''));
                                          dispatch(setMeetingSubType(''));
                                          dispatch(setSubject(''));
                                          dispatch(setRoom(''));
                                          dispatch(setOtherRoom(''));
                                          dispatch(setChair(''));
                                          dispatch(setMinuteTaker(''));
                                          dispatch(setAuditor(''));
                                          dispatch(setInvitees([]));
                                          dispatch(setActionItems([]));
                                          dispatch(setVenue(''));
                                          dispatch(setProvince(''));
                                          dispatch(setCity(''));
                                          dispatch(setCountry(''));
                                          dispatch(setIsVirtualMeeting(false));
                                          dispatch(setZoomLink(''));
                                          navigate('/meetings/add');
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.MEETING,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={editToolTipIsOpen}
                                          target="editIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    <div
                                      className={`d-flex justify-content-center align-items-center ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'cursor-not-allowed'
                                          : ''
                                      }`}
                                      onMouseEnter={() =>
                                        setDeleteToolTipIsOpen(true)
                                      }
                                      onMouseLeave={() =>
                                        setDeleteToolTipIsOpen(false)
                                      }
                                    >
                                      <i
                                        className={`bx bxs-trash-alt text-danger ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.DELETE
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'icon-disabled'
                                            : ''
                                        }`}
                                        id="deleteIcon"
                                        onClick={() => {
                                          dispatch(setMeetingViewScreen(false));
                                          ConfirmationDialogue.fire({
                                            title: 'Are you sure?',
                                            text: "You won't be able to revert this!",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonText: 'Delete',
                                            cancelButtonText: 'Cancel',
                                            reverseButtons: true,
                                          }).then(result => {
                                            if (result.isConfirmed) {
                                              deleteMeeting({
                                                variables: {
                                                  id: val.node.meetingId,
                                                },
                                              })
                                                .then((res: any) => {
                                                  if (
                                                    res.data.deleteMeeting.count
                                                  ) {
                                                    dispatch(
                                                      setToastSuccess(
                                                        'Meeting Deleted!'
                                                      )
                                                    );
                                                    getAllMeetingsForCompany({
                                                      variables: {
                                                        first: itemsPerPage,
                                                        searchKey:
                                                          validationSearch
                                                            ?.values
                                                            ?.searchKey !==
                                                          'ALL'
                                                            ? validationSearch
                                                                ?.values
                                                                ?.searchKey
                                                            : undefined,
                                                        searchValue:
                                                          validationSearch
                                                            ?.values
                                                            ?.searchValue !==
                                                          'Select'
                                                            ? validationSearch
                                                                ?.values
                                                                ?.searchValue
                                                            : undefined,
                                                      },
                                                    });
                                                  }
                                                })
                                                .catch(err => {
                                                  dispatch(setToastError(err));
                                                });
                                            }
                                          });
                                        }}
                                      />
                                      {!(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.MEETING,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      ) ? (
                                        <Tooltip
                                          placement="top"
                                          isOpen={deleteToolTipIsOpen}
                                          target="deleteIcon"
                                        >
                                          You don't have permission for this
                                          module
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                    {val?.node?.status ===
                                    MeetingStatus.CREATED ? (
                                      <div
                                        className={`d-flex justify-content-center align-items-center ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setScheduleToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setScheduleToolTipIsOpen(false)
                                        }
                                      >
                                        <img
                                          id="scheduleIcon"
                                          className={`cursor-pointer height-22 padding-2 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.MEETING,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          src={ScheduleIcon}
                                          onClick={() => {
                                            findMeetingById({
                                              variables: {
                                                id: val.node.meetingId,
                                              },
                                              fetchPolicy: 'no-cache',
                                            })
                                              .then(res => {
                                                if (res?.data?.findOneMeeting) {
                                                  dispatch(
                                                    setInvitees(
                                                      res?.data?.findOneMeeting?.meeting?.invitees.split(
                                                        ';'
                                                      )
                                                    )
                                                  );
                                                }
                                                setDropDownVisibility(false);
                                                dispatch(
                                                  setMeetingViewScreen(false)
                                                );
                                                dispatch(
                                                  setSelectedMeetingId(
                                                    val.node.meetingId
                                                  )
                                                );
                                                setModalVisibility(
                                                  !modalVisibility
                                                );
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }}
                                        ></img>
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={scheduleToolTipIsOpen}
                                            target="scheduleIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            placement="top"
                                            isOpen={scheduleToolTipIsOpen}
                                            target="scheduleIcon"
                                          >
                                            Schedule Meeting
                                          </Tooltip>
                                        )}
                                      </div>
                                    ) : null}

                                    {val?.node?.status ===
                                    MeetingStatus.SCHEDULED ? (
                                      <div
                                        className={`d-flex justify-content-center align-items-center ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setConcludeToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setConcludeToolTipIsOpen(false)
                                        }
                                      >
                                        <img
                                          id="concludeIcon"
                                          className={`cursor-pointer height-22 padding-2 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.MEETING,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          src={ConcludeIcon}
                                          onClick={e => {
                                            dispatch(
                                              setMeetingViewScreen(false)
                                            );
                                            dispatch(
                                              setSelectedMeetingId(
                                                val.node.meetingId
                                              )
                                            );
                                            navigate('/meetings/add/conclude');
                                          }}
                                        ></img>
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={concludeToolTipIsOpen}
                                            target="concludeIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            placement="top"
                                            isOpen={concludeToolTipIsOpen}
                                            target="concludeIcon"
                                          >
                                            Conclude Meeting
                                          </Tooltip>
                                        )}
                                      </div>
                                    ) : null}

                                    {val?.node?.status ===
                                    MeetingStatus.SCHEDULED ? (
                                      <div
                                        className={`d-flex justify-content-center align-items-center ${
                                          !(
                                            GetPermissionsOfUser(
                                              selectedUserPermissions,
                                              ModuleEnum.MEETING,
                                              PermissionCategoryEnum.EDIT
                                            ) ||
                                            userType ===
                                              UserTypeEnum.COMPANY_ADMIN
                                          )
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}
                                        onMouseEnter={() =>
                                          setAdjournToolTipIsOpen(true)
                                        }
                                        onMouseLeave={() =>
                                          setAdjournToolTipIsOpen(false)
                                        }
                                      >
                                        <img
                                          id="adjournIcon"
                                          className={`cursor-pointer height-22 padding-2 ${
                                            !(
                                              GetPermissionsOfUser(
                                                selectedUserPermissions,
                                                ModuleEnum.MEETING,
                                                PermissionCategoryEnum.EDIT
                                              ) ||
                                              userType ===
                                                UserTypeEnum.COMPANY_ADMIN
                                            )
                                              ? 'icon-disabled'
                                              : ''
                                          }`}
                                          src={AdjournIcon}
                                          onClick={e => {
                                            dispatch(
                                              setMeetingViewScreen(false)
                                            );
                                            dispatch(
                                              setSelectedMeetingId(
                                                val.node.meetingId
                                              )
                                            );
                                            navigate('/meetings/add/adjourn');
                                          }}
                                        ></img>
                                        {!(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.MEETING,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        ) ? (
                                          <Tooltip
                                            placement="top"
                                            isOpen={adjournToolTipIsOpen}
                                            target="adjournIcon"
                                          >
                                            You don't have permission for this
                                            module
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            placement="top"
                                            isOpen={adjournToolTipIsOpen}
                                            target="adjournIcon"
                                          >
                                            Adjourn Meeting
                                          </Tooltip>
                                        )}
                                      </div>
                                    ) : null}

                                    {(GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.MEETING,
                                      PermissionCategoryEnum.GENERATE_DOCUMENTS
                                    ) ||
                                      userType ===
                                        UserTypeEnum.COMPANY_ADMIN) &&
                                    (val?.node?.status ===
                                      MeetingStatus.CONCLUDED ||
                                      val?.node?.status ===
                                        MeetingStatus.ADJOURNED) ? (
                                      <div className="dropleft d-flex justify-content-center align-items-center">
                                        <i
                                          id="dropdownMenuButton"
                                          ref={dropdownRef}
                                          className="bx bx-dots-vertical-rounded"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          onClick={() => {
                                            setDropDownRow(index);
                                            toggleModal();
                                          }}
                                        ></i>
                                        <ul
                                          className={`dropdown-menu min-width-8 ${
                                            dropDownVisibility &&
                                            index === dropDownRow &&
                                            'show'
                                          } `}
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {val?.node?.status ===
                                            MeetingStatus.CONCLUDED ||
                                          val?.node?.status ===
                                            MeetingStatus.ADJOURNED ? (
                                            <li className="cursor-pointer">
                                              <div
                                                className="dropdown-item"
                                                onClick={() => {
                                                  dispatch(
                                                    setMeetingViewScreen(false)
                                                  );
                                                  if (val?.node?.url) {
                                                    setDropDownVisibility(
                                                      false
                                                    );
                                                    const uniqueTimestamp =
                                                      Date.now();
                                                    window.open(
                                                      `${val?.node?.url}?t=${uniqueTimestamp}`,
                                                      'new',
                                                      'clearcache=yes'
                                                    );
                                                  }
                                                }}
                                              >
                                                Generate Report
                                              </div>
                                            </li>
                                          ) : null}
                                        </ul>
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {(dataGetAllMeetingsForCompany?.findAllMeetings?.edges
                        ?.length === 0 ||
                        dataGetAllMeetingsForCompany?.findAllMeetings?.edges ===
                          undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataGetAllMeetingsForCompany?.findAllMeetings
                            ?.pageInfo?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllMeetingsForCompany?.findAllMeetings
                              ?.pageInfo?.hasPreviousPage
                          ) {
                            getAllMeetingsForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                last: itemsPerPage,
                                before:
                                  dataGetAllMeetingsForCompany?.findAllMeetings
                                    ?.pageInfo?.startCursor,
                              },
                            });
                            pageNo.current = pageNo.current - 1;
                          }
                        }}
                      />
                      <i
                        className={
                          dataGetAllMeetingsForCompany?.findAllMeetings
                            ?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataGetAllMeetingsForCompany?.findAllMeetings
                              ?.pageInfo?.hasNextPage
                          ) {
                            getAllMeetingsForCompany({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                first: itemsPerPage,
                                after:
                                  dataGetAllMeetingsForCompany?.findAllMeetings
                                    ?.pageInfo?.endCursor,
                              },
                            });
                            pageNo.current = pageNo.current + 1;
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <ScheduleMeetingModal
        visible={modalVisibility}
        modalSelectedMeetingId={selectedMeetingId}
        modalInvitees={invitees}
        onHide={() => {
          setModalVisibility(false);
        }}
      />
    </React.Fragment>
  );
};
