import { createSlice } from "@reduxjs/toolkit";
import { CompanyProfileStoreType } from "./types";

const initialState: CompanyProfileStoreType = {
  companyID: '',
  companyAdd: true,
  companyView: false,

}
const companyProfileSlice = createSlice({
  name: "company-profile",
  initialState,
  reducers: {
    setCompanyID: (state, { payload }) => {
      state.companyID = payload;
    },
    setCompanyAddScreen: (state, { payload }) => {
      state.companyAdd = payload;
    },
    setCompanyViewScreen: (state, { payload }) => {
      state.companyView = payload;
    },

  },
});

export const { setCompanyID, setCompanyAddScreen, setCompanyViewScreen } = companyProfileSlice.actions;

export default companyProfileSlice.reducer;

