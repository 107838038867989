import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import './addSystemUser.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  ADD_USER_TO_USERGROUP,
  FIND_USER_GROUPS,
  UPDATE_USER_OF_USERGROUP,
} from '~/Store/graphQL/UserGroup';
import { FIND_ALL_COMPANY_MEMBERS } from '~/Store/graphQL/CompanyMembers';
import { FIND_ALL_COMPANY_OFFICERS } from '~/Store/graphQL/CompanyOfficer';
import { ModuleEnum, UserCategoryEnum, UserTypeEnum } from '~/commons/enums';
import { FIND_ALL_USERS, FIND_USER } from '~/Store/graphQL/Users';
import {
  setSelectedUserGroupId,
  setSelectedUserId,
} from '~/Store/reducers/users';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_MEMBER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { User } from '~/assets/images';

export const AddSystemUser: FC = () => {
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserId, selectedUserCategory, selectedUserGroupId } =
    useAppSelector(state => state.userReducer);
  const navigate = useNavigate();
  const [companyMembers, setCompanyMembers] = useState<any>([]);
  const [companyOfficers, setCompanyOfficers] = useState<any>([]);
  const [intermedialUsers, setIntermedialUsers] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);
  const [userData, setUserData] = useState<any>();
  const [selectedUserMemberId, setSetSelectedUserMemberId] = useState<string>();
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/systemuser',
          title: 'System User',
        },
        {
          url: location.pathname,
          title: 'Add User Information',
        },
      ])
    );
  }, []);

  const [
    findUserGroups,
    {
      loading: loadingFindUserGroup,
      error: errorFindUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUPS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findAllCompanyMembers,
    {
      loading: loadingFindllCompanyMembers,
      error: errorFindAllCompanyMembers,
      data: dataFindAllCompanyMembers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_MEMBERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findAllCompanyOfficers,
    {
      loading: loadingFindllCompanyOfficers,
      error: errorFindAllCompanyOfficers,
      data: dataFindAllCompanyOfficers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_OFFICERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  // Getting information of intermedial users
  const [
    getAllIntermedialUsers,
    {
      loading: loadingGetAllIntermedialUsers,
      error: errorGetAllIntermedialUsers,
      data: dataGetAllIntermedialUsers,
    },
  ] = useLazyQuery<any>(FIND_ALL_USERS, {
    variables: {
      userType: UserTypeEnum.INTERMEDIAL_USER.toString(),
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findUserData,
    {
      loading: loadingFindUserData,
      error: errorFindUserData,
      data: dataFindUserData,
    },
  ] = useLazyQuery<any>(FIND_USER, {
    fetchPolicy: 'no-cache',
  });
  const [
    addUserToUserGroup,
    {
      loading: loadingaddUserToUserGroup,
      error: erroraddUserToUserGroup,
      data: dataaddUserToUserGroup,
    },
  ] = useMutation<any>(ADD_USER_TO_USERGROUP, {
    fetchPolicy: 'no-cache',
  });
  const [
    updateUserOfUserGroup,
    {
      loading: loadingUpdateUserOfUserGroup,
      error: errorUpdateUserOfUserGroup,
      data: dataUpdateUserOfUserGroup,
    },
  ] = useMutation<any>(UPDATE_USER_OF_USERGROUP, {
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.SYSTEM_USER,
    },
    fetchPolicy: 'no-cache',
  });

  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.SYSTEM_USER,
    },
  });
  // For fetching relevant processes
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_MEMBER_ENUM_ARRAY,
    },
  });

  useEffect(() => {
    if (companyID) {
      findAllCompanyMembers();
      findAllCompanyOfficers();
      getAllIntermedialUsers();
      findUserGroups();
    }
  }, [companyID]);
  useEffect(() => {
    if (dataFindAllCompanyMembers) {
      setCompanyMembers(
        dataFindAllCompanyMembers?.findAllCompanyMembers?.edges
      );
    } else if (errorFindAllCompanyMembers) {
      console.log('errorFindAllCompanyMembers', errorFindAllCompanyMembers);
      dispatch(setToastError(errorFindAllCompanyMembers));
    }
  }, [dataFindAllCompanyMembers, errorFindAllCompanyMembers]);
  useEffect(() => {
    if (dataFindAllCompanyOfficers) {
      setCompanyOfficers(
        dataFindAllCompanyOfficers?.findAllCompanyOfficers?.edges
      );
    } else if (errorFindAllCompanyOfficers) {
      console.log('errorFindAllCompanyOfficers', errorFindAllCompanyOfficers);
      dispatch(setToastError(errorFindAllCompanyOfficers));
    }
  }, [dataFindAllCompanyOfficers, errorFindAllCompanyOfficers]);
  useEffect(() => {
    if (dataGetAllIntermedialUsers) {
      setIntermedialUsers(dataGetAllIntermedialUsers?.users?.edges);
    } else if (errorGetAllIntermedialUsers) {
      console.log('errorGetAllIntermedialUsers', errorGetAllIntermedialUsers);
      dispatch(setToastError(errorGetAllIntermedialUsers));
    }
  }, [dataGetAllIntermedialUsers, errorGetAllIntermedialUsers]);
  useEffect(() => {
    if (dataFindUserGroup) {
      setUserGroups(dataFindUserGroup.userGroups.edges);
    } else if (errorFindUserGroup) {
      console.log('errorFindUserGroup', errorFindUserGroup);
      dispatch(setToastError(errorFindUserGroup));
    }
  }, [dataFindUserGroup, errorFindUserGroup]);
  useEffect(() => {
    if (selectedUserId) {
      findUserData({
        variables: {
          id: selectedUserId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedUserId]);
  useEffect(() => {
    if (dataFindUserData) {
      setUserData(dataFindUserData?.user);
    } else if (errorFindUserData) {
      console.log('errorFindUserData:', errorFindUserData);
    }
  }, [dataFindUserData, errorFindUserData]);
  useEffect(() => {
    if (selectedUserCategory && companyOfficers && companyMembers && userData) {
      let selectedUserCategoryId: any = {};
      if (selectedUserCategory === UserTypeEnum.COMPANY_OFFICER) {
        selectedUserCategoryId = companyOfficers?.find(
          (o: any) => o.email === userData.email
        );
        if (selectedUserCategoryId) {
          setSetSelectedUserMemberId(selectedUserCategoryId?.node?.id);
        }
      } else if (selectedUserCategory === UserTypeEnum.COMPANY_MEMBER) {
        selectedUserCategoryId = companyMembers?.find(
          (o: any) => o.node.email === userData.email
        );
        if (selectedUserCategoryId) {
          setSetSelectedUserMemberId(selectedUserCategoryId?.node?.memberId);
        }
      } else if (selectedUserCategory === UserTypeEnum.INTERMEDIAL_USER) {
        selectedUserCategoryId = intermedialUsers?.find(
          (o: any) => o.node.email === userData.email
        );
        if (selectedUserCategoryId) {
          setSetSelectedUserMemberId(selectedUserCategoryId?.node?.id);
        }
      }
    }
  }, [
    userData,
    companyMembers,
    companyOfficers,
    intermedialUsers,
    selectedUserCategory,
  ]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      category: selectedUserCategory
        ? selectedUserCategory === UserTypeEnum.COMPANY_OFFICER
          ? UserCategoryEnum.COMPANY_OFFICER
          : selectedUserCategory === UserTypeEnum.COMPANY_MEMBER
          ? UserCategoryEnum.COMPANY_MEMBER
          : UserCategoryEnum.INTERMEDIAL_USER
        : '',
      email: selectedUserMemberId ? selectedUserMemberId : '',
      firstName: userData?.firstName ? userData?.firstName : '',
      lastName: userData?.lastName ? userData?.lastName : '',
      userGroup: selectedUserGroupId ? selectedUserGroupId : '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please select Email').nullable(),
      category: Yup.string().required('Please select Category').nullable(),
      userGroup: Yup.string().required('Please select User Group').nullable(),
    }),
    onSubmit: (values: any) => {
      if (userData && selectedUserId) {
        updateUserOfUserGroup({
          variables: {
            userId: selectedUserId,
            userGroupId: values.userGroup,
            prevUserGroupId: selectedUserGroupId,
            category: values.category,
            companyId: companyID,
          },
        })
          .then(res => {
            if (res?.data?.updateUserGroupUser?.userGroupUser?.id) {
              dispatch(setToastSuccess('User of usergroup updated!'));
              setSetSelectedUserMemberId(undefined);
              dispatch(setSelectedUserGroupId(null));
              dispatch(setSelectedUserId(null));
              navigate('/systemuser');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        addUserToUserGroup({
          variables: {
            userId: values.email,
            userGroupId: values.userGroup,
            category: values.category,
            companyId: companyID,
          },
        })
          .then(res => {
            if (res.data.addUserToUserGroup.userGroupUser.id) {
              dispatch(setToastSuccess('User added to user group '));
              navigate('/systemuser');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  return (
    <React.Fragment>
      {loadingFindllCompanyMembers ||
      loadingFindllCompanyOfficers ||
      loadingFindUserData ||
      loadingFindUserGroup ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 d-flex parent">
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="justify-content-end  background-primary-medium"
            >
              <div className="d-flex gap-3 position-relative end-0 mx-4">
                <button
                  type="button"
                  className="btn dangerButton fw-bold d-flex align-items-center my-auto"
                  disabled={
                    loadingFindUserData ||
                    loadingaddUserToUserGroup ||
                    loadingUpdateUserOfUserGroup
                  }
                  onMouseDown={() => {
                    navigate('/systemuser');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn successButton fw-bold d-flex align-items-center my-auto"
                  disabled={
                    loadingUpdateUserOfUserGroup || loadingaddUserToUserGroup
                  }
                  onMouseDown={() => {
                    validation.handleSubmit();
                  }}
                >
                  {(loadingUpdateUserOfUserGroup ||
                    loadingaddUserToUserGroup) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
            <div id="mainComponentNew">
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={User}
                      ></img>
                      <div className="font-weight-600">System Users Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-4">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="category">Category*</label>
                        {validation.touched.category &&
                        validation.errors.category ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.category}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.category &&
                            validation.errors.category &&
                            'is-invalid'
                          }`}
                          id="category"
                          name="category"
                          placeholder="Category"
                          value={validation.values.category}
                          disabled={selectedUserId}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={UserCategoryEnum.COMPANY_MEMBER}>
                            Company Member
                          </option>
                          <option value={UserCategoryEnum.COMPANY_OFFICER}>
                            Company Officer
                          </option>
                          <option value={UserCategoryEnum.INTERMEDIAL_USER}>
                            Intermedial User
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="email">Email*</label>
                        {validation.touched.email && validation.errors.email ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.email}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.email &&
                            validation.errors.email &&
                            'is-invalid'
                          }`}
                          id="email"
                          name="email"
                          placeholder="email"
                          value={validation.values.email}
                          disabled={selectedUserId}
                          onChange={e => {
                            if (
                              validation.values.category ===
                              UserCategoryEnum.COMPANY_MEMBER
                            ) {
                              let companyMember = companyMembers.find(
                                (element: any) => {
                                  return (
                                    element?.node?.memberId === e.target.value
                                  );
                                }
                              );
                              validation.setFieldValue(
                                'firstName',
                                companyMember?.node?.firstName
                              );
                              validation.setFieldValue(
                                'lastName',
                                companyMember?.node?.lastName
                              );
                            } else if (
                              validation.values.category ===
                              UserCategoryEnum.COMPANY_OFFICER
                            ) {
                              let companyOfficer = companyOfficers.find(
                                (element: any) => {
                                  return element?.node?.id === e.target.value;
                                }
                              );
                              validation.setFieldValue(
                                'firstName',
                                companyOfficer?.node?.firstName
                              );
                              validation.setFieldValue(
                                'lastName',
                                companyOfficer?.node?.lastName
                              );
                            } else if (
                              validation.values.category ===
                              UserCategoryEnum.INTERMEDIAL_USER
                            ) {
                              let intermedialUser = intermedialUsers.find(
                                (element: any) => {
                                  return element?.node?.id === e.target.value;
                                }
                              );
                              validation.setFieldValue(
                                'firstName',
                                intermedialUser?.node?.firstName
                              );
                              validation.setFieldValue(
                                'lastName',
                                intermedialUser?.node?.lastName
                              );
                            }
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {validation.values.category ===
                          UserCategoryEnum.COMPANY_MEMBER
                            ? companyMembers?.map(
                                (member: any, index: number) => (
                                  <option
                                    value={member?.node?.memberId}
                                    key={index}
                                  >
                                    {member?.node?.email}
                                  </option>
                                )
                              )
                            : null}
                          {validation.values.category ===
                          UserCategoryEnum.COMPANY_OFFICER
                            ? companyOfficers?.map(
                                (officer: any, index: number) => (
                                  <option value={officer?.node?.id} key={index}>
                                    {officer?.node?.email}
                                  </option>
                                )
                              )
                            : null}
                          {validation.values.category ===
                          UserCategoryEnum.INTERMEDIAL_USER
                            ? intermedialUsers?.map(
                                (user: any, index: number) => (
                                  <option value={user?.node?.id} key={index}>
                                    {user?.node?.email}
                                  </option>
                                )
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="userGroup">User Group*</label>
                        {validation.touched.userGroup &&
                        validation.errors.userGroup ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.userGroup}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.userGroup &&
                            validation.errors.userGroup &&
                            'is-invalid'
                          }`}
                          id="userGroup"
                          name="userGroup"
                          placeholder="User Group"
                          disabled={selectedUserId}
                          value={validation.values.userGroup}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {userGroups?.map((userGroup: any, index: number) => (
                            <option value={userGroup?.node?.id} key={index}>
                              {userGroup?.node?.groupName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey mb-4">
                  <div className="col-4">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="firstName">First Name*</label>
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.firstName}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.firstName &&
                          validation.errors.firstName &&
                          'is-invalid'
                        }`}
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={validation.values.firstName}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="lastName">Last Name*</label>
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.lastName}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.lastName &&
                          validation.errors.lastName &&
                          'is-invalid'
                        }`}
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={validation.values.lastName}
                        disabled={true}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
          </div>
          {!loadingReliventDocumnets &&
            !loadingRelevantProcesses &&
            !loadingGetHelp &&
            (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
              <RightSideBar
                documents={
                  dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
                }
                processes={
                  dataRelevantProcesses?.getRelevantProcessesByEvent
                    ?.eventRelevantProcesses
                }
                help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};
