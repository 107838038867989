import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import './ordinance.scss';

import { useLazyQuery, useMutation } from '@apollo/client';
import { ActionItemsInputDto } from './types';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationDialogue } from '~/components/Toasts';
import Swal from 'sweetalert2';
import './ordinance.scss';
import {
  CREATE_ORDINANCE,
  FIND_ONE_ORDINANCE,
  UPDATE_ORDINANCE,
} from '~/Store/graphQL/Ordinance';

export const AddAdminOrdinance: FC = () => {
  const { selectedOrdinanceId, ordinanceView } = useAppSelector(
    state => state.ordinanceReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ordinanceData, setOrdinanceData] = useState<any>();
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [sectionItemError, setsectionItemError] = useState<string>();
  const [sections, setSections] = useState<any>([]);
  const [tempsections, setTempsections] = useState<
    ActionItemsInputDto[] | undefined
  >();
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/admin/ordinance',
          title: 'Ordinance',
        },
        {
          url: location.pathname,
          title: 'Add Ordinance',
        },
      ])
    );
  }, []);

  const [
    findOrdinanceId,
    {
      loading: loadingFindOrdinanceById,
      error: errorFindOrdinanceById,
      data: dataFindOrdinanceById,
    },
  ] = useLazyQuery<any>(FIND_ONE_ORDINANCE);

  useEffect(() => {
    if (selectedOrdinanceId) {
      findOrdinanceId({
        variables: {
          id: selectedOrdinanceId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedOrdinanceId]);

  const [
    createOrdinance,
    {
      loading: loadingCreateOrdinance,
      error: errorCreateOrdinance,
      data: dataCreateOrdinance,
    },
  ] = useMutation<any>(CREATE_ORDINANCE);

  const [
    updateOrdinance,
    {
      loading: loadingUpdateOrdinance,
      error: errorUpdateOrdinance,
      data: dataUpdateOrdinance,
    },
  ] = useMutation<any>(UPDATE_ORDINANCE);

  useEffect(() => {
    if (dataFindOrdinanceById) {
      setOrdinanceData(dataFindOrdinanceById?.findOneOrdinance?.ordinance);
      setSections(dataFindOrdinanceById?.findOneOrdinance?.ordinance?.section);
    } else if (errorFindOrdinanceById) {
      console.log('errorFindOrdinanceById:', errorFindOrdinanceById);
    }
  }, [dataFindOrdinanceById, errorFindOrdinanceById]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      ordinance: ordinanceData ? ordinanceData?.ordinanceName : '',
    },
    validationSchema: Yup.object({
      ordinance: Yup.string().required('Please enter ordinance').nullable(),
    }),

    onSubmit: (values: any) => {
      sections?.forEach((element: any) => {
        delete element['__typename'];
      });
      if (selectedOrdinanceId) {
        updateOrdinance({
          variables: {
            id: selectedOrdinanceId,
            ordinanceName: values.ordinance,
            section: sections,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateOrdinance?.ordinance) {
              dispatch(setToastSuccess('Oridnance updated!'));
              navigate(`/admin/ordinance`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createOrdinance({
          variables: {
            ordinanceName: values.ordinance,
            section: sections,
          },
        })
          .then((res: any) => {
            if (res?.data?.createOrdinance?.ordinance) {
              dispatch(setToastSuccess('Oridnance created!'));
              navigate(`/admin/ordinance`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      {loadingFindOrdinanceById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 min">
          <div id="mainComponentHeading">Ordinance Info</div>
          <div id="mainComponentNew">
            <div className="inputFields">
              <div>
                <div className="formGroupHeading">Ordinance*</div>
                <hr className="formGroupHeadingUnderline"></hr>
              </div>

              <div className="row">
                <div className="col-xxl-4 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      {/* <label htmlFor="section">Ordinance</label> */}
                      {validation.touched.ordinance &&
                      validation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {validation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control
                      ${
                        validation.touched.ordinance &&
                        validation.errors.ordinance &&
                        'is-invalid'
                      }`}
                      id="ordinance"
                      name="ordinance"
                      placeholder="Ordinance"
                      value={validation.values.ordinance}
                      disabled={ordinanceView}
                      onChange={validation.handleChange}
                    />
                  </div>
                </div>
                <div>
                  <div className="formGroupHeading">Sections</div>
                  <hr className="formGroupHeadingUnderline"></hr>
                </div>
                {sections?.map((section: any, index: number) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <div className="me-3">{index + 1}.</div>
                    {editableRow !== index ? (
                      <>
                        <div className="action-items my-3">
                          {section?.sectionName}
                        </div>
                        <>
                          {!ordinanceView ? (
                            <>
                              <i
                                className={
                                  'bx bxs-edit-alt text-primary mx-2 cursor-pointer'
                                }
                                onClick={() => {
                                  if (editableRow === -1) {
                                    setEditableRow(index);
                                    setTempsections(sections);
                                  }
                                }}
                              />

                              <i
                                className={
                                  'bx bxs-trash-alt text-danger cursor-pointer'
                                }
                                onClick={() => {
                                  if (editableRow === -1) {
                                    ConfirmationDialogue.fire({
                                      title: 'Are you sure?',
                                      text: "You won't be able to revert this!",
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonText: 'Delete',
                                      cancelButtonText: 'Cancel',
                                      reverseButtons: true,
                                    }).then(result => {
                                      if (result.isConfirmed) {
                                        let temp = [...sections];
                                        temp.splice(index, 1);
                                        let temp2 = JSON.parse(
                                          JSON.stringify(sections)
                                        );
                                        temp2.forEach((element: any) => {
                                          delete element['__typename'];
                                        });
                                        temp2[index].toBeDeleted = true;
                                        setSections(temp);
                                        ConfirmationDialogue.fire(
                                          'Deleted!',
                                          '',
                                          'success'
                                        );
                                      } else if (
                                        /* Read more about handling dismissals below */
                                        result.dismiss ===
                                        Swal.DismissReason.cancel
                                      ) {
                                        ConfirmationDialogue.fire(
                                          'Cancelled',
                                          '',
                                          'error'
                                        );
                                      }
                                    });
                                  }
                                }}
                              />
                            </>
                          ) : null}
                        </>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="text-end">
                            {sectionItemError ? (
                              <p className="text-danger font-12 ms-auto mb-0">
                                {sectionItemError}
                              </p>
                            ) : null}
                            <input
                              type="text"
                              id="sections"
                              name="sections"
                              placeholder="Section"
                              className={`form-control form-medium form-control-no-margin-bottom`}
                              value={section?.sectionName}
                              disabled={ordinanceView}
                              onChange={e => {
                                setsectionItemError(undefined);
                                const { value } = e.target;
                                const temp = JSON.parse(
                                  JSON.stringify(sections)
                                );
                                temp[index].sectionName = value;
                                setSections(temp);
                              }}
                            />
                          </div>

                          <i
                            className="bx bx-check-circle bx-md ms-4 me-2 text-success cursor-pointer"
                            onClick={() => {
                              if (sections[editableRow]) {
                                let temp = [...sections];
                                temp.forEach((element: any) => {
                                  delete element['__typename'];
                                });
                                temp[editableRow] = section;
                                setEditableRow(-1);
                                setSections(sections);
                              } else {
                                setsectionItemError(
                                  'Please enter section item'
                                );
                              }
                            }}
                          ></i>
                          <i
                            className="bx bx-x-circle bx-md text-danger cursor-pointer"
                            onClick={() => {
                              setEditableRow(-1);
                              setSections(tempsections);
                            }}
                          ></i>
                        </div>
                      </>
                    )}
                  </div>
                ))}

                {editableRow === -1 && !ordinanceView ? (
                  <div className="d-flex justify-content-start align-items-center">
                    <i className="bx bx-plus me-1 color-disabled"></i>
                    <div
                      className="color-disabled cursor-pointer"
                      onClick={() => {
                        if (editableRow === -1) {
                          if (sections) {
                            setTempsections(sections);
                            let newsection = [...sections];
                            newsection.push({
                              ordinanceId: selectedOrdinanceId,
                              sectionId: uuidv4(),
                              sectionName: '',
                            });
                            setSections(newsection);
                            setEditableRow(sections.length);
                          } else {
                            let newsection = [
                              {
                                ordinanceId: selectedOrdinanceId,
                                sectionId: uuidv4(),
                                sectionName: '',
                              },
                            ];
                            setSections(newsection);
                            setEditableRow(0);
                          }
                        }
                      }}
                    >
                      Add New
                    </div>
                  </div>
                ) : null}
              </div>

              <br className="my-4"></br>

              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn dangerButton d-flex align-items-center my-auto"
                  // disabled={ordinanceView}
                  onMouseDown={() => {
                    navigate('/admin/ordinance');
                  }}
                >
                  Cancel
                </button>

                {!ordinanceView ? (
                  <button
                    type="button"
                    className="btn successButton d-flex align-items-center my-auto"
                    disabled={ordinanceView}
                    onClick={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {loadingCreateOrdinance && (
                      <i className="bx bx-loader-circle bx-spin me-1" />
                    )}
                    Save
                    <i className="bx bx-upload mx-1" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
