import { gql } from '@apollo/client';

export const CREATE_USER_GROUP = gql`
  mutation createUserGroup(
    $companyId: ID!
    $name: String!
    $description: String!
    $permissionArr: [PermissionInput!]!
  ) {
    createUserGroup(
      data: {
        companyId: $companyId
        groupName: $name
        description: $description
        permissions: $permissionArr
      }
    ) {
      userGroup {
        id
        groupName
        description
        companyId
      }
    }
  }
`;

export const FIND_USER_GROUPS = gql`
  query findUserGroups(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: ID!
  ) {
    userGroups(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId }
    ) {
      edges {
        node {
          id
          groupName
          description
        }
      }
      pageInfo {
        startCursor

        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SEARCH_USER_GROUPS = gql`
  query findUserGroups(
    $name: String,
    $companyId: ID!
    $searchKey: String
    $searchValue: String
  ) {
    userGroups(
      q: $name,
      filterBy: { companyId: $companyId }
      searchKey: $searchKey
      searchValue: $searchValue) {
      edges {
        node {
          id
          groupName
          description
        }
      }
      pageInfo {
        startCursor

        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FIND_USER_GROUP = gql`
  query findUserGroup($id: ID!) {
    userGroup(id: $id) {
      groupName
      description
      permissions {
        groupName
        moduleName
        edit
        view
        delete
        email

        generateDocument
      }
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation deleteUserGroup($Id: ID!) {
    deleteUserGroup(data: { userGroupId: $Id }) {
      errors {
        field
        message
      }
      count
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation updateUserGroup(
    $Id: ID!
    $name: String!
    $description: String!
    $permissionArr: [PermissionInput!]!
  ) {
    updateUserGroup(
      data: {
        userGroupId: $Id
        groupName: $name
        description: $description
        permissions: $permissionArr
      }
    ) {
      errors {
        field
        message
      }
      userGroup {
        id
        groupName
        description
        version
      }
    }
  }
`;

export const ADD_USER_TO_USERGROUP = gql`
  mutation addUserToUserGroup(
    $userId: ID!
    $userGroupId: ID!
    $category: String!
    $companyId: ID!
  ) {
    addUserToUserGroup(
      data: {
        userId: $userId
        userGroupId: $userGroupId
        userCategory: $category
        companyId: $companyId
      }
    ) {
      errors {
        field
        message
      }
      userGroupUser {
        id
        userId
        userGroupId
      }
    }
  }
`;
export const UPDATE_USER_OF_USERGROUP = gql`
  mutation updateUserGroupUser(
    $userId: ID!
    $userGroupId: ID!
    $prevUserGroupId: ID
    $category: String!
    $companyId: ID!
  ) {
    updateUserGroupUser(
      data: {
        userId: $userId
        userGroupId: $userGroupId
        prevUserGroupId: $prevUserGroupId
        userCategory: $category
        companyId: $companyId
      }
    ) {
      errors {
        field
        message
      }
      userGroupUser {
        id
        userId
        userGroupId
      }
    }
  }
`;

export const DELETE_USER_FROM_USERGROUP = gql`
  mutation deleteUserFromUserGroup(
    $userId: ID!
    $userGroupId: ID!
    $userCategory: String
    $companyId: ID
  ) {
    deleteUserFromUserGroup(
      data: {
        userId: $userId
        userGroupId: $userGroupId
        userCategory: $userCategory
        companyId: $companyId
      }
    ) {
      count
      errors {
        field
        message
      }
    }
  }
`;

export const ASSIGN_PERMISSION_TO_USERGROUP = gql`
  mutation assignPermissionsToUserGroup(
    $userGroupId: ID!
    $permissionArr: [PermissionInput!]!
  ) {
    assignPermissionsToUserGroup(
      data: { userGroupId: $userGroupId, permission: $permissionArr }
    ) {
      permission {
        view
        edit
      }
    }
  }
`;

export const FIND_PERMISSIONS = gql`
  query findPermissions(
    $companyId: ID!
    $userGroupId: ID
    $moduleName: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    findPermissions(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: {
        companyId: $companyId
        userGroupId: $userGroupId
        moduleName: $moduleName
      }
    ) {
      edges {
        node {
          userGroupId
          groupName
          moduleName
          edit
          view
          delete
          email
          generateDocument
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const UPDATE_PERMISSION_OF_USERGROUP = gql`
  mutation updatePermissionsOfUserGroup(
    $userGroupId: ID!
    $permissionArr: [PermissionInput!]!
  ) {
    updatePermissionsOfUserGroup(
      data: { userGroupId: $userGroupId, permission: $permissionArr }
    ) {
      permission {
        view
        edit
      }
    }
  }
`;

export const FIND_USERGROUP_PERMISSIONS = gql`
  query findPermissionsOfUserGroup($userGroupId: ID!) {
    findPermissionsOfUserGroup(id: $userGroupId) {
      permission {
        id
        moduleName
        userGroupId
        groupName
        edit
        view
        delete
        email
        generateDocument
      }
    }
  }
`;
