import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import {
  BusinessTypeEnum,
  CommitteeStatusEnum,
  MeetingActionItemStatusEnum,
  MeetingStatus,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
  ModuleEnum,
} from '../../../../commons/enums';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import '../meetings.scss';
import {
  setMeetingType,
  setMeetingSubType,
  setSubject,
  setRoom,
  setOtherRoom,
  setChair,
  setMinuteTaker,
  setAuditor,
  setInvitees,
  setActionItems,
  setCountry,
  setProvince,
  setCity,
  setVenue,
  setIsVirtualMeeting,
  setZoomLink,
  setCommitteeId,
  setSelectedMeetingId,
  setStatus,
} from '~/Store/reducers/meeting';
import {
  CREATE_MEETING,
  FIND_MEETING_BY_ID,
  FIND_MEETING_INVITEES_INFO,
  UPDATE_MEETING,
} from '~/Store/graphQL/MeetingsManagement';
import { Container, Modal } from 'reactstrap';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import {
  CREATE_AGENDA,
  FIND_ACTION_ITEMS_OF_AGENDA,
} from '~/Store/graphQL/AgendaManagement';
import NoAgendaImg from '/images/NoAgenda.png';
import ScheduleImg from '/images/schedule.png';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { v4 as uuidv4 } from 'uuid';
import { ScheduleMeetingModal } from '../ScheduleMeeting';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import { FIND_COMPANY_PROFILE_BY_ID } from '~/Store/graphQL/CompanyProfile';
import moment from 'moment';
import { GET_ALL_COMMITTEES_FOR_MEETING } from '~/Store/graphQL/CommitteeManagement';
import { GET_ALL_ORDINANCE } from '~/Store/graphQL/Ordinance';
import GetAgendaBusinessType from '~/helpers/Agenda/getBusinessType';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { GET_RELEVANT_DOCS_BY_MODULE_NAME } from '~/Store/graphQL/ReleventDocuments';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
export const MeetingInfo: FC = () => {
  const {
    selectedMeetingId,
    meetingView,
    meetingType,
    meetingSubType,
    status,
    subject,
    room,
    otherRoom,
    chair,
    invitees,
    minuteTaker,
    auditor,
    actionItems,
    venue,
    country,
    province,
    city,
    isVirtualMeeting,
    zoomLink,
    committeeId,
  } = useAppSelector(state => state.meetingReducer);
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [actionItemsData, setActionItemsData] = useState<any>([]);
  const [meetingInfoModalVisibility, setMeetingInfoModalVisibility] =
    useState(false);
  const [agendaInfoModalVisibility, setAgendaInfoModalVisibility] =
    useState(false);
  const [addAgendaModalVisibility, setAddAgendaModalVisibility] =
    useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  const [meetingActionItems, setMeetingActionItems] = useState<any>([]);
  const [momFiles, setMomFiles] = useState<any>([]);
  const [resolutionFiles, setResolutionFiles] = useState<any>([]);
  const [expandedRow, setExpandedRow] = useState<number>(-1);
  const [meetingActionItemIds, setMeetingActionItemIds] = useState<any>([]);
  const [isScheduleMeeting, setIsScheduleMeeting] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountry>();
  const [states, setStates] = useState<IState>();
  const [cities, setCities] = useState<ICity>();
  const [selectedCountry, setSelectedCountry] = useState<number | string>();
  const [selectedProvince, setSelectedProvince] = useState<number>();
  const [filterName, setFilterName] = useState<string>('');
  const [selectedCommitteeIndex, setSelectedCommitteeIndex] =
    useState<number>(-1);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [selectOrdinanceName, setSelectOrdinanceName] = useState<any>();
  const [selectSection, setSelectSection] = useState<any>();
  const [modalError, setModalError] = useState<string>('');

  useEffect(() => {
    getAllOrdinance();

    dispatch(
      setBreadCrumb([
        {
          url: '/meetings',
          title: 'Meeting',
        },
        {
          url: location.pathname,
          title: 'Meeting Info',
        },
      ])
    );
  }, []);
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedProvince && selectedProvince) {
      setCities(City.getCitiesOfState(selectedCountry, selectedProvince));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedProvince, selectedCountry]);
  useEffect(() => {
    if (actionItems) {
      let temp = [...meetingActionItems];
      let tempActionItemIds = [...meetingActionItemIds];
      actionItems.forEach((element: any) => {
        let parseActionItem = JSON.parse(element);
        parseActionItem.actionItemId = parseActionItem.id;
        delete parseActionItem['id'];
        delete parseActionItem['__typename'];
        var index = temp.findIndex(
          (x: any) => x.actionItemId === parseActionItem.actionItemId
        );
        if (index === -1) {
          temp.push(parseActionItem);
        }
        tempActionItemIds.push(parseActionItem.actionItemId);
      });
      if (!meetingView) {
        getActionItemsOfAgenda({
          variables: {
            actionItemIds: tempActionItemIds,
            meetingType: meetingType,
            meetingSubType: meetingSubType,
            q: filterName,
          },
          fetchPolicy: 'no-cache',
        });
      }
      setMeetingActionItemIds(tempActionItemIds);
      setMeetingActionItems(temp);
    }
  }, [actionItems]);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.MEETING,
    },
    fetchPolicy: 'no-cache',
  });

  // // For fetching relevant processes
  // const {
  //   loading: loadingRelevantProcesses,
  //   error: errorRelevantProcesses,
  //   data: dataRelevantProcesses,
  // } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
  //   variables: {
  //     eventIds: COMPANY_MEMBER_ENUM_ARRAY,
  //   },
  // });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.MEETING,
    },
    fetchPolicy: 'no-cache',
  });
  // get all ordinance info
  const [
    getAllOrdinance,
    {
      loading: loadingGetAllOrdinance,
      error: errorGetAllOrdinance,
      data: dataGetAllOrdinance,
    },
  ] = useLazyQuery<any>(GET_ALL_ORDINANCE, {
    fetchPolicy: 'no-cache',
  });
  // create meeting
  const [
    createMeeting,
    {
      loading: loadingCreateMeeting,
      error: errorCreateMeeting,
      data: dataCreateMeeting,
    },
  ] = useMutation<any>(CREATE_MEETING);
  // Getting meeting information
  const [
    findMeetingById,
    {
      loading: loadingFindMeetingById,
      error: errorFindMeetingById,
      data: dataFindMeetingById,
    },
  ] = useLazyQuery<any>(FIND_MEETING_BY_ID);
  //   update meeting
  const [
    updateMeeting,
    {
      loading: loadingUpdateMeeting,
      error: errorUpdateMeeting,
      data: dataUpdateMeeting,
    },
  ] = useMutation<any>(UPDATE_MEETING);
  // Get action items of agenda query
  const [
    getActionItemsOfAgenda,
    {
      loading: loadingGetActionItemsOfAgenda,
      error: errorGetActionItemsOfAgenda,
      data: dataGetActionItemsOfAgenda,
    },
  ] = useLazyQuery<any>(FIND_ACTION_ITEMS_OF_AGENDA, {
    variables: {
      meetingType: meetingType,
      meetingSubType: meetingSubType,
    },
    fetchPolicy: 'no-cache',
  });
  // create agenda
  const [
    createAgenda,
    {
      loading: loadingCreateAgenda,
      error: errorCreateAgenda,
      data: dataCreateAgenda,
    },
  ] = useMutation<any>(CREATE_AGENDA);
  // Getting company information
  const [
    findCompanyProfileById,
    {
      loading: loadingFindCompanyProfileById,
      error: errorFindCompanyProfileById,
      data: dataFindCompanyProfileById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_ID);
  // Getting company information
  const [
    findMeetingInviteesInfo,
    {
      loading: loadingFindMeetingInviteesInfo,
      error: errorFindMeetingInviteesInfo,
      data: dataFindMeetingInviteesInfo,
    },
  ] = useLazyQuery<any>(FIND_MEETING_INVITEES_INFO);
  // Get all committees query
  const [
    getAllCommitteesForMeeting,
    {
      loading: loadingGetAllCommitteesForMeeting,
      error: errorGetAllCommitteesForMeeting,
      data: dataGetAllCommitteesForMeeting,
    },
  ] = useLazyQuery<any>(GET_ALL_COMMITTEES_FOR_MEETING, {
    variables: {
      companyId: companyID,
      meetingType: MeetingTypeEnum.COMMITTEE_MEETING,
      meetingSubType: meetingSubType,
      committeeStatus: CommitteeStatusEnum.FORMED,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (companyID && meetingType === MeetingTypeEnum.COMMITTEE_MEETING) {
      getAllCommitteesForMeeting();
    }
  }, [meetingType]);
  useEffect(() => {
    if (companyID) {
      findCompanyProfileById({
        variables: {
          id: companyID,
        },
      });
    }
  }, [companyID]);
  useEffect(() => {
    if (meetingType && meetingSubType && !selectedMeetingId) {
      findMeetingInviteesInfo({
        variables: {
          meetingType: meetingType,
          meetingSubType: meetingSubType,
          companyId: companyID,
        },
      });
    }
  }, [meetingType, meetingSubType]);
  useEffect(() => {
    if (dataFindMeetingInviteesInfo?.findMeetingInviteesInfo) {
      if (!auditor) {
        dispatch(
          setAuditor(
            dataFindMeetingInviteesInfo?.findMeetingInviteesInfo?.auditor
          )
        );
      }
      if (!minuteTaker) {
        dispatch(
          setMinuteTaker(
            dataFindMeetingInviteesInfo?.findMeetingInviteesInfo?.minuteTaker
          )
        );
      }
      if (!chair) {
        dispatch(
          setChair(dataFindMeetingInviteesInfo?.findMeetingInviteesInfo?.head)
        );
      }
      if (invitees.length === 0) {
        dispatch(
          setInvitees(
            dataFindMeetingInviteesInfo?.findMeetingInviteesInfo?.invitees
          )
        );
      }
    }
    if (errorFindMeetingInviteesInfo) {
      console.log('errorFindMeetingInviteesInfo', errorFindMeetingInviteesInfo);
    }
  }, [dataFindMeetingInviteesInfo, errorFindMeetingInviteesInfo]);
  useEffect(() => {
    if (dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile) {
      setSelectedCountry('PK');
      dispatch(
        setVenue(
          dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.address
        )
      );
      dispatch(
        setCity(
          dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.city
        )
      );
      dispatch(
        setProvince(
          dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.province
        )
      );
      dispatch(setCountry('Pakistan'));
    }
  }, [dataFindCompanyProfileById]);
  useEffect(() => {
    if (meetingType && meetingSubType && !meetingView) {
      getActionItemsOfAgenda({
        variables: {
          actionItemIds: meetingActionItemIds,
          meetingType: meetingType,
          meetingSubType: meetingSubType,
          q: filterName,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [meetingType, meetingSubType]);
  useEffect(() => {
    if (dataGetActionItemsOfAgenda) {
      setActionItemsData(
        dataGetActionItemsOfAgenda?.findActionItemByMeetingTypeAndSubType
          ?.actionItems
      );
    } else if (errorGetActionItemsOfAgenda) {
      console.log('errorGetActionItemsOfAgenda:', errorGetActionItemsOfAgenda);
    }
  }, [dataGetActionItemsOfAgenda, errorGetActionItemsOfAgenda]);
  useEffect(() => {
    if (selectedMeetingId) {
      findMeetingById({
        variables: {
          id: selectedMeetingId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedMeetingId]);
  useEffect(() => {
    if (dataFindMeetingById) {
      dispatch(
        setMeetingType(dataFindMeetingById?.findOneMeeting?.meeting.meetingType)
      );
      dispatch(
        setMeetingSubType(
          dataFindMeetingById?.findOneMeeting?.meeting.meetingSubType
        )
      );
      dispatch(setStatus(dataFindMeetingById?.findOneMeeting?.meeting?.status));
      dispatch(
        setSubject(dataFindMeetingById?.findOneMeeting?.meeting.meetingSubject)
      );
      dispatch(
        setRoom(dataFindMeetingById?.findOneMeeting?.meeting.meetingRoom)
      );
      dispatch(
        setOtherRoom(dataFindMeetingById?.findOneMeeting?.meeting.otherRoom)
      );
      dispatch(setChair(dataFindMeetingById?.findOneMeeting?.meeting.chair));
      dispatch(
        setMinuteTaker(dataFindMeetingById?.findOneMeeting?.meeting.minuteTaker)
      );
      dispatch(
        setAuditor(dataFindMeetingById?.findOneMeeting?.meeting.auditor)
      );
      dispatch(
        setInvitees(
          dataFindMeetingById?.findOneMeeting?.meeting?.invitees?.split(';')
        )
      );
      dispatch(
        setIsVirtualMeeting(
          dataFindMeetingById?.findOneMeeting?.meeting.isVirtual
        )
      );
      dispatch(
        setZoomLink(dataFindMeetingById?.findOneMeeting?.meeting.zoomLink)
      );
      dispatch(
        setCommitteeId(
          dataFindMeetingById?.findOneMeeting?.meeting?.committee?.committeeId
        )
      );
      dispatch(setVenue(dataFindMeetingById?.findOneMeeting?.meeting.address));
      dispatch(setCity(dataFindMeetingById?.findOneMeeting?.meeting.city));
      dispatch(
        setProvince(dataFindMeetingById?.findOneMeeting?.meeting.province)
      );
      dispatch(
        setCountry(dataFindMeetingById?.findOneMeeting?.meeting.country)
      );
      let country: ICountry = countries.find(
        (x: ICountry) =>
          x.name === dataFindMeetingById?.findOneMeeting?.meeting.country
      );
      if (country) {
        setSelectedCountry(country.isoCode);
      }
      let stringifyActionItems: any = [];
      dataFindMeetingById?.findOneMeeting?.meeting.meetingActionItems.forEach(
        (element: any, index: number) => {
          delete element['__typename'];
          let parseActionItem = JSON.stringify(element);
          stringifyActionItems.push(parseActionItem);
          let tempMomFiles = [...momFiles];
          tempMomFiles[index] = JSON.parse(element?.momUrl)?.name;
          setMomFiles(tempMomFiles);
          let tempResolutionFiles = [...resolutionFiles];
          tempResolutionFiles[index] = JSON.parse(element?.resolutionUrl)?.name;
          setResolutionFiles(tempResolutionFiles);
        }
      );
      dispatch(setActionItems(stringifyActionItems));

      let tempActionItems: any = [];
      dataFindMeetingById?.findOneMeeting?.meeting.meetingActionItems.forEach(
        (element: any) => {
          delete element['__typename'];
          delete element.actionItem['__typename'];
          element.actionItemId = element.id;
          delete element['id'];
          tempActionItems.push(element);
        }
      );
      setActionItemsData(tempActionItems);
    } else if (errorFindMeetingById) {
      console.log('errorFindMeetingById:', errorFindMeetingById);
    }
  }, [dataFindMeetingById, errorFindMeetingById]);
  useEffect(() => {
    if (committeeId && dataGetAllCommitteesForMeeting) {
      let committeeIndex =
        dataGetAllCommitteesForMeeting?.findAllCommitteesForMeeting?.edges?.findIndex(
          (element: any) => {
            return element.node.committeeId === committeeId;
          }
        );
      setSelectedCommitteeIndex(committeeIndex);
    }
  }, [committeeId, dataGetAllCommitteesForMeeting]);
  useEffect(() => {
    if (selectOrdinanceName) {
      const result = dataGetAllOrdinance?.findAllOrdinance?.edges.filter(
        (ordinance: any, ordinanceIndex: number) =>
          ordinance?.node?.ordinanceName === selectOrdinanceName
      );
      setSelectSection(result);
    }
  }, [selectOrdinanceName]);
  useEffect(() => {
    if (modalError) {
      setTimeout(() => {
        setModalError('');
      }, 8000);
    }
  }, [modalError]);
  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      meetingType: meetingType ? meetingType : '',
      meetingSubType: meetingSubType ? meetingSubType : '',
    },
    validationSchema: Yup.object({
      meetingType: Yup.string()
        .required('Please select meeting type')
        .nullable(),
      meetingSubType: Yup.string()
        .required('Please select meeting sub type')
        .nullable(),
    }),
    onSubmit: (values: any) => {
      meetingActionItems.forEach((element: any) => {
        element.id = element.meetingActionItemId;
        delete element['meetingActionItemId'];
        delete element['businessType'];
        delete element['attachmentUrl'];
      });
      if (selectedMeetingId && !meetingView) {
        updateMeeting({
          variables: {
            id: selectedMeetingId,
            meetingType: meetingType,
            meetingSubType: meetingSubType,
            committeeId:
              meetingType === MeetingTypeEnum.COMMITTEE_MEETING
                ? committeeId
                : undefined,
            meetingSubject: subject,
            status: status ? status : MeetingStatus.CREATED,
            meetingRoom: room,
            otherRoom: otherRoom,
            chair: chair,
            invitees: invitees.join(';'),
            minuteTaker: minuteTaker,
            auditor: auditor,
            address: isVirtualMeeting ? '' : venue,
            city: isVirtualMeeting ? '' : city,
            province: isVirtualMeeting ? '' : province,
            country: isVirtualMeeting ? '' : country,
            isVirtual: isVirtualMeeting,
            zoomLink: isVirtualMeeting ? zoomLink : '',
            meetingActionItems: meetingActionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.updateMeeting?.meeting) {
              if (isScheduleMeeting) {
                setModalVisibility(true);
                setIsScheduleMeeting(false);
              } else {
                dispatch(setToastSuccess('Meeting updated!'));
                dispatch(setMeetingType(''));
                dispatch(setMeetingSubType(''));
                dispatch(setStatus(''));
                dispatch(setSubject(''));
                dispatch(setRoom(''));
                dispatch(setOtherRoom(''));
                dispatch(setChair(''));
                dispatch(setMinuteTaker(''));
                dispatch(setAuditor(''));
                dispatch(setInvitees([]));
                dispatch(setActionItems([]));
                dispatch(setVenue(''));
                dispatch(setProvince(''));
                dispatch(setCity(''));
                dispatch(setCountry(''));
                dispatch(setIsVirtualMeeting(false));
                dispatch(setZoomLink(''));
                dispatch(setCommitteeId(''));
                navigate(`/meetings`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        createMeeting({
          variables: {
            companyId: companyID,
            meetingType: meetingType,
            meetingSubType: meetingSubType,
            committeeId:
              meetingType === MeetingTypeEnum.COMMITTEE_MEETING
                ? committeeId
                : undefined,
            meetingSubject: subject,
            status: MeetingStatus.CREATED,
            meetingRoom: room,
            otherRoom: otherRoom,
            chair: chair,
            invitees: invitees.join(';'),
            minuteTaker: minuteTaker,
            auditor: auditor,
            address: isVirtualMeeting ? '' : venue,
            city: isVirtualMeeting ? '' : city,
            province: isVirtualMeeting ? '' : province,
            country: isVirtualMeeting ? '' : country,
            isVirtual: isVirtualMeeting,
            zoomLink: isVirtualMeeting ? zoomLink : '',
            meetingActionItems: meetingActionItems,
          },
        })
          .then((res: any) => {
            if (res?.data?.createMeeting?.meeting) {
              if (isScheduleMeeting) {
                dispatch(
                  setSelectedMeetingId(
                    res?.data?.createMeeting?.meeting?.meetingId
                  )
                );
                setModalVisibility(true);
                setIsScheduleMeeting(false);
              } else {
                dispatch(setToastSuccess('Meeting created!'));
                dispatch(setMeetingType(''));
                dispatch(setMeetingSubType(''));
                dispatch(setStatus(''));
                dispatch(setSubject(''));
                dispatch(setRoom(''));
                dispatch(setOtherRoom(''));
                dispatch(setChair(''));
                dispatch(setMinuteTaker(''));
                dispatch(setAuditor(''));
                dispatch(setInvitees([]));
                dispatch(setActionItems([]));
                dispatch(setVenue(''));
                dispatch(setProvince(''));
                dispatch(setCity(''));
                dispatch(setCountry(''));
                dispatch(setIsVirtualMeeting(false));
                dispatch(setZoomLink(''));
                dispatch(setCommitteeId(''));
                navigate(`/meetings`);
              }
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  const agendaValidation: any = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      actionItem: '',
      ordinance: '',
      section: '',
      businessType: '',
      attachment: {
        name: '',
      },
    },
    validationSchema: Yup.object({
      actionItem: Yup.string().required('Please enter action item').nullable(),
      ordinance: Yup.string().required('Please enter ordinance').nullable(),
      section: Yup.string().required('Please select section').nullable(),
      businessType: Yup.string()
        .required('Please enter business type')
        .nullable(),
    }),
    onSubmit: (values: any, { resetForm }: any) => {
      createAgenda({
        variables: {
          companyId: companyID,
          ordinanceType: values.ordinance,
          ordinanceSection: values.section,
          meetingType: MeetingTypeEnum.OTHER,
          meetingSubType: MeetingSubTypeEnum.OTHER,
          businessType: values.businessType,
          actionItems: [
            {
              id: uuidv4(),
              actionItem: values.actionItem,
              agendaId: '',
              attachment: values.attachment,
            },
          ],
        },
      })
        .then((res: any) => {
          if (res?.data?.createAgenda?.agenda) {
            let tempMeetingActionItemIds = [...meetingActionItemIds];
            tempMeetingActionItemIds.push(
              res?.data?.createAgenda?.agenda?.actionItems[0].id
            );
            setMeetingActionItemIds(tempMeetingActionItemIds);
            getActionItemsOfAgenda({
              variables: {
                actionItemIds: tempMeetingActionItemIds,
                meetingType: meetingType,
                meetingSubType: meetingSubType,
                q: filterName,
              },
              fetchPolicy: 'no-cache',
            });
            resetForm();
            setAgendaInfoModalVisibility(false);
          }
        })
        .catch(err => {
          dispatch(setToastError(err));
        });
    },
  });
  const meetingInfoValidation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      subject: subject ? subject : '',
      room: room ? room : '',
      otherRoom: otherRoom ? otherRoom : '',
      chair: chair ? chair : '',
      minuteTaker: minuteTaker ? minuteTaker : '',
      auditor: auditor ? auditor : '',
      invitees: invitees ? invitees.join(';') : '',
      venue: venue
        ? venue
        : dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.address,
      city: city
        ? city
        : dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.city,
      province: province
        ? province
        : dataFindCompanyProfileById?.findCompanyProfileById?.companyProfile
            ?.province,
      country: country ? country : 'Pakistan',
      isVirtualMeeting: isVirtualMeeting ? isVirtualMeeting : false,
      zoomLink: zoomLink ? zoomLink : '',
      committeeName: committeeId ? committeeId : '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Please enter subject').nullable(),
      room: Yup.string().required('Please enter meeting room').nullable(),
      chair: Yup.string()
        .email('Incorrect email')
        .required('Please enter chair')
        .nullable(),
      minuteTaker: Yup.string()
        .email('Incorrect email')
        .required('Please enter minute taker')
        .nullable(),
      auditor: Yup.string().when('meetingType', {
        is: () => {
          if (
            validation.values.meetingType ===
              MeetingTypeEnum.COMMITTEE_MEETING &&
            validation.values.meetingSubType ===
              MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING
          ) {
            return true;
          }
          return false;
        },
        then: Yup.string()
          .email('Incorrect email')
          .required('Please enter auditor')
          .nullable(),
        otherwise: Yup.string().email('Incorrect email').nullable(),
      }),
      invitees: Yup.string().required('Please enter invitees email').nullable(),
      committeeName: Yup.string().when('meetingType', {
        is: () => {
          if (
            validation.values.meetingType === MeetingTypeEnum.COMMITTEE_MEETING
          ) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please select committee name').nullable(),
        otherwise: Yup.string(),
      }),
      zoomLink: Yup.string().when('isVirtualMeeting', {
        is: () => {
          if (isVirtualMeeting) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please enter zoom link').nullable(),
        otherwise: Yup.string(),
      }),
      venue: Yup.string().when('isVirtualMeeting', {
        is: () => {
          if (!isVirtualMeeting) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please enter venue').nullable(),
        otherwise: Yup.string(),
      }),
      city: Yup.string().when('isVirtualMeeting', {
        is: () => {
          if (!isVirtualMeeting) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please select city').nullable(),
        otherwise: Yup.string(),
      }),
      province: Yup.string().when('isVirtualMeeting', {
        is: () => {
          if (!isVirtualMeeting) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please select province').nullable(),
        otherwise: Yup.string(),
      }),
      country: Yup.string().when('isVirtualMeeting', {
        is: () => {
          if (!isVirtualMeeting) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please select country').nullable(),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: (values: any) => {
      dispatch(setSubject(values.subject));
      dispatch(setRoom(values.room));
      dispatch(setOtherRoom(values.otherRoom));
      dispatch(setChair(values.chair));
      dispatch(setMinuteTaker(values.minuteTaker));
      dispatch(setAuditor(values.auditor));
      dispatch(setInvitees(invitees));
      dispatch(setCommitteeId(values.committeeName));
      setMeetingInfoModalVisibility(false);
    },
  });
  const toggleMeetingInfoModal = () => {
    setMeetingInfoModalVisibility(!meetingInfoModalVisibility);
  };
  const toggleAgendaInfoModal = () => {
    setAgendaInfoModalVisibility(!agendaInfoModalVisibility);
  };
  const toggleAddAgendaModal = () => {
    setAddAgendaModalVisibility(!addAgendaModalVisibility);
  };
  const checkIfActionItemSelected = (actionItem: any) => {
    let temp = actionItems?.find(
      (o: any) => JSON.parse(o).id === actionItem.id
    );
    if (temp) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      {loadingFindMeetingById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <div className="w-100 dashed-border">
          <div className="row background-white border-bottom">
            <div className="col-8 border-right">
              <div className="row">
                <div className="col-9">
                  <div className="d-flex justify-content-start align-items-center">
                    {!meetingView && (
                      <button
                        type="button"
                        className="btn successButton d-flex align-items-center m-3 fw-bold"
                        disabled={loadingCreateMeeting || loadingUpdateMeeting}
                        onMouseDown={() => {
                          if (actionItems && actionItems?.length !== 0) {
                            if (
                              subject &&
                              room &&
                              chair &&
                              minuteTaker &&
                              invitees
                            ) {
                              validation.handleSubmit();
                            } else {
                              dispatch(
                                setToastError('Please add meeting information!')
                              );
                            }
                          } else {
                            dispatch(
                              setToastError(
                                'Please select atleast 1 action item!'
                              )
                            );
                          }
                        }}
                      >
                        {(loadingCreateMeeting || loadingUpdateMeeting) && (
                          <i className="bx bx-loader-circle bx-spin me-1" />
                        )}
                        Save
                        <i className="bx bx-upload mx-1" />
                      </button>
                    )}

                    {!meetingView &&
                    (!selectedMeetingId ||
                      dataFindMeetingById?.findOneMeeting?.meeting?.status ===
                        MeetingStatus.CREATED) ? (
                      <button
                        type="button"
                        className="btn primary-btn-new d-flex align-items-center my-auto fw-bold"
                        disabled={loadingCreateMeeting || loadingUpdateMeeting}
                        onMouseDown={() => {
                          setIsScheduleMeeting(true);
                          if (actionItems && actionItems?.length !== 0) {
                            if (
                              subject &&
                              room &&
                              chair &&
                              minuteTaker &&
                              invitees
                            ) {
                              validation.handleSubmit();
                            } else {
                              dispatch(
                                setToastError('Please add meeting information!')
                              );
                            }
                          } else {
                            dispatch(
                              setToastError(
                                'Please select atleast 1 action item!'
                              )
                            );
                          }
                        }}
                      >
                        {(loadingCreateMeeting || loadingUpdateMeeting) && (
                          <i className="bx bx-loader-circle bx-spin me-1" />
                        )}
                        {'Save & Schedule'}
                        <i className="bx bx-upload mx-1" />
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-center  ">
                  <button
                    type="button"
                    className="btn dangerButton d-flex align-items-center m-3 fw-bold me-2 ms-auto"
                    disabled={loadingCreateMeeting || loadingUpdateMeeting}
                    onMouseDown={() => {
                      navigate('/meetings');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-starts align-items-center">
              <i className="bx bxs-detail bx-sm text-primary mx-1"></i>
              <div className="mx-2 font-weight-600 font-20">Agenda</div>
            </div>
          </div>
          <div className="row background-white">
            <div className="col-8 inputFields border-right">
              <div className="row border-div my-3 mx-2">
                <div className="col-12">
                  <div className="m-2">
                    <div className="formGroupHeading">Meeting Type</div>
                    <hr className="formGroupHeadingUnderline"></hr>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex ">
                        <label htmlFor="meetingType">Meeting Type*</label>
                        {validation.touched.meetingType &&
                        validation.errors.meetingType ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.meetingType}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 bg-transparent ${
                            validation.touched.meetingType &&
                            validation.errors.meetingType &&
                            'is-invalid'
                          }`}
                          id="meetingType"
                          name="meetingType"
                          placeholder="Meeting Type"
                          value={validation.values.meetingType}
                          disabled={meetingView}
                          onChange={e => {
                            validation.setFieldValue('meetingSubType', '');
                            dispatch(setMeetingSubType(''));
                            validation.handleChange(e);
                            dispatch(setMeetingType(e.currentTarget.value));
                          }}
                        >
                          <option value="">Select</option>
                          <option value={MeetingTypeEnum.BOARD_MEETING}>
                            Board Meeting
                          </option>
                          <option value={MeetingTypeEnum.COMMITTEE_MEETING}>
                            Committee Meeting
                          </option>
                          <option value={MeetingTypeEnum.GENERAL_MEETING}>
                            General Meeting
                          </option>
                          <option value={MeetingTypeEnum.STATUTORY_MEETING}>
                            Statutory Meeting
                          </option>
                          <option value={MeetingTypeEnum.OTHER}>
                            Other Meeting
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="meetingSubType">
                          Meeting Sub Type*
                        </label>
                        {validation.touched.meetingSubType &&
                        validation.errors.meetingSubType ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.meetingSubType}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 bg-transparent ${
                            validation.touched.meetingSubType &&
                            validation.errors.meetingSubType &&
                            'is-invalid'
                          }`}
                          id="meetingSubType"
                          name="meetingSubType"
                          placeholder="Meeting Sub Type"
                          value={validation.values.meetingSubType}
                          disabled={meetingView}
                          onChange={e => {
                            validation.handleChange(e);
                            dispatch(setMeetingSubType(e.currentTarget.value));
                          }}
                        >
                          <option value="">Select</option>
                          {validation.values.meetingType ===
                          MeetingTypeEnum.BOARD_MEETING ? (
                            <>
                              <option value={MeetingSubTypeEnum.BOARD_MEETING}>
                                Board Meeting
                              </option>
                            </>
                          ) : null}
                          {validation.values.meetingType ===
                          MeetingTypeEnum.GENERAL_MEETING ? (
                            <>
                              <option
                                value={
                                  MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING
                                }
                              >
                                Annual General Meeting
                              </option>

                              <option
                                value={
                                  MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING
                                }
                              >
                                Extraordinary General Meeting
                              </option>
                            </>
                          ) : null}
                          {validation.values.meetingType ===
                          MeetingTypeEnum.COMMITTEE_MEETING ? (
                            <>
                              <option
                                value={
                                  MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING
                                }
                              >
                                Audit Committee Meeting
                              </option>
                              <option
                                value={
                                  MeetingSubTypeEnum.EXECUTIVE_COMMITTEE_MEETING
                                }
                              >
                                Executive Committee Meeting
                              </option>
                              <option
                                value={MeetingSubTypeEnum.HR_COMMITTEE_MEETING}
                              >
                                HR Committee Meeting
                              </option>
                              <option
                                value={
                                  MeetingSubTypeEnum.NOMINATION_COMMITTEE_MEETING
                                }
                              >
                                Nomination Committee Meeting
                              </option>
                              <option
                                value={
                                  MeetingSubTypeEnum.PROCUREMENT_COMMITTEE_MEETING
                                }
                              >
                                Precurement Committee Meeting
                              </option>
                              <option
                                value={
                                  MeetingSubTypeEnum.RISK_MANAGEMENT_COMMITTEE_MEETING
                                }
                              >
                                Risk Management Committee Meeting
                              </option>
                              <option
                                value={
                                  MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING
                                }
                              >
                                Other Committee Meeting
                              </option>
                            </>
                          ) : null}
                          {validation.values.meetingType ===
                          MeetingTypeEnum.STATUTORY_MEETING ? (
                            <option
                              value={MeetingSubTypeEnum.STATUTORY_MEETING}
                            >
                              Statutory Meeting
                            </option>
                          ) : null}
                          {validation.values.meetingType !==
                            MeetingTypeEnum.COMMITTEE_MEETING && (
                            <option value={MeetingSubTypeEnum.OTHER}>
                              Other Meeting
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border-div my-3 mx-2 pb-4 min-height-50-vh">
                <div className="col-11">
                  <div className="m-2">
                    <div className="formGroupHeading">Meeting Info</div>
                    <hr className="formGroupHeadingUnderline"></hr>
                  </div>
                </div>
                {subject && room && chair && invitees && minuteTaker ? (
                  <>
                    {!meetingView && (
                      <div className="col-1 d-flex justify-content-end mt-2 cursor-pointer">
                        <i
                          className="bx bxs-pencil text-primary bx-sm"
                          onClick={() => {
                            setMeetingInfoModalVisibility(true);
                          }}
                        ></i>
                      </div>
                    )}
                    <div className="row m-1 mb-3">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 p-3 selected-row-border">
                        <div className="form-group">
                          <div className="d-flex align-items-center">
                            <div>
                              <img src={ScheduleImg}></img>
                            </div>
                            <div className="w-350 mx-3">
                              <label htmlFor="startTime">
                                Start Date / Time
                              </label>
                              <div className="text-disabled">
                                {dataFindMeetingById?.findOneMeeting?.meeting
                                  .startTime ? (
                                  <>
                                    {moment(
                                      dataFindMeetingById?.findOneMeeting
                                        ?.meeting.startTime
                                    ).format('DD-MM-YYYY')}{' '}
                                    (
                                    {moment(
                                      dataFindMeetingById?.findOneMeeting
                                        ?.meeting.startTime
                                    ).format('HH:mm')}
                                    )
                                  </>
                                ) : (
                                  'N/A'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 p-3 selected-row-border d-flex align-items-center">
                        <div className="form-group">
                          <div className="w-350">
                            <div>
                              <label htmlFor="meetingVenue">
                                Meeting Venue
                              </label>
                            </div>
                            <div className="mt-2 text-disabled">
                              {venue ? venue : 'N/A'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-1 mb-3">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="chair">Chair</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {chair ? chair : 'N/A'}
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="subject">Subject</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {subject ? subject : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-1 mb-3">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="meetingRoom">Meeting Room</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {room ? room : 'N/A'}
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="otherRoom">Other Room</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {otherRoom ? otherRoom : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-1 mb-3">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="auditor">Auditor</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {auditor ? auditor : 'N/A'}
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-350">
                            <label htmlFor="minuteTaker">Minute Taker</label>
                          </div>
                          <div className="mt-2 text-disabled">
                            {minuteTaker ? minuteTaker : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-2 mb-3">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                          <div className="d-flex w-100">
                            <label htmlFor="invitees">Invitees</label>
                          </div>
                          {invitees?.map((invitee: any, index: number) => (
                            <div className="row" key={index}>
                              <div className="col-12 mt-2 text-disabled">
                                <ul>
                                  <li>{invitee}</li>
                                </ul>
                              </div>
                            </div>
                          ))}
                          {!invitees || invitees?.length === 0 ? (
                            <div className="mt-2 text-disabled">{'N/A'}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        {meetingType === MeetingTypeEnum.COMMITTEE_MEETING ? (
                          <div className="form-group">
                            <div className="d-flex w-100">
                              <label htmlFor="committeeTors">
                                Committee TORs
                              </label>
                            </div>
                            {selectedCommitteeIndex === 0 ||
                            selectedCommitteeIndex > 0 ? (
                              dataGetAllCommitteesForMeeting?.findAllCommitteesForMeeting?.edges[
                                selectedCommitteeIndex
                              ].node?.committeeObjectives?.map(
                                (objective: any, index: number) => (
                                  <div className="row" key={index}>
                                    <div className="col-12 mt-2 text-disabled">
                                      <ul>
                                        <li>{objective?.actionItem}</li>
                                      </ul>
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <div className="mt-2 text-disabled">{'N/A'}</div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12 d-flex flex-column align-items-center gap-4">
                        <button
                          type="button"
                          className="btn primaryButton d-flex align-items-center"
                          onClick={() => {
                            if (meetingType && meetingSubType) {
                              setMeetingInfoModalVisibility(true);
                            } else {
                              dispatch(
                                setToastError(
                                  'Please select meeting and meeting sub type first!'
                                )
                              );
                            }
                          }}
                        >
                          <i className="bx bx-plus"></i>
                          <div className="ms-1">Add Info</div>
                        </button>
                        <div className="font-14 text-disabled">
                          By Clicking on Add Button, you can Add info
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-4">
              {loadingGetActionItemsOfAgenda ||
              loadingFindMeetingInviteesInfo ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                </div>
              ) : (
                <>
                  {meetingType && meetingSubType ? (
                    <>
                      {meetingActionItems.length !== 0 ? (
                        <>
                          <div className="row">
                            {meetingActionItems?.map(
                              (actionItem: any, index: number) => (
                                <div
                                  className={`col-11 border-div mt-3 mx-3
                                  ${
                                    expandedRow !== index
                                      ? 'selected-row-border'
                                      : ''
                                  }
                                   `}
                                  key={index}
                                >
                                  <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-xxl-1 col-xl-2 col-2 d-flex justify-content-center align-items-center">
                                      <input
                                        className="height-20 width-20 form-check-input"
                                        type="checkbox"
                                        id={actionItem.id}
                                        name={actionItem.id}
                                        value={JSON.stringify(actionItem)}
                                        disabled={true}
                                        checked={true}
                                      ></input>
                                    </div>
                                    <div className="col-xxl-10 col-xl-12 col-12">
                                      <div className="m-0 my-2 font-19 text-center">
                                        {actionItem?.actionItem}
                                        {dataFindMeetingById ? (
                                          <div className="mt-2 d-flex justify-content-between flex-wrap w-100">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id="actionItemStatus"
                                                disabled={
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.APPROVED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.REJECTED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CREATED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CANCELLED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.SCHEDULED
                                                    ? true
                                                    : false
                                                }
                                                checked={
                                                  actionItem?.status ===
                                                  MeetingActionItemStatusEnum.PENDING
                                                    ? true
                                                    : false
                                                }
                                                onChange={e => {
                                                  if (e.target.checked) {
                                                    let tempMeetingActionItems =
                                                      [...meetingActionItems];
                                                    tempMeetingActionItems[
                                                      index
                                                    ].status =
                                                      MeetingActionItemStatusEnum.PENDING;
                                                    setMeetingActionItems(
                                                      tempMeetingActionItems
                                                    );
                                                  }
                                                }}
                                              ></input>
                                              <label className="form-check-label">
                                                Pending
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id="actionItemStatus"
                                                disabled={
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.APPROVED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.REJECTED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CREATED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CANCELLED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.SCHEDULED
                                                    ? true
                                                    : false
                                                }
                                                checked={
                                                  actionItem?.status ===
                                                  MeetingActionItemStatusEnum.APPROVED
                                                    ? true
                                                    : false
                                                }
                                                onChange={e => {
                                                  if (e.target.checked) {
                                                    let tempMeetingActionItems =
                                                      [...meetingActionItems];
                                                    tempMeetingActionItems[
                                                      index
                                                    ].status =
                                                      MeetingActionItemStatusEnum.APPROVED;
                                                    setMeetingActionItems(
                                                      tempMeetingActionItems
                                                    );
                                                  }
                                                }}
                                              ></input>
                                              <label className="form-check-label">
                                                Approved
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                id="actionItemStatus"
                                                disabled={
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.APPROVED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    .meetingActionItems[index]
                                                    ?.status ===
                                                    MeetingActionItemStatusEnum.REJECTED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CREATED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.CANCELLED ||
                                                  dataFindMeetingById
                                                    ?.findOneMeeting?.meeting
                                                    ?.status ===
                                                    MeetingStatus.SCHEDULED
                                                    ? true
                                                    : false
                                                }
                                                checked={
                                                  actionItem?.status ===
                                                  MeetingActionItemStatusEnum.REJECTED
                                                    ? true
                                                    : false
                                                }
                                                onChange={e => {
                                                  if (e.target.checked) {
                                                    let tempMeetingActionItems =
                                                      [...meetingActionItems];
                                                    tempMeetingActionItems[
                                                      index
                                                    ].status =
                                                      MeetingActionItemStatusEnum.REJECTED;
                                                    setMeetingActionItems(
                                                      tempMeetingActionItems
                                                    );
                                                  }
                                                }}
                                              ></input>
                                              <label className="form-check-label">
                                                Rejected
                                              </label>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-xxl-1 col-xl-12 col-12 d-flex justify-content-center align-items-center mt-1">
                                      <i
                                        className={`bx bx-sm ${
                                          expandedRow === index
                                            ? 'bx-chevron-up'
                                            : 'bx-chevron-down'
                                        } mx-2 my-1 cursor-pointer`}
                                        onClick={() => {
                                          expandedRow === index
                                            ? setExpandedRow(-1)
                                            : setExpandedRow(index);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                  {expandedRow === index ? (
                                    <>
                                      <div className="col-12">
                                        <ul className="nav nav-tabs">
                                          <li
                                            className="nav-item cursor-pointer"
                                            onClick={e => {
                                              setSelectedTab(1);
                                            }}
                                          >
                                            <a
                                              className={`nav-link ${
                                                selectedTab === 1 && 'active'
                                              }`}
                                            >
                                              Minutes of Meeting
                                            </a>
                                          </li>
                                          <li
                                            className="nav-item cursor-pointer"
                                            onClick={e => {
                                              setSelectedTab(2);
                                            }}
                                          >
                                            <a
                                              className={`nav-link ${
                                                selectedTab === 2 && 'active'
                                              }`}
                                            >
                                              Resolution
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      {selectedTab === 1 ? (
                                        <>
                                          <div className="row">
                                            <div className="col-12 mb-3">
                                              <DefaultEditor
                                                value={actionItem?.mom}
                                                onChange={e => {
                                                  let tempMeetingActionItems = [
                                                    ...meetingActionItems,
                                                  ];
                                                  tempMeetingActionItems[
                                                    index
                                                  ].mom = e.target.value.replaceAll(
                                                    '&nbsp;',
                                                    ' '
                                                  );
                                                  setMeetingActionItems(
                                                    tempMeetingActionItems
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-2">
                                              <div className="font-weight-600">
                                                Attachment
                                              </div>
                                              {/* <div className="font-11">
                                                    Documents
                                                  </div> */}
                                              <div className="attachedDocumentDiv cursor-pointer">
                                                <label
                                                  htmlFor={'file-input'}
                                                  className="cursor-pointer"
                                                >
                                                  <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                                                </label>
                                                <input
                                                  id="file-input"
                                                  type="file"
                                                  accept=".jpg,.jpeg,.png,.pdf,.docx"
                                                  onChangeCapture={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    if (e?.target?.files) {
                                                      const selectedFile =
                                                        e?.target?.files[0];

                                                      // Perform file type validation
                                                      if (selectedFile) {
                                                        const allowedFileTypes =
                                                          [
                                                            '.jpg',
                                                            '.jpeg',
                                                            '.png',
                                                            '.pdf',
                                                            '.docx',
                                                          ];
                                                        const fileExtension =
                                                          selectedFile.name.substring(
                                                            selectedFile.name.lastIndexOf(
                                                              '.'
                                                            )
                                                          );
                                                        if (
                                                          !allowedFileTypes.includes(
                                                            fileExtension.toLowerCase()
                                                          )
                                                        ) {
                                                          // File type not allowed
                                                          dispatch(
                                                            setToastError(
                                                              'Only JPG, PNG, PDF and DOCX files are allowed.'
                                                            )
                                                          );
                                                          e.target.value = ''; // Clear the selected file
                                                          return;
                                                        }

                                                        // Perform further processing with the selected file
                                                        let tempMomFiles = [
                                                          ...momFiles,
                                                        ];
                                                        tempMomFiles[index] =
                                                          e?.target?.files[0].name;
                                                        setMomFiles(
                                                          tempMomFiles
                                                        );
                                                        let tempMeetingActionItems =
                                                          [
                                                            ...meetingActionItems,
                                                          ];
                                                        tempMeetingActionItems[
                                                          index
                                                        ].momFiles =
                                                          e.target.files;
                                                        setMeetingActionItems(
                                                          tempMeetingActionItems
                                                        );
                                                      }
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-10 d-flex justify-content-center align-items-center">
                                              {momFiles[index] ? (
                                                <div className="row d-flex justify-content-center align-items-center">
                                                  <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <ul className="d-flex justify-content-center align-items-center">
                                                      <li>{momFiles[index]}</li>
                                                      {!meetingView ? (
                                                        <i
                                                          className="bx bx-x bx-sm mx-3 cursor-pointer"
                                                          onClick={e => {
                                                            let tempMomFiles = [
                                                              ...momFiles,
                                                            ];
                                                            tempMomFiles[
                                                              index
                                                            ] = '';
                                                            setMomFiles(
                                                              tempMomFiles
                                                            );
                                                            let tempMeetingActionItems =
                                                              [
                                                                ...meetingActionItems,
                                                              ];
                                                            tempMeetingActionItems[
                                                              index
                                                            ].momFiles = '';
                                                            setMeetingActionItems(
                                                              tempMeetingActionItems
                                                            );
                                                          }}
                                                        ></i>
                                                      ) : null}
                                                    </ul>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                      {selectedTab === 2 ? (
                                        <>
                                          <div className="row">
                                            <div className="col-12 mb-3">
                                              <DefaultEditor
                                                value={actionItem?.resolution}
                                                onChange={e => {
                                                  let tempMeetingActionItems = [
                                                    ...meetingActionItems,
                                                  ];
                                                  tempMeetingActionItems[
                                                    index
                                                  ].resolution = e.target.value.replaceAll(
                                                    '&nbsp;',
                                                    ' '
                                                  );
                                                  setMeetingActionItems(
                                                    tempMeetingActionItems
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-2">
                                              <div className="font-weight-600">
                                                Attachment
                                              </div>
                                              {/* <div className="font-11">
                                                    Documents
                                                  </div> */}
                                              <div className="attachedDocumentDiv cursor-pointer">
                                                <label
                                                  htmlFor={'file-input'}
                                                  className="cursor-pointer"
                                                >
                                                  <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                                                </label>
                                                <input
                                                  id="file-input"
                                                  type="file"
                                                  accept=".jpg,.jpeg,.png,.pdf,.docx"
                                                  onChangeCapture={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    if (e?.target?.files) {
                                                      const selectedFile =
                                                        e?.target?.files[0];

                                                      // Perform file type validation
                                                      if (selectedFile) {
                                                        const allowedFileTypes =
                                                          [
                                                            '.jpg',
                                                            '.jpeg',
                                                            '.png',
                                                            '.pdf',
                                                            '.docx',
                                                          ];
                                                        const fileExtension =
                                                          selectedFile.name.substring(
                                                            selectedFile.name.lastIndexOf(
                                                              '.'
                                                            )
                                                          );
                                                        if (
                                                          !allowedFileTypes.includes(
                                                            fileExtension.toLowerCase()
                                                          )
                                                        ) {
                                                          // File type not allowed
                                                          dispatch(
                                                            setToastError(
                                                              'Only JPG, PNG, PDF and DOCX files are allowed.'
                                                            )
                                                          );
                                                          e.target.value = ''; // Clear the selected file
                                                          return;
                                                        }

                                                        // Perform further processing with the selected file
                                                        let tempResolutionFiles =
                                                          [...resolutionFiles];

                                                        tempResolutionFiles[
                                                          index
                                                        ] =
                                                          e?.target?.files[0].name;
                                                        setResolutionFiles(
                                                          tempResolutionFiles
                                                        );
                                                        let tempMeetingActionItems =
                                                          [
                                                            ...meetingActionItems,
                                                          ];
                                                        tempMeetingActionItems[
                                                          index
                                                        ].resolutionFiles =
                                                          e.target.files;
                                                        setMeetingActionItems(
                                                          tempMeetingActionItems
                                                        );
                                                      }
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-10 d-flex justify-content-center align-items-center">
                                              {resolutionFiles[index] ? (
                                                <div className="row d-flex justify-content-center align-items-center">
                                                  <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <ul className="d-flex justify-content-center align-items-center">
                                                      <li>
                                                        {resolutionFiles[index]}
                                                      </li>
                                                      {!meetingView ? (
                                                        <i
                                                          className="bx bx-x bx-sm mx-3 cursor-pointer"
                                                          onClick={e => {
                                                            let tempResolutionFiles =
                                                              [
                                                                ...resolutionFiles,
                                                              ];

                                                            tempResolutionFiles[
                                                              index
                                                            ] = '';
                                                            setResolutionFiles(
                                                              tempResolutionFiles
                                                            );
                                                            let tempMeetingActionItems =
                                                              [
                                                                ...meetingActionItems,
                                                              ];
                                                            tempMeetingActionItems[
                                                              index
                                                            ].resolutionFiles =
                                                              '';
                                                            setMeetingActionItems(
                                                              tempMeetingActionItems
                                                            );
                                                          }}
                                                        ></i>
                                                      ) : null}
                                                    </ul>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              )
                            )}
                          </div>
                          {!meetingView ? (
                            <div className="row my-4">
                              <div className="col-12 d-flex justify-content-end align-items-end">
                                <button
                                  type="button"
                                  className="btn primary-btn-new-modal d-flex align-items-center my-auto fw-bold"
                                  disabled={loadingGetActionItemsOfAgenda}
                                  onClick={() => {
                                    setAddAgendaModalVisibility(true);
                                  }}
                                >
                                  <i className="bx bx-plus"></i>
                                  <div className="ms-1">
                                    Edit Selected Agenda
                                  </div>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className="min-height-50-vh d-flex justify-content-center align-items-center">
                            <img src={NoAgendaImg}></img>
                          </div>
                          <div className="text-center font-14 noAgendaText">
                            By Clicking on Add Button you can Add Agenda
                          </div>
                          <div className="row my-4">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                              <button
                                type="button"
                                className="btn primary-btn-new-modal d-flex align-items-center justify-content-center"
                                disabled={loadingGetActionItemsOfAgenda}
                                onClick={() => {
                                  setAddAgendaModalVisibility(true);
                                }}
                              >
                                <i className="bx bx-plus"></i>
                                <div className="ms-1">Add Agenda</div>
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="min-height-50-vh d-flex justify-content-center align-items-center">
                        <img src={NoAgendaImg}></img>
                      </div>
                      <div className="text-center font-14 noAgendaText">
                        Please Select Meeting Type and Sub Type
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        size="lg"
        isOpen={meetingInfoModalVisibility}
        toggle={() => {
          toggleMeetingInfoModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-plus-circle text-primary bx-sm  me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Add Meeting Info
              </h5>
            </div>

            <button
              onClick={() => {
                toggleMeetingInfoModal();
              }}
              type="button"
              className="close bx-sm"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {modalError ? (
              <div className="text-danger fw-bold ms-auto text-center mb-3">
                {modalError}
              </div>
            ) : null}

            <div className="inputFields">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="subject">Subject*</label>
                      {meetingInfoValidation.touched.subject &&
                      meetingInfoValidation.errors.subject ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.subject}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.subject &&
                        meetingInfoValidation.errors.subject &&
                        'is-invalid'
                      }`}
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      value={meetingInfoValidation.values.subject}
                      disabled={meetingView}
                      onChange={e => {
                        meetingInfoValidation.handleChange(e);
                        dispatch(setSubject(e.currentTarget.value));
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="auditor">
                        Auditor (Email)
                        {meetingType === MeetingTypeEnum.COMMITTEE_MEETING &&
                        meetingSubType ===
                          MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING
                          ? '*'
                          : null}
                      </label>
                      {meetingInfoValidation.touched.auditor &&
                      meetingInfoValidation.errors.auditor ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.auditor}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="email"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.auditor &&
                        meetingInfoValidation.errors.auditor &&
                        'is-invalid'
                      }`}
                      id="auditor"
                      name="auditor"
                      placeholder="Auditor Email"
                      value={meetingInfoValidation.values.auditor}
                      disabled={meetingView}
                      onChange={e => {
                        meetingInfoValidation.handleChange(e);
                        dispatch(setAuditor(e.currentTarget.value));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="room">Meeting Room*</label>
                      {meetingInfoValidation.touched.room &&
                      meetingInfoValidation.errors.room ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.room}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.room &&
                        meetingInfoValidation.errors.room &&
                        'is-invalid'
                      }`}
                      id="room"
                      name="room"
                      placeholder="Meeting Room"
                      value={meetingInfoValidation.values.room}
                      disabled={meetingView}
                      onChange={e => {
                        meetingInfoValidation.handleChange(e);
                        dispatch(setRoom(e.currentTarget.value));
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="otherRoom">Other Meeting Room</label>
                      {meetingInfoValidation.touched.otherRoom &&
                      meetingInfoValidation.errors.otherRoom ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.otherRoom}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.otherRoom &&
                        meetingInfoValidation.errors.otherRoom &&
                        'is-invalid'
                      }`}
                      id="otherRoom"
                      name="otherRoom"
                      placeholder="Other Room"
                      value={meetingInfoValidation.values.otherRoom}
                      disabled={meetingView}
                      onChange={e => {
                        meetingInfoValidation.handleChange(e);
                        dispatch(setOtherRoom(e.currentTarget.value));
                      }}
                    />
                  </div>
                </div>
              </div>
              {meetingType === MeetingTypeEnum.COMMITTEE_MEETING ? (
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex w-350">
                        <label htmlFor="committeeName">Committee Name</label>
                        {meetingInfoValidation.touched.committeeName &&
                        meetingInfoValidation.errors.committeeName ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {meetingInfoValidation.errors.committeeName}
                          </p>
                        ) : null}
                      </div>
                      <div
                        style={{ position: 'relative', width: 'max-content' }}
                      >
                        <select
                          className={`form-control form-control-medium bg-transparent ${
                            meetingInfoValidation.touched.committeeName &&
                            meetingInfoValidation.errors.committeeName &&
                            'is-invalid'
                          }`}
                          id="committeeName"
                          name="committeeName"
                          placeholder="Meeting Type"
                          value={meetingInfoValidation.values.committeeName}
                          disabled={meetingView}
                          onChange={e => {
                            meetingInfoValidation.handleChange(e);
                            dispatch(setCommitteeId(e.currentTarget.value));
                          }}
                        >
                          <option value="">Select</option>
                          {dataGetAllCommitteesForMeeting?.findAllCommitteesForMeeting?.edges?.map(
                            (val: any, index: number) => (
                              <option key={index} value={val.node.committeeId}>
                                {val?.node?.committeeName}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12"></div>
                </div>
              ) : null}

              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-start align-items-center">
                  <label htmlFor="isSendEmail">Virtual Meeting</label>
                  <input
                    className="mx-3 form-check-input"
                    type="checkbox"
                    id={'isVirtualMeeting'}
                    name={'isVirtualMeeting'}
                    value={meetingInfoValidation.values.isVirtualMeeting.toString()}
                    disabled={meetingView}
                    checked={
                      meetingInfoValidation.values.isVirtualMeeting
                        ? true
                        : false
                    }
                    onChange={e => {
                      dispatch(setToastError(''));
                      if (e.target.checked === true) {
                        dispatch(setIsVirtualMeeting(true));
                        meetingInfoValidation.setFieldValue(
                          'isVirtualMeeting',
                          true
                        );
                      } else {
                        dispatch(setIsVirtualMeeting(false));
                        meetingInfoValidation.setFieldValue(
                          'isVirtualMeeting',
                          false
                        );
                      }
                    }}
                  ></input>
                </div>
              </div>
              {isVirtualMeeting && (
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <div className="d-flex w-350">
                        <label htmlFor="zoomLink">Virtual Meeting Link*</label>
                        {meetingInfoValidation.touched.zoomLink &&
                        meetingInfoValidation.errors.zoomLink ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {meetingInfoValidation.errors.zoomLink}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-350 ${
                          meetingInfoValidation.touched.zoomLink &&
                          meetingInfoValidation.errors.zoomLink &&
                          'is-invalid'
                        }`}
                        id="zoomLink"
                        name="zoomLink"
                        placeholder="Virtual Meeting Link"
                        value={meetingInfoValidation.values.zoomLink}
                        disabled={meetingView}
                        onChange={e => {
                          dispatch(setZoomLink(e.target.value));
                          meetingInfoValidation.setFieldValue(
                            'zoomLink',
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12"></div>
                </div>
              )}
              {!isVirtualMeeting && (
                <>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="venue">Venue*</label>
                          {meetingInfoValidation.touched.venue &&
                          meetingInfoValidation.errors.venue ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {meetingInfoValidation.errors.venue}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-350 ${
                            meetingInfoValidation.touched.venue &&
                            meetingInfoValidation.errors.venue &&
                            'is-invalid'
                          }`}
                          id="venue"
                          name="venue"
                          placeholder="Meeting venue"
                          value={meetingInfoValidation.values.venue}
                          disabled={meetingView}
                          onChange={e => {
                            dispatch(setVenue(e.target.value));
                            meetingInfoValidation.setFieldValue(
                              'venue',
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="country">Country*</label>
                          {meetingInfoValidation.touched.country &&
                          meetingInfoValidation.errors.country ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {meetingInfoValidation.errors.country}
                            </p>
                          ) : null}
                        </div>
                        <div
                          style={{ position: 'relative', width: 'max-content' }}
                        >
                          <select
                            className={`form-control w-350 ${
                              meetingInfoValidation.touched.country &&
                              meetingInfoValidation.errors.country &&
                              'is-invalid'
                            }`}
                            id="country"
                            name="country"
                            placeholder="Country"
                            value={meetingInfoValidation.values.country}
                            disabled={
                              meetingView ||
                              (meetingType ===
                                MeetingTypeEnum.GENERAL_MEETING &&
                                meetingSubType ===
                                  MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING) ||
                              (meetingType ===
                                MeetingTypeEnum.GENERAL_MEETING &&
                                meetingSubType ===
                                  MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING)
                            }
                            onChange={e => {
                              let country: ICountry = countries.find(
                                (x: ICountry) =>
                                  x.name === e.currentTarget.value
                              );
                              dispatch(setCountry(e.currentTarget.value));
                              if (country) {
                                setSelectedCountry(country.isoCode);
                              }
                              setSelectedProvince(undefined);
                              meetingInfoValidation.setFieldValue('city', '');
                              meetingInfoValidation.setFieldValue(
                                'province',
                                ''
                              );
                              meetingInfoValidation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {countries?.map(
                              (country: ICountry, index: number) => (
                                <option value={country.name} key={index}>
                                  {country.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="province">Province*</label>
                          {meetingInfoValidation.touched.province &&
                          meetingInfoValidation.errors.province ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {meetingInfoValidation.errors.province}
                            </p>
                          ) : null}
                        </div>
                        <div
                          style={{ position: 'relative', width: 'max-content' }}
                        >
                          <select
                            className={`form-control w-350 ${
                              meetingInfoValidation.touched.province &&
                              meetingInfoValidation.errors.province &&
                              'is-invalid'
                            }`}
                            id="province"
                            name="province"
                            placeholder="Province"
                            value={meetingInfoValidation.values.province}
                            disabled={
                              meetingView ||
                              (meetingType ===
                                MeetingTypeEnum.GENERAL_MEETING &&
                                meetingSubType ===
                                  MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING) ||
                              (meetingType ===
                                MeetingTypeEnum.GENERAL_MEETING &&
                                meetingSubType ===
                                  MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING)
                            }
                            onChange={e => {
                              let state: IState = states.find(
                                (x: IState) => x.name === e.target.value
                              );
                              setSelectedCountry(selectedCountry);
                              dispatch(setProvince(e.target.value));
                              if (state) {
                                setSelectedProvince(state.isoCode);
                              }
                              meetingInfoValidation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {states?.map((state: IState, index: number) => (
                              <option value={state.name} key={index}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <div className="d-flex w-350">
                          <label htmlFor="city">City*</label>
                          {meetingInfoValidation.touched.city &&
                          meetingInfoValidation.errors.city ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {meetingInfoValidation.errors.city}
                            </p>
                          ) : null}
                        </div>
                        <div
                          style={{ position: 'relative', width: 'max-content' }}
                        >
                          <select
                            className={`form-control w-350 ${
                              meetingInfoValidation.touched.city &&
                              meetingInfoValidation.errors.city &&
                              'is-invalid'
                            }`}
                            id="city"
                            name="city"
                            placeholder="City"
                            value={meetingInfoValidation.values.city}
                            disabled={meetingView}
                            onChange={e => {
                              dispatch(setCity(e.target.value));
                              meetingInfoValidation.handleChange(e);
                            }}
                          >
                            <option value="">Select</option>
                            {cities?.map((city: ICity, index: number) => (
                              <option value={city.name} key={index}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="chair">Chair (Email)*</label>
                      {meetingInfoValidation.touched.chair &&
                      meetingInfoValidation.errors.chair ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.chair}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="email"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.chair &&
                        meetingInfoValidation.errors.chair &&
                        'is-invalid'
                      }`}
                      id="chair"
                      name="chair"
                      placeholder="Chair Email"
                      value={meetingInfoValidation.values.chair}
                      disabled={meetingView}
                      onChange={e => {
                        dispatch(setChair(e.target.value));
                        meetingInfoValidation.handleChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <div className="d-flex w-350">
                      <label htmlFor="minuteTaker">Minute Taker (Email)*</label>
                      {meetingInfoValidation.touched.minuteTaker &&
                      meetingInfoValidation.errors.minuteTaker ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.minuteTaker}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="email"
                      className={`form-control w-350 ${
                        meetingInfoValidation.touched.minuteTaker &&
                        meetingInfoValidation.errors.minuteTaker &&
                        'is-invalid'
                      }`}
                      id="minuteTaker"
                      name="minuteTaker"
                      placeholder="Minute Taker Email"
                      value={meetingInfoValidation.values.minuteTaker}
                      disabled={meetingView}
                      onChange={e => {
                        dispatch(setMinuteTaker(e.target.value));
                        meetingInfoValidation.handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className="form-group">
                    <div className="d-flex w-100">
                      <label htmlFor="invitees">Invitees (Email)*</label>
                      {meetingInfoValidation.touched.invitees &&
                      meetingInfoValidation.errors.invitees ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {meetingInfoValidation.errors.invitees}
                        </p>
                      ) : null}
                    </div>
                    <ReactMultiEmail
                      placeholder="Invitees Email"
                      className={`multiple_emails-container ${
                        meetingView ? 'pointer-none' : ''
                      }`}
                      emails={invitees}
                      onChange={(_emails: string[]) => {
                        let stringifyEmails = _emails.join(';');
                        dispatch(setInvitees(_emails));
                        meetingInfoValidation.setFieldValue(
                          'invitees',
                          stringifyEmails
                        );
                      }}
                      validateEmail={email => {
                        var index = invitees.findIndex((x: any) => x === email);
                        if (index >= 0) {
                          setModalError('Email already added');
                        }
                        return isEmail(email);
                      }}
                      getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void
                      ) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-12"></div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  disabled={loadingCreateMeeting || loadingUpdateMeeting}
                  onMouseDown={() => {
                    meetingInfoValidation.handleSubmit();
                  }}
                >
                  {(loadingCreateMeeting || loadingUpdateMeeting) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
      <Modal
        size="md"
        isOpen={agendaInfoModalVisibility}
        toggle={() => {
          toggleAgendaInfoModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-plus-circle text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Add Agenda Info
              </h5>
            </div>

            <button
              onClick={() => {
                toggleAgendaInfoModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="inputFields">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="actionItem">Action Item*</label>
                      {agendaValidation.touched.actionItem &&
                      agendaValidation.errors.actionItem ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.actionItem}
                        </p>
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className={`form-control ${
                        agendaValidation.touched.actionItem &&
                        agendaValidation.errors.actionItem &&
                        'is-invalid'
                      }`}
                      id="actionItem"
                      name="actionItem"
                      placeholder="Action Item"
                      value={agendaValidation.values.actionItem}
                      disabled={meetingView}
                      onChange={agendaValidation.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="ordinance">Ordinance*</label>
                      {agendaValidation.touched.ordinance &&
                      agendaValidation.errors.ordinance ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.ordinance}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.ordinance &&
                          agendaValidation.errors.ordinance &&
                          'is-invalid'
                        }`}
                        id="ordinance"
                        name="ordinance"
                        placeholder="Ordinance"
                        value={agendaValidation.values.ordinance}
                        disabled={meetingView}
                        onChange={e => {
                          agendaValidation.setFieldValue(
                            'ordinance',
                            e.target.value
                          );
                          setSelectOrdinanceName(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {dataGetAllOrdinance?.findAllOrdinance?.edges?.map(
                          (ordinance: any, ordinanceIndex: number) => (
                            <option
                              value={ordinance?.node?.ordinanceName}
                              key={ordinanceIndex}
                            >
                              {ordinance?.node?.ordinanceName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="section">Section*</label>
                      {agendaValidation.touched.section &&
                      agendaValidation.errors.section ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.section}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.section &&
                          agendaValidation.errors.section &&
                          'is-invalid'
                        }`}
                        id="section"
                        name="section"
                        placeholder="Section"
                        value={agendaValidation.values.section}
                        disabled={meetingView}
                        onChange={agendaValidation.handleChange}
                      >
                        <option value="">Select</option>
                        {selectSection &&
                          selectSection[0]?.node?.section.map(
                            (section: any, sectionIndex: number) => (
                              <>
                                <option value={section?.id} key={sectionIndex}>
                                  {section?.sectionName}
                                </option>
                              </>
                            )
                          )}{' '}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex w-400">
                      <label htmlFor="businessType">Business Type*</label>
                      {agendaValidation.touched.businessType &&
                      agendaValidation.errors.businessType ? (
                        <p className="text-danger font-12 ms-auto mb-0">
                          {agendaValidation.errors.businessType}
                        </p>
                      ) : null}
                    </div>
                    <div style={{ position: 'relative', width: 'max-content' }}>
                      <select
                        className={`form-control w-400 ${
                          agendaValidation.touched.businessType &&
                          agendaValidation.errors.businessType &&
                          'is-invalid'
                        }`}
                        id="businessType"
                        name="businessType"
                        placeholder="Business Type"
                        value={agendaValidation.values.businessType}
                        disabled={meetingView}
                        onChange={agendaValidation.handleChange}
                      >
                        <option value="">Select</option>
                        <option value={BusinessTypeEnum.ORDINARY_BUSINESS}>
                          Ordinary Business
                        </option>
                        <option value={BusinessTypeEnum.SPECIAL_BUSINESS}>
                          Special Business
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="font-weight-600">Attachment</div>
                  {/* <div className="font-11">Documents</div> */}
                  <div
                    className={`attachedDocumentDiv cursor-pointer ${
                      meetingView && 'icon-disabled'
                    }`}
                  >
                    <label htmlFor={'file-input'} className="cursor-pointer">
                      <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.docx"
                      onChangeCapture={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (e?.target?.files) {
                          const selectedFile = e?.target?.files[0];

                          // Perform file type validation
                          if (selectedFile) {
                            const allowedFileTypes = [
                              '.jpg',
                              '.jpeg',
                              '.png',
                              '.pdf',
                              '.docx',
                            ];
                            const fileExtension = selectedFile.name.substring(
                              selectedFile.name.lastIndexOf('.')
                            );
                            if (
                              !allowedFileTypes.includes(
                                fileExtension.toLowerCase()
                              )
                            ) {
                              // File type not allowed
                              dispatch(
                                setToastError(
                                  'Only JPG, PNG, PDF and DOCX files are allowed.'
                                )
                              );
                              e.target.value = ''; // Clear the selected file
                              return;
                            }

                            // Perform further processing with the selected file
                            agendaValidation.setFieldValue(
                              'attachment',
                              e?.target?.files[0]
                            );
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-10 d-flex justify-content-start align-items-center">
                  {agendaValidation.values.attachment?.name ? (
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <ul className="d-flex justify-content-center align-items-center">
                          <li>{agendaValidation.values.attachment.name}</li>
                          {!meetingView ? (
                            <i
                              className="bx bx-x bx-sm mx-3 cursor-pointer"
                              onClick={e => {
                                agendaValidation.setFieldValue(
                                  'attachment',
                                  ''
                                );
                              }}
                            ></i>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center m-3 fw-bold"
                  disabled={loadingCreateAgenda}
                  onMouseDown={() => {
                    agendaValidation.handleSubmit();
                  }}
                >
                  {loadingCreateAgenda && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
      <Modal
        size="lg"
        isOpen={addAgendaModalVisibility}
        toggle={() => {
          toggleAddAgendaModal();
        }}
      >
        <Container fluid>
          <div className="modal-header">
            <div className="d-flex justify-content-center align-items-center">
              <i className="bx bx-plus-circle text-primary bx-sm me-2"></i>
              <h5 className="modal-title" id="myLargeModalLabel">
                Add Agenda
              </h5>
            </div>

            <button
              onClick={() => {
                toggleAddAgendaModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="inputFields">
              <div className="row">
                {actionItemsData?.map((actionItem: any, index: number) => (
                  <div
                    className={`col-12 border-div mt-3  ${
                      checkIfActionItemSelected(actionItem) &&
                      expandedRow !== index
                        ? 'selected-row-border'
                        : ''
                    }`}
                    key={index}
                  >
                    <div className="row">
                      <div className="col-1 d-flex justify-content-center align-items-center">
                        <input
                          className={`height-20 width-20 form-check-input`}
                          type="checkbox"
                          id={actionItem.id}
                          name={actionItem.id}
                          value={JSON.stringify(actionItem)}
                          disabled={meetingView}
                          checked={
                            meetingView
                              ? true
                              : checkIfActionItemSelected(actionItem)
                          }
                          onChange={e => {
                            dispatch(setToastError(''));
                            if (e.target.checked === true) {
                              let temp = [...actionItems];
                              temp.push(e.target.value);
                              dispatch(setActionItems(temp));
                            } else {
                              let temp = [...actionItems];
                              let parsedActionItems: any = [];
                              actionItems.forEach((element: any) => {
                                let parseActionItem = JSON.parse(element);
                                parseActionItem.actionItemId =
                                  parseActionItem.id;
                                delete parseActionItem['id'];
                                delete parseActionItem['__typename'];
                                var index = parsedActionItems.findIndex(
                                  (x: any) =>
                                    x.actionItemId ===
                                    parseActionItem.actionItemId
                                );
                                if (index === -1) {
                                  parsedActionItems.push(parseActionItem);
                                }
                              });
                              var index = parsedActionItems.findIndex(
                                (x: any) =>
                                  x.actionItemId ===
                                  JSON.parse(e.currentTarget.value).id
                              );
                              if (index !== -1) {
                                temp.splice(index, 1);
                              }
                              let parsedTemp: any = [];
                              temp.forEach(element => {
                                let parsedElement = JSON.parse(element);
                                parsedElement.actionItemId = parsedElement.id;
                                delete parsedElement['id'];
                                parsedTemp.push(parsedElement);
                              });
                              setMeetingActionItems(parsedTemp);
                              dispatch(setActionItems(temp));
                            }
                          }}
                        ></input>
                      </div>
                      <div className="col-10">
                        <div className="m-0 my-2 font-19">
                          {actionItem?.actionItem}
                        </div>
                        <div className="badge agenda-badge mb-2">
                          {GetAgendaBusinessType(actionItem.businessType)}
                        </div>
                        {actionItem?.attachmentUrl ? (
                          <div
                            className="badge agenda-view-badge mb-2 mx-2 cursor-pointer"
                            onClick={e => {
                              window.open(
                                JSON.parse(actionItem?.attachmentUrl).url,
                                'new'
                              );
                            }}
                          >
                            View
                          </div>
                        ) : null}
                      </div>
                      {checkIfActionItemSelected(actionItem) ? (
                        <div className="col-1 d-flex justify-content-center align-items-center mt-1">
                          <i
                            className={`bx ${
                              expandedRow === index
                                ? 'bx-chevron-up'
                                : 'bx-chevron-down'
                            } mx-2 my-1 cursor-pointer`}
                            onClick={() => {
                              expandedRow === index
                                ? setExpandedRow(-1)
                                : setExpandedRow(index);
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                    {expandedRow === index ? (
                      <div className="row mb-2">
                        <div className="col-12">
                          <DefaultEditor
                            value={actionItem?.resolution}
                            onChange={e => {
                              let tempMeetingActionItems = [
                                ...meetingActionItems,
                              ];
                              tempMeetingActionItems[index].resolution =
                                e.target.value.replaceAll('&nbsp;', ' ');
                              setMeetingActionItems(tempMeetingActionItems);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-start align-items-start p-0">
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center fw-bold"
                  disabled={loadingGetActionItemsOfAgenda}
                  onClick={() => {
                    setAgendaInfoModalVisibility(true);
                  }}
                >
                  <i className="bx bx-plus"></i>
                  <div className="ms-1">Add More</div>
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-end align-items-center p-0">
                <button
                  type="button"
                  className="btn primaryButton d-flex align-items-center fw-bold"
                  disabled={loadingCreateAgenda}
                  onMouseDown={() => {
                    setAddAgendaModalVisibility(false);
                  }}
                >
                  {loadingCreateAgenda && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
      <ScheduleMeetingModal
        visible={modalVisibility}
        modalSelectedMeetingId={selectedMeetingId}
        modalInvitees={invitees}
        onHide={() => {
          setModalVisibility(false);
        }}
      />
      {!loadingReliventDocumnets &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
