import { MeetingSubTypeEnum } from '../../commons/enums';
const GetMeetingSubType = (meetingSubType: string) => {
  if (meetingSubType === MeetingSubTypeEnum.ANNUAL_GENERAL_MEETING) {
    return 'Annual General Meeting';
  } else if (meetingSubType === MeetingSubTypeEnum.AUDIT_COMMITTEE_MEETING) {
    return 'Audit Committee Meeting';
  } else if (
    meetingSubType === MeetingSubTypeEnum.EXECUTIVE_COMMITTEE_MEETING
  ) {
    return 'Executive Committee Meeting';
  } else if (meetingSubType === MeetingSubTypeEnum.HR_COMMITTEE_MEETING) {
    return 'HR Committee Meeting';
  } else if (
    meetingSubType === MeetingSubTypeEnum.NOMINATION_COMMITTEE_MEETING
  ) {
    return 'Nomination Committee Meeting';
  } else if (
    meetingSubType === MeetingSubTypeEnum.PROCUREMENT_COMMITTEE_MEETING
  ) {
    return 'Precurement Committee Meeting';
  } else if (
    meetingSubType === MeetingSubTypeEnum.RISK_MANAGEMENT_COMMITTEE_MEETING
  ) {
    return 'Risk Management Committee Meeting';
  } else if (meetingSubType === MeetingSubTypeEnum.BOARD_MEETING) {
    return 'Board Meeting';
  } else if (
    meetingSubType === MeetingSubTypeEnum.EXTRAORDINARY_GENERAL_MEETING
  ) {
    return 'Extraordinary General Meeting';
  } else if (meetingSubType === MeetingSubTypeEnum.OTHER_COMMITTEE_MEETING) {
    return 'Other Committee Meeting';
  } else if (meetingSubType === MeetingSubTypeEnum.OTHER) {
    return 'Other';
  }
};

export default GetMeetingSubType;
