import { createSlice } from '@reduxjs/toolkit';
import { CommitteeStoreType } from './types';

const initialState: CommitteeStoreType = {
  selectedCommitteeId: '',
  committeeView: false,
  committeeName: '',
  committeeHead: '',
  committeeMembers: '',
  startDate: '',
  isSendEmailInvitation: false,
};
const committeeSlice = createSlice({
  name: 'committee',
  initialState,
  reducers: {
    setSelectedCommitteeId: (state, { payload }) => {
      state.selectedCommitteeId = payload;
    },
    setCommitteeViewScreen: (state, { payload }) => {
      state.committeeView = payload;
    },
    setCommitteeName: (state, { payload }) => {
      state.committeeName = payload;
    },
    setCommitteeHead: (state, { payload }) => {
      state.committeeHead = payload;
    },
    setCommitteeMembers: (state, { payload }) => {
      state.committeeMembers = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setIsSendEmailInvitation: (state, { payload }) => {
      state.isSendEmailInvitation = payload;
    },
  },
});

export const {
  setSelectedCommitteeId,
  setCommitteeViewScreen,
  setCommitteeName,
  setCommitteeHead,
  setCommitteeMembers,
  setStartDate,
  setIsSendEmailInvitation,
} = committeeSlice.actions;

export default committeeSlice.reducer;
