import { SectorialClassificationEnum } from '../../commons/enums';
const GetSectorialClassification = (status: number) => {
  if (status == SectorialClassificationEnum.BASIC_MATERIALS) {
    return 'Basic Materials';
  } else if (status == SectorialClassificationEnum.CONSUMER_GOODS) {
    return 'Consumer Goods';
  } else if (status == SectorialClassificationEnum.CONSUMER_SERVICES) {
    return 'Consumer Services';
  } else if (status == SectorialClassificationEnum.FINANCIALS) {
    return 'Financials';
  } else if (status == SectorialClassificationEnum.HEALTH_CARE) {
    return 'Health Care';
  } else if (status == SectorialClassificationEnum.INDUSTRIALS) {
    return 'Industrials';
  } else if (status == SectorialClassificationEnum.MISCELLANEOUS) {
    return 'Miscellaneous';
  } else if (status == SectorialClassificationEnum.OIL_AND_GAS) {
    return 'Oil and Gas';
  } else if (status == SectorialClassificationEnum.TECHNOLOGY) {
    return 'Technology';
  } else if (status == SectorialClassificationEnum.TELECOMMUNICATIONS) {
    return 'Telecommunications';
  } else if (status == SectorialClassificationEnum.UTILITIES) {
    return 'Utilities';
  }
};

export default GetSectorialClassification;
