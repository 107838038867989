import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import {
  FIND_COMPANY_PROFILE_BY_USER_ID,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE_DRAFT,
} from '~/Store/graphQL/CompanyProfile';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import * as Yup from 'yup';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { Country } from '~/assets/StatesCities';
import { ModuleEnum, UserTypeEnum } from '~/commons/enums';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';

export const RegisteredOfficeAddress: FC = () => {
  const { companyID, companyView } = useAppSelector(
    state => state.companyProfileReducer
  );
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [tempData, setTempData] = useState<any>();
  const [states, setStates] = useState<any>();

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Registered Office Address',
        },
      ])
    );
  }, []);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findCompanyByUserId,
    {
      loading: loadingCompanyProfileByUserId,
      error: errorCompanyProfileByUserId,
      data: dataCompanyProfileByUserId,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_USER_ID);

  const [
    draftUpdateCompanyProfile,
    {
      loading: loadingCompanyDraft,
      error: errorCompanyDraft,
      data: dataCompanyDraft,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE_DRAFT
    // { errorPolicy: 'all' }
  );

  const [
    updateCompanyProfile,
    {
      loading: loadingupdateCompanyProfile,
      error: errorupdateCompanyProfile,
      data: dataupdateCompanyProfile,
    },
  ] = useMutation<any>(
    UPDATE_COMPANY_PROFILE
    // { errorPolicy: 'all' }
  );

  useEffect(() => {
    if (userId) {
      findCompanyByUserId({
        variables: {
          id: userId,
          isIntermedialUser:
            userType === UserTypeEnum.INTERMEDIAL_USER ? true : false,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [userId]);

  useEffect(() => {
    if (dataCompanyProfileByUserId) {
      setTempData(
        dataCompanyProfileByUserId?.findCompanyProfileByUserId?.companyProfile
      );
      setIsDraft(
        dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
          .isDraft
      );
      setStates(
        Country.states.find(
          state =>
            state.name ===
            dataCompanyProfileByUserId.findCompanyProfileByUserId.companyProfile
              .province
        )
      );
    } else if (errorCompanyProfileByUserId) {
      console.log('errorCompanyProfileByUserId:', errorCompanyProfileByUserId);
    }
  }, [errorCompanyProfileByUserId, dataCompanyProfileByUserId]);

  useEffect(() => {
    if (dataCompanyDraft && !errorCompanyDraft) {
    } else if (errorCompanyDraft) {
      dispatch(setToastError(errorCompanyDraft));
    }
  }, [errorCompanyDraft, dataCompanyDraft]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      address: tempData?.address ? tempData.address : '',
      city: tempData?.city ? tempData.city : '',
      district: tempData?.district ? tempData.district : '',
      province: tempData?.province ? tempData.province : '',
      town: tempData?.town ? tempData.town : '',
      postalCode: tempData?.postalCode ? tempData.postalCode : '',
      mobile: tempData?.mobile ? tempData.mobile : '',
      phone: tempData?.phone ? tempData.phone : '',
      fax: tempData?.fax ? tempData.fax : '',
      email: tempData?.email ? tempData.email : '',
      websiteUrl: tempData?.websiteUrl ? tempData.websiteUrl : '',
    },
    validationSchema: Yup.object({
      address: Yup.string().required('Please enter address').nullable(),
      city: Yup.string().required('Please enter city').nullable(),
      district: Yup.string().required('Please select a district').nullable(),
      province: Yup.string().required('Please select a province').nullable(),
      town: Yup.string().required('Please enter area/town').nullable(),
      postalCode: Yup.string().required('Please enter postal code').nullable(),
      mobile: Yup.string().required('Please enter mobile number').nullable(),
      phone: Yup.string().required('Please enter phone number').nullable(),
      fax: Yup.string().required('Please enter fax number').nullable(),
      email: Yup.string().required('Please enter email address').nullable(),
      websiteUrl: Yup.string().required('Please enter website url').nullable(),
    }),
    onSubmit: values => {
      if (isDraft) {
        draftUpdateCompanyProfile({
          variables: {
            id: companyID,
            address: values.address,
            city: values.city,
            district: values.district,
            province: values.province,
            town: values.town,
            postalCode: values.postalCode,
            mobile: values.mobile,
            phone: values.phone,
            fax: values.fax,
            email: values.email,
            websiteUrl: values.websiteUrl,
          },
        })
          .then((res: any) => {
            if (res.data.draftUpdateProfileCompanyDraft.draftCompanyProfile) {
              dispatch(setToastSuccess('Company profile draft updated!'));
              navigate('/companyProfile/subsidiary');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyProfile({
          variables: {
            id: companyID,
            address: values.address,
            city: values.city,
            district: values.district,
            province: values.province,
            town: values.town,
            postalCode: values.postalCode,
            mobile: values.mobile,
            phone: values.phone,
            fax: values.fax,
            email: values.email,
            websiteUrl: values.websiteUrl,
          },
        })
          .then((res: any) => {
            if (res.data.updateCompanyProfile.companyProfile) {
              dispatch(setToastSuccess('Company profile updated!'));
              navigate('/companyProfile/subsidiary');
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          Company Profile Info
          <span>
            <div className="d-flex justify-content-end gap-3 position-absolute end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingCompanyDraft}
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primaryDismissButton d-flex align-items-center "
                  disabled={loadingCompanyDraft}
                  onMouseDown={() => {
                    navigate('/companyProfile/companyInformation');
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary primaryButton d-flex align-items-center "
                  disabled={loadingCompanyDraft}
                  onMouseDown={() => {
                    companyView
                      ? navigate('/companyProfile/subsidiary')
                      : validation.handleSubmit();
                  }}
                >
                  {(loadingCompanyDraft || loadingupdateCompanyProfile) && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  {companyView ? 'Next' : 'Save & Next'}
                  <i className="bx bx-chevron-right ms-1" />
                </button>
              </div>
            </div>
          </span>
        </div>
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link active ">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link active cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link cursor-pointer"
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        <div id="mainComponentNew">
          {loadingCompanyProfileByUserId ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <div className="inputFields">
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="address">Address*</label>
                  {validation.touched.address && validation.errors.address ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.address}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.address &&
                    validation.errors.address &&
                    'is-invalid'
                  }`}
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={validation.values.address}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.address &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.address &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            address: validation.values.address,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="province">Province*</label>
                  {validation.touched.province && validation.errors.province ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.province}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.province &&
                      validation.errors.province &&
                      'is-invalid'
                    }`}
                    id="province"
                    name="province"
                    placeholder="Province"
                    value={validation.values.province}
                    disabled={companyView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.province &&
                          isDraft &&
                          validation.values.province &&
                          draftUpdateCompanyProfile({
                            variables: {
                              id: companyID,
                              province: validation.values.province,
                            },
                          });
                      }
                    }}
                    onChange={e => {
                      validation.handleChange(e);
                      const { value } = e.target;
                      value &&
                        setStates(
                          Country.states.find(state => state.name === value)
                        );
                      validation.setFieldValue('city', '');
                      validation.setFieldValue('district', '');
                    }}
                  >
                    <option value="">Select</option>
                    {Country.states?.map(state => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="district">District*</label>
                  {validation.touched.district && validation.errors.district ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.district}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.district &&
                      validation.errors.district &&
                      'is-invalid'
                    }`}
                    id="district"
                    name="district"
                    placeholder="District"
                    value={validation.values.district}
                    disabled={companyView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.district &&
                          isDraft &&
                          validation.values.district &&
                          draftUpdateCompanyProfile({
                            variables: {
                              id: companyID,
                              district: validation.values.district,
                            },
                          });
                      }
                    }}
                    onChange={validation.handleChange}
                  >
                    <option value="">Select</option>
                    {states?.cities
                      ?.filter((data: any) => data?.district)
                      .map((city: any) => (
                        <option value={city.name}>{city.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="city">City*</label>
                  {validation.touched.city && validation.errors.city ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.city}
                    </p>
                  ) : null}
                </div>
                <div style={{ position: 'relative', width: 'max-content' }}>
                  <select
                    className={`form-control ${
                      validation.touched.city &&
                      validation.errors.city &&
                      'is-invalid'
                    }`}
                    id="city"
                    name="city"
                    placeholder="City"
                    value={validation.values.city}
                    disabled={companyView}
                    onBlur={e => {
                      validation.handleBlur(e);
                      {
                        !validation.errors.city &&
                          isDraft &&
                          validation.values.city &&
                          draftUpdateCompanyProfile({
                            variables: {
                              id: companyID,
                              city: validation.values.city,
                            },
                          });
                      }
                    }}
                    onChange={validation.handleChange}
                  >
                    <option value="">Select</option>
                    {states?.cities
                      ?.filter((data: any) => !data.name.includes('District'))
                      .map((city: any) => (
                        <option value={city.name}>{city.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="town">Area / Town*</label>
                  {validation.touched.town && validation.errors.town ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.town}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.town &&
                    validation.errors.town &&
                    'is-invalid'
                  }`}
                  id="town"
                  name="town"
                  placeholder="Area / Town"
                  value={validation.values.town}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.town &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.town &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            town: validation.values.town,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="postalCode">Postal Code*</label>
                  {validation.touched.postalCode &&
                  validation.errors.postalCode ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.postalCode}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.postalCode &&
                    validation.errors.postalCode &&
                    'is-invalid'
                  }`}
                  id="postalCode"
                  name="postalCode"
                  placeholder="Postal Code"
                  value={validation.values.postalCode}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.postalCode &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.postalCode &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            postalCode: validation.values.postalCode,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="email">Email ID*</label>
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>
                <input
                  type="email"
                  className={`form-control ${
                    validation.touched.email &&
                    validation.errors.email &&
                    'is-invalid'
                  }`}
                  id="email"
                  name="email"
                  placeholder="Email ID"
                  value={validation.values.email}
                  disabled={true}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.email &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.email &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            email: validation.values.email,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="phone">Telephone*</label>
                  {validation.touched.phone && validation.errors.phone ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.phone}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.phone &&
                    validation.errors.phone &&
                    'is-invalid'
                  }`}
                  id="phone"
                  name="phone"
                  placeholder="Telephone"
                  value={validation.values.phone}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.phone &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.phone &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            phone: validation.values.phone,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="mobile">Mobile*</label>
                  {validation.touched.mobile && validation.errors.mobile ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.mobile}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.mobile &&
                    validation.errors.mobile &&
                    'is-invalid'
                  }`}
                  id="mobile"
                  name="mobile"
                  placeholder="Mobile"
                  value={validation.values.mobile}
                  disabled={true}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.mobile &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.mobile &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            mobile: validation.values.mobile,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="fax">Fax*</label>
                  {validation.touched.fax && validation.errors.fax ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.fax}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.fax &&
                    validation.errors.fax &&
                    'is-invalid'
                  }`}
                  id="fax"
                  name="fax"
                  placeholder="Fax"
                  value={validation.values.fax}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.fax &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.fax &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            fax: validation.values.fax,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
              <div className="form-group">
                <div className="d-flex w-400">
                  <label htmlFor="websiteUrl">Website URL*</label>
                  {validation.touched.websiteUrl &&
                  validation.errors.websiteUrl ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validation.errors.websiteUrl}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    validation.touched.websiteUrl &&
                    validation.errors.websiteUrl &&
                    'is-invalid'
                  }`}
                  id="websiteUrl"
                  name="websiteUrl"
                  placeholder="URL"
                  value={validation.values.websiteUrl}
                  disabled={companyView}
                  onBlur={e => {
                    validation.handleBlur(e);
                    {
                      !validation.errors.websiteUrl &&
                        isDraft &&
                        !loadingCompanyDraft &&
                        validation.values.websiteUrl &&
                        draftUpdateCompanyProfile({
                          variables: {
                            id: companyID,
                            websiteUrl: validation.values.websiteUrl,
                          },
                        });
                    }
                  }}
                  onChange={validation.handleChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
