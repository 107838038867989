import { gql } from '@apollo/client';
export const GET_ALL_FINANCIAL_YEARS_OF_COMPANY = gql`
  query findAllFinancialYears(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
  ) {
    findAllFinancialYears(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId }
    ) {
      edges {
        node {
          id
          name
          startDate
          endDate
          companyId
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const ADD_FINANCIAL_YEAR = gql`
  mutation createFinancialYear(
    $companyId: String
    $name: String
    $startDate: String
    $endDate: String
  ) {
    createFinancialYear(
      data: {
        companyId: $companyId
        name: $name
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      financialYear {
        id
        name
        startDate
        endDate
        companyId
        isActive
      }
    }
  }
`;
export const UPDATE_FINANCIAL_YEAR = gql`
  mutation updateFinancialYear(
    $id: String!
    $name: String
    $startDate: String
    $endDate: String
  ) {
    updateFinancialYear(
      data: {
        id: $id
        data: { name: $name, startDate: $startDate, endDate: $endDate }
      }
    ) {
      financialYear {
        id
        name
        startDate
        endDate
        companyId
        isActive
      }
    }
  }
`;
export const FIND_FINANCIAL_YEAR_BY_ID = gql`
  query findOneFinancialYear($id: ID!) {
    findOneFinancialYear(id: $id) {
      financialYear {
        id
        name
        startDate
        endDate
        companyId
      }
    }
  }
`;
export const DELETE_FINANCIAL_YEAR = gql`
  mutation deleteFinancialYear($id: String!) {
    deleteFinancialYear(data: { id: $id }) {
      count
    }
  }
`;
