import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import { ACCESS_PRIVILAGES } from '~/commons/enums/AccessPrivileges';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
  setUserId,
} from '~/Store/reducers/layout';

import {
  ASSIGN_PERMISSION_TO_USERGROUP,
  FIND_USERGROUP_PERMISSIONS,
  FIND_USER_GROUPS,
  UPDATE_PERMISSION_OF_USERGROUP,
} from '~/Store/graphQL/UserGroup';
import getModules from '~/helpers/Permissions/getModules';

export const AddPermission: FC = () => {
  const [temp, setTemp] = useState<any>([]);
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const [userGroups, setuserGroups] = useState<any>([]);
  const [usergroupId, setusergroupId] = useState<any>();
  const navigate = useNavigate();

  const data = ACCESS_PRIVILAGES;

  const { selectedUserGroupId } = useAppSelector(
    state => state.userGroupReducer
  );

  const [
    updatePermissionOfUserGroup,
    {
      loading: loadingUpdatePermissionToUserGroup,
      error: errorUpdatePermissionToUserGroup,
      data: dataUpdatePermissionToUserGroup,
    },
  ] = useMutation<any>(UPDATE_PERMISSION_OF_USERGROUP, {
    fetchPolicy: 'no-cache',
  });

  const [
    assignPermissionToUserGroup,
    {
      loading: loadingAssignPermissionToUserGroup,
      error: errorAssignPermissionToUserGroup,
      data: dataAssignPermissionToUserGroup,
    },
  ] = useMutation<any>(ASSIGN_PERMISSION_TO_USERGROUP, {
    fetchPolicy: 'no-cache',
  });

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      userGroup: selectedUserGroupId ? selectedUserGroupId : '',
    },

    validationSchema: Yup.object({
      userGroup: Yup.string().required('Please select user group').nullable(),
    }),
    onSubmit: (values: any) => {
      if (selectedUserGroupId) {
        temp?.forEach((element: any) => {
          delete element['groupName'];
          delete element['__typename'];
          delete element['userGroupId'];
          delete element['id'];
        });

        updatePermissionOfUserGroup({
          variables: {
            userGroupId: usergroupId,
            permissionArr: temp,
          },
        })
          .then(res => {
            if (res.data.updatePermissionsOfUserGroup.permission) {
              dispatch(setToastSuccess('Privileges updated for user group '));
              navigate('/permission');
            }
          })
          .catch(dispatch(setToastError(errorAssignPermissionToUserGroup)));
      } else {
        assignPermissionToUserGroup({
          variables: {
            userGroupId: usergroupId,
            permissionArr: temp,
          },
        })
          .then(res => {
            if (res.data.assignPermissionsToUserGroup.permission) {
              dispatch(
                setToastSuccess('Privileges assigned to the user group')
              );
              navigate('/permission');
            }
          })
          .catch(dispatch(setToastError(errorAssignPermissionToUserGroup)));
      }
    },
  });

  useEffect(() => {
    if (selectedUserGroupId) {
      setusergroupId(selectedUserGroupId);
      findUserGroupPermission();
    }

    findUserGroups();
    dispatch(
      setBreadCrumb([
        {
          url: '/permission',
          title: 'Access Privileges',
        },
        {
          url: location.pathname,
          title: 'Access Privileges Information',
        },
      ])
    );

    setTemp(data);
  }, []);

  const [
    findUserGroups,
    {
      loading: loadingFindUserGroup,
      error: errorFindUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUPS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataFindUserGroup) {
      setuserGroups(dataFindUserGroup.userGroups.edges);
    }
  }, [dataFindUserGroup]);

  const [
    findUserGroupPermission,
    {
      loading: loadingFindUserGroupPermission,
      error: errorFindUserGroupPermission,
      data: dataFindUserGroupPermission,
    },
  ] = useLazyQuery<any>(FIND_USERGROUP_PERMISSIONS, {
    variables: {
      userGroupId: selectedUserGroupId,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataFindUserGroupPermission) {
      setTemp(
        dataFindUserGroupPermission.findPermissionsOfUserGroup.permission
      );
      setUserId(
        dataFindUserGroupPermission.findPermissionsOfUserGroup.permission[0]
          .userGroupId
      );
    }
  }, [dataFindUserGroupPermission]);

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          Permission Info
          <span>
            <div className="d-flex justify-content-end gap-3 position-absolute end-0 mx-4">
              <button
                type="button"
                className="btn primaryButton d-flex align-items-center my-auto fw-bold"
                onClick={() => {
                  navigate('/permission');
                }}
              >
                Cancel
              </button>
              {true ? (
                <button
                  type="button"
                  className="btn successButton d-flex align-items-center my-auto fw-bold"
                  onClick={() => {
                    validation.handleSubmit();
                  }}
                >
                  {loadingAssignPermissionToUserGroup && (
                    <i className="bx bx-loader-circle bx-spin me-1" />
                  )}
                  Save
                  <i className="bx bx-upload mx-1" />
                </button>
              ) : null}
            </div>
          </span>
        </div>
        <div id="mainComponent" className="m-0">
          <div className="w-100">
            <div className="d-flex justify-content-between mt-2 mb-4">
              <div className="companyMemberfields">
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="userGroup">User Group</label>
                    {validation.touched.userGroup &&
                    validation.errors.userGroup ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validation.errors.userGroup}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="userGroup"
                      name="userGroup"
                      className={`form-control`}
                      disabled={selectedUserGroupId}
                      value={selectedUserGroupId ? selectedUserGroupId : ''}
                      onChange={e => {
                        validation.setFieldValue('userGroup', e.target.value);
                        setusergroupId(e.target.value);
                      }}
                    >
                      <option>Select</option>
                      {userGroups?.map((val: any, index: number) => (
                        <option key={index} value={val.node.id}>
                          {val.node.groupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div id="mainComponent">
              <div className="w-100">
                <>
                  <>
                    <div className="table-responsive">
                      <div className="d-flex w-300 action-items mb-3">
                        <label htmlFor="userGroup">Access Privileges</label>
                      </div>
                      <table>
                        <thead className="bg-primary">
                          <tr>
                            <th className="col-1 text-white">Module</th>

                            <th className="col-1 text-white">View</th>
                            <th className="col-1 text-white">Edit</th>
                            <th className="col-1 text-white">Delete</th>
                            <th className="col-1 text-white">Email</th>
                            <th className="col-1 text-white">
                              Generate Documents
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {temp?.map((val: any, index: number) => (
                            <tr key={`${index}`}>
                              <td>{getModules(val.moduleName)}</td>

                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'view'}
                                  name={'view'}
                                  checked={val.view}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].view = e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'edit'}
                                  name={'edit'}
                                  checked={val.edit}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].edit = e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'delete'}
                                  name={'delete'}
                                  checked={val.delete}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].delete = e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'email'}
                                  name={'email'}
                                  checked={val.email}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].email = e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td>
                              <td>
                                <input
                                  className="me-3 form-check-input c-me"
                                  type="checkbox"
                                  id={'generateDocument'}
                                  name={'generateDocument'}
                                  checked={val.generateDocument}
                                  onChange={e => {
                                    const temp2 = JSON.parse(
                                      JSON.stringify(temp)
                                    );
                                    temp2[index].generateDocument =
                                      e.target.checked;
                                    setTemp(temp2);
                                  }}
                                ></input>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
