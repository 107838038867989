import { MeetingTypeEnum } from '../../commons/enums';
const GetMeetingType = (meetingType: string) => {
  if (meetingType === MeetingTypeEnum.BOARD_MEETING) {
    return 'Board Meeting';
  } else if (meetingType === MeetingTypeEnum.COMMITTEE_MEETING) {
    return 'Committee Meeting';
  } else if (meetingType === MeetingTypeEnum.GENERAL_MEETING) {
    return 'General Meeting';
  } else if (meetingType === MeetingTypeEnum.STATUTORY_MEETING) {
    return 'Statutory Meeting';
  } else if (meetingType === MeetingTypeEnum.OTHER) {
    return 'Other';
  }
};

export default GetMeetingType;
