import { gql } from '@apollo/client';
export const GET_ALL_AGENDA_OF_COMPANY = gql`
  query findAllAgendas(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $companyId: String
    $meetingType: String
  ) {
    findAllAgendas(
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, meetingType: $meetingType }
      orderBy: "createdAt"
    ) {
      edges {
        node {
          createdAt
          agendaId
          ordinanceType
          ordinanceSection
          meetingType
          meetingSubType
          businessType

          actionItems {
            id
            actionItem
            agendaId
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

// ($data.name: String, $description:String )

export const FIND_AGENDA_BY_ID = gql`
  query findOneAgenda($id: ID!) {
    findOneAgenda(id: $id) {
      agenda {
        agendaId
        ordinanceType
        ordinanceSection
        meetingType
        meetingSubType
        companyId
        businessType
        businessRuleId
        actionItems {
          id
          actionItem
          agendaId
          resolution
          attachmentUrl
        }
      }
    }
  }
`;
export const UPDATE_AGENDA = gql`
  mutation updateAgenda(
    $id: String!
    $companyId: String
    $ordinanceType: String
    $ordinanceSection: String
    $meetingType: String
    $meetingSubType: String
    $businessType: String
    $businessRuleId: String
    $actionItems: [ActionItemInput]
  ) {
    updateAgenda(
      data: {
        id: $id
        data: {
          ordinanceType: $ordinanceType
          companyId: $companyId
          ordinanceSection: $ordinanceSection
          meetingType: $meetingType
          meetingSubType: $meetingSubType
          businessType: $businessType
          businessRuleId: $businessRuleId
          actionItems: $actionItems
        }
      }
    ) {
      agenda {
        agendaId
        ordinanceType
        ordinanceSection
        meetingType
        meetingSubType
        companyId
        actionItems {
          id
          actionItem
          agendaId
        }
      }
    }
  }
`;

export const CREATE_AGENDA = gql`
  mutation createAgenda(
    $companyId: String
    $ordinanceType: String
    $ordinanceSection: String
    $meetingType: String
    $meetingSubType: String
    $businessType: String
    $businessRuleId: String
    $actionItems: [ActionItemInput]
  ) {
    createAgenda(
      data: {
        companyId: $companyId
        ordinanceType: $ordinanceType
        ordinanceSection: $ordinanceSection
        meetingType: $meetingType
        meetingSubType: $meetingSubType
        businessType: $businessType
        businessRuleId: $businessRuleId
        actionItems: $actionItems
      }
    ) {
      agenda {
        agendaId
        ordinanceType
        ordinanceSection
        meetingType
        meetingSubType
        companyId
        actionItems {
          id
          actionItem
          agendaId
        }
      }
    }
  }
`;
export const DELETE_AGENDA = gql`
  mutation deleteAgenda($id: String!) {
    deleteAgenda(data: { id: $id }) {
      count
    }
  }
`;
export const FIND_ACTION_ITEMS_OF_AGENDA = gql`
  query findActionItemByMeetingTypeAndSubType(
    $agendaIds: [String]
    $actionItemIds: [String]
    $meetingType: String
    $meetingSubType: String
    $q: String
  ) {
    findActionItemByMeetingTypeAndSubType(
      agendaIds: $agendaIds
      actionItemIds: $actionItemIds
      meetingType: $meetingType
      meetingSubType: $meetingSubType
      q: $q
    ) {
      actionItems {
        id
        actionItem
        agendaId
        resolution
        businessType
        attachmentUrl
      }
    }
  }
`;
