import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmationDialogue } from '~/components/Toasts';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';

import {
  FIND_USER_GROUPS,
  DELETE_USER_GROUP,
  SEARCH_USER_GROUPS,
} from '~/Store/graphQL/UserGroup';

import { setSelectedUserGroupId } from '~/Store/reducers/userGroup';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import {
  ModuleEnum,
  PermissionCategoryEnum,
  SearchParameterOptionsEnum,
  UserGroupSearchParameterOptionsEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { Tooltip } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export const UserGroup: FC = () => {
  const [temp, setTemp] = useState<any>([]);
  const [searchName, setSearchName] = useState<any>();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'System User Groups',
        },
      ])
    );

    findUserGroups({
      variables: {
        first: itemsPerPage,
        searchKey:
          validationSearch?.values?.searchKey !== 'ALL'
            ? validationSearch?.values?.searchKey
            : undefined,
        searchValue:
          validationSearch?.values?.searchValue !== 'Select'
            ? validationSearch?.values?.searchValue
            : undefined,
      },
    });
  }, []);

  const [
    searchUserGroups,
    {
      loading: loadingsearchUserGroup,
      error: errorsearchUserGroup,
      data: datasearchUserGroup,
    },
  ] = useLazyQuery<any>(SEARCH_USER_GROUPS, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (datasearchUserGroup) {
      setTemp(datasearchUserGroup.userGroups.edges);
    }
  }, [datasearchUserGroup]);

  const [
    findUserGroups,
    {
      loading: loadingFindUserGroup,
      error: errorFindUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUPS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataFindUserGroup) {
      setTemp(dataFindUserGroup.userGroups.edges);
    }
    if (errorFindUserGroup) {
      setToastError(errorFindUserGroup);
    }
  }, [dataFindUserGroup, errorFindUserGroup]);

  //Deleting userGroup
  const [
    deleteUserGroup,
    {
      loading: loadingDeleteUserGroup,
      error: errorDeleteUserGroup,
      data: dataDeleteUserGroup,
    },
  ] = useMutation<any>(DELETE_USER_GROUP, {});
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>User Groups</b>
              </h5>
            </div>
          </div>
          <div className="d-flex row mt-2 mb-4">
            <div className="officerfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="searchKey">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option
                      value={UserGroupSearchParameterOptionsEnum.GROUP_NAME}
                    >
                      {' '}
                      Name{' '}
                    </option>
                    <option
                      value={UserGroupSearchParameterOptionsEnum.DESCRIPTION}
                    >
                      {' '}
                      Description{' '}
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex w-300 mb-1">
                  <label htmlFor="searchValue">Search Data</label>
                  {validationSearch.touched.searchValue &&
                  validationSearch.errors.searchValue ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchValue}
                    </p>
                  ) : null}
                </div>
                <input
                  type="text"
                  id="searchValue"
                  name="searchValue"
                  placeholder="Text"
                  className={`form-control ${
                    validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue &&
                    'is-invalid'
                  }`}
                  value={validationSearch.values.searchValue}
                  onChange={e => {
                    const { value } = e.target;
                    validationSearch.setFieldValue('searchValue', value);
                  }}
                />
              </div>
              <div className="d-flex gap-3 align-self-center mt-2">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  onClick={() => {
                    if (validationSearch?.values?.searchValue) {
                      searchUserGroups({
                        variables: {
                          companyId: companyID,
                        },
                      });
                    }
                    validationSearch?.resetForm();
                  }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn fw-bold successButton d-flex align-items-center my-auto"
                  onClick={() => {
                    validationSearch.handleSubmit();
                    if (validationSearch?.values?.searchValue) {
                      searchUserGroups({
                        variables: {
                          companyId: companyID,
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                        },
                      });
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.SYSTEM_USER_GROUPS,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.SYSTEM_USER_GROUPS,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setSelectedUserGroupId(null));
                navigate('/usergroup/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add User Group
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.SYSTEM_USER_GROUPS,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>
          <div id="mainComponent">
            <div className="w-100">
              <>
                {loadingFindUserGroup ? (
                  <div className="d-flex justify-content-center align-items-center w-100 h-75">
                    <i className="bx bx-loader-circle bx-spin bx-lg"></i>
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table>
                        <thead className="">
                          <tr>
                            <th className="text-white">S.No</th>
                            <th className="text-white">Group Name</th>
                            <th className="text-white">Description</th>
                            <th className="text-white">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {temp?.map((val: any, index: number) => (
                            <tr key={`${index}`}>
                              <td>
                                <div className="badge custom-badge-primary mx-1">
                                  {index + 1}
                                </div>
                              </td>

                              <td>
                                <div className="badge custom-badge-success">
                                  {val.node.groupName}
                                </div>
                              </td>
                              <td>{val.node.description}</td>

                              <td>
                                <div className="editColumn mt-0 gap-1">
                                  <div
                                    className={`${
                                      !(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SYSTEM_USER_GROUPS,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      )
                                        ? 'cursor-not-allowed'
                                        : ''
                                    }`}
                                    onMouseEnter={() =>
                                      setEditToolTipIsOpen(true)
                                    }
                                    onMouseLeave={() =>
                                      setEditToolTipIsOpen(false)
                                    }
                                  >
                                    <i
                                      className={`bx bxs-edit text-primary ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SYSTEM_USER_GROUPS,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'icon-disabled'
                                          : ''
                                      }`}
                                      id="editIcon"
                                      onClick={() => {
                                        navigate('/usergroup/add');
                                        dispatch(
                                          setSelectedUserGroupId(val.node.id)
                                        );
                                      }}
                                    />
                                    {!(
                                      GetPermissionsOfUser(
                                        selectedUserPermissions,
                                        ModuleEnum.SYSTEM_USER_GROUPS,
                                        PermissionCategoryEnum.EDIT
                                      ) ||
                                      userType === UserTypeEnum.COMPANY_ADMIN
                                    ) ? (
                                      <Tooltip
                                        placement="top"
                                        isOpen={editToolTipIsOpen}
                                        target="editIcon"
                                      >
                                        You don't have permission for this
                                        module
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                  <div
                                    className={`${
                                      !(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SYSTEM_USER_GROUPS,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      )
                                        ? 'cursor-not-allowed'
                                        : ''
                                    }`}
                                    onMouseEnter={() =>
                                      setDeleteToolTipIsOpen(true)
                                    }
                                    onMouseLeave={() =>
                                      setDeleteToolTipIsOpen(false)
                                    }
                                  >
                                    <i
                                      className={`bx bxs-trash-alt text-danger ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SYSTEM_USER_GROUPS,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'icon-disabled'
                                          : ''
                                      }`}
                                      id="deleteIcon"
                                      onClick={() => {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteUserGroup({
                                              variables: {
                                                Id: val.node.id,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data.deleteUserGroup.count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'User Group Deleted!'
                                                    )
                                                  );
                                                  findUserGroups({
                                                    variables: {
                                                      first: itemsPerPage,
                                                      searchKey:
                                                        validationSearch?.values
                                                          ?.searchKey !== 'ALL'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchKey
                                                          : undefined,
                                                      searchValue:
                                                        validationSearch?.values
                                                          ?.searchValue !==
                                                        'Select'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchValue
                                                          : undefined,
                                                    },
                                                  });
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                    {!(
                                      GetPermissionsOfUser(
                                        selectedUserPermissions,
                                        ModuleEnum.SYSTEM_USER_GROUPS,
                                        PermissionCategoryEnum.DELETE
                                      ) ||
                                      userType === UserTypeEnum.COMPANY_ADMIN
                                    ) ? (
                                      <Tooltip
                                        placement="top"
                                        isOpen={deleteToolTipIsOpen}
                                        target="deleteIcon"
                                      >
                                        You don't have permission for this
                                        module
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {(dataFindUserGroup?.userGroups?.edges?.length === 0 ||
                        dataFindUserGroup?.userGroups?.edges === undefined) && (
                        <div className="addDiv">
                          <h6 className="m-3">No records Found</h6>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                      <p className="m-0 text-info">Rows Per Page:</p>
                      <select
                        className="form-control button-danger width-5vw"
                        value={itemsPerPage}
                        onChange={e => {
                          const { value } = e.target;
                          setItemsPerPage(Number(value));
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <i
                        className={
                          dataFindUserGroup?.userGroups?.pageInfo
                            ?.hasPreviousPage
                            ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                            : 'bx bx-caret-left bx-sm ms-auto'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataFindUserGroup?.userGroups?.pageInfo
                              ?.hasPreviousPage
                          ) {
                            findUserGroups({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                last: itemsPerPage,
                                before:
                                  dataFindUserGroup?.userGroups?.pageInfo
                                    ?.startCursor,
                              },
                            });
                          }
                        }}
                      />
                      <i
                        className={
                          dataFindUserGroup?.userGroups?.pageInfo?.hasNextPage
                            ? 'bx bx-caret-right color-primary bx-sm'
                            : 'bx bx-caret-right bx-sm'
                        }
                        onClick={() => {
                          if (
                            companyID &&
                            dataFindUserGroup?.userGroups?.pageInfo?.hasNextPage
                          ) {
                            findUserGroups({
                              variables: {
                                searchKey:
                                  validationSearch?.values?.searchKey !== 'ALL'
                                    ? validationSearch?.values?.searchKey
                                    : undefined,
                                searchValue:
                                  validationSearch?.values?.searchValue !==
                                  'Select'
                                    ? validationSearch?.values?.searchValue
                                    : undefined,
                                after:
                                  dataFindUserGroup?.userGroups?.pageInfo
                                    ?.endCursor,
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
