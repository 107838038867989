import { gql } from '@apollo/client';

export const GET_ALL_COMPANY_OFFICERS = gql`
  query getAllCompanyOfficers(
    $first: Int
    $name: String
    $companyId: String
    $status: [String]
    $last: Int
    $after: String
    $before: String
    $searchKey: String
    $searchValue: String
  ) {
    getAllCompanyOfficers(
      q: $name
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, status: $status }
      searchKey: $searchKey
      searchValue: $searchValue
      orderBy: "date"
    ) {
      draftCompanyOfficerConnection {
        edges {
          node {
            id
            firstName
            middleName
            lastName
            designation
            dateOfBirth
            cnic
            nationality
            city
            telephone
            postalCode
            status
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      companyOfficerConnection {
        edges {
          node {
            id
            firstName
            middleName
            lastName
            residentialAddress
            designation
            dateOfBirth
            cnic
            nationality
            city
            telephone
            postalCode
            status
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
export const FIND_ALL_COMPANY_OFFICERS = gql`
  query findAllCompanyOfficers(
    $first: Int
    $last: Int
    $name: String
    $companyId: String
    $designation: String
    $after: String
    $before: String
  ) {
    findAllCompanyOfficers(
      q: $name
      first: $first
      last: $last
      after: $after
      before: $before
      filterBy: { companyId: $companyId, designation: $designation }
    ) {
      edges {
        node {
          id
          firstName
          middleName
          lastName
          designation
          email
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FIND_COMPANY_OFFICER_BY_ID = gql`
  query findOneCompanyOfficer($id: ID!) {
    findOneCompanyOfficer(id: $id) {
      companyOfficer {
        id
        secpRefId
        firstName
        middleName
        lastName
        status
        designation
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        cnicExpiry
        passportNumber
        dateOfBirth
        gender
        residentialAddress
        otherOccupation
        noc
        city
        province
        country
        postalCode
        telephone
        email
        ntn
        date
        renumeration
        cessationReason
        companyId
        isDraft
        isActive
        url
        companyOfficerHistory {
          id
          secpRefId
          firstName
          middleName
          lastName
          status
          designation
          relativeFirstName
          relativeMiddleName
          relativeLastName
          relationship
          nationality
          cnic
          cnicExpiry
          passportNumber
          dateOfBirth
          gender
          residentialAddress
          otherOccupation
          noc
          city
          province
          country
          postalCode
          telephone
          email
          ntn
          date
          renumeration
          cessationReason
          companyId
          isDraft
          isActive
          url
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_OFFICER = gql`
  mutation update_companyOfficer(
    $id: String!
    $secpRefId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $designation: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $cnicExpiry: String
    $passportNumber: String
    $dateOfBirth: String
    $gender: String
    $residentialAddress: String
    $otherOccupation: String
    $noc: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $telephone: String
    $email: String
    $ntn: String
    $date: String
    $renumeration: String
    $cessationReason: String
    $companyId: String
    $files: [Upload]
  ) {
    update_companyOfficer(
      data: {
        id: $id
        data: {
          secpRefId: $secpRefId
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          status: $status
          designation: $designation
          relativeFirstName: $relativeFirstName
          relativeMiddleName: $relativeMiddleName
          relativeLastName: $relativeLastName
          relationship: $relationship
          nationality: $nationality
          cnic: $cnic
          cnicExpiry: $cnicExpiry
          passportNumber: $passportNumber
          dateOfBirth: $dateOfBirth
          gender: $gender
          residentialAddress: $residentialAddress
          otherOccupation: $otherOccupation
          noc: $noc
          city: $city
          province: $province
          country: $country
          postalCode: $postalCode
          telephone: $telephone
          email: $email
          ntn: $ntn
          date: $date
          renumeration: $renumeration
          cessationReason: $cessationReason
          companyId: $companyId
          files: $files
        }
      }
    ) {
      companyOfficer {
        id
        firstName
        middleName
        lastName
        status
        designation
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        dateOfBirth
        gender
        residentialAddress
        otherOccupation
        noc
        city
        province
        country
        postalCode
        telephone
        email
        ntn
        date
        renumeration
        cessationReason
        companyId
        isDraft
        isActive
        companyOfficerHistory {
          cnic
          status
          designation
        }
      }
    }
  }
`;

export const CREATE_COMPANY_OFFICER_DRAFT = gql`
  mutation draft_createCompanyOfficerDraft(
    $companyId: String!
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $designation: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $passportNumber: String
    $dateOfBirth: String
    $gender: String
    $residentialAddress: String
    $otherOccupation: String
    $noc: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $telephone: String
    $email: String
    $ntn: String
    $date: String
    $renumeration: String
    $cessationReason: String
  ) {
    draft_createCompanyOfficerDraft(
      data: {
        companyId: $companyId
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        status: $status
        designation: $designation
        relativeFirstName: $relativeFirstName
        relativeMiddleName: $relativeMiddleName
        relativeLastName: $relativeLastName
        relationship: $relationship
        nationality: $nationality
        cnic: $cnic
        passportNumber: $passportNumber
        dateOfBirth: $dateOfBirth
        gender: $gender
        residentialAddress: $residentialAddress
        otherOccupation: $otherOccupation
        noc: $noc
        city: $city
        province: $province
        country: $country
        postalCode: $postalCode
        telephone: $telephone
        email: $email
        ntn: $ntn
        date: $date
        renumeration: $renumeration
        cessationReason: $cessationReason
      }
    ) {
      draftCompanyOfficer {
        id
        firstName
        middleName
        lastName
        status
        designation
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        dateOfBirth
        gender
        residentialAddress
        otherOccupation
        noc
        city
        province
        country
        postalCode
        telephone
        email
        ntn
        date
        renumeration
        cessationReason
        companyId
        isDraft
        isActive
      }
    }
  }
`;
export const UPDATE_COMPANY_OFFICER_DRAFT = gql`
  mutation draft_updateCompanyOfficerDraft(
    $id: String!
    $companyId: String!
    $secpRefId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $designation: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $cnicExpiry: String
    $passportNumber: String
    $dateOfBirth: String
    $gender: String
    $residentialAddress: String
    $otherOccupation: String
    $noc: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $telephone: String
    $email: String
    $ntn: String
    $date: String
    $renumeration: String
    $cessationReason: String
    $files: [Upload]
  ) {
    draft_updateCompanyOfficerDraft(
      data: {
        id: $id
        data: {
          secpRefId: $secpRefId
          companyId: $companyId
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          status: $status
          designation: $designation
          relativeFirstName: $relativeFirstName
          relativeMiddleName: $relativeMiddleName
          relativeLastName: $relativeLastName
          relationship: $relationship
          nationality: $nationality
          cnic: $cnic
          cnicExpiry: $cnicExpiry
          passportNumber: $passportNumber
          dateOfBirth: $dateOfBirth
          gender: $gender
          residentialAddress: $residentialAddress
          otherOccupation: $otherOccupation
          noc: $noc
          city: $city
          province: $province
          country: $country
          postalCode: $postalCode
          telephone: $telephone
          email: $email
          ntn: $ntn
          date: $date
          renumeration: $renumeration
          cessationReason: $cessationReason
          files: $files
        }
      }
    ) {
      draftCompanyOfficer {
        id
        firstName
        middleName
        lastName
        status
        designation
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        dateOfBirth
        gender
        residentialAddress
        otherOccupation
        noc
        city
        province
        country
        postalCode
        telephone
        email
        ntn
        date
        renumeration
        cessationReason
        companyId
        isDraft
        isActive
      }
    }
  }
`;
export const CREATE_COMPANY_OFFICER_FROM_DRAFT = gql`
  mutation create_companyOfficerFromDraft(
    $secpRefId: String
    $firstName: String
    $middleName: String
    $lastName: String
    $status: String
    $designation: String
    $relativeFirstName: String
    $relativeMiddleName: String
    $relativeLastName: String
    $relationship: String
    $nationality: String
    $cnic: String
    $cnicExpiry: String
    $passportNumber: String
    $dateOfBirth: String
    $gender: String
    $residentialAddress: String
    $otherOccupation: String
    $noc: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $telephone: String
    $email: String
    $ntn: String
    $date: String
    $renumeration: String
    $cessationReason: String
    $companyId: String
    $files: [Upload]
  ) {
    create_companyOfficerFromDraft(
      data: {
        secpRefId: $secpRefId
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        status: $status
        designation: $designation
        relativeFirstName: $relativeFirstName
        relativeMiddleName: $relativeMiddleName
        relativeLastName: $relativeLastName
        relationship: $relationship
        nationality: $nationality
        cnic: $cnic
        cnicExpiry: $cnicExpiry
        passportNumber: $passportNumber
        dateOfBirth: $dateOfBirth
        gender: $gender
        residentialAddress: $residentialAddress
        otherOccupation: $otherOccupation
        noc: $noc
        city: $city
        province: $province
        country: $country
        postalCode: $postalCode
        telephone: $telephone
        email: $email
        ntn: $ntn
        date: $date
        renumeration: $renumeration
        cessationReason: $cessationReason
        companyId: $companyId
        files: $files
      }
    ) {
      companyOfficer {
        id
        firstName
        middleName
        lastName
        status
        designation
        relativeFirstName
        relativeMiddleName
        relativeLastName
        relationship
        nationality
        cnic
        passportNumber
        dateOfBirth
        gender
        residentialAddress
        otherOccupation
        noc
        city
        province
        country
        postalCode
        telephone
        email
        ntn
        date
        renumeration
        cessationReason
        companyId
        isDraft
        isActive
        companyOfficerHistory {
          cnic
          status
          designation
        }
      }
    }
  }
`;
export const DELETE_COMPANY_OFFICER = gql`
  mutation deleteCompanyOfficer($id: String!) {
    deleteCompanyOfficer(data: { id: $id }) {
      count
    }
  }
`;
export const DRAFT_DELETE_COMPANY_OFFICER = gql`
  mutation draft_deleteCompanyOfficerDraft($id: String!) {
    draft_deleteCompanyOfficerDraft(data: { id: $id }) {
      count
    }
  }
`;
export const CREATE_COMPANY_OFFICER_FROM_EXCEL_FILE = gql`
  mutation importCompanyOfficers(
    $companyId: String!
    $companyOfficer: [CreateCompanyOfficerInput!]!
  ) {
    importCompanyOfficers(
      data: { companyId: $companyId, companyOfficer: $companyOfficer }
    ) {
      isSuccessfull
    }
  }
`;
export const EXPORT_COMPANY_OFFICER_DATA_IN_EXCEL_FILE = gql`
  query exportCompanyOfficers($companyId: ID!) {
    exportCompanyOfficers(companyId: $companyId) {
      isSuccessful
      url
      fileName
    }
  }
`;
export const DELETE_FILE_FROM_OBS = gql`
  mutation deleteFileFromOBS($fileName: String!) {
    deleteFileFromOBS(data: { fileName: $fileName }) {
      isSuccessful
    }
  }
`;
export const CREATE_RESIGNATION_LETTER = gql`
  query createResignationLetter($id: ID!) {
    createResignationLetter(id: $id) {
      isGenerated
      url
    }
  }
`;
export const CREATE_POWER_OF_ATTORNEY_LETTER = gql`
  mutation createLetterOfAttorney(
    $sentTo: String
    $sentToAddress: String
    $auhtorizedToName: String
    $authorizedtoAddress: String
    $auhtorizedFromName: String
    $authorizedFromAddress: String
    $companyId: String
  ) {
    createLetterOfAttorney(
      data: {
        sentTo: $sentTo
        sentToAddress: $sentToAddress
        auhtorizedToName: $auhtorizedToName
        authorizedtoAddress: $authorizedtoAddress
        auhtorizedFromName: $auhtorizedFromName
        authorizedFromAddress: $authorizedFromAddress
        companyId: $companyId
      }
    ) {
      isGenerated
      url
    }
  }
`;
