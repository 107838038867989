import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DRAFT_DELETE_COMPANY_OFFICER,
  FIND_COMPANY_OFFICER_BY_ID,
  UPDATE_COMPANY_OFFICER,
  UPDATE_COMPANY_OFFICER_DRAFT,
} from '~/Store/graphQL/CompanyOfficer';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_OFFICER_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import {
  ActionEnum,
  DesignationEnum,
  GenderEnum,
  ModuleEnum,
  NOCEnum,
  OfficerStatusEnum,
  RelationshipEnum,
} from '../../../../commons/enums';
import { setSelectedOfficerId } from '~/Store/reducers/companyOfficer';
// import PhoneInput from 'react-phone-input-2';
import PI from 'react-phone-input-2';
const PhoneInput = (PI as any).default !== null ? (PI as any).default : PI;
import 'react-phone-input-2/lib/style.css';
import {
  AddressIcon,
  ContactInfoIcon,
  PersonalInfoLogo,
} from '~/assets/images';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import NumberBlockImg from '/images/number-blocks.png';

export const OfficerPersonalInformation: FC = () => {
  const { selectedOfficerId, officerView } = useAppSelector(
    state => state.companyOfficerReducer
  );
  const { companyID } = useAppSelector(state => state.companyProfileReducer);

  //
  const [countries, setCountries] = useState<ICountry[]>();
  const [states, setStates] = useState<IState[]>();
  const [cities, setCities] = useState<ICity[]>();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedState, setSelectedState] = useState<string>();
  //
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [officersData, setOfficersData] = useState<any>();
  //

  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: '/companyOfficer',
          title: 'Company Officer',
        },
        {
          url: location.pathname,
          title: 'Personal Information',
        },
      ])
    );
  }, []);
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
    } else if (selectedCountry) {
      setCities(City.getCitiesOfCountry(selectedCountry));
    }
  }, [selectedState, selectedCountry]);
  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_OFFICERS,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_OFFICER_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_OFFICERS,
    },
  });
  const [
    findCompanyOfficerById,
    {
      loading: loadingFindCompanyOfficerById,
      error: errorFindCompanyOfficerById,
      data: dataFindCompanyOfficerById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_OFFICER_BY_ID);

  const [
    draftUpdateCompanyOfficer,
    {
      loading: loadingDraftUpdateCompanyOfficer,
      error: errorDraftUpdateCompanyOfficer,
      data: dataDraftUpdateCompanyOfficer,
    },
  ] = useMutation<any>(UPDATE_COMPANY_OFFICER_DRAFT);
  const [
    updateCompanyOfficer,
    {
      loading: loadingupdateCompanyOfficer,
      error: errorupdateCompanyOfficer,
      data: dataupdateCompanyOfficer,
    },
  ] = useMutation<any>(UPDATE_COMPANY_OFFICER);
  const [
    draftDeleteCompanyOfficer,
    {
      loading: loadingDraftDeleteCompanyOfficer,
      error: errorDraftDeleteCompanyOfficer,
      data: dataDraftDeleteCompanyOfficer,
    },
  ] = useMutation<any>(DRAFT_DELETE_COMPANY_OFFICER);
  useEffect(() => {
    if (selectedOfficerId) {
      findCompanyOfficerById({
        variables: {
          id: selectedOfficerId,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [selectedOfficerId]);

  useEffect(() => {
    if (dataFindCompanyOfficerById) {
      setOfficersData(
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
      );
      setIsDraft(
        dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
          ?.isDraft
      );

      let country: any = countries?.find(
        (x: ICountry) =>
          x.name ===
          dataFindCompanyOfficerById?.findOneCompanyOfficer?.companyOfficer
            ?.country
      );
      if (country) {
        setSelectedCountry(country.isoCode);
      }
    } else if (errorFindCompanyOfficerById) {
      console.log('errorCompanyProfileByUserId:', errorFindCompanyOfficerById);
    }
  }, [dataFindCompanyOfficerById, errorFindCompanyOfficerById]);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      secpRefId: officersData ? officersData.secpRefId : '',
      officerStatus: officersData ? officersData.status : '',
      designation: officersData ? officersData.designation : '',
      officerName: {
        officerFirstName: officersData ? officersData.firstName : '',
        officerMiddleName: officersData ? officersData.middleName : '',
        officerLastName: officersData ? officersData.lastName : '',
      },
      relativeName: {
        fatherFirstName: officersData ? officersData.relativeFirstName : '',
        fatherMiddleName: officersData ? officersData.relativeMiddleName : '',
        fatherLastName: officersData ? officersData.relativeLastName : '',
      },
      email: officersData ? officersData.email : '',
      relationship: officersData ? officersData.relationship : '',
      nationality: officersData ? officersData.nationality : '',
      cnic: officersData ? officersData.cnic : '',
      cnicExpiry: officersData?.cnicExpiry
        ? new Date(officersData.cnicExpiry).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      passportNo: officersData?.passportNumber
        ? officersData.passportNumber
        : '',
      dateOfBirth: officersData?.dateOfBirth
        ? new Date(officersData.dateOfBirth).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      date: officersData?.date
        ? new Date(officersData.date).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10),
      gender: officersData ? officersData.gender : '',
      businessOccupation: officersData ? officersData.otherOccupation : '',
      noc: officersData ? officersData.noc : '',

      //
      residentialAddress: officersData ? officersData.residentialAddress : '',
      country: officersData ? officersData.country : '',
      province: officersData ? officersData.province : '',
      city: officersData ? officersData.city : '',
      postalCode: officersData ? officersData.postalCode : '',
      telephone:
        officersData && officersData.telephone ? officersData.telephone : '',
      //
      ntn: officersData ? officersData.ntn : '',
      remuneration: officersData ? officersData.renumeration : '',
      cessationReason: officersData ? officersData.cessationReason : '',
      attachment:
        officersData && officersData.url ? JSON.parse(officersData.url) : '',
    },

    validationSchema: Yup.object({
      officerStatus: Yup.string()
        .required('Please select an option')
        .nullable(),
      designation: Yup.string().required('Please select an option').nullable(),
      officerName: Yup.object()
        .shape({
          officerFirstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          officerLastName: Yup.string()
            .required('Please enter last name')
            .nullable(),
        })
        .nullable(),
      relativeName: Yup.object()
        .shape({
          fatherFirstName: Yup.string()
            .required('Please enter first name')
            .nullable(),
          fatherLastName: Yup.string()
            .required('Please enter last name')
            .nullable(),
        })
        .nullable(),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Incorrect email'
        )
        .email('Incorrect email')
        .required('Please enter Email')
        .nullable(),

      relationship: Yup.string().required('Please select an option').nullable(),
      nationality: Yup.string().required('Please enter Nationality').nullable(),
      cnic: Yup.string()
        .matches(
          /^\d{5}-\d{7}-\d$/,
          'CNIC must be in the format xxxxx-xxxxxxx-x'
        )
        .required('Please enter CNIC number')
        .nullable(),
      cnicExpiry: Yup.string()
        .required('Please select date of expiry of cnic')
        .nullable(),
      passportNo: Yup.string().nullable(),
      dateOfBirth: Yup.string()
        .required('Please select date of birth')
        .nullable(),
      date: Yup.string().required('Please select date').nullable(),
      gender: Yup.string().required('Please select an option').nullable(),
      noc: Yup.string().required('Please select an option').nullable(),

      //

      residentialAddress: Yup.string()
        .required('Please enter Residential Address')
        .nullable(),
      country: Yup.string().required('Please select an option').nullable(),
      province: Yup.string().required('Please select an option').nullable(),
      city: Yup.string().required('Please select an option').nullable(),
      postalCode: Yup.string().required('Please enter Postal Code').nullable(),
      telephone: Yup.string()
        .required('Please enter Telephone')
        .min(12, 'Telephone must contains atleast 11 numbers')
        .nullable(),
      //
      ntn: Yup.string()
        .required('Please enter correct NTN')
        .nullable()
        .matches(
          /^\d{7}$|^(\d{5})-(\d{7})-(\d{1})$/,
          'NTN format xxxxx-xxxxxxx-x or 7 digit number'
        ),
      cessationReason: Yup.string().when('officerStatus', {
        is: () => {
          if (validation.values.officerStatus === OfficerStatusEnum.CEASED) {
            return true;
          }
          return false;
        },
        then: Yup.string().required('Please enter Cessation Reason').nullable(),
        otherwise: Yup.string().nullable(),
      }),
    }),

    onSubmit: values => {
      if (isDraft) {
        draftUpdateCompanyOfficer({
          variables: {
            id: selectedOfficerId,
            companyId: companyID,
            secpRefId: values.secpRefId?.toString(),
            firstName: values.officerName.officerFirstName,
            middleName: values.officerName.officerMiddleName,
            lastName: values.officerName.officerLastName,
            status: values.officerStatus,
            date: values.date,
            designation: values.designation,
            relativeFirstName: values.relativeName.fatherFirstName,
            relativeMiddleName: values.relativeName.fatherMiddleName,
            relativeLastName: values.relativeName.fatherLastName,
            relationship: values.relationship,
            nationality: values.nationality,
            email: values.email,

            cnic: values.cnic,
            cnicExpiry: values.cnicExpiry,
            passportNumber: values.passportNo,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            otherOccupation: values.businessOccupation,
            noc: values.noc,

            //
            residentialAddress: values.residentialAddress,
            country: values.country,
            province: values.province,
            city: values.city,
            postalCode: values.postalCode?.toString(),
            telephone: values.telephone,

            //
            ntn: values.ntn,
            renumeration: values.remuneration,
            cessationReason:
              values.officerStatus === ActionEnum.CEASED
                ? values.cessationReason
                : '',
            files: values.attachment,
          },
        })
          .then((res: any) => {
            if (res.data.draft_updateCompanyOfficerDraft.draftCompanyOfficer) {
              dispatch(setToastSuccess('Company officer draft updated!'));
              navigate(`/companyOfficer/officerHistory`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      } else {
        updateCompanyOfficer({
          variables: {
            id: selectedOfficerId,
            companyId: companyID,
            secpRefId: values.secpRefId?.toString(),
            firstName: values.officerName.officerFirstName,
            middleName: values.officerName.officerMiddleName,
            lastName: values.officerName.officerLastName,
            status: values.officerStatus,
            date: values.date,
            designation: values.designation,
            relativeFirstName: values.relativeName.fatherFirstName,
            relativeMiddleName: values.relativeName.fatherMiddleName,
            relativeLastName: values.relativeName.fatherLastName,
            relationship: values.relationship,
            nationality: values.nationality,
            email: values.email,

            cnic: values.cnic,
            cnicExpiry: values.cnicExpiry,
            passportNumber: values.passportNo,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            otherOccupation: values.businessOccupation,
            noc: values.noc,
            //
            residentialAddress: values.residentialAddress,
            country: values.country,
            province: values.province,
            city: values.city,
            postalCode: values.postalCode?.toString(),
            telephone: values.telephone,
            //
            ntn: values.ntn,
            renumeration: values.remuneration,
            cessationReason:
              values.officerStatus === ActionEnum.CEASED
                ? values.cessationReason
                : '',

            files: values.attachment,
          },
        })
          .then((res: any) => {
            if (res?.data?.update_companyOfficer?.companyOfficer) {
              dispatch(setToastSuccess('Company officer updated!'));
              navigate(`/companyOfficer/officerHistory`);
            }
          })
          .catch(err => {
            dispatch(setToastError(err));
          });
      }
    },
  });
  // if (officersData?.url) {
  //   console.log(JSON.parse(officersData?.url));
  // }
  return (
    <React.Fragment>
      {loadingupdateCompanyOfficer ||
      loadingReliventDocumnets ||
      loadingFindCompanyOfficerById ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <i className="bx bx-loader-circle bx-spin bx-lg"></i>
        </div>
      ) : (
        <>
          <div className="w-100 background-white">
            <div
              id="mainComponentHeading"
              className="background-primary-medium "
            >
              <span className="ms-auto">
                <div className="d-flex justify-content-end gap-3 end-0 mx-4">
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      type="button"
                      className="btn fw-bold dangerButton d-flex align-items-center "
                      disabled={loadingDraftUpdateCompanyOfficer}
                      onClick={() => {
                        if (
                          dataFindCompanyOfficerById?.findOneCompanyOfficer
                            ?.companyOfficer
                        ) {
                          let tempObj: any = validation.values;
                          tempObj.dateOfBirth = '';
                          tempObj.officerName = '';
                          tempObj.relativeName = '';
                          const isEmpty = Object.values(tempObj).every(
                            (x: any) => x === null || x === ''
                          );
                          if (isEmpty) {
                            draftDeleteCompanyOfficer({
                              variables: {
                                id: selectedOfficerId,
                              },
                            })
                              .then((res: any) => {
                                if (
                                  res.data.draft_deleteCompanyOfficerDraft.count
                                ) {
                                  dispatch(setSelectedOfficerId(''));
                                  navigate('/companyOfficer');
                                }
                              })
                              .catch(err => {
                                dispatch(setToastError(err));
                              });
                          } else {
                            dispatch(setSelectedOfficerId(''));
                            navigate('/companyOfficer');
                          }
                        } else {
                          dispatch(setSelectedOfficerId(''));
                          navigate('/companyOfficer');
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn fw-bold successButton d-flex align-items-center "
                      disabled={loadingDraftUpdateCompanyOfficer}
                      onMouseDown={() => {
                        officerView
                          ? navigate(`/companyOfficer/officerHistory`)
                          : validation.handleSubmit();
                      }}
                    >
                      {(loadingupdateCompanyOfficer ||
                        loadingDraftUpdateCompanyOfficer) && (
                        <i className="bx bx-loader-circle bx-spin me-1" />
                      )}
                      {'Save & Next'}
                      <i className="bx bx-chevron-right ms-1" />
                    </button>
                  </div>
                </div>
              </span>
            </div>
            <div className="c-nav">
              <span>
                <ul className="nav nav-tabs w-100">
                  <li className="nav-item border-end flex-grow-1 text-center">
                    {isDraft ? (
                      <a className="nav-link active cursor-pointer">
                        Officer Information
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigate('/companyOfficer/personalInformation')
                        }
                        className="nav-link active cursor-pointer"
                      >
                        Officer Information
                      </a>
                    )}
                  </li>
                  {!isDraft && (
                    <li className="nav-item border-end flex-grow-1 text-center">
                      {isDraft ? (
                        <a className="nav-link cursor-pointer">
                          Officer History
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            navigate('/companyOfficer/officerHistory')
                          }
                          className="nav-link cursor-pointer"
                        >
                          Officer History
                        </a>
                      )}
                    </li>
                  )}
                </ul>
              </span>
            </div>
            <div id="mainComponentNew" className="scroll-content">
              {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
              <div className="row mb-2 w-100">
                <div className="col">
                  <div className="d-flex namebox gap-2 justify-content-start align-items-center px-3">
                    <div>
                      <img
                        className="companyIcon me-1 my-3 bg-transparent align-items-center"
                        src={NumberBlockImg}
                      ></img>
                    </div>
                    <div className="form-group w-100">
                      <div className="d-flex">
                        <label htmlFor="secpRefId">SECP Ref ID</label>
                        {validation.touched.secpRefId &&
                        validation.errors.secpRefId ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.secpRefId}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="number"
                        className={`form-control w-100 ${
                          validation.touched.secpRefId &&
                          validation.errors.secpRefId &&
                          'is-invalid'
                        }`}
                        id="secpRefId"
                        name="secpRefId"
                        placeholder="SECP Ref ID"
                        min={'1'}
                        value={validation.values.secpRefId}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.secpRefId &&
                              isDraft &&
                              validation.values.secpRefId &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  secpRefId:
                                    validation.values.secpRefId?.toString(),
                                },
                              });
                          }
                        }}
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-8"></div>
              </div>
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-primary-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={PersonalInfoLogo}
                      ></img>
                      <div className="font-weight-600">Personal Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="OfficerName">Officer Name*</label>
                        {validation.touched.officerName &&
                        validation.errors.officerName ? (
                          <p className="text-danger font-12 ms-2 mb-0">
                            {validation.errors.officerName.officerFirstName
                              ? validation.errors.officerName.officerFirstName
                              : validation.errors.officerName.officerLastName}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="officerName.officerFirstName"
                          name="officerName.officerFirstName"
                          placeholder="First Name"
                          className={`form-control form-sm w-30 ${
                            validation.touched.officerName?.officerFirstName &&
                            validation.errors.officerName?.officerFirstName &&
                            'is-invalid'
                          }`}
                          value={validation.values.officerName.officerFirstName}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.officerName
                                ?.officerFirstName &&
                                isDraft &&
                                validation.values.officerName
                                  ?.officerFirstName &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    firstName:
                                      validation.values.officerName
                                        .officerFirstName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          id="officerName.officerMiddleName"
                          name="officerName.officerMiddleName"
                          placeholder="Middle Name"
                          className={`form-control form-sm w-30 ${
                            validation.touched.officerName?.officerMiddleName &&
                            validation.errors.officerName?.officerMiddleName &&
                            'is-invalid'
                          }`}
                          value={
                            validation.values.officerName.officerMiddleName
                          }
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.officerName
                                ?.officerMiddleName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyOfficer &&
                                validation.values.officerName
                                  .officerMiddleName &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    middleName:
                                      validation.values.officerName
                                        .officerMiddleName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          id="officerName.officerLastName"
                          name="officerName.officerLastName"
                          placeholder="Last Name"
                          className={`form-control form-sm w-30 ${
                            validation.touched.officerName?.officerLastName &&
                            validation.errors.officerName?.officerLastName &&
                            'is-invalid'
                          }`}
                          value={validation.values.officerName.officerLastName}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.officerName?.officerLastName &&
                                isDraft &&
                                validation.values.officerName.officerLastName &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    lastName:
                                      validation.values.officerName
                                        .officerLastName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="fatherName">Father/Spouse Name*</label>
                        {validation.touched.relativeName &&
                        validation.errors.relativeName ? (
                          <p className="text-danger font-12 ms-2 mb-0">
                            {validation.errors.relativeName.fatherFirstName
                              ? validation.errors.relativeName.fatherFirstName
                              : validation.errors.relativeName.fatherLastName}
                          </p>
                        ) : null}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <input
                          type="text"
                          id="relativeName.fatherFirstName"
                          name="relativeName.fatherFirstName"
                          placeholder="First Name"
                          className={`form-control form-sm w-30 ${
                            validation.touched.relativeName?.fatherFirstName &&
                            validation.errors.relativeName?.fatherFirstName &&
                            'is-invalid'
                          }`}
                          value={validation.values.relativeName.fatherFirstName}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.relativeName
                                ?.fatherFirstName &&
                                isDraft &&
                                validation.values.relativeName
                                  ?.fatherFirstName &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    relativeFirstName:
                                      validation.values.relativeName
                                        .fatherFirstName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          id="relativeName.fatherMiddleName"
                          name="relativeName.fatherMiddleName"
                          placeholder="Middle Name"
                          className={`d-flex form-control form-sm w-30 ${
                            validation.touched.relativeName?.fatherMiddleName &&
                            validation.errors.relativeName?.fatherMiddleName &&
                            'is-invalid'
                          }`}
                          value={
                            validation.values.relativeName.fatherMiddleName
                          }
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.relativeName
                                ?.fatherMiddleName &&
                                isDraft &&
                                !loadingDraftUpdateCompanyOfficer &&
                                validation.values.relativeName
                                  .fatherMiddleName &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    relativeMiddleName:
                                      validation.values.relativeName
                                        .fatherMiddleName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                        <input
                          type="text"
                          id="relativeName.fatherLastName"
                          name="relativeName.fatherLastName"
                          placeholder="Last Name"
                          className={`d-flex form-control form-sm w-30 ${
                            validation.touched.relativeName?.fatherLastName &&
                            validation.errors.relativeName?.fatherLastName &&
                            'is-invalid'
                          }`}
                          value={validation.values.relativeName.fatherLastName}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.relativeName?.fatherLastName &&
                                isDraft &&
                                validation.values.relativeName.fatherLastName &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    relativeLastName:
                                      validation.values.relativeName
                                        .fatherLastName,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="officerStatus">Officer Status*</label>
                        {validation.touched.officerStatus &&
                        validation.errors.officerStatus ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.officerStatus}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.officerStatus &&
                            validation.errors.officerStatus &&
                            'is-invalid'
                          }`}
                          id="officerStatus"
                          name="officerStatus"
                          placeholder="Officer Status"
                          value={validation.values.officerStatus}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.officerStatus &&
                                isDraft &&
                                validation.values.officerStatus &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    status: validation.values.officerStatus,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={OfficerStatusEnum.APPOINTED}>
                            Appointed
                          </option>
                          <option value={OfficerStatusEnum.RESIGNED}>
                            Resigned
                          </option>
                          <option value={OfficerStatusEnum.ELECTED}>
                            Elected
                          </option>
                          <option value={OfficerStatusEnum.REELECTED}>
                            Re-Elected
                          </option>
                          <option value={OfficerStatusEnum.CEASED}>
                            Ceased
                          </option>
                          <option value={OfficerStatusEnum.RETIRED}>
                            Retired
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="date">Date*</label>
                        {validation.touched.date && validation.errors.date ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.date}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.date &&
                          validation.errors.date &&
                          'is-invalid'
                        }`}
                        id="date"
                        name="date"
                        value={validation.values.date}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.date &&
                              isDraft &&
                              validation.values.date &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  date: validation.values.date,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="designation">Designation*</label>
                        {validation.touched.designation &&
                        validation.errors.designation ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.designation}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.designation &&
                            validation.errors.designation &&
                            'is-invalid'
                          }`}
                          id="designation"
                          name="designation"
                          placeholder="Designation"
                          value={validation.values.designation}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.designation &&
                                isDraft &&
                                validation.values.designation &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    designation: validation.values.designation,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={DesignationEnum.DIRECTOR}>
                            Director
                          </option>
                          <option
                            value={DesignationEnum.DIRECTOR_AND_SUBSCRIBER}
                          >
                            Director And Subscriber
                          </option>
                          <option value={DesignationEnum.DECLARANT}>
                            Declarant
                          </option>
                          <option value={DesignationEnum.ADVISOR_CONSULTANT}>
                            Advisor/Consultant
                          </option>
                          <option value={DesignationEnum.NOMINEE_DIRECTOR}>
                            Nominee Director
                          </option>
                          <option value={DesignationEnum.LEGAL_ADVISOR}>
                            Legal Advisor
                          </option>
                          <option value={DesignationEnum.AUDITOR}>
                            Auditor
                          </option>
                          <option value={DesignationEnum.CHIEF_ACCOUNTANT}>
                            Chairman Accountant/CFO
                          </option>
                          <option value={DesignationEnum.CHAIRMAN}>
                            Chairman
                          </option>
                          <option value={DesignationEnum.COMPANY_SECRETARY}>
                            Company Secretary
                          </option>
                          <option value={DesignationEnum.CHIEF_EXECUTIVE}>
                            Chief Executive
                          </option>
                          <option value={DesignationEnum.MANAGING_AGENT}>
                            Managing Agent
                          </option>
                          <option value={DesignationEnum.SALE_AGENT}>
                            Sale Agent
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="relationship">Relationship*</label>
                        {validation.touched.relationship &&
                        validation.errors.relationship ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.relationship}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.relationship &&
                            validation.errors.relationship &&
                            'is-invalid'
                          }`}
                          id="relationship"
                          name="relationship"
                          placeholder="Relationship"
                          value={validation.values.relationship}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.relationship &&
                                isDraft &&
                                validation.values.relationship &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    relationship:
                                      validation.values.relationship,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={RelationshipEnum.SON_OF}>
                            Son of
                          </option>
                          <option value={RelationshipEnum.FATHER_OF}>
                            Father of
                          </option>
                          <option value={RelationshipEnum.SPOUSE_OF}>
                            Spouse of
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="nationality">Nationality*</label>
                        {validation.touched.nationality &&
                        validation.errors.nationality ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.nationality}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.nationality &&
                          validation.errors.nationality &&
                          'is-invalid'
                        }`}
                        id="nationality"
                        name="nationality"
                        placeholder="Nationality"
                        value={validation.values.nationality}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.nationality &&
                              isDraft &&
                              validation.values.nationality &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  nationality: validation.values.nationality,
                                },
                              });
                          }
                        }}
                        onChange={e => {
                          const { value } = e.target;
                          const re = /^[A-Za-z]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            validation.setFieldValue('nationality', value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnic">CNIC No* (PK)</label>
                        {validation.touched.cnic && validation.errors.cnic ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.cnic}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.cnic &&
                          validation.errors.cnic &&
                          'is-invalid'
                        }`}
                        id="cnic"
                        name="cnic"
                        placeholder="CNIC"
                        value={validation.values.cnic}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.cnic &&
                              isDraft &&
                              validation.values.cnic &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  cnic: validation.values.cnic,
                                },
                              });
                          }
                        }}
                        onChange={e => {
                          validation.setFieldValue(
                            'cnic',
                            e.target.value.replace(/[^0-9]/gi, '-')
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="cnicExpiry">CNIC Expiry Date*</label>
                        {validation.touched.cnicExpiry &&
                        validation.errors.cnicExpiry ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.cnicExpiry}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.cnicExpiry &&
                          validation.errors.cnicExpiry &&
                          'is-invalid'
                        }`}
                        id="cnicExpiry"
                        name="cnicExpiry"
                        value={validation.values.cnicExpiry}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.cnicExpiry &&
                              isDraft &&
                              validation.values.cnicExpiry &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  cnicExpiry: validation.values.cnicExpiry,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="passportNo">
                          Passport No (Foreign National)
                        </label>
                        {validation.touched.passportNo &&
                        validation.errors.passportNo ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.passportNo}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.passportNo &&
                          validation.errors.passportNo &&
                          'is-invalid'
                        }`}
                        id="passportNo"
                        name="passportNo"
                        placeholder="Passport No"
                        value={validation.values.passportNo}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.passportNo &&
                              isDraft &&
                              validation.values.passportNo &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  passportNumber: validation.values.passportNo,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="dateOfBirth">Date of Birth*</label>
                        {validation.touched.dateOfBirth &&
                        validation.errors.dateOfBirth ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.dateOfBirth}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="date"
                        className={`form-control w-100 ${
                          validation.touched.dateOfBirth &&
                          validation.errors.dateOfBirth &&
                          'is-invalid'
                        }`}
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={validation.values.dateOfBirth}
                        disabled={officerView}
                        max={new Date().toISOString().split('T')[0]}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.dateOfBirth &&
                              isDraft &&
                              validation.values.dateOfBirth &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  dateOfBirth: validation.values.dateOfBirth,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="gender">Gender*</label>
                        {validation.touched.gender &&
                        validation.errors.gender ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.gender}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.gender &&
                            validation.errors.gender &&
                            'is-invalid'
                          }`}
                          id="gender"
                          name="gender"
                          placeholder="Gender"
                          value={validation.values.gender}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.gender &&
                                isDraft &&
                                validation.values.gender &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    gender: validation.values.gender,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={GenderEnum.MALE}>Male</option>
                          <option value={GenderEnum.FEMALE}>Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="businessOccupation">
                          Business Occupation
                        </label>
                        {validation.touched.businessOccupation &&
                        validation.errors.businessOccupation ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.businessOccupation}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.businessOccupation &&
                          validation.errors.businessOccupation &&
                          'is-invalid'
                        }`}
                        id="businessOccupation"
                        name="businessOccupation"
                        placeholder="Business Occupation"
                        value={validation.values.businessOccupation}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.businessOccupation &&
                              isDraft &&
                              validation.values.businessOccupation &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  otherOccupation:
                                    validation.values.businessOccupation,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="noc">NOC*</label>
                        {validation.touched.noc && validation.errors.noc ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.noc}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.noc &&
                            validation.errors.noc &&
                            'is-invalid'
                          }`}
                          id="noc"
                          name="noc"
                          placeholder="NOC"
                          value={validation.values.noc}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.noc &&
                                isDraft &&
                                validation.values.noc &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    noc: validation.values.noc,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        >
                          <option value="">Select</option>
                          <option value={NOCEnum.YES}>Yes</option>
                          <option value={NOCEnum.NO}>No</option>
                          <option value={NOCEnum.NA}>N/A</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  {validation.values.officerStatus === ActionEnum.CEASED ? (
                    <div className="col-3">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="cessationReason">
                            Cessation Reason*
                          </label>
                          {validation.touched.cessationReason &&
                          validation.errors.cessationReason ? (
                            <p className="text-danger font-12 ms-auto mb-0">
                              {validation.errors.cessationReason}
                            </p>
                          ) : null}
                        </div>
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            validation.touched.cessationReason &&
                            validation.errors.cessationReason &&
                            'is-invalid'
                          }`}
                          id="cessationReason"
                          name="cessationReason"
                          placeholder="Cessation Reason"
                          value={validation.values.cessationReason}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.cessationReason &&
                                isDraft &&
                                validation.values.cessationReason &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    cessationReason:
                                      validation.values.cessationReason,
                                  },
                                });
                            }
                          }}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="ntn">NTN*</label>
                        {validation.touched.ntn && validation.errors.ntn ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.ntn}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.ntn &&
                          validation.errors.ntn &&
                          'is-invalid'
                        }`}
                        id="ntn"
                        name="ntn"
                        placeholder="NTN"
                        value={validation.values.ntn}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.ntn &&
                              isDraft &&
                              validation.values.ntn &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  ntn: validation.values.ntn,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="remuneration">Remuneration</label>
                        {validation.touched.remuneration &&
                        validation.errors.remuneration ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.remuneration}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.remuneration &&
                          validation.errors.remuneration &&
                          'is-invalid'
                        }`}
                        id="remuneration"
                        name="remuneration"
                        placeholder="Remuneration"
                        value={validation.values.remuneration}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.remuneration &&
                              isDraft &&
                              validation.values.remuneration &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  renumeration: validation.values.remuneration,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>

                  <div className={`col-1`}>
                    <div className="font-weight-600">Attachment</div>
                    {/* <div className="font-11">Documents</div> */}
                    <div
                      className={`attachedDocumentDiv cursor-pointer ${
                        officerView && 'icon-disabled'
                      }`}
                    >
                      <label htmlFor={'file-input'} className="cursor-pointer">
                        <i className="bx bx-paperclip bx-sm rotate-270 m-2 text-primary"></i>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf,.docx"
                        multiple={true}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e?.target?.files) {
                            validation.setFieldValue(
                              'attachment',
                              e?.target?.files
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-start align-items-center">
                    {validation.values.attachment ? (
                      <div className="row">
                        <div className="col-12">
                          <ul className="d-flex flex-column">
                            {[...validation?.values?.attachment]?.map(
                              (attachment: any, index: number) => (
                                <div className="d-flex align-items-center justify-content-between">
                                  <li key={index}>
                                    {attachment ? attachment.name : ''}
                                  </li>
                                  {!officerView ? (
                                    <i
                                      className="bx bx-x bx-sm mx-3 cursor-pointer"
                                      onClick={e => {
                                        let tempAttachmentFiles = [
                                          ...validation.values.attachment,
                                        ];
                                        tempAttachmentFiles.splice(index, 1);
                                        validation.setFieldValue(
                                          'attachment',
                                          tempAttachmentFiles
                                        );
                                      }}
                                    ></i>
                                  ) : null}
                                </div>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-success-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={AddressIcon}
                      ></img>
                      <div className="font-weight-600">Address</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="country">Country*</label>
                        {validation.touched.country &&
                        validation.errors.country ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.country}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.country &&
                            validation.errors.country &&
                            'is-invalid'
                          }`}
                          id="country"
                          name="country"
                          placeholder="Country"
                          value={validation.values.country}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.country &&
                                isDraft &&
                                validation.values.country &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    country: validation.values.country,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            let country: ICountry | undefined = countries?.find(
                              (x: ICountry) => x.name === e.currentTarget.value
                            );
                            if (country) {
                              setSelectedCountry(country.isoCode);
                            }
                            setSelectedState(undefined);
                            validation.setFieldValue('city', '');
                            validation.setFieldValue('province', '');
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {countries?.map((country: ICountry) => (
                            <option value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="province">Province*</label>
                        {validation.touched.province &&
                        validation.errors.province ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.province}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.province &&
                            validation.errors.province &&
                            'is-invalid'
                          }`}
                          id="province"
                          name="province"
                          placeholder="Province"
                          value={validation.values.province}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.province &&
                                isDraft &&
                                validation.values.province &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    province: validation.values.province,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            let state: IState | undefined = states?.find(
                              (x: IState) => x.name === e.target.value
                            );
                            setSelectedCountry(selectedCountry);
                            if (state) {
                              setSelectedState(state.isoCode);
                            }
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {states?.map((state: IState) => (
                            <option value={state.name}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="city">City*</label>
                        {validation.touched.city && validation.errors.city ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.city}
                          </p>
                        ) : null}
                      </div>
                      <div style={{ position: 'relative' }}>
                        <select
                          className={`form-control w-100 ${
                            validation.touched.city &&
                            validation.errors.city &&
                            'is-invalid'
                          }`}
                          id="city"
                          name="city"
                          placeholder="City"
                          value={validation.values.city}
                          disabled={officerView}
                          onBlur={e => {
                            validation.handleBlur(e);
                            {
                              !validation.errors.city &&
                                isDraft &&
                                validation.values.city &&
                                !loadingDraftUpdateCompanyOfficer &&
                                draftUpdateCompanyOfficer({
                                  variables: {
                                    id: selectedOfficerId,
                                    companyId: companyID,
                                    city: validation.values.city,
                                  },
                                });
                            }
                          }}
                          onChange={e => {
                            validation.handleChange(e);
                          }}
                        >
                          <option value="">Select</option>
                          {cities?.map((city: ICity) => (
                            <option value={city.name}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="postalCode">Postal Code*</label>
                        {validation.touched.postalCode &&
                        validation.errors.postalCode ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.postalCode}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="number"
                        className={`form-control w-100 ${
                          validation.touched.postalCode &&
                          validation.errors.postalCode &&
                          'is-invalid'
                        }`}
                        id="postalCode"
                        name="postalCode"
                        placeholder="Postal Code"
                        min={'1'}
                        value={validation.values.postalCode}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.postalCode &&
                              isDraft &&
                              validation.values.postalCode &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  postalCode:
                                    validation.values.postalCode?.toString(),
                                },
                              });
                          }
                        }}
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onChange={e => {
                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-4 background-dim-grey">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="residentialAddress">
                          Residential Address*
                        </label>
                        {validation.touched.residentialAddress &&
                        validation.errors.residentialAddress ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.residentialAddress}
                          </p>
                        ) : null}
                      </div>
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          validation.touched.residentialAddress &&
                          validation.errors.residentialAddress &&
                          'is-invalid'
                        }`}
                        id="residentialAddress"
                        name="residentialAddress"
                        placeholder="Residential Address"
                        value={validation.values.residentialAddress}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.residentialAddress &&
                              isDraft &&
                              validation.values.residentialAddress &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  residentialAddress:
                                    validation.values.residentialAddress,
                                },
                              });
                          }
                        }}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                </div>
              </div>
              <div className="inputFields border-div px-3 pt-3">
                <div className="row mb-3">
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <div className="border-bottom-yellow-5 mb-3 d-flex justify-content-start align-items-center">
                      <img
                        className="height-30 me-2 my-1 bg-transparent align-items-center"
                        src={ContactInfoIcon}
                      ></img>
                      <div className="font-weight-600">Contact Info</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="email">Email*</label>

                        {validation.touched.email && validation.errors.email ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.email}
                          </p>
                        ) : null}
                      </div>

                      <input
                        type="email"
                        className={`form-control w-100
                  ${
                    validation.touched.email &&
                    validation.errors.email &&
                    'is-invalid'
                  }`}
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={validation.values.email}
                        disabled={officerView || !isDraft}
                        onBlur={e => {
                          validation.handleBlur(e);
                          {
                            !validation.errors.email &&
                              isDraft &&
                              validation.values.email &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  email: validation.values.email,
                                },
                              });
                          }
                        }}
                        onChange={e => {
                          const { value } = e.target;

                          validation.handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="telephone">Telephone*</label>
                        {validation.touched.telephone &&
                        validation.errors.telephone ? (
                          <p className="text-danger font-12 ms-auto mb-0">
                            {validation.errors.telephone}
                          </p>
                        ) : null}
                      </div>
                      <PhoneInput
                        containerStyle={{ padding: 0, width: '100%' }}
                        buttonClass="button-class"
                        containerClass="form-control"
                        inputClass="my-input-class"
                        inputStyle={{
                          border: 0,
                          height: 48,
                          width: '100%',
                        }}
                        buttonStyle={{
                          height: 48,
                        }}
                        countryCodeEditable={false}
                        preferredCountries={['pk']}
                        country={'pk'}
                        value={validation.values.telephone}
                        onChange={e => {
                          validation.setFieldValue('telephone', e);
                        }}
                        disabled={officerView}
                        onBlur={e => {
                          validation.handleBlur(e);

                          {
                            !validation.errors.telephone &&
                              isDraft &&
                              validation.values.telephone &&
                              !loadingDraftUpdateCompanyOfficer &&
                              draftUpdateCompanyOfficer({
                                variables: {
                                  id: selectedOfficerId,
                                  companyId: companyID,
                                  telephone: validation.values.telephone,
                                },
                              });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        !loadingGetHelp &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
