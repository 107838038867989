import { DocumentTypeEnum } from '../../../commons/enums/index';
const GetDocumentTypes = (documentTypes: string) => {
  if (documentTypes === DocumentTypeEnum.Agreements) {
    return 'Agreements';
  } else if (documentTypes === DocumentTypeEnum.Appointment_of_Auditor) {
    return 'Appointment of Auditor';
  } else if (documentTypes === DocumentTypeEnum.Appointment_of_Chairman) {
    return 'Appointment of Chairman';
  } else if (documentTypes === DocumentTypeEnum.Minutes_of_Meeting) {
    return 'Minutes of Meeting';
  } else if (documentTypes === DocumentTypeEnum.Mortgages) {
    return 'Mortgages';
  } else if (documentTypes === DocumentTypeEnum.Charges) {
    return 'Charges';
  } else if (documentTypes === DocumentTypeEnum.Court_order) {
    return 'Court orders';
  } else if (documentTypes === DocumentTypeEnum.Letters_of_consent) {
    return 'Letters of consent';
  } else if (documentTypes === DocumentTypeEnum.Resolutions) {
    return 'Resolutions';
  } else if (
    documentTypes === DocumentTypeEnum.Participations_in_other_entities
  ) {
    return 'Participations in other entities';
  } else if (documentTypes === DocumentTypeEnum.Indemnity_letters) {
    return 'Indemnity letters';
  } else if (documentTypes === DocumentTypeEnum.Letters_of_engagement) {
    return 'Letters of engagement';
  } else if (documentTypes === DocumentTypeEnum.Resignation_letters) {
    return 'Resignation letters';
  } else if (documentTypes === DocumentTypeEnum.Powers_of_attorneys) {
    return 'Powers of attorneys';
  } else if (documentTypes === DocumentTypeEnum.Powers_of_attorneys) {
    return 'Powers of attorneys';
  } else if (documentTypes === DocumentTypeEnum.Mom_Files) {
    return 'Mom Files';
  } else if (documentTypes === DocumentTypeEnum.Resolution_Files) {
    return 'Resolution Files';
  } else {
    return documentTypes;
  }
};

export default GetDocumentTypes;
