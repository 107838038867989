import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { RightSideBar } from '~/components/Sidebar/RightSideBar';

import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import { setBreadCrumb, setToastError } from '~/Store/reducers/layout';
import { CompanySubsidiariesInput } from '../types';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_RELEVANT_DOCS_BY_MODULE_NAME,
  GET_RELEVANT_PROCESSES_BY_EVENT_ID,
} from '~/Store/graphQL/ReleventDocuments';
import { COMPANY_PROFILE_ENUM_ARRAY } from '~/components/NotificationDropdown/Notification.enum';
import { FIND_ALL_COMPANY_MEMBERS } from '~/Store/graphQL/CompanyMembers';
import moment from 'moment';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ModuleEnum } from '~/commons/enums';
import { emptyState, PersonalInfoLogo, task } from '~/assets/images';

export const CompanyProfileMembers: FC = () => {
  const { companyID, companyView } = useAppSelector(
    state => state.companyProfileReducer
  );
  const { userId, userType } = useAppSelector(state => state.layoutReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [editableRow, setEditableRow] = useState<number>(-1);
  const [products, setProducts] = useState<
    CompanySubsidiariesInput[] | undefined
  >();
  const [tempProducts, setTempProducts] = useState<
    CompanySubsidiariesInput[] | undefined
  >();

  useEffect(() => {
    findAllCompanyMembers();
    dispatch(
      setBreadCrumb([
        {
          url: '/companyProfile',
          title: 'Company Profile',
        },
        {
          url: location.pathname,
          title: 'Company Members',
        },
      ])
    );
  }, []);

  const {
    loading: loadingReliventDocumnets,
    error: errorReliventDocumnets,
    data: dataReliventDocumnets,
  } = useQuery<any>(GET_RELEVANT_DOCS_BY_MODULE_NAME, {
    variables: {
      companyId: companyID,
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingRelevantProcesses,
    error: errorRelevantProcesses,
    data: dataRelevantProcesses,
  } = useQuery<any>(GET_RELEVANT_PROCESSES_BY_EVENT_ID, {
    variables: {
      eventIds: COMPANY_PROFILE_ENUM_ARRAY,
    },
  });
  // For fetching help of a module
  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: ModuleEnum.COMPANY_PROFILE,
    },
  });
  const [
    findAllCompanyMembers,
    {
      loading: loadingFindllCompanyMembers,
      error: errorFindAllCompanyMembers,
      data: dataFindAllCompanyMembers,
    },
  ] = useLazyQuery<any>(FIND_ALL_COMPANY_MEMBERS, {
    variables: {
      companyId: companyID,
      first: itemsPerPage,
      status: undefined,
      category: undefined,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataFindAllCompanyMembers) {
      setProducts(dataFindAllCompanyMembers?.findAllCompanyMembers?.edges);
    }
  }, [errorFindAllCompanyMembers, dataFindAllCompanyMembers]);

  return (
    <React.Fragment>
      <div className="w-100 background-white">
        <div id="mainComponentHeading" className="background-primary-medium">
          <span className="ms-auto">
            <div className="d-flex justify-content-end gap-3 end-0 mx-4">
              <div className="d-flex justify-content-end gap-3">
                <button
                  type="button"
                  className="btn fw-bold dangerButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile');
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn fw-bold primaryDismissButton d-flex align-items-center "
                  disabled={editableRow !== -1}
                  onClick={() => {
                    navigate('/companyProfile/paidUpCapitalHistory');
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </span>
        </div>
        {/*  */}
        <div className="c-nav">
          <span>
            <ul className="nav nav-tabs ">
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link ">Company Information</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyInformation');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Company Information
                  </a>
                )}
              </li>

              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Subsidiary</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/subsidiary');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Subsidiary
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link border-end">Mortgages/Charges</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/mortgagesCharges');
                    }}
                    className="nav-link border-end cursor-pointer"
                  >
                    Mortgages/Charges
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">History</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/companyAddressHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    History
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                {isDraft ? (
                  <a className="nav-link">Capital Stucture</a>
                ) : (
                  <a
                    onClick={() => {
                      navigate('/companyProfile/advancedCapitalHistory');
                    }}
                    className="nav-link cursor-pointer"
                  >
                    Capital Stucture
                  </a>
                )}
              </li>
              <li className="nav-item border-end fw-bold">
                <a
                  onClick={() => {
                    navigate('/companyProfile/companyMembers');
                  }}
                  className="nav-link active cursor-pointer"
                >
                  Company Members
                </a>
              </li>
            </ul>
          </span>
        </div>
        {/*  */}
        <div id="mainComponentNew">
          {loadingFindllCompanyMembers ? (
            <div className="d-flex justify-content-center align-items-center w-100">
              <i className="bx bx-loader-circle bx-spin bx-lg"></i>
            </div>
          ) : (
            <div className="w-100" style={{ display: 'contents' }}>
              <div className="table-responsive">
                {isDraft && <p className="is-draft rounded">Saved as Draft</p>}
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Member Category</th>
                      <th>Member Type</th>
                      <th>Member Status</th>
                      <th>Membership Date</th>
                      <th>Folio Number</th>
                      <th>Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((val: any, index: number) => (
                      <tr key={`${index}`}>
                        <td className="border-end">
                          {val.node.firstName + ' ' + val.node.lastName}
                        </td>

                        <td className="border-end">{val.node.category}</td>

                        <td className="border-end">{val.node.type}</td>
                        <td className="border-end">{val.node.status}</td>
                        <td className="border-end">
                          <div className="badge custom-badge-primary">
                            {moment(val.dateOfMembershiph).format(
                              'MMM DD, yyyy'
                            )}
                          </div>
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-success">
                            {val.node.phone}
                          </div>
                        </td>
                        <td className="border-end">
                          <div className="badge custom-badge-primary">
                            {val.node.phone}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {(products?.length === 0 || products === undefined) && (
                  <div className="addDiv h-75 border-bottom-0">
                    <div className=" d-flex justify-content-center align-items-center">
                      <img className="emptyImg" src={emptyState}></img>
                    </div>
                    <h6 className="mt-5">
                      <strong className="color-primary"> Oops! </strong>
                      Looks like you have not added any members yet. Please
                      visit{' '}
                      <strong className="color-primary">
                        <a
                          className="text-decoration-none"
                          href="/companyMembers"
                        >
                          {' '}
                          company member{' '}
                        </a>
                      </strong>{' '}
                      module to add active members
                    </h6>
                    <button
                      type="button"
                      className="btn fw-bold primaryDismissButton mt-4 "
                      disabled={editableRow !== -1}
                      onClick={() => {
                        navigate('/companyProfile/companyInformation');
                      }}
                    >
                      Visit Company Members
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {!loadingReliventDocumnets &&
        !loadingRelevantProcesses &&
        (dataReliventDocumnets || dataRelevantProcesses || dataGetHelp) && (
          <RightSideBar
            documents={
              dataReliventDocumnets?.getRelevantDocsByModuleName?.edges
            }
            processes={
              dataRelevantProcesses?.getRelevantProcessesByEvent
                ?.eventRelevantProcesses
            }
            help={dataGetHelp?.getAllHelpSections?.edges[0]?.node}
          />
        )}
    </React.Fragment>
  );
};
