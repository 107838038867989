import { createSlice } from '@reduxjs/toolkit';
import { AgendaStoreType } from './types';

const initialState: AgendaStoreType = {
  selectedAgendaId: '',
  agendaView: false,
};
const agendaSlice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    setSelectedAgendaId: (state, { payload }) => {
      state.selectedAgendaId = payload;
    },
    setAgendaViewScreen: (state, { payload }) => {
      state.agendaView = payload;
    },
  },
});

export const { setSelectedAgendaId, setAgendaViewScreen } = agendaSlice.actions;

export default agendaSlice.reducer;
