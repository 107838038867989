import { SubscriptionStatusEnum } from '../../commons/enums';
const GetSubscriptionStatus = (status: string) => {
  if (status === SubscriptionStatusEnum.EXPIRED) {
    return 'Expired';
  } else if (status === SubscriptionStatusEnum.PAID) {
    return 'Paid';
  } else if (status === SubscriptionStatusEnum.UN_PAID) {
    return 'Un-Paid';
  } else if (status === SubscriptionStatusEnum.UPDATE_REQUESTED) {
    return 'Update-Requested';
  }
};

export default GetSubscriptionStatus;
