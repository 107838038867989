import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';
import NotificationDropdown from './NotificationDropdown/NotificationDropdown';
import {
  clearStore,
  setIsOpen,
  setToastError,
  setUserId,
} from '~/Store/reducers/layout';
import jwt_decode from 'jwt-decode';
import '../App.scss';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { LOG_OUT } from '~/Store/graphQL/Auth';
import { useNavigate } from 'react-router';
import {
  FIND_COMPANY_PROFILE_BY_ID,
  GET_ALL_COMPANY_PROFILES,
} from '~/Store/graphQL/CompanyProfile';
import { FIND_USER } from '~/Store/graphQL/Users';
import {
  ModuleEnum,
  SubscriptionStatusEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { setSelectedUserPermissions } from '~/Store/reducers/users';
import GetSubscriptionStatus from '~/helpers/Subscriptions/getSubscriptionStatus';
import moment from 'moment';
import { LogoPrimary, LogoWhite } from '~/assets/images';
import Cookies from 'js-cookie';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { GET_ALL_HELP_SECTION } from '~/Store/graphQL/HelpSection';
import { ToastError, ToastSuccess } from './Toasts';

// import $ from 'jquery'
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { breadCrumb, token, isOpen } = useAppSelector(
    state => state.layoutReducer
  );
  const { deviceToken } = useAppSelector(state => state.notificationReducer);

  const [user, setUser] = useState<any>();
  const client = useApolloClient();
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [companiesList, setCompaniesList] = useState<any>();
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number>(-1);
  const popoverRef = useRef<HTMLDivElement>(null);

  const [
    logout,
    { loading: loadinglogout, error: errorlogout, data: datalogout },
  ] = useMutation<any>(LOG_OUT);
  const [
    findCompanyProfileById,
    {
      loading: loadingFindCompanyProfileById,
      error: errorFindCompanyProfileById,
      data: dataFindCompanyProfileById,
    },
  ] = useLazyQuery<any>(FIND_COMPANY_PROFILE_BY_ID);
  const [
    userData,
    { loading: loadingUserData, error: errorUserData, data: dataUserData },
  ] = useLazyQuery<any>(FIND_USER, {
    fetchPolicy: 'no-cache',
  });
  const [
    getAllCompanyProfiles,
    {
      loading: loadingGetAllCompanyProfiles,
      error: errorGetAllCompanyProfiles,
      data: dataGetAllCompanyProfiles,
    },
  ] = useLazyQuery<any>(GET_ALL_COMPANY_PROFILES);
  useEffect(() => {
    if (companyID) {
      findCompanyProfileById({
        variables: {
          id: companyID,
        },
      });
    }
  }, [companyID]);
  useEffect(() => {
    if (token) {
      const { user } = jwt_decode(token) as any;
      getAllCompanyProfiles({
        variables: {
          userId: user.id,
        },
      });
      userData({
        variables: {
          id: user.id,
        },
      });
    }
  }, [token]);
  useEffect(() => {
    let permissionsArray: any = [];
    if (dataUserData) {
      dataUserData?.user?.userGroups?.forEach((element: any) => {
        element.permissions.forEach((permission: any) => {
          permissionsArray.push(permission);
        });
      });
      setUser(dataUserData?.user);
      dispatch(setSelectedUserPermissions(permissionsArray));
    } else if (errorUserData) {
      console.log('errorUserData', errorUserData);
      dispatch(setToastError(errorUserData));
    }
  });
  useEffect(() => {
    if (dataGetAllCompanyProfiles && !errorGetAllCompanyProfiles) {
      let temp: any[] = [];
      dataGetAllCompanyProfiles.getAllCompanyProfiles.companyProfileConnection.edges?.forEach(
        (company: any) => {
          temp.push(company.node);
        }
      );
      setCompaniesList(temp);
    } else if (errorGetAllCompanyProfiles) {
      dispatch(setToastError(errorGetAllCompanyProfiles));
    }
  }, [dataGetAllCompanyProfiles, errorGetAllCompanyProfiles]);
  const toggleModal = () => {
    setDropDownVisibility(!dropDownVisibility);
  };
  const { selectedHelp } = useAppSelector(state => state.helpReducer);

  const {
    loading: loadingGetHelp,
    error: errorGetHelp,
    data: dataGetHelp,
  } = useQuery<any>(GET_ALL_HELP_SECTION, {
    variables: {
      moduleName: selectedHelp,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        dispatch(setIsOpen(isOpen - 1));
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="row m-0 p-0 border-div border-start-0 border-top-0"
      id="header"
    >
      <div className="col-xxl-6 col-xl-7 col-7 d-flex justify-content-start align-items-center p-0">
        <div
          className={`esspLogoHeader d-flex justify-content-center align-items-center
        ${
          location.pathname.includes('add') ||
          location.pathname.includes('companyInformation') ||
          location.pathname.includes('subsidiary') ||
          location.pathname.includes('mortgagesCharges') ||
          location.pathname.includes('companyAddressHistory') ||
          location.pathname.includes('advancedCapitalHistory') ||
          location.pathname.includes('paidUpCapitalHistory') ||
          location.pathname.includes('personalInformation') ||
          location.pathname.includes('companyMembers/')
            ? ''
            : 'background-primary'
        }
        `}
        >
          <img
            className="height-30 me-2 my-1 bg-transparent align-items-center cursor-pointer"
            src={
              location.pathname.includes('add') ||
              location.pathname.includes('companyInformation') ||
              location.pathname.includes('subsidiary') ||
              location.pathname.includes('mortgagesCharges') ||
              location.pathname.includes('companyAddressHistory') ||
              location.pathname.includes('advancedCapitalHistory') ||
              location.pathname.includes('paidUpCapitalHistory') ||
              location.pathname.includes('personalInformation') ||
              location.pathname.includes('companyMembers/')
                ? LogoPrimary
                : LogoWhite
            }
            onClick={() => {
              navigate('/');
            }}
          ></img>
        </div>
        {user?.userType !== UserTypeEnum.SUPER_ADMIN &&
        !location.pathname.includes('companies') ? (
          <div className="border-right-div border-left-div width-20vw px-2">
            <div className="row mt-1">
              <div className="col-2 text-primary d-flex justify-content-center align-items-center">
                <i className="bx bx-buildings bx-sm"></i>
              </div>
              <div className="col-8 headerCompanyName p-0">
                {
                  dataFindCompanyProfileById?.findCompanyProfileById
                    ?.companyProfile?.name
                }
              </div>
              {user?.userType === UserTypeEnum.INTERMEDIAL_USER ? (
                <div className="col-2 d-flex justify-content-center align-items-center dropdown">
                  <i
                    className="bx bxs-down-arrow cursor-pointer"
                    onClick={() => {
                      toggleModal();
                    }}
                  ></i>
                  <ul
                    className={`dropdown-menu change-company-dropdown ${
                      dropDownVisibility && 'show'
                    } `}
                  >
                    {companiesList?.map((element: any, index: number) => (
                      <>
                        <li>
                          <div
                            className={`borderDiv width400 d-flex align-items-center justify-content-between me-3 ms-2 cursor-pointer
                       ${index === 0 ? 'mt-1' : ''}
                       ${index === companiesList.length - 1 ? 'mb-1' : ''}
                      `}
                            onClick={() => {
                              setSelectedCompanyIndex(index);
                              dispatch(setUserId(element.companyId));
                              setDropDownVisibility(false);
                              window.location.reload();
                              // navigate('/dashboard');
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <i className="bx bx-buildings bx-sm text-primary mx-2"></i>
                              <div>{element?.name}</div>
                            </div>

                            <input
                              className="form-check-input cursor-pointer"
                              type="radio"
                              name={`userType`}
                              id="userType"
                              checked={
                                (selectedCompanyIndex === -1 &&
                                  element?.name ===
                                    dataFindCompanyProfileById
                                      ?.findCompanyProfileById?.companyProfile
                                      ?.name) ||
                                index === selectedCompanyIndex
                              }
                            ></input>
                          </div>
                        </li>
                        {index !== companiesList?.length - 1 ? <hr></hr> : null}
                      </>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <div className="row mt-1">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="headerDisabledHeading">Subscription Status</div>
                <div
                  className={`
            ${
              user?.subcriptionData?.status === SubscriptionStatusEnum.PAID
                ? 'headerBoldHeadingSuccess'
                : user?.subcriptionData?.status ===
                  SubscriptionStatusEnum.EXPIRED
                ? 'headerBoldHeadingDanger'
                : 'headerBoldHeadingPrimary'
            }
            `}
                >
                  {GetSubscriptionStatus(user?.subcriptionData?.status)}
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="headerDisabledHeading">Expiration Date</div>
                <div
                  className={`
            ${
              user?.subcriptionData?.status === SubscriptionStatusEnum.EXPIRED
                ? 'headerBoldHeadingDanger'
                : 'headerBoldHeadingPrimary'
            }
            `}
                >
                  {moment(user?.subcriptionData?.endDate).format('YYYY-MM-DD')}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* <div className="col-xxl-1 col-xl-2 col-2 d-flex justify-content-center align-items-center">
        <div className="esspLogoHeader">Compliance Digital</div>
      </div>
      {user?.userType !== UserTypeEnum.SUPER_ADMIN &&
      !location.pathname.includes('companies') ? (
        <div className="col-xxl-3 col-xl-3 col-3 border-div">
          <div className="row mt-1">
            <div className="col-2 text-primary d-flex justify-content-center align-items-center">
              <i className="bx bx-buildings bx-sm"></i>
            </div>
            <div className="col-8 headerCompanyName p-0">
              {
                dataFindCompanyProfileById?.findCompanyProfileById
                  ?.companyProfile?.name
              }
            </div>
            {user?.userType === UserTypeEnum.INTERMEDIAL_USER ? (
              <div className="col-2 d-flex justify-content-center align-items-center dropdown">
                <i
                  className="bx bxs-down-arrow cursor-pointer"
                  onClick={() => {
                    toggleModal();
                  }}
                ></i>
                <ul
                  className={`dropdown-menu change-company-dropdown ${
                    dropDownVisibility && 'show'
                  } `}
                >
                  {companiesList?.map((element: any, index: number) => (
                    <>
                      <li>
                        <div
                          className={`borderDiv width400 d-flex align-items-center justify-content-between me-3 ms-2 cursor-pointer
                       ${index === 0 ? 'mt-1' : ''}
                       ${index === companiesList.length - 1 ? 'mb-1' : ''}
                      `}
                          onClick={() => {
                            setSelectedCompanyIndex(index);
                            dispatch(setUserId(element.companyId));
                            setDropDownVisibility(false);
                            navigate('/dashboard');
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-buildings bx-sm text-primary mx-2"></i>
                            <div>{element?.name}</div>
                          </div>

                          <input
                            className="form-check-input cursor-pointer"
                            type="radio"
                            name={`userType`}
                            id="userType"
                            checked={
                              (selectedCompanyIndex === -1 &&
                                element?.name ===
                                  dataFindCompanyProfileById
                                    ?.findCompanyProfileById?.companyProfile
                                    ?.name) ||
                              index === selectedCompanyIndex
                            }
                          ></input>
                        </div>
                      </li>
                      {index !== companiesList?.length - 1 ? <hr></hr> : null}
                    </>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="row mt-1">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="headerDisabledHeading">Subscription Status</div>
              <div
                className={`
            ${
              user?.subcriptionData?.status === SubscriptionStatusEnum.PAID
                ? 'headerBoldHeadingSuccess'
                : user?.subcriptionData?.status ===
                  SubscriptionStatusEnum.EXPIRED
                ? 'headerBoldHeadingDanger'
                : 'headerBoldHeadingPrimary'
            }
            `}
              >
                {GetSubscriptionStatus(user?.subcriptionData?.status)}
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="headerDisabledHeading">Expiration Date</div>
              <div
                className={`
            ${
              user?.subcriptionData?.status === SubscriptionStatusEnum.EXPIRED
                ? 'headerBoldHeadingDanger'
                : 'headerBoldHeadingPrimary'
            }
            `}
              >
                {user?.subcriptionData?.endDate}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xxl-3 col-xl-3 col-3"></div>
      )} */}

      <div className="col-xxl-6 col-xl-5 col-5 d-flex">
        <div className="d-flex ms-auto">
          {document.getElementById('Popover1') ? (
            <div>
              <Popover
                placement="bottom"
                isOpen={isOpen === 1}
                target="Popover1"
                innerRef={popoverRef}
                toggle={() => {
                  // setHelp(!help);
                  dispatch(setIsOpen(isOpen === 1 ? -1 : 1));
                }}
              >
                {/* <PopoverHeader>HELP</PopoverHeader> */}
                <PopoverBody className="helpSection w-100  ">
                  {' '}
                  <div className="w-100">
                    <h5 className="mb-3 helpHeading">HELP</h5>

                    {/* <h6 className="mt-1 mb-3">
                    {dataGetHelp?.getAllHelpSections?.edges[0]?.node?.heading}
                  </h6> */}
                    {dataGetHelp?.getAllHelpSections?.edges[0]?.node
                      ?.instructions ? (
                      <div
                        className="content "
                        dangerouslySetInnerHTML={{
                          __html:
                            dataGetHelp?.getAllHelpSections?.edges[0]?.node
                              ?.instructions,
                        }}
                      ></div>
                    ) : (
                      'No instructions available'
                    )}

                    {/* <pre> */}
                    {/* {convert(help?.instructions, {
                    wordwrap: 130,
                  })} */}
                    {/* {} */}
                    {/* </pre> */}
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          ) : null}

          <div
            id="Popover1"
            className="d-flex align-items-center me-1 "
            onClick={() => {
              // setHelp(!help);
              dispatch(setIsOpen(isOpen === 1 ? -1 : 1));
            }}
          >
            <i className="bx font-26 bxs-help-circle cursor-pointer text-primary " />

            {/* {help && (
              <div className="menuHeader">
                {' '}
                <>
                  <div className="d-flex">
                    <i className="bx bx-log-out font-16 me-2"></i>
                    <p className="cursor-pointer m-0">abc</p>
                  </div>
                </>
              </div>
            )} */}
          </div>

          {!location.pathname.includes('companies') ? (
            <NotificationDropdown />
          ) : null}

          <div className="d-flex align-items-center">
            <div className="rounded-circle p-2 color-img">
              {user?.firstName.substring(0, 2).toUpperCase()}
            </div>
            <p
              className="mx-2 my-0 cursor-pointer"
              onClick={() => {
                dispatch(setIsOpen(isOpen === 2 ? -1 : 2));
              }}
            >
              {user?.firstName}
              <i className="bx bxs-chevron-down ms-2"></i>
            </p>
            {isOpen === 2 && (
              <div className="menuHeader mt-2">
                {' '}
                {user?.userType === UserTypeEnum.COMPANY_ADMIN ? (
                  <>
                    <div className="d-flex">
                      <i className="bx bx-log-out font-16 me-2"></i>
                      <p
                        className="cursor-pointer m-0"
                        onClick={() => {
                          dispatch(setIsOpen(isOpen === 2 ? -1 : 2));
                          navigate('/intermediary');
                        }}
                      >
                        Settings
                      </p>
                    </div>
                    <hr></hr>
                  </>
                ) : null}
                <div className="d-flex">
                  <i className="bx bx-log-out font-16 me-2"></i>
                  <p
                    className="cursor-pointer m-0"
                    onClick={() => {
                      dispatch(setIsOpen(isOpen === 2 ? -1 : 2));
                      navigate('changePassword');
                    }}
                  >
                    Change Password
                  </p>
                </div>
                <hr></hr>
                <div className="d-flex">
                  <i className="bx bx-log-out font-16 me-2"></i>
                  <p
                    className="cursor-pointer m-0"
                    onClick={() => {
                      logout({
                        variables: {
                          deviceToken: deviceToken,
                        },
                      })
                        .then((res: any) => {
                          if (res.data.logout) {
                            dispatch(clearStore());
                            client.clearStore();
                            client.cache.reset();
                            const options = {
                              path: '/',
                              domain: 'compliancedigital.com.pk',
                            };
                            Cookies.remove('NEXT_AUTH_TOKEN', options);
                            window.open(
                              `https://compliancedigital.com.pk/home`,
                              '_self'
                            );
                          }
                        })
                        .catch(err => {
                          dispatch(setToastError(err));
                        });
                    }}
                  >
                    Logout
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastSuccess />
      <ToastError />
    </div>
  );
};
export default Header;
