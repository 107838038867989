import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'reactstrap';
import {
  MeetingSearchParameterOptionsEnum,
  MeetingStatus,
  MeetingSubTypeEnum,
  MeetingTypeEnum,
  ModuleEnum,
  PermissionCategoryEnum,
  SystemUserSearchParameterOptionsEnum,
  UserCategoryEnum,
  UserTypeEnum,
} from '~/commons/enums';
import { ConfirmationDialogue } from '~/components/Toasts';
import GetPermissionsOfUser from '~/helpers/Permissions/checkPermissionOfUser';
import GetUserType from '~/helpers/User/getUserType';
import {
  DELETE_USER_FROM_USERGROUP,
  FIND_USER_GROUPS,
} from '~/Store/graphQL/UserGroup';
import { FIND_ALL_USERS } from '~/Store/graphQL/Users';
import { useAppDispatch, useAppSelector } from '~/Store/hooks';

import {
  setBreadCrumb,
  setToastError,
  setToastSuccess,
} from '~/Store/reducers/layout';
import {
  setSelectedUserCategory,
  setSelectedUserGroupId,
  setSelectedUserId,
} from '~/Store/reducers/users';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export const SystemUser: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyID } = useAppSelector(state => state.companyProfileReducer);
  const { selectedUserPermissions } = useAppSelector(
    state => state.userReducer
  );
  const { userType } = useAppSelector(state => state.layoutReducer);
  let pageNo = useRef(0);
  const [users, setUsers] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [userGroups, setUserGroups] = useState<any>([]);
  // const [filters, setFilters] = useState<any>({
  //   userGroupId: '',
  //   searchKey: '',
  //   searchValue: '',
  // });
  const [addToolTipIsOpen, setAddToolTipIsOpen] = useState(false);
  const [editToolTipIsOpen, setEditToolTipIsOpen] = useState(false);
  const [deleteToolTipIsOpen, setDeleteToolTipIsOpen] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadCrumb([
        {
          url: location.pathname,
          title: 'System User',
        },
      ])
    );
  }, []);
  const [
    getAllUsers,
    {
      loading: loadingGetAllUsers,
      error: errorGetAllUsers,
      data: dataGetAllUsers,
    },
  ] = useLazyQuery<any>(FIND_ALL_USERS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    findUserGroups,
    {
      loading: loadingFindUserGroup,
      error: errorFindUserGroup,
      data: dataFindUserGroup,
    },
  ] = useLazyQuery<any>(FIND_USER_GROUPS, {
    variables: {
      companyId: companyID,
    },
    fetchPolicy: 'no-cache',
  });
  const [
    deleteUserFromUserGroup,
    {
      loading: loadingDeleteUserFromUserGroup,
      error: errorDeleteUserFromUserGroup,
      data: dataDeleteUserFromUserGroup,
    },
  ] = useMutation<any>(DELETE_USER_FROM_USERGROUP, {});
  useEffect(() => {
    if (companyID) {
      getAllUsers({
        variables: {
          first: itemsPerPage,
          searchKey:
            validationSearch?.values?.searchKey !== 'ALL'
              ? validationSearch?.values?.searchKey
              : undefined,
          searchValue:
            validationSearch?.values?.searchValue !== 'Select'
              ? validationSearch?.values?.searchValue
              : undefined,
        },
      });
      findUserGroups();
    }
  }, [itemsPerPage]);
  useEffect(() => {
    if (dataGetAllUsers) {
      setUsers(dataGetAllUsers?.users?.edges);
    } else if (errorGetAllUsers) {
      console.log('errorGetAllUsers', errorGetAllUsers);
      dispatch(setToastError(errorGetAllUsers));
    }
  }, [dataGetAllUsers, errorGetAllUsers]);
  useEffect(() => {
    if (dataFindUserGroup) {
      setUserGroups(dataFindUserGroup.userGroups.edges);
    } else if (errorFindUserGroup) {
      console.log('errorFindUserGroup', errorFindUserGroup);
      dispatch(setToastError(errorFindUserGroup));
    }
  }, [dataFindUserGroup, errorFindUserGroup]);
  const validationSearch = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      searchKey: '',
      searchValue: '',
    },
    validationSchema: Yup.object({
      searchKey: Yup.string().required('This field required').nullable(),
      searchValue: Yup.string().required('This field required').nullable(),
    }),

    onSubmit: values => {},
  });
  return (
    <React.Fragment>
      <div id="mainComponent">
        <div style={{ width: 'inherit' }}>
          <div className="row d-flex align-items-center mb-5 mx-1">
            <div className="col-1 dot p-0"></div>
            <div className="col-11 d-flex align-items-center">
              <h5 className="m-0">
                <b>System Users</b>
              </h5>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2 mb-4">
            <div className="companyMemberfields">
              <div className="form-group">
                <div className="d-flex w-300">
                  <label htmlFor="meetingType">Choose Option</label>
                  {validationSearch.touched.searchKey &&
                  validationSearch.errors.searchKey ? (
                    <p className="text-danger font-12 ms-auto mb-0">
                      {validationSearch.errors.searchKey}
                    </p>
                  ) : null}
                </div>
                <div className="field">
                  <select
                    id="searchKey"
                    name="searchKey"
                    className={`form-control ${
                      validationSearch.touched.searchKey &&
                      validationSearch.errors.searchKey &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchKey}
                    onChange={e => {
                      validationSearch.setFieldValue('searchValue', '');
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchKey', value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value={SystemUserSearchParameterOptionsEnum.EMAIL}>
                      {' '}
                      Email{' '}
                    </option>
                    <option
                      value={SystemUserSearchParameterOptionsEnum.FIRST_NAME}
                    >
                      {' '}
                      First Name{' '}
                    </option>
                    <option
                      value={SystemUserSearchParameterOptionsEnum.LAST_NAME}
                    >
                      {' '}
                      Last Name{' '}
                    </option>
                    <option
                      value={SystemUserSearchParameterOptionsEnum.USER_TYPE}
                    >
                      {' '}
                      Type{' '}
                    </option>
                    <option
                      value={SystemUserSearchParameterOptionsEnum.USER_GROUP}
                    >
                      {' '}
                      User Group{' '}
                    </option>
                  </select>
                </div>
              </div>
              {validationSearch?.values?.searchKey ===
              SystemUserSearchParameterOptionsEnum.USER_TYPE ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value={UserTypeEnum.COMPANY_ADMIN}>
                        {' '}
                        Company Admin{' '}
                      </option>
                      <option value={UserTypeEnum.INTERMEDIAL_USER}>
                        {' '}
                        Intermediary User{' '}
                      </option>
                      <option value={UserTypeEnum.SUPER_ADMIN}>
                        {' '}
                        Super Admin{' '}
                      </option>
                      <option value={UserTypeEnum.COMPANY_OFFICER}>
                        {' '}
                        Company Officer{' '}
                      </option>
                      <option value={UserTypeEnum.COMPANY_MEMBER}>
                        {' '}
                        Company Member{' '}
                      </option>
                    </select>
                  </div>
                </div>
              ) : validationSearch?.values?.searchKey ===
                SystemUserSearchParameterOptionsEnum.USER_GROUP ? (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="shareType">Choose Option</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <div className="field">
                    <select
                      id="searchValue"
                      name="searchValue"
                      className={`form-control ${
                        validationSearch.touched.searchValue &&
                        validationSearch.errors.searchValue &&
                        'is-invalid'
                      }`}
                      value={validationSearch.values.searchValue}
                      onChange={e => {
                        const { value } = e.target;
                        validationSearch.setFieldValue('searchValue', value);
                      }}
                    >
                      <option value="">Select</option>
                      {userGroups?.map((userGroup: any, index: number) => (
                        <option value={userGroup?.node?.id} key={index}>
                          {userGroup?.node?.groupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div className="d-flex w-300">
                    <label htmlFor="searchValue">Search Data</label>
                    {validationSearch.touched.searchValue &&
                    validationSearch.errors.searchValue ? (
                      <p className="text-danger font-12 ms-auto mb-0">
                        {validationSearch.errors.searchValue}
                      </p>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    id="searchValue"
                    name="searchValue"
                    placeholder="Text"
                    className={`form-control ${
                      validationSearch.touched.searchValue &&
                      validationSearch.errors.searchValue &&
                      'is-invalid'
                    }`}
                    value={validationSearch.values.searchValue}
                    onChange={e => {
                      const { value } = e.target;
                      validationSearch.setFieldValue('searchValue', value);
                    }}
                  />
                </div>
              )}
              <button
                type="button"
                className="btn fw-bold dangerButton d-flex align-items-center align-self-end mb-2"
                onClick={() => {
                  if (validationSearch?.values?.searchKey) {
                    getAllUsers({
                      variables: {
                        first: itemsPerPage,
                        userGroupId: undefined,
                      },
                    });
                  }
                  validationSearch.resetForm();
                }}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn fw-bold successButton d-flex align-items-center align-self-end mb-2 "
                onClick={() => {
                  validationSearch?.handleSubmit();
                  if (validationSearch?.values?.searchValue) {
                    getAllUsers({
                      variables: {
                        first: itemsPerPage,
                        searchKey:
                          validationSearch?.values?.searchKey !== 'ALL'
                            ? validationSearch?.values?.searchKey
                            : undefined,
                        searchValue:
                          validationSearch?.values?.searchValue !== 'Select'
                            ? validationSearch?.values?.searchValue
                            : undefined,
                      },
                    });
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end gap-3 my-3 ${
              !(
                GetPermissionsOfUser(
                  selectedUserPermissions,
                  ModuleEnum.SYSTEM_USER,
                  PermissionCategoryEnum.EDIT
                ) || userType === UserTypeEnum.COMPANY_ADMIN
              )
                ? 'cursor-not-allowed'
                : ''
            }`}
            onMouseEnter={() => setAddToolTipIsOpen(true)}
            onMouseLeave={() => setAddToolTipIsOpen(false)}
          >
            <button
              type="button"
              className="btn btn-primary primary-btn-new d-flex align-items-center fw-bold"
              id="addButton"
              disabled={
                !(
                  GetPermissionsOfUser(
                    selectedUserPermissions,
                    ModuleEnum.SYSTEM_USER,
                    PermissionCategoryEnum.EDIT
                  ) || userType === UserTypeEnum.COMPANY_ADMIN
                )
              }
              onClick={() => {
                dispatch(setSelectedUserId(null));
                dispatch(setSelectedUserCategory(null));
                dispatch(setSelectedUserGroupId(null));
                navigate('/systemuser/add');
              }}
            >
              <i className="bx bx-plus"></i>
              Add User To User Group
            </button>
            {!(
              GetPermissionsOfUser(
                selectedUserPermissions,
                ModuleEnum.SYSTEM_USER,
                PermissionCategoryEnum.EDIT
              ) || userType === UserTypeEnum.COMPANY_ADMIN
            ) ? (
              <Tooltip
                placement="top"
                isOpen={addToolTipIsOpen}
                target="addButton"
              >
                You don't have permission for this module
              </Tooltip>
            ) : null}
          </div>

          <div id="mainComponent">
            <div className="w-100">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th className="text-white">S.No</th>
                      <th className="text-white">Email</th>
                      <th className="text-white">First Name</th>
                      <th className="text-white">Last Name</th>
                      <th className="text-white">Type</th>
                      <th className="text-white">User Group</th>
                      {/* <th className="text-white">
                            Enable Notification
                          </th> */}
                      <th className="text-white">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((element: any, userIndex: number) =>
                      element?.node?.userGroups.length > 0 ? (
                        element?.node?.userGroups?.map(
                          (val: any, index: number) => (
                            <tr key={`${index}`}>
                              <td>
                                <div className="badge custom-badge-primary">
                                  {pageNo.current * itemsPerPage +
                                    (userIndex + index + 1)}
                                </div>
                              </td>
                              <td>
                                {element?.node?.email
                                  ? element?.node?.email
                                  : 'N/A'}
                              </td>
                              <td>
                                {element?.node?.firstName
                                  ? element?.node?.firstName
                                  : 'N/A'}
                              </td>
                              <td>
                                {element?.node?.lastName
                                  ? element?.node?.lastName
                                  : 'N/A'}
                              </td>
                              <td>
                                <div className="badge custom-badge-success">
                                  {element?.node?.userType
                                    ? GetUserType(element?.node?.userType)
                                    : 'N/A'}
                                </div>
                              </td>
                              <td>
                                <div className="badge custom-badge-primary">
                                  {val?.groupName}
                                </div>
                              </td>
                              {/* <td>
                                <input
                                  className="me-3"
                                  type="checkbox"
                                  checked={val.generateDocument}
                                  onChange={e => {}}
                                ></input>
                              </td> */}
                              <td>
                                <div className="editColumn mt-0 gap-1 justify-content-start ms-5">
                                  <div
                                    className={`${
                                      !(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SYSTEM_USER,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      )
                                        ? 'cursor-not-allowed'
                                        : ''
                                    }`}
                                    onMouseEnter={() =>
                                      setEditToolTipIsOpen(true)
                                    }
                                    onMouseLeave={() =>
                                      setEditToolTipIsOpen(false)
                                    }
                                  >
                                    <i
                                      className={`bx bx-sm bxs-edit text-primary  ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SYSTEM_USER,
                                            PermissionCategoryEnum.EDIT
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'icon-disabled'
                                          : ''
                                      }`}
                                      id="editIcon"
                                      onClick={() => {
                                        dispatch(
                                          setSelectedUserId(element?.node?.id)
                                        );
                                        dispatch(
                                          setSelectedUserCategory(
                                            element?.node?.userType
                                          )
                                        );
                                        dispatch(
                                          setSelectedUserGroupId(val?.id)
                                        );
                                        navigate('/systemuser/add');
                                      }}
                                    />
                                    {!(
                                      GetPermissionsOfUser(
                                        selectedUserPermissions,
                                        ModuleEnum.SYSTEM_USER,
                                        PermissionCategoryEnum.EDIT
                                      ) ||
                                      userType === UserTypeEnum.COMPANY_ADMIN
                                    ) ? (
                                      <Tooltip
                                        placement="top"
                                        isOpen={editToolTipIsOpen}
                                        target="editIcon"
                                      >
                                        You don't have permission for this
                                        module
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                  <div
                                    className={`${
                                      !(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SYSTEM_USER,
                                          PermissionCategoryEnum.DELETE
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      )
                                        ? 'cursor-not-allowed'
                                        : ''
                                    }`}
                                    onMouseEnter={() =>
                                      setDeleteToolTipIsOpen(true)
                                    }
                                    onMouseLeave={() =>
                                      setDeleteToolTipIsOpen(false)
                                    }
                                  >
                                    <i
                                      className={`bx bx-sm bxs-trash-alt text-danger ${
                                        !(
                                          GetPermissionsOfUser(
                                            selectedUserPermissions,
                                            ModuleEnum.SYSTEM_USER,
                                            PermissionCategoryEnum.DELETE
                                          ) ||
                                          userType ===
                                            UserTypeEnum.COMPANY_ADMIN
                                        )
                                          ? 'icon-disabled'
                                          : ''
                                      }`}
                                      id="deleteIcon"
                                      onClick={() => {
                                        ConfirmationDialogue.fire({
                                          title: 'Are you sure?',
                                          text: "You won't be able to revert this!",
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonText: 'Delete',
                                          cancelButtonText: 'Cancel',
                                          reverseButtons: true,
                                        }).then(result => {
                                          if (result.isConfirmed) {
                                            deleteUserFromUserGroup({
                                              variables: {
                                                userId: element?.node?.id,
                                                userGroupId: val?.id,
                                                userCategory:
                                                  element?.node?.userType ===
                                                  UserTypeEnum.COMPANY_OFFICER
                                                    ? UserCategoryEnum.COMPANY_OFFICER
                                                    : element?.node
                                                        ?.userType ===
                                                      UserTypeEnum.COMPANY_MEMBER
                                                    ? UserCategoryEnum.COMPANY_MEMBER
                                                    : UserCategoryEnum.INTERMEDIAL_USER,
                                                companyId: companyID,
                                              },
                                            })
                                              .then((res: any) => {
                                                if (
                                                  res.data
                                                    .deleteUserFromUserGroup
                                                    .count
                                                ) {
                                                  dispatch(
                                                    setToastSuccess(
                                                      'User deleted from user group!'
                                                    )
                                                  );
                                                  getAllUsers({
                                                    variables: {
                                                      first: itemsPerPage,
                                                      searchKey:
                                                        validationSearch?.values
                                                          ?.searchKey !== 'ALL'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchKey
                                                          : undefined,
                                                      searchValue:
                                                        validationSearch?.values
                                                          ?.searchValue !==
                                                        'Select'
                                                          ? validationSearch
                                                              ?.values
                                                              ?.searchValue
                                                          : undefined,
                                                    },
                                                  });
                                                }
                                              })
                                              .catch(err => {
                                                dispatch(setToastError(err));
                                              });
                                          }
                                        });
                                      }}
                                    />
                                    {!(
                                      GetPermissionsOfUser(
                                        selectedUserPermissions,
                                        ModuleEnum.SYSTEM_USER,
                                        PermissionCategoryEnum.DELETE
                                      ) ||
                                      userType === UserTypeEnum.COMPANY_ADMIN
                                    ) ? (
                                      <Tooltip
                                        placement="top"
                                        isOpen={deleteToolTipIsOpen}
                                        target="deleteIcon"
                                      >
                                        You don't have permission for this
                                        module
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : element?.node?.userType !==
                        UserTypeEnum.COMPANY_ADMIN ? (
                        <tr>
                          <td>
                            <div className="badge custom-badge-primary">
                              {pageNo.current * itemsPerPage + (userIndex + 1)}
                            </div>
                          </td>
                          <td>
                            {element?.node?.email
                              ? element?.node?.email
                              : 'N/A'}
                          </td>
                          <td>
                            {element?.node?.firstName
                              ? element?.node?.firstName
                              : 'N/A'}
                          </td>
                          <td>
                            {element?.node?.lastName
                              ? element?.node?.lastName
                              : 'N/A'}
                          </td>
                          <td>
                            <div className="badge custom-badge-success">
                              {element?.node?.userType
                                ? GetUserType(element?.node?.userType)
                                : 'N/A'}
                            </div>
                          </td>
                          <td>
                            <div className="badge custom-badge-primary">
                              {'N/A'}
                            </div>
                          </td>
                          {/* <td>
                                <input
                                  className="me-3"
                                  type="checkbox"
                                  checked={val.generateDocument}
                                  onChange={e => {}}
                                ></input>
                              </td> */}
                          <td>
                            <div className="editColumn mt-0 gap-1  justify-content-start ms-5 ">
                              {element?.node?.userType !==
                              UserTypeEnum.COMPANY_ADMIN ? (
                                <div
                                  className={`  ${
                                    !(
                                      GetPermissionsOfUser(
                                        selectedUserPermissions,
                                        ModuleEnum.SYSTEM_USER,
                                        PermissionCategoryEnum.EDIT
                                      ) ||
                                      userType === UserTypeEnum.COMPANY_ADMIN
                                    )
                                      ? 'cursor-not-allowed'
                                      : ''
                                  }`}
                                  onMouseEnter={() =>
                                    setEditToolTipIsOpen(true)
                                  }
                                  onMouseLeave={() =>
                                    setEditToolTipIsOpen(false)
                                  }
                                >
                                  <i
                                    className={`bx bx-sm bxs-edit text-primary  ${
                                      !(
                                        GetPermissionsOfUser(
                                          selectedUserPermissions,
                                          ModuleEnum.SYSTEM_USER,
                                          PermissionCategoryEnum.EDIT
                                        ) ||
                                        userType === UserTypeEnum.COMPANY_ADMIN
                                      )
                                        ? 'icon-disabled'
                                        : ''
                                    }`}
                                    id="editIcon"
                                    onClick={() => {
                                      dispatch(
                                        setSelectedUserId(element?.node?.id)
                                      );
                                      dispatch(
                                        setSelectedUserCategory(
                                          element?.node?.userType
                                        )
                                      );
                                      dispatch(setSelectedUserGroupId(null));
                                      navigate('/systemuser/add');
                                    }}
                                  />
                                  {!(
                                    GetPermissionsOfUser(
                                      selectedUserPermissions,
                                      ModuleEnum.SYSTEM_USER,
                                      PermissionCategoryEnum.EDIT
                                    ) || userType === UserTypeEnum.COMPANY_ADMIN
                                  ) ? (
                                    <Tooltip
                                      placement="top"
                                      isOpen={editToolTipIsOpen}
                                      target="editIcon"
                                    >
                                      You don't have permission for this module
                                    </Tooltip>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </table>
                {(users?.length === 0 || users === undefined) && (
                  <div className="addDiv">
                    <h6>No records Found</h6>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-2 mt-2 mx-1">
                <p className="m-0 text-info">Rows Per Page:</p>
                <select
                  className="form-control button-danger text-info width-5vw"
                  value={itemsPerPage}
                  onChange={e => {
                    const { value } = e.target;
                    setItemsPerPage(Number(value));
                  }}
                >
                  <option value={10}>10</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <i
                  className={
                    dataGetAllUsers?.users?.pageInfo?.hasPreviousPage
                      ? 'bx bx-caret-left bx-sm color-primary ms-auto'
                      : 'bx bx-caret-left bx-sm ms-auto'
                  }
                  onClick={() => {
                    if (
                      companyID &&
                      dataGetAllUsers?.users?.pageInfo?.hasPreviousPage
                    ) {
                      getAllUsers({
                        variables: {
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                          last: itemsPerPage,
                          before: dataGetAllUsers?.users?.pageInfo?.startCursor,
                        },
                      });
                      pageNo.current = pageNo.current - 1;
                    }
                  }}
                />

                <i
                  className={
                    dataGetAllUsers?.users?.pageInfo?.hasNextPage
                      ? 'bx bx-caret-right bx-sm color-primary '
                      : 'bx bx-caret-right bx-sm'
                  }
                  onClick={() => {
                    if (
                      companyID &&
                      dataGetAllUsers?.users?.pageInfo?.hasNextPage
                    ) {
                      getAllUsers({
                        variables: {
                          searchKey:
                            validationSearch?.values?.searchKey !== 'ALL'
                              ? validationSearch?.values?.searchKey
                              : undefined,
                          searchValue:
                            validationSearch?.values?.searchValue !== 'Select'
                              ? validationSearch?.values?.searchValue
                              : undefined,
                          last: itemsPerPage,
                          before: dataGetAllUsers?.users?.pageInfo?.startCursor,
                        },
                      });
                      pageNo.current = pageNo.current + 1;
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
