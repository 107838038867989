import { createSlice } from '@reduxjs/toolkit';
import { OrdinanceType } from './types';

const initialState: OrdinanceType = {
  selectedOrdinanceId: '',
  ordinanceView: false,
};
const ordinanceSlice = createSlice({
  name: 'ordinance',
  initialState,
  reducers: {
    setSelectedOrdinanceId: (state, { payload }) => {
      state.selectedOrdinanceId = payload;
    },
    setOrdinanceViewScreen: (state, { payload }) => {
      state.ordinanceView = payload;
    },
  },
});

export const { setSelectedOrdinanceId ,setOrdinanceViewScreen} = ordinanceSlice.actions;



export default ordinanceSlice.reducer;
