import { createSlice } from '@reduxjs/toolkit';
import { UserStoreType } from './types';

const initialState: UserStoreType = {
  selectedUserId: '',
  selectedUserCategory: '',
  selectedUserGroupId: '',
  selectedUserPermissions: [],
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedUserId: (state, { payload }) => {
      state.selectedUserId = payload;
    },
    setSelectedUserCategory: (state, { payload }) => {
      state.selectedUserCategory = payload;
    },
    setSelectedUserGroupId: (state, { payload }) => {
      state.selectedUserGroupId = payload;
    },
    setSelectedUserPermissions: (state, { payload }) => {
      state.selectedUserPermissions = payload;
    },
  },
});

export const {
  setSelectedUserId,
  setSelectedUserCategory,
  setSelectedUserGroupId,
  setSelectedUserPermissions,
} = userSlice.actions;

export default userSlice.reducer;
